import { observer } from 'mobx-react';
import * as React from 'react';
import {
  RoundButtonGroupModelType,
  RoundButtonModelType,
} from '../../../../internal';
import { RoundButton } from './RoundButton';

export const RoundButtonGroup = observer(
  (props: { model: RoundButtonGroupModelType; id?: string }): JSX.Element => {
    const { model, id } = props;
    return (
      <div id={id} style={{ display: 'flex' }}>
        {model.buttonsArray.map(
          (button: RoundButtonModelType, index: number) => {
            return <RoundButton key={index} model={button} />;
          }
        )}
      </div>
    );
  }
);
