import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { UploadCodesModelType, useMst } from '../../../internal';
import { Dropdown } from '../../UI/dropdown/Dropdown';
import styles from './UploadCodes.module.scss';
import { InputField } from '../../UI/input/InputField';
import { RoundButton } from '../../UI/buttons/round/RoundButton';
import { CheckboxGroup } from '../../UI/checkbox/CheckboxGroup';
import { LightButton } from '../../UI/buttons/light-button/LightButton';
import DownloadLink from 'react-download-link';
import { SectionMessage } from '../../UI/section/message/SectionMessage';
import { UploadModal } from './UploadModal';
import { templateReplace } from '../../../utils/template';
import i18n from '../../../i18n';
import { toAmount } from '../../../utils/methods';
import { SEGMENTED, UNSEGMENTED } from '../../../constants';
import { CODE_TYPE_PERSONALIZED } from '../../../constants/ad';

export const UploadCodes = observer(
  (props: { model: UploadCodesModelType; id?: string }): JSX.Element => {
    const { model, id } = props;
    const { currentPage } = useMst();
    const { t } = useTranslation();
    const promptAlert: React.CSSProperties = {
      textDecoration: 'underline',
      color: ' #002340',
    };
    const codeMessageMapper = () => {
      if (!model.uploadCodesCount) {
        return '';
      } else if (model.uploadCodesCount === 1) {
        return `Uspešno unet ${toAmount(model.uploadCodesCount)} kod`;
      } else if (model.uploadCodesCount < 5) {
        return `Uspešno uneto ${toAmount(model.uploadCodesCount)} koda`;
      }
      return `Uspešno uneto ${toAmount(model.uploadCodesCount)} kodova`;
    };

    const personalizedTextCondition =
      (model?.codeTypeDropdown?.value === CODE_TYPE_PERSONALIZED &&
        !!currentPage?.getNumberOfCoupons()?.value &&
        currentPage?.getOfferTypeModel()?.value === SEGMENTED) ||
      (currentPage?.getOfferTypeModel()?.value === UNSEGMENTED &&
        !!currentPage?.getNumberOfCoupons()?.value);

    return (
      <div className={styles.UploadCodesContainer} id={id}>
        {model.codeTypeDropdown.value === CODE_TYPE_PERSONALIZED ? (
          <>
            <UploadModal model={model} />
            {model.csvFile === null ? (
              <>
                <div className={styles.UploadCodesContent}>
                  <div className={styles.DropdownContainer}>
                    <Dropdown model={model.codeTypeDropdown} />
                  </div>
                  <div className={styles.CodeTypeContainer}>
                    <div className={styles.CodeTypeLabel}>
                      {`${t('user_segments:code_type_label')}`}
                      <span className={styles.Required}>*</span>
                    </div>
                    <CheckboxGroup model={model.checkboxContainer} />
                  </div>
                  {!model.isDisabled && (
                    <div className={styles.ContinueContainer}>
                      <div
                        onClick={() => {
                          model.modal.setOpened(true);
                        }}
                      >
                        <RoundButton model={model.buttonAddTable} />
                      </div>
                      <div className={styles.PersonalizedCodesText}>
                        {templateReplace(
                          i18n.t(
                            personalizedTextCondition
                              ? 'basic:codes_needed_personalized'
                              : 'basic:codes_needed'
                          ),
                          'x',
                          `${toAmount(currentPage.codesNeeded)}`
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </>
            ) : (
              <>
                <div className={styles.UploadCodesContentFile}>
                  <div className={styles.DropdownContainer}>
                    <Dropdown model={model.codeTypeDropdown} />
                  </div>
                  <div className={styles.CodeTypeContainer}>
                    <div className={styles.CodeTypeLabel}>
                      {`${t('user_segments:code_type_label')}`}
                      <span className={styles.Required}>*</span>
                    </div>
                    <CheckboxGroup model={model.checkboxContainer} />
                  </div>
                </div>
                <div className={styles.ChangeFile}>
                  <div>
                    <p className={styles.FileName}>Uneto</p>
                    <div className={styles.UploadedFileContainer}>
                      <p className={styles.FileName}>{model.csvFile.name}</p>
                      <div className={styles.Download}>
                        <DownloadLink
                          style={promptAlert}
                          label="Preuzmi"
                          filename={model.csvFile.name}
                          exportFile={() => model.csvFile}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.CenteredContentChange}>
                  <div className={styles.Left}>
                    <SectionMessage content={codeMessageMapper()} />
                  </div>
                  <LightButton model={model.buttonChange} />
                </div>
              </>
            )}
            {model.isDisabled && (
              <div className={styles.Left}>
                <SectionMessage
                  content={t('basic:previously_entered_table_valid')}
                />
              </div>
            )}
          </>
        ) : (
          <div className={styles.EnterCodesContainer}>
            <div className={styles.DropdownContainer}>
              <Dropdown model={model.codeTypeDropdown} />
            </div>
            <div className={styles.InputField}>
              <InputField model={model.textInputField} />
            </div>
          </div>
        )}
      </div>
    );
  }
);
