import { Instance, types } from 'mobx-state-tree';
import { ChangeEvent } from 'react';
import { ComponentModel } from '../../../internal';
import { EditorState } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import { createLinkDecorator } from '../../../utils/draftJS_Link';

export const TextAreaInputModel = types
  .compose(
    ComponentModel,
    types.model({
      selectedBold: types.maybe(types.boolean),
      selectedItalic: types.maybe(types.boolean),
      selectedBullet: types.maybe(types.boolean),
      editorState: types.frozen<any>(
        EditorState.createWithContent(stateFromHTML(''), createLinkDecorator())
      ),
      showButtons: true,
      rows: types.maybe(types.number),
      resize: types.maybe(types.boolean),
    })
  )
  .actions((self) => {
    return {
      logEditorState(state: any) {
        console.log(state);
      },
      formatInput: (e: any) => {
        switch (e.keyCode) {
          case 8: // Backspace
          case 9: // Tab
          case 13: // Enter
          case 37: // Left
          case 38: // Up
          case 39: // Right
          case 40: // Down
            break;
          default:
            if (!new RegExp('^[a-zA-Z0-9,šćžčđŠĆŽČĐ ]+$').test(e.key)) {
              e.preventDefault();
              return false;
            }
            break;
        }
      },
      resetState() {
        self.value = '';
        self.editorState = EditorState.createWithContent(stateFromHTML(''));
      },
      setStateFromString(stateStr: string) {
        self.value = stateStr;
        self.editorState = EditorState.createWithContent(
          stateFromHTML(stateStr)
        );
      },
      setEditorState(state: any) {
        self.editorState = state;
      },
      onChange(e: ChangeEvent) {
        self.setValue((e.target as HTMLInputElement).value);
      },
      onClick(e: any) {
        self?.parent?.setValue(self.id);
      },
      toggleBold(value: boolean) {
        self.selectedBold = !value;
      },
      toggleItalic(value: boolean) {
        self.selectedItalic = !value;
      },
      toggleBullet(value: boolean) {
        self.selectedBullet = !value;
      },
      setHtml(hasText: boolean, value: string) {
        if (hasText) {
          self.setValue(value);
        }
      },
    };
  })
  .views((self) => {
    return {
      get isSelectedBold() {
        return self.selectedBold === true;
      },
      get isSelectedItalic() {
        return self.selectedItalic === true;
      },
      get isSelectedBullet() {
        return self.selectedBullet === true;
      },
    };
  })
  .named('TextAreaInputModel');

export type TextAreaInputModelType = Instance<typeof TextAreaInputModel>;
