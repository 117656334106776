import { observer } from 'mobx-react';
import React, { useRef } from 'react';
import { UploadCodesModelType, useMst } from '../../../internal';
import styles from './UploadCodes.module.scss';
import { Modal } from '../../UI/modal/Modal';
import close from '../../../assets/images/icon-close.svg';
import upload from '../../../assets/images/icon-upload.svg';
import { useTranslation } from 'react-i18next';
import { CSVReader } from 'react-papaparse';
import dragAndDrop from '../../../assets/images/icon-drag-and-drop.png';
import csvLink from '../../../assets/files/code-template.csv';

export const UploadModal = observer(
  (props: { model: UploadCodesModelType; id?: string }): JSX.Element => {
    const { model, id } = props;
    const { currentPage } = useMst();
    const inputRef = useRef<HTMLInputElement>(null);
    const buttonRef = React.createRef<CSVReader>();
    const handleOpenDialog = (e: React.MouseEvent) => {
      if (buttonRef.current) {
        buttonRef.current.open(e);
      }
    };
    const handleOnDrop = (data: any, e: React.DragEvent<HTMLInputElement>) => {
      model.modal.setOpened(false);
      currentPage.root.api.offers.uploadCodes(
        e,
        currentPage.root.header.partnerId,
        currentPage.codesNeeded
      );
    };
    const handleOnError = (
      err: any,
      file: any,
      inputElem: any,
      reason: any
    ) => {
      return (
        <div className={styles.Error}>
          <img className={styles.ErrorIcon} />
          <span>
            Niste uspešno uneli podatke sa kodovima, proverite sadržaj tabele
          </span>
        </div>
      );
    };

    const { t } = useTranslation(['basic']);
    return (
      <Modal id={id} model={model.modal}>
        <div className={styles.UploadCodesModalContainer}>
          <img
            className={styles.Close}
            src={close}
            alt="close"
            onClick={() => {
              model.modal.setOpened(false);
            }}
          />
          <div className={styles.HeaderContainer}>
            <span className={styles.Heading}>Unos personalizovanih kodova</span>
          </div>
        </div>
        <div className={styles.UploadContent}>
          <div className={styles.DragAndDrop}>
            <CSVReader
              ref={buttonRef}
              onDrop={handleOnDrop}
              onError={handleOnError}
              addRemoveButton
              style={{
                dropArea: {
                  borderRadius: 20,
                  background: '#ececec',
                  borderStyle: 'solid',
                  width: '100%',
                },
              }}
              noProgressBar={true}
            >
              <span>{t('drag_and_drop')}</span>
              <img
                src={dragAndDrop}
                className={styles.DragAndDropIcon}
                alt="drag_and_drop"
              />
            </CSVReader>
          </div>
          <div className={styles.FileUpload}>
            <div className={styles.UploadCodesButtonContainer}>
              <input ref={inputRef} type="file" hidden={true} />
              <div
                className={styles.UploadContainer}
                onClick={handleOpenDialog}
              >
                <span>Unesite tabelu sa kodovima</span>
                <img
                  className={styles.UploadImage}
                  src={upload}
                  alt={'upload'}
                />
              </div>
            </div>
            <CSVReader
              ref={buttonRef}
              onFileLoad={handleOnDrop}
              onError={handleOnError}
              noClick
              noDrag
              noProgressBar={true}
            >
              {({ file }: { file: any }) => (
                <>
                  <div className={styles.ProgressBarContainer}>
                    <div className={styles.ProgressBarLabel}>
                      <div className={styles.FileName}>
                        {model?.csvFile?.name}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </CSVReader>
          </div>
        </div>
        <a
          className={styles.DownloadLink}
          href={csvLink}
          download="code-template.csv"
        >
          Molim vas da koristite sledeći šablon CSV tabele
        </a>
      </Modal>
    );
  }
);
