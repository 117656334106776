import * as React from 'react';
import { DropdownModelType, DropdownOptionModelType } from '../../../internal';
import './BaseDropdown.scss';
import styles from './Dropdown.module.scss';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import SelectSearch from 'react-select-search';
import { Show } from '../show/Show';

export const Dropdown = observer(
  (props: { model: DropdownModelType }): JSX.Element => {
    const { model } = props;
    const { t } = useTranslation();
    const showColor = (option: any) => {
      return (
        <Show condition={option.colorIcon != undefined}>
          <img
            className={styles.ImageLabel}
            src={option.colorIcon}
            alt={'color'}
          />
        </Show>
      );
    };
    const renderOption = (
      props: any,
      option: any,
      snapshot: any,
      className: string
    ) => {
      return (
        <button {...props} className={className} type="submit">
          <div className={styles.Option}>
            {showColor(option)}
            <span>{option.name}</span>
          </div>
        </button>
      );
    };

    const renderOptionValue = (
      valueProps: any,
      snapshot: any,
      className: string
    ) => {
      const { option } = snapshot;
      return (
        <div className={styles.OptionLabel}>
          {option != null ? showColor(option) : <></>}
          <input {...valueProps} className={className} />
        </div>
      );
    };

    return (
      <div data-id={model.id} className={styles.SelectContainer}>
        {model.label ? (
          <label htmlFor={model.id} className={styles.SelectLabel}>
            {t(model.label)}
            <span className={styles.Required}>
              {model.isRequired ? '*' : ''}
            </span>
          </label>
        ) : (
          <></>
        )}
        <div id={model.id} className={styles.SelectContent}>
          <SelectSearch
            options={model.optionsArray.map(
              (option: DropdownOptionModelType) => {
                return {
                  name: option.displayValue,
                  value: option.id,
                  colorIcon: option.colorIcon,
                };
              }
            )}
            value={model.value}
            onChange={(val) => {
              model.onChange({ target: { value: val } });
            }}
            placeholder={t(model.placeholder)}
            search={false}
            printOptions={model.alwaysShowOptions ? 'always' : 'auto'}
            id={`select_${model.id}`}
            disabled={!model.isEnabledComputed}
            renderOption={renderOption}
            renderValue={renderOptionValue}
          />
        </div>
        <div className={styles.SelectArrowsContainer}>
          <div className={styles.SelectArrowsInnerContainer}>
            <div className={styles.UpArrow} />
            <div className={styles.DownArrow} />
          </div>
        </div>
        {model.isInvalid ? (
          <div className={styles.ErrorContainer}>
            <div className={styles.Error}>{t(model.currentMessageValue)}</div>
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  }
);
