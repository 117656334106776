import { observer } from 'mobx-react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { DurationModelType } from '../../../internal';
import styles from './StandardDurationPick.module.scss';

// base pick component encapsulating the common logic between same components
export const StandardDurationPick = observer(
  (props: {
    model: DurationModelType;
    children: any;
    label: string;
    id?: string;
  }): JSX.Element => {
    const { t } = useTranslation();
    const { model, children, label, id } = props;
    return (
      <div className={styles.MainOuterContainer} id={id}>
        {children}
        <div className={styles.OuterDateFieldsContainer}>
          <div className={styles.LowMarginLabel}>{label}</div>
          <div className={styles.DateFieldsContainer}>
            <div className={styles.InnerDateFieldsContainer}>
              <div className={styles.DateContainer}>
                <img className={styles.PickDateBtn} />
                <label className={styles.DateLabel}>
                  {`${t('basic:date_from')} ${model.localizedFromDate}`}
                </label>
              </div>

              <div className={styles.DateContainer}>
                <img className={styles.PickDateBtn} />
                <label className={styles.DateLabel}>
                  {`${t('basic:date_to')} ${model.localizedToDate}`}
                </label>
              </div>
            </div>
            <div
              className={`${styles.PickLinkContainer} ${
                model.disabled ? styles.Disabled : ''
              }`}
            >
              <div
                className={styles.PickLink}
                onClick={() => model.openModal()}
              >
                {t('basic:pick')}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);
