import { StatusCodes } from '../constants/statusCodes';

export async function linkToFile(link: string): Promise<File | null> {
  const response = await fetch(link);
  if (response.status !== StatusCodes.OK) {
    return null;
  }
  const data = await response.blob();
  let filename = link.split('/').pop();
  filename = filename ?? 'test.jpg';
  return new File([data], filename);
}

export function filesToFileList(files: File[]): FileList {
  const list = new DataTransfer();
  files.forEach((file: File) => {
    list.items.add(file);
  });

  return list.files;
}

export function dataURLtoFile(dataurl: string, filename: string): File {
  const arr = dataurl.split(',');
  const mime = arr?.[0]?.match(/:(.*?);/)?.[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}
