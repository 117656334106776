import { observer } from 'mobx-react';
import { AdTypeModelType } from '../../../../internal';
import styles from '../AdType.module.scss';
import distance from '../../../../assets/images/category/icon-walking.png';
import windPayIcon from '../../../../assets/images/icon-apple-pay.svg';
import { useMst } from '../../../../internal';
import React, { ReactNode } from 'react';
import { FileInput } from '../../file-input/FileInput';
import { Show } from '../../show/Show';
import ExpirationInfo from '../../expiration-info/ExpirationInfo';

interface IOfferModel {
  model: AdTypeModelType;
  uiOptions?: { hideDistanceIcon?: boolean };
}

export const FeaturedAd = observer((props: IOfferModel) => {
  const { model, uiOptions } = props;
  const {
    currentPage: {
      adName,
      // IF NO REASON FOR NOT USING PROPS FOR THESE VALUES, SAFE TO DELETE
      adRegularPriceRSD,
      adLoweredPriceRSD,
      fileInputs,
      isImageFromResponse,
      offerPreview,
      shouldShowBothPrices,
    },
  } = useMst();
  const partnerName = offerPreview?.partner?.name;

  // AD SHOULD NEVER DISPLAY ONLY REGULAR PRICE
  // const shouldShowOnlyRegularPrice = () =>
  //   shouldShowOnlyRegularPriceFunc(adRegularPriceRSD, adLoweredPriceRSD);

  return (
    <>
      <div className={styles.FeaturedAdContainer}>
        <div className={styles.FeaturedAd}>
          <div className={styles.TopSection}>
            <Show condition={shouldShowBothPrices}>
              <div className={styles.RibbonPrice}>
                <div className={styles.RegularPrice}>
                  {adRegularPriceRSD} RSD
                </div>
                <div>
                  <span>{adLoweredPriceRSD} RSD</span>
                </div>
              </div>
            </Show>
            {isImageFromResponse ? (
              <div
                className={styles.UploadedImage}
                style={{
                  backgroundImage: `url(${offerPreview.imagesArray[0]})`,
                  backgroundPosition: 'top center',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                }}
              />
            ) : (
              <FileInput
                model={fileInputs[0]}
                ImageWrapper={({ children }: { children: ReactNode }) => {
                  const imgSrc = (children as any)?.props?.src;
                  return (
                    <div
                      className={styles.UploadedImage}
                      style={{
                        backgroundImage: `url(${imgSrc})`,
                        backgroundPosition: 'top center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                      }}
                    />
                  );
                }}
                LabelRenderer={() => <div />}
              />
            )}
          </div>
          <div className={styles.Content}>
            {/*AD SHOULD NEVER DISPLAY ONLY REGULAR PRICE*/}
            {/*<Show condition={shouldShowOnlyRegularPrice()}>*/}
            {/*  <div className={styles.Price}>*/}
            {/*    <span>{adRegularPriceRSD}</span>*/}
            {/*    <span>RSD</span>*/}
            {/*  </div>*/}
            {/*</Show>*/}
            <p className={styles.Store}>{partnerName}</p>
            <h2 className={styles.Title}>{adName}</h2>
            <div className={styles.BottomSection}>
              <div className={styles.Info}>
                <ExpirationInfo model={offerPreview} />
                {!uiOptions?.hideDistanceIcon && (
                  <>
                    <span>|</span>
                    <div className={styles.Distance}>
                      <img
                        className={styles.DistanceIcon}
                        src={distance}
                        alt={'distance'}
                      />
                      <span className={styles.DistanceText}>
                        {model.distance}
                      </span>
                    </div>
                  </>
                )}
              </div>
              {model.isWindPaySupported && (
                <img
                  className={styles.WindPayIcon}
                  src={windPayIcon}
                  alt={'wind pay supported'}
                />
              )}
            </div>
          </div>
        </div>
        <Show condition={model.showDescriptionList}>
          <div className={styles.DescriptionSection}>
            <ul className={styles.Content}>
              <li>Istaknuta veličina oglasa</li>
              <li>Prikaz među prvim oglasima</li>
              <li>Istaknuta cena</li>
              <li>Prikaz trajanja ponude</li>
              <li>Opcija čuvanja za kasnije</li>
            </ul>
          </div>
        </Show>
      </div>
    </>
  );
});
