import { observer } from 'mobx-react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './TranslationSwitcher.module.scss';

export const TranslationSwitcher = (): JSX.Element => {
  const { i18n } = useTranslation();
  const [lang, setLang] = React.useState('sr');

  const changeLanguageHandler = (lang: string) => {
    setLang(lang);
    i18n.changeLanguage(lang);
  };

  return (
    <div className={styles.LangContainer}>
      <div
        onClick={() => changeLanguageHandler('sr')}
        className={lang === 'sr' ? styles.LangActive : styles.Lang}
      >
        {' '}
        RS{' '}
      </div>
      <div className={styles.Break}></div>
      <div
        onClick={() => changeLanguageHandler('en')}
        className={lang === 'en' ? styles.LangActive : styles.Lang}
      >
        {' '}
        EN{' '}
      </div>
    </div>
  );
};
