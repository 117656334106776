import { runsOnNode } from './runenv';

export function smoothlyScrollToPageTop(): void {
  if (runsOnNode()) {
    return;
  }
  window?.scrollTo({ top: 0, behavior: 'smooth' });
}

export function smoothlyScrollToPageBottom(): void {
  if (runsOnNode()) {
    return;
  }
  window?.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
}

export function smoothlyScrollToElement(elementId: string): void {
  document?.getElementById(elementId)?.scrollIntoView({ behavior: 'smooth' });
}
