import { getRoot, Instance, types } from 'mobx-state-tree';
import { ComponentModel, RootType } from '../../../internal';

export const InfoFieldModel = types
  .compose(
    ComponentModel,
    types.model({
      content: types.maybe(types.string),
      showIcon: false,
    })
  )
  .views((self) => {
    return {
      get root(): RootType {
        return getRoot(self);
      },
    };
  })
  .actions((self) => {
    return {
      setContent(value: string) {
        self.content = value;
      },
    };
  })
  .named('InfoFieldModel');

export type InfoFieldModelType = Instance<typeof InfoFieldModel>;
