import { IInvoice, useMst } from '../../internal';
import { Tabs } from '../../components/UI/tabs/Tabs';
import { observer } from 'mobx-react';
import { PaymentReport } from './PaymentReport';
import arrowBack from './../../assets/icons/arrow_left_light.svg';
import styles from './PaymentsAndDocumentsTab2.module.scss';
import { Fragment } from 'react';

export const PaymentsAndDocumentsTab2 = observer(() => {
  const { currentPage, data } = useMst();

  if (currentPage.years.length === 0 || !data.isPromoPeriodOver) {
    return (
      <p className={styles.noInvoicesMessage}>
        Trenutno nemate zaduženja na Yettel Shopping-u.
      </p>
    );
  }
  if (currentPage.currentlyShownHistoricalInvoices) {
    return (
      <div className={styles.wrapper}>
        <div
          className={styles.currentlyShownHistoricalInvoice}
          onClick={() => {
            currentPage.resetCurrentlyShownHistoricalInvoices();
          }}
        >
          <img className={styles.arrowBack} src={arrowBack} />
          <span className={styles.textBack}>Nazad</span>
        </div>

        {currentPage.currentlyShownHistoricalInvoices.map(
          (invoice: IInvoice, i: number) => {
            if (i === 0) {
              return invoice.calculator === 'pay-as-you-go' &&
                invoice.invoice_ads?.length > 0 ? (
                <PaymentReport
                  key={i}
                  paymentReport={invoice}
                  perOfferPaymentsTable={currentPage.getInvoiceAds()}
                  showMonthAndInvoiceDate={true}
                />
              ) : (
                <PaymentReport
                  key={i}
                  paymentReport={invoice}
                  showMonthAndInvoiceDate={true}
                />
              );
            } else {
              return invoice.calculator === 'pay-as-you-go' &&
                invoice.invoice_ads?.length > 0 ? (
                <PaymentReport
                  key={i}
                  paymentReport={invoice}
                  perOfferPaymentsTable={currentPage.getInvoiceAds()}
                  showMonthAndInvoiceDate={false}
                />
              ) : (
                <PaymentReport
                  key={i}
                  paymentReport={invoice}
                  showMonthAndInvoiceDate={false}
                />
              );
            }
          }
        )}

        {currentPage.currentlyShownHistoricalInvoices.length > 1 && (
          <div className={styles.finalSum}>
            <span> Ukupno za naplatu </span>
            <span>
              {currentPage.currentlyShownHistoricalInvoicesSum.toLocaleString(
                'sr-RS'
              )}{' '}
              RSD
            </span>
          </div>
        )}
      </div>
    );
  }

  if (!currentPage.currentlyShownHistoricalInvoices) {
    return (
      <div className={styles.historicalInvoicesMonthsWrapper}>
        <p className={styles.yearText}>Godina</p>
        <div className={styles.yearsTabs}>
          <Tabs model={currentPage.getHistoryMonthlyInvoicesYearsTabs()}></Tabs>
        </div>

        {currentPage.invoicesByMonthsForSelectedYear.map(
          (invoice: { month: number; total: number }, index: number) => (
            <Fragment key={index}>
              {invoice !== undefined && (
                <div className={styles.monthlyInvoiceBlock}>
                  <Month
                    month={Months[invoice.month]}
                    year={
                      currentPage.getHistoryMonthlyInvoicesYearsTabs()
                        .selectedTab
                    }
                    total={invoice.total}
                  />
                  <div
                    onClick={() =>
                      currentPage.setCurrentlyShownHistoricalInvoices(
                        invoice.month
                      )
                    }
                    className={styles.detailedTextWrapper}
                  >
                    <span> Detaljnije </span>
                  </div>
                </div>
              )}
            </Fragment>
          )
        )}
      </div>
    );
  }

  return <></>;
});

const Month = (props: { month: string; year: string; total: number }) => {
  const { month, year, total } = props;
  return (
    <div className={styles.monthBlock}>
      <span>
        {month} {year}
      </span>
      <span>
        {total.toLocaleString('sr-RS')}
        <span className={styles.monthRsd}> RSD </span>
      </span>
    </div>
  );
};

export const Months: Record<number, string> = {
  1: 'Januar',
  2: 'Februar',
  3: 'Mart',
  4: 'April',
  5: 'Maj',
  6: 'Jun',
  7: 'Jul',
  8: 'Avgust',
  9: 'Septembar',
  10: 'Oktobar',
  11: 'Novembar',
  12: 'Decembar',
};
