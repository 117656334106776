import { getRoot, Instance, types } from 'mobx-state-tree';
import { SeverityType } from '../../../components/UI/alert';
import { RootType } from '../../../internal';
import { observable } from 'mobx';

export const ToastModel = types
  .model('ToastModel', {
    id: types.identifier,
    severity: types.enumeration(Object.values(SeverityType)),
    message: types.maybe(types.union(types.string)),
    duration: 5000,
  })
  .volatile((self) => {
    return {
      content: observable<any>(<></>),
    };
  })
  .actions((self) => {
    return {
      afterAttach() {
        const root: RootType = getRoot(self);
        setTimeout(() => {
          root.removeToast(self.id);
        }, self.duration);
      },
      setContent(content: any) {
        self.content = content;
      },
    };
  });

export type ToastModelType = Instance<typeof ToastModel>;
