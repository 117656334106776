import * as React from 'react';
import { observer } from 'mobx-react';

import styles from './TermsSection.module.scss';
import { useTranslation } from 'react-i18next';
import { TextSection } from '../../text-section/TextSection';
import { OfferPreview } from '../../OfferDetailsPreview';

interface IOfferModel {
  model: OfferPreview;
}

export const TermsSection = observer((props: IOfferModel) => {
  const { model } = props;
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.AccordionSection}>
        <TextSection
          id={'uslovi_ponude'}
          title={t('offer:offer_terms')}
          content={model.conditions ?? ''}
        />
      </div>
    </>
  );
});
