import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import styles from '../radio/RadioButton.module.scss';
import * as React from 'react';
import { RadioButtonModelType } from '../../../../internal';

export const RadioButton = observer(
  (props: { model: RadioButtonModelType }): JSX.Element => {
    const { model } = props;
    const { t } = useTranslation(['basic']);
    return (
      <div
        className={
          model.isSelected
            ? styles.RadioButtonContainerActive
            : styles.RadioButtonContainer
        }
      >
        <div className={styles.RadioButtonContent}>
          <label>
            <input
              type="radio"
              name="ad-type"
              value="featured-ad"
              checked={model.isSelected}
              onChange={model.onChange}
              disabled={!model.isEnabledComputed}
            />
            {t(model.label)}
          </label>
        </div>
      </div>
    );
  }
);
