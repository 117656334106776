import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { RootType, useMst } from '../../internal';
import { useTranslation } from 'react-i18next';
import styles from './ResetPassword.module.scss';
import { InputField } from '../../components/UI/input/InputField';
import { Button } from '../../components/UI/buttons/regular/Button';
import { PasswordField } from '../../components/UI/password/PasswordField';

const ResetPassword = observer(() => {
  const { t } = useTranslation();
  const root: RootType = useMst();
  const {
    currentPage: {
      getEmailModel,
      getPasswordModel,
      getConfirmPasswordModel,
      getResetBtnModel,
    },
  } = root;

  useEffect(() => {
    const handleSubmit = (e: { key: string }) => {
      if (e.key === 'Enter') {
        getResetBtnModel().click();
      }
    };
    window.addEventListener('keypress', handleSubmit);
    return () => {
      window.removeEventListener('keypress', handleSubmit);
    };
  }, []);

  return (
    <div className={styles.Container}>
      <div className={styles.Main}>
        <h1 className={styles.Title}>{t('basic:reset_password')}</h1>
        <form
          onSubmit={(event) => {
            event.preventDefault();
          }}
          className={styles.Form}
          noValidate
        >
          <div className={styles.OuterInputFieldContainer}>
            <InputField model={getEmailModel()} />
          </div>

          <div className={styles.OuterInputFieldContainer}>
            <PasswordField model={getPasswordModel()} />
          </div>
          <div className={styles.OuterInputFieldContainer}>
            <PasswordField model={getConfirmPasswordModel()} />
          </div>
          <div className={styles.BtnContainer}>
            <Button model={getResetBtnModel()}></Button>
          </div>
        </form>
      </div>
    </div>
  );
});

export default ResetPassword;
