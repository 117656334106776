import { observer } from 'mobx-react';
import * as React from 'react';
import { Checkbox } from '../../../UI/checkbox/Checkbox';
import styles from './StorePicker.module.scss';

export const StorePicker = observer((props: { model: any }): JSX.Element => {
  const { model } = props;
  return (
    <div className={styles.CheckboxGroupContainer}>
      {model.checkboxesArray.map((checkboxModel: any, index: number) => {
        return (
          <div key={index} className={styles.CheckboxContainer}>
            <Checkbox model={checkboxModel}></Checkbox>
          </div>
        );
      })}
    </div>
  );
});
