import { observer } from 'mobx-react';
import { Modal } from '../../../../components/UI/modal/Modal';
import styles from './AdDetails.module.scss';
import * as React from 'react';
import {
  ApproveRejectAdModelType,
  PageRoutes,
  useMst,
} from '../../../../internal';
import { Show } from '../../../../components/UI/show/Show';
import iconCheck from '../../../../assets/images/icon-check.svg';
import { useTranslation } from 'react-i18next';

export const ApproveRejectModal = observer(
  (props: { model: ApproveRejectAdModelType }): JSX.Element => {
    const { model } = props;
    const { t } = useTranslation();
    const {
      router: { navigate },
    } = useMst();
    return (
      <Modal
        styles={{
          content: {
            minWidth: '40%',
            maxHeight: 'fit-content',
            borderRadius: '20px',
            padding: '40px',
          },
        }}
        model={model.modal}
      >
        <div className={styles.MessageContainer}>
          <img src={iconCheck} alt={'arrow right'} />
          <Show condition={model.isApproved}>
            <div>{t('basic:success_approved')}</div>
          </Show>
          <Show condition={model.isRejected}>
            <div>{t('basic:success_rejected')}</div>
          </Show>
          <Show condition={model.isStopped}>
            <div>{t('basic:success_stop_campaign')}</div>
          </Show>
        </div>
        <div className={styles.CancelLinkContainer}>
          <span
            className={styles.CancelApprovalLink}
            onClick={() => {
              model.modal.setOpened(false);
              navigate({ newView: PageRoutes.AllAds.id });
            }}
          >
            {t('basic:close')}
          </span>
        </div>
      </Modal>
    );
  }
);
