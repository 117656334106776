import { observer } from 'mobx-react';
import * as React from 'react';
import { AdditionalLocationModelType } from '../../../internal';
import { useTranslation } from 'react-i18next';
import styles from './AdditionalLocation.module.scss';
import { LocationPriceListModal } from '../../domain/location-price-list/LocationPriceListModal';
import { templateReplace } from '../../../utils/template';

export const AdditionalLocation = observer(
  (props: {
    model: AdditionalLocationModelType;
    maxNumber: number;
  }): JSX.Element => {
    const { model, maxNumber } = props;
    const { t } = useTranslation();
    const description = templateReplace(
      t('basic:additional_location_description'),
      'x',
      `${maxNumber}`
    ).split('\n');
    return (
      <>
        {description.map((item) => (
          <p key={item} className={styles.AdditionalLocationText}>
            {item}
          </p>
        ))}
        <div className={styles.AddAdditional}>
          <div className={styles.BuyAdditionalLocationContainer}>
            <span>{t('basic:buy_additional_location_text')}</span>
            <span>{model.numberOfAdditionalLocations}</span>
            <a
              onClick={() => {
                model.openModal();
              }}
              className={styles.Link}
            >
              {t('basic:location_addition_price_list')}
            </a>
            <LocationPriceListModal model={model} />
          </div>
          <div className={styles.PriceContainer}>
            <span className={styles.PriceSpan}>{t('basic:price')}</span>
            <div className={styles.PriceContent}>
              <span className={styles.Price}>{model.amount} RSD</span>
            </div>
          </div>
        </div>
      </>
    );
  }
);
