import { IAnyModelType, Instance, types } from 'mobx-state-tree';
import {
  ComponentModel,
  RoundButtonModel,
  RoundButtonModelType,
} from '../../../../internal';

export const RoundButtonGroupModel: IAnyModelType = types
  .compose(
    ComponentModel,
    types.model({
      buttons: types.map(types.late(() => RoundButtonModel)),
      initialValue: '',
    })
  )
  .views((self) => {
    return {
      get buttonsArray() {
        return Array.from(self.buttons.values());
      },
      get selectedButton() {
        return self.buttons.get(self.value);
      },
    };
  })
  .actions((self) => {
    return {
      setValue(value: string) {
        self.value = value;
      },
      beforeDestroy() {
        self.buttons.clear();
      },
      afterCreate() {
        self.value = self.initialValue || self.buttonsArray[0]?.id;
        self.buttonsArray.forEach((button: RoundButtonModelType) => {
          button.setParent(self.id);
        });
      },
      addButton(button: RoundButtonModelType) {
        self.buttons.set(button.id, RoundButtonModel.create(button));
        self.buttons.get(button.id).setParent(self);
      },
      clearData() {
        self.buttons.clear();
      },
    };
  })
  .named('RoundButtonGroupModel');

export type RoundButtonGroupModelType = Instance<typeof RoundButtonGroupModel>;
