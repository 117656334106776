import React, {
  ReactElement,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import './App.css';
import StateRouter from './utils/StateRouter';
import './i18n';
import { Header } from './components/UI/header/Header';
import Toast from './components/UI/toast';
import { ToastPositionType } from './components/UI/toast/Toast';
import { useMst } from './internal';
import { observer } from 'mobx-react';
import { BottomHeader } from './components/UI/header/BottomHeader';
import { MobileHeader } from './components/UI/header/MobileHeader';
import debounce from 'lodash/debounce';

export const App = observer((): ReactElement => {
  const {
    toastsArray,
    user: { token },
  } = useMst();

  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  const isMobile = dimensions.width < 768;

  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }, 1000);

    window.addEventListener('resize', debouncedHandleResize);

    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  });
  const renderHeader = () => {
    if (isMobile) {
      return <MobileHeader />;
    }
    return (
      <>
        <Header />
        {token && <BottomHeader />}
      </>
    );
  };
  return (
    <div className="App">
      <Toast
        list={toastsArray}
        position={ToastPositionType.TopRight}
        duration={5000}
      />
      {renderHeader()}
      <StateRouter />
    </div>
  );
});

export default App;
