import { observer } from 'mobx-react';
import { useMst } from '../../../internal';
import { InputField } from '../../UI/input/InputField';
import styles from './OfferFilters.module.scss';
import { Dropdown } from '../../UI/dropdown/Dropdown';
import { DropdownMultiselect } from '../../UI/dropdown-multiselect/DropdownMultiselect';
import saveIcon from './../../../assets/icons/save.png';

export const OffersFilter = observer((): JSX.Element => {
  const { currentPage } = useMst();
  const adApi = currentPage.root.api.offers;
  return (
    <div>
      <div className={styles.Container}>
        <div className={styles.LeftSideContainer}>
          <div className={styles.SearchInputContainer}>
            <InputField model={currentPage.getSearchInput()} />
          </div>
          <div className={styles.ColumnsSelectorContainer}>
            <DropdownMultiselect model={currentPage.getColumnsSelector()} />
          </div>
        </div>

        <div className={styles.RightSideContainer}>
          <div
            className={styles.ExportButtonContainer}
            onClick={() => adApi.downloadCSVTable(currentPage.downloadCSVQuery)}
          >
            <img src={saveIcon} />
            <span className={styles.saveTable}> Sačuvaj tabelu </span>
          </div>
          <div className={styles.PageSizeDropdownContainer}>
            <span className={styles.numberOfAdsPerPage}>
              Broj oglasa po strani
            </span>
            <Dropdown model={currentPage.getPageSizeDropdown()} />
          </div>
        </div>
      </div>
    </div>
  );
});
