import { getRoot, types } from 'mobx-state-tree';
import { RootType } from '../../internal';
import { MAPS_API_KEY } from '../google-maps';

export interface AddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}

export interface GeolocationResponseData {
  formatted_address: string;
  error_message?: string;
  geometry: {
    location: {
      lat: number;
      lng: number;
    };
  };
  results?: {
    geometry: {
      location: {
        lat: number;
        lng: number;
      };
    };
    address_components: AddressComponent[];
  }[];
}

export const GeolocationApi = types
  .model()
  .views((self) => {
    return {
      get root(): RootType {
        return getRoot(self);
      },
    };
  })
  .actions((self) => {
    return {
      async geolocationToLocality(coordinates: {
        lat: number;
        lng: number;
      }): Promise<string | undefined> {
        const addressObj: GeolocationResponseData = await (
          await fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${coordinates.lat},${coordinates.lng}&key=${MAPS_API_KEY}`
          )
        ).json();
        return addressObj?.results?.[0]?.address_components?.filter?.(
          (comp: AddressComponent) => comp?.types?.includes?.('locality')
        )?.[0]?.long_name;
      },

      async geolocationToAddress(coordinates: {
        lat: number;
        lng: number;
      }): Promise<string> {
        const addressObj: GeolocationResponseData = await (
          await fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${coordinates.lat},${coordinates.lng}&key=${MAPS_API_KEY}`
          )
        ).json();
        return addressObj.formatted_address;
      },

      async addressToGeolocation(
        address: string
      ): Promise<{ lat: number; lng: number } | GeolocationResponseData> {
        const addressObj: GeolocationResponseData = await (
          await fetch(
            'https://maps.googleapis.com/maps/api/geocode/json?' +
              new URLSearchParams({
                address,
                key: MAPS_API_KEY,
              })
          )
        ).json();
        if (addressObj.results?.length) {
          return addressObj.results[0].geometry.location;
        } else {
          if (addressObj.error_message) {
            throw new Error(addressObj.error_message);
          }
        }
        return Promise.resolve(addressObj);
      },
    };
  });
