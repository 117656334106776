import * as React from 'react';
import { observer } from 'mobx-react';

import styles from './TopOffer.module.scss';
import { toAmount } from '../../../../../utils/methods';
import { OfferPreview } from '../../OfferDetailsPreview';
import ExpirationInfo from '../../../../UI/expiration-info/ExpirationInfo';

interface IOfferModel {
  model: OfferPreview;
}

export const TopOffer = observer((props: IOfferModel) => {
  const { model } = props;

  const thumbnailStyles = {
    backgroundImage: `url(${model.imagesArray[0]})`,
    //backgroundPosition: 'top center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  };

  return (
    <>
      <div className={styles.TopOffer}>
        {model.discountDescription && (
          <div className={styles.Discount}>{model.discountDescription}</div>
        )}
        <div className={styles.TopSection}>
          <div className={styles.Photo} style={thumbnailStyles} />
        </div>
        <div className={styles.Content}>
          {!!model.loweredPrice && (
            <div className={styles.Price}>
              <span className={styles.Amount}>
                {toAmount(model.loweredPrice)} RSD
              </span>
            </div>
          )}
          <h2 className={styles.Title}>{model.name}</h2>
          <p className={styles.Store}>{model?.partner?.name}</p>
          <div className={styles.BottomSection}>
            <ExpirationInfo model={model} />
          </div>
        </div>
      </div>
    </>
  );
});
