import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { LightButton } from '../../../../../components/UI/buttons/light-button/LightButton';
import { InputField } from '../../../../../components/UI/input/InputField';
import { STANDARD } from '../../../../../constants';
import { EXPIRED, STOPPED } from '../../../../../constants/ad';
import { Ad, AdAnalytics, UsedCode, useMst } from '../../../../../internal';
import { dateSRLocale, parseDate } from '../../../../../utils/date';
import { toAmount } from '../../../../../utils/methods';
import { DownloadCSVTable } from '../download-csv-table/DownloadCSVTable';
import styles from './AdAnalytics.module.scss';

export const AdAnalyticsView = observer(
  (props: { model: AdAnalytics }): JSX.Element => {
    const { model } = props;
    const { t } = useTranslation();
    const { currentPage } = useMst();
    const usedCodes = currentPage.getUsedCodes();
    const isStopped = () => currentPage.adDetails.status === STOPPED;
    const isExpired = () => currentPage.adDetails.status === EXPIRED;
    const isStandardAd = () => currentPage.adDetails.type === STANDARD;
    const isStandardAdWithoutLimits = () =>
      isStandardAd() && !model.codes_total;
    if (!model) {
      return <></>;
    }
    return (
      <div className={styles.Container}>
        <div className={styles.BasicContainer}>
          <div className={styles.BasicRow}>
            <div className={styles.BasicElement}>{t('basic:ad_views')}</div>
            <div className={`${styles.BasicElement} ${styles.Right}`}>
              <span>{toAmount(model.views)}</span>
            </div>
          </div>
          <div className={styles.BasicRow}>
            <div className={styles.BasicElement}>{t('basic:codes_saved')}</div>
            <div className={`${styles.BasicElement} ${styles.Right}`}>
              <span>{toAmount(model.codes_claimed)}</span>
            </div>
          </div>
          <div className={styles.BasicRow}>
            <div className={styles.BasicElement}>{t('basic:conversion')}</div>
            <div className={`${styles.BasicElement} ${styles.Right}`}>
              <span>{toAmount(model.conversion * 100)}%</span>
            </div>
          </div>
          <div className={styles.BasicRow}>
            <div className={styles.BasicElement}>{t('basic:ad_users_num')}</div>
            <div className={`${styles.BasicElement} ${styles.Right}`}>
              <span>
                {isStandardAd()
                  ? t('basic:unlimited')
                  : toAmount((currentPage.adDetails as Ad).max_target)}
              </span>
            </div>
          </div>
        </div>
        {(isExpired() || isStopped()) && (
          <DownloadCSVTable
            adId={currentPage.adDetails.id}
            partnerId={currentPage.adDetails.partner_id}
          />
        )}
        <div className={styles.NumberOfUsedCoupons}>
          <div className={styles.CouponsAvailableContainer}>
            <div className={styles.MaxNumber}>
              {t('basic:max_number_of_coupons_available')}
            </div>
            <div className={styles.CouponCount}>
              {isStandardAdWithoutLimits()
                ? t('basic:unlimited')
                : toAmount(model.codes_total)}{' '}
              kupona
            </div>
          </div>
          <div className={styles.HeaderText}>
            {t('basic:number_of_used_coupons_campaign')}
          </div>
          <div className={styles.Input}>
            <div className={styles.InputFieldContainer}>
              <InputField model={usedCodes.codeInput} />
            </div>
            <div className={styles.InputBtnContainer}>
              <LightButton model={usedCodes.btn} />
            </div>
          </div>
          <div className={styles.UsedCodesTable}>
            {(usedCodes.elements as UsedCode[])?.map?.((usedCode) => {
              return (
                <div key={usedCode.id} className={styles.UsedCodeRow}>
                  <div className={styles.Key}>
                    {dateSRLocale(parseDate(usedCode.date.split(' ')[0]))}
                  </div>
                  <div className={styles.Value}>
                    {toAmount(usedCode.count)} kupona
                  </div>
                </div>
              );
            })}
            <div className={styles.TotalRow}>
              <div className={styles.Key}>{t('basic:total')}</div>
              <div className={styles.Value}>{toAmount(usedCodes.total)}</div>
            </div>
            {!isStandardAdWithoutLimits() && (
              <div className={styles.RemainingRow}>
                <div className={styles.Key}>{t('basic:remaining')}</div>
                <div className={styles.Value}>
                  {toAmount(model.codes_total - usedCodes.total)}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
);
