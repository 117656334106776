// import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './Support.module.scss';
import React from 'react';
import DOWN_ARROW from '../../assets/icons/chevron-down.svg';
import RIGHT_ARROW from '../../assets/icons/chevron-right.svg';
import LEFT_ARROW from '../../assets/icons/arrow_left_2_light.svg';
import { SetOffer } from './explanation-card/set-offer';
import { LawLimits } from './explanation-card/law-limits';
interface Card {
  label: string;
  component: JSX.Element;
}
export const Support = (): JSX.Element => {
  const { t } = useTranslation();
  const [openedCardIndex, setOpenedCardIndex] = React.useState<number | null>(
    null
  );
  const deviceSize = window.screen.width;
  const isMobileSize = Boolean(deviceSize <= 687);

  const handleToggleCard = (index: number) => {
    setOpenedCardIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const cards: Card[] = [
    {
      label: 'Unos kampanje - postavljanje oglasa',
      component: <SetOffer />,
    },
    {
      label: 'Pravna ograničenja prilikom oglašavanja',
      component: <LawLimits />,
    },
  ];
  return (
    <div className={styles.PageContainer}>
      <div className={styles.PageTitleContainer}>
        <div className={styles.PageTitle}>
          {t('navigation:help_and_support')}
        </div>
      </div>

      {openedCardIndex !== null && isMobileSize && (
        <>
          <div className={styles.LeftContainer}>
            <img height="32px" width="32px" src={LEFT_ARROW} alt="left_arrow" />
            <span onClick={() => window.location.reload()}>Nazad</span>
          </div>
          {cards.map(
            (card, index) =>
              openedCardIndex === index && (
                <div key={index} className={styles.Left}>
                  <div className={styles.MainContentContainer}>
                    <div className={styles.Tab}>
                      <div>{card.label}</div>
                    </div>
                    {card.component}
                  </div>
                </div>
              )
          )}
        </>
      )}
      {(openedCardIndex === null ||
        (openedCardIndex !== null && !isMobileSize)) &&
        cards.map((card, index) => (
          <div key={index} className={styles.CenteredTextContainer}>
            <div className={styles.MainContentContainer}>
              <div
                className={`${styles.TabSection} ${styles.BottomBorder}`}
                onClick={() => handleToggleCard(index)}
              >
                <div className={styles.Tab}>
                  <label className={styles.TabLabel} htmlFor={`card-${index}`}>
                    {card.label}
                  </label>
                  {openedCardIndex !== index && (
                    <img
                      height="32px"
                      width="32px"
                      src={RIGHT_ARROW}
                      alt="right_arrow"
                    />
                  )}
                  {openedCardIndex === index && !isMobileSize && (
                    <img
                      height="32px"
                      width="32px"
                      src={DOWN_ARROW}
                      alt="down_arrow"
                    />
                  )}
                </div>
              </div>
              {openedCardIndex === index && !isMobileSize && card.component}
            </div>
          </div>
        ))}
    </div>
  );
};
