import { getRoot, Instance, types } from 'mobx-state-tree';
import { ButtonModel, RootType } from '../../../internal';

export const SliderModel = types
  .model({
    id: types.identifier,
    value: 18,
    isAgeFrom: types.boolean,
    resetButton: types.maybe(ButtonModel),
  })
  .views((self) => {
    return {
      get root(): RootType {
        return getRoot(self);
      },
    };
  })
  .actions((self) => {
    return {
      setValue(value: number) {
        self.value = value;
      },
      onChange(e: any) {
        this.setValue(e);
      },
    };
  });

export type SliderModelType = Instance<typeof SliderModel>;
