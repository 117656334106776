import { observer } from 'mobx-react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { PickStoresModelType } from '../../../../internal';
import styles from './SearchField.module.scss';

export const SearchField = observer(
  (props: { model: PickStoresModelType }): JSX.Element => {
    const { t } = useTranslation();
    const { model } = props;
    return (
      <div className={styles.SearchFieldContainer}>
        <div className={styles.InnerSearchFieldContainer}>
          <input
            className={styles.SearchField}
            type="text"
            value={model.searchValue}
            onChange={(e: React.FormEvent<HTMLInputElement>) =>
              model.search((e.target as any).value)
            }
            placeholder={t('basic:search')}
          />
          <div className={styles.SearchIconContainer}></div>
        </div>
      </div>
    );
  }
);
