import * as React from 'react';
import { observer } from 'mobx-react';

import styles from './FeaturedOffer.module.scss';
import { OfferPreview } from '../../OfferDetailsPreview';
import ExpirationInfo from '../../../../UI/expiration-info/ExpirationInfo';
import { toAmount } from '../../../../../utils/methods';

interface IOfferModel {
  model: OfferPreview;
}

export const FeaturedOffer = observer((props: IOfferModel) => {
  const { model } = props;

  const thumbnailStyles = {
    backgroundImage: `url(${model.imagesArray[0]})`,
    backgroundPosition: 'top center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  };

  return (
    <>
      <div className={styles.FeaturedOffer}>
        <div className={styles.TopSection}>
          <div className={styles.Photo} style={thumbnailStyles} />
          {!!model.loweredPrice && (
            <div className={styles.Price}>
              {model.regularPrice && (
                <span className={styles.OriginalPrice}>
                  {toAmount(Number(model.regularPrice))} RSD
                </span>
              )}
              <span className={styles.Amount}>
                {toAmount(model.loweredPrice)} RSD
              </span>
            </div>
          )}
        </div>
        <div className={styles.Content}>
          <h2 className={styles.Title}>{model.name}</h2>
          <div className={styles.BottomSection}>
            <div className={styles.Store}>
              <p className={styles.StoreName}>{model?.partner?.name}</p>
            </div>
            <ExpirationInfo model={model} />
          </div>
        </div>
      </div>
    </>
  );
});
