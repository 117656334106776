import { getRoot, Instance, types } from 'mobx-state-tree';
import { FunctionModel, ComponentModel, RootType } from '../../../internal';
import i18n from 'i18next';
import { dataURLtoFile } from '../../../utils/files';

export const FileInputModel = types
  .compose(
    ComponentModel,
    types
      .model({
        files: types.map(types.frozen({})),
        sizeLimit: 5,
        acceptedExtensions: types.optional(types.array(types.string), [
          'png',
          'jpg',
          'jpeg',
          'PNG',
          'JPG',
          'JPEG',
        ]),
        maxNumberOfFiles: types.maybe(types.number),
        onRemove: types.maybe(FunctionModel),
        onAdd: types.maybe(FunctionModel),
        crop: '',
        src: types.maybe(types.string),
      })
      .views((self) => {
        return {
          get filesArray() {
            return Array.from(self.files.values());
          },
          // gets file as File object
          // if it is cropped it gets the cropped data
          // as file, otherwise just returns the data
          get file(): File {
            const originalFile = this.filesArray[0] as File;
            if (!self.crop) {
              return originalFile;
            }
            return dataURLtoFile(self.crop, originalFile.name);
          },
        };
      })
      .actions((self) => {
        let inputRef: any;
        return {
          setFiles(files: FileList | null, runOnAdd = true): void {
            const root: RootType = getRoot(self);
            if (files) {
              Array.from(files).forEach((newFile) => {
                if (
                  !Object.values(self.filesArray).find((file: any) => {
                    return (
                      file.name === newFile.name && file.size === newFile.size
                    );
                  })
                ) {
                  self.files.set(`${newFile.name}_${newFile.size}`, newFile);
                  runOnAdd && self.onAdd && self.onAdd(self);
                } else {
                  root.showFailure(i18n.t('alert:duplicateFile'));
                }
              });
            } else {
              self.files.clear();
            }
            if (inputRef) {
              inputRef.files = new DataTransfer().files;
            }
          },
          clearFiles() {
            this.setCrop('');
            self.files.clear();
            self.src = undefined;
          },
          setInputRef(ref: any) {
            inputRef = ref.current;
          },
          removeFile(fileId: string, index: number) {
            if (self.onRemove) {
              self.onRemove(self.files.get(fileId), self, index);
            }
            self.files.delete(fileId);
            const dt = new DataTransfer();
            self.filesArray.forEach((file: any) => {
              dt.items.add(file);
            });
            if (inputRef) {
              inputRef.files = dt.files;
            }
          },
          processFilesArray(files: File[], dt: DataTransfer) {
            const root: RootType = getRoot(self);
            files.forEach((file: File) => {
              if (
                !self.acceptedExtensions.includes(
                  file.name.split('.').pop() as string
                )
              ) {
                root.showFailure(i18n.t('alert:fileType'));
              } else if (self.sizeLimit < file.size / 1000000) {
                root.showFailure(i18n.t('alert:maxFileSize'));
              } else {
                dt.items.add(file);
              }
            });
          },
          processDroppedFiles(e: React.DragEvent<HTMLDivElement>) {
            const root: RootType = getRoot(self);
            if (
              self.filesArray.length > self.sizeLimit ||
              self.filesArray.length + e.dataTransfer.files.length >
                self.sizeLimit ||
              e.dataTransfer.files.length > self.sizeLimit
            ) {
              root.showFailure(i18n.t('alert:imageCount'));
              (e.target as HTMLInputElement).value = '';
              return;
            }
            const dt = new DataTransfer();
            this.processFilesArray(Array.from(e.dataTransfer.files), dt);
            this.setFiles(dt.files);
          },
          processFiles(e: Event) {
            const root: RootType = getRoot(self);
            const input = e.target as HTMLInputElement;
            if (
              self.filesArray.length > self.sizeLimit ||
              self.filesArray.length + (input.files || []).length >
                self.sizeLimit ||
              (input.files || []).length > self.sizeLimit
            ) {
              root.showFailure(i18n.t('alert:imageCount'));
              (e.target as HTMLInputElement).value = '';
              return;
            }
            const dt = new DataTransfer();
            this.processFilesArray(Array.from(input.files || []), dt);
            this.setFiles(dt.files);
          },
          setCrop(crop: string) {
            // workaround, certain scenarios (like navigating back to section after some time)
            // even pressing F12 trigger this event where the crop string is only the data
            // in the if. This results in broken cropped images. Why this occurs is unknown
            if (crop === 'data:,') {
              return;
            }
            self.crop = crop;
          },
        };
      })
  )
  .named('FileInputModel');

export type FileInputType = Instance<typeof FileInputModel>;
