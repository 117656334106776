import { getRoot, types } from 'mobx-state-tree';
import { createConfirmAdminMessage } from '../../components/domain/confirm-success/ConfirmSuccess';
import { LONG_TIMEOUT, NEW_ADMIN_KEY } from '../../constants';
import i18n from '../../i18n';
import {
  TextInputModel,
  PageModel,
  ButtonModel,
  RootType,
  PasswordInputModel,
  PageRoutes,
  AdminPublic,
  ApiObjectResponse,
} from '../../internal';
import { validatePassword } from '../../validators/validators';

export const ConfirmAdminModel = types
  .compose(PageModel, types.model({}))
  .views((self) => {
    return {
      get root(): RootType {
        return getRoot(self);
      },
      get credentialsNotEntered() {
        return !this.email || !this.password || !this.confirmPassword;
      },
      get email() {
        return self?.components?.get('email')?.value;
      },
      get emailNotEntered(): boolean {
        return !this.email;
      },
      get password() {
        return self?.components?.get('password')?.value;
      },
      get confirmPassword() {
        return self?.components?.get('confirmPassword')?.value;
      },
      get passwordMismatch() {
        return this.password !== this.confirmPassword;
      },
      get isNewAdmin(): boolean {
        return !!Object.keys(this?.root?.router?.currentQueryParams).find(
          (key) => key === NEW_ADMIN_KEY
        );
      },
      get verificationCode(): string {
        return this?.root?.router?.currentQueryParams?.code;
      },
      get emailParam(): string {
        return this?.root?.router?.currentQueryParams?.email;
      },
    };
  })
  .actions((self) => {
    return {
      async onPageExit() {
        // prevent caching
        self.clearData();
      },
      getConfirmBtnModel() {
        return (
          self.components.get('confirm') ||
          self.addComponent(
            ButtonModel.create({
              id: 'confirm',
              label: 'basic:confirm',
              onClickCallback: async () => {
                try {
                  this.getConfirmBtnModel().disable();
                  await this.confirmAdminPassword();
                } catch (err: any) {
                  self.root.showFailure(self.root.mapUserPasswordErrors(err));
                  this.getConfirmBtnModel().enable();
                }
              },
            })
          )
        );
      },
      async confirmAdminPassword() {
        self.runValidators();
        if (self.credentialsNotEntered) {
          self.root.showFailure(i18n.t('alert:credentials_not_entered'));
          this.getConfirmBtnModel().enable();
          return;
        }
        if (self.passwordMismatch) {
          self.root.showFailure(i18n.t('alert:password_mismatch'));
          this.getConfirmBtnModel().enable();
          return;
        }
        await self.root.api.admin.verifyAdmin({
          email: this.getEmailModel().value,
          password: this.getPasswordModel().value,
          verification_code: self.verificationCode,
        });

        try {
          const response: ApiObjectResponse<{
            token: string;
            admin: AdminPublic;
          }> = await self.root.api.admin.login({
            email: this.getEmailModel().value,
            password: this.getPasswordModel().value,
          });
          await self.root.user.setLoginData(response.data);
          self.root.router.navigate({
            newView: PageRoutes.HomeRedirect.id,
          });
        } catch (err) {
          self.root.showFailure(i18n.t('alert:form_invalid'));
          this.getConfirmBtnModel().enable();
          return;
        }
      },
      getEmailModel() {
        return (
          self.components.get('email') ||
          self.addComponent(
            TextInputModel.create({
              id: 'email',
              label: 'basic:email',
              disabled: true,
              value: self.emailParam,
              isRequired: true,
            })
          )
        );
      },
      getPasswordModel() {
        return (
          self.components.get('password') ||
          self.addComponent(
            PasswordInputModel.create({
              id: 'password',
              label: 'basic:password',
              isRequired: true,
              validators: {
                password: validatePassword,
              },
            })
          )
        );
      },
      getConfirmPasswordModel() {
        return (
          self.components.get('confirmPassword') ||
          self.addComponent(
            PasswordInputModel.create({
              id: 'confirmPassword',
              label: 'basic:confirm_password',
              isRequired: true,
              validators: {
                password: validatePassword,
              },
            })
          )
        );
      },
    };
  })
  .named('ConfirmAdminModel');
