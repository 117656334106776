import { Instance, types } from 'mobx-state-tree';

export const LocationPriceDataModel = types
  .model({
    id: types.string,
    from: types.string,
    to: types.string,
    price: types.string,
  })
  .views((self) => {
    return {};
  })
  .actions((self) => {
    return {};
  })
  .named('LocationPriceDataModel');

export type LocationPriceDataModelType = Instance<
  typeof LocationPriceDataModel
>;
