import styles from './Footer.module.scss';
import { observer } from 'mobx-react';
import * as React from 'react';
import iconHome from '../../../../assets/icons/home_active.png';
import iconCoupons from '../../../../assets/icons/coupons_inactive.png';
import iconProfile from '../../../../assets/icons/profile_inactive.png';

const menuItems = [
  {
    id: 'HOME',
    name: 'Ponude',
    icon: iconHome,
    active: true,
  },
  {
    id: 'COUPONS',
    name: 'Kuponi',
    icon: iconCoupons,
    active: false,
  },
  {
    id: 'PROFILE',
    name: 'Profil',
    icon: iconProfile,
    active: false,
  },
];

export const Footer = observer(() => {
  const itemsArray: any[] = menuItems;
  return (
    <footer className={styles.Footer}>
      <nav className={styles.Nav}>
        {itemsArray.map((item: any) => {
          return (
            <div
              className={`${styles.LinkWrapper} ${
                item.active ? styles.Active : ''
              }`}
              key={item.id}
            >
              <img src={item.icon} alt="home" />
              <span className={styles.LinkText}>{item.name}</span>
            </div>
          );
        })}
      </nav>
    </footer>
  );
});
