import { getRoot, Instance, types } from 'mobx-state-tree';
import {
  ComponentModel,
  AdListElemModel,
  PaginationModel,
  AdListElemModelType,
  RootType,
  ColumnModelType,
  OfferFiltersModel,
  useMst,
} from '../../../internal';

export const ColumnModel = types
  .model({
    id: types.identifier,
    label: '',
  })
  .named('ColumnModel');

export const ExtendedAdModel = types.model({
  ad_id: types.identifier,
  ad_status: types.maybeNull(types.string),
  ad_name: types.maybeNull(types.string),
  ad_recommended: types.maybeNull(types.string),
  ad_keywords: types.maybeNull(types.string),
  ad_date_start: types.maybeNull(types.string),
  ad_date_end: types.maybeNull(types.string),
  ad_date_code_start: types.maybeNull(types.string),
  ad_date_code_end: types.maybeNull(types.string),
  ad_visual_type: types.maybeNull(types.string),
  ad_store_location_type: types.maybeNull(types.string),
  ad_url: types.maybeNull(types.string),
  ad_original_price: types.maybeNull(types.string),
  ad_discount_value: types.maybeNull(types.string),
  ad_discount_percentage: types.maybeNull(types.string),
  ad_user_locations: types.maybeNull(types.string),
  ad_gender: types.maybeNull(types.string),
  ad_territory_type: types.maybeNull(types.string),
  ad_user_contract_type: types.maybeNull(types.string),
  ad_spender_type: types.maybeNull(types.string),
  ad_max_target: types.maybeNull(types.string),
  ad_rejection_reason: types.maybeNull(types.string),
  ad_discount_description: types.maybeNull(types.string),
  ad_code_count: types.maybeNull(types.string),
  ad_with_code: types.maybeNull(types.string),
  ad_code_type: types.maybeNull(types.string),
  ad_age_from: types.maybeNull(types.string),
  ad_age_to: types.maybeNull(types.string),
  ad_type: types.maybeNull(types.string),
  ad_views: types.maybeNull(types.string),
  ad_claimed_codes: types.maybeNull(types.string),
  category_id: types.maybeNull(types.string),
  category_name: types.maybeNull(types.string),
  subcategory_id: types.maybeNull(types.string),
  subcategory_name: types.maybeNull(types.string),
  partner_id: types.maybeNull(types.string),
  partner_name: types.maybeNull(types.string),
  partner_legal_name: types.maybeNull(types.string),
  creator: types.maybeNull(types.string),
  publisher: types.maybeNull(types.string),
  partnership_expert: types.maybeNull(types.string),
  partner_admin_name: types.maybeNull(types.string),
  partner_admin_email: types.maybeNull(types.string),
  partner_admin_phone: types.maybeNull(types.string),
  ad_date_range: types.maybeNull(types.string),
  ad_common_code: types.maybeNull(types.string),
  ref: types.maybeNull(types.string),
});
export const TableModel = types
  .compose(
    ComponentModel,
    types.model({
      pagination: types.maybe(types.late(() => PaginationModel)),
      columns: types.map(ColumnModel),
      selectableColumns: false,
    })
  )
  .views((self) => {
    return {
      get root(): RootType {
        return getRoot(self);
      },
      get rowsArray() {
        return Array.from(self.rows.values());
      },
      get columnsArray(): ColumnModelType[] {
        return Array.from(self.columns.values());
      },
      get visibleColumnsArray() {
        return self.selectableColumns
          ? this.columnsArray.filter((column: ColumnModelType) => {
              return (
                self.root.currentPage
                  .getColumnsSelector()
                  .options.checkboxes.get(column.id).isChecked &&
                column.id !== 'allOptions'
              );
            })
          : this.columnsArray;
      },
      get pageSize(): number {
        if (!this.root.currentPage) {
          return 20;
        }

        return Number(
          this.root.currentPage?.getPageSizeDropdown?.()?.value || 20
        );
      },
    };
  })
  .actions((self) => {
    return {
      clearElements() {
        self.rows.clear();
      },
      setElements(adsArr: ExtendedAdModelType[]) {
        self.clearElements();
        adsArr.forEach((adElem: ExtendedAdModelType) => {
          self.rows.put(adElem);
        });
      },
      setColumns(columnsArr: ColumnModelType[]) {
        self.columns.clear();
        columnsArr.forEach((column: ColumnModelType) => {
          self.columns.set(Object.keys(column)[0], {
            id: Object.keys(column)[0],
            label: Object.values(column)[0],
          });
        });
      },
    };
  })
  .named('TableModel');

export const AdTableModel = types.compose(
  TableModel,
  types.model({ rows: types.map(ExtendedAdModel) })
);

export const PaymentsAndDocumentsRowModel = types.model({
  ad_id: types.maybeNull(types.string),
  ad_name: types.maybeNull(types.string),
  days: types.maybeNull(types.union(types.string, types.number)),
  end: types.maybeNull(types.string),
  id: types.identifier,
  invoice_id: types.maybeNull(types.string),
  price_basic_per_day: types.maybeNull(types.union(types.string, types.number)),
  price_targeted_per_day: types.maybeNull(
    types.union(types.string, types.number)
  ),
  price_top_per_day: types.maybeNull(types.union(types.string, types.number)),
  start: types.maybeNull(types.string),
  status: types.maybeNull(types.string),
  subtotal: types.maybeNull(types.union(types.string, types.number)),
  subtotal_per_day: types.maybeNull(types.union(types.string, types.number)),
});
export const PaymentsAndDocumentsTableModel = types.compose(
  TableModel,
  types.model({
    rows: types.map(PaymentsAndDocumentsRowModel),
  })
);

export type AdListModelType = Instance<typeof TableModel>;
export type ExtendedAdModelType = Instance<typeof ExtendedAdModel>;
