import { Instance, types } from 'mobx-state-tree';
import { DropdownMultiselectModel, TextInputModel } from '../../../internal';

export const SearchAndMultiselectFilterModel = types
  .model({
    id: types.identifier,
    filterSearch: types.late(() => TextInputModel),
    filterMultiselect: types.late(() => DropdownMultiselectModel),
  })
  .actions((self) => {
    return {};
  });

export type SearchAndMultiselectFilterModelType = Instance<
  typeof SearchAndMultiselectFilterModel
>;
