import { Instance, types } from 'mobx-state-tree';
import { ComponentModel, FunctionModel } from '../../../internal';
import { dateSRLocale } from '../../../utils/date';

export const DatePickerModel = types
  .compose(
    ComponentModel,
    types.model({
      value: types.maybe(types.Date),
      opened: types.maybe(types.boolean),
      disabled: types.maybe(types.boolean),
      onChangeCallback: types.maybe(FunctionModel),
    })
  )
  .actions((self) => {
    return {
      resetDate() {
        if (!self.value) {
          return;
        }
        self.setValue(undefined);
        self?.onChangeCallback?.(self.value);
      },
      onChange(e: Date) {
        self.setValue(new Date(e));
        if (self.onChangeCallback) {
          self.onChangeCallback(self.value);
        }
      },
      setOpened(value: boolean) {
        self.opened = value;
      },
    };
  })
  .views((self) => {
    return {
      get localizedDate() {
        if (!self.value) {
          return '';
        }
        return dateSRLocale(self.value);
      },
      get isOpened() {
        return self.opened === true;
      },
      get isDisabled() {
        return self.disabled === true;
      },
    };
  })
  .named('DatePickerModel');

export type DatePickerModelType = Instance<typeof DatePickerModel>;
