import { getRoot, types } from 'mobx-state-tree';
import i18n from '../../i18n';
import {
  TextInputModel,
  PageModel,
  ButtonModel,
  RootType,
  PageRoutes,
} from '../../internal';

export const ForgotPasswordModel = types
  .compose(PageModel, types.model({}))
  .views((self) => {
    return {
      get root(): RootType {
        return getRoot(self);
      },
      get credentialsNotEntered() {
        return !this.username;
      },
      get username() {
        return self?.components?.get('username')?.value;
      },
    };
  })
  .actions((self) => {
    return {
      async onPageExit() {
        self.clearData();
      },
      getSubmitModel() {
        return (
          self.components.get('submit_reset') ||
          self.addComponent(
            ButtonModel.create({
              id: 'submit_reset',
              label: i18n.t('basic:send_reset_email'),
              onClickCallback: async () => {
                self.runValidators();
                if (self.credentialsNotEntered) {
                  self.root.showFailure(
                    i18n.t('alert:credentials_not_entered')
                  );
                  return;
                }

                try {
                  await self.root.api.admin.sendResetPasswordEmail({
                    email: self.username,
                  });
                  self.root.showSuccess(i18n.t('alert:reset_email_sent'));
                  self.root.router.navigate({ newView: PageRoutes.Login.id });
                } catch (err) {
                  this.makeCredentialsInvalid();
                }
              },
            })
          )
        );
      },
      makeCredentialsInvalid() {
        this.getUserNameModel().setCurrentMessage(
          i18n.t('alert:invalid_credentials')
        );
      },
      getUserNameModel() {
        return (
          self.components.get('username') ||
          self.addComponent(
            TextInputModel.create({
              id: 'username',
              label: 'E-mail',
              isRequired: true,
              placeholder: 'Unesite vašu e-mail adresu',
            })
          )
        );
      },
    };
  })
  .named('ForgotPasswordModel');
