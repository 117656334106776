import { getRoot, types } from 'mobx-state-tree';
import { RootType } from '../../internal';
import { HttpVerbs } from '../../constants/verbs';

export const PartnerDocumentApi = types
  .model({})
  .named('PartnerDocumentApi')
  .views((self) => {
    return {
      get root(): RootType {
        return getRoot(self);
      },
    };
  })
  .actions((self) => ({
    downloadDocument(partnerId: string) {
      return new Promise((resolve) => {
        self.root.axios
          .requestData({
            type: HttpVerbs.GET,
            path: `/partner-documents/${partnerId}`,
            responseType: 'blob',
          })
          .then((response: any) => {
            resolve(response);
            const url = window.URL.createObjectURL(new Blob(response));
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = partnerId;
            document.body.appendChild(a);
            //simulate click
            a.click();
            //remove the link when done
            document.body.removeChild(a);
          });
      });
    },
    deleteDocument(partnerId: string, documentId: string) {
      return new Promise((resolve) => {
        self.root.axios
          .requestData({
            type: HttpVerbs.DELETE,
            path: `/partner-documents/${partnerId}/${documentId}`,
          })
          .then((response: any) => {
            resolve(response);
            console.log(response);
          })
          .catch((e: any) => {
            console.log(e);
          });
      });
    },
    uploadDocument: (file: File, partnerId: string) => {
      return self.root.axios.uploadFile({
        path: `/partner-documents/${partnerId}`,
        file,
        fileKey: 'partner-contract',
      });
    },
  }));
