import { observer } from 'mobx-react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonModelType } from '../../../../internal';
import styles from './PreviewButton.module.scss';

export const PreviewButton = observer(
  (props: {
    model?: ButtonModelType;
    disabled?: boolean;
    id?: string;
  }): JSX.Element => {
    const { model, disabled, id } = props;
    const { t } = useTranslation();
    return (
      <div
        id={id}
        className={
          disabled || model?.isDisabled ? styles.BtnDisabled : styles.Btn
        }
      >
        {t(model.label)}
      </div>
    );
  }
);
