import {
  getSnapshot,
  IAnyModelType,
  ModelInstanceType,
  types,
} from 'mobx-state-tree';
import { toJS } from 'mobx';
import shortId from 'shortid';

import {
  RouterModel,
  PageModel,
  PageType,
  HeaderModel,
  CreateOfferPageModel,
  ApiModel,
  AxiosModel,
  GeolocationModel,
  ToastModel,
  ToastModelType,
  UserModel,
  CreateOfferSuccessPageModel,
  LoginModel,
  UserType,
  PartnerOffersPageModel,
  AllAdsModel,
  BannersPageModel,
  PartnerProfilesModel,
  SharedDataModel,
  AdDetailsModel,
  CompanyProfileModel,
  AccountsModel,
  ConfirmAdminModel,
  UpdatePartnerAdminEmailModel,
  ForgotPasswordModel,
  ResetPasswordModel,
  AdministrationPageModel,
  PageRoutes,
  PaymentsAndDocumentsModel,
  CreateBannerPageModel,
} from '../internal';
import { SeverityType } from '../components/UI/alert';
import i18n from '../i18n';
import { LINK_EXPIRED } from '../constants';
import { StatusCodes } from '../constants/statusCodes';

// in milliseconds
const TOKEN_REFRESH_INTERVAL =
  Number(process.env.REACT_APP_TOKEN_REFRESH_INTERVAL) || 1800_000;

export const RootModel: IAnyModelType = types
  .model({
    isDev: false,
    pages: types.optional(
      types.map(
        types.union(
          PageModel,
          CreateOfferPageModel,
          CreateOfferSuccessPageModel,
          LoginModel,
          PartnerOffersPageModel,
          AllAdsModel,
          BannersPageModel,
          PartnerProfilesModel,
          AdDetailsModel,
          CompanyProfileModel,
          AccountsModel,
          ConfirmAdminModel,
          UpdatePartnerAdminEmailModel,
          ForgotPasswordModel,
          ResetPasswordModel,
          AdministrationPageModel,
          PaymentsAndDocumentsModel,
          CreateBannerPageModel
        )
      ),
      {}
    ),
    router: RouterModel,
    header: HeaderModel,
    api: ApiModel,
    axios: AxiosModel,
    toasts: types.map(ToastModel),
    geolocation: GeolocationModel,
    data: SharedDataModel,
    user: UserModel,
    isLoggedOut: false,
    language: 'rs',
  })
  .views((self) => {
    return {
      get currentPageJSON(): PageType {
        return (
          self.pages.get(self.router?.currentView.id) as ModelInstanceType<
            any,
            any
          >
        ).toJSON();
      },
      get currentPage(): PageType {
        return self.pages.get(
          self.router?.currentView?.id
        ) as ModelInstanceType<any, any>;
      },
      get pagesArray(): PageType[] {
        return Array.from(self.pages.values());
      },
      get toastsArray(): ToastModelType[] {
        return Array.from(self.toasts.values());
      },
      get errorHandlerMap(): any {
        const retVal: any = {};
        retVal[LINK_EXPIRED] = i18n.t('alert:link_expired');
        return retVal;
      },
    };
  })
  .actions((self) => {
    return {
      afterCreate() {
        self.isDev = process.env.NODE_ENV === 'development';
        if (self.isDev) {
          Object.defineProperty(window, 'toJS', {
            get() {
              return toJS;
            },
          });

          Object.defineProperty(window, 'getSnapshot', {
            get() {
              return getSnapshot;
            },
          });
          Object.defineProperty(window, 'rootInstance', {
            get() {
              return self;
            },
          });
          Object.defineProperty(window, 'comps', {
            get() {
              return self.currentPage.componentsArray;
            },
          });
          Object.defineProperty(window, 'values', {
            get() {
              return Object.values(self.currentPage.components.toJSON()).map(
                (comp: any) => comp.value
              );
            },
          });
          Object.defineProperty(window, 'cpage', {
            get() {
              return self.currentPage;
            },
          });
        }
        if (!self.router.isMaintenancePage) {
          this.initTokenRefreshMechanism();
        }
      },
      initTokenRefreshMechanism() {
        setInterval(async () => {
          // if the token is still valid, then try to refresh it
          // to prevent session timeout
          if (self.user.isTokenValid) {
            await self.api.admin.refreshTokenSilently();
          }
        }, TOKEN_REFRESH_INTERVAL);
      },
      addPage(page: PageType) {
        self.pages.put(page);
      },
      addToast(toast: ToastModelType) {
        self.toasts.put(toast);
        return self.toasts.get(toast.id);
      },
      removeToast(id: string) {
        self.toasts.delete(id);
      },
      clearToasts() {
        self.toasts.clear();
      },
      setUser(user: UserType) {
        self.user = user;
      },
      showFailure(message: string, content: any, duration?: number) {
        const toast = this.addToast(
          ToastModel.create({
            id: shortId(),
            message,
            severity: SeverityType.Danger,
            duration: duration ?? 5000,
          })
        );
        toast?.setContent(content);
      },
      setIsLoggedOut(isLoggedOut: boolean) {
        self.isLoggedOut = isLoggedOut;
      },
      showSuccess(message: string, content: any, duration?: number) {
        const toast = this.addToast(
          ToastModel.create({
            id: shortId(),
            message,
            severity: SeverityType.Success,
            duration: duration ?? 5000,
          })
        );
        toast?.setContent(content);
      },
      showInfo(message: string, content: any, duration?: any) {
        const toast = this.addToast(
          ToastModel.create({
            id: shortId(),
            message,
            severity: SeverityType.Info,
            duration: duration ?? 10000,
          })
        );
        toast?.setContent(content);
      },
      mapUserPasswordErrors(err: any) {
        if (err.response.status === StatusCodes.NOT_FOUND) {
          return i18n.t('alert:entity_not_found');
        }
        if (err.response.status === StatusCodes.BAD_REQUEST) {
          const description = err?.response?.data?.error?.description;
          const translatedMessage = self.errorHandlerMap[description];
          if (translatedMessage) {
            return translatedMessage;
          }
        }
        return i18n.t('alert:generic_error');
      },
      async currentPageBeforeExit() {
        const page = self.currentPage as any;
        if (!page?.beforePageExit) {
          return true;
        }
        try {
          return await page.beforePageExit();
        } catch (err) {
          return false;
        }
      },
      async reset() {
        /**
         * This block of code currently only runs for the CreateOfferPage
         * as it is the only one that tries to stop the user from leaving
         * This logic may need to be updated if we choose to add additional pages
         * with the leave confirmation dialog
         */
        const shouldLogout = await this.currentPageBeforeExit();
        if (!shouldLogout) {
          return;
        }
        (self.currentPage as any)?.disableLeaveWarning?.();

        self.user.reset();
        self.header.reset();
        localStorage.clear();
        this.setIsLoggedOut(true);
        await self.router.navigate({ newView: PageRoutes.Login.id });

        // we reset the data last, on purpose, otherwise all sorts of logout errors pop up
        self.data.reset();
      },
    };
  });
