import { observer } from 'mobx-react';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Tabs } from '../../../components/UI/tabs/Tabs';
import { useMst, PageRoutes } from '../../../internal';
import styles from './PartnerOffers.module.scss';
import { PartnerAdList } from '../../../components/domain/partner-ad-list/PartnerAdList';
import React from 'react';

export const PartnerOffers = observer((): ReactElement => {
  const { t } = useTranslation();
  const {
    currentPage,
    router: { navigate },
  } = useMst();

  const tabNoTextMap: Record<string, () => JSX.Element> = {
    active: () => (
      <>
        <p>{t('basic:no_active_partner_offers')}</p>
        <p>
          {t('basic:please_create_offer_in_section')}
          &nbsp;&nbsp;
          <span
            className={styles.Link}
            onClick={() => navigate({ newView: PageRoutes.CreateOffer.id })}
          >
            &quot;{t('basic:create_offer')}&quot;
          </span>
        </p>
      </>
    ),
    expired: () => <p>{t('basic:no_expired_partner_offers')}</p>,
    submitted: () => <p>{t('basic:no_submitted_partner_offers')}</p>,
    rejected: () => <p>{t('basic:no_rejected_partner_offers')}</p>,
    prep: () => <p>{t('basic:no_prep_partner_offers')}</p>,
  };

  return (
    <div className={styles.PageContainer}>
      <div className={styles.PageTitleContainer}>
        <div className={styles.PageTitle}>{t('basic:all_offers_view')}</div>
      </div>
      <div className={styles.CenteredTabsContainer}>
        <Tabs model={currentPage.getTabs()} />
      </div>
      <div className={styles.AdListOuterContainer}>
        <PartnerAdList
          model={currentPage.getAdList()}
          noElementsComponent={
            tabNoTextMap?.[currentPage?.getTabs()?.selectedTab]?.() ?? <></>
          }
        />
      </div>
    </div>
  );
});
