import { observer } from 'mobx-react';
import styles from './PaymentSummary.module.scss';
import { useMst } from '../../../internal';

export const PaymentSummary = observer(() => {
  const { currentPage } = useMst();
  const backToFirstStep = () => {
    currentPage.backToFirstStep();
  };
  const backToSecondStep = () => {
    currentPage.backToSecondStep();
  };
  return (
    <table className={styles.Table}>
      <thead>
        <tr>
          <th className={styles.FirstColumn}>Usluga</th>
          <th className={styles.SecondColumn}>Broj dana trajanja kampanje</th>
          <th className={styles.ThirdColumn}>Cena</th>
          <th className={styles.FourthColumn}></th>
        </tr>
      </thead>
      <tbody>
        {/* SEVICES */}
        <tr>
          <td className={styles.FirstColumn}>Osnovno oglašavnje</td>
          <td className={styles.SecondColumn}>
            {currentPage.offerPriceCheck.data.number_of_days}
          </td>
          <td className={styles.ThirdColumn}>
            {currentPage.offerPriceCheck.data.price_basic_per_day} RSD
          </td>
          <td className={styles.FourthColumn}>
            {currentPage.offerPriceCheck.data.price_basic_total} RSD
          </td>
        </tr>
        {currentPage.components.get('UserTargetCheckboxGroup')
          ?.checkboxesArray[0].isChecked ? (
          <tr>
            <td className={styles.FirstColumn}>
              Segmentacija i DNA{' '}
              <span
                className={styles.EditLinkContainer}
                onClick={backToSecondStep}
              >
                Izmeni
              </span>
            </td>
            <td className={styles.SecondColumn}>
              {currentPage.offerPriceCheck.data.number_of_days}
            </td>
            <td className={styles.ThirdColumn}>
              {currentPage.offerPriceCheck.data.price_targeted_per_day} RSD
            </td>
            <td className={styles.FourthColumn}>
              {currentPage.offerPriceCheck.data.price_targeted_total} RSD
            </td>
          </tr>
        ) : null}

        {currentPage.offerPriceCheck.data.price_top_total ? (
          <tr>
            <td className={styles.FirstColumn}>
              Top oglas{' '}
              <span
                className={styles.EditLinkContainer}
                onClick={backToFirstStep}
              >
                Izmeni
              </span>
            </td>
            <td className={styles.SecondColumn}>
              {currentPage.offerPriceCheck.data.number_of_days}
            </td>
            <td className={styles.ThirdColumn}>
              {currentPage.offerPriceCheck.data.price_top_per_day} RSD
            </td>
            <td className={styles.FourthColumn}>
              {currentPage.offerPriceCheck.data.price_top_total} RSD
            </td>
          </tr>
        ) : null}

        <tr>
          <td className={`${styles.Bold} ${styles.FirstColumn}`}>Ukupno</td>
          <td className={styles.SecondColumn}></td>
          <td className={styles.ThirdColumn}></td>
          <td className={styles.FourthColumn}>
            {currentPage.offerPriceCheck.data.subtotal} RSD
          </td>
        </tr>
        {/* DISCOUNTS */}

        {/* OTKOMENTARISATI PO POTREBI NAKON PROVERE SA YETTEL-om */}

        {/* {currentPage.offerPriceCheck.data
          .discount_quantity_amount && (
          <tr>
            <td className={styles.FirstColumn}>
              Popust na prvu kampanju{' '}
              {
                currentPage.offerPriceCheck.data
                  .discount_quantity_value
              }
              %
            </td>
            <td className={styles.SecondColumn}></td>
            <td className={styles.ThirdColumn}></td>
            <td className={styles.FourthColumn}>
              -
              {
                currentPage.offerPriceCheck.data
                  .discount_quantity_amount
              }{' '}
              RSD
            </td>
          </tr>
        )} */}
        {currentPage.offerPriceCheck.data.discount_telco_amount ? (
          <tr>
            <td className={styles.FirstColumn}>
              Popust za Yettel korisnike{' '}
              {currentPage.offerPriceCheck.data.discount_telco_value}%
            </td>
            <td className={styles.SecondColumn}></td>
            <td className={styles.ThirdColumn}></td>
            <td className={styles.FourthColumn}>
              -{currentPage.offerPriceCheck.data.discount_telco_amount} RSD
            </td>
          </tr>
        ) : null}

        {currentPage.offerPriceCheck.data.discount_soho_amount && (
          <tr>
            <td className={styles.FirstColumn}>
              Popust na SOHO{' '}
              {currentPage.offerPriceCheck.data.discount_soho_value}%
            </td>
            <td className={styles.SecondColumn}></td>
            <td className={styles.ThirdColumn}></td>
            <td className={styles.FourthColumn}>
              -{currentPage.offerPriceCheck.data.discount_soho_amount} RSD
            </td>
          </tr>
        )}

        <tr>
          <td className={`${styles.Bold} ${styles.FirstColumn}`}>
            Ukupno za naplatu po postavljenoj kampanji
          </td>
          <td className={styles.SecondColumn}></td>
          <td className={styles.ThirdColumn}></td>
          <td className={`${styles.Bold} ${styles.FourthColumn}`}>
            {currentPage.offerPriceCheck.data.total} RSD
          </td>
        </tr>
      </tbody>
    </table>
  );
});
