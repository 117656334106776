import { getRoot, Instance, types } from 'mobx-state-tree';
import i18n from '../../../i18n';
import {
  AccountListFiltersModel,
  EditAccountModalModel,
  ButtonModel,
  ConfirmationModalModel,
  CreateAccountModel,
  CreatedAccountListModel,
  DropdownModel,
  DropdownOptionModel,
  ModalModel,
  PageModel,
  PaginationModel,
  RootType,
  TabModel,
  TabsModel,
  TextInputModel,
  DropdownOptionModelType,
  DurationModel,
  AdminPublic,
  CheckboxGroupModel,
  CheckboxModel,
  ChangePacketModalModel,
  BillingModalModel,
  TextAreaInputModel,
  ApproveRejectPackageModalModel,
} from '../../../internal';
import { PAGE_SIZE } from '../../../utils/page';
import { smoothlyScrollToPageTop } from '../../../utils/scroll';
import {
  SUBSCRIPTION_WITH_CONTRACT,
  SUBSCRIPTION_WITHOUT_CONTRACT,
} from '../../../utils/constants';
import { PROMO_PACKAGE_ID } from '../../../constants/packageIds';

export const AccountsModel = types
  .compose(PageModel, types.model({}))
  .views((self) => {
    return {
      get root(): RootType {
        return getRoot(self);
      },
    };
  })
  .actions((self) => {
    return {
      async beforePageEnter() {
        await this.initCatalogs();

        return Promise.all([
          this.initCategories(),
          this.initCompanies(),
          this.initSalesmen(),
          this.loadData(),
        ]);
      },

      getCompanyName(partnerId: string) {
        const options =
          this.getCreatedAccountList()?.filters?.company?.optionsArray;
        return options?.filter?.(
          (opt: DropdownOptionModelType) => opt?.id === partnerId
        )?.[0]?.data?.legal_name;
      },
      async loadData(resetPagination = true) {
        if (resetPagination) {
          this.resetPagination();
        }
        await this.managePagination();
        const response = await self.root.api.partner.getAdminAccounts(
          this.generateFilter()
        );
        this.getCreatedAccountList().setAccounts(self.root.data.accounts);
        return response;
      },
      async downloadCSV() {
        const response = await self.root.api.partner.exportPartners();
        self.root.data.setCsvString(response);
      },
      getCsvData() {
        return self.root.data.csvData || '';
      },
      generateFilterWithoutPagination() {
        return {
          partner_main_account: [1],
          ...this.generateFieldFilters(),
          ...this.generateSortFilter(),
        };
      },
      generateFilter() {
        return {
          partner_main_account: [1],
          ...this.generatePagination(),
          ...this.generateFieldFilters(),
          ...this.generateSortFilter(),
        };
      },
      generateSortFilter() {
        const orderBy = this.getCreatedAccountList()?.orderBy;
        const order = this.getCreatedAccountList()?.order;
        return {
          ...(orderBy ? { order_by: orderBy } : {}),
          ...(order ? { order: order } : {}),
        };
      },
      generateFieldFilters() {
        const filters = this?.getCreatedAccountList()?.filters;
        const partnerId = filters?.company?.value?.trim?.();
        const category = filters?.category?.value.trim?.();
        const status = filters?.status?.value.trim?.();
        const pib = filters?.pib?.value?.trim?.();
        const partnershipExpert = filters?.partnershipExpert?.value?.trim?.();
        const segmentation = filters?.segmentation?.value?.trim?.();
        return {
          ...(partnerId ? { id: partnerId } : {}),
          ...(category ? { category: category } : {}),
          ...(status ? { status: status } : {}),
          ...(pib ? { pib: pib } : {}),
          ...(partnershipExpert
            ? { partnership_expert_id: partnershipExpert }
            : {}),
          ...(segmentation ? { segmentation } : {}),
        };
      },
      generatePagination(): { offset: number; limit: number } {
        let currentPage = this.getCreatedAccountList()?.pagination?.currentPage;
        currentPage = currentPage ? currentPage : 1;
        return {
          offset: (currentPage - 1) * PAGE_SIZE,
          limit: PAGE_SIZE,
        };
      },
      async managePagination() {
        const totalCount = await self.root.api.partner.getAdminAccountsCount(
          this.generateFilterWithoutPagination()
        );
        const totalPages = Math.ceil(totalCount / PAGE_SIZE);
        this.getCreatedAccountList().pagination.setTotalPages(totalPages);
        const currentPage = this.getCreatedAccountList().pagination.currentPage;
        // remain on current page if the number of elements shrank so that it doesn't exist
        this.getCreatedAccountList().pagination.setCurrentPage(
          currentPage > totalPages ? totalPages : currentPage
        );
      },
      resetPagination() {
        this?.getCreatedAccountList()?.pagination?.setCurrentPage(1);
      },
      // clears partner from user model if non existing
      clearPartnerIfNonExisting(partnerId: string) {
        const partner = self?.root?.user?.partner;
        if (partner?.id === partnerId) {
          self?.root?.user?.clearPartner();
        }
      },

      async initSalesmen() {
        const response = await self.root.api.admin.getAllSalesmen();
        self.root.data.setSellers(response.data);
        const options = response.data?.map((option: any) => ({
          data: { ...option },
          value: `${option.first_name} ${option.last_name}`,
          id: option.id,
        }));

        this.getCreateAccount()?.partnershipExpert?.setOptions?.(options, true);
        this.getCreatedAccountList()?.editModal?.partnershipExpert?.setOptions?.(
          options,
          false
        );

        this.getCreatedAccountList()?.filters?.partnershipExpert?.setOptions?.(
          [
            {
              id: ' ',
              value: ' ',
              data: {},
            },
            ...options,
          ],
          false
        );

        if (self?.root?.user?.isSalesman) {
          this.getCreateAccount()?.partnershipExpert?.setValue(
            self?.root?.user?.admin?.id ?? ''
          );
        }
        return response;
      },
      getPartnershipExpert(id: string) {
        return self.root.data.sellers.find((s: AdminPublic) => s.id === id);
      },
      async initCompanies() {
        const response = await self.root.api.partner.getAllPartners();
        const options = response.data?.map((option: any) => ({
          data: { ...option },
          value: option.name,
          id: option.id,
        }));
        this.getCreatedAccountList()?.filters?.company?.setOptions?.(
          [
            {
              id: ' ',
              value: ' ',
              data: {},
            },
            ...options,
          ],
          false
        );
        return response;
      },
      async initCatalogs() {
        const response = await self.root.api.partner.getAllCatalogs();
        const options = response.data
          ?.filter(
            (item: any) =>
              item.calculator !== 'subscription-with-contract-obligation'
          )
          .map((option: any) => ({
            data: { ...option },
            value: i18n.t(`dropdown:${option.calculator}`),
            id: option.id,
          }));
        const indexOfPromoPackage = options.findIndex(
          (option: any) => option.id === PROMO_PACKAGE_ID
        );
        if (indexOfPromoPackage !== -1) {
          const promoPackageOption = options.splice(indexOfPromoPackage, 1)[0];
          options.unshift(promoPackageOption);
        }
        this.getCreateAccount()?.catalogs?.setOptions?.(options, true);
        this.getCreatedAccountList()?.editModal?.catalogs?.setOptions?.(
          options,
          false
        );
        return response;
      },
      async uploadContract(
        file: File,
        partnerId: string,
        successCallback?: () => void
      ) {
        return new Promise((resolve) => {
          self.root.axios
            .uploadFile({
              path: '',
              fileKey: 'file',
              file,
              params: {
                ...(partnerId ? { partner_id: partnerId } : {}),
              },
              customErrorMessage: () => i18n.t('basic:upload_pdf_error'),
            })
            .then((response: any) => {
              resolve(response);
              if (response.statusCode === 200) {
                successCallback?.();
              }
            });
        });
      },
      async initCategories() {
        const response = await self.root.api.category.getAllCategories('abc');
        const options = response.data?.map((option: any) => ({
          data: { ...option },
          value: option.name,
          id: option.id,
        }));
        this.getCreatedAccountList()?.filters?.category?.setOptions?.(
          [
            {
              id: ' ',
              value: ' ',
              data: {},
            },
            ...options,
          ],
          false
        );
        this.getCreateAccount()?.category?.setOptions?.(options, false);
        this.getCreatedAccountList()?.editModal?.category?.setOptions?.(
          options,
          false
        );
        return response;
      },

      switchToCreateAccount() {
        this.getTabs().selectTab('createPartnerAccount');
        this.getCreateAccount().clear();
        smoothlyScrollToPageTop();
      },
      getTabs() {
        return (
          self.components.get('Tabs') ||
          self.addComponent(
            TabsModel.create({
              id: 'Tabs',
              selectedTab: 'createdAccountList',
              onChangeCallback: (id: string) => {
                if (id === 'createPartnerAccount') {
                  this.getCreateAccount().clear();
                }
              },
              tabs: {
                createdAccountList: TabModel.create({
                  id: 'createdAccountList',
                  name: i18n.t('tabs:created_account_list'),
                }),
                createPartnerAccount: TabModel.create({
                  id: 'createPartnerAccount',
                  name: i18n.t('tabs:create_partner_account'),
                }),
              },
            })
          )
        );
      },

      generateAccountFields(editMode?: boolean) {
        return {
          company: TextInputModel.create({
            id: 'company',
            label: 'basic:company_name',
            isRequired: true,
          }),
          // contractIdExternal: TextInputModel.create({
          //   id: 'company',
          //   label: 'basic:contractIdExternal',
          //   isRequired: true,
          // }),
          // sapId: TextInputModel.create({
          //   id: 'company',
          //   label: 'basic:sapId',
          //   isRequired: true,
          // }),
          pib: TextInputModel.create({
            id: 'pib',
            label: 'basic:pib',
            type: 'number',
            isRequired: true,
            inputFieldArrowsHidden: true,
          }),
          uniqueNumber: TextInputModel.create({
            id: 'uniqueNumber',
            label: 'basic:unique_number',
            type: 'number',
            isRequired: true,
            inputFieldArrowsHidden: true,
          }),
          hasYettelPay: DropdownModel.create({
            id: 'hasYettelPay',
            label: 'basic:has_yettel_pay',
            initialValue: 'no',
            options: {
              no: DropdownOptionModel.create({
                id: 'no',
                value: 'basic:no',
              }),
              yes: DropdownOptionModel.create({
                id: 'yes',
                value: 'basic:yes',
              }),
            },
          }),
          companyLegalForm: DropdownModel.create({
            id: 'companyLegalForm',
            label: 'basic:company_legal_form',
            isRequired: true,
            initialValue: 'doo',
            options: {
              ad: DropdownOptionModel.create({
                id: 'ad',
                value: 'dropdown:shareholder_company',
              }),
              jp: DropdownOptionModel.create({
                id: 'jp',
                value: 'dropdown:enterpreneur',
              }),
              doo: DropdownOptionModel.create({
                id: 'doo',
                value: 'dropdown:limited_responsibility_company',
              }),
              pr: DropdownOptionModel.create({
                id: 'pr',
                value: 'dropdown:public_company',
              }),
              su: DropdownOptionModel.create({
                id: 'su',
                value: 'dropdown:sports_association',
              }),
              z: DropdownOptionModel.create({
                id: 'z',
                value: 'dropdown:collective',
              }),
              pg: DropdownOptionModel.create({
                id: 'pg',
                value: 'dropdown:agriculture',
              }),
            },
          }),
          taxPayer: DropdownModel.create({
            id: 'taxPayer',
            label: 'basic:tax_payer',
            initialValue: '1',
            isRequired: true,
            options: {
              '1': DropdownOptionModel.create({
                id: '1',
                value: 'dropdown:yes',
              }),
              '0': DropdownOptionModel.create({
                id: '0',
                value: 'dropdown:no',
              }),
            },
          }),
          contractType: DropdownModel.create({
            id: 'contractType',
            label: 'basic:contract_type',
            initialValue: 'order',
            isRequired: true,
            options: {
              order: DropdownOptionModel.create({
                id: 'order',
                value: 'dropdown:order_contract_type',
              }),
              contract: DropdownOptionModel.create({
                id: 'contract',
                value: 'dropdown:contract_contract_type',
              }),
            },
          }),
          tariffPackage: DropdownModel.create({
            id: 'tariffPackage',
            label: 'basic:tariff_package',
            initialValue: 'promo-package',
            isRequired: true,
            options: {
              promo: DropdownOptionModel.create({
                id: 'promo-package',
                value: 'dropdown:promo-package',
              }),
              order: DropdownOptionModel.create({
                id: 'monthly_package',
                value: 'dropdown:monthly',
              }),
              contract: DropdownOptionModel.create({
                id: 'pay_per_ad',
                value: 'dropdown:pay_per_ad',
              }),
            },
          }),
          payPerAdPackageAddition: CheckboxGroupModel.create({
            id: 'PayPerAdPackageAddition',
            selectedCheckboxes: {},
            checkboxes: {
              PAY_PER_AD1: CheckboxModel.create({
                id: 'telco',
                label: `Telco popust ${self.root.data.payAsYouGoCatalog.discount_telco}%`,
              }),
              PAY_PER_AD2: CheckboxModel.create({
                id: 'soho',
                label: `SOHO popust ${self.root.data.payAsYouGoCatalog.discount_soho}%`,
              }),
            },
          }),
          monthlyPackageAddition: CheckboxGroupModel.create({
            id: `MonthlyPackageAddition${editMode ? 'Edit' : ''}`,
            selectedCheckboxes: {},
            checkboxes: {
              MONTHLY_PAYMENT1: CheckboxModel.create({
                id: 'telco',
                label: `Telco popust ${self.root.data.monthlyPaymentWithContractCatalog.discount_telco}%`,
              }),
              MONTHLY_PAYMENT2: CheckboxModel.create({
                id: 'so',
                label: `UO popust ${self.root.data.monthlyPaymentWithContractCatalog.discount_so}%`,
              }),
              MONTHLY_PAYMENT3: CheckboxModel.create({
                id: 'BTL',
                label: 'BTL popust',
              }),
            },
          }),
          contractDuration: DurationModel.create({
            id: 'contractDuration',
            fromDate: new Date(),
            toDate: new Date(),
            modal: ModalModel.create({
              id: 'modal',
              label: 'Contract Duration',
              opened: false,
              showCloseButton: true,
            }),
          }),
          numberOfEmployees: DropdownModel.create({
            id: 'numberOfEmployees',
            label: 'basic:number_of_employees',
            initialValue: '0',
            options: {
              '0': DropdownOptionModel.create({
                id: '0',
                value: '0',
              }),
              '1-50': DropdownOptionModel.create({
                id: '1-50',
                value: '1-50',
              }),
              '51-500': DropdownOptionModel.create({
                id: '51-500',
                value: '51-500',
              }),
              '500+': DropdownOptionModel.create({
                id: '500+',
                value: '500+',
              }),
            },
          }),
          segmentation: DropdownModel.create({
            id: 'segmentation',
            label: 'basic:segmentation',
            initialValue: 'SoHo',
            isRequired: true,
            placeholder: 'basic:pick',
            options: {
              a: DropdownOptionModel.create({
                id: 'A brend',
                value: 'A brend',
              }),
              b: DropdownOptionModel.create({
                id: 'B brend',
                value: 'B brend',
              }),
              soho: DropdownOptionModel.create({
                id: 'SoHo',
                value: 'Soho',
              }),
            },
          }),
          category: DropdownModel.create({
            id: 'category',
            label: 'basic:category',
            isRequired: true,
            placeholder: 'basic:pick',
            options: {},
          }),
          catalogs: DropdownModel.create({
            id: 'catalogs',
            label: 'basic:catalogs',
            isRequired: true,
            placeholder: 'basic:pick',
            options: {},
            onChangeCallback: () => {
              this.getCreateAccount().deselectCheckBoxes();
              this.getCreateAccount().btlDiscount.clearData();
            },
          }),
          street: TextInputModel.create({
            id: 'street',
            label: 'basic:company_address_street',
            isRequired: true,
          }),
          streetNumber: TextInputModel.create({
            id: 'streetNumber',
            label: 'basic:company_address_street_number',
            isRequired: true,
          }),
          apartmentNumber: TextInputModel.create({
            id: 'apartmentNumber',
            label: 'basic:company_address_apartment_number',
            isRequired: false,
          }),
          billingAddressDropdown: DropdownModel.create({
            id: 'billingAddressDropdown',
            label: 'basic:billing_address_dropdown',
            initialValue: 'sameAddress',
            isRequired: true,
            options: {
              sameAddress: DropdownOptionModel.create({
                id: 'sameAddress',
                value: 'dropdown:same_address',
              }),
              other: DropdownOptionModel.create({
                id: 'other',
                value: 'dropdown:other',
              }),
            },

            onChangeCallback: (props: any) => {
              if (props.id === 'sameAddress') {
                self.components
                  .get('CreateAccount')
                  .billingAddressStreet.setIsRequired(false);
                self.components
                  .get('CreateAccount')
                  .billingAddressStreetNumber.setIsRequired(false);
                self.components
                  .get('CreateAccount')
                  .billingAddressCity.setIsRequired(false);
                self.components
                  .get('CreateAccount')
                  .billingAddressZip.setIsRequired(false);
                self.components
                  .get('CreateAccount')
                  .billingAddressPak.setIsRequired(false);
              } else {
                self.components
                  .get('CreateAccount')
                  .billingAddressStreet.setIsRequired(true);
                self.components
                  .get('CreateAccount')
                  .billingAddressStreetNumber.setIsRequired(true);
                self.components
                  .get('CreateAccount')
                  .billingAddressCity.setIsRequired(true);
                self.components
                  .get('CreateAccount')
                  .billingAddressZip.setIsRequired(true);
                self.components
                  .get('CreateAccount')
                  .billingAddressPak.setIsRequired(true);
              }
            },
          }),

          uploadDocumentBtn: ButtonModel.create({
            id: 'uploadDocumentBtn',
            label: 'basic:upload_document',
          }),
          billingAddress: TextInputModel.create({
            id: 'billingAddress',
            label: editMode
              ? 'basic:billing_address_street_edit'
              : 'basic:billing_address_street',
            isRequired: editMode ? true : false,
          }),
          billingAddressStreet: TextInputModel.create({
            id: 'billingAddressStreet',
            label: 'basic:billing_address_street_name',
            isRequired: editMode ? true : false,
          }),
          billingAddressStreetNumber: TextInputModel.create({
            id: 'billingAddressStreetNumber',
            label: 'basic:billing_address_street_number',
            isRequired: editMode ? true : false,
          }),
          billingAddressApartmentNumber: TextInputModel.create({
            id: 'billingAddressApartmentNumber',
            label: 'basic:billing_address_apartment_number',
            isRequired: false,
          }),
          billingAddressCity: TextInputModel.create({
            id: 'billingAddressCity',
            label: 'basic:billing_city',
            isRequired: editMode ? true : false,
          }),
          billingAddressZip: TextInputModel.create({
            id: 'billingAddressZip',
            label: 'basic:billing_zip',
            isRequired: editMode ? true : false,
          }),
          billingAddressPak: TextInputModel.create({
            id: 'billingAddressPak',
            label: 'company_profile:pak',
            isRequired: editMode ? true : false,
            type: 'number',
            inputFieldArrowsHidden: true,
          }),
          partnershipExpert: DropdownModel.create({
            id: 'partnershipExpert',
            options: {},
            label: 'basic:partnership_expert',
            isRequired: true,
          }),
          city: TextInputModel.create({
            id: 'city',
            label: 'basic:city',
            isRequired: true,
          }),
          zip: TextInputModel.create({
            id: 'zip',
            label: 'basic:zip',
            isRequired: true,
            type: 'number',
            inputFieldArrowsHidden: true,
          }),
          pak: TextInputModel.create({
            id: 'pak',
            label: 'company_profile:pak',
            isRequired: true,
            type: 'number',
            inputFieldArrowsHidden: true,
          }),
          email: TextInputModel.create({
            id: 'email',
            label: editMode
              ? 'basic:admin_email'
              : 'basic:email_for_opening_account',
            isRequired: true,
          }),
          adminFirstName: TextInputModel.create({
            id: 'adminFirstName',
            label: 'basic:admin_first_name',
            isRequired: true,
          }),
          adminLastName: TextInputModel.create({
            id: 'adminLastName',
            label: 'basic:admin_last_name',
            isRequired: true,
          }),
          adminPhoneNumber: TextInputModel.create({
            id: 'adminPhoneNumber',
            label: 'basic:admin_phone_number',
            type: 'number',
            inputFieldArrowsHidden: true,
            isRequired: true,
          }),
          btlDiscount: TextInputModel.create({
            placeholder: i18n.t('basic:btl_discount'),
            disabled: false,
            id: 'btlDiscount',
            type: 'number',
            inputFieldArrowsHidden: true,
            isEnabledFunc: () => this.getCreateAccount().isBTLSelected,
            isRequiredFunc: () => this.getCreateAccount().isBTLSelected,
          }),
        };
      },
      getCreateAccount() {
        return (
          self.components.get('CreateAccount') ||
          self.addComponent(
            CreateAccountModel.create({
              id: 'CreateAccount',
              ...this.generateAccountFields(),
              saveBtn: ButtonModel.create({
                id: 'saveBtn',
                label: 'basic:create_and_send',
              }),
            })
          )
        );
      },
      getCreatedAccountList() {
        return (
          self.components.get('CreatedAccountList') ||
          self.addComponent(
            CreatedAccountListModel.create({
              id: 'CreatedAccountList',
              deleteModal: ConfirmationModalModel.create({
                id: 'deleteModal',
                text: 'basic:are_you_sure',
                modal: ModalModel.create({
                  id: 'modal',
                  opened: false,
                  showCloseButton: true,
                }),
              }),
              editModal: EditAccountModalModel.create({
                id: 'EditAccountModalModel',
                modal: ModalModel.create({
                  id: 'modal',
                  opened: false,
                  showCloseButton: true,
                }),
                ...this.generateAccountFields(true),
                saveBtn: ButtonModel.create({
                  id: 'saveBtn',
                  label: 'basic:save_changes',
                  labelFunc: () => {
                    return this.getCreatedAccountList().editModal
                      .adminStatus === 'requested'
                      ? i18n.t('basic:approve')
                      : this.getCreatedAccountList().editModal.adminStatus ===
                          'active' ||
                        this.getCreatedAccountList().editModal.adminStatus ===
                          'inactive'
                      ? i18n.t('basic:save_changes')
                      : i18n.t('basic:create_and_send');
                  },
                }),
                rejectBtn: ButtonModel.create({
                  id: 'saveBtn',
                  label: 'basic:reject',
                }),
              }),
              changePacketModal: ChangePacketModalModel.create({
                id: 'ChangePacketModalModel',
                modal: ModalModel.create({
                  id: 'modal',
                  opened: false,
                  showCloseButton: true,
                  onAfterClose: (self: any) => {
                    self.root.currentPage
                      .getCreatedAccountList()
                      .changePacketModal.rejectionReasonTextArea.resetState();
                    self.root.currentPage
                      .getCreatedAccountList()
                      .changePacketModal.setShowRejectionReasonTextArea(false);
                  },
                }),
                packagesDropdown: DropdownModel.create({
                  id: 'changePacketModalPackagesDropdown',
                  label: 'Promeni tarifni paket',
                  isRequired: true,
                  options: {
                    'promo-package': DropdownOptionModel.create({
                      id: 'promo-package',
                      value: 'Promo period',
                    }),
                    'pay-as-you-go': DropdownOptionModel.create({
                      id: 'pay-as-you-go',
                      value: 'Plaćanje po oglasu',
                    }),
                    subscription: DropdownOptionModel.create({
                      id: 'subscription',
                      value: 'Mesečni tarifni paket',
                    }),
                  },
                }),
                changePacketModalDiscountsCheckboxGroupPayAsYouGo:
                  CheckboxGroupModel.create({
                    id: 'changePacketModalDiscountsCheckboxGroupPayAsYouGoCheckboxGroup',
                    checkboxes: {
                      telco: {
                        label: `Telco popust ${self.root.data.payAsYouGoCatalog.discount_telco}%`,
                        id: 'telco',
                      },
                      soho: {
                        label: `SOHO popust ${self.root.data.payAsYouGoCatalog.discount_soho}%`,
                        id: 'soho',
                      },
                    },
                    selectedCheckboxes: {},
                  }),
                changePacketModalDiscountsCheckboxGroupSubscription:
                  CheckboxGroupModel.create({
                    id: 'changePacketModalDiscountsCheckboxGroupSubscriptionCheckboxGroup',
                    checkboxes: {
                      telco: {
                        label: `Telco popust ${self.root.data.monthlyPaymentWithContractCatalog.discount_telco}%`,
                        id: 'telco',
                      },
                      uo: {
                        label: `UO popust ${self.root.data.monthlyPaymentWithContractCatalog.discount_so}%`,
                        id: 'uo',
                      },
                      btl: {
                        label: `BTL ${self.root.data.monthlyPaymentWithContractCatalog.discount_btl}%`,
                        id: 'btl',
                      },
                    },
                    selectedCheckboxes: {},
                  }),
                changePacketModalBtlInput: TextInputModel.create({
                  id: 'changePacketModalBtlInput',
                  type: 'number',
                }),
                rejectionReasonTextArea: TextAreaInputModel.create({
                  id: 'rejectionReasonTextArea',
                  label: 'Razlog',
                  placeholder: 'Nije postignut dogovor',
                  showButtons: false,
                  rows: 5,
                  resize: false,
                }),
              }),
              billingModal: BillingModalModel.create({
                id: 'billingModalModel',
                opened: false,
                showCloseButton: true,
                onAfterClose: (self: any) => {
                  self.billingModalDiscountsCheckboxGroupSubscriptionWithContract.deselectAll();
                  self.billingModalDiscountsCheckboxGroupSubscription.deselectAll();
                  self.billingModalDiscountsCheckboxGroupPayAsYouGo.deselectAll();
                  self.packagesDropdown.selectOption(0);
                  self.billingModalBtlInput.clearInput();
                },
                type: 'monthly',
                packagesDropdown: DropdownModel.create({
                  id: 'billingModalPackagesDropdown',
                  label: 'Tarifni paket',
                  isRequired: true,
                  options: {
                    'promo-package': DropdownOptionModel.create({
                      id: 'promo-package',
                      value: 'Promo period',
                    }),
                    'pay-as-you-go': DropdownOptionModel.create({
                      id: 'pay-as-you-go',
                      value: 'Plaćanje po oglasu',
                    }),
                    subscription: DropdownOptionModel.create({
                      id: 'subscription',
                      value: SUBSCRIPTION_WITHOUT_CONTRACT,
                    }),
                    'subscription-with-contract-obligation':
                      DropdownOptionModel.create({
                        id: 'subscription-with-contract-obligation',
                        value: SUBSCRIPTION_WITH_CONTRACT,
                      }),
                  },
                }),
                billingModalDiscountsCheckboxGroupSubscriptionWithContract:
                  CheckboxGroupModel.create({
                    id: 'billingModalDiscountsCheckboxGroupSubscriptionWithContractCheckboxGroup',
                    checkboxes: {
                      telco: {
                        label: `Telco popust ${self.root.data.monthlyPaymentWithContractCatalog.discount_telco}%`,
                        id: 'telco',
                      },
                      uo: {
                        label: `UO popust ${self.root.data.monthlyPaymentWithContractCatalog.discount_so}%`,
                        id: 'uo',
                      },
                      btl: {
                        label: 'BTL popust',
                        id: 'btl',
                      },
                    },
                    selectedCheckboxes: {},
                  }),
                billingModalDiscountsCheckboxGroupSubscription:
                  CheckboxGroupModel.create({
                    id: 'billingModalDiscountsCheckboxGroupSubscriptionCheckboxGroup',
                    checkboxes: {
                      telco: {
                        label: `Telco popust ${self.root.data.monthlyPaymentCatalog.discount_telco}%`,
                        id: 'telco',
                      },
                      btl: {
                        label: 'BTL popust',
                        id: 'btl',
                      },
                    },
                    selectedCheckboxes: {},
                  }),
                billingModalDiscountsCheckboxGroupPayAsYouGo:
                  CheckboxGroupModel.create({
                    id: 'billingModalDiscountsCheckboxGroupPayAsYouGoCheckboxGroup',
                    checkboxes: {
                      telco: {
                        label: `Telco popust ${self.root.data.payAsYouGoCatalog.discount_telco}%`,
                        id: 'telco',
                      },
                      soho: {
                        label: `SOHO popust ${self.root.data.payAsYouGoCatalog.discount_soho}%`,
                        id: 'soho',
                      },
                    },
                    selectedCheckboxes: {},
                  }),
                billingModalBtlInput: TextInputModel.create({
                  id: 'billingModalBtlInput',
                  type: 'number',
                }),
              }),
              approveRejectPackageModal: ApproveRejectPackageModalModel.create({
                id: 'approveRejectPackageModal',
                opened: false,
                showCloseButton: true,
              }),

              elements: {},
              pagination: PaginationModel.create({
                id: 'pagination',
                currentPage: 1,
                totalPages: 1,
                pageChangedCallback: (pageNum: number) => {
                  this.loadData(false);
                },
              }),
              filters: AccountListFiltersModel.create({
                id: 'filters',
                company: DropdownModel.create({
                  id: 'company',
                  label: 'basic:company',
                  placeholder: 'basic:pick',
                  options: {},
                  onChangeCallback: () => this.loadData(),
                }),
                partnershipExpert: DropdownModel.create({
                  id: 'partnershipExpert',
                  label: 'basic:partnership_expert',
                  placeholder: 'basic:pick',
                  options: {},
                  onChangeCallback: () => this.loadData(),
                }),
                category: DropdownModel.create({
                  id: 'category',
                  label: 'basic:category',
                  placeholder: 'basic:pick',
                  options: {},
                  onChangeCallback: () => this.loadData(),
                }),
                pib: TextInputModel.create({
                  id: 'pib',
                  label: 'basic:pib',
                  placeholder: i18n.t('basic:pib'),
                  options: {},
                  setValueCallback: () => this.loadData(),
                }),
                segmentation: DropdownModel.create({
                  id: 'segmentation',
                  label: 'Segment',
                  placeholder: 'basic:pick',
                  initialValue: ' ',
                  options: {
                    none: DropdownOptionModel.create({
                      id: '',
                      value: '',
                    }),
                    a: DropdownOptionModel.create({
                      id: 'A brend',
                      value: 'A brend',
                    }),
                    b: DropdownOptionModel.create({
                      id: 'B brend',
                      value: 'B brend',
                    }),
                    soho: DropdownOptionModel.create({
                      id: 'SoHo',
                      value: 'Soho',
                    }),
                  },
                  onChangeCallback: () => this.loadData(),
                }),
              }),
            })
          )
        );
      },
    };
  })
  .named('AccountsModel');

export type AccountsModelType = Instance<typeof AccountsModel>;
