import { getRoot, Instance, types } from 'mobx-state-tree';
import {
  ButtonModel,
  ComponentModel,
  DropdownModel,
  TextInputModel,
  DurationModel,
  PartnerAdminPublic,
  DropdownModelType,
  CheckboxGroupModel,
  UploadCodesModel,
  DropdownOptionModel,
  RoundButtonModel,
  CheckboxModel,
  ModalModel,
  RootType,
} from '../../../../internal';
import { dateFormatter } from '../../../../utils/date';

export const CreateAccountModel = types
  .compose(
    ComponentModel,
    types.model({
      company: types.late(() => TextInputModel),
      pib: types.late(() => TextInputModel),
      uniqueNumber: types.late(() => TextInputModel), // mbr
      hasYettelPay: types.late(() => DropdownModel),
      companyLegalForm: types.late(() => DropdownModel),
      taxPayer: types.late(() => DropdownModel),
      contractType: types.late(() => DropdownModel),
      tariffPackage: types.late(() => DropdownModel),
      payPerAdPackageAddition: types.late(() => CheckboxGroupModel),
      monthlyPackageAddition: types.late(() => CheckboxGroupModel),
      contractDuration: types.late(() => DurationModel),
      numberOfEmployees: types.late(() => DropdownModel),
      segmentation: types.late(() => DropdownModel),
      category: types.late(() => DropdownModel),
      catalogs: types.late(() => DropdownModel),
      street: types.late(() => TextInputModel),
      streetNumber: types.late(() => TextInputModel),
      apartmentNumber: types.late(() => TextInputModel),
      billingAddressDropdown: types.late(() => DropdownModel),
      billingAddressStreet: types.late(() => TextInputModel),
      billingAddressStreetNumber: types.late(() => TextInputModel),
      billingAddressApartmentNumber: types.late(() => TextInputModel),
      billingAddressCity: types.late(() => TextInputModel),
      billingAddressZip: types.late(() => TextInputModel),
      billingAddressPak: types.late(() => TextInputModel),
      partnershipExpert: types.late(() => DropdownModel),
      city: types.late(() => TextInputModel),
      zip: types.late(() => TextInputModel),
      pak: types.late(() => TextInputModel),
      email: types.late(() => TextInputModel),
      adminFirstName: types.late(() => TextInputModel),
      adminLastName: types.late(() => TextInputModel),
      adminPhoneNumber: types.late(() => TextInputModel),
      btlDiscount: types.late(() => TextInputModel),
      // sapId: types.late(() => TextInputModel),
      // contractIdExternal: types.late(() => TextInputModel),
      saveBtn: types.late(() => ButtonModel),
      uploadDocumentBtn: types.late(() => ButtonModel),
      files: types.map(types.frozen<File | null>(null)),
    })
  )
  .views((self) => {
    return {
      get isSameAddress(): boolean {
        return self?.billingAddressDropdown?.value === 'sameAddress';
      },
      get root(): RootType {
        return getRoot(self);
      },
      get filesArray(): File[] {
        return Array.from(self.files.values());
      },
      get isPayAsYouGoSelected(): boolean {
        return (
          self.catalogs.selectedOptionId === self.root.data.payAsYouGoCatalog.id
        );
      },
      get isPromoPeriodSelected(): boolean {
        return (
          self.catalogs.selectedOptionId === self.root.data.promoPeriod?.id
        );
      },
      get isMonthlyPaymentSelected(): boolean {
        return (
          self.catalogs.selectedOptionId ===
            self.root.data.monthlyPaymentCatalog.id && !this.isSOSelected
        );
      },
      get isMonthlyPaymentWithContractSelected(): boolean {
        return (
          self.catalogs.selectedOptionId ===
            self.root.data.monthlyPaymentCatalog.id && this.isSOSelected
        );
      },
      get selectedCatalog() {
        if (this.isPayAsYouGoSelected) {
          return self.root.data.payAsYouGoCatalog;
        }
        if (this.isPromoPeriodSelected) {
          return self.root.data.promoPeriodContractCatalog;
        }
        if (this.isMonthlyPaymentSelected) {
          return self.root.data.monthlyPaymentCatalog;
        }
        if (this.isMonthlyPaymentWithContractSelected) {
          return self.root.data.monthlyPaymentWithContractCatalog;
        }
      },
      get isSOSelected(): boolean {
        return self.monthlyPackageAddition?.selectedCheckboxesIds.includes(
          'so'
        );
      },
      get isSOHOSelected(): boolean {
        return self.payPerAdPackageAddition.selectedCheckboxesIds.includes(
          'soho'
        );
      },
      get isTelcoSelected(): boolean {
        return (
          self.monthlyPackageAddition.selectedCheckboxesIds.includes('telco') ||
          self.payPerAdPackageAddition.selectedCheckboxesIds.includes('telco')
        );
      },
      get isBTLSelected(): boolean {
        return self.monthlyPackageAddition.selectedCheckboxesIds.includes(
          'BTL'
        );
      },

      get account(): PartnerAdminPublic {
        return {
          category: self?.category?.value,
          pib: self?.pib?.value,
          mbr: self?.uniqueNumber?.value,
          address: `${self?.street?.value} ${self?.streetNumber?.value} ${
            self?.apartmentNumber?.value ? self?.apartmentNumber?.value : ''
          }`,
          street: self?.street?.value,
          street_no: self?.streetNumber?.value,
          app_no: self?.apartmentNumber?.value,
          billing_address: this.isSameAddress
            ? `${self?.street?.value} ${self?.streetNumber?.value} ${
                self?.apartmentNumber?.value ? self?.apartmentNumber?.value : ''
              }`
            : `${self?.billingAddressStreet?.value} ${
                self?.billingAddressStreetNumber?.value
              } ${
                self?.billingAddressApartmentNumber?.value
                  ? self?.billingAddressApartmentNumber?.value
                  : ''
              }`,
          billing_street: this.isSameAddress
            ? self?.street?.value
            : self?.billingAddressStreet?.value,
          billing_street_no: this.isSameAddress
            ? self?.streetNumber?.value
            : self?.billingAddressStreetNumber?.value,
          billing_app_no: this.isSameAddress
            ? self?.apartmentNumber?.value
            : self?.billingAddressApartmentNumber?.value,
          billing_city: this.isSameAddress
            ? self?.city?.value
            : self?.billingAddressCity?.value,
          billing_zip: this.isSameAddress
            ? self?.zip?.value
            : self?.billingAddressZip?.value,
          billing_pak: this.isSameAddress
            ? self?.pak?.value
            : self?.billingAddressPak?.value,
          city: self?.city?.value,
          zip: self?.zip?.value,
          pak: self?.pak?.value,
          email: self?.email?.value,
          pay: self.hasYettelPay.value === 'yes' ? 1 : 0,
          active: 1,
          legal_name: self?.company?.value,
          name: self?.company?.value,
          legal_type: self?.companyLegalForm?.value,
          taxpayer: self?.taxPayer?.value === '1' ? 1 : 0,
          employees: self?.numberOfEmployees?.value,
          segmentation: self?.segmentation?.value,
          cost_by: 'click',
          image: 'unknown',
          admin_email: self?.email?.value,
          contract_type: self?.contractType?.value,
          contract_from: dateFormatter(self?.contractDuration?.fromDate),
          contract_to: dateFormatter(self?.contractDuration?.toDate),
          admin_first_name: self?.adminFirstName?.value,
          admin_last_name: self?.adminLastName?.value,
          admin_phone: self?.adminPhoneNumber?.value,
          phone: self?.adminPhoneNumber?.value,
          partnership_expert_id: self?.partnershipExpert?.value,
          // sap_id: self?.sapId?.value,
          // contract_id_external: self?.contractIdExternal?.value,
          contract: {
            type: self?.contractType?.value,
            from: dateFormatter(self?.contractDuration?.fromDate),
            to: dateFormatter(self?.contractDuration?.toDate),
            catalog_id: this.isSOSelected
              ? self.root.data.monthlyContractId
              : self?.catalogs?.value,
            discount_telco: this.isTelcoSelected
              ? this.selectedCatalog.discount_telco
              : null,
            discount_soho: this.isSOHOSelected
              ? this.selectedCatalog.discount_soho
              : null,
            discount_so: this.isSOSelected
              ? this.selectedCatalog.discount_so
              : null,
            discount_btl: parseInt(self?.btlDiscount?.value),
          },
          agent_contact_email: self?.email?.value,
          admin: {
            first_name: self?.adminFirstName?.value,
            last_name: self?.adminLastName?.value,
            email: self?.email?.value,
            phone: self?.adminPhoneNumber?.value,
          },
        };
      },
      get components() {
        const comps: any[] = [
          self.company,
          self.pib,
          self.uniqueNumber,
          self.hasYettelPay,
          self.companyLegalForm,
          self.taxPayer,
          self.numberOfEmployees,
          self.segmentation,
          self.category,
          self.street,
          self.streetNumber,
          self.apartmentNumber,
          self.billingAddressStreet,
          self.billingAddressStreetNumber,
          self.billingAddressApartmentNumber,
          self.billingAddressCity,
          self.billingAddressZip,
          self.billingAddressPak,
          self.city,
          self.zip,
          self.pak,
          self.email,
          self.contractType,
          self.contractDuration,
          // self.sapId,
          // self.contractIdExternal,
          self.adminFirstName,
          self.adminLastName,
          self.adminPhoneNumber,
          self.btlDiscount,
        ];
        return comps;
      },
    };
  })
  .actions((self) => {
    return {
      clear() {
        self.company?.clearData();
        self.pib?.clearData();
        self.uniqueNumber?.clearData();
        this.resetDropdown(self.hasYettelPay);
        this.resetDropdown(self.companyLegalForm);
        this.resetDropdown(self.taxPayer);
        this.resetDropdown(self.numberOfEmployees);
        this.resetDropdown(self.segmentation);
        self.category?.clearData();
        this.resetDropdown(self.billingAddressDropdown);
        self.billingAddressStreet?.clearData();
        self.billingAddressStreetNumber?.clearData();
        self.billingAddressApartmentNumber?.clearData();
        self.billingAddressCity?.clearData();
        self.billingAddressZip?.clearData();
        self.billingAddressPak?.clearData();
        self.street?.clearData();
        self.streetNumber?.clearData();
        self.apartmentNumber?.clearData();
        self.city?.clearData();
        self.zip?.clearData();
        self.pak?.clearData();
        self.email?.clearData();
        // self.sapId?.clearData();
        // self.contractIdExternal?.clearData();

        self.adminFirstName?.clearData();
        self.adminLastName?.clearData();
        self.adminPhoneNumber?.clearData();

        this.resetDropdown(self.contractType);
        self.contractDuration?.clearData();
        self.btlDiscount?.clearData();
        self.partnershipExpert.setValue(
          self.partnershipExpert?.optionsArray.find(
            (option: any) => option.id === self?.root?.user?.admin?.id
          )?.id
        );
        this.deselectCheckBoxes();
        this.clearFiles();
      },
      deselectCheckBoxes() {
        self.monthlyPackageAddition.deselectAll();
        self.payPerAdPackageAddition.deselectAll();
      },
      resetDropdown(dropdownComp: DropdownModelType) {
        dropdownComp.setValue(
          dropdownComp.initialValue ?? dropdownComp?.optionsArray?.[0]?.id
        );
      },
      checkValidity() {
        let valid = true;
        for (const comp of self.components) {
          comp.runValidators();
          if (comp.isInvalid) {
            valid = false;
          }
        }
        return valid;
      },
      getUploadContractModel() {
        return (
          self.root.currentPage?.components.get('UploadCodesModel') ||
          self.root.currentPage?.addComponent(
            UploadCodesModel.create({
              id: 'UploadCodesModel',
              step: 2,
              isRequired: true,
              codeTypeDropdown: DropdownModel.create({
                id: 'UploadCodesModel',
                label: 'basic:code_type',
                isRequired: true,
                step: 2,
                onChangeCallback: (option: { id: string; value: string }) => {
                  const uploadCodesModel =
                    self.components?.get('UploadCodesModel');
                  if (option.id === 'personalized') {
                    // if the option is personalized, then the user has to pick
                    // from a checkbox container view
                    uploadCodesModel?.checkboxContainer?.setIsRequired(true);
                    uploadCodesModel?.textInputField?.setIsRequired(false);
                  } else {
                    // meaning 'common', then checkbox is irrelevant
                    // but the text code is everything
                    uploadCodesModel?.checkboxContainer?.setIsRequired(false);
                    uploadCodesModel?.textInputField?.setIsRequired(true);
                  }
                },
                options: {
                  1: DropdownOptionModel.create({
                    id: 'personalized',
                    value: 'basic:personalized_code_type',
                  }),
                  2: DropdownOptionModel.create({
                    id: 'common',
                    value: 'basic:same_code_type',
                  }),
                },
              }),
              buttonSend: RoundButtonModel.create({
                id: 'EnterCodesButtonSend',
                label: 'basic:send',
              }),
              textInputField: TextInputModel.create({
                id: 'EnterCodesInputNumber',
                label: 'Unesi kod',
                isRequired: true,
                step: 2,
                placeholder: 'AX34BH',
              }),
              buttonAddTable: RoundButtonModel.create({
                id: 'EnterCodesButtonAddTable',
                label: 'basic:add_table',
              }),
              buttonChange: ButtonModel.create({
                id: 'EnterCodesButtonChange',
                label: 'basic:change_table',
                onClickCallback: () => {
                  this.getUploadContractModel().openModal();
                },
              }),
              uploadCodesCount: 0,
              checkboxContainer: CheckboxGroupModel.create({
                id: 'CodeUploadTypesCreate',
                isRequired: true,
                step: 2,
                selectedCheckboxes: {},
                checkboxes: {
                  numeric: CheckboxModel.create({
                    id: 'numeric',
                    label: 'user_segments:numeric',
                  }),
                  bar: CheckboxModel.create({
                    id: 'bar',
                    label: 'user_segments:barcode',
                  }),
                  qr: CheckboxModel.create({
                    id: 'qr',
                    label: 'user_segments:qrcode',
                  }),
                },
              }),
              modal: ModalModel.create({
                id: 'UploadCodesModal',
                label: 'UploadCodes',
                opened: false,
                step: 2,
              }),
            })
          )
        );
      },
      setFiles(files: File[]) {
        Array.from(files).forEach((file) => {
          if (!self.files.has(file.name)) {
            self.files.set(file.name, file);
          } else {
            self.root.showFailure(`File already exists: ${file.name}`);
          }
        });
      },
      clearFiles() {
        self.files.clear();
      },
      removeFile(file: File) {
        self.files.delete(file.name);
      },
      async save(callback?: () => void) {
        if (self.checkValidity()) {
          const response = await self.root.api.partner.save(self.account, 1);
          const fileUploadPromises = self.filesArray.map((file: File) =>
            self.root.api.partnerDocument.uploadDocument(file, response.data.id)
          );
          await Promise.all(fileUploadPromises);
          callback?.();
        } else {
          console.log('TODO: error handling.');
        }
      },
    };
  })
  .named('CreateAccountModel');

export type CreateAccountModelType = Instance<typeof CreateAccountModel>;
