import { getRoot, types } from 'mobx-state-tree';
import { RootType } from '../../internal';
import { HttpVerbs } from '../../constants/verbs';

export const PurchasesApi = types
  .model('PurchasesApi', {})
  .views((self) => {
    return {
      get root(): RootType {
        return getRoot(self);
      },
    };
  })
  .actions((self) => {
    return {
      getPurchases(id: string) {
        return self.root.axios
          .requestData({
            type: HttpVerbs.GET,
            path: `/purchases/${id}`,
          })
          .then((response: any) => {
            self.root.data.setPurchases(response.data);
            return response;
          });
      },
      newPurchase(partner_id: string, contract_id: string) {
        return self.root.axios
          .requestData({
            type: HttpVerbs.POST,
            path: '/purchases',
            data: {
              partner_id,
              contract_id,
            },
          })
          .then((response: any) => {
            self.root.data.setPurchases(response.data);
            return response;
          });
      },
    };
  })
  .named('PurchasesApi');
