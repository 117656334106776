import * as React from 'react';
import { observer } from 'mobx-react';

import expirationStyles from '../ExpirationInfo.module.scss';

import Countdown from 'react-countdown';
import { useMst } from '../../../../internal';
import {
  isValidForLessThanOneMinute,
  getSecondsString,
  getHoursString,
  getMinutesString,
} from '../../../../utils/methods';
import { differenceInSeconds } from 'date-fns';

interface IOfferModel {
  model: { expiresIn: Date };
}

export const ExpirationCountdownComponent = observer((props: IOfferModel) => {
  const { model } = props;
  const {
    router: { isOfferDetailPage },
  } = useMst();

  const isExpired = () => {
    const currentTime = new Date().getTime();
    if (currentTime && model.expiresIn) {
      return differenceInSeconds(model.expiresIn, currentTime) < 1;
    }
    return false;
  };

  //COUNTDOWN RENDERER
  const renderer = ({
    hours,
    minutes,
    seconds,
    completed,
  }: {
    hours: number;
    minutes: number;
    seconds: number;
    completed: boolean;
  }) => {
    if (completed) {
      // Render a completed state
      return <></>;
    } else {
      // Render a countdown
      return (
        <span
          className={`${expirationStyles.CountdownExpirationText} ${
            !isOfferDetailPage ? expirationStyles.ListText : ''
          }`}
        >
          {isValidForLessThanOneMinute(model.expiresIn, isExpired())
            ? getSecondsString(seconds)
            : `${hours ? hours : ''} ${getHoursString(
                hours
              )} ${minutes} ${getMinutesString(minutes)}`}
        </span>
      );
    }
  };

  return (
    <Countdown
      date={model.expiresIn ? model.expiresIn : undefined}
      renderer={renderer}
    />
  );
});
