import styles from './Header.module.scss';
import { observer } from 'mobx-react';
import { PageRoutes } from '../../../utils/PageRoutes';
import { useMst } from '../../../rootInstance';
import { useTranslation } from 'react-i18next';
import Link from '../link';

export const BottomHeaderContent = observer(() => {
  const { router } = useMst();
  const { t } = useTranslation(['basic', 'navigation']);
  const {
    router: { navigate },
    header: {
      activeMenuItem,
      isModeratorProfileTabActive,
      isPartnerProfilesTabActive,
      isModerator,
      isSalesman,
      isPartner,
      isAdministrator,
    },
    currentPage,
  } = useMst();
  if (!activeMenuItem) {
    return <></>;
  }
  return (
    <>
      {isModeratorProfileTabActive ? (
        <ul className={styles.Navigation}>
          <Link
            className={
              activeMenuItem === PageRoutes.AllAds.id ||
              activeMenuItem === PageRoutes.AdDetails.id
                ? styles.Active
                : ''
            }
            view={router.views.get(PageRoutes.AllAds.id)}
          >
            <span onClick={() => navigate(PageRoutes.AllAds.id)}>
              {t('navigation:all_ads')}
            </span>
          </Link>
          {isAdministrator && (
            <Link
              className={
                activeMenuItem === PageRoutes.Packages.id ? styles.Active : ''
              }
              view={router.views.get(PageRoutes.Packages.id)}
            >
              <span
                onClick={() =>
                  navigate({
                    newView: PageRoutes.Packages.id,
                  })
                }
              >
                {t('navigation:packages')}
              </span>
            </Link>
          )}
          <Link
            className={
              activeMenuItem === PageRoutes.Accounts.id ? styles.Active : ''
            }
            view={router.views.get(PageRoutes.Accounts.id)}
          >
            <span onClick={() => navigate(PageRoutes.Accounts.id)}>
              {t('navigation:partner_list')}
            </span>
          </Link>
          {(isModerator || isAdministrator || isSalesman) && (
            <Link
              className={
                activeMenuItem === PageRoutes.Administration.id
                  ? styles.Active
                  : ''
              }
              view={router.views.get(PageRoutes.Administration.id)}
            >
              <span onClick={() => navigate(PageRoutes.Administration.id)}>
                {t('navigation:administration')}
              </span>
            </Link>
          )}
          {(isModerator || isAdministrator) && (
            <Link
              className={
                activeMenuItem === PageRoutes.Banners.id ? styles.Active : ''
              }
              view={router.views.get(PageRoutes.Banners.id)}
            >
              <span onClick={() => navigate(PageRoutes.Banners.id)}>
                {t('navigation:banners')}
              </span>
            </Link>
          )}
        </ul>
      ) : isPartnerProfilesTabActive && currentPage.id === 'PartnerProfiles' ? (
        ''
      ) : (
        <ul className={styles.Navigation}>
          {(isPartner || isSalesman || isModerator || isAdministrator) && (
            <Link
              className={
                activeMenuItem === PageRoutes.PartnerOffers.id
                  ? styles.Active
                  : ''
              }
              view={router.views.get(PageRoutes.PartnerOffers.id)}
            >
              <span onClick={() => navigate(PageRoutes.PartnerOffers.id)}>
                {t('navigation:all_my_ads')}
              </span>
            </Link>
          )}
          {(isPartner || isModerator || isAdministrator || isSalesman) && (
            <Link
              className={
                activeMenuItem === PageRoutes.CreateOffer.id
                  ? styles.Active
                  : ''
              }
              view={router.views.get(PageRoutes.CreateOffer.id)}
            >
              <span onClick={() => navigate(PageRoutes.CreateOffer.id)}>
                {t('navigation:create_ad')}
              </span>
            </Link>
          )}
          {(isPartner || isSalesman || isModerator || isAdministrator) && (
            <Link
              className={
                activeMenuItem === PageRoutes.CompanyProfile.id
                  ? styles.Active
                  : ''
              }
              view={router.views.get(PageRoutes.CompanyProfile.id)}
            >
              <span>{t('basic:company_profile')}</span>
            </Link>
          )}
          {(isPartner || isSalesman || isModerator || isAdministrator) && (
            <Link
              className={
                activeMenuItem === PageRoutes.PaymentsAndDocuments.id
                  ? styles.Active
                  : ''
              }
              view={router.views.get(PageRoutes.PaymentsAndDocuments.id)}
            >
              <span>{t('navigation:payments_and_documents')}</span>
            </Link>
          )}
          {(isPartner || isSalesman || isModerator || isAdministrator) && (
            <Link
              className={
                activeMenuItem === PageRoutes.Support.id ? styles.Active : ''
              }
              view={router.views.get(PageRoutes.Support.id)}
            >
              <span>{t('navigation:help_and_support')}</span>
            </Link>
          )}
        </ul>
      )}
    </>
  );
});
