import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './LawLimits.module.scss';

export const LawLimits = (): ReactElement => {
  const { t } = useTranslation();
  return (
    <div className={styles.LawElements}>
      <div className={styles.LawElement}>
        <strong>{t('basic:law_limit_title')}</strong> <br />
        <div>{t('basic:law_limit1')}</div>
        <ul>
          <li>{t('basic:law_limit_li_1')}</li>
          <li>{t('basic:law_limit_li_2')}</li>
          <li>{t('basic:law_limit_li_3')}</li>
        </ul>
        <div>{t('basic:law_limit2')}</div>
      </div>
    </div>
  );
};
