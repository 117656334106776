import { getRoot, Instance, types } from 'mobx-state-tree';
import {
  CheckboxGroupModel,
  CheckboxModel,
  ComponentModel,
} from '../../../internal';

export const SectionModel = types
  .compose(
    ComponentModel,
    types.model({
      opened: types.maybe(types.boolean),
      message: types.maybe(types.string),
      editMode: types.maybe(types.boolean),
      checkboxGroup: types.maybe(types.reference(CheckboxGroupModel)),
    })
  )
  .views((self) => {
    return {
      get isOpened() {
        return self.opened === true;
      },
    };
  })
  .actions((self) => {
    return {
      open() {
        self.opened = true;
      },
      close() {
        self.opened = false;
      },
      setMessage(content: string) {
        self.message = content;
      },
      toggle() {
        if (!self.isOpened) {
          self.open();
        } else {
          self.close();
        }
      },
      setEditMode(edit: boolean) {
        self.editMode = edit;
      },
    };
  })
  .named('SectionModel');

export type SectionModelType = Instance<typeof SectionModel>;
