import { Instance, types } from 'mobx-state-tree';

export const UserPriceDataModel = types
  .model({
    id: types.string,
    from: types.string,
    to: types.string,
    price: types.string,
  })
  .views((self) => {
    return {};
  })
  .actions((self) => {
    return {};
  })
  .named('UserPriceDataModel');

export type UserPriceDataModelType = Instance<typeof UserPriceDataModel>;
