import { getRoot, Instance, types } from 'mobx-state-tree';
import i18n from '../../../../i18n';
import {
  ButtonModel,
  ComponentModel,
  DropdownModel,
  ModalModel,
  TextInputModel,
  DurationModel,
  PartnerAdminPublic,
  CheckboxGroupModel,
  CheckboxModel,
  RoundButtonModel,
  DropdownOptionModel,
  UploadCodesModel,
  RootType,
  IDocument,
} from '../../../../internal';
import { dateFormatter, parseDate } from '../../../../utils/date';
import { ADMIN_STATUS_REQUESTED } from '../../../../constants';

export const EditAccountModalModel = types
  .compose(
    ComponentModel,
    types.model({
      modal: types.late(() => ModalModel),
      partnerId: types.maybeNull(types.string),
      status: types.maybeNull(types.string),
      adminId: types.maybeNull(types.string),
      contractId: types.maybeNull(types.string),
      name: types.maybeNull(types.string),
      company: types.late(() => TextInputModel),
      pib: types.late(() => TextInputModel),
      uniqueNumber: types.late(() => TextInputModel),
      // contractIdExternal: types.late(() => TextInputModel),
      // sapId: types.late(() => TextInputModel),
      hasYettelPay: types.late(() => DropdownModel),
      companyLegalForm: types.late(() => DropdownModel),
      taxPayer: types.late(() => DropdownModel),
      contractType: types.late(() => DropdownModel),
      contractDuration: types.late(() => DurationModel),
      numberOfEmployees: types.late(() => DropdownModel),
      segmentation: types.late(() => DropdownModel),
      category: types.late(() => DropdownModel),
      // address: types.late(() => TextInputModel),
      street: types.late(() => TextInputModel),
      streetNumber: types.late(() => TextInputModel),
      apartmentNumber: types.late(() => TextInputModel),
      // billingAddress: types.late(() => TextInputModel),
      billingAddressStreet: types.late(() => TextInputModel),
      billingAddressStreetNumber: types.late(() => TextInputModel),
      billingAddressApartmentNumber: types.late(() => TextInputModel),
      billingAddressCity: types.late(() => TextInputModel),
      billingAddressZip: types.late(() => TextInputModel),
      billingAddressDropdown: types.late(() => DropdownModel),
      billingAddressPak: types.late(() => TextInputModel),
      partnershipExpert: types.late(() => DropdownModel),
      city: types.late(() => TextInputModel),
      zip: types.late(() => TextInputModel),
      pak: types.late(() => TextInputModel),
      email: types.late(() => TextInputModel),
      adminFirstName: types.late(() => TextInputModel),
      adminLastName: types.late(() => TextInputModel),
      adminPhoneNumber: types.late(() => TextInputModel),
      saveBtn: types.late(() => ButtonModel),
      rejectBtn: types.late(() => ButtonModel),
      uploadDocumentBtn: types.late(() => ButtonModel),
      files: types.map(types.frozen<File | null | IDocument>(null)),
      partnerDocuments: types.array(types.frozen<IDocument>()),
    })
  )
  .views((self) => {
    return {
      get root(): RootType {
        return getRoot(self);
      },
      get filesArray(): File[] {
        return Array.from(self.files.values());
      },
      get account(): PartnerAdminPublic {
        return {
          id: self.partnerId,
          category: self?.category?.value,
          pib: self?.pib?.value,
          mbr: self?.uniqueNumber?.value,
          name: self?.name,
          address: `${self?.street?.value} ${self?.streetNumber?.value} ${
            self?.apartmentNumber?.value ? self?.apartmentNumber?.value : ''
          }`,
          street: self?.street?.value,
          street_no: self?.streetNumber?.value,
          app_no: self?.apartmentNumber?.value,
          // billing_address_dropdown: self?.billingAddressDropdown?.value,
          // billing_address: self?.billingAddress?.value,
          billing_address: `${self?.billingAddressStreet?.value} ${
            self?.billingAddressStreetNumber?.value
          } ${
            self?.billingAddressApartmentNumber?.value
              ? self?.billingAddressApartmentNumber?.value
              : ''
          }`,
          billing_street: self?.billingAddressStreet?.value,
          billing_street_no: self?.billingAddressStreetNumber?.value,
          billing_app_no: self?.billingAddressApartmentNumber?.value,
          billing_city: self?.billingAddressCity?.value,
          billing_zip: self?.billingAddressZip?.value,
          billing_pak: self?.billingAddressPak?.value,
          city: self?.city?.value,
          zip: self?.zip?.value,
          // contract_id_external: self?.contractIdExternal?.value,
          // sap_id: self?.sapId?.value,
          admin_email: self?.email?.value,
          pak: self?.pak?.value,
          pay: self.hasYettelPay.value === 'yes' ? 1 : 0,
          active: 1,
          legal_name: self?.company?.value,
          legal_type: self?.companyLegalForm?.value,
          taxpayer: self?.taxPayer?.selectedOptionId === '1' ? 1 : 0,
          employees: self?.numberOfEmployees?.value,
          segmentation: self?.segmentation?.value,
          cost_by: 'click',
          image: 'unknown',
          contract: {
            type: self?.contractType?.value,
            from: dateFormatter(self?.contractDuration?.fromDate),
            to: dateFormatter(self?.contractDuration?.toDate),
            catalog_id: 'ca8c031d-2e73-42d5-af32-c8f63630609a',
            id: self?.contractId,
          },
          phone: self?.adminPhoneNumber?.value,
          admin: {
            first_name: self?.adminFirstName?.value,
            last_name: self?.adminLastName?.value,
            email: self?.email?.value || 'test@test.com',
            phone: self?.adminPhoneNumber?.value,
            id: self.adminId,
          },
          // catalog_id: self?.catalogs?.value,
          partnership_expert_id: self?.partnershipExpert?.value,
          //dodati ugovor
        };
      },
    };
  })
  .actions((self) => {
    return {
      afterCreate() {
        self.modal.setOnAfterClose(() => {
          self?.clear();
        });
      },
      clear() {
        self.partnerId = null;
        self.adminId = null;
        self.status = null;
        self.contractId = null;
        self?.company?.clearData();
        self.pib?.clearData();
        self.email?.clearData();

        self.adminFirstName?.clearData();
        self.adminLastName?.clearData();
        self.adminPhoneNumber?.clearData();

        self.uniqueNumber?.clearData();
        // self.address?.clearData();
        self.street?.clearData();
        self.streetNumber?.clearData();
        self.apartmentNumber?.clearData();
        self?.city?.clearData();
        self.zip?.clearData();
        self?.category?.clearData();
        self.hasYettelPay?.clearData();
        self?.companyLegalForm?.clearData();
        self?.companyInVatSystem?.clearData();
        self.billingAddressDropdown?.clearData();
        // self.billingAddress?.clearData();
        self.billingAddressStreet?.clearData();
        self.billingAddressStreetNumber?.clearData();
        self.billingAddressApartmentNumber?.clearData();
        self.billingAddressCity?.clearData();
        self.billingAddressZip?.clearData();
        self.billingAddressPak?.clearData();
        self.pak?.clearData();
        self.numberOfEmployees?.clearData();
        self.segmentation?.clearData();
        self?.contractType?.clearData();
        // self?.contractIdExternal?.clearData();
        // self.sapId?.clearData();
        self.partnershipExpert?.clearData();
        self?.contractDuration?.clearFromDate();
        self?.contractDuration?.clearToDate();

        self?.company.setDisabled(false);
        self.pib.setDisabled(false);
        self.uniqueNumber.setDisabled(false);
        self.partnershipExpert.setDisabled(false);
        self.email.setDisabled(false);

        self.name = '';
        this.clearFiles();
      },
      setAccount(acc: PartnerAdminPublic) {
        self.partnerId = acc.id;
        // self.contractIdExternal.setValue(acc.contract_id_external);
        // self.sapId.setValue(acc.sap_id);
        self.contractId = acc.contract?.id;
        self.name = acc.name;
        self.company.setValue(acc.legal_name);
        self.pib.setValue(acc.pib);
        self.pib.setValue(acc.pib);
        self.adminStatus = acc.admin?.status;
        self.status = acc.status;
        self.adminId = acc.admin?.id;
        self.email.setValue(acc?.email);
        self.adminFirstName.setValue(acc.admin?.first_name);
        self.adminLastName.setValue(acc.admin?.last_name);
        self.adminPhoneNumber.setValue(acc.admin?.phone);
        self.uniqueNumber.setValue(acc.mbr);

        // self.address.setValue(acc.address);
        self.street.setValue(acc.street);
        self.streetNumber.setValue(acc.street_no);
        self.apartmentNumber.setValue(acc.app_no);
        // self.billingAddressDropdown.setValue(acc.billing_address_dropdown);
        self.billingAddressCity.setValue(acc.billing_city);
        // self.billingAddress.setValue(acc.billing_address);
        self.billingAddressStreet.setValue(acc.billing_street);
        self.billingAddressStreetNumber.setValue(acc.billing_street_no);
        self.billingAddressApartmentNumber.setValue(acc.billing_app_no);
        self.billingAddressZip.setValue(acc.billing_zip);
        self.billingAddressPak.setValue(acc.billing_pak);
        self.city.setValue(acc.city);
        self.zip.setValue(acc.zip);
        self.pak.setValue(acc.pak);

        self.taxPayer.setValue(acc.taxpayer);
        self.category.setValue(acc.category);
        self.hasYettelPay.setValue(acc.pay ? 'yes' : 'no');
        self.companyLegalForm.setValue(acc.legal_type);
        self.numberOfEmployees.setValue(acc.employees);
        self.segmentation.setValue(acc.segmentation);
        self.contractType.setValue(acc.contract?.type ?? 'order');
        self.contractDuration.setFromDate(
          acc.contract?.from ? parseDate(acc.contract?.from as any) : new Date()
        );
        self.contractDuration.setToDate(
          acc.contract?.to ? parseDate(acc.contract?.to as any) : new Date()
        );
        self.partnershipExpert.setValue(acc.partnership_expert_id ?? '');
        self.company.setDisabled(true);
        self.pib.setDisabled(true);
        self.uniqueNumber.setDisabled(true);
        self.email.setDisabled(true);
        if (self?.root.user?.isSalesman) {
          self.partnershipExpert.setDisabled(true);
          self.partnershipExpert.setCurrentMessage('');
        }
        self.company.setCurrentMessage('');
        self.pib.setCurrentMessage('');
        self.email.setCurrentMessage('');
        //dodati ugovor
      },
      open() {
        self.modal.setOpened(true);
        this.clearFiles();

        self.root.api.partner
          .getAllPartnerDocuments(self.partnerId)
          .then((response: any) => {
            this.setPartnerDocuments(response.data);
            this.setFiles(response.data);
          });
      },
      close() {
        self.modal.setOpened(false);
      },
      setPartnerDocuments(documents: any) {
        self.partnerDocuments = documents;
      },
      getUploadContractModel() {
        return (
          self.root.currentPage?.components.get('UploadCodesModelEdit') ||
          self.root.currentPage?.addComponent(
            UploadCodesModel.create({
              id: 'UploadCodesModelEdit',
              step: 2,
              isRequired: true,
              codeTypeDropdown: DropdownModel.create({
                id: 'UploadCodesModel',
                label: 'basic:code_type',
                isRequired: true,
                step: 2,
                onChangeCallback: (option: { id: string; value: string }) => {
                  const uploadCodesModel = this.getUploadContractModel();
                  if (option.id === 'personalized') {
                    // if the option is personalized, then the user has to pick
                    // from a checkbox container view
                    uploadCodesModel?.checkboxContainer?.setIsRequired(true);
                    uploadCodesModel?.textInputField?.setIsRequired(false);
                  } else {
                    // meaning 'common', then checkbox is irrelevant
                    // but the text code is everything
                    uploadCodesModel?.checkboxContainer?.setIsRequired(false);
                    uploadCodesModel?.textInputField?.setIsRequired(true);
                  }
                },
                options: {
                  1: DropdownOptionModel.create({
                    id: 'personalized',
                    value: 'basic:personalized_code_type',
                  }),
                  2: DropdownOptionModel.create({
                    id: 'common',
                    value: 'basic:same_code_type',
                  }),
                },
              }),
              buttonSend: RoundButtonModel.create({
                id: 'EnterCodesButtonSend',
                label: 'basic:send',
              }),
              textInputField: TextInputModel.create({
                id: 'EnterCodesInputNumber',
                label: 'Unesi kod',
                isRequired: true,
                step: 2,
                placeholder: 'AX34BH',
              }),
              buttonAddTable: RoundButtonModel.create({
                id: 'EnterCodesButtonAddTable',
                label: 'basic:add_table',
              }),
              buttonChange: ButtonModel.create({
                id: 'EnterCodesButtonChange',
                label: 'basic:change_table',
                onClickCallback: () => {
                  this.getUploadContractModel().openModal();
                },
              }),
              uploadCodesCount: 0,
              checkboxContainer: CheckboxGroupModel.create({
                id: 'CodeUploadTypesEdit',
                isRequired: true,
                step: 2,
                selectedCheckboxes: {},
                checkboxes: {
                  numeric: CheckboxModel.create({
                    id: 'numeric',
                    label: 'user_segments:numeric',
                  }),
                  bar: CheckboxModel.create({
                    id: 'bar',
                    label: 'user_segments:barcode',
                  }),
                  qr: CheckboxModel.create({
                    id: 'qr',
                    label: 'user_segments:qrcode',
                  }),
                },
              }),
              modal: ModalModel.create({
                id: 'UploadCodesModal',
                label: 'UploadCodes',
                opened: false,
                step: 2,
              }),
            })
          )
        );
      },
      setFiles(files: File[]) {
        Array.from(files).forEach((file) => {
          if (!self.files.has(file.name)) {
            self.files.set(file.name, file);
          } else {
            self.root.showFailure(`File already exists: ${file.name}`);
          }
        });
      },
      clearFiles() {
        self.files.clear();
      },
      removeFile(file: File) {
        self.files.delete(file.name);
      },
      getSaveButton() {
        return (
          self.root.currentPage.components.get('EditAccountModalSaveButton') ||
          self.root.currentPage.addComponent(
            ButtonModel.create({
              id: 'EditAccountModalSaveButton',
              labelFunc: () =>
                self.adminStatus === ADMIN_STATUS_REQUESTED
                  ? i18n.t('basic:approve')
                  : i18n.t('basic:save_changes'),
            })
          )
        );
      },
      getResendEmailButton() {
        return (
          self.root.currentPage.components.get(
            'EditAccountResendEmailButton'
          ) ||
          self.root.currentPage.addComponent(
            ButtonModel.create({
              id: 'EditAccountResendEmailButton',
              labelFunc: () => i18n.t('basic:resend_verification_email'),
            })
          )
        );
      },
      async resendEmail(email: string) {
        await self.root.api.admin.resendCreateAdminEmail({
          email: email,
        });
        self.close();
      },
      async save(callback?: () => void) {
        const id = self.partnerId;
        await self.root.api.partner.update(self.account, 1);
        self.close();
        callback?.();
      },
      async reject(callback?: () => void) {
        const id = self.partnerId;
        const response = await self.root.api.partner.reject(id);
        this.setAccount(response.data);
        self.close();
        callback?.();
      },
      async approve(callback?: () => void) {
        const id = self.partnerId;
        const response = await self.root.api.partner.approve(id);
        this.setAccount(response.data);
        self.close();
        callback?.();
      },
    };
  })
  .named('EditAccountModalModel');

export type EditAccountModalModelType = Instance<typeof EditAccountModalModel>;
