import { observer } from 'mobx-react';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import {
  CreatedAccountListModelType,
  PartnerAdminNew,
  useMst,
} from '../../../../internal';
import { AccountListFilters } from './account-list-filters/AccountListFilters';
import styles from './CreatedAccountList.module.scss';
import { dateSRLocale, parseDate } from '../../../../utils/date';
import { Pagination } from '../../../UI/pagination/Pagination';
import { EditAccountModal } from './edit-account-modal/EditAccountModal';
import { ConfirmationModal } from '../../../UI/confirmation-modal/ConfirmationModal';
import ALPHABETICAL_SORT from '../../../../assets/icons/alphabetical-order.png';
import SORT from '../../../../assets/icons/sort.png';
import { SMALL_ICON_SIZE } from '../../../../constants/image';
import { ChangePacketModal } from '../../../../models/ui/change-packet-modal/ChangePacketModal';
import { BillingModal } from '../../../../models/ui/billing-modal/BillingModal';
import { ApproveRejectPackageModal } from '../../../../models/ui/approve-reject-package-modal/ApproveRejectPackageModal';

const statusColors: any = {
  active: 'var(--activestatus)',
  submitted: 'var(--pendingstatus)',
  nonactive: 'var(--inactivestatus)',
};

const getColor = (status?: string) => {
  if (!status) {
    return {};
  }
  const cl: string = statusColors[status] as string;
  if (!cl) {
    return {};
  }
  return {
    color: cl,
  };
};

export const CreatedAccountList = observer(
  (props: { model: CreatedAccountListModelType }): ReactElement => {
    const { model } = props;
    const { currentPage } = useMst();
    const { t } = useTranslation();
    return (
      <>
        <div className={styles.Container}>
          <AccountListFilters model={model.filters} />
          <div className={styles.TableContainer}>
            <div className={styles.Section}>
              <div className={styles.TableContent}>
                <table>
                  <thead>
                    <tr className={styles.HeaderRow}>
                      <th>
                        {t('basic:company')}
                        <img
                          className={styles.SortIcon}
                          src={ALPHABETICAL_SORT}
                          width={SMALL_ICON_SIZE}
                          height={SMALL_ICON_SIZE}
                          onClick={() => {
                            model.setOrderBy('name');
                            model.toggleOrder();
                            currentPage.loadData();
                          }}
                        />
                      </th>
                      <th>
                        {t('basic:pib')}
                        <img
                          className={styles.SortIcon}
                          src={SORT}
                          width={SMALL_ICON_SIZE}
                          height={SMALL_ICON_SIZE}
                          onClick={() => {
                            model.setOrderBy('pib');
                            model.toggleOrder();
                            currentPage.loadData();
                          }}
                        />
                      </th>
                      <th>
                        {t('basic:partnership_expert')}
                        <img
                          className={styles.SortIcon}
                          src={ALPHABETICAL_SORT}
                          width={SMALL_ICON_SIZE}
                          height={SMALL_ICON_SIZE}
                          onClick={() => {
                            model.setOrderBy('partnership_expert_id');
                            model.toggleOrder();
                            currentPage.loadData();
                          }}
                        />
                      </th>
                      <th>Segment</th>
                      <th>
                        {t('basic:date_created')}
                        <img
                          className={styles.SortIcon}
                          src={SORT}
                          width={SMALL_ICON_SIZE}
                          height={SMALL_ICON_SIZE}
                          onClick={() => {
                            model.setOrderBy('created_at');
                            model.toggleOrder();
                            currentPage.loadData();
                          }}
                        />
                      </th>
                      <th>
                        {t('basic:status')}
                        <img
                          className={styles.SortIcon}
                          src={ALPHABETICAL_SORT}
                          width={SMALL_ICON_SIZE}
                          height={SMALL_ICON_SIZE}
                          onClick={() => {
                            model.setOrderBy('status');
                            model.toggleOrder();
                            currentPage.loadData();
                          }}
                        />
                      </th>
                      <th>Status ugovora</th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {model.accountsArray.map(
                      (elem: PartnerAdminNew, index: number) => {
                        const expert = currentPage.getPartnershipExpert(
                          elem.partnership_expert_id
                        );
                        return (
                          <tr key={index}>
                            <td>{elem.name}</td>
                            <td>{elem.pib}</td>
                            <td>
                              <span className={styles.BreakWord}>
                                {expert
                                  ? `${expert.first_name} ${expert.last_name}`
                                  : ''}
                              </span>
                            </td>
                            <td>
                              <span>{elem.segmentation}</span>
                            </td>
                            <td>
                              {dateSRLocale(
                                parseDate(
                                  elem.created_at?.split(' ')[0] as string
                                )
                              )}
                            </td>
                            <td>
                              <span style={getColor(elem?.status)}>
                                {t(`account_status:${elem.status}`)}
                              </span>
                            </td>
                            <td>
                              <span>
                                {elem.contract_request &&
                                  t(
                                    `contract_request_status:${elem.contract_request?.status}`
                                  )}
                              </span>
                            </td>
                            <td>
                              {elem.contract_request?.status ===
                                'requested' && (
                                <span
                                  onClick={() => {
                                    model.changePacketModal.setPartnerAdmin(
                                      elem
                                    );
                                    model.changePacketModal.open();
                                  }}
                                  className={styles.TableLink}
                                >
                                  Promena paketa
                                </span>
                              )}
                              {elem.contract_request?.status ===
                                'submitted' && (
                                <span
                                  onClick={() => {
                                    model.approveRejectPackageModal.setPartnerAdmin(
                                      elem
                                    );
                                    model.approveRejectPackageModal.open();
                                  }}
                                  className={styles.TableLink}
                                >
                                  Odobrenje paketa
                                </span>
                              )}
                              {!elem.contract_request && (
                                <span
                                  onClick={() => {
                                    model.billingModal.setPartnerAdmin(elem);
                                    model.billingModal.open(true);
                                  }}
                                  className={styles.TableLink}
                                >
                                  Model naplate
                                </span>
                              )}
                            </td>
                            <td>
                              <span
                                className={styles.TableLink}
                                onClick={() => {
                                  model.editModal.setAccount({
                                    ...elem,
                                    company_legal_name:
                                      currentPage.getCompanyName(elem.id),
                                  });
                                  model.editModal.open();
                                }}
                              >
                                Detaljnije
                              </span>
                            </td>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </table>
              </div>
              {!!model?.accountsArray?.length && (
                <div className={styles.PaginationContainer}>
                  <Pagination model={model.pagination} />
                </div>
              )}
              <div className={styles.AddAccountContainer}>
                <span className={styles.Plus}>+</span>
                <a
                  onClick={() => {
                    currentPage.switchToCreateAccount();
                  }}
                  className={styles.Link}
                >
                  {t('basic:add_account')}
                </a>
              </div>
            </div>
          </div>
        </div>
        <EditAccountModal model={model.editModal} />
        <ConfirmationModal model={model.deleteModal} />
        <ChangePacketModal model={model.changePacketModal} />
        <BillingModal model={model.billingModal} />
        <ApproveRejectPackageModal model={model.approveRejectPackageModal} />
      </>
    );
  }
);
