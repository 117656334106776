import { getRoot, types } from 'mobx-state-tree';
import { HttpVerbs } from '../../constants/verbs';
import { RootType, ApiListResponse } from '../../internal';

export interface UsedCode {
  date: string;
  ad_id: string;
  submitted_by: string;
  count: string;
  id: string;
}

export const UsedCodesApi = types
  .model({})
  .views((self) => {
    return {
      get root(): RootType {
        return getRoot(self);
      },
    };
  })
  .actions((self) => {
    return {
      async getAllUsedCodes(adId: string): Promise<ApiListResponse<UsedCode>> {
        const response = await self.root.axios.getAll('/used-codes', {
          ad_id: adId,
        });
        return response;
      },
      save(data: { ad_id: string; submitted_by: string; count: number }) {
        return new Promise((resolve) => {
          self.root.axios
            .requestData({
              type: HttpVerbs.POST,
              data,
              path: '/used-codes',
            })
            .then((response: any) => {
              resolve(response);
            });
        });
      },
    };
  });
