export const CROPBOX_WIDTH = 600;
export const CROPBOX_HEIGHT = 300;

export const CROPPER_DIMENSIONS = { height: '300px', width: '600px' };
export const CROPPER_DIMENSIONS_MOBILE = { height: '200px', width: '300px' };
export const CROPPER_DIALOG_STYLES = {
  content: {
    maxHeight: 'fit-content',
    top: '5%',
  },
};
