import { destroy, detach, getRoot, Instance, types } from 'mobx-state-tree';
import {
  ComponentModel,
  ComponentModelType,
  TextInputModel,
  TextAreaInputModel,
  FileInputModel,
  ModalModel,
  AdTypeModel,
  RoundButtonGroupModel,
  RadioButtonGroupModel,
  DatePickerModel,
  DropdownModel,
  DropdowOptionGroupsModel,
  TagModel,
  TagGroupModel,
  CheckboxModel,
  CheckboxGroupModel,
  AdditionalLocationModel,
  AccordionModel,
  DurationModel,
  ProgressBarModel,
  PickStoresModel,
  OfferStepsModel,
  ButtonModel,
  SectionModel,
  ClearCodesWarningModel,
  UploadCodesModel,
  AdditionalUserModel,
  TabsModel,
  OfferFiltersModel,
  TableModel,
  ApproveAdModel,
  ApproveRejectAdModel,
  InfoFieldModel,
  PackageSelectionModel,
  AdministratorsModel,
  PhysicalStoreLocationsModel,
  CreatedAccountListModel,
  CreateAccountModel,
  BuyAdditionalPackageModalModel,
  UsedCodesModel,
  AdministrationAccountModel,
  DeleteAccountModel,
  PasswordInputModel,
  ConfirmationModalModel,
  DropdownMultiselectModel,
  AdsTableFiltersModel,
  OldAdListModel,
  AdTableModel,
  PaymentsAndDocumentsTableModel,
  BannersTableModel,
  BannersListElementModel,
} from '../internal';

const comps: any[] = [
  ComponentModel,
  TextInputModel,
  DropdownModel,
  DropdowOptionGroupsModel,
  FileInputModel,
  RoundButtonGroupModel,
  DatePickerModel,
  TextAreaInputModel,
  ModalModel,
  AdTypeModel,
  RadioButtonGroupModel,
  TagModel,
  TagGroupModel,
  CheckboxModel,
  CheckboxGroupModel,
  AdditionalLocationModel,
  AccordionModel,
  SectionModel,
  DurationModel,
  ProgressBarModel,
  PickStoresModel,
  OfferStepsModel,
  ButtonModel,
  ClearCodesWarningModel,
  UploadCodesModel,
  AdditionalUserModel,
  TabsModel,
  OfferFiltersModel,
  TableModel,
  ApproveAdModel,
  ApproveRejectAdModel,
  InfoFieldModel,
  PackageSelectionModel,
  AdministratorsModel,
  PhysicalStoreLocationsModel,
  CreatedAccountListModel,
  CreateAccountModel,
  BuyAdditionalPackageModalModel,
  UsedCodesModel,
  AdministrationAccountModel,
  DeleteAccountModel,
  PasswordInputModel,
  ConfirmationModalModel,
  DropdownMultiselectModel,
  AdsTableFiltersModel,
  OldAdListModel,
  AdTableModel,
  PaymentsAndDocumentsTableModel,
  BannersTableModel,
  BannersListElementModel,
];
export const PageModel = types
  .model('PageModel', {
    id: types.identifier,
    components: types.optional(types.map(types.union(...comps)), {}),
  })
  .views((self) => {
    return {
      get root() {
        return getRoot(self);
      },
      get componentsArray() {
        return Array.from(self.components.values());
      },
      get isFormValid() {
        let result = true;
        this.componentsArray.forEach((component) => {
          if (
            component.validationMessagesArray &&
            component.validationMessagesArray.length
          ) {
            result = false;
            return;
          }
        });
        return result;
      },
    };
  })
  .actions((self) => {
    return {
      addComponent(component: ComponentModelType) {
        self.components.put(component);
        return self.components.get(component.id);
      },
      clearData() {
        self.componentsArray.forEach((component) => {
          detach(component);
          destroy(component);
        });
        self.components.clear();
      },
      clearValidators() {
        self.componentsArray.forEach((component) => {
          component.setCurrentMessage('');
        });
      },
      removeComponent(id: string) {
        const component = self.components.get(id);
        self.components.delete(id);
        destroy(component);
      },
      runValidators(): boolean {
        let result = true;
        self.componentsArray.forEach((component) => {
          component.runValidators();
          if (result && component.currentMessage) {
            result = false;
          }
        });
        return result;
      },
    };
  });
export type PageType = Instance<typeof PageModel>;
