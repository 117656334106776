import * as React from 'react';
import { observer } from 'mobx-react';

import styles from './TextSection.module.scss';
import DOMPurify from 'dompurify';

interface ITextSectionModel {
  id: string;
  title: string;
  content?: string;
}

export const TextSection = observer((props: ITextSectionModel) => {
  const { id, title, content } = props;
  return (
    <>
      <div className={styles.TextSection}>
        <div className={styles.Tab}>
          <label className={styles.TabLabel} htmlFor={id}>
            {title}
          </label>
          {!!content && (
            <div
              className={styles.TabContent}
              dangerouslySetInnerHTML={{ __html: content }}
            />
          )}
        </div>
      </div>
    </>
  );
});
