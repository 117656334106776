import { observer } from 'mobx-react';
import { TabsModelType } from '../../../../../../internal';
import { Tabs } from '../../../../../UI/tabs/Tabs';
import './tabs.css';

export const StoreLocationTabs = observer(
  (props: { model: TabsModelType; children?: any }): JSX.Element => {
    const { children, model } = props;
    return (
      <div className="container">
        <Tabs model={model}>{children}</Tabs>
      </div>
    );
  }
);
