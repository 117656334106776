import { getRoot, Instance, types } from 'mobx-state-tree';
import {
  BannersListElementModel,
  BannersListElementModelType,
  CheckboxGroupModel,
  CheckboxModel,
  ConfirmationModalModel,
  DurationModel,
  FileInputModel,
  ModalModel,
  PageModel,
  PageRoutes,
  RootType,
  TextAreaInputModel,
  TextInputModel,
} from '../../../internal';
import { validateURL } from '../../../validators/validators';
import { EditorState } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import { dateFormatter } from '../../../utils/date';

export const CreateBannerPageModel = types
  .compose(
    PageModel,
    types.model({
      record: types.maybe(BannersListElementModel),
    })
  )
  .views((self) => {
    return {
      get root(): RootType {
        return getRoot(self);
      },
    };
  })
  .actions((self) => {
    return {
      async onPageExit() {
        // prevent caching
        // self.clearData();
      },
      async beforePageEnter() {
        const id = self.root.router.queryParams.id;

        if (!id) return;
        const record = await self.root.api.banners.get(id);
        if (!record) return;
        this.setRecord(record);
      },
      setRecord(record: BannersListElementModelType) {
        self.record = record;
      },
      navigateToBannersPage(type: string) {
        self.root.router.navigate({
          newView: PageRoutes.Banners.id,
          params: {
            type,
          },
        });
      },
      getConfirmationModal() {
        return (
          self.components.get('confirmation_modal') ||
          self.addComponent(
            ConfirmationModalModel.create({
              id: 'confirmation_modal',
              labelNo: 'basic:no',
              labelYes: 'basic:yes',
              afterYes: this.deleteBanner,
              modal: ModalModel.create({
                id: 'modal',
                opened: false,
                showCloseButton: false,
              }),
            })
          )
        );
      },
      openConfirmationModal() {
        this.getConfirmationModal().open();
      },
      async deleteBanner() {
        const id = self.root.router.queryParams.id;
        const type = self.root.router.queryParams.type;
        await self.root.api.banners.delete(id);
        this.navigateToBannersPage(type);
      },
      async submitData() {
        const components = self.components;
        const count = self.root.router.params.count;
        let desktopImage = components.get('image_desktop').src || '';
        let mobileImage = components.get('image_mobile').src || '';
        const rawDesktopImage = components.get('image_desktop').file;
        const rawMobileImage = components.get('image_mobile').file;

        if (rawDesktopImage) {
          const response = await self.root.api.banners.uploadImage(
            rawDesktopImage,
            'desktopImage'
          );
          const fileName = response?.data?.desktopImage?.[0]?.filename;

          if (fileName) {
            desktopImage = `${process.env.REACT_APP_IMAGE_BASE_URL}banners/${fileName}`;
          }
        }

        if (rawMobileImage) {
          const response = await self.root.api.banners.uploadImage(
            rawMobileImage,
            'mobileImage'
          );
          const fileName = response?.data?.mobileImage?.[0]?.filename;

          if (fileName) {
            mobileImage = `${process.env.REACT_APP_IMAGE_BASE_URL}banners/${fileName}`;
          }
        }

        const data = {
          order: self.record?.order || count + 1,
          name: components.get('name').value,
          alt: 'banner',
          url: components.get('url').value,
          desktopImage,
          mobileImage,
          bannerType:
            self.root.router.queryParams.type === 'horizontal' ? '0' : '1',
          startDate: dateFormatter(components.get('banner_duration').fromDate),
          endDate: dateFormatter(components.get('banner_duration').toDate),
          author: `${self.root.user.admin.email}`,
          htmlContent: components.get('html_content').value,
          isActive: Number(
            components.get('checkbox_group').checkboxes.get('is_active')
              .isChecked
          ),
          showEveryTime: Number(
            components.get('checkbox_group').checkboxes.get('show_every_time')
              ?.isChecked || false
          ),
        };

        const id = self.root.router.queryParams.id;

        if (id) {
          await self.root.api.banners.update(id, data);
        } else {
          await self.root.api.banners.create(data);
        }
        const type = self.root.router.queryParams.type;
        this.navigateToBannersPage(type);
      },
      generateFields() {
        const { type } = self.root.router.queryParams;
        const isPopup = type === 'popup';
        return {
          name: self.addComponent(
            TextInputModel.create({
              id: 'name',
              label: 'banners:name',
              isRequired: true,
              value: self.record?.name || '',
            })
          ),
          banner_duration: self.addComponent(
            DurationModel.create({
              id: 'banner_duration',
              fromDate: self.record?.startDate
                ? new Date(self.record?.startDate)
                : new Date(),
              toDate: self.record?.endDate
                ? new Date(self.record?.endDate)
                : new Date(),
              maxDuration: 31,
              disabledDates: [],
              minDate: new Date(),
              modal: ModalModel.create({
                id: 'BannerDurationModal',
                label: 'Banner Duration',
                opened: false,
                showCloseButton: true,
              }),
            })
          ),
          url: self.addComponent(
            TextInputModel.create({
              id: 'url',
              label: 'banners:url',
              isRequired: true,
              validators: {
                validateURL: validateURL,
              },
              value: self.record?.url || '',
            })
          ),
          image_desktop: self.addComponent(
            FileInputModel.create({
              id: 'image_desktop',
              maxNumberOfFiles: 1,
              src: self.record?.desktopImage || '',
            })
          ),
          image_mobile: self.addComponent(
            FileInputModel.create({
              id: 'image_mobile',
              maxNumberOfFiles: 1,
              src: self.record?.mobileImage || '',
            })
          ),
          html_content: self.addComponent(
            TextAreaInputModel.create({
              id: 'html_content',
              label: 'banners:text_content',
              isRequired: true,
              editorState: EditorState.createWithContent(
                stateFromHTML(self.record?.htmlContent || '')
              ),
            })
          ),
          checkbox_group: self.addComponent(
            CheckboxGroupModel.create({
              id: 'checkbox_group',
              selectedCheckboxes: {
                ...(self.record?.isActive ? { is_active: 'is_active' } : {}),
                ...(self.record?.showEveryTime
                  ? { show_every_time: 'show_every_time' }
                  : {}),
              },
              checkboxes: {
                is_active: CheckboxModel.create({
                  id: 'is_active',
                  label: 'banners:is_active',
                  disabled: !self.root.router.params.canActivate,
                }),
                ...(isPopup
                  ? {
                      show_every_time: CheckboxModel.create({
                        id: 'show_every_time',
                        label: 'banners:show_every_time',
                      }),
                    }
                  : {}),
              },
            })
          ),
        };
      },
    };
  })
  .named('CreateBannerPageModel');

export type CreateBannerPageModelType = Instance<typeof CreateBannerPageModel>;
