import * as React from 'react';
import { observer } from 'mobx-react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import './SliderSection.scss';
import styles from './SliderSection.module.scss';
import { Show } from '../../../../UI/show/Show';
import { useMst } from '../../../../../internal';

interface IOfferModel {
  model: any;
}

export const SliderSection = observer((props: IOfferModel) => {
  const { currentPage } = useMst();
  const { model } = props;

  return (
    <>
      <div className={styles.SliderSection + ' offerPageSlider'}>
        <Carousel
          autoPlay={true}
          emulateTouch={true}
          interval={5000}
          showArrows={false}
          showStatus={false}
          showThumbs={false}
          infiniteLoop={true}
          showIndicators={model.imagesArray.length > 1}
        >
          {model.imagesArray.map((imagePath: string, index: number) => {
            return (
              <div className={styles.PhotoWrapper} key={index}>
                <img
                  src={imagePath}
                  className={styles.Photo}
                  alt={model.name}
                />
              </div>
            );
          })}
        </Carousel>
        {/**Hidden for now, see: LOY-726 */}
        {/*<Show condition={model.discountDescription}>
          <div className={styles.DiscountLabel}>
            {model.discountDescription}
          </div>
        </Show>
        */}
        <Show condition={currentPage.labelText}>
          <div
            style={{
              backgroundColor: currentPage.labelColorHex,
            }}
            className={styles.LabelText}
          >
            {currentPage.labelText}
          </div>
        </Show>
      </div>
    </>
  );
});
