import { observer } from 'mobx-react';
import styles from './AdTableRow.module.scss';
import {
  AdListElemModelType,
  AdListModelType,
  ColumnModelType,
  useMst,
} from '../../../internal';
import shortId from 'shortid';
import { Tooltip } from 'react-tippy';
import copyIcon from '../../../assets/icons/copy_icon.png';
import { Content } from './AdTableElementContent';
import { copyToClipboard } from '../../../utils/methods';
import { useTranslation } from 'react-i18next';
import React from 'react';
export const AdTableRow = observer(
  (props: {
    rowModel: AdListElemModelType;
    tableModel: AdListModelType;
  }): JSX.Element => {
    const { rowModel, tableModel } = props;
    const { t } = useTranslation();
    const { currentPage } = useMst();
    return (
      <tr className={styles.TableRow} key={rowModel.id}>
        {tableModel.visibleColumnsArray.map(
          (column: ColumnModelType, i: number) => {
            if (column.id === 'allOptions') return null;
            const id = shortId();
            return (
              <TD
                key={rowModel.id + column.id}
                column={column}
                rowModel={rowModel}
                id={id}
                currentPage={currentPage}
              />
            );
          }
        )}
      </tr>
    );
  }
);
const TD = (props: any) => {
  const { column, rowModel, id, currentPage } = props;
  const { t } = useTranslation();
  const ref = React.useRef<HTMLTableDataCellElement>(null);
  const [tooltip, setTooltip] = React.useState(false);
  React.useEffect(() => {
    if (Number(ref.current?.offsetWidth) < Number(ref.current?.scrollWidth)) {
      setTooltip(true);
    }
  }, [ref.current, id]);

  const handleSpenderType = (spenderTypeString: string) => {
    let tooltipString = '';
    spenderTypeString.split(',').forEach((spenderType) => {
      tooltipString += t(`ad_settings:${spenderType.trim()}`) + ',';
    });
    tooltipString = tooltipString.slice(0, -1);
    return tooltipString;
  };

  return (
    <td
      ref={ref}
      id={id}
      className={styles.TableData}
      data-isidcolumn={column.id === 'id'}
    >
      {column.id === 'ad_id' && (
        <img
          className={styles.CopyIcon}
          onClick={() => {
            copyToClipboard((rowModel as { [key: string]: any })[column.id]);
            currentPage.root.showSuccess(t('offer:id_copy_success'));
          }}
          src={copyIcon}
          alt="copy"
        />
      )}
      {tooltip ? (
        <Tooltip
          key={rowModel.id + column.id}
          theme={'dark'}
          html={
            <div style={{ padding: '0.2em' }}>
              {(rowModel as { [key: string]: any })[column.id] &&
                column.id === 'ad_spender_type' && (
                  <span>
                    {handleSpenderType(
                      (rowModel as { [key: string]: any })[column.id]
                    )}
                  </span>
                )}
              {column.id === 'ad_store_location_type' && (
                <span>
                  {t(
                    `ad_settings:${
                      (rowModel as { [key: string]: any })[column.id]
                    }`
                  )}
                </span>
              )}
              {column.id !== 'ad_spender_type' &&
                column.id !== 'ad_store_location_type' && (
                  <span>{(rowModel as { [key: string]: any })[column.id]}</span>
                )}
            </div>
          }
          position="top"
        >
          <Content column={column} rowModel={rowModel} />
        </Tooltip>
      ) : (
        <Content column={column} rowModel={rowModel} />
      )}
    </td>
  );
};
