import { Instance, types } from 'mobx-state-tree';
import { ComponentModel, FunctionModel } from '../../../internal';
import { smoothlyScrollToPageTop } from '../../../utils/scroll';

export const OfferStepsModel = types
  .compose(
    ComponentModel,
    types.model({
      beforeStepSelectedCallback: types.maybe(FunctionModel),
    })
  )
  .views((self) => {
    return {
      get isFirstSelected() {
        return self.value === '1';
      },
      get isSecondSelected() {
        return self.value === '2';
      },
      get isThirdSelected() {
        return self.value === '3';
      },
    };
  })
  .actions((self) => {
    return {
      afterCreate() {
        if (!self.value) {
          self.value = '1';
        }
      },
      selectStep(stepId: string) {
        if (
          self.beforeStepSelectedCallback &&
          !self.beforeStepSelectedCallback(stepId)
        ) {
          return;
        }
        self.value = stepId;
        smoothlyScrollToPageTop();
      },
    };
  })
  .named('OfferStepsModel');

export type OfferStepsModelType = Instance<typeof OfferStepsModel>;
