import { types } from 'mobx-state-tree';
import { PageModel } from '../../../../internal';

export const CreateOfferSuccessPageModel = types
  .compose(
    PageModel,
    types.model({
      standardPackageSelected: types.maybe(types.boolean),
      numberOfTargetetedUsers: types.maybe(types.string),
    })
  )
  .views((self) => {
    return {};
  })
  .actions((self) => {
    return {
      setStandardPackageSelected(is: boolean) {
        self.standardPackageSelected = is;
      },
      setNumberOfTargetedUsers(num: string | number) {
        self.numberOfTargetetedUsers = `${num}`;
      },
    };
  });
