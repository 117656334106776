import { getRoot, IAnyModelType, types } from 'mobx-state-tree';
import { SEGMENTED, WHOLE_SERBIA } from '../../../../constants';
import { FROM_AGE_OPTIONS, TO_AGE_OPTIONS } from '../../../../constants/age';
import {
  AdditionalLocationModel,
  AdditionalUserModel,
  ButtonModel,
  BuyAdditionalPackageModalModel,
  CheckboxGroupModel,
  CheckboxModel,
  CreateOfferPageModel,
  DropdownModel,
  DropdownOptionModel,
  DropdownOptionModelType,
  ModalModel,
  RootType,
  RoundButtonModel,
  TagGroupModel,
  TextInputModel,
  UploadCodesModel,
  UserLocation,
} from '../../../../internal';

export const OfferTargetingModel: IAnyModelType = types
  .model({
    parent: types.maybe(
      types.reference(types.late(() => CreateOfferPageModel))
    ),
  })
  .views((self) => {
    return {
      get root(): RootType {
        return getRoot(self);
      },
    };
  })
  .actions((self) => {
    const isEnabledFunc = (selfie: any) => {
      const { isInactive } = self.root.router.queryParams;
      if (isInactive) {
        return true;
      }

      return (
        !self.parent?.isUpdateMode && self.parent?.getTargetSection().editMode
      );
    };
    return {
      updateAdditionalLocations() {
        self?.parent
          ?.getAdditionalLocation()
          .setNumberOfAdditionalLocations(
            this.getLocationTagGroup().numberOfExcessElements
          );
      },
      setTargetSectionContinueStatus() {
        self?.parent
          ?.getLocationPickContinue()
          .setLabel(
            this.getLocationTagGroup().maxExceeded
              ? 'basic:add_and_confirm'
              : 'basic:confirm_location'
          );

        if (this.getLocationTagGroup().maxExceeded) {
          self?.parent
            ?.getAdditionalLocation()
            .getContractAddons(self?.parent?.contractId, 'location');
        }
      },
      updateDisabledLocations() {
        const tags: { id: string; name: string }[] =
          this.getLocationTagGroup().tagsArray;
        const userLocations = this.getUserLocationDropdown();
        const tagNames = tags.map((tag) => tag.name);
        userLocations.optionsArray.forEach(
          (option: DropdownOptionModelType) => {
            const data: UserLocation = option.data as UserLocation;
            option.setDisabled(
              tagNames.includes(data.location) ||
                tagNames.includes(WHOLE_SERBIA) ||
                tagNames.includes(data.location_group)
            );
          }
        );
      },
      clearLocationTextDropdown() {
        this.getUserLocationDropdown().resetValue();
      },
      getUserLocationDropdown() {
        return (
          self?.parent?.components.get('UserLocationDropdown') ||
          self?.parent?.addComponent(
            DropdownModel.create({
              id: 'UserLocationDropdown',
              label: 'basic:select_user_location',
              placeholder: 'Izaberi opštinu/grad',
              initialValue: ' ', // nothing is selected by default
              isRequired: true,
              step: 2,
              isEnabledFunc,
              onChangeCallback: (element: { id: any; value: any }) => {
                if (element.id) {
                  this.getLocationTagGroup().add({
                    id: element.id,
                    name: element.value,
                  });
                  this.updateAdditionalLocations();
                  this.setTargetSectionContinueStatus();
                  this.updateDisabledLocations();
                }
              },
              options: {},
            })
          )
        );
      },
      getLocationTagGroup() {
        return (
          self?.parent?.components.get('LocationTagGroup') ||
          self?.parent?.addComponent(
            TagGroupModel.create({
              id: 'LocationTagGroup',
              maxNumber: 5,
              tags: {},
              step: 2,
              isEnabledFunc,
              removeCallback: () => {
                this.updateAdditionalLocations();
                this.setTargetSectionContinueStatus();
                this.updateDisabledLocations();
                const group = self?.parent?.components.get('LocationTagGroup');
                if (!group?.tagsArray?.length) {
                  this.clearLocationTextDropdown();
                }
              },
            })
          )
        );
      },
      getGenderDropdown() {
        return (
          self?.parent?.components.get('GenderDropdown') ||
          self?.parent?.addComponent(
            DropdownModel.create({
              id: 'GenderDropdown',
              isRequired: false,
              isEnabledFunc,
              options: {
                ' ': DropdownOptionModel.create({
                  id: ' ',
                  value: 'user_segments:both_male_and_female_gender',
                }),
                M: DropdownOptionModel.create({
                  id: 'M',
                  value: 'user_segments:male_gender',
                }),
                Ž: DropdownOptionModel.create({
                  id: 'Ž',
                  value: 'user_segments:female_gender',
                }),
              },
            })
          )
        );
      },

      getFromAge() {
        return (
          self?.parent?.components.get('FromAge') ||
          self?.parent?.addComponent(
            DropdownModel.create({
              id: 'FromAge',
              isRequired: false,
              options: this.initOptions(FROM_AGE_OPTIONS),
              isEnabledFunc,
              onChangeCallback: () => {
                const fromAge = this.getFromAge();
                const toAge = this.getToAge();
                const oldToAgeValue = toAge.value;
                // if no value is selected, the filter doesn't do anything(fromAge.value check)
                toAge.setOptions(
                  Object.values(TO_AGE_OPTIONS).filter((elem: any) => {
                    return (
                      !fromAge.value ||
                      Number(elem.value) >= Number(fromAge.value)
                    );
                  }),
                  false
                );
                // set options resets value, we set it if it makes sense(sometimes it doesn't)
                if (toAge.hasOption(oldToAgeValue)) {
                  toAge.setValue(oldToAgeValue);
                }
              },
            })
          )
        );
      },

      getToAge() {
        return (
          self?.parent?.components.get('ToAge') ||
          self?.parent?.addComponent(
            DropdownModel.create({
              id: 'ToAge',
              isRequired: false,
              isEnabledFunc,
              onChangeCallback: () => {
                const fromAge = this.getFromAge();
                const toAge = this.getToAge();
                const oldFromAgeValue = fromAge.value;
                // if no value is selected, the filter doesn't do anything(toAge.value check)
                fromAge.setOptions(
                  Object.values(FROM_AGE_OPTIONS).filter((elem: any) => {
                    return (
                      !toAge.value || Number(elem.value) <= Number(toAge.value)
                    );
                  }),
                  false
                );
                // set options resets value, we set it if it makes sense(sometimes it doesn't)
                if (fromAge.hasOption(oldFromAgeValue)) {
                  fromAge.setValue(oldFromAgeValue);
                }
              },
              options: this.initOptions(TO_AGE_OPTIONS),
            })
          )
        );
      },

      initOptions(options: any) {
        const retVal: any = {};
        for (const key in options) {
          retVal[key] = DropdownOptionModel.create(options[key]);
        }
        return retVal;
      },

      getUsersPickedSimilarOffersDropdown() {
        return (
          self?.parent?.components.get('UsersPickedSimilarOffersDropdown') ||
          self?.parent?.addComponent(
            DropdownModel.create({
              id: 'UsersPickedSimilarOffersDropdown',
              isRequired: false,
              initialValue: '0',
              options: {
                1: DropdownOptionModel.create({
                  id: '1',
                  value: 'binary:yes',
                }),
                0: DropdownOptionModel.create({
                  id: '0',
                  value: 'binary:no',
                }),
              },
            })
          )
        );
      },

      getNumberOfCoupons() {
        return (
          self?.parent?.components.get('NumberOfCoupons') ||
          self?.parent?.addComponent(
            TextInputModel.create({
              id: 'NumberOfCoupons',
              step: 1,
              type: 'number',
              min: 1,
              placeholder: '0',
              isRequired: true,
              setValueCallback: () => {
                this.getEnterCodesModel().clearFile();
              },
            })
          )
        );
      },

      getUserSegments() {
        return (
          self?.parent?.components.get('UserSegments') ||
          self?.parent?.addComponent(
            CheckboxGroupModel.create({
              id: 'UserSegments',
              isRequired: true,
              step: 2,
              selectedCheckboxes: {
                // the data has 'serbian' format in the BE
                Biznis: 'Biznis',
                Postpejd: 'Postpejd',
                Pripejd: 'Pripejd',
              },
              checkboxes: {
                Biznis: CheckboxModel.create({
                  id: 'Biznis',
                  label: 'user_segments:business',
                  isEnabledFunc,
                }),
                Postpejd: CheckboxModel.create({
                  id: 'Postpejd',
                  label: 'user_segments:postpaid',
                  isEnabledFunc,
                }),
                Pripejd: CheckboxModel.create({
                  id: 'Pripejd',
                  label: 'user_segments:prepaid',
                  isEnabledFunc,
                }),
              },
            })
          )
        );
      },
      getMobileNetworkSpending() {
        return (
          self?.parent?.components.get('MobileNetworkSpending') ||
          self?.parent?.addComponent(
            CheckboxGroupModel.create({
              id: 'MobileNetworkSpending',
              selectedCheckboxes: {},
              step: 2,
              checkboxes: {
                ARPU_RANK1: CheckboxModel.create({
                  isEnabledFunc,
                  id: 'ARPU_RANK1',
                  label: 'user_segments:mobile_network_big_spenders',
                }),
                ARPU_RANK2: CheckboxModel.create({
                  isEnabledFunc,
                  id: 'ARPU_RANK2',
                  label: 'user_segments:mobile_network_medium_spenders',
                }),
                ARPU_RANK3: CheckboxModel.create({
                  isEnabledFunc,
                  id: 'ARPU_RANK3',
                  label: 'user_segments:mobile_network_small_spenders',
                }),
              },
            })
          )
        );
      },
      getAreaDropdown() {
        return (
          self?.parent?.components.get('AreaDropdown') ||
          self?.parent?.addComponent(
            DropdownModel.create({
              id: 'AreaDropdown',
              //label: 'user_segments:urban_or_rural_area', // temporary, see LOY-394
              isRequired: false,
              initialValue: ' ',
              isEnabledFunc,
              options: {
                ' ': DropdownOptionModel.create({
                  id: ' ',
                  value: 'user_segments:both_rural_and_urban_area',
                }),
                R: DropdownOptionModel.create({
                  id: 'R',
                  value: 'user_segments:rural_area',
                }),
                U: DropdownOptionModel.create({
                  id: 'U',
                  value: 'user_segments:urban_area',
                }),
              },
            })
          )
        );
      },
      getAdditionalLocation() {
        return (
          self?.parent?.components.get('AdditionalLocationModel') ||
          self?.parent?.addComponent(
            AdditionalLocationModel.create({
              id: 'AdditionalLocationModel',
              numberOfAdditionalLocations:
                this.getLocationTagGroup().numberOfExcessElements,
              modal: ModalModel.create({
                id: 'AdditionalLocationPriceListModal',
                label: 'Price List',
                opened: false,
                showCloseButton: true,
              }),
              prices: {},
            })
          )
        );
      },

      getAdditionalUsers() {
        return (
          self?.parent?.components.get('AdditionalUsersModel') ||
          self?.parent?.addComponent(
            AdditionalUserModel.create({
              id: 'AdditionalUsersModel',
              numberOfAdditionalUsersField: TextInputModel.create({
                id: 'numAdditionalUsers',
                type: 'number',
                value: '0',
                min: 0,
              }),
              modal: ModalModel.create({
                id: 'AdditionalUsersPriceListModal',
                label: 'Price List',
                opened: false,
                showCloseButton: true,
              }),
              prices: {},
            })
          )
        );
      },
      async openBuyAdditionalPackage() {
        const root = self?.parent?.root;
        await root?.api?.contract?.getContractExtensions(
          self?.parent?.contractId
        );
        self?.parent
          ?.getBuyAdditionalPackage()
          .setAdditionalPackages(root.data.contractExtensions);
        self?.parent?.getBuyAdditionalPackage()?.modal?.setOpened?.(true);
      },
      closeBuyAdditionalPackage() {
        self?.parent?.getBuyAdditionalPackage()?.modal?.setOpened?.(false);
      },
      getBuyAdditionalPackage() {
        return (
          self?.parent?.components.get('BuyAdditionalPackage') ||
          self?.parent?.addComponent(
            BuyAdditionalPackageModalModel.create({
              id: 'BuyAdditionalPackage',
              additionalPackages: {},
              modal: ModalModel.create({
                id: 'modal',
                showCloseButton: true,
                opened: false,
              }),
              onAfterActivate: async () => {
                const contractId = self?.parent?.contractId;
                const root = self?.parent?.root;
                // after activating a package we refresh the package pick with these two lines
                await root?.api?.purchasedExtension?.getAllPurchasedExtensions(
                  contractId
                );
                self?.parent?.updatePackagePick();

                // Then we select the last package which was the one we just bought
                const packagePickModel = self?.parent?.getPackagePickModel();
                const lastOptionindex =
                  packagePickModel.optionsArray.length - 1;
                packagePickModel.selectOption(lastOptionindex);
                this.closeBuyAdditionalPackage();
              },
            })
          )
        );
      },
      getEnterCodesModel() {
        return (
          self.parent?.components.get('UploadCodesModel') ||
          self.parent?.addComponent(
            UploadCodesModel.create({
              id: 'UploadCodesModel',
              step: 2,
              isRequired: true,
              codeTypeDropdown: DropdownModel.create({
                id: 'UploadCodesModel',
                label: 'basic:code_type',
                isRequired: !self?.parent?.getOfferSteps().isSecondSelected,
                step: 2,
                onChangeCallback: (option: { id: string; value: string }) => {
                  const uploadCodesModel =
                    self.parent?.components?.get('UploadCodesModel');
                  if (option.id === 'personalized') {
                    // if the option is personalized, then the user has to pick
                    // from a checkbox container view
                    uploadCodesModel?.checkboxContainer?.setIsRequired(true);
                    uploadCodesModel?.textInputField?.setIsRequired(false);
                  } else {
                    // meaning 'common', then checkbox is irrelevant
                    // but the text code is everything
                    uploadCodesModel?.checkboxContainer?.setIsRequired(false);
                    uploadCodesModel?.textInputField?.setIsRequired(true);
                  }
                },
                options: {
                  1: DropdownOptionModel.create({
                    id: 'common',
                    value: 'basic:same_code_type',
                  }),
                  2: DropdownOptionModel.create({
                    id: 'personalized',
                    value: 'basic:personalized_code_type',
                  }),
                },
              }),
              buttonSend: RoundButtonModel.create({
                id: 'EnterCodesButtonSend',
                label: 'basic:send',
              }),
              textInputField: TextInputModel.create({
                id: 'EnterCodesInputNumber',
                label: 'Unesi kod',
                isRequired: true,
                step: 2,
                placeholder: 'AX34BH',
              }),
              buttonAddTable: RoundButtonModel.create({
                id: 'EnterCodesButtonAddTable',
                label: 'basic:add_table',
              }),
              buttonChange: ButtonModel.create({
                id: 'EnterCodesButtonChange',
                label: 'basic:change_table',
                onClickCallback: () => {
                  this.getEnterCodesModel().openModal();
                },
              }),
              uploadCodesCount: 0,
              checkboxContainer: CheckboxGroupModel.create({
                id: 'CodeUploadTypes',
                isRequired: true,
                step: 2,
                selectedCheckboxes: {},
                checkboxes: {
                  numeric: CheckboxModel.create({
                    id: 'numeric',
                    label: 'user_segments:numeric',
                  }),
                  bar: CheckboxModel.create({
                    id: 'bar',
                    label: 'user_segments:barcode',
                  }),
                  qr: CheckboxModel.create({
                    id: 'qr',
                    label: 'user_segments:qrcode',
                  }),
                },
              }),
              modal: ModalModel.create({
                id: 'UploadCodesModal',
                label: 'UploadCodes',
                opened: false,
                step: 2,
              }),
            })
          )
        );
      },
    };
  });
