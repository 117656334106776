import { Instance, types } from 'mobx-state-tree';
import {
  ActivatePackageModel,
  AdditionalPackageModel,
  ComponentModel,
  ContractExtension,
  ModalModel,
  FunctionModel,
} from '../../../internal';
import { toAmount } from '../../../utils/methods';

export const BuyAdditionalPackageModalModel = types
  .compose(
    ComponentModel,
    types.model({
      additionalPackages: types.map(types.late(() => AdditionalPackageModel)),
      modal: types.late(() => ModalModel),
      onAfterActivate: types.maybe(types.late(() => FunctionModel)),
    })
  )
  .actions((self) => {
    return {
      initActivateModal(elem: ContractExtension) {
        return ActivatePackageModel.create({
          id: 'ActivatePackageModel',
          name: elem.name,
          contractId: elem.contract_id,
          contractExtensionId: elem.id,
          onAfterActivate: () => {
            self?.onAfterActivate?.(elem.id);
          },
          modal: ModalModel.create({
            id: 'modal',
            showCloseButton: false,
            opened: false,
          }),
        });
      },

      initAdditionalPackage(elem: ContractExtension) {
        return AdditionalPackageModel.create({
          id: elem.id,
          name: elem.name,
          base: false,
          userNumber: toAmount(Number(elem.targets)),
          locationNumber: toAmount(Number(elem.locations)),
          durationDays: Number(elem.days_valid),
          oldMonthlyPayment: `${
            elem.old_price ? toAmount(Number(elem.old_price)) : ''
          }`,
          currentMonthlyPayment: `${toAmount(Number(elem.price))}`,
          activateModal: this.initActivateModal(elem),
        });
      },

      setAdditionalPackages(contractExtensions: ContractExtension[]) {
        self.additionalPackages.clear();
        self.additionalPackages.set(
          '1',
          AdditionalPackageModel.create({
            id: '1',
            name: 'Članarina',
            base: true,
            userNumber: '-',
            locationNumber: '-',
            durationDays: 0,
            currentMonthlyPayment: `${toAmount(0)}`,
            activateModal: ActivatePackageModel.create({
              id: 'ActivatePackageModel',
              name: 'Članarina',
              modal: ModalModel.create({
                id: 'modal',
                showCloseButton: false,
                opened: false,
              }),
            }),
          })
        );
        [...contractExtensions]
          .sort((a, b) => (Number(a.price) < Number(b.price) ? -1 : 1))
          .forEach((elem: ContractExtension) => {
            self.additionalPackages.set(
              elem.id,
              this.initAdditionalPackage(elem)
            );
          });
      },
    };
  })
  .views((self) => {
    return {
      get additionalPackagesArray() {
        return Array.from(self.additionalPackages.values());
      },
    };
  })
  .named('BuyAdditionalPackageModalModel');

export type BuyAdditionalPackageModalModelType = Instance<
  typeof BuyAdditionalPackageModalModel
>;
