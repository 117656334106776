import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import clsx from 'clsx';
import { DropdowOptionGroupsModelType } from '../../../internal';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { Checkbox } from '../checkbox/Checkbox';
import { getCategoryIdFromSubcategory } from '../../../utils/methods';
import dropdownStyles from '../dropdown/Dropdown.module.scss';
import styles from './DropdownOptionGroupsOptions.module.scss';
import '../dropdown/BaseDropdown.scss';

export interface IDropdownOptionGroupsOptionsProps {
  model: DropdowOptionGroupsModelType;
}

export const DropdownOptionGroupsOptions: FC<IDropdownOptionGroupsOptionsProps> =
  observer(({ model }) => {
    const { t } = useTranslation();

    const refDropdown = useDetectClickOutside({
      onTriggered: () => {
        model.isOpen && model.toggleIsOpen();
      },
    });

    function getCategoryNameFromId(categorySuncategoryId: string): any {
      const categoryId = getCategoryIdFromSubcategory(categorySuncategoryId);
      return model.optionGroupsArray.find(
        (optionGroup: any) => optionGroup.id === categoryId
      )?.label;
    }

    return (
      <div ref={refDropdown} className={dropdownStyles.SelectContainer}>
        <div
          className={clsx(
            dropdownStyles.SelectLabel,
            styles.CustomDropdownLabels
          )}
        >
          <span>
            {t('offer:categories_offer')}
            <span className={styles.Required}>*</span>
          </span>
          <span className={styles.FadedLabel}>{t('offer:max_categories')}</span>
        </div>
        <div
          id={model.id}
          className={dropdownStyles.SelectContent}
          onClick={(e: any) => {
            e.stopPropagation();
            model.toggleIsOpen();
          }}
        >
          <div className={dropdownStyles.SelectContent}>
            <div className="select-search__input">
              {model.selectedOptionsArray.length > 0
                ? getCategoryNameFromId(
                    model.selectedOptionsArray[
                      model.selectedOptionsArray.length - 1
                    ]
                  )
                : t('offer:choose_category_subcategory')}
            </div>
          </div>
          <div className={styles.SelectArrows}>
            <div className={styles.SelectArrowsInnerContainer}>
              <div className={styles.UpArrow} />
              <div className={styles.DownArrow} />
            </div>
          </div>
        </div>
        {model.isOpen && (
          <div className={styles.DropdownOptionGroupsOptionsList}>
            {model.optionGroupsArray.map(
              (optionGroup: any, optionGroupIndex: number) => {
                return (
                  <>
                    <Checkbox
                      key={optionGroup.id}
                      model={model.optionGroupsArray[optionGroupIndex]}
                      typeClass={'OptionGroup'}
                    />
                    {model.optionsArray.map(
                      (option: any, optionIndex: number) => {
                        if (
                          model.selectedOptionGroupsArray.includes(
                            optionGroup.id
                          ) &&
                          optionGroup.id ===
                            getCategoryIdFromSubcategory(option.id)
                        ) {
                          return (
                            <Checkbox
                              key={option.id}
                              model={model.optionsArray[optionIndex]}
                              typeClass={'Option'}
                            />
                          );
                        }
                      }
                    )}
                  </>
                );
              }
            )}
          </div>
        )}
      </div>
    );
  });
