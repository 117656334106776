import { flow, Instance, types } from 'mobx-state-tree';

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const ProgressBarModel = types
  .model({
    id: types.identifier,
    progress: 0,
    duration: 1000,
    tick: 10,
    started: false,
  })
  .actions((self) => {
    return {
      setProgress(progress: number) {
        self.progress = Math.max(self.progress, Math.min(progress, 100));
      },
    };
  })
  .actions((self) => {
    let st: number;
    let stopUpdating = false;
    return {
      startProgress: flow(function* () {
        self.started = true;
        st = new Date().getTime();
        while (true && !stopUpdating) {
          yield sleep(self.tick + Math.random() * 1000);
          const diff = Math.round(new Date().getTime() - st);
          self.setProgress(Math.round((diff / self.duration) * 100));
          if (diff >= self.duration || self.progress === 100) {
            stopUpdating = true;
          }
        }
      }),
    };
  })
  .named('ProgressBarModel');

export type ProgressBarType = Instance<typeof ProgressBarModel>;
