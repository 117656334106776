import { observer } from 'mobx-react';
import * as React from 'react';
import { AccountListFiltersModelType, useMst } from '../../../../../internal';
import { DropdownWithSearch } from '../../../../UI/dropdown/dropdown-with-search/DropdownWithSearch';
import { InputField } from '../../../../UI/input/InputField';
import styles from './AccountListFilters.module.scss';
import { Dropdown } from '../../../../UI/dropdown/Dropdown';
import DOWNLOAD_ICON from '../../../../../assets/icons/icon-download.png';
import { CSVDownload } from 'react-csv';

export const AccountListFilters = observer(
  (props: { model: AccountListFiltersModelType }): JSX.Element => {
    const { model } = props;
    const { currentPage } = useMst();
    const [isDownloadClicked, setIsDownloadClicked] = React.useState(false);

    return (
      <div className={styles.Container}>
        <div className={styles.InputFieldContainer}>
          <DropdownWithSearch model={model.company} />
        </div>
        <div className={styles.InputFieldContainer}>
          <DropdownWithSearch model={model.category} />
        </div>
        <div className={`${styles.InputFieldContainer} ${styles.FieldMargin}`}>
          <InputField model={model.pib} />
        </div>
        <div className={styles.InputFieldContainer}>
          <DropdownWithSearch model={model.partnershipExpert} />
        </div>
        <div className={`${styles.InputFieldContainer} ${styles.FieldMargin}`}>
          <Dropdown model={model.segmentation} />
        </div>
        <div className={`${styles.InputFieldContainer} ${styles.FieldMargin}`}>
          <div className={styles.ExportCsvContainer}>
            <a
              onClick={async () => {
                await currentPage.downloadCSV();
                setIsDownloadClicked(true);
              }}
            >
              <img
                src={DOWNLOAD_ICON}
                alt="download"
                className={styles.DownloadIcon}
              />
              Sačuvaj tabelu
            </a>
            {isDownloadClicked && (
              <CSVDownload
                data={currentPage.getCsvData().replaceAll('"', '')} //https://github.com/react-csv/react-csv/issues/249
              />
            )}
          </div>
        </div>
      </div>
    );
  }
);
