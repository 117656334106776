import { Instance, types } from 'mobx-state-tree';
import { ActivatePackageModel, ComponentModel } from '../../../internal';

export const AdditionalPackageModel = types
  .compose(
    ComponentModel,
    types.model({
      name: types.string,
      base: types.boolean,
      userNumber: types.string,
      locationNumber: types.string,
      durationDays: types.number,
      oldMonthlyPayment: types.maybe(types.string),
      currentMonthlyPayment: types.string,
      activateModal: types.maybe(types.late(() => ActivatePackageModel)),
    })
  )
  .actions((self) => {
    return {};
  })
  .views((self) => {
    return {};
  })
  .named('AdditionalPackageModel');

export type AdditionalPackageModelType = Instance<
  typeof AdditionalPackageModel
>;
