import { Instance, types } from 'mobx-state-tree';
import {
  ComponentModel,
  DropdownModel,
  TextInputModel,
} from '../../../../../internal';

export const AccountListFiltersModel = types
  .compose(
    ComponentModel,
    types.model({
      company: types.late(() => DropdownModel),
      category: types.late(() => DropdownModel),
      pib: types.late(() => TextInputModel),
      partnershipExpert: types.late(() => DropdownModel),
      segmentation: types.late(() => DropdownModel),
    })
  )
  .views((self) => {
    return {};
  })
  .actions((self) => {
    return {};
  })
  .named('AccountListFiltersModel');

export type AccountListFiltersModelType = Instance<
  typeof AccountListFiltersModel
>;
