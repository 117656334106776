import { getRoot, types } from 'mobx-state-tree';
import { ADMIN_EMAIL_ALREADY_EXISTS } from '../../constants';
import { HttpVerbs } from '../../constants/verbs';
import i18n from '../../i18n';
import {
  ApiListResponse,
  ApiObjectResponse,
  PageRoutes,
  Partner,
  PartnerAdminNew,
  PartnerAdminPublic,
  RootType,
} from '../../internal';

export const PartnerApi = types
  .model()
  .views((self) => {
    return {
      get root(): RootType {
        return getRoot(self);
      },
    };
  })
  .actions((self) => {
    return {
      topOffersRemaining(partnerId: string) {
        return self.root.axios.requestData({
          type: HttpVerbs.GET,
          path: `/partners/${partnerId}/top-offers-remaining`,
        });
      },
      uploadImage(partnerId: string) {
        const image = self.root.currentPage.getFileInputs()[0].filesArray[0];
        return new Promise((resolve) => {
          self.root.axios
            .uploadFile({
              path: `/partners/${partnerId}/image`,
              fileKey: 'image',
              file: image,
            })
            .then(() => {
              resolve(true);
            });
        });
      },
      getAllActivePartners(): Promise<ApiListResponse<Partner[]>> {
        return self.root.axios.requestData({
          path: '/partners?status=active&limit=1000',
          type: HttpVerbs.GET,
        });
      },
      async getAllPartners() {
        const response = await self.root.axios.getAll('/partners');
        return response;
      },
      async getAllCatalogs() {
        const response = await self.root.axios.getAll('/catalogs');
        self.root.data.setCatalogs(response.data);
        return response;
      },

      async getAllPartnerDocuments(partnerId: string) {
        return await self.root.axios.requestData({
          type: HttpVerbs.GET,
          path: `/partner-documents/${partnerId}`,
        });
      },

      async getPartnerDocument(partnerId: string, documentId: string) {
        return await self.root.axios.requestData({
          type: HttpVerbs.GET,
          path: `/partner-documents/${partnerId}/${documentId}`,
        });
      },

      getAdminAccounts(params: {
        id?: string;
        partner_main_account?: number;
        role_id?: string;
        category: string;
        pib?: string;
        name?: string;
        status?: string;
        order_by?: string;
        order?: string;
        offset?: number;
        limit?: number;
      }) {
        return new Promise((resolve) => {
          self.root.axios
            .requestData({
              type: HttpVerbs.GET,
              path: '/partners/admins',
              params,
            })
            .then((response: ApiListResponse<PartnerAdminNew[]>) => {
              resolve(response);
              self.root.data.setAccounts(response.data);
              self.root.data.setTotalAccountsCount(response.meta.total);
            });
        });
      },
      async getAdminAccountsCount(params: {
        partner_id?: string;
        partner_main_account?: number;
        role_id?: string;
        category: string;
        pib?: string;
        name?: string;
        status?: string;
        order_by?: string;
        order?: string;
      }): Promise<number> {
        return (
          await self.root.axios.requestData({
            type: HttpVerbs.GET,
            path: '/partners',
            params: { ...params, offset: 0, limit: 1 },
          })
        )?.meta?.total;
      },

      // non main partner admins + main admin (requirements change)
      getOtherPartnerAdmins(partnerId: string) {
        return new Promise((resolve) => {
          self.root.axios
            .getAll('/partners', {
              ...(partnerId ? { id: partnerId } : {}),
              partner_main_account: [0, 1],
            })
            .then((response: any) => {
              resolve(response);
              self.root.data.setOtherPartnerAdmins(response.data);
            });
        });
      },

      getPartners(offset: number, limit: number) {
        const offsetQuery = offset ? `&offset=${offset}` : '';
        const limitSizeQuery = limit ? `&limit=${limit}` : '';
        if (
          self.root.pages.get(PageRoutes.PartnerProfiles.id).getPartnersModel()
            ?.options.size
        ) {
          return Promise.resolve();
        } else {
          return new Promise((resolve) => {
            self.root.axios
              .requestData({
                type: HttpVerbs.GET,
                path: `/partners?${offsetQuery}${limitSizeQuery}`,
              })
              .then((response: ApiListResponse<Partner>) => {
                resolve(response);
                self.root.pages
                  .get(PageRoutes.PartnerProfiles.id)
                  .getPartnersModel()
                  .setOptions(
                    response.data?.map((option: any) => ({
                      data: { ...option },
                      value: option.name,
                      id: option.id,
                    })),
                    false
                  );
              });
          });
        }
      },

      get(id: string) {
        return new Promise((resolve) => {
          self.root.axios
            .requestData({
              type: HttpVerbs.GET,
              path: `/partners/${id}`,
            })
            .then((response: ApiObjectResponse<Partner>) => {
              self.root.data.setPartnerDetails(response.data);
              resolve(response);
            });
        });
      },
      adminErrorHandler(error: {
        response: {
          data: {
            error: {
              description: string;
              type: string;
            };
          };
        };
      }) {
        if (
          error?.response?.data?.error?.description ===
          ADMIN_EMAIL_ALREADY_EXISTS
        ) {
          return i18n.t('forms:admin_exists');
        } else {
          return 'Error.';
        }
      },
      save(partner: Partner | PartnerAdminPublic) {
        // for some reason it won't POST with the 'params' object

        return new Promise((resolve) => {
          self.root.axios
            .requestData({
              type: HttpVerbs.POST,
              data: partner,
              path: '/partners/admins',
              customErrorMessage: (err: any) => this.adminErrorHandler(err),
            })
            .then(
              (response: ApiObjectResponse<Partner | PartnerAdminPublic>) => {
                resolve(response);
              }
            );
        });
      },
      update(partner: Partner | PartnerAdminPublic) {
        return new Promise((resolve) => {
          self.root.axios
            .requestData({
              type: HttpVerbs.PUT,
              data: { ...partner },
              path: '/partners/admins',
            })
            .then(
              (response: ApiObjectResponse<Partner | PartnerAdminPublic>) => {
                resolve(response);
              }
            );
        });
      },
      updatePartner() {
        const { currentPage } = self.root;
        return new Promise((resolve) => {
          self.root.axios
            .requestData({
              type: HttpVerbs.PUT,
              path: `/partners/${self.root.header.partnerId}`,
              data: {
                phone: currentPage.getContactPhoneResponsiblePerson().value,
                customer_contact_phone:
                  currentPage.getPhoneForConsumersContact().value,
                email: currentPage.getTelenorAgentEmail().value,
                customer_contact_email:
                  currentPage.getEmailForConsumersContact().value,
                web: currentPage.getWebAddress().value,
                name: currentPage?.getCompanyNameOnAds()?.value,
                segmentation: currentPage?.getSegmentModel()?.value,

                // other fields are not editable
                // active: self.root.data.partnerDetails.active,
                // pay: self.root.data.partnerDetails.pay,
                // category: currentPage.getCategoryModel().value,
                // pib: self.root.data.partnerDetails.pib,
                // mbr: self.root.data.partnerDetails.mbr,
                // address: self.root.data.partnerDetails.address,
                // city: self.root.data.partnerDetails.city,
                // zip: self.root.data.partnerDetails.zip,
                //
                // logo: '', // TODO when backend is ready
              },
            })
            .then((response: ApiObjectResponse<any>) => {
              resolve(response);
              this.disableInputFields();
            });
        });
      },
      disableInputFields() {
        const { currentPage } = self.root;
        currentPage.getWebAddress().setDisabled(true);
        currentPage.getContactPhoneResponsiblePerson().setDisabled(true);
        currentPage.getTelenorAgentEmail().setDisabled(true);
        currentPage.getCategoryModel().setDisabled(true);
        currentPage.getPhoneForConsumersContact().setDisabled(true);
        currentPage.getEmailForConsumersContact().setDisabled(true);
        currentPage.getCompanyNameOnAds().setDisabled(true);
        currentPage.getSegmentModel().setDisabled(true);
      },
      approve(partnerId: string) {
        return self.root.axios.requestData({
          type: HttpVerbs.PUT,
          path: `/partners/${partnerId}/approve`,
        });
      },
      reject(partnerId: string) {
        return self.root.axios.requestData({
          type: HttpVerbs.PUT,
          path: `/partners/${partnerId}/reject`,
        });
      },
      sendUpdatePartnerAdminEmail(data: { email: string; id: string }) {
        return new Promise((resolve, reject) => {
          self.root.axios
            .requestData({
              type: HttpVerbs.POST,
              path: '/partners/send-email-update-partner-admin-email',
              data: data,
              customErrorMessage: (err: any) => this.adminErrorHandler(err),
            })
            .then((response: any) => {
              resolve(response);
              console.log('extract verification code', response);
            })
            .catch((err: any) => {
              reject(err);
            });
        });
      },
      delete(id: string) {
        return new Promise((resolve) => {
          self.root.axios
            .requestData({
              type: HttpVerbs.DELETE,
              path: `/partners/${id}`,
            })
            .then((response: any) => {
              resolve(response);
            });
        });
      },

      updatePartnerAdminEmail(obj: {
        id: string;
        new_email: string;
        verification_code: string;
      }) {
        return new Promise((resolve) => {
          self.root.axios
            .requestData({
              type: HttpVerbs.PUT,
              path: '/partners/update-admin-email',
              data: obj,
            })
            .then((updateResponse: any) => {
              resolve(updateResponse);
            });
        });
      },

      exportPartners() {
        return new Promise((resolve) => {
          self.root.axios
            .requestData({
              type: HttpVerbs.GET,
              path: '/partners/export',
            })
            .then((response: any) => {
              resolve(response);
            });
        });
      },
    };
  });
