import { HttpVerbs } from '../../constants/verbs';
import { RootType, ApiListResponse, PageRoutes } from '../../internal';
import { getRoot, types } from 'mobx-state-tree';
import { StringifyOptions } from 'querystring';

export interface ContractRequest {
  catalog_id: string;
  partner_id: string;
  status: string;
  name: string;
  submitted_by: string;
  submitted_at: string;
  approved_by: string;
  approved_at: string;
  rejected_by: string;
  rejected_at: string;
  discount_telco: number;
  discount_soho: number;
  discount_so: number;
  discount_btl: number;
  created_by: string;
  created_at: string;
  reject_reason: string;
  id: string;
}

export const ContractRequestApi = types
  .model({})
  .views((self) => {
    return {
      get root(): RootType {
        return getRoot(self);
      },
    };
  })
  .actions((self) => {
    return {
      submit(submitBody: any) {
        return self.root.axios.requestData({
          type: HttpVerbs.PUT,
          path: '/contract-requests/submit',
          data: { ...submitBody },
        });
      },
      approve(contractRequestId: string) {
        return self.root.axios.requestData({
          type: HttpVerbs.PUT,
          path: `/contract-requests/${contractRequestId}/approve`,
        });
      },
      reject(contractRequestId: string, reject_reason: string) {
        return self.root.axios.requestData({
          type: HttpVerbs.PUT,
          path: `/contract-requests/${contractRequestId}/reject`,
          data: { reject_reason },
        });
      },
    };
  });
