import { getRoot, Instance, types } from 'mobx-state-tree';
import {
  Contract,
  ContractRequest,
  ModalModel,
  Partner,
  PartnerAdminNew,
  RootType,
} from '../../../../internal';

export const ApproveRejectPackageModalModel = types
  .compose(
    ModalModel,
    types.model({
      partnerAdmin: types.late(() => types.frozen<PartnerAdminNew>()),
    })
  )
  .views((self) => {
    return {
      get root(): RootType {
        return getRoot(self);
      },
    };
  })
  .actions((self) => {
    return {
      open() {
        self.setOpened(true);
      },
      close() {
        self.setOpened(false);
      },
      setPartnerAdmin(partnerAdmin: PartnerAdminNew) {
        self.partnerAdmin = partnerAdmin;
      },
      async approve(contractRequestId: string) {
        await self.root.api.contractRequest.approve(contractRequestId);

        const partnerAdminsResponse =
          await self.root.api.partner.getAdminAccounts();
        self.root.currentPage
          .getCreatedAccountList()
          .setAccounts(partnerAdminsResponse.data);

        self.root.currentPage
          .getCreatedAccountList()
          .filters.company.resetValue();

        this.close();
      },
      async reject(contractRequestId: string) {
        self.root.api.contractRequest.reject(contractRequestId);

        const partnerAdminsResponse =
          await self.root.api.partner.getAdminAccounts();
        self.root.currentPage
          .getCreatedAccountList()
          .setAccounts(partnerAdminsResponse.data);

        self.root.currentPage
          .getCreatedAccountList()
          .filters.company.resetValue();

        this.close();
      },
    };
  })
  .named('BillingModalModel');

export type ApproveRejectPackageModalModelType = Instance<
  typeof ApproveRejectPackageModalModel
>;
