import React from 'react';
import { observer } from 'mobx-react';
import { ReactElement } from 'react';
import DownloadLink from 'react-download-link';
import { useTranslation } from 'react-i18next';
import { CreateAccountModelType, useMst } from '../../../../internal';
import { LightButton } from '../../../UI/buttons/light-button/LightButton';
import { Button } from '../../../UI/buttons/regular/Button';
import { CheckboxGroup } from '../../../UI/checkbox/CheckboxGroup';
import { Dropdown } from '../../../UI/dropdown/Dropdown';
import { InputField } from '../../../UI/input/InputField';
import { UploadContractModal } from '../../upload-contract/UploadContractModal';
import PartnershipExpert from '../partnership-expert/PartnershipExpert';
import styles from './CreateAccount.module.scss';
import durationViewStyles from '../../duration/StandardDurationPick.module.scss';
import deleteIcon from '../../../../assets/icons/icons8_trash_32px.png';
import { dateSRLocale } from '../../../../utils/date';
import { PROMO_PACKAGE } from '../../../../utils/constants';
import { PARTNER } from '../../../../constants/roleTypes';

export const CreateAccount = observer(
  (props: { model: CreateAccountModelType }): ReactElement => {
    const { model } = props;
    const { t } = useTranslation();
    const {
      currentPage,
      user: { role },
    } = useMst();
    const promptAlert: React.CSSProperties = {
      textDecoration: 'underline',
      color: ' #002340',
      fontSize: '14px',
    };
    return (
      <div className={styles.Container}>
        <div className={styles.ColumnsContainer}>
          <div className={styles.Column}>
            <div className={styles.FieldsContainer}>
              <div className={styles.InputFieldContainer}>
                <InputField model={model.company} />
              </div>
              <div className={styles.InputFieldContainer}>
                <InputField model={model.pib} />
              </div>
              <div className={styles.InputFieldContainer}>
                <InputField model={model.uniqueNumber} />
              </div>
              <div className={styles.InputFieldContainer}>
                <InputField model={model.street} />
              </div>
              <div className={styles.InputFieldContainer}>
                <InputField model={model.streetNumber} />
              </div>
              <div className={styles.InputFieldContainer}>
                <InputField model={model.apartmentNumber} />
              </div>
              <div className={styles.InputFieldContainer}>
                <InputField model={model.city} />
              </div>
              <div className={styles.InputFieldContainer}>
                <InputField model={model.zip} />
              </div>
              <div className={styles.InputFieldContainer}>
                <InputField model={model.pak} />
              </div>
              <div className={styles.InputFieldContainer}>
                <Dropdown model={model.companyLegalForm} />
              </div>
              {/* <div className={styles.InputFieldContainer}>
                <InputField model={model.sapId} />
              </div>
              <div className={styles.InputFieldContainer}>
                <InputField model={model.contractIdExternal} />
              </div>
              <div className={styles.InputFieldContainer}>
                <Dropdown model={model.taxPayer} />
              </div>
              </div> */}
            </div>
          </div>
          <div className={styles.Column}>
            <div className={styles.FieldsContainer}>
              <div className={styles.InputFieldContainer}>
                <Dropdown model={model.billingAddressDropdown} />
              </div>
              {model.billingAddressDropdown.value === 'other' && (
                <>
                  {' '}
                  <div className={styles.InputFieldContainer}>
                    <InputField model={model.billingAddressStreet} />
                  </div>
                  <div className={styles.InputFieldContainer}>
                    <InputField model={model.billingAddressStreetNumber} />
                  </div>
                  <div className={styles.InputFieldContainer}>
                    <InputField model={model.billingAddressApartmentNumber} />
                  </div>
                  <div className={styles.InputFieldContainer}>
                    <InputField model={model.billingAddressCity} />
                  </div>
                  <div className={styles.InputFieldContainer}>
                    <InputField model={model.billingAddressZip} />
                  </div>
                  <div className={styles.InputFieldContainer}>
                    <InputField model={model.billingAddressPak} />
                  </div>{' '}
                </>
              )}
              <div className={styles.InputFieldContainer}>
                <Dropdown model={model.contractType} />
              </div>
              <div className={styles.InputFieldContainer}>
                <div className={durationViewStyles.LowMarginLabel}>
                  {t('basic:contract_start_date')}: {dateSRLocale(new Date())}
                </div>
              </div>
              <div className={styles.InputFieldContainer}>
                <Dropdown model={model.numberOfEmployees} />
              </div>
              <div className={styles.InputFieldContainer}>
                <InputField model={model.email} />
              </div>
              <div className={styles.NoteContainer}>
                {t('forms:account_email_send_note')}
              </div>
              <div className={styles.InputFieldContainer}>
                <InputField model={model.adminFirstName} />
              </div>
              <div className={styles.InputFieldContainer}>
                <InputField model={model.adminLastName} />
              </div>
              <div className={styles.InputFieldContainer}>
                <InputField model={model.adminPhoneNumber} />
              </div>
              {role.name !== PARTNER && (
                <div className={styles.InputFieldContainer}>
                  <Dropdown model={model.segmentation} />
                </div>
              )}
            </div>
          </div>
          <div className={styles.Column}>
            <div className={styles.FieldsContainer}>
              <div className={styles.InputFieldContainer}>
                <Dropdown model={model.category} />
              </div>
              <div className={styles.InputFieldContainer}>
                <Dropdown model={model.hasYettelPay} />
              </div>
              <div className={styles.InputFieldContainer}>
                <PartnershipExpert model={model.partnershipExpert} />
              </div>
              <>
                <div className={styles.InputFieldContainer}>
                  <Dropdown model={model.catalogs} />
                </div>
                {model.catalogs.selectedOption?.calculator !==
                  PROMO_PACKAGE && (
                  <>
                    <div className={styles.CheckboxInputFieldContainer}>
                      <div className={styles.CodeTypeLabel}>
                        {model.catalogs.selectedOption?.calculator ===
                        'pay-as-you-go'
                          ? `${t('basic:tariff_package_addition')}`
                          : `${t('basic:tariff_package_addition')}`}
                      </div>
                      <CheckboxGroup
                        model={
                          model.catalogs.selectedOption?.calculator ===
                          'pay-as-you-go'
                            ? model.payPerAdPackageAddition
                            : model.monthlyPackageAddition
                        }
                      />
                    </div>
                    {model.catalogs.selectedOption?.calculator !==
                      'pay-as-you-go' && (
                      <div className={styles.SmallInputFieldContainer}>
                        <InputField model={model.btlDiscount} />
                      </div>
                    )}
                  </>
                )}
              </>
            </div>

            <div className={styles.UploadBtnContainer}>
              <div className={styles.CodeTypeLabel}>
                {`${t('dropdown:contract_contract_type')}`}
              </div>
              {model.filesArray.map((file: File, index: number) => {
                return (
                  <React.Fragment key={index}>
                    {file !== null && (
                      <div className={styles.ContractContainer}>
                        <p>{file.name}</p>
                        <div className={styles.DownloadDeleteContainer}>
                          <DownloadLink
                            style={promptAlert}
                            label="Preuzmi"
                            filename={file.name}
                            exportFile={() => file}
                          />
                          <img
                            className={styles.DeleteIcon}
                            src={deleteIcon}
                            alt="delete"
                            onClick={() => {
                              model.removeFile(file);
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                );
              })}
              <LightButton
                model={model.uploadDocumentBtn}
                onClick={() => {
                  model.getUploadContractModel().modal.setOpened(true);
                }}
              />
            </div>
          </div>
        </div>
        <div className={styles.SaveBtnContainer}>
          <Button
            model={model.saveBtn}
            onClick={() => {
              model.save(async () => {
                await currentPage.initCompanies();
                await currentPage.loadData();
                currentPage.getTabs().selectIndex(0);
              });
            }}
          />
        </div>
        <UploadContractModal model={model.getUploadContractModel()} />
      </div>
    );
  }
);
