import { getRoot, Instance, types } from 'mobx-state-tree';
import {
  ComponentModel,
  ModalModel,
  PublishAd,
  RootType,
} from '../../../internal';

export const ApproveAdModel = types
  .compose(
    ComponentModel,
    types.model({
      modal: types.late(() => ModalModel),
      type: types.maybe(types.string),
    })
  )
  .actions((self) => {
    return {
      openModal(value: string) {
        self.type = value;
        self.modal.setOpened(true);
      },
      openApproveModal() {
        const adDetails = self.root.currentPage;
        adDetails.getApproveRejectAd().setApproved();
        adDetails.getApproveRejectAd().openModal();
      },
      openRejectModal() {
        const adDetails = self.root.currentPage;
        adDetails.getApproveRejectAd().setRejected();
        adDetails.getApproveRejectAd().openModal();
      },
      async publishAd() {
        try {
          const adDetails = self.root.currentPage;
          if (adDetails.update) {
            await self.root.api.adUpdate.publishAdUpdate(
              adDetails.update.id,
              self.publishAdBody
            );
          } else {
            await self.root.api.offers.publishAd(
              self.root.router.queryParams.id,
              self.publishAdBody
            );
          }
          this.openApproveModal();
        } catch (err) {
          console.log(err);
        }
      },
      async rejectAd() {
        try {
          const adDetails = self.root.currentPage;
          if (adDetails.update) {
            await self.root.api.adUpdate.rejectAdUpdate(
              adDetails.update.id,
              self.root.currentPage.getTextInputField().value
            );
          } else {
            await self.root.api.offers.rejectAd(
              self.root.router.queryParams.id,
              self.root.currentPage.getTextInputField().value
            );
          }
          this.openRejectModal();
        } catch (err) {
          console.log(err);
        }
      },
      stopAd() {
        self.root.api.offers.stopAd(self.root.router.queryParams.id);
      },
    };
  })
  .views((self) => {
    return {
      get root(): RootType {
        return getRoot(self);
      },
      get publishAdBody() {
        return {
          label_text: self.root.currentPage.labelText,
          label_color: self.root.currentPage.labelColor,
          recommended: Number(
            self.root.currentPage.getRecommendedDropdown().value
          ),
        } as any as PublishAd;
      },
    };
  })
  .named('ApproveAdModel');

export type ApproveAdModelType = Instance<typeof ApproveAdModel>;
