import { IAnyModelType, Instance, types } from 'mobx-state-tree';
import { TagGroupModel, ComponentModel } from '../../../internal';

export const TagModel: IAnyModelType = types
  .compose(
    ComponentModel,
    types.model({
      parent: types.maybe(types.reference(types.late(() => TagGroupModel))),
    })
  )
  .actions((self) => {
    return {
      setParent(parent: string) {
        self.parent = parent;
      },
      remove() {
        if (!self.isDisabled) {
          self.parent.remove(self);
        }
      },
    };
  })
  .views((self) => {
    return {
      get isDisabled() {
        return self?.parent?.isDisabled;
      },
    };
  })
  .named('TagModel');

export type TagModelType = Instance<typeof TagModel>;
