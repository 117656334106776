import arrowBack from '../../../../assets/icons/arrow-back.svg';
import iconSearch from '../../../../assets/icons/search.svg';

import styles from './Header.module.scss';
import * as React from 'react';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { OfferPreview } from '../OfferDetailsPreview';

export const Header = observer(
  (props: { model: OfferPreview }): ReactElement => {
    const { t } = useTranslation();
    const { model } = props;
    return (
      <div className={styles.Breadcrumbs}>
        <div className={styles.Inner}>
          <div className={styles.Link}>
            <img src={arrowBack} alt={'arrow right'} />
          </div>
          <div className={styles.TitleText}>
            {model.partner?.name
              ? model.partner.name
              : t('offer:offer_details')}
          </div>
        </div>
        <div className={styles.SearchIconWrapper}>
          <img className={styles.Search} src={iconSearch} alt={'search'} />
        </div>
      </div>
    );
  }
);
