import styles from './Header.module.scss';
import { YETTEL } from '../../../constants/index';
import Link from '../../UI/link';
import { useMst, PageRoutes } from '../../../internal';
import { observer } from 'mobx-react';
import { TranslationSwitcher } from '../translation-switcher/TranslationSwitcher';
import React from 'react';
import { Show } from '../show/Show';
import { PARTNER_PROFILES, MODERATOR_PROFILE } from '../../../constants/header';

export const Header = observer(() => {
  const { router } = useMst();
  const {
    router: { navigate },
    header: {
      isModeratorProfileTabActive,
      isPartnerProfilesTabActive,
      setActiveTab,
      partnerName,
      currentUser,
      isModerator,
      isPartner,
      isSalesman,
      isAdministrator,
    },
    user: { token },
    api: {
      admin: { logout },
    },
    currentPage,
  } = useMst();
  return (
    <nav className={styles.MainNavigation}>
      <div className={styles.TranslationSwitcherContainer}>
        <TranslationSwitcher />
      </div>
      <div className={styles.NavTopContainer}>
        <div className={styles.NavTopContent}>
          <a
            className={styles.Logo}
            onClick={() => navigate({ newView: PageRoutes.HomeRedirect.id })}
          >
            {YETTEL}
          </a>
          {token && (
            <>
              {!isPartner && (
                <div className={styles.ModeratorTopNav}>
                  <div className={styles.NavModeratorContent}>
                    <ul className={styles.NavigationModerator}>
                      <Link
                        className={
                          isModeratorProfileTabActive
                            ? styles.ActiveModerator
                            : styles.InactiveModerator
                        }
                        view={router.views.get(PageRoutes.AllAds.id)}
                      >
                        <span
                          onClick={() => {
                            setActiveTab(MODERATOR_PROFILE);
                            navigate(PageRoutes.AllAds.id);
                          }}
                        >
                          Profil moderatora
                        </span>
                      </Link>
                      {partnerName === undefined ? (
                        <Link
                          className={
                            isPartnerProfilesTabActive
                              ? styles.ActiveModerator
                              : styles.InactiveModerator
                          }
                          view={router.views.get(PageRoutes.PartnerProfiles.id)}
                        >
                          <span
                            onClick={() => {
                              setActiveTab(PARTNER_PROFILES);
                              navigate(PageRoutes.PartnerProfiles.id);
                            }}
                          >
                            Profili firmi
                          </span>
                        </Link>
                      ) : (
                        <Link
                          className={
                            isPartnerProfilesTabActive
                              ? styles.ActiveModerator
                              : styles.InactiveModerator
                          }
                          view={router.views.get(PageRoutes.CompanyProfile.id)}
                        >
                          <span
                            onClick={() => {
                              currentPage.root.header.setActiveTab(
                                PARTNER_PROFILES
                              );
                              return Promise.all([
                                currentPage.root.api.contract.getContracts(
                                  currentPage.root.header.partnerId,
                                  0,
                                  1000
                                ),
                                currentPage.root.api.partnerLocation.getPartnerLocations(
                                  currentPage.root.header.partnerId
                                ),
                                navigate({
                                  newView: PageRoutes.CompanyProfile.id,
                                  shouldReplace: true,
                                }),
                              ]);
                            }}
                          >
                            Profili firmi
                          </span>
                        </Link>
                      )}
                    </ul>
                  </div>
                </div>
              )}
              <div className={styles.UserContainer}>
                <Show condition={isPartnerProfilesTabActive}>
                  {partnerName !== undefined ? (
                    <>
                      <span className={styles.PartnerName}>{partnerName}</span>
                      {(isModerator || isAdministrator || isSalesman) && (
                        <>
                          <Link
                            view={router.views.get(
                              PageRoutes.PartnerProfiles.id
                            )}
                          >
                            <span
                              className={styles.ChangeLinkContainer}
                              onClick={() => {
                                setActiveTab(PARTNER_PROFILES);
                                navigate(PageRoutes.PartnerProfiles.id);
                              }}
                            >
                              Izmeni
                            </span>
                          </Link>
                          <span className={styles.Pipe}>|</span>
                        </>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </Show>
                <span className={styles.Username}>{currentUser}</span>
                <img className={styles.IconUser} alt={''} />
                <span onClick={logout} className={styles.Username}>
                  Odjava
                </span>
              </div>
            </>
          )}
        </div>
      </div>
    </nav>
  );
});
