import { observer } from 'mobx-react';
import { SliderModelType } from '../../../models/ui/slider/SliderModel';
import styles from './AgeSlider.module.scss';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { Tooltip } from 'react-tippy';
import filterIcon from './../../../assets/icons/filter_icon.png';

const AgeSlider = observer((props: { model: SliderModelType }): JSX.Element => {
  const { model } = props;

  const markStyle = (numberLabel: number) => ({
    style: {
      fontSize: '15px',
      fontFamily: 'Yettel-Light',
      color: '#B7B7B7',
    },
    label: <strong>{numberLabel}</strong>,
  });

  const marks = {
    18: (
      <Tooltip
        theme={'light'}
        html={
          <div style={{ padding: '1em' }}>
            <span>18</span>
          </div>
        }
        position="bottom"
      >
        |
      </Tooltip>
    ),
    19: (
      <Tooltip
        theme={'light'}
        html={
          <div style={{ padding: '1em' }}>
            <span>19</span>
          </div>
        }
        position="bottom"
      >
        |
      </Tooltip>
    ),
    20: (
      <Tooltip
        theme={'light'}
        html={
          <div style={{ padding: '1em' }}>
            <span>20</span>
          </div>
        }
        position="bottom"
      >
        |
      </Tooltip>
    ),
    25: markStyle(25),
    30: markStyle(30),
    35: markStyle(35),
    40: markStyle(40),
    45: markStyle(45),
    50: markStyle(50),
    55: markStyle(55),
    60: markStyle(60),
    65: markStyle(65),
    70: markStyle(70),
    75: markStyle(75),
    80: markStyle(80),
    85: markStyle(85),
    90: markStyle(90),
    95: markStyle(95),
    100: markStyle(100),
    105: markStyle(105),
  };
  const realMarks = !model.isAgeFrom ? { ...marks, 110: 110 } : marks;

  return (
    <>
      <div className={styles.AgeSlider}>
        <div className={styles.filterHeader}>
          <img className={styles.filterIcon} src={filterIcon} />
          <span> Filter </span>
        </div>
        <Slider
          className="my-slider"
          min={18}
          max={!model.isAgeFrom ? 110 : 105}
          defaultValue={18}
          value={model.value}
          marks={realMarks}
          step={null}
          onChange={model.onChange}
        />
      </div>
    </>
  );
});

export default AgeSlider;
