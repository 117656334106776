import { Instance, types } from 'mobx-state-tree';
import { ButtonModel, ComponentModel, FunctionModel } from '../../../internal';
import { ChangeEvent } from 'react';

const valueParsers: any = {
  number: (val: string) => Number(val),
};

export const TextInputModel = types
  .compose(
    ComponentModel,
    types.model({
      setValueCallback: types.maybe(FunctionModel),
      max: types.maybe(types.number),
      min: types.maybe(types.number),
      inputFieldArrowsHidden: false,
      showSideSign: false,
      sign: types.maybe(types.string),
      placeholder: types.maybe(types.string),
      icon: types.maybe(types.string),
      resetButton: types.maybe(types.late(() => ButtonModel)),
    })
  )
  .actions((self) => {
    return {
      clearInput() {
        self.value = '';
      },
      setStringValue(value: string) {
        self.value = value;
      },
      onChange(e: ChangeEvent) {
        const value = (e.target as HTMLInputElement).value;
        this.manageValue(value);
        if (self.setValueCallback) {
          self.setValueCallback(value);
        }
      },
      resetInput() {
        if (self.setValueCallback) {
          self.setValueCallback(null);
        }
      },
      setMax(num: number) {
        self.max = num;
      },
      formatInput: (e: any) => {
        if (self.type === 'number') {
          // Prevent characters that are not numbers ("e", "+" & "-"), allow only  "." by client requirement
          let checkIfNum;
          if (e.key !== undefined) {
            // Check if it's a "e", "+" or "-"
            checkIfNum = e.key === 'e' || e.key === '+' || e.key === '-';
          } else if (e.keyCode !== undefined) {
            // Check if it's a "e" (69), "+" (187) or "-" (189)
            checkIfNum =
              e.keyCode === 69 || e.keyCode === 187 || e.keyCode === 189;
          }
          return checkIfNum && e.preventDefault();
        }
      },
      manageValue(value: string) {
        if (self.max !== undefined && this.parseValue(value) > self.max) {
          self.setValue('');
        } else if (
          self.min !== undefined &&
          this.parseValue(value) < self.min
        ) {
          self.setValue('');
        } else {
          self.setValue(value);
        }
      },
      parseValue(value: string) {
        const parser = valueParsers[self.type];
        if (parser) {
          return parser(value);
        }
        return value;
      },
      setInputFieldArrowsHidden(value: boolean) {
        self.inputFieldArrowsHidden = value;
      },
    };
  })
  .named('TextInputModel');

export type TextInputModelType = Instance<typeof TextInputModel>;
