import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import accountWhite from '../../../assets/icons/account-white.png';
import cancel from '../../../assets/icons/cancel.png';
import styles from './MobileHeader.module.scss';
import { HamburgerIcon } from '../../../images/Icons';
import { MobileHeaderProfileModal } from './MobileHeaderProfileModal';
import { MobileHeaderMenuModal } from './MobileHeaderMenuModal';
import { PageRoutes } from '../../../utils/PageRoutes';
import { useMst } from '../../../rootInstance';

export const MobileHeader = observer(() => {
  const {
    router,
    user: { token },
  } = useMst();
  const [isMobileHeaderProfileOpen, setIsMobileHeaderProfileOpen] =
    useState(false);
  const [isMobileHeaderMenuOpen, setIsMobileHeaderMenuOpen] = useState(false);

  const handleMobileHeaderProfileClick = () => {
    setIsMobileHeaderProfileOpen(!isMobileHeaderProfileOpen);
    setIsMobileHeaderMenuOpen(false);
  };

  const handleMobileHeaderMenuClick = () => {
    setIsMobileHeaderMenuOpen(!isMobileHeaderMenuOpen);
    setIsMobileHeaderProfileOpen(false);
  };

  useEffect(() => {
    setIsMobileHeaderMenuOpen(false);
    setIsMobileHeaderMenuOpen(false);
  }, [router.currentView]);

  return (
    <>
      <div className={styles.MobileHeader}>
        <div
          className={styles.MobileHeaderLogo}
          onClick={() => {
            router.navigate({ newView: PageRoutes.HomeRedirect.id });
            setIsMobileHeaderMenuOpen(false);
            setIsMobileHeaderProfileOpen(false);
          }}
        >
          Y. Shopping
        </div>

        {token && (
          <div className={styles.MobileHeaderMenu}>
            <div
              className={styles.MobileHeaderClickable}
              onClick={handleMobileHeaderProfileClick}
            >
              <img
                src={accountWhite}
                className={styles.MobileHeaderProfileImg}
                alt={'profile'}
              />
            </div>
            <div
              className={styles.MobileHeaderClickable}
              onClick={handleMobileHeaderMenuClick}
            >
              {isMobileHeaderMenuOpen ? (
                <img
                  src={cancel}
                  alt={'cancel'}
                  className={styles.cancelMenu}
                />
              ) : (
                <HamburgerIcon className={styles.HamburgerMenu} />
              )}
            </div>
            <MobileHeaderProfileModal
              isOpen={isMobileHeaderProfileOpen}
              setIsOpen={setIsMobileHeaderProfileOpen}
            />
            <MobileHeaderMenuModal
              isOpen={isMobileHeaderMenuOpen}
              setIsOpen={setIsMobileHeaderMenuOpen}
            />
          </div>
        )}
      </div>
    </>
  );
});
