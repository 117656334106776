export const YETTEL = 'Yettel.';
export const YSHOPPING = 'Y. Shopping';
export const STANDARD = 'standard';
export const SPECIAL = 'special';
export const FEATURED = 'prominent';
export const TOP = 'top';
export const PAYMENT_BY_TRANSACTION = 'trans';
export const WHOLE_SERBIA = 'Cela Srbija';
export const CODE_COUNT_MISMATCH = 'code count mismatch';
export const ADMIN_EXISTS = 'Admin exists';
export const LINK_EXPIRED = 'link expired';
export const ADMIN_EMAIL_ALREADY_EXISTS = 'admin email already exists';
export const EMAIL_MISSING = 'email missing';

export const ADMIN_STATUS_REQUESTED = 'requested';
export const ADMIN_STATUS_SUBMITTED = 'submitted';

export const ADMIN_STATUS_ACTIVE = 'active';
export const ADMIN_STATUS_NONACTIVE = 'nonactive';
export const ADMIN_STATUS_INACTIVE = 'inactive';
export const YETTEL_CATEGORY = 'Yettel';
export const NEW_ADMIN_KEY = 'newAdmin';
export const UPDATE_EMAIL_KEY = 'updateEmail';
export const ID_KEY = 'id';
export const YETTEL_PARTNER = 'Yettel';

export const LONG_TIMEOUT = 5000000000000000000000000;
export const SEGMENTED = 'segmented';
export const UNSEGMENTED = 'unsegmented';
