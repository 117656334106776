import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { Table } from '../../components/domain/ad-list/Table';
import { AdListModelType, IInvoice } from '../../internal';
import styles from './PaymentReport.module.scss';
import { Months } from './PaymentsAndDocumentsTab2';
import { SUBSCRIPTION_WITH_CONTRACT } from '../../utils/constants';

interface PaymentReportProps {
  paymentReport: IInvoice;
  perOfferPaymentsTable?: AdListModelType;
  showMonthAndInvoiceDate: boolean;
}

export const PaymentReport: FC<PaymentReportProps> = observer(
  ({ paymentReport, perOfferPaymentsTable, showMonthAndInvoiceDate }) => {
    if (paymentReport.calculator === 'pay-as-you-go') {
      return (
        <div>
          <div className={styles.tabOneHeader}>
            {showMonthAndInvoiceDate && (
              <h1 className={styles.tabOneHeaderTitle}>
                {Months[Number(paymentReport.month)]} {paymentReport.year}
              </h1>
            )}
            <p className={styles.tabOneContractType}>Plaćanje po oglasu</p>
            <p className={styles.invoiceDates}>
              Datum obračuna: od{' '}
              {new Date(paymentReport.period_start).toLocaleDateString('sr-RS')}{' '}
              do{' '}
              {new Date(paymentReport.period_end).toLocaleDateString('sr-RS')}
            </p>
          </div>

          {perOfferPaymentsTable && (
            <Table model={perOfferPaymentsTable} isAdTable={false} />
          )}

          <div className={styles.sumsTable}>
            <div className={styles.sumsTableRow}>
              <span> Puna cena </span>
              {paymentReport.subtotal ? (
                <span>
                  {paymentReport.subtotal.toLocaleString('sr-RS')} RSD
                </span>
              ) : null}
            </div>
            {paymentReport.discount_btl_amount !== 0 &&
              paymentReport.discount_btl_amount !== null && (
                <div className={styles.sumsTableRow}>
                  <span> BTL popust {paymentReport.discount_btl_value}% </span>
                  <span>
                    -{paymentReport.discount_btl_amount.toLocaleString('sr-RS')}{' '}
                    RSD
                  </span>
                </div>
              )}
            {paymentReport.discount_quantity_amount !== 0 &&
              paymentReport.discount_quantity_amount !== null && (
                <div className={styles.sumsTableRow}>
                  <span>
                    Popust na količinu {paymentReport.discount_quantity_value}%
                  </span>
                  <span>
                    -
                    {paymentReport.discount_quantity_amount.toLocaleString(
                      'sr-RS'
                    )}{' '}
                    RSD
                  </span>
                </div>
              )}
            {paymentReport.discount_so_amount !== 0 &&
              paymentReport.discount_so_amount !== null && (
                <div className={styles.sumsTableRow}>
                  <span>UO popust {paymentReport.discount_so_value}%</span>
                  <span>
                    -{paymentReport.discount_so_amount.toLocaleString('sr-RS')}{' '}
                    RSD
                  </span>
                </div>
              )}
            {paymentReport.discount_soho_amount !== 0 &&
              paymentReport.discount_soho_amount !== null && (
                <div className={styles.sumsTableRow}>
                  <span>
                    Popust na SOHO {paymentReport.discount_soho_value}%
                  </span>
                  <span>
                    -
                    {paymentReport.discount_soho_amount.toLocaleString('sr-RS')}{' '}
                    RSD
                  </span>
                </div>
              )}
            {paymentReport.discount_telco_amount !== 0 &&
              paymentReport.discount_telco_amount !== null && (
                <div className={styles.sumsTableRow}>
                  <span>
                    Telco popust {paymentReport.discount_telco_value}%
                  </span>
                  <span>
                    -
                    {paymentReport.discount_telco_amount.toLocaleString(
                      'sr-RS'
                    )}{' '}
                    RSD
                  </span>
                </div>
              )}
            {paymentReport.product_addon !== 0 &&
              paymentReport.product_addon !== null && (
                <div className={styles.sumsTableRow}>
                  <span> Aktiviranje dodatka +5 top oglasa</span>
                  <span>
                    +{paymentReport.product_addon.toLocaleString('sr-RS')} RSD
                  </span>
                </div>
              )}
            {paymentReport.total ? (
              <div className={styles.sumsTableRowLast}>
                <span> Ukupno za naplatu </span>
                <span>{paymentReport.total.toLocaleString('sr-RS')} RSD</span>
              </div>
            ) : null}
          </div>
        </div>
      );
    }

    if (
      paymentReport.calculator === 'subscription' ||
      paymentReport.calculator === 'subscription-with-contract-obligation'
    ) {
      return (
        <div>
          <div className={styles.tabOneHeader}>
            {showMonthAndInvoiceDate && (
              <h1 className={styles.tabOneHeaderTitle}>
                {Months[Number(paymentReport.month)]} {paymentReport.year}
              </h1>
            )}
            <p className={styles.tabOneContractType}>
              Mesečni tarifni paket -
              {paymentReport.calculator === 'subscription'
                ? ' bez ugovorne obaveze'
                : ' sa ugovornom obavezom'}
            </p>
            <p className={styles.invoiceDates}>
              Datum obračuna: od{' '}
              {new Date(paymentReport.period_start).toLocaleDateString('sr-RS')}{' '}
              do{' '}
              {new Date(paymentReport.period_end).toLocaleDateString('sr-RS')}
            </p>
          </div>

          <div className={styles.sumsTable}>
            <div className={styles.sumsTableRow}>
              <span> Puna cena </span>
              {paymentReport.subtotal ? (
                <span>
                  {paymentReport.subtotal.toLocaleString('sr-RS')} RSD
                </span>
              ) : null}
            </div>
            {paymentReport.discount_btl_amount !== 0 &&
              paymentReport.discount_btl_amount !== null && (
                <div className={styles.sumsTableRow}>
                  <span> BTL popust {paymentReport.discount_btl_value}% </span>
                  <span>
                    -{paymentReport.discount_btl_amount.toLocaleString('sr-RS')}{' '}
                    RSD
                  </span>
                </div>
              )}
            {paymentReport.discount_quantity_amount !== 0 &&
              paymentReport.discount_quantity_amount !== null && (
                <div className={styles.sumsTableRow}>
                  <span>
                    Popust na količinu {paymentReport.discount_quantity_value}%
                  </span>
                  <span>
                    -
                    {paymentReport.discount_quantity_amount.toLocaleString(
                      'sr-RS'
                    )}{' '}
                    RSD
                  </span>
                </div>
              )}
            {paymentReport.discount_so_amount !== 0 &&
              paymentReport.discount_so_amount !== null && (
                <div className={styles.sumsTableRow}>
                  <span>UO popust {paymentReport.discount_so_value}%</span>
                  <span>
                    -{paymentReport.discount_so_amount.toLocaleString('sr-RS')}{' '}
                    RSD
                  </span>
                </div>
              )}
            {paymentReport.discount_soho_amount !== 0 &&
              paymentReport.discount_soho_amount !== null && (
                <div className={styles.sumsTableRow}>
                  <span>
                    Popust na SOHO {paymentReport.discount_soho_value}%
                  </span>
                  <span>
                    -
                    {paymentReport.discount_soho_amount.toLocaleString('sr-RS')}{' '}
                    RSD
                  </span>
                </div>
              )}
            {paymentReport.discount_telco_amount !== 0 &&
              paymentReport.discount_telco_amount !== null && (
                <div className={styles.sumsTableRow}>
                  <span>
                    Telco popust {paymentReport.discount_telco_value}%
                  </span>
                  <span>
                    -
                    {paymentReport.discount_telco_amount.toLocaleString(
                      'sr-RS'
                    )}{' '}
                    RSD
                  </span>
                </div>
              )}
            {paymentReport.product_addon !== 0 &&
              paymentReport.product_addon !== null && (
                <div className={styles.sumsTableRow}>
                  <span> Aktiviranje dodatka +5 top oglasa</span>
                  <span>
                    +{paymentReport.product_addon.toLocaleString('sr-RS')} RSD
                  </span>
                </div>
              )}
            {paymentReport.total ? (
              <div className={styles.sumsTableRowLast}>
                <span> Ukupno za naplatu </span>
                <span>{paymentReport.total.toLocaleString('sr-RS')} RSD</span>
              </div>
            ) : null}
          </div>
        </div>
      );
    }

    return <></>;
  }
);
