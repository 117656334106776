import { observer } from 'mobx-react';
import * as React from 'react';
import { Checkbox } from './Checkbox';
import styles from './CheckboxGroup.module.scss';

export const CheckboxGroup = observer(
  (props: { model: any; id?: string }): JSX.Element => {
    const { model, id } = props;
    return (
      <div id={id} className={styles.CheckboxGroupContainer}>
        {model.checkboxesArray.map((checkboxModel: any, index: number) => {
          return <Checkbox key={index} model={checkboxModel} />;
        })}
      </div>
    );
  }
);
