import { getRoot, Instance, types } from 'mobx-state-tree';
import {
  DropdownModel,
  PageModel,
  PageRoutes,
  Partner,
  RootType,
} from '../../../internal';

export const PartnerProfilesModel = types
  .compose(PageModel, types.model({}))
  .views((self) => {
    return {
      get root(): RootType {
        return getRoot(self);
      },
    };
  })
  .actions((self) => {
    return {
      async beforePageEnter() {
        await this.loadPartners();
        this.getPartnersModel().resetValue();
      },
      async loadPartners() {
        const response = await self.root.api.partner.getAllActivePartners();
        this.getPartnersModel().setOptions(
          response.data?.map((partner: Partner) => ({
            data: { ...partner },
            value: partner.name,
            id: partner.id,
          })),
          false
        );
        return true;
      },
      resetCreateOffer() {
        const createOfferPage = self.root.pages.get(PageRoutes.CreateOffer.id);
        createOfferPage.clearData();
      },
      getPartnersModel() {
        return (
          self?.components.get('PartnersDropdown') ||
          self?.addComponent(
            DropdownModel.create({
              id: 'PartnersDropdown',
              label: 'Izaberite firmu u pretrazi',
              placeholder: 'Izaberi firmu',
              initialValue: ' ', // nothing is selected by default
              isRequired: false,
              onChangeCallback: (element: {
                id: any;
                value: any;
                data: any;
              }) => {
                self.root.user.setPartner(element.data);
                this.resetCreateOffer();
              },
              options: {},
              showIcon: true,
            })
          )
        );
      },
    };
  })
  .named('PartnerProfilesModel');

export type PartnerProfileModelType = Instance<typeof PartnerProfilesModel>;
