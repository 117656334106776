import { types } from 'mobx-state-tree';
import {
  OffersApi,
  AdminApi,
  CategoryApi,
  ContractApi,
  PartnerApi,
  PartnerLocationApi,
  PurchasedExtensionApi,
  SubCategoryApi,
  UserApi,
  GeolocationApi,
  UsedCodesApi,
  AdUpdateApi,
  InvoiceApi,
  ContractRequestApi,
  PurchasesApi,
  PartnerDocumentApi,
  BannersApi,
} from '../internal';

export interface Ad {
  ad_id: string;
  id: string;
  status: string;
  image0: string;
  image1: string;
  image2: string;
  image3: string;
  partner_id: string;
  type: string;
  with_code: string;
  code_type: string;
  common_code: string;
  contract_id: string;
  cost_by: string;
  store_location_type: string;
  store_locations: string;
  categories: [];
  name: string;
  discount_value: string;
  original_price: string;
  discount_description: string;
  discount_percentage: number;
  description: string;
  conditions: string;
  date_start: string;
  date_end: string;
  date_code_end: string;
  url: string;
  visual_type: string;
  ref: string;
  user_locations: string;
  gender: string;
  age_from: number;
  age_to: number;
  similar_only: number;
  user_contract_type: string;
  spender_type: string;
  territory_type: string;
  max_target: number;
  purchased_extension_id: string;
  contract_extension: string;
  contract_addons: string[];
  date_code_start: string;
  rejections: Rejection[];
  created_by: string;
  code_count: number;
  published_at: string;
  published_by: string;
  recommended: number;
  label_text: string;
  label_color: string;
  keywords: string;
  submitted_at: string;
  comment: string;
}
export interface ExtendedAd {
  ad_id: string;
  ad_status: string;
  ad_type: string;
  ad_with_code: string;
  ad_code_type: string;
  ad_store_location_type: string;
  ad_name: string;
  ad_discount_value: string;
  ad_original_price: string;
  ad_discount_percentage: number;
  ad_date_start: string;
  ad_date_end: string;
  ad_date_code_end: string;
  ad_url: string;
  ad_visual_type: string;
  ad_user_locations: string;
  ad_gender: string;
  ad_age_from: number;
  ad_age_to: number;
  ad_user_contract_type: string;
  ad_spender_type: string;
  ad_territory_type: string;
  ad_max_target: number;
  ad_date_code_start: string;
  ad_code_count: number;
  ad_recommended: number;
  ad_keywords: string;
  ad_claimed_codes: string;
  category_id: string;
  category_name: string;
  subcategory_id: string;
  subcategory_name: string;
  partner_id: string;
  partner_name: string;
  partner_legal_name: string;
  ad_rejection_reason: string;
  ad_views: string;
  creator: string;
  publisher: string;
  partnership_expert: string;
  partner_admin_name: string;
  partner_admin_email: string;
  partner_admin_phone: string;
  common_code: string;
  ref: string;
}
export interface AdFilters {
  status?: 'submitted' | 'active' | 'rejected' | 'expired';
  partner_id?: string;
  category_id?: string;
  sub_category_id?: string;
  date_start?: string;
  date_end?: string;
  offset: number;
  limit: number;
}

export interface ApiListResponse<T> {
  statusCode: number;
  data?: T[] | null;
  meta?: any;
}

export interface ApiObjectResponse<T> {
  statusCode: number;
  data?: T | null;
}

export interface Role {
  id: string;
  is_super_admin: string;
  name: string;
  permissions: string;
}

export interface Category {
  id: string;
  name: string;
  icon: string;
  subcategories: SubCategory[];
}

export interface SubCategory {
  id: string;
  category_id: string;
  name: string;
}

export interface PartnerAdminNew {
  admin: {
    role_id: string;
    partner_id: string;
    partner_main_account: number;
    status: string;
    email: string;
    first_name: string;
    last_name: string;
    phone: string;
    id: string;
  };
  partnership_expert: {
    role_id: string;
    partner_id: string;
    partner_main_account: number;
    status: string;
    email: string;
    first_name: string;
    last_name: string;
    phone: string;
    id: string;
  };
  contract: {
    partner_id: string;
    status: string;
    catalog_id: string;
    from: Date;
    to: Date;
    obligation_till: Date;
    name: string;
    calculator: string;
    price_basic: number;
    price_targeted: number;
    price_top: number;
    top_addon_max: number;
    top_addon_price: number;
    top_addon_value: number;
    subscription_price: number;
    subscription_obligation: number;
    discount_quantity_level_1_amount: number;
    discount_quantity_level_1_number: number;
    discount_quantity_level_2_amount: number;
    discount_quantity_level_2_number: number;
    discount_quantity_level_3_amount: number;
    discount_quantity_level_3_number: number;
    id: string;
  };
  contract_request: {
    catalog_id: string;
    partner_id: string;
    status: string;
    name: string;
    submitted_by: string;
    submitted_at: Date;
    approved_by: string;
    approved_at: Date;
    rejected_by: string;
    rejected_at: Date;
    discount_telco: number;
    discount_soho: number;
    discount_so: number;
    discount_btl: number;
    created_by: string;
    created_at: Date;
    reject_reason: string;
    id: string;
  };
  category: string;
  pib: string;
  mbr: string;
  address: string;
  city: string;
  zip: string;
  billing_address: string;
  billing_city: string;
  billing_zip: string;
  pay: number;
  image: string;
  name: string;
  legal_name: string;
  legal_type: string;
  employees: string;
  taxpayer: number;
  agent_contact_email: string;
  partnership_expert_id: string;
  sap_id: string;
  contract_id_external: string;
  cost_by: string;
  status: string;
  promo_till: Date;
  phone: string;
  customer_contact_phone: string;
  email: string;
  customer_contact_email: string;
  web: string;
  created_by: string;
  created_at: string;
  updated_by: Date;
  updated_at: Date;
  id: string;
  segmentation: string;
}

export interface PartnerAdminPublic {
  //newly added
  sap_id?: string;
  contract_id_external?: string;
  created_by?: string;
  updated_by?: string;
  updated_at?: string;
  //existing
  category?: string;
  pib?: string;
  mbr?: string;
  address?: string;
  street?: string;
  street_no?: string;
  app_no?: string;
  billing_address?: string;
  billing_street?: string;
  billing_street_no?: string;
  billing_app_no?: string;
  billing_city?: string;
  billing_zip?: string;
  billing_pak?: string;
  city?: string;
  zip?: string;
  pak?: string;
  phone?: string;
  customer_contact_phone?: string;
  email?: string;
  customer_contact_email?: string;
  web?: string;
  image?: string;
  pay?: number;
  legal_name?: string;
  legal_type?: string;
  employees?: string;
  segmentation?: string;
  agent_contact_email?: string;
  cost_by?: string;
  id?: string;
  status?: string;
  created_at?: string;
  //to be checked with BE
  name?: string;
  active?: number;
  admin_id?: string;
  admin_email?: string;
  admin_first_name?: string;
  first_name?: string;
  admin_last_name?: string;
  last_name?: string;
  admin_phone?: string;
  admin_role_id?: string;
  admin_partner_main_account?: number;
  contract_id?: string;
  contract_type?: string;
  contract_from?: string;
  contract_to?: string;
  contract?: Record<string, unknown>;
  admin?: Record<string, unknown>;
  partnership_expert_id?: string;
  taxpayer?: number;
  catalog_id?: string;
}

export interface Admin {
  email: string;
}

export interface AdAttributes {
  label_text: string;
  label_color: string;
  recommended: number;
  should_reindex: boolean;
}

export interface AdAnalytics {
  views: number;
  codes_total: number;
  codes_used: number;
  codes_claimed: number;
  codes_left: number;
  conversion: number;
}

export interface Partner {
  category: string;
  promo_till: string;
  pib: string;
  mbr: string;
  name: string;
  address: string;
  city: string;
  zip: string;
  phone: string;
  customer_contact_phone: string;
  email: string;
  customer_contact_email: string;
  web: string;
  pay: number;
  active: number;
  image: string;
  legal_name: string;
  legal_type: string;
  employees: string;
  segmentation: string;
  agent_contact_email: string;
  cost_by: string;
  id: string;
}

export interface UserLocation {
  location: string;
  location_group: string;
}

export interface ShoppingMall {
  name: string;
  id: string;
  full_name: string;
  address: string;
  longitude: string;
  latitude: string;
  location_group: string;
  sort: number;
}

export interface PartnerLocation {
  id?: string;
  partner_id?: string;
  name?: string;
  address?: string;
  city?: string;
  email?: string;
  phone?: string;
  work_days?: string;
  saturday?: string;
  sunday?: string;
  longitude?: string;
  latitude?: string;
  shopping_mall_id?: string;
}

export interface Rejection {
  id: string;
  ad_id: string;
  created_at: string;
  created_by: string;
  reason: string;
}

export interface AdminPublic {
  email: string;
  id: string;
  partner_id: string;
  role_id: string;
  status: string;
}

export interface PublishAd {
  label_text: string;
  label_color: string;
  recommended: number;
}

export const ApiModel = types.model('ApiModel', {
  offers: OffersApi,
  adUpdate: AdUpdateApi,
  admin: AdminApi,
  category: CategoryApi,
  contract: ContractApi,
  contractRequest: ContractRequestApi,
  partner: PartnerApi,
  partnerLocation: PartnerLocationApi,
  purchases: PurchasesApi,
  purchasedExtension: PurchasedExtensionApi,
  subcategory: SubCategoryApi,
  user: UserApi,
  geolocation: GeolocationApi,
  usedCodes: UsedCodesApi,
  invoices: InvoiceApi,
  partnerDocument: PartnerDocumentApi,
  banners: BannersApi,
});
