import { observer } from 'mobx-react';
import React from 'react';
import { Modal } from '../../UI/modal/Modal';
import greenCheckmarkIcon from '../../../assets/icons/checkmark_green_icon.png';
import { Button } from '../../UI/buttons/regular/Button';
import styles from './RequestSentModal.module.scss';

export const RequestSentModal = observer((props: any) => {
  const { currentPage } = props;

  const closeModal = () => {
    currentPage.getSendRequestModal().setOpened(false);
  };
  return (
    <Modal
      styles={{
        overlay: {
          backgroundColor: 'rgba(0, 35, 63, 0.5)',
          border: '1px solid #707070',
        },
        content: {
          opacity: '1',
        },
      }}
      model={currentPage.getSendRequestModal()}
    >
      <div className={styles.ModalContent}>
        <img
          className={styles.Checkmark}
          src={greenCheckmarkIcon}
          alt="check"
        />
        <p>
          Vaš zahtev za promenu paketa je prihvaćen.<br></br> Uskoro će Vas
          kontaktirati Vaš Partnership expert.
        </p>
        <div className={styles.Button}>
          <Button
            model={{
              id: 'ok',
              label: 'OK',
              isEnabledComputed: true,
            }}
            onClick={closeModal}
          />
        </div>
      </div>
    </Modal>
  );
});
