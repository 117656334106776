import { useTranslation } from 'react-i18next';
import styles from './DownloadCSVTable.module.scss';
import DOWNLOAD from '../../../../../assets/icons/icon-download.png';
import { useMst } from '../../../../../internal';

export const DownloadCSVTable = (props: {
  partnerId: string;
  adId: string;
  unUsed?: boolean;
  reference?: string;
  label?: string;
}): JSX.Element => {
  const { partnerId, adId, unUsed, reference } = props;
  const { t } = useTranslation();
  const { currentPage } = useMst();
  const adApi = currentPage.root.api.offers;
  return (
    <div
      className={styles.Container}
      onClick={() => {
        unUsed
          ? adApi.downloadCSVCodes(reference, partnerId)
          : adApi.downloadUsedCSVCodes(partnerId, adId);
      }}
    >
      {props.label ? (
        <div className={styles.Link}>{props.label}</div>
      ) : unUsed ? (
        <div className={styles.Link}>
          {t('basic:download_uploaded_csv_table')}
        </div>
      ) : (
        <>
          {' '}
          <img
            className={styles.DownloadImage}
            width={'32px'}
            height={'32px'}
            src={DOWNLOAD}
          />{' '}
          <div className={styles.Link}>
            {t('basic:download_used_csv_table')}
          </div>
        </>
      )}
      {/* {unUsed ? null : (
        <img
          className={styles.DownloadImage}
          width={'32px'}
          height={'32px'}
          src={DOWNLOAD}
        />
      )}
      <div className={styles.Link}>
        {unUsed
          ? t('basic:download_uploaded_csv_table')
          : t('basic:download_used_csv_table')}
      </div> */}
    </div>
  );
};
