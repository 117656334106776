import { Instance, types } from 'mobx-state-tree';
import {
  ButtonModel,
  ComponentModel,
  ModalModel,
  FunctionModel,
} from '../../../internal';

export const ClearCodesWarningModel = types
  .compose(
    ComponentModel,
    types.model({
      modal: types.late(() => ModalModel),
      continueButton: types.late(() => ButtonModel),
      continueCallback: types.maybe(FunctionModel),
      cancelCallback: types.maybe(FunctionModel),
    })
  )
  .actions((self) => {
    return {
      afterCreate() {
        self.continueButton.setCallback(() => self.continue());
      },
      open(continueCallback?: () => void, cancelCallback?: () => void) {
        self.modal.setOpened(true);
        if (continueCallback) {
          self.setContinueCallback(continueCallback);
        }
        if (cancelCallback) {
          self.setCancelCallback(cancelCallback);
        }
      },
      close() {
        self.modal.setOpened(false);
      },
      setContinueCallback(callback: () => void) {
        self.continueCallback = callback;
      },
      setCancelCallback(callback: () => void) {
        self.cancelCallback = callback;
      },
      continue() {
        self.modal.setOpened(false);
        self.root.currentPage.getEnterCodesModel().setUploadCodesCount(0);
        if (self.continueCallback) {
          self.continueCallback();
        }
      },
      cancel() {
        self.modal.setOpened(false);
        if (self.cancelCallback) {
          self.cancelCallback();
        }
      },
    };
  })
  .views((self) => {
    return {};
  })
  .named('ClearCodesWarningModel');

export type ClearCodesWarningModelType = Instance<
  typeof ClearCodesWarningModel
>;
