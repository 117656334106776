import { Instance, types } from 'mobx-state-tree';
import { ComponentModel, FunctionModel } from '../../../internal';

export const ModalModel = types
  .compose(
    ComponentModel,
    types.model({
      opened: types.boolean,
      onAfterOpen: types.maybe(FunctionModel),
      onAfterClose: types.maybe(FunctionModel),
      showCloseButton: types.maybe(types.boolean),
    })
  )
  .actions((self) => {
    return {
      setOnAfterClose(callback: () => void) {
        self.onAfterClose = callback;
      },
      setOpened(opened: boolean) {
        self.opened = opened;
        if (opened) {
          document.body.style.overflow = 'hidden';
        } else {
          document.body.style.overflow = 'initial';
        }
      },
    };
  })
  .views((self) => {
    return {
      get isOpened() {
        return self.opened;
      },
    };
  })
  .named('ModalModel');

export type ModalModelType = Instance<typeof ModalModel>;
