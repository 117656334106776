import { getRoot, types } from 'mobx-state-tree';
import { YETTEL, YETTEL_CATEGORY, YETTEL_PARTNER } from '../../constants';
import { HttpVerbs } from '../../constants/verbs';
import { ApiListResponse, Category, RootType } from '../../internal';

export const CategoryApi = types
  .model({})
  .views((self) => {
    return {
      get root(): RootType {
        return getRoot(self);
      },
    };
  })
  .actions((self) => {
    return {
      async getAllCategories(sort: string) {
        // This code allows us to hide the Yettel category, when the user
        // is a moderator and he selects a partner who is not Yettel
        // Yettel category is only visible to Yettel
        // Regular non-moderator users shouldn't even receive the Yettel category from the BE
        // maybe this could've been solved by sending an optional partnerId param to the BE?
        const header = self?.root?.header;
        const hideYettel =
          header?.isPartnerProfilesTabActive &&
          header?.partnerName !== YETTEL_PARTNER;

        const response = await self.root.axios.getAll('/categories', {
          sort,
          subcategories: true,
        });

        response.data = hideYettel
          ? response.data.filter(
              (cat: Category) => cat.name !== YETTEL_CATEGORY
            )
          : response.data;

        self.root.data.setCategories(response.data);
        return response;
      },
      getCategories(sort: string, offset: number, pageSize: number) {
        const sortQuery = sort ? `&sort=${sort}` : '';
        const offsetQuery = offset ? `&offset=${offset}` : '';
        const pageSizeQuery = pageSize ? `&limit=${pageSize}` : '';
        return new Promise((resolve) => {
          self.root.axios
            .requestData({
              type: HttpVerbs.GET,
              path: `/categories?${sortQuery}${offsetQuery}${pageSizeQuery}`,
            })
            .then((response: ApiListResponse<Category>) => {
              self.root.data.setCategories(response.data);
              resolve(response);
            });
        });
      },
    };
  });
