import React, { ReactElement, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { useMst } from '../../../internal';
import { InputField } from '../../../components/UI/input/InputField';
import { StandardDurationPick } from '../../../components/domain/duration/StandardDurationPick';
import { BannerDurationModal } from './components/BannerDurationModal';
import { FileInput } from '../../../components/UI/file-input/FileInput';
import { TextAreaInputField } from '../../../components/UI/textarea/TextAreaInputField';
import { Button } from '../../../components/UI/buttons/regular/Button';
import { CheckboxGroup } from '../../../components/UI/checkbox/CheckboxGroup';
import cancel from '../../../assets/icons/cancel.png';
import PLUS from '../../../layouts/images/icon-add.svg';
import arrowBack from '../../../assets/icons/arrow-back.svg';
import styles from './Banners.module.scss';
import { ConfirmationModal } from '../../../components/UI/confirmation-modal/ConfirmationModal';

export const CreateBanner = observer((): ReactElement => {
  const { t } = useTranslation();
  const mst = useMst();
  const {
    currentPage,
    router: { queryParams },
  } = mst;
  const { type, id } = queryParams;
  const fields = currentPage.generateFields();

  const removeImage = (e: React.MouseEvent<HTMLElement>, type: string) => {
    e.preventDefault();
    fields[type].clearFiles();
  };

  return (
    <div className={styles.PageContainer}>
      <div
        className={styles.NavigateBack}
        onClick={() => {
          currentPage.navigateToBannersPage(type);
        }}
      >
        <img className={styles.Arrow} src={arrowBack} alt={'arrow right'} />
        <span>{t('banners:back_to_banners')}</span>
      </div>
      <div className={styles.PageTitleContainer}>
        <div className={styles.PageTitle}>
          {t(id ? 'basic:edit_banner' : 'basic:create_banner')}
        </div>
      </div>
      <div className={styles.AdListOuterContainer}>
        <div className={styles.InputFieldContainer}>
          <InputField model={fields.name} />
        </div>
        <div className={styles.CheckboxContainer}>
          <CheckboxGroup model={fields.checkbox_group} />
        </div>
        {type === 'popup' && (
          <div className={styles.TextAreaContainer}>
            <TextAreaInputField model={fields.html_content} />
          </div>
        )}
        <div>
          <label className={styles.InputLabel}>
            {t('banners:desktop_image')}
          </label>
          <div className={styles.FileInput}>
            <FileInput
              model={fields.image_desktop}
              ImageWrapper={({ children }: { children: ReactNode }) => {
                return (
                  <div className={styles.UploadContainer}>
                    {children}
                    <img
                      src={cancel}
                      alt={'cancel'}
                      className={styles.DeleteButton}
                      onClick={(e) => removeImage(e, 'image_desktop')}
                    />
                  </div>
                );
              }}
              LabelRenderer={() => (
                <div className={styles.UploadContainer}>
                  <img className={styles.PlusIcon} src={PLUS} alt={'icon'} />
                  <div className={styles.UploadFileDescription}>
                    {t('banners:add_image')}
                  </div>
                  <div className={styles.Description}>
                    {t('banners:desktop_optimized')}
                  </div>
                </div>
              )}
            />
          </div>
        </div>
        <div>
          <label className={styles.InputLabel}>
            {t('banners:mobile_image')}
          </label>
          <div className={styles.FileInput}>
            <FileInput
              model={fields.image_mobile}
              ImageWrapper={({ children }: { children: ReactNode }) => {
                return (
                  <div className={styles.UploadContainer}>
                    {children}
                    <img
                      src={cancel}
                      alt={'cancel'}
                      className={styles.DeleteButton}
                      onClick={(e) => removeImage(e, 'image_mobile')}
                    />
                  </div>
                );
              }}
              LabelRenderer={() => (
                <div className={styles.UploadContainer}>
                  <img className={styles.PlusIcon} src={PLUS} alt={'icon'} />
                  <div className={styles.UploadFileDescription}>
                    {t('banners:add_image')}
                  </div>
                  <div className={styles.Description}>
                    {t('banners:mobile_optimized')}
                  </div>
                </div>
              )}
            />
          </div>
        </div>
        <div className={styles.InputFieldContainer}>
          <StandardDurationPick
            model={fields.banner_duration}
            label={t('banners:pick_dates_intro')}
          >
            <BannerDurationModal model={fields.banner_duration} />
          </StandardDurationPick>
        </div>
        <div className={styles.InputFieldContainer}>
          <InputField model={fields.url} />
        </div>
        <Button onClick={currentPage.submitData}>{t('banners:save')}</Button>
        {id && (
          <p
            onClick={currentPage.openConfirmationModal}
            className={styles.DeleteBanner}
          >
            {t('banners:delete_banner')}
          </p>
        )}
      </div>
      <ConfirmationModal model={currentPage.getConfirmationModal()}>
        <>
          <div>
            <strong>{t('banners:delete_title')}</strong>
          </div>
          <div>{t('banners:delete_confirmation')}</div>
        </>
      </ConfirmationModal>
    </div>
  );
});
