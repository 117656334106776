import { applySnapshot, getSnapshot, types } from 'mobx-state-tree';
import {
  Ad,
  AdAnalytics,
  Admin,
  Category,
  Contract,
  ContractAddon,
  ContractExtension,
  Partner,
  PartnerAdminPublic,
  PartnerLocation,
  PurchasedExtension,
  SubCategory,
  UserTargets,
  AdminPublic,
  ContractExtended,
  ShoppingMall,
} from '../internal';
import { differenceInDays, isAfter } from 'date-fns';
import {
  SUBSCRIPTION_WITH_CONTRACT,
  SUBSCRIPTION_WITHOUT_CONTRACT,
  PAY_AS_YOU_GO,
  PROMO_PERIOD,
  PROMO_PACKAGE,
} from '../utils/constants';

export const SharedDataModel = types
  .model({
    catalogs: types.frozen(),
    categories: types.optional(types.array(types.frozen<Category>()), []),
    subcategories: types.optional(types.array(types.frozen<SubCategory>()), []),
    partners: types.optional(types.array(types.frozen<Partner>()), []),
    adDetails: types.maybe(types.frozen<Ad>()),
    partnerDetails: types.maybe(types.frozen<Partner>()),
    contractDetails: types.maybe(types.frozen<ContractExtended>()),
    ads: types.optional(types.array(types.frozen<Ad>()), []),
    totalAdsCount: types.maybe(types.number),
    purchasedExtension: types.maybe(
      types.maybeNull(types.frozen<PurchasedExtension>())
    ),
    purchasedExtensions: types.optional(
      types.array(types.frozen<PurchasedExtension>()),
      []
    ),
    partnerLocations: types.optional(
      types.array(types.frozen<PartnerLocation>()),
      []
    ),
    shoppingMalls: types.optional(
      types.array(types.frozen<ShoppingMall>()),
      []
    ),
    contractExtensions: types.optional(
      types.array(types.frozen<ContractExtension>()),
      []
    ),
    locationContractAddons: types.optional(
      types.array(types.frozen<ContractAddon>()),
      []
    ),
    targetContractAddons: types.optional(
      types.array(types.frozen<ContractAddon>()),
      []
    ),
    userTargets: types.optional(types.array(types.frozen<UserTargets>()), []),
    partnerLocationDetails: types.maybe(types.frozen<PartnerLocation>()),
    accounts: types.optional(
      types.array(types.frozen<PartnerAdminPublic>()),
      []
    ),
    totalAccountsCount: types.maybe(types.number),
    // non-main ones
    otherPartnerAdmins: types.optional(
      types.array(types.frozen<PartnerAdminPublic>()),
      []
    ),
    adminDetails: types.maybe(types.frozen<Admin>()),
    adAnalytics: types.maybe(types.frozen<AdAnalytics>()),
    administrators: types.optional(
      types.array(types.frozen<AdminPublic>()),
      []
    ),
    moderators: types.optional(types.array(types.frozen<AdminPublic>()), []),
    sellers: types.optional(types.array(types.frozen<AdminPublic>()), []),
    pageSize: types.maybe(types.number),
    currentPage: types.maybe(types.number),
    alert: false,
    selectedPage: '',
    purchases: types.frozen(),
    csvData: '',
  })
  .views((self) => {
    return {
      get payAsYouGoCatalog() {
        return self.catalogs?.find?.((catalog: any) => {
          return catalog.name === PAY_AS_YOU_GO;
        });
      },
      get monthlyPaymentCatalog() {
        return self.catalogs?.find?.((catalog: any) => {
          return catalog.name === SUBSCRIPTION_WITHOUT_CONTRACT;
        });
      },
      get monthlyPaymentWithContractCatalog() {
        return self.catalogs?.find?.((catalog: any) => {
          return catalog.name === SUBSCRIPTION_WITH_CONTRACT;
        });
      },
      get promoPeriodContractCatalog() {
        return self.catalogs?.find?.((catalog: any) => {
          return catalog.name === PROMO_PERIOD;
        });
      },
      get monthlyPaymentCatalogPrice() {
        return Number(
          this.monthlyPaymentCatalog?.subscription_price -
            this.monthlyPaymentCatalog?.discount_telco *
              (this.monthlyPaymentCatalog?.subscription_price / 100)
        ).toFixed(0);
      },
      get monthlyPaymentWithContractCatalogPrice() {
        return Number(
          this.monthlyPaymentWithContractCatalog?.subscription_price -
            this.monthlyPaymentWithContractCatalog?.discount_telco *
              (this.monthlyPaymentWithContractCatalog?.subscription_price /
                100) -
            this.monthlyPaymentWithContractCatalog?.discount_so *
              (this.monthlyPaymentWithContractCatalog?.subscription_price / 100)
        ).toFixed(0);
      },
      get currentCatalog() {
        return self.catalogs?.find((catalog: any) => {
          return catalog.id === self.contractDetails?.catalog_id;
        });
      },
      get hasPackage() {
        return !!self.contractDetails?.name;
      },
      get isPayAsYouGo() {
        return self.contractDetails?.calculator === 'pay-as-you-go';
      },
      get isMonthly() {
        return self.contractDetails?.calculator === 'subscription';
      },
      get isPromoPackage() {
        return self.contractDetails?.calculator === PROMO_PACKAGE;
      },
      get isSubscription() {
        return (
          self.contractDetails?.calculator ===
          'subscription-with-contract-obligation'
        );
      },
      get monthlyContractId(): string {
        return self.catalogs?.find?.((catalog: any) => {
          return catalog.name === SUBSCRIPTION_WITH_CONTRACT;
        })?.id;
      },
      get hasPurchases() {
        return Boolean(Object.keys(self.purchases || {}).length);
      },
      get isContractExpired() {
        const today = new Date();
        const obligationTill = new Date(
          self.contractDetails?.obligation_till || ''
        );

        return isAfter(today, obligationTill);
      },
      get isPromoPeriodOver() {
        return self.partnerDetails?.promo_till
          ? differenceInDays(
              new Date(self.partnerDetails?.promo_till || ''),
              Date.now()
            ) < 0
          : false;
      },
      get isUnderContractObligation() {
        return self.contractDetails?.obligation_till
          ? isAfter(self.contractDetails?.obligation_till, Date.now())
          : false;
      },
    };
  })
  .actions((self) => {
    let snapshot: any = null;
    return {
      setAlert(alert: boolean) {
        self.alert = alert;
      },
      setSelectedPage(selectedPage: string) {
        self.selectedPage = selectedPage;
      },
      setPurchases(purchases: any) {
        self.purchases = purchases;
      },
      afterCreate() {
        snapshot = getSnapshot(self);
      },
      reset() {
        applySnapshot(self, snapshot);
      },
      setCategories(categories: Category[]) {
        self.categories.replace(categories);
      },
      setSubcategories(subcats: SubCategory[]) {
        self.subcategories.replace(subcats);
      },
      setPartners(partners: Partner[]) {
        self.partners.replace(partners);
      },
      setAds(ads: Ad[]) {
        self.ads.replace(ads);
      },
      setTotalAdsCount(count: number) {
        self.totalAdsCount = count;
      },
      setAdDetails(ad: Ad | undefined) {
        self.adDetails = ad;
      },
      resetAdDetails() {
        self.adDetails = undefined;
      },
      setPartnerDetails(partner: Partner) {
        self.partnerDetails = partner;
      },
      setCatalogs(catalogs: any) {
        self.catalogs = catalogs;
      },
      setPurchasedExtension(extension: PurchasedExtension | null) {
        self.purchasedExtension = extension;
      },
      setPurchasedExtensions(extensions: PurchasedExtension[]) {
        self.purchasedExtensions.replace(extensions);
      },
      setPartnerLocations(locations: PartnerLocation[]) {
        self.partnerLocations.replace(locations);
      },
      setContractDetails(contract: ContractExtended) {
        self.contractDetails = contract;
      },
      setShoppingMalls(shoppingMalls: ShoppingMall[]) {
        self.shoppingMalls.replace(shoppingMalls);
      },
      setContractExtensions(extensions: ContractExtension[]) {
        self.contractExtensions.replace(extensions);
      },
      setLocationContractAddons(addons: ContractAddon[]) {
        self.locationContractAddons.replace(addons);
      },
      setTargetContractAddons(addons: ContractAddon[]) {
        self.targetContractAddons.replace(addons);
      },
      setUserTargets(elems: UserTargets[]) {
        self.userTargets.replace(elems);
      },
      setPartnerLocation(details: PartnerLocation) {
        self.partnerLocationDetails = details;
      },
      setAccounts(elems: Partner[]) {
        self.accounts.replace(elems);
      },
      setTotalAccountsCount(count: number) {
        self.totalAccountsCount = count;
      },
      setOtherPartnerAdmins(elems: Partner[]) {
        self.otherPartnerAdmins.replace(elems);
      },
      setAdminDetails(admin: Admin) {
        self.adminDetails = admin;
      },
      setAdAnalytics(adAnalytics: AdAnalytics) {
        self.adAnalytics = adAnalytics;
      },
      setAdministrators(administrators: AdminPublic[]) {
        self.administrators.replace(administrators);
      },
      setModerators(moderators: AdminPublic[]) {
        self.moderators.replace(moderators);
      },
      setSellers(sellers: AdminPublic[]) {
        self.sellers.replace(sellers);
      },
      setPageSize(pageSize: number) {
        self.pageSize = pageSize;
      },
      setCurrentPage(currentPage: number) {
        self.currentPage = currentPage;
      },
      setCsvString(data: string) {
        self.csvData = data;
      },
    };
  })
  .named('SharedDataModel');
