import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './../../Support.module.scss';
import IMAGE1 from '../../../../assets/images/support/1.png';
import IMAGE2 from '../../../../assets/images/support/2.png';
import IMAGE3 from '../../../../assets/images/support/3.png';
import IMAGE4 from '../../../../assets/images/support/4.png';
import IMAGE5 from '../../../../assets/images/support/5.png';
import IMAGE6 from '../../../../assets/images/support/6.png';
import IMAGE7 from '../../../../assets/images/support/7.png';
import IMAGE8 from '../../../../assets/images/support/8.png';
import IMAGE9 from '../../../../assets/images/support/9.png';
import IMAGE10 from '../../../../assets/images/support/10.png';
import IMAGE11 from '../../../../assets/images/support/11.png';
import IMAGE12 from '../../../../assets/images/support/12.png';
import IMAGE13 from '../../../../assets/images/support/13.png';
import IMAGE14 from '../../../../assets/images/support/14.png';
import IMAGE15 from '../../../../assets/images/support/15.png';
import IMAGE16 from '../../../../assets/images/support/16.png';

export const SetOffer = (): ReactElement => {
  const { t } = useTranslation();
  return (
    <div className={styles.Elements} id="unos-kampanje">
      <div className={`${styles.Element} ${styles.ElementMargin}`}>
        <div>{t('basic:set_offer_step1')}</div>
        <div>
          <img width="604px" height="72px" src={IMAGE1} alt="first" />
        </div>
        <div>{t('basic:set_offer_step1_text')}</div>
      </div>
      <div className={`${styles.Element} ${styles.ElementMargin}`}>
        <div>{t('basic:set_offer_step2')}</div>
        <div>
          <img width="602px" height="133px" src={IMAGE2} alt="second" />
        </div>
        <div>
          <p>
            {t('basic:set_offer_step2_text1')}
            <br />
            {t('basic:set_offer_step2_text2')}
          </p>
          <p>{t('basic:set_offer_step2_text3')}</p>
        </div>
      </div>

      <div className={`${styles.Element} ${styles.ElementMargin}`}>
        <div>{t('basic:set_offer_step3')}</div>
        <div>
          <img
            className={styles.ImgBorder}
            width="224px"
            height="159px"
            src={IMAGE3}
            alt="third"
          />
        </div>
        <div>
          <p>{t('basic:set_offer_step3_text')}</p>
        </div>
      </div>

      <div className={`${styles.Element} ${styles.ElementMargin}`}>
        <div>{t('basic:set_offer_step4')}</div>
        <div>
          <img
            className={styles.ImgBorder}
            width="285px"
            height="223px"
            src={IMAGE4}
            alt="fourth"
          />
        </div>
        <div>
          <p>{t('basic:set_offer_step4_text')}</p>
        </div>
      </div>

      <div className={`${styles.Element} ${styles.ElementMargin}`}>
        <div>{t('basic:set_offer_step5')}</div>
        <div>
          <img
            className={styles.ImgBorder}
            width="267px"
            height="314px"
            src={IMAGE5}
            alt="fifth"
          />
        </div>
        <div>
          <p>{t('basic:set_offer_step5_text')}</p>
        </div>
      </div>

      <div className={`${styles.Element} ${styles.ElementMargin}`}>
        <div>{t('basic:set_offer_step6')}</div>
        <div>
          <img
            className={styles.ImgBorder}
            width="653px"
            height="88px"
            src={IMAGE6}
            alt="sixth"
          />
        </div>
        <div>
          <p>{t('basic:set_offer_step6_text1')}</p>
          <p>{t('basic:set_offer_step6_text2')}</p>
        </div>
      </div>

      <div className={`${styles.Element} ${styles.ElementMargin}`}>
        <div>{t('basic:set_offer_step7')}</div>
        <div>
          <img
            className={styles.ImgBorder}
            height="212px"
            width="641px"
            src={IMAGE7}
            alt="seventh"
          />
        </div>
        <div>
          <p>
            {t('basic:set_offer_step7_text1')}
            <br />
            {t('basic:set_offer_step7_text2')}
          </p>
        </div>
      </div>

      <div className={`${styles.Element} ${styles.ElementMargin}`}>
        <div>{t('basic:set_offer_step8')}</div>
        <div>
          <img
            className={styles.ImgBorder}
            height="121px"
            width="608px"
            src={IMAGE8}
            alt="eighth"
          />
        </div>
        <div>
          <p>{t('basic:set_offer_step8_text')}</p>
        </div>
      </div>

      <div className={`${styles.Element} ${styles.ElementMargin}`}>
        <div>{t('basic:set_offer_step9')}</div>
        <div>
          <img
            className={styles.ImgBorder}
            width="608px"
            height="131px"
            src={IMAGE9}
            alt="ninth"
          />
        </div>
        <div>
          <p>{t('basic:set_offer_step9_text')}</p>
        </div>
      </div>

      <div className={`${styles.Element} ${styles.ElementMargin}`}>
        <div>{t('basic:set_offer_step10')}</div>
        <div>
          <img
            className={styles.ImgBorder}
            width="400px"
            height="171px"
            src={IMAGE10}
            alt="tenth"
          />
        </div>
        <div>
          <p>{t('basic:set_offer_step10_text')}</p>
        </div>
      </div>

      <div className={`${styles.Element} ${styles.ElementMargin}`}>
        <div>{t('basic:set_offer_step11')}</div>
        <div>
          <img
            className={styles.ImgBorder}
            width="608px"
            height="170px"
            src={IMAGE11}
            alt="eleventh"
          />
        </div>
        <div>
          <p>{t('basic:set_offer_step11_text')}</p>
        </div>
      </div>

      <div className={`${styles.Element} ${styles.ElementMargin}`}>
        <div>{t('basic:set_offer_step12')}</div>
        <div>
          <img
            className={styles.ImgBorder}
            width="608px"
            height="237px"
            src={IMAGE12}
            alt="twelfth"
          />
        </div>
        <div>
          <p>{t('basic:set_offer_step12_text')}</p>
        </div>
      </div>

      <div className={`${styles.Element} ${styles.ElementMargin}`}>
        <div>{t('basic:set_offer_step13')}</div>
        <div>
          <img
            className={styles.ImgBorder}
            width="608px"
            height="267px"
            src={IMAGE13}
            alt="thirteenth"
          />
        </div>
        <div>
          <p>
            {t('basic:set_offer_step13_text1')}

            <br />
            {t('basic:set_offer_step13_text2')}

            <br />
            {t('basic:set_offer_step13_text3')}
          </p>
        </div>
      </div>

      <div className={`${styles.Element} ${styles.ElementMargin}`}>
        <div>{t('basic:set_offer_step14')}</div>
        <div>
          <img
            className={styles.ImgBorder}
            width="608px"
            height="185px"
            src={IMAGE14}
            alt="fourteenth"
          />
        </div>
        <div>
          <p>{t('basic:set_offer_step14_text')}</p>
        </div>
      </div>

      <div className={`${styles.Element} ${styles.ElementMargin}`}>
        <div>{t('basic:set_offer_step15')}</div>
        <div>
          <img
            className={styles.ImgBorder}
            width="457px"
            height="169px"
            src={IMAGE15}
            alt="fifteenth"
          />
        </div>
        <div>
          <p>{t('basic:set_offer_step15_text')}</p>
        </div>
      </div>

      <div className={`${styles.Element} ${styles.ElementMargin}`}>
        <div>{t('basic:set_offer_step16')}</div>
        <div>
          <img
            className={styles.ImgBorder}
            width="608px"
            height="550px"
            src={IMAGE16}
            alt="sixteenth"
          />
        </div>
        <div>
          <p>{t('basic:set_offer_step16_text')}</p>
        </div>
      </div>

      <div className={`${styles.Element} ${styles.ElementMargin}`}>
        <div>
          {t('basic:thank_you')}
          <br />
          {t('basic:yettel')}
        </div>
      </div>
    </div>
  );
};
