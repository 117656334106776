import { observer } from 'mobx-react';
import React, { useRef, useState } from 'react';
import { UploadCodesModelType, useMst } from '../../../internal';
import styles from './UploadContractModal.module.scss';
import { Modal } from '../../UI/modal/Modal';
import close from '../../../assets/images/icon-close.svg';
import upload from '../../../assets/images/icon-upload.svg';
import documentIcon from '../../../assets/icons/icon-document.png';
import { useTranslation } from 'react-i18next';
import dragAndDrop from '../../../assets/images/icon-drag-and-drop.png';

export const UploadContractModal = observer(
  (props: { model: UploadCodesModelType; id?: string }): JSX.Element => {
    const { model, id } = props;
    const {
      currentPage,
      showFailure,
      api: {
        partnerDocument: { uploadDocument },
        partner: { getAllPartnerDocuments },
      },
    } = useMst();
    const { t } = useTranslation();

    const [dragging, setDragging] = useState(false);

    const handleDragStart = (event: React.DragEvent<HTMLDivElement>) => {
      event.dataTransfer.setData(
        'text/plain',
        (event.target as HTMLDivElement).id
      );
      setDragging(true);
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
    };

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      setDragging(false);
      const files = event.dataTransfer.files;
      const isPDF = files[0].type === 'application/pdf';
      if (!isPDF) {
        showFailure(t('alert:file_not_supported'));
      } else {
        if (currentPage.getCreatedAccountList().editModal.modal.opened) {
          for (const file of Array.from(files)) {
            uploadDocument(
              file,
              currentPage.getCreatedAccountList().editModal.partnerId
            ).then(async () => {
              const filesFromServer = await getAllPartnerDocuments(
                currentPage.getCreatedAccountList().editModal.partnerId
              );
              currentPage.getCreatedAccountList()?.editModal?.clearFiles();
              currentPage
                .getCreatedAccountList()
                ?.editModal?.setFiles(filesFromServer.data);
            });
          }
        } else {
          currentPage.getCreateAccount().setFiles(files);
        }
        model.modal.setOpened(false);
      }
    };

    const fileInputRef = useRef<HTMLInputElement>(null);
    const handleFileUpload = () => {
      if (fileInputRef.current) {
        fileInputRef.current.click();
      }
    };
    const handleFileChange = async (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      const files = event.target.files;
      if (files) {
        const isPDF = files[0].type === 'application/pdf';
        if (!isPDF) {
          showFailure(t('alert:file_not_supported'));
        } else {
          if (currentPage.getCreatedAccountList().editModal.modal.opened) {
            for (const file of Array.from(files)) {
              uploadDocument(
                file,
                currentPage.getCreatedAccountList().editModal.partnerId
              ).then(async () => {
                const filesFromServer = await getAllPartnerDocuments(
                  currentPage.getCreatedAccountList().editModal.partnerId
                );
                currentPage.getCreatedAccountList()?.editModal?.clearFiles();
                currentPage
                  .getCreatedAccountList()
                  ?.editModal?.setFiles(filesFromServer.data);
              });
            }
          } else {
            currentPage.getCreateAccount().setFiles(files);
          }
          model.modal.setOpened(false);
        }
      }
    };

    return (
      <Modal
        id={id}
        model={model.modal}
        styles={{
          content: {
            height: '554px',
          },
        }}
      >
        <div className={styles.UploadCodesModalContainer}>
          <img
            className={styles.Close}
            src={close}
            alt="close"
            onClick={() => {
              model.modal.setOpened(false);
            }}
          />
          <div className={styles.HeaderContainer}>
            <span className={styles.Heading}>Ugovor</span>
          </div>
        </div>
        <div className={styles.UploadContent}>
          <div
            className={styles.DragAndDrop}
            id="drag-and-drop-div"
            draggable
            onDragStart={handleDragStart}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
          >
            <span className={styles.UploadText}>
              {t('drag_and_drop_document')}
            </span>
            <img
              src={dragAndDrop}
              className={styles.DragAndDropIcon}
              alt="drag_and_drop_document"
            />
          </div>
          <div className={styles.FileUpload}>
            <div
              className={styles.UploadCodesButtonContainer}
              onClick={handleFileUpload}
            >
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileChange}
                hidden={true}
                accept="application/pdf"
                multiple={true}
              />
              <div className={styles.UploadContainer}>
                <span>{t('upload')}</span>
                <img
                  className={styles.UploadImage}
                  src={upload}
                  alt={'upload'}
                />
              </div>
            </div>
          </div>
          <div className={styles.FileFormat}>
            <img
              src={documentIcon}
              className={styles.DocumentIcon}
              alt="drag_and_drop_document"
            />
            <p>Format.pdf</p>
          </div>
        </div>
      </Modal>
    );
  }
);
