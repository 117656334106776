import {
  ApiListResponse,
  ApiObjectResponse,
  PublishAd,
  RootType,
} from '../../internal';
import { getRoot, types } from 'mobx-state-tree';
import { HttpVerbs } from '../../constants/verbs';
import { PUBLISHED_SOON_UPDATE, PUBLISHED_UPDATE } from '../../constants/ad';

export interface AdUpdate {
  ad_id: string;
  image0: string;
  image1: string;
  image2: string;
  image3: string;
  categories: [];
  name: string;
  discount_value: string | null;
  discount_percentage: number | null;
  original_price: string | null;
  discount_description: string | undefined;
  description: string | undefined;
  conditions: string;
  keywords: string | undefined;
  date_start: string;
  date_end: string;
  date_code_start?: string;
  date_code_end?: string;
  url: string;
  visual_type: string;
  status?: string;
  store_location_type?: string;
  recommended: number;
}

export const AdUpdateApi = types
  .model('AdUpdateApi', {})
  .views((self) => {
    return {
      get root(): RootType {
        return getRoot(self);
      },
    };
  })
  .actions((self) => {
    return {
      async update(update: AdUpdate) {
        try {
          const response: ApiObjectResponse<AdUpdate> =
            await self.root.axios.requestData({
              type: HttpVerbs.POST,
              path: '/offer-updates',
              data: update,
            });
          await self.root.api.offers.uploadImages(update.ad_id);
          return response;
        } catch (err) {
          self.root.showFailure('e400');
          throw err;
        }
      },
      // gets the relevant ad update
      async get(adId: string): Promise<AdUpdate | undefined> {
        const response: ApiListResponse<AdUpdate> =
          await self.root.axios.requestData({
            type: HttpVerbs.GET,
            path: `/offer-updates?ad_id=${adId}`,
          });
        return response?.data?.filter(
          (update) =>
            update.status === PUBLISHED_UPDATE ||
            update.status === PUBLISHED_SOON_UPDATE
        )?.[0];
      },
      publishAdUpdate(id: string, data: PublishAd) {
        return new Promise((resolve, reject) => {
          self.root.axios
            .requestData({
              type: HttpVerbs.PUT,
              path: `/offer-updates/${id}/publish`,
              data: data,
            })
            .then((response: ApiObjectResponse<AdUpdate>) => {
              resolve(response);
            })
            .catch((err: any) => {
              reject(err);
            });
        });
      },
      rejectAdUpdate(id: string, reason: string) {
        return new Promise((resolve, reject) => {
          self.root.axios
            .requestData({
              type: HttpVerbs.PUT,
              path: `/offer-updates/${id}/reject`,
              data: {
                reason: reason,
              },
            })
            .then((response: ApiObjectResponse<AdUpdate>) => {
              resolve(response);
            })
            .catch((err: any) => {
              reject(err);
            });
        });
      },
    };
  });
