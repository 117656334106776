import * as React from 'react';
import { PageRoutes, useMst } from '../../../internal';
import { observer } from 'mobx-react';
import { DropdownWithSearch } from '../../../components/UI/dropdown/dropdown-with-search/DropdownWithSearch';
import { Button } from '../../../components/UI/buttons/regular/Button';
import { PARTNER_PROFILES } from '../../../constants/header';
import styles from './PartnerProfiles.module.scss';

export const PartnerProfiles = observer((): JSX.Element => {
  const {
    currentPage,
    router: { navigate },
  } = useMst();
  return (
    <>
      <div className={styles.PartnerProfileContainer}>
        <DropdownWithSearch model={currentPage.getPartnersModel()} />
        <div className={styles.PartnerProfileButton}>
          <Button
            onClick={() => {
              currentPage.root.header.setActiveTab(PARTNER_PROFILES);
              return Promise.all([
                currentPage.root.api.contract.getContracts(
                  currentPage.root.header.partnerId,
                  0,
                  1000
                ),
                currentPage.root.api.partnerLocation.getPartnerLocations(
                  currentPage.root.header.partnerId
                ),
                navigate({
                  newView: PageRoutes.CompanyProfile.id,
                  shouldReplace: true,
                }),
              ]);
            }}
          >
            {'Prikaži'}
          </Button>
        </div>
      </div>
    </>
  );
});
