import * as React from 'react';
import { observer } from 'mobx-react';
import styles from './Switch.module.scss';

export const Switch = observer(
  (props: {
    checked: boolean;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  }): JSX.Element => {
    const { checked, onChange } = props;

    return (
      <label className={styles.Switch}>
        <input
          type="checkbox"
          className={styles.SwitchInput}
          onChange={onChange}
          checked={checked}
        />
        <span className={styles.SwitchSlider} />
      </label>
    );
  }
);
