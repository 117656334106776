import { observer } from 'mobx-react';
import * as React from 'react';
import { toAmount } from '../../../utils/methods';
import styles from './BillSpecification.module.scss';

export const BillSpecification = observer(
  (props: { preview: { key: string; value: string }[] }): JSX.Element => {
    const { preview } = props;
    return (
      <div className={styles.Container}>
        {preview.map((element: any, index: number) => {
          return (
            <div
              key={index}
              className={
                index + 1 === preview.length ? styles.LastRow : styles.Row
              }
            >
              <div className={styles.Item}>{element.key}</div>
              <div className={styles.Price}>
                {toAmount(parseFloat(element.value))}&nbsp;RSD
              </div>
            </div>
          );
        })}
      </div>
    );
  }
);
