import { Instance, types } from 'mobx-state-tree';

export const DropdownMultiselectOptionModel = types.model({
  id: types.identifier,
  displayValue: types.string,
  value: types.string,
  selected: types.boolean,
});

export type DropdownMultiselectOptionModelType = Instance<
  typeof DropdownMultiselectOptionModel
>;
