import { observer } from 'mobx-react';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { TabPanel } from 'react-tabs';
import { Tabs } from '../../../components/UI/tabs/Tabs';
import { useMst } from '../../../internal';
import styles from './Accounts.module.scss';
import { CreateAccount } from '../../../components/domain/accounts/create-account/CreateAccount';
import { CreatedAccountList } from '../../../components/domain/accounts/created-account-list/CreatedAccountList';

export const Accounts = observer((): ReactElement => {
  const { t } = useTranslation();
  const { currentPage } = useMst();
  return (
    <div className={styles.PageContainer}>
      <div className={styles.PageTitleContainer}>
        <div className={styles.PageTitle}>{t('basic:advertiser_accounts')}</div>
      </div>
      <div className={styles.CenteredTabsContainer}>
        <Tabs model={currentPage.getTabs()}>
          <TabPanel>
            <div className={styles.TabPanelContentContainer}>
              <CreatedAccountList model={currentPage.getCreatedAccountList()} />
            </div>
          </TabPanel>
          <TabPanel>
            <div className={styles.TabPanelContentContainer}>
              <CreateAccount model={currentPage.getCreateAccount()} />
            </div>
          </TabPanel>
        </Tabs>
      </div>
    </div>
  );
});
