import { Instance, types } from 'mobx-state-tree';
import { ComponentModel } from '../../../internal';

export const AccordionModel = types
  .compose(
    ComponentModel,
    types.model({
      // name: types.string,
    })
  )
  .actions((self) => {
    return {};
  })
  .named('AccordionModel');

export type AccordionModelType = Instance<typeof AccordionModel>;
