import { getRoot, Instance, types } from 'mobx-state-tree';
import {
  ComponentModel,
  DropdownModel,
  TextInputModel,
  RoundButtonModel,
  ButtonModel,
  CheckboxGroupModel,
  ModalModel,
  RootType,
} from '../../../internal';

export const UploadCodesModel = types
  .compose(
    ComponentModel,
    types
      .model({
        codeTypeDropdown: types.late(() => DropdownModel),
        buttonSend: types.late(() => RoundButtonModel),
        textInputField: types.late(() => TextInputModel),
        buttonAddTable: types.late(() => RoundButtonModel),
        buttonChange: types.late(() => ButtonModel),
        checkboxContainer: types.late(() => CheckboxGroupModel),
        modal: types.late(() => ModalModel),
        uploadCodesRef: types.maybe(types.string),
        uploadCodesCount: types.maybe(types.number),
      })
      .volatile((self) => ({
        csvFile: null as File | null,
      }))
  )
  .views((self) => {
    return {
      get isSelected() {
        return self?.parent?.value === self.id;
      },
      get root(): RootType {
        return getRoot(self);
      },
    };
  })
  .actions((self) => {
    return {
      onClick(e: any) {
        self?.parent?.setValue(self.textInputField.value);
      },
      setParent(parent: string) {
        self.parent = parent;
      },
      onChange(e: any) {
        self?.parent?.setValue(self.id);
      },
      clearFile() {
        self.csvFile = null;
        const partnerId = self?.root?.header?.partnerId;
        const { isInactive } = self.root.router.queryParams;
        if (self.uploadCodesRef && partnerId && !isInactive) {
          self.root.api.offers.deleteCodes(partnerId, self.uploadCodesRef);
          self.setUploadCodesRef(undefined);
        }
      },
      setFile(file: File) {
        self.csvFile = file;
      },
      uploadFiles(file: File, partnerId: string) {
        self.root.api.offers.uploadCodes(file, partnerId);
      },
      openModal() {
        self.modal.setOpened(true);
      },
      closeModal() {
        self.modal.setOpened(false);
      },
      setUploadCodesRef(value: string) {
        self.uploadCodesRef = value;
      },
      setUploadCodesCount(value: number) {
        self.uploadCodesCount = value;
      },
      setDisabled(val: boolean) {
        self.disabled = val;
        self.codeTypeDropdown.setDisabled(val);
        self.buttonSend.setDisabled(val);
        self.textInputField.setDisabled(val);
        self.buttonAddTable.setDisabled(val);
        self.buttonChange.setDisabled(val);
        self.checkboxContainer.setDisabled(val);
      },
      runValidators() {
        if (self.isDisabled) {
          return true;
        }
        let result = true;
        if (self.codeTypeDropdown.value === 'personalized') {
          self.checkboxContainer.runValidators();
          if (result && self.checkboxContainer.currentMessage) {
            self.currentMessage = self.checkboxContainer.currentMessage;
            result = false;
          }
        } else {
          self.textInputField.runValidators();
          if (result && self.textInputField.currentMessage) {
            self.currentMessage = self.textInputField.currentMessage;
            result = false;
          }
        }

        if (result) {
          self.currentMessage = '';
        }

        return result;
      },
    };
  })
  .named('UploadCodesModel');

export type UploadCodesModelType = Instance<typeof UploadCodesModel>;
