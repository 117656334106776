import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Modal } from '../../../../UI/modal/Modal';
import { StoreLocationModalModelType } from '../../../../../internal';
import { TabPanel } from 'react-tabs';
import { StoreLocationTabs } from './tabs/StoreLocationTabs';
import { InputField } from '../../../../UI/input/InputField';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import {
  DEFAULT_LATITUDE,
  DEFAULT_LONGITUDE,
  DEFAULT_ZOOM,
  MAPS_API_KEY,
} from '../../../../../utils/google-maps';
import { Button } from '../../../../UI/buttons/regular/Button';
import { MapPinMarker } from './map-pin-marker/MapPinMarker';
import { UploadLocationCodes } from './upload-location-codes/UploadLocationCodes';
import { Show } from '../../../../UI/show/Show';
import { CheckboxGroup } from '../../../../UI/checkbox/CheckboxGroup';
import { DropdownWithSearch } from '../../../../UI/dropdown/dropdown-with-search/DropdownWithSearch';
import { IS_SHOPPING_MALL } from '../../../../../constants/companyprofile';
import { isMobileView } from '../../../../../utils/page';
import MARKER_ICON from '../../../../../assets/icons/icons8_store_front_marker.png';
import styles from './StoreLocationModal.module.scss';
import './Map.scss';

export const StoreLocationModal = observer(
  (props: { model: StoreLocationModalModelType }): JSX.Element => {
    const { model } = props;
    const { t } = useTranslation();
    const isMobileSize = isMobileView();
    return (
      <Modal
        styles={{
          content: {
            top: '5%',
            minWidth: '80%',
            maxHeight: 'fit-content',
            borderRadius: '20px',
            padding: '40px',
          },
        }}
        model={model.modal}
      >
        <div className={styles.Container}>
          <div className={styles.Title}>
            {t('basic:store_locations_addition')}
          </div>
          <div className={styles.TabsContainer}>
            <StoreLocationTabs model={model.tabs}>
              <TabPanel>
                <div className={styles.IndividualStoresTabContent}>
                  {!isMobileSize && (
                    <div className={styles.StoreLocationMapContainer}>
                      <LoadScript
                        id="script-loader"
                        googleMapsApiKey={MAPS_API_KEY}
                      >
                        <GoogleMap
                          id="near-by-map"
                          center={{
                            lat: model.latitude
                              ? model.latitude
                              : DEFAULT_LATITUDE,
                            lng: model.longitude
                              ? model.longitude
                              : DEFAULT_LONGITUDE,
                          }}
                          zoom={DEFAULT_ZOOM}
                          options={{
                            streetViewControl: false,
                            fullscreenControl: false,
                            mapTypeControl: false,
                          }}
                          clickableIcons={true}
                          onLoad={() => {
                            console.log('loaded');
                          }}
                          onUnmount={() => {
                            console.log('unmounted');
                          }}
                        >
                          {model.coordinatesSet && (
                            <Marker
                              icon={MARKER_ICON}
                              draggable={model.markerDraggable}
                              onDragEnd={(e: google.maps.MapMouseEvent) => {
                                model.setLatLng(
                                  e.latLng?.lat(),
                                  e.latLng?.lng()
                                );
                                model.syncAddress();
                              }}
                              position={{
                                lat: model.latitude,
                                lng: model.longitude,
                              }}
                            />
                          )}
                        </GoogleMap>
                      </LoadScript>
                    </div>
                  )}

                  <div className={styles.FieldsContainer}>
                    <div className={styles.InputContainer}>
                      <InputField model={model.name} />
                    </div>

                    <div className={styles.InputContainer}>
                      <CheckboxGroup model={model.getShoppingMallGroup} />
                    </div>
                    <Show
                      condition={
                        model.getShoppingMallGroup.selectedIdsStr ===
                        IS_SHOPPING_MALL
                      }
                    >
                      <div className={styles.InputContainer}>
                        <DropdownWithSearch
                          model={model.shoppingMallLocation}
                        />
                      </div>
                      <div className={styles.InputContainer}>
                        <DropdownWithSearch model={model.shoppingMall} />
                      </div>
                    </Show>
                    <Show
                      condition={
                        model.getShoppingMallGroup.selectedIdsStr !==
                        IS_SHOPPING_MALL
                      }
                    >
                      <div className={styles.InputContainer}>
                        <InputField model={model.address} />
                      </div>
                    </Show>

                    {model?.address?.value && (
                      <div className={styles.InputContainer}>
                        <MapPinMarker model={model} />
                      </div>
                    )}

                    <div className={styles.LabelContainer}>
                      <div className={styles.Label}>
                        {t('basic:working_hours')}
                      </div>
                    </div>
                    <div className={styles.InputContainerHalf}>
                      <InputField model={model.workingHoursWeekday} />
                    </div>
                    <div className={styles.InputContainerHalf}>
                      <InputField model={model.workingHoursSaturday} />
                    </div>
                    <div className={styles.InputContainerHalf}>
                      <InputField model={model.workingHoursSunday} />
                    </div>
                    <div className={styles.InputContainer}>
                      <InputField model={model.phone} />
                    </div>
                    <div className={styles.InputContainer}>
                      <InputField model={model.email} />
                    </div>
                    <div className={styles.SaveButtonContainer}>
                      <Button
                        model={model.saveBtn}
                        onClick={() => model.save()}
                      />
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className={styles.TabContent}>
                  <UploadLocationCodes model={model} />
                </div>
              </TabPanel>
            </StoreLocationTabs>
          </div>
        </div>
      </Modal>
    );
  }
);
