import { getRoot, Instance, types } from 'mobx-state-tree';
import { MODERATOR_PROFILE, PARTNER_PROFILES } from '../constants/header';
import { AdminPublic, Partner, Role, RootType } from '../internal';

export function parseJwt(token: string | any): any {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
}

export const UserModel = types
  .model({
    token: '',
    // The partner represents currently selected partner
    // Can be preselected if the admin is tied to a partner (non Yettel employee)
    // See 'admin' for more details
    partner: types.maybe(types.frozen<Partner>()),
    // This object is received from the BE on login
    // It can contain the preselected partner_id, if the admin
    // is tied to a partner
    admin: types.maybe(types.frozen<AdminPublic>()),
    // role determines what we can or cannot access
    // we determine it from role_id that we receive on login
    // by querying /role endpoint
    role: types.maybe(types.frozen<Role>()),
  })
  .views((self) => {
    return {
      get root(): RootType {
        return getRoot(self);
      },
      // prodavac
      get isSalesman(): boolean {
        return self?.role?.name === 'prodavac';
      },
      // registrovani administrator oglasivaca
      get isPartner(): boolean {
        return self?.role?.name === 'partner';
      },
      // yettel moderator oglasa
      get isModerator(): boolean {
        return self?.role?.name === 'moderator';
      },
      // yettel administrator oglasa
      get isAdministrator(): boolean {
        return self?.role?.name === 'SUPER_ADMIN';
      },
      get isTokenValid(): boolean {
        return !!self.token && !this.isTokenExpired;
      },
      get isTokenExpired(): boolean {
        try {
          return !(Date.now() < 1000 * parseJwt(self.token)?.exp);
        } catch {
          return true;
        }
      },
    };
  })
  .actions((self) => {
    return {
      afterCreate() {
        const partnerStr = window.localStorage.getItem('partner');
        const adminStr = window.localStorage.getItem('admin');
        const roleStr = window.localStorage.getItem('role');

        if (partnerStr) {
          this.setPartner(JSON.parse(partnerStr));
        }
        if (adminStr) {
          this.setAdmin(JSON.parse(adminStr));
        }
        if (roleStr) {
          this.setRole(JSON.parse(roleStr));
        }
      },
      reset() {
        self.token = '';
        window.localStorage.removeItem('token');

        self.partner = undefined;
        window.localStorage.removeItem('partner');

        self.admin = undefined;
        window.localStorage.removeItem('admin');

        self.role = undefined;
        window.localStorage.removeItem('role');
      },
      setPartner(partner: Partner) {
        self.partner = partner;
        window.localStorage.setItem('partner', JSON.stringify(self.partner));
      },
      clearPartner() {
        self.partner = undefined;
        window.localStorage.removeItem('partner');
      },
      setAdmin(admin: AdminPublic) {
        self.admin = admin;
        window.localStorage.setItem('admin', JSON.stringify(self.admin));
      },
      setRole(role: Role) {
        self.role = role;
        window.localStorage.setItem('role', JSON.stringify(self.role));
      },
      setToken(token: string) {
        self.token = token;
        window.localStorage.setItem('token', token);
      },
      selectHeaderTab() {
        if (!self.root.user.partner) {
          self?.root?.header?.setActiveTab(MODERATOR_PROFILE);
        } else {
          self?.root?.header?.setActiveTab(PARTNER_PROFILES);
        }
      },
      async setLoginData(data: { token: string; admin: AdminPublic }) {
        this.setToken(data.token);
        this.setAdmin(data.admin);
        if (data?.admin?.partner_id) {
          await self?.root?.api?.partner?.get?.(data.admin.partner_id);
          const partner = self.root.data.partnerDetails;
          this.setPartner(partner);
        }
        if (data?.admin?.role_id) {
          const role = await self?.root?.api?.admin?.getRole(
            data.admin.role_id
          );
          this.setRole(role);
        }
        self.root.setIsLoggedOut(false);
        this.selectHeaderTab();
      },
    };
  })
  .named('UserModel');

export type UserType = Instance<typeof UserModel>;
