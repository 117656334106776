import { Instance, types } from 'mobx-state-tree';
import {
  ButtonModel,
  ComponentModel,
  ConfirmationModalModel,
  ModalModel,
  Partner,
  PartnerAdminPublic,
  TextInputModel,
} from '../../../internal';
import i18n from '../../../i18n';
import { ACTIVE, INACTIVE, SUBMITTED } from '../../../constants/admins';
import { validateEmail } from '../../../validators/validators';

export const AdministratorsModel = types
  .compose(
    ComponentModel,
    types.model({
      elements: types.optional(types.array(types.frozen<Partner>()), []),
      modal: types.late(() => ModalModel),
      isUpdate: types.boolean,
      deleteAdminModal: types.late(() => ModalModel),
      adminId: types.maybe(types.string),
      deleteModal: types.late(() => ConfirmationModalModel),
      // Admin dialog (modal fields)
      adminEmail: types.maybe(types.late(() => TextInputModel)),
      adminFirstName: types.maybe(types.late(() => TextInputModel)),
      adminLastName: types.maybe(types.late(() => TextInputModel)),
      adminPhoneNumber: types.maybe(types.late(() => TextInputModel)),
      saveAdminButton: types.maybe(types.late(() => ButtonModel)),
      updateAdminButton: types.maybe(types.late(() => ButtonModel)),
    })
  )
  .actions((self) => {
    return {
      setElements(elementsResponse: Partner[]) {
        self.elements.replace(elementsResponse);
      },
      openModal() {
        self.modal.setOpened(true);
      },
      openCreateAdminModal() {
        this.clearDialogData();
        this.setIsUpdate(false);
        this.openModal();
      },
      openEditAdminModal(elem: PartnerAdminPublic) {
        this.clearDialogData();
        this.setAdminId(elem.id);
        this.getAdminEmail().setValue(elem.email);
        this.getAdminFirstName().setValue(elem.first_name);
        this.getAdminLastName().setValue(elem.last_name);
        this.getAdminPhoneNumber().setValue(elem.phone);
        this.setIsUpdate(true);
        this.openModal();
      },
      openDeleteModal() {
        self.deleteAdminModal.setOpened(true);
      },
      setStatus(status: string) {
        if (status === SUBMITTED) {
          return i18n.t('company_profile:submitted');
        } else if (status === INACTIVE) {
          return i18n.t('company_profile:nonactive');
        } else if (status === ACTIVE) {
          return i18n.t('company_profile:active');
        }
      },
      setIsUpdate(isUpdate: boolean) {
        self.isUpdate = isUpdate;
      },
      setAdminId(id?: string) {
        self.adminId = id;
      },
      async deleteAdmin() {
        await self.root.api.admin.deleteAdmin();
      },
      clearDialogData() {
        this.getAdminEmail().clearData();
        this.getAdminEmail().setCurrentMessage('');
        this.getAdminFirstName().clearData();
        this.getAdminFirstName().setCurrentMessage('');
        this.getAdminLastName().clearData();
        this.getAdminLastName().setCurrentMessage('');
        this.getAdminPhoneNumber().clearData();
        this.getAdminPhoneNumber().setCurrentMessage('');
        this.setAdminId(undefined);
      },
      getAdminEmail() {
        if (!self.adminEmail) {
          self.adminEmail = TextInputModel.create({
            id: 'adminEmail',
            label: 'basic:email',
            isRequired: true,
            validators: {
              validateEmail,
            },
          });
        }
        return self.adminEmail;
      },
      getAdminFirstName() {
        if (!self.adminFirstName) {
          self.adminFirstName = TextInputModel.create({
            id: 'adminFirstName',
            label: 'basic:first_name',
            isRequired: true,
          });
        }
        return self.adminFirstName;
      },
      getAdminLastName() {
        if (!self.adminLastName) {
          self.adminLastName = TextInputModel.create({
            id: 'adminLastName',
            label: 'basic:last_name',
            isRequired: true,
          });
        }
        return self.adminLastName;
      },
      getAdminPhoneNumber() {
        if (!self.adminPhoneNumber) {
          self.adminPhoneNumber = TextInputModel.create({
            id: 'adminPhoneNumber',
            label: 'basic:phone_number',
            type: 'number',
            isRequired: true,
            inputFieldArrowsHidden: true,
          });
        }
        return self.adminPhoneNumber;
      },
      checkIfIsValid(inputFields: any[]) {
        const children = inputFields;
        let valid = true;
        for (const child of children) {
          child.runValidators();
          if (child.isInvalid) {
            valid = false;
          }
        }
        return valid;
      },
      getCompsForValidation() {
        return [
          this.getAdminEmail(),
          this.getAdminFirstName(),
          this.getAdminLastName(),
          this.getAdminPhoneNumber(),
        ];
      },
      getSaveAdminButton() {
        if (!self.saveAdminButton) {
          self.saveAdminButton = ButtonModel.create({
            id: 'SaveAdminButton',
            label: 'basic:save',
            onClickCallback: async () => {
              const comps = this.getCompsForValidation();
              const loadAdmins = self.root.currentPage.loadAdmins;
              if (!this.checkIfIsValid(comps)) {
                return;
              }
              this.getSaveAdminButton().setDisabled(true);

              try {
                await self.root.api.admin.createPartnerAdmin(
                  self.dialogAdminData
                );
                self.modal.setOpened(false);
                loadAdmins();

                this.getSaveAdminButton().setDisabled(false);
                this.clearDialogData();
              } catch (err) {
                this.getSaveAdminButton().setDisabled(false);
              }
            },
          });
        }
        return self.saveAdminButton;
      },

      getUpdateAdminButton() {
        if (!self.updateAdminButton) {
          self.updateAdminButton = ButtonModel.create({
            id: 'UpdateAdminButton',
            label: 'basic:save',
            onClickCallback: async () => {
              const comps = this.getCompsForValidation();
              if (!this.checkIfIsValid(comps)) {
                return;
              }
              const loadAdmins = self.root.currentPage.loadAdmins;

              this.getUpdateAdminButton().setDisabled(true);

              try {
                await self.root.api.partner.sendUpdatePartnerAdminEmail(
                  self.dialogAdminData
                );
                self.modal.setOpened(false);
                loadAdmins();

                this.getUpdateAdminButton().setDisabled(false);
              } catch (err) {
                this.getUpdateAdminButton().setDisabled(false);
              }
            },
          });
        }
        return self.updateAdminButton;
      },
    };
  })
  .views((self) => {
    return {
      get adminsArray() {
        return Array.from(self.elements.values());
      },
      get dialogAdminData() {
        return {
          id: self.adminId,
          email: self?.adminEmail?.value,
          first_name: self?.adminFirstName?.value,
          last_name: self?.adminLastName?.value,
          phone: self?.adminPhoneNumber?.value,
        };
      },
    };
  })
  .named('AdministratorsModelType');

export type AdministratorsModelType = Instance<typeof AdministratorsModel>;
