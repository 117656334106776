import { observer } from 'mobx-react';
import * as React from 'react';
import {
  RadioButtonGroupModelType,
  RoundButtonModelType,
} from '../../../internal';
import { RadioButton } from '../buttons/radio/RadioButton';

export const AdTypeRadioButtonGroup = observer(
  (props: { model: RadioButtonGroupModelType }): JSX.Element => {
    const { model } = props;
    return (
      <>
        {model.buttonsArray.map(
          (button: RoundButtonModelType, index: number) => {
            return <RadioButton model={button} key={index} />;
          }
        )}
      </>
    );
  }
);
