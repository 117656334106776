import { observer } from 'mobx-react';
import styles from './AdTable.module.scss';
import {
  AdListElemModelType,
  AdListModelType,
  ColumnModelType,
  useMst,
} from '../../../internal';
import { Show } from '../../UI/show/Show';
import { useTranslation } from 'react-i18next';
import NumberedPagination from '../../UI/pagination/NumberedPagination';
import { AdsTableFilter } from './AdsTableFilter';
import { AdTableRow } from '../../../pages/moderator/all-ads/AdTableRow';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file, in index.html we overrride part of this(check it out!)
import { useEffect, useRef, useState } from 'react';

export const Table = observer(
  (props: { model: AdListModelType; isAdTable: boolean }): JSX.Element => {
    const [selectionRange, setSelectionRange] = useState({
      startDate: new Date(),
      endDate: new Date(new Date().getDate() + 10),
      key: 'selection',
    });

    const { model, isAdTable } = props;
    const {
      data: { totalAdsCount, alert, setAlert, selectedPage, setSelectedPage },
      currentPage,
    } = useMst();
    const { t } = useTranslation();

    const maxDisplayedAds =
      parseInt(currentPage.getPageSizeDropdown?.().value) *
      currentPage.pageNumber;
    const handleChange = (event: any) => {
      setSelectedPage(event.target.value);
    };
    const handleSubmit = (event: any) => {
      event.preventDefault();
      if (
        selectedPage <= model.pagination.totalPages &&
        Number(selectedPage) >= 1
      ) {
        model.pagination.getPage(Number(selectedPage));
        setAlert(false);
      } else {
        setAlert(true);
      }
    };
    return (
      <div className={styles.Container}>
        <Show condition={!model?.rowsArray?.length}>
          <div className={styles.NoElementsContainer}>
            {isAdTable && <p>{t('basic:no_ads')}</p>}
          </div>
        </Show>
        <div
          style={{ height: isAdTable ? '600px' : 'auto' }}
          className={styles.tableWrapper}
        >
          <table
            style={{ margin: isAdTable ? '0 50px' : '' }}
            className={styles.table}
          >
            <thead className={styles.TableHead}>
              <tr>
                {model.visibleColumnsArray.map((column: ColumnModelType) => {
                  return column.id === 'allOptions' ? null : (
                    <th key={column.id}> {t(column.label)} </th>
                  );
                })}
              </tr>
            </thead>
            <tbody className={styles.TableBody}>
              <tr style={{ position: 'relative', zIndex: 2 }}>
                {model.visibleColumnsArray.map((column: ColumnModelType) => (
                  <td key={column.id}>
                    {currentPage
                      .getColumnFilters?.()
                      .filters.get(column.id) && (
                      <AdsTableFilter
                        model={currentPage
                          .getColumnFilters()
                          .filters.get(column.id)}
                      />
                    )}
                  </td>
                ))}
              </tr>

              <Show condition={model?.rowsArray?.length}>
                {model.rowsArray.map(
                  (elem: AdListElemModelType, index: number) => {
                    return (
                      <AdTableRow
                        tableModel={model}
                        key={index}
                        rowModel={elem}
                      />
                    );
                  }
                )}
              </Show>
            </tbody>
          </table>
        </div>
        {model.pagination ? (
          <div className={styles.PaginationOuterContainer}>
            <p className={styles.DisplayedAds}>
              {maxDisplayedAds - currentPage.getPageSizeDropdown().value + 1} -{' '}
              {maxDisplayedAds > totalAdsCount
                ? totalAdsCount
                : maxDisplayedAds}{' '}
              od {totalAdsCount} oglasa
            </p>
            <NumberedPagination model={model.pagination} setAlert={setAlert} />
            <div className={styles.PagePicker}>
              <form onSubmit={handleSubmit}>
                <input
                  className={
                    !alert
                      ? styles.PageNumberInput
                      : styles.PageNumberInputError
                  }
                  type="number"
                  value={selectedPage}
                  onChange={handleChange}
                  placeholder={currentPage.pageNumber}
                ></input>
              </form>
              <span>/ strana</span>
              {alert && (
                <p className={`${styles.Alert}`}>
                  {t('warnings:pagination_error')}
                  {model.pagination.totalPages}
                </p>
              )}
            </div>
          </div>
        ) : null}

        {/* <DateRangePicker
          months={2}
          direction="horizontal"
          locale={sr}
          ranges={[selectionRange]}
          onChange={(ranges: any) => {
            setSelectionRange({
              key: 'selection',
              startDate: ranges.selection.startDate,
              endDate: ranges.selection.endDate,
            });
          }}
        /> */}
      </div>
    );
  }
);
