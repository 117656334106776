import * as React from 'react';
import styles from './InputField.module.scss';
import { TextInputModelType } from '../../../internal';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

export const InputField = observer(
  (props: {
    model: TextInputModelType;
    id?: string;
    className?: string;
    wrapperClassName?: string;
  }): JSX.Element => {
    const { model, id, className, wrapperClassName } = props;
    const { t } = useTranslation();
    const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
      model.onChange(e);
      model.runValidators();
    };
    return (
      <div id={id} className={styles.InputContainer}>
        {model.label ? (
          <label htmlFor={model.id} className={styles.InputLabel}>
            {t(model.label)}
            <span className={styles.Required}>
              {model.isRequired ? '*' : ''}
            </span>
          </label>
        ) : (
          <></>
        )}
        <div
          id={model.id}
          className={`${
            !model.icon ? styles.InputContent : styles.IconInputContent
          } ${wrapperClassName || ''}`}
        >
          <input
            required={model.isRequired}
            className={`${className || ''} ${
              model.isInvalid ? styles.InputFieldInvalid : styles.InputField
            } ${model.inputFieldArrowsHidden ? styles.ArrowsHidden : ''} ${
              !model.isEnabledComputed ? styles.Disabled : ''
            }`}
            type={model.type}
            disabled={!model.isEnabledComputed}
            placeholder={model.placeholder}
            onChange={handleChange}
            value={model.value || ''}
            max={model.max}
            min={model.min}
            onKeyDown={model.formatInput}
          />
          {model.icon ? (
            <img className={styles.Icon} src={model.icon} alt={model.icon} />
          ) : (
            <></>
          )}
          <div className={model.showSideSign ? styles.DisplaySign : ''}>
            {model.showSideSign ? <span>{model.sign}</span> : <></>}
          </div>
        </div>
        {model.isInvalid ? (
          <div className={styles.ErrorContainer}>
            <div className={styles.Error}>{t(model.currentMessageValue)}</div>
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  }
);
