import { Instance, types } from 'mobx-state-tree';

export const AdListElemModel = types
  .model({
    id: types.identifier,
    status: types.maybe(types.string),
    imageSrc: types.maybe(types.string),
    title: types.maybe(types.string),
    partner: types.maybe(types.string),
    createdAt: types.maybe(types.Date),
    dateCodeStart: types.maybeNull(types.string),
    dateCodeEnd: types.maybeNull(types.string),
    dateEnd: types.maybe(types.string),
    price: types.maybe(types.string),
    originalPrice: types.maybeNull(types.string),
    discountValue: types.maybeNull(types.string),
    recommended: types.maybe(types.string),
    visualType: types.maybe(types.string),
    categoryName: types.maybe(types.string),
    subCategoryName: types.maybe(types.string),
    ageFrom: types.maybeNull(types.string),
    ageTo: types.maybeNull(types.string),
    partnerLegalName: types.maybe(types.string),
    keywords: types.maybeNull(types.string),
    storeLocationType: types.maybeNull(types.string),
    gender: types.maybeNull(types.string),
    url: types.maybeNull(types.string),
    codeCount: types.maybeNull(types.string),
    adType: types.maybeNull(types.string),
    discountPercentage: types.maybeNull(types.string),
    withCode: types.maybeNull(types.string),
    codeType: types.maybeNull(types.string),
    userLocations: types.maybeNull(types.string),
    userContractType: types.maybeNull(types.string),
    adViews: types.maybeNull(types.string),
    claimedCodes: types.maybeNull(types.string),
    maxTarget: types.maybeNull(types.string),
    territoryType: types.maybeNull(types.string),
    comment: types.maybeNull(types.string),
    creator: '',
    publisher: types.maybeNull(types.string),
    partnershipExpert: types.maybeNull(types.string),
    partnerAdminName: types.maybeNull(types.string),
    partnerAdminEmail: types.maybeNull(types.string),
    partnerAdminPhone: types.maybeNull(types.string),
    submittedAt: types.maybeNull(types.string),
  })
  .views((self) => {
    return {};
  })
  .actions((self) => {
    return {};
  })
  .named('AdListElemModel');

export type AdListElemModelType = Instance<typeof AdListElemModel>;
