import { observer } from 'mobx-react';
import * as React from 'react';
import { TabPanel, Tabs as ReactTabs } from 'react-tabs';
import { Tab, TabList } from 'react-tabs';
import { TabModelType, TabsModelType } from '../../../internal';
import { Show } from '../show/Show';
import './react-tabs.scss';

// children are tab panels, they are optional
// so you could pass them like this:
// <><TabPanel></TabPanel>...</>
export const Tabs = observer(
  (props: {
    model: TabsModelType;
    children?: any;
    tabsClass?: string;
  }): JSX.Element => {
    const { children, model, tabsClass } = props;
    return (
      <ReactTabs
        selectedIndex={model.selectedIndex}
        onSelect={(index) => model.selectIndex(index)}
      >
        <TabList className={tabsClass}>
          {model.tabsArray.map((tab: TabModelType, index: number) => {
            return <Tab key={index}>{tab.name}</Tab>;
          })}
        </TabList>
        <Show condition={children}>{children}</Show>
        {!children &&
          model.tabsArray.map((tab: TabModelType, index: number) => {
            return <TabPanel key={index} />;
          })}
      </ReactTabs>
    );
  }
);
