import { useTranslation } from 'react-i18next';
import { DropdownModelType, useMst } from '../../../../internal';
import { Dropdown } from '../../../UI/dropdown/Dropdown';
import styles from './PartnershipExpert.module.scss';

const PartnershipExpert = (props: {
  model: DropdownModelType;
}): JSX.Element => {
  const {
    user,
    user: { isSalesman },
  } = useMst();
  const { model } = props;
  const { t } = useTranslation();
  return isSalesman ? (
    <div className={styles.Container}>
      <div className={styles.Item}>{t('basic:partnership_expert')}:</div>
      <div
        className={styles.Item}
      >{`${user?.admin?.first_name} ${user?.admin?.last_name}`}</div>
    </div>
  ) : (
    <Dropdown model={model} />
  );
};

export default PartnershipExpert;
