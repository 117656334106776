import { Instance, types } from 'mobx-state-tree';
import {
  ComponentModel,
  ModalModel,
  FunctionModel,
} from '../../../../internal';

export const ActivatePackageModel = types
  .compose(
    ComponentModel,
    types.model({
      name: types.maybe(types.string),
      contractId: types.maybe(types.string),
      contractExtensionId: types.maybe(types.string),
      modal: types.late(() => ModalModel),
      onAfterActivate: types.late(() => FunctionModel),
    })
  )
  .actions((self) => {
    return {
      openModal() {
        self.modal.setOpened(true);
      },
      closeModal() {
        self.modal.setOpened(false);
      },
      async activate() {
        await self.root.api.purchasedExtension.activatePackage({
          contract_id: self.contractId,
          contract_extension_id: self.contractExtensionId,
        });
        self.closeModal();
        self?.onAfterActivate?.();
      },
      cancel() {
        self.closeModal();
      },
    };
  })
  .views((self) => {
    return {};
  })
  .named('ActivatePackageModel');

export type ActivatePackageModelType = Instance<typeof ActivatePackageModel>;
