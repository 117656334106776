import { observer } from 'mobx-react';
import styles from './PickStoresModal.module.scss';

import { useTranslation } from 'react-i18next';
import { Modal } from '../../UI/modal/Modal';
import { PickStoresModelType } from '../../../internal';
import { StorePicker } from './store-picker/StorePicker';
import { SearchField } from './search-field/SearchField';

export const PickStoresModal = observer(
  (props: { model: PickStoresModelType; id?: string }): JSX.Element => {
    const { model, id } = props;
    const { t } = useTranslation();

    return (
      <Modal
        styles={{
          content: {
            minWidth: '40%',
            maxHeight: 'fit-content',
          },
        }}
        model={model.modal}
        id={id}
      >
        <div className={styles.ModalContentContainer}>
          <div className={styles.ContentHeader}>
            <div className={styles.PickStoresTextContainer}>
              <div className={styles.PickStoresText}>
                {t('basic:pick_businesses')}
              </div>
            </div>
            <div className={styles.OuterSearchContainer}>
              <SearchField model={model} />
            </div>
            <div className={styles.PickContainer}>
              <div className={styles.CancelAllContainer}>
                {model.anythingSelected ? (
                  <div
                    onClick={() => model.checkboxContainer.deselectAll()}
                    className={styles.CancelAll}
                  >
                    {t('basic:cancel_all')}
                  </div>
                ) : (
                  <></>
                )}
              </div>

              <div className={styles.PickAllContainer}>
                {!model.everythingSelected ? (
                  <div
                    onClick={() => model.checkboxContainer.selectAll()}
                    className={styles.PickAll}
                  >
                    {t('basic:pick_all')}
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          <div className={styles.StorePickerContainer}>
            <StorePicker model={model.checkboxContainer} />
          </div>
          <div className={styles.SaveButtonContainer}>
            <div
              onClick={() => model.saveAndClose()}
              className={
                model.anythingSelected
                  ? styles.SaveButton
                  : styles.SaveButtonDisabled
              }
            >
              <span className={styles.SaveButtonText}>
                {t('basic:save_and_close')}
              </span>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
);
