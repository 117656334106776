import { getRoot, types } from 'mobx-state-tree';
import i18n from '../../i18n';
import {
  TextInputModel,
  PageModel,
  ButtonModel,
  RootType,
  PageRoutes,
  ApiObjectResponse,
  AdminPublic,
  PasswordInputModel,
} from '../../internal';

export const LoginModel = types
  .compose(
    PageModel,
    types.model({
      captcha: types.maybe(types.string),
      showCaptcha: types.maybe(types.boolean),
    })
  )
  .views((self) => {
    return {
      get root(): RootType {
        return getRoot(self);
      },
      get credentialsNotEntered() {
        return !this.username || !this.password;
      },
      get username() {
        return self?.components?.get('username')?.value;
      },
      get password() {
        return self?.components?.get('password')?.value;
      },
    };
  })
  .actions((self) => {
    return {
      getSubmitModel() {
        return (
          self.components.get('submit_login') ||
          self.addComponent(
            ButtonModel.create({
              id: 'submit_login',
              label: 'Prijavi se',
              onClickCallback: async () => {
                self.runValidators();
                if (self.credentialsNotEntered) {
                  self.root.showFailure(
                    i18n.t('alert:credentials_not_entered')
                  );
                  return;
                }
                // We handle login a little differently now (no toasts, see AdminApi)
                try {
                  this.setHideCaptcha();
                  const response: ApiObjectResponse<{
                    token: string;
                    admin: AdminPublic;
                  }> = await self.root.api.admin.login({
                    email: this.getUserNameModel().value,
                    password: this.getPasswordModel().value,
                    captcha: self.captcha,
                  });
                  await self.root.user.setLoginData(response.data);
                  self.root.router.navigate({
                    newView: PageRoutes.HomeRedirect.id,
                  });
                } catch (err: any) {
                  if (err.response.status === 429) {
                    self.root.showFailure(i18n.t('alert:captcha_required'));
                    this.setShowCaptcha();
                    return;
                  }
                  this.makeCredentialsInvalid();
                }
              },
              disabled: self.showCaptcha,
            })
          )
        );
      },
      makeCredentialsInvalid() {
        this.getUserNameModel().setCurrentMessage(
          i18n.t('alert:invalid_credentials')
        );
        this.getPasswordModel().setCurrentMessage(
          i18n.t('alert:invalid_credentials')
        );
      },
      getUserNameModel() {
        return (
          self.components.get('username') ||
          self.addComponent(
            TextInputModel.create({
              id: 'username',
              label: 'E-mail',
              isRequired: true,
              placeholder: 'Unesite vašu e-mail adresu',
            })
          )
        );
      },
      getPasswordModel() {
        return (
          self.components.get('password') ||
          self.addComponent(
            PasswordInputModel.create({
              id: 'password',
              label: 'Lozinka',
              isRequired: true,
              placeholder: 'Lozinka sa min. 10 karaktera',
            })
          )
        );
      },
      setHideCaptcha() {
        self.showCaptcha = false;
      },
      setShowCaptcha() {
        self.showCaptcha = true;
      },
      setCaptcha(captcha: string) {
        self.captcha = captcha;
        this.getSubmitModel().setDisabled(false);
      },
    };
  })
  .named('LoginModel');
