import { ReactElement } from 'react';
import { Rejection, useMst } from '../../../../internal';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import styles from './AdDetails.module.scss';
import arrowBack from '../../../../assets/icons/arrow-back.svg';
import { Tabs } from '../../../../components/UI/tabs/Tabs';
import { Section } from '../../../../components/UI/section/Section';
import { Show } from '../../../../components/UI/show/Show';
import { Button } from '../../../../components/UI/buttons/regular/Button';
import { LightButton } from '../../../../components/UI/buttons/light-button/LightButton';
import { ConfirmationAdModal } from './ConfirmationAdModal';
import { ApproveRejectModal } from './ApproveRejectModal';
import {
  PUBLISHED,
  STOP_AD,
  PUBLISH_AD,
  SUBMITTED,
  REJECTED,
  EXPIRED,
  STOPPED,
  CODE_SPENT,
  PUBLISHED_SOON_UPDATE,
  PUBLISHED_UPDATE,
  PUBLISHED_SOON,
  PREP,
} from '../../../../constants/ad';
import { TabPanel } from 'react-tabs';
import { AdOverview } from './ad-overview/AdOverview';
import { BillSpecification } from '../../../../components/domain/bill-specification/BillSpecification';
import { TextAreaInputField } from '../../../../components/UI/textarea/TextAreaInputField';
import DOMPurify from 'dompurify';
import { AdSettings } from './ad-settings/AdSettings';
import './ad-details-tabs.scss';

export const AdDetails = observer((): ReactElement => {
  const {
    currentPage,
    user: { isSalesman, isPartner },
    router: { history },
  } = useMst();
  const { t } = useTranslation();

  const billSpecVisible = () =>
    (currentPage?.adDetails?.status === SUBMITTED ||
      currentPage?.adDetails?.status === PUBLISHED) &&
    !currentPage.emptyBillSpec;

  const approveAndRejectVisible = () =>
    (currentPage?.adDetails?.status === SUBMITTED ||
      ((currentPage?.adDetails?.status === PUBLISHED_SOON_UPDATE ||
        currentPage?.adDetails?.status === PUBLISHED_UPDATE) &&
        currentPage.updateAllowed)) &&
    !currentPage?.isPartnerView &&
    !isSalesman &&
    !isPartner;

  const isRejected = () => currentPage?.adDetails?.status === REJECTED;
  const isInPreparation = () => currentPage?.adDetails?.status === PREP;
  const isSubmitted = () => currentPage?.adDetails?.status === SUBMITTED;
  const shouldShowEditAdBtn = () =>
    (isRejected() || isInPreparation() || isSubmitted()) && !isPartner;

  const shouldShowStopCampaign = () =>
    currentPage?.adDetails?.status === PUBLISHED ||
    currentPage?.adDetails?.status === PUBLISHED_SOON;

  const shouldShowPlaceAgain = () =>
    currentPage.adDetails?.status === EXPIRED ||
    currentPage.adDetails?.status === STOPPED ||
    currentPage.adDetails?.status === CODE_SPENT;

  return (
    <div className={styles.PageContainer}>
      <div
        className={styles.BackToOffersContainer}
        onClick={() => {
          currentPage.setShowCancelReasonInput();
          currentPage.navigateToProperBackPage();
        }}
      >
        <img className={styles.Arrow} src={arrowBack} alt={'arrow right'} />
        <span>{t('basic:back_to_ad_list')}</span>
      </div>
      <div className={styles.FormContainerTop}>
        <Section model={currentPage.getSectionHeaderLabel()}>
          <div className={styles.FormContent}>
            <div className={styles.MainContentContainer}>
              {currentPage.isPartnerView ? (
                <div className={styles.CenteredTabsContainer}>
                  <Tabs
                    model={currentPage.getTabs()}
                    tabsClass={'ad-detail-tabs'}
                  >
                    <TabPanel>
                      <AdOverview />
                    </TabPanel>
                    <TabPanel>
                      <AdSettings />
                    </TabPanel>
                  </Tabs>
                </div>
              ) : (
                <AdOverview />
              )}
            </div>
          </div>
        </Section>
        {shouldShowEditAdBtn() && (
          <Button
            customButtonStyles={{ marginBottom: 15 }}
            onClick={() => {
              currentPage.editInactiveAd();
            }}
          >
            {t('basic:edit_ad')}
          </Button>
        )}
        <Show condition={approveAndRejectVisible()}>
          <Button
            onClick={() => {
              currentPage.getConfirmationModal().openModal(PUBLISH_AD);
            }}
          >
            {t('basic:approve_ad')}
          </Button>
          <ConfirmationAdModal model={currentPage.getConfirmationModal()} />
          <ApproveRejectModal model={currentPage.getApproveRejectAd()} />
          <Show condition={!currentPage.showCancelReasonInput}>
            <div className={styles.CancelLinkContainer}>
              <span
                className={styles.CancelApprovalLink}
                onClick={() => {
                  currentPage.setShowCancelReasonInput();
                }}
              >
                {t('basic:reject_ad')}
              </span>
            </div>
          </Show>
          <Show condition={currentPage.showCancelReasonInput}>
            <div className={styles.TextAreaInputField}>
              <TextAreaInputField model={currentPage.getTextInputField()} />
            </div>
            <LightButton model={currentPage.getChangeButton()} />
          </Show>
        </Show>
        <Show
          condition={
            currentPage.adDetails?.status === REJECTED &&
            !currentPage.isPartnerView
          }
        >
          <div>{t('basic:ad_rejection_reason')}</div>
          {currentPage?.root?.data?.adDetails?.rejections !== undefined ? (
            currentPage.root.data.adDetails.rejections.map(
              (rejection: Rejection) => {
                return (
                  <div
                    key={rejection.id}
                    dangerouslySetInnerHTML={{
                      __html: rejection.reason,
                    }}
                  />
                );
              }
            )
          ) : (
            <></>
          )}
        </Show>
        {shouldShowPlaceAgain() && (
          <Button model={currentPage.getPlaceAdAgainButton()} />
        )}
        <Show condition={shouldShowStopCampaign()}>
          <Button
            onClick={() => {
              currentPage.getConfirmationModal().openModal(STOP_AD);
            }}
          >
            {t('basic:stop_campaign')}
          </Button>
          <span
            className={`${styles.Link} ${styles.EditAdText}`}
            onClick={() => {
              currentPage.editActiveAd();
            }}
          >
            {t('basic:edit_ad')}
          </span>
          <ConfirmationAdModal model={currentPage.getConfirmationModal()} />
          <ApproveRejectModal model={currentPage.getApproveRejectAd()} />
        </Show>
        <Show condition={billSpecVisible()}>
          <div className={styles.FormContainer}>
            <Section model={currentPage.getPackagesAndAddonsPriceListSection()}>
              <div className={styles.FormContent}>
                <BillSpecification
                  preview={currentPage.billSpecificationPreview}
                />
              </div>
            </Section>
          </div>
        </Show>
      </div>
    </div>
  );
});
