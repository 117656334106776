import { Instance, types } from 'mobx-state-tree';
import {
  ComponentModel,
  DropdownOptionModel,
  DropdownOptionModelType,
  FunctionModel,
} from '../../../internal';
import { ChangeEvent } from 'react';
import { validateRequiredStr } from '../../../validators/validators';

// Note - value represents selected option id
// DropdownOptionModel - value represents display data
// (such as name) received from the BE (see DropdownOptionModel)
// which can be further tweaked(translated etc) to form displayValue which is actually displayed
export const DropdownModel = types
  .compose(
    ComponentModel,
    types.model({
      options: types.map(DropdownOptionModel),
      initialValue: '',
      onChangeCallback: types.maybe(FunctionModel),
      alwaysShowOptions: false,
      showIcon: false,
    })
  )
  .views((self) => {
    return {
      get optionsArray() {
        return Array.from(self.options.values());
      },
      // gets the whole object(typically persisted from the BE)
      // not just display value
      get selectedOption() {
        return self?.options?.get(self.value)?.data;
      },
      get selectedOptionId() {
        return self?.options?.get(self.value)?.id;
      },
    };
  })
  .actions((self) => {
    return {
      setOnChangeCallback(callback: (value: any) => void) {
        self.onChangeCallback = callback;
      },
      setAlwaysShowOptions(alwaysShow: boolean) {
        self.alwaysShowOptions = alwaysShow;
      },
      removeOption(id: string) {
        self.options.delete(id);
      },
      hasOption(id: string) {
        return self.optionsArray
          .map((option: DropdownOptionModelType) => option.id)
          .includes(id);
      },
      afterCreate() {
        self.value = self.initialValue
          ? self.initialValue
          : self?.optionsArray[0]?.id;
        // we override the validator
        if (self.isRequired) {
          self.addValidator('isRequired', validateRequiredStr);
        }
      },
      selectOption(index: number) {
        const id = self?.optionsArray?.[index]?.id;
        this.handleNewSelection(id);
      },
      clearOptions() {
        self.options.clear();
      },
      setOptions(options: DropdownOptionModelType[], selectFirstOption = true) {
        this.clearOptions();
        options.forEach((option: DropdownOptionModelType) => {
          self.options.set(option.id, option);
          if (option.data) {
            self.options.get(option.id).setData(option.data);
          }
        });
        self.value = selectFirstOption ? self?.optionsArray[0]?.id : self.value;
      },
      onChange(e: ChangeEvent) {
        const id = (e.target as HTMLInputElement).value;
        this.handleNewSelection(id);
      },
      handleNewSelection(id: any, skipCallback?: boolean) {
        // selecting the same option (already selected)
        // shouldn't do anything callback wise,
        // should close the options
        // if they are opened though ;)
        if (id === self.value) {
          self.setAlwaysShowOptions(false);
          return;
        }
        self.setValue(id);
        if (self.onChangeCallback) {
          const selectedOption = self.optionsArray.filter(
            (opt: any) => opt.id === id
          )[0];
          self.setAlwaysShowOptions(false);
          if (!skipCallback) {
            self.onChangeCallback({
              id: id,
              value: selectedOption?.value,
              data: selectedOption?.data,
            });
          }
        }
      },
      resetValue() {
        self.setValue('');
      },
      setShowIcon(value: boolean) {
        self.showIcon = value;
      },
    };
  })
  .named('DropdownModel');

export type DropdownModelType = Instance<typeof DropdownModel>;
