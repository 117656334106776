import { observer } from 'mobx-react';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file, in index.html we overrride part of this(check it out!)
import { DateRangePicker } from 'react-date-range';
import styles from './StandardDuration.module.scss';
import sr from 'date-fns/locale/sr-Latn';

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { dateSRLocale } from '../../../utils/date';
import { DurationModelType } from '../../../internal';
import { isMobileView } from '../../../utils/page';
import { addDays } from 'date-fns';

interface IDuration {
  model: DurationModelType;
  children: any;
  startRangeText: string;
  endRangeText: string;
  maxRange?: number;
}

// this component serves as a basis for behaviorally similar, yet different components
// follows DRY principle
export const StandardDuration = observer((props: IDuration): JSX.Element => {
  const { model, children, startRangeText, endRangeText, maxRange } = props;
  const [maxDate, setMaxDate] = useState<Date>();
  const { t } = useTranslation();

  const [state, setState] = useState<
    [{ key?: any; startDate?: any; endDate?: any }]
  >([
    {
      startDate: model.fromDate ? model.fromDate : new Date(),
      endDate: model.toDate ? model.toDate : new Date(),
      key: 'selection',
    },
  ]);

  const clearDates = () => {
    setState([
      {
        key: 'selection',
        startDate: null,
        endDate: null,
      },
    ]);
    setMaxDate(undefined);
  };

  const datesValid = (): boolean => {
    return !!state[0]?.startDate && !!state[0]?.endDate;
  };

  const getMaxDate = () => {
    if (!maxDate) {
      return undefined;
    }
    return maxDate;
  };

  const getRange = () => {
    return [
      {
        startDate: state[0]?.startDate ? state[0]?.startDate : new Date(),
        endDate: state[0]?.endDate ? state[0]?.endDate : new Date(),
        key: 'selection',
      },
    ];
  };

  return (
    <div className={styles.ModalContentContainer}>
      {children}
      <div className={styles.DateDisplayContainer}>
        <div className={styles.CampaignDateContainer}>
          <div className={styles.CampaignDateTextContainer}>
            {`${startRangeText} ${dateSRLocale(state[0]?.startDate)}`}
            <div
              onClick={() => clearDates()}
              className={`${styles.CampaignDateClear} ${
                datesValid() ? '' : styles.Hidden
              }`}
            ></div>
          </div>
        </div>
        <div className={styles.CampaignDateContainer}>
          <div className={styles.CampaignDateTextRightContainer}>
            {`${endRangeText} ${dateSRLocale(state[0]?.endDate)}`}
            <div
              onClick={() => clearDates()}
              className={`${styles.CampaignDateClear} ${
                datesValid() ? '' : styles.Hidden
              }`}
            ></div>
          </div>
        </div>
      </div>
      <div className={styles.DateRangerPickerContainer}>
        <DateRangePicker
          staticRanges={[]}
          inputRanges={[]}
          onChange={(item: any) => {
            if (maxRange) {
              setMaxDate(addDays(item.selection.startDate, maxRange));
            }
            setState([item.selection]);
          }}
          moveRangeOnFirstSelection={false}
          months={2}
          locale={sr}
          disabledDates={model.disabledDates}
          ranges={getRange()}
          showDateDisplay={false}
          minDate={model.minDate}
          maxDate={maxRange ? getMaxDate() : model.maxDate}
          direction={isMobileView() ? 'vertical' : 'horizontal'}
        />
      </div>
      <div className={styles.SaveButtonContainer}>
        <div
          onClick={() =>
            model.saveAndClose({
              fromDate: state[0]?.startDate,
              toDate: state[0]?.endDate,
            })
          }
          className={
            datesValid() ? styles.SaveButton : styles.SaveButtonDisabled
          }
        >
          <span className={styles.SaveButtonText}>{t('basic:save')}</span>
        </div>
      </div>
    </div>
  );
});
