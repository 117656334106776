import { observer } from 'mobx-react';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { CSVReader } from 'react-papaparse';
import { CSVLink } from 'react-csv';
import {
  ShoppingMall,
  StoreLocationModalModelType,
  useMst,
} from '../../../../../../internal';
import { isMobileView } from '../../../../../../utils/page';
import TEMPLATE_LINK from '../../../../../../assets/files/partner-locations-template.csv';
import DOWNLOAD_ICON from '../../../../../../assets/icons/icon-download.png';
import DRAG_AND_DROP_ICON from '../../../../../../assets/images/icon-drag-and-drop.png';
import UPLOAD_ICON from '../../../../../../assets/images/icon-upload.svg';
import styles from './UploadLocationCodes.module.scss';

export const UploadLocationCodes = observer(
  (props: { model: StoreLocationModalModelType }): JSX.Element => {
    const { model } = props;
    const { currentPage, data } = useMst();
    const buttonRef = React.createRef<CSVReader>();
    const inputRef = useRef<HTMLInputElement>(null);
    const isMobileSize = isMobileView();
    const csvParsedData = data.shoppingMalls.map((mall: ShoppingMall) => {
      return [mall.full_name, mall.address, mall.location_group, mall.id];
    });
    const csvData = [
      ['Full name', 'Adress', 'Location', 'ID'],
      ...csvParsedData,
    ];
    const handleOpenDialog = (e: React.MouseEvent) => {
      if (buttonRef.current) {
        buttonRef.current.open(e);
      }
    };
    const handleOnDrop = (data: any, e: React.DragEvent<HTMLInputElement>) => {
      currentPage.root.api.partnerLocation.uploadCodes(
        e,
        currentPage.root.header.partnerId,
        () => {
          currentPage.root.api.partnerLocation
            .getPartnerLocations(currentPage.root.data.partnerDetails.id)
            .then(() => {
              currentPage.manageStoreLocations();
              model.closeModal();
            });
        }
      );
    };
    const handleOnError = (
      err: any,
      file: any,
      inputElem: any,
      reason: any
    ) => {
      return (
        <div className={styles.Error}>
          <img className={styles.ErrorIcon} />
          <span>
            Niste uspešno uneli podatke sa lokacijama, proverite sadržaj tabele
          </span>
        </div>
      );
    };

    const { t } = useTranslation();
    return (
      <div className={styles.Container}>
        {!isMobileSize ? (
          <>
            <div className={styles.UploadContent}>
              <div className={styles.DragAndDrop}>
                <CSVReader
                  ref={buttonRef}
                  onDrop={handleOnDrop}
                  onError={handleOnError}
                  addRemoveButton
                  style={{
                    dropArea: {
                      borderRadius: 20,
                      background: '#ececec',
                      borderStyle: 'solid',
                      width: '100%',
                    },
                  }}
                  noProgressBar={true}
                >
                  <span>{t('drag_and_drop')}</span>
                  <img
                    src={DRAG_AND_DROP_ICON}
                    className={styles.DragAndDropIcon}
                    alt="drag_and_drop"
                  />
                </CSVReader>
              </div>
              <div className={styles.FileUpload}>
                <div className={styles.UploadCodesButtonContainer}>
                  <input ref={inputRef} type="file" hidden={true} />
                  <div
                    className={styles.UploadContainer}
                    onClick={handleOpenDialog}
                  >
                    <span>Unesite tabelu sa lokacijama</span>
                    <img
                      className={styles.UploadImage}
                      src={UPLOAD_ICON}
                      alt={'upload'}
                    />
                  </div>
                </div>
                <CSVReader
                  ref={buttonRef}
                  onFileLoad={handleOnDrop}
                  onError={handleOnError}
                  noClick
                  noDrag
                  noProgressBar={true}
                >
                  {({ file }: { file: any }) => (
                    <>
                      <div className={styles.ProgressBarContainer}>
                        <div className={styles.ProgressBarLabel}>
                          <div className={styles.FileName}>
                            {model?.csvFile?.name}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </CSVReader>
              </div>
            </div>
            <div className={styles.DownloadLinkContainer}>
              <a
                className={styles.DownloadLink}
                href={TEMPLATE_LINK}
                download="location-code-template.csv"
              >
                <img
                  src={DOWNLOAD_ICON}
                  alt="download"
                  className={styles.DownloadIcon}
                />
                Preuzmi šablon CSV tabele.
              </a>
              <CSVLink
                className={styles.DownloadLink}
                data={csvData}
                filename={'shopping-mall-ids.csv'}
              >
                <img
                  src={DOWNLOAD_ICON}
                  alt="download"
                  className={styles.DownloadIcon}
                />
                Preuzmi šifrarnik tržnih centara.
              </CSVLink>
            </div>
          </>
        ) : (
          <div className={styles.UploadContent}>
            <div className={styles.FileUpload}>
              <div className={styles.UploadCodesButtonContainer}>
                <CSVReader
                  ref={buttonRef}
                  onDrop={handleOnDrop}
                  onError={handleOnError}
                  addRemoveButton
                  style={{
                    dropArea: {
                      borderRadius: 20,
                      background: '#ffffff',
                      border: 'none',
                      width: '100%',
                      padding: '0',
                    },
                  }}
                  noProgressBar={true}
                >
                  <div className={styles.UploadContainer}>
                    <span>Otpremite tabelu</span>
                    <img
                      className={styles.UploadImage}
                      src={UPLOAD_ICON}
                      alt={'upload'}
                    />
                  </div>
                </CSVReader>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
);
