import { observer } from 'mobx-react';
import { TextAreaInputModelType } from '../../../internal';
import { useTranslation } from 'react-i18next';
import styles from './TextAreaInputField.module.scss';
import * as React from 'react';

export const TextArea = observer(
  (props: { model: TextAreaInputModelType; id?: string }): JSX.Element => {
    const { model, id } = props;
    const { t } = useTranslation();
    return (
      <div id={id} className={styles.TextAreaContainer}>
        {model.label ? (
          <label htmlFor={model.id} className={styles.InputLabel}>
            {t(model.label)}
            <span className={styles.Required}>
              {model.isRequired ? '*' : ''}
            </span>
          </label>
        ) : (
          <></>
        )}
        <textarea
          className={styles.TextAreaContent}
          onChange={model.onChange}
          onKeyDown={model.formatInput}
          value={model.value ? model.value : ''}
          placeholder={t(model.placeholder)}
          rows={model.rows}
          style={{ resize: model.resize ? 'both' : 'none', padding: '10px' }}
        />
        {model.isInvalid ? (
          <div className={styles.ErrorContainer}>
            <div className={styles.Error}>{t(model.currentMessageValue)}</div>
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  }
);
