import { observer } from 'mobx-react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckboxGroup } from '../../../components/UI/checkbox/CheckboxGroup';
import { Dropdown } from '../../../components/UI/dropdown/Dropdown';
import { InputField } from '../../../components/UI/input/InputField';
import { Modal } from '../../../components/UI/modal/Modal';
import { ApproveRejectPackageModalModelType } from '../../../internal';
import { BillingModalModelType } from '../../domain/accounts/billing-modal-model/BillingModalModel';
import styles from './ApproveRejectPackageModal.module.scss';

interface IApproveRejectPackageModalProps {
  model: ApproveRejectPackageModalModelType;
}

export const ApproveRejectPackageModal: FC<IApproveRejectPackageModalProps> =
  observer(({ model }) => {
    const { t } = useTranslation();

    return (
      <Modal
        styles={{
          content: {
            //maxHeight: 'fit-content',
            width: '800px',
            paddingLeft: '50px',
            borderRadius: '20px',
            maxHeight: '90%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
          },
        }}
        model={model}
      >
        <p className={styles.title}> Promena tarifnog paketa </p>
        <div className={styles.table}>
          <div className={styles.tableRow}>
            <span> Partner/Kompanija: </span>
            <span> {model.partnerAdmin?.name} </span>
          </div>
          <div className={styles.tableRow}>
            <span> Aktivan paket: </span>
            <span className={styles.activePackage}>
              {t(`calculator:${model.partnerAdmin?.contract?.calculator}`)}
            </span>
          </div>
          <div className={styles.tableRow}>
            <span> Uračunati popusti: </span>
            <div className={styles.tableRowDiscounts}>
              {Boolean(model.partnerAdmin?.contract?.discount_btl) && (
                <span>
                  BTL popust {model.partnerAdmin?.contract.discount_btl}%
                </span>
              )}
              {Boolean(model.partnerAdmin?.contract?.discount_so) && (
                <span>
                  UO popust {model.partnerAdmin?.contract?.discount_so}%
                </span>
              )}
              {Boolean(model.partnerAdmin?.contract?.discount_soho) && (
                <span>
                  SOHO popust {model.partnerAdmin?.contract?.discount_soho}%
                </span>
              )}
              {Boolean(model.partnerAdmin?.contract?.discount_telco) && (
                <span>
                  TELCO popust {model.partnerAdmin?.contract?.discount_telco}%
                </span>
              )}
            </div>
          </div>
        </div>
        <div className={styles.table}>
          <div className={styles.tableRow}>
            <span> Čeka na odobrenje: </span>
            <span className={styles.requestedPackage}>
              {model.partnerAdmin?.contract_request.name}
            </span>
          </div>
          <div className={styles.tableRow}>
            <span> Uračunati popusti: </span>
            <div className={styles.tableRowDiscounts}>
              {Boolean(model.partnerAdmin?.contract_request?.discount_btl) && (
                <span>
                  BTL popust{' '}
                  {model.partnerAdmin?.contract_request?.discount_btl}%
                </span>
              )}
              {Boolean(model.partnerAdmin?.contract_request?.discount_so) && (
                <span>
                  UO popust {model.partnerAdmin?.contract_request?.discount_so}%
                </span>
              )}
              {Boolean(model.partnerAdmin?.contract_request?.discount_soho) && (
                <span>
                  SOHO popust{' '}
                  {model.partnerAdmin?.contract_request?.discount_soho}%
                </span>
              )}
              {Boolean(
                model.partnerAdmin?.contract_request?.discount_telco
              ) && (
                <span>
                  TELCO popust{' '}
                  {model.partnerAdmin?.contract_request?.discount_telco}%
                </span>
              )}
            </div>
          </div>
          {model.root.user.isAdministrator && (
            <div className={styles.buttonsWrapper}>
              <button
                onClick={() =>
                  model.approve(model.partnerAdmin?.contract_request.id)
                }
                className={styles.approveButton}
              >
                Odobri
              </button>
              <button
                onClick={() =>
                  model.reject(model.partnerAdmin?.contract_request.id)
                }
                className={styles.rejectButton}
              >
                Odbij
              </button>
            </div>
          )}
        </div>
      </Modal>
    );
  });
