import { getParent, Instance, types } from 'mobx-state-tree';
import {
  SearchAndMultiselectFilterModel,
  TextInputModel,
  DatePickerV2Model,
  SliderModel,
  DropdownMultiselectModel,
  SortModel,
} from '../../../internal';

export const AdsTableFilterModel = types
  .model({
    id: types.identifier,
    type: types.enumeration([
      'text',
      'multiselect',
      'multiselectNested',
      'date',
      'dateRange',
      'sort',
      'slider',
      'searchAndMultiselect',
    ]),
    filter: types.union(
      DropdownMultiselectModel,
      TextInputModel,
      DatePickerV2Model,
      SortModel,
      SliderModel,
      SearchAndMultiselectFilterModel
    ),
  })
  .views((self) => {
    return {
      get isCurrentFilter(): boolean {
        return (
          (getParent(self, 2) as AdsTableFiltersModelType).currentFilter?.id ===
          self.id
        );
      },
    };
  });

export const AdsTableFiltersModel = types
  .model({
    id: types.identifier,
    filters: types.map(AdsTableFilterModel),
    currentFilter: types.maybe(types.reference(AdsTableFilterModel)),
  })

  .views((self) => {
    return {
      get filtersArray() {
        return Array.from(self.filters.values());
      },
    };
  })
  .actions((self) => {
    return {
      setCurrentFilter(filter: AdsTableFilterModelType) {
        self.currentFilter = filter;
      },
    };
  })
  .named('AdsTableFiltersModel');

export type AdsTableFiltersModelType = Instance<typeof AdsTableFiltersModel>;
export type AdsTableFilterModelType = Instance<typeof AdsTableFilterModel>;
