import { IAnyModelType, Instance, types } from 'mobx-state-tree';
import { ComponentModel, RadioButtonModel } from '../../../../internal';

export const RadioButtonGroupModel: IAnyModelType = types
  .compose(
    ComponentModel,
    types.model({
      buttons: types.map(types.late(() => RadioButtonModel)),
      initialValue: '',
    })
  )
  .views((self) => {
    return {
      get buttonsArray() {
        return Array.from(self.buttons.values());
      },
      get selectedButton() {
        return self.buttons.get(self.value);
      },
    };
  })
  .actions((self) => {
    return {
      setValue(value: string) {
        self.value = value;
      },
      beforeDestroy() {
        self.buttons.clear();
      },
      afterCreate() {
        self.value = self.initialValue || self.buttonsArray[0]?.id;
        self.buttonsArray.forEach((button: RadioButtonGroupModelType) => {
          button.setParent(self.id);
        });
      },
      addButton(button: RadioButtonGroupModelType) {
        self.buttons.set(button.id, RadioButtonModel.create(button));
        self.buttons.get(button.id).setParent(self);
      },
      setButtons(buttonsArr: RadioButtonGroupModelType[]) {
        self.buttons.clear();
        buttonsArr.forEach((button) => {
          this.addButton(button);
        });
        self.value = self.initialValue;
      },
      clearData() {
        self.buttons.clear();
      },
    };
  })
  .named('RadioButtonGroupModel');

export type RadioButtonGroupModelType = Instance<typeof RadioButtonGroupModel>;
