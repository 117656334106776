import styles from './TariffPackages.module.scss';
import checkmarkIcon from '../../../../assets/icons/checkmark_icon.png';
import infoIcon from '../../../../assets/icons/info_icon.png';
import { Tooltip } from 'react-tippy';
import { useMst } from '../../../../internal';
import { Button } from '../../../UI/buttons/regular/Button';
import clockIcon from '../../../../assets/icons/clock_icon.png';
import { observer } from 'mobx-react';

export const PayAsYouGo = observer((): JSX.Element => {
  const {
    data: { isPayAsYouGo, payAsYouGoCatalog },
    currentPage: {
      isPayAsYouGoRequested,
      setPackageName,
      getConfirmSendRequestModal,
      getRequestTariffChangeButton,
      isRequestSubmitted,
    },
  } = useMst();
  const requestPackageChange = () => {
    setPackageName('pay-as-you-go');
    getConfirmSendRequestModal().setOpened(true);
  };
  return (
    <>
      <div className={styles.Text}>
        <h5 className={styles.Title}>Plaćanje po oglasu</h5>
        <p>
          Paket plaćanja po oglasu je namenjen partnerima koji žele punu slobodu
          u korišćenju naših servisa. Nemate fiksnih mesečnih obaveza, ukoliko
          imate povremene kampanje ovaj paket je idealan za vas. Ukoliko
          postavite više od 2 oglasa ostvarujete popust od 10%.
        </p>
      </div>
      <div
        className={`${styles.PayAsYouGoContent} ${
          isPayAsYouGo ? styles.ActiveBorder : ''
        }`}
      >
        <div className={styles.Box1}>
          <div className={styles.Content}>
            <p className={styles.Title}>Osnovno oglašavanje</p>
            <p className={styles.Description}>Jedan oglas po danu</p>
            <div className={styles.Price}>
              <p className={styles.BigNumber}>
                {payAsYouGoCatalog?.price_basic}
              </p>
              <div className={styles.Currency}>
                <p>RSD</p>
                <p>dan</p>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.Box2}>
          <div className={styles.Content}>
            <div className={styles.TitleWrapper}>
              <p className={styles.Title}>Dodatne opcije po izboru</p>
              <Tooltip
                theme={'dark'}
                html={
                  <div style={{ padding: '0.2em' }}>
                    <span>
                      Uz malu doplatu, omogućićete bolje pozicioniranje Vašeg
                      oglasa i ciljano targetiranje korisnika
                    </span>
                  </div>
                }
                position="top"
              >
                <img src={infoIcon} alt="info" />
              </Tooltip>
            </div>
            <div className={styles.FlexContent}>
              <div>
                <p className={styles.Description}>Ciljna grupa</p>
                <div className={styles.Price}>
                  <p className={styles.BigNumber}>
                    {payAsYouGoCatalog?.price_targeted}
                  </p>
                  <div className={styles.Currency}>
                    <p>RSD</p>
                    <p>dan</p>
                  </div>
                </div>
              </div>
              <div>
                <p className={styles.Description}>Istaknuti oglas</p>
                <div className={styles.Price}>
                  <p className={styles.BigNumber}>
                    {payAsYouGoCatalog?.price_top}
                  </p>
                  <div className={styles.Currency}>
                    <p>RSD</p>
                    <p>dan</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.Box3}>
          <div className={styles.Content}>
            <div className={styles.TitleWrapper}>
              <p className={styles.Title}>Mogući popusti</p>
              <Tooltip
                theme={'dark'}
                html={
                  <div style={{ padding: '0.2em' }}>
                    <span>
                      Kontaktirajte Vašeg partneršip eksperta za ostvarivanje
                      ovih popusta
                    </span>
                  </div>
                }
                position="top"
              >
                <img src={infoIcon} alt="info" />
              </Tooltip>
            </div>
            <div className={styles.TextContent}>
              <p className={styles.Description}>
                Popust za Yettel korisnike{' '}
                <span style={{ fontWeight: 'bold' }}>
                  {payAsYouGoCatalog?.discount_telco}%
                </span>
              </p>
              <p className={styles.Description}>
                Popust SOHO{' '}
                <span style={{ fontWeight: 'bold' }}>
                  {payAsYouGoCatalog?.discount_soho}%
                </span>
              </p>
            </div>
          </div>
        </div>
        <div
          className={
            isPayAsYouGoRequested
              ? styles.Box4RequestSent
              : isPayAsYouGo
              ? styles.Active
              : styles.Box4Blank
          }
        >
          {isPayAsYouGo && (
            <>
              <img src={checkmarkIcon} alt="check" />
              <p>Aktivan paket</p>
            </>
          )}

          {isRequestSubmitted || isPayAsYouGo ? null : (
            <div className={styles.Button}>
              <Button
                model={getRequestTariffChangeButton()}
                onClick={requestPackageChange}
                disabledTooltipMessage={
                  'Način naplate je moguće promeniti jednom mesečno.'
                }
                customButtonStyles={{
                  height: '48px',
                  width: '168px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              />
            </div>
          )}
          {isPayAsYouGoRequested ? (
            <div className={styles.WaitingForApproval}>
              <img src={clockIcon} alt="waiting for approval" />
              <span>Čeka na odobrenje</span>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
});
