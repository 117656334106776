import { ReactElement } from 'react';
import styles from './AdministrationTable.module.scss';
import { useTranslation } from 'react-i18next';
import EDIT_ICON from '../../../assets/icons/icons8_edit_property_96px.png';
import DELETE_ICON from '../../../assets/icons/icons8_trash_32px.png';
import { AdministrationAccountModelType, useMst } from '../../../internal';
import { observer } from 'mobx-react';
import { SUBMITTED } from '../../../constants/admins';

export interface TableData {
  type: string;
  tableTitle: string;
  btnText: string;
  editable: boolean;
  data: AdministrationAccountModelType[];
}

export const AdministrationTable = observer(
  (props: TableData): ReactElement => {
    const tableData = { ...props };
    const { currentPage } = useMst();

    const { t } = useTranslation();

    return (
      <div className={styles.Container}>
        <div className={styles.TableWrapper}>
          <h5 className={styles.TableTitle}>{tableData.tableTitle}</h5>
          <div className={styles.TableContainer}>
            <div className={styles.Section}>
              <div className={styles.TableContent}>
                <table>
                  <thead>
                    <tr className={styles.HeaderRow}>
                      <th>{t('administration:name')}</th>
                      <th>{t('administration:email')}</th>
                      {tableData.editable && (
                        <>
                          <th></th>
                          <th></th>
                        </>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {tableData.data.map(
                      (elem: AdministrationAccountModelType) => {
                        return (
                          <tr key={elem.id}>
                            <td>
                              {elem.first_name} {elem.last_name}
                            </td>
                            <td>
                              <span>{elem.email}</span>
                            </td>
                            {tableData.editable && (
                              <>
                                <td className={styles.Resend}>
                                  {elem.status === SUBMITTED && (
                                    <a
                                      onClick={async () => {
                                        await currentPage.root.api.admin.resendCreateAdminEmail(
                                          {
                                            email: elem.email,
                                          }
                                        );
                                        currentPage.root.showSuccess(
                                          t('alert:email_sent'),
                                          undefined
                                        );
                                      }}
                                      className={styles.Link}
                                    >
                                      {t('basic:resend_verification_email')}
                                    </a>
                                  )}
                                </td>
                                <td className={styles.ActionCell}>
                                  <img
                                    className={styles.Edit}
                                    width="32px"
                                    height="32px"
                                    onClick={() => {
                                      currentPage.openAddAccountModal(
                                        tableData.type,
                                        elem.id
                                      );
                                    }}
                                    src={EDIT_ICON}
                                    alt={'edit'}
                                  />
                                  <img
                                    className={styles.Delete}
                                    width="32px"
                                    height="32px"
                                    onClick={() => {
                                      currentPage.openDeleteAccountModal(
                                        elem.id
                                      );
                                    }}
                                    src={DELETE_ICON}
                                    alt={'delete'}
                                  />
                                </td>
                              </>
                            )}
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </table>
              </div>
              {tableData.editable && (
                <div className={styles.BtnWrapper}>
                  <button
                    className={styles.ActionBtn}
                    onClick={() =>
                      currentPage.openAddAccountModal(tableData.type)
                    }
                  >
                    + {tableData.btnText}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
);
