import { getRoot, Instance, types } from 'mobx-state-tree';

import i18n from '../../../i18n';
import { CATEGORIES } from '../../../constants/createoffer';
import { PAGE_SIZE } from '../../../utils/page';
import { IMAGES_BASE_URL } from '../../../utils/api/images';
import { parseDate } from '../../../utils/date';
import { toAmount } from '../../../utils/methods';
import {
  Ad,
  AdFilters,
  AdListElemModel,
  AdListElemModelType,
  ConfirmationModalModel,
  ModalModel,
  OldAdListModel,
  PageModel,
  PaginationModel,
  RootType,
  TabModel,
  TabsModel,
} from '../../../internal';
import { PREP, SUBMITTING } from '../../../constants/ad';

export const PartnerOffersPageModel = types
  .compose(
    PageModel,
    types.model({
      activeTab: CATEGORIES,
    })
  )
  .views((self) => {
    return {
      get root(): RootType {
        return getRoot(self);
      },
    };
  })
  .actions((self) => {
    return {
      async onPageExit() {
        // prevent caching
        self.clearData();
      },
      async beforePageEnter() {
        const partnerId = this.getPartnerId();
        self.root.api.partner.get(partnerId);
        await this.loadAds();
      },

      async loadAds(resetPagination = true) {
        if (resetPagination) {
          this.resetPagination();
        }

        const newFilter: AdFilters = this.generateNewFilter();
        const response = await self.root.api.offers.getPartnerAds({
          ...newFilter,
          partner_id: this.getPartnerId(),
        });
        this.setTotalPages(response.meta.total);
        const adElements = this.generateAdElements(response.data);
        this.getAdList().setElements(adElements);
      },

      async deleteSelectedAd(offerId: string) {
        await self.root.api.offers.deleteAd(offerId);
      },

      getDeleteDraftAdConfirmationModal(offerId: string) {
        return (
          self.components.get(`${offerId}DeleteDraftOfferConfirmationModal`) ||
          self.addComponent(
            ConfirmationModalModel.create({
              id: `${offerId}DeleteDraftOfferConfirmationModal`,
              labelNo: 'basic:no',
              labelYes: 'basic:yes',
              modal: ModalModel.create({
                id: 'modal',
                opened: false,
                showCloseButton: false,
              }),
            })
          )
        );
      },
      getSuccessfulDeleteDraftAdConfirmationModal(offerId: string) {
        return (
          self.components.get(
            `${offerId}SuccessfulDeleteDraftOfferConfirmationModal`
          ) ||
          self.addComponent(
            ConfirmationModalModel.create({
              id: `${offerId}SuccessfulDeleteDraftOfferConfirmationModal`,
              labelYes: 'basic:ok',
              modal: ModalModel.create({
                id: 'modal',
                opened: false,
                showCloseButton: false,
              }),
            })
          )
        );
      },
      getPartnerId() {
        const partnerId = self?.root?.header.partnerId;
        return partnerId;
      },
      getAdList() {
        return (
          self.components.get('AdList') ||
          self.addComponent(
            OldAdListModel.create({
              id: 'AdList',
              ads: {},
              pagination: PaginationModel.create({
                id: 'AdListPagination',
                currentPage: 1,
                totalPages: 1,
                pageChangedCallback: (pageNum: number) => {
                  this.loadAds(false);
                },
              }),
            })
          )
        );
      },

      getPartner() {
        return self.root.data.partnerDetails;
      },

      generateAdElements(ads: Ad[]) {
        const retVal: AdListElemModelType[] = [];
        ads.forEach((ad: Ad) => {
          retVal.push(
            AdListElemModel.create({
              id: `${ad.id}`,
              imageSrc: `${IMAGES_BASE_URL}${ad.image0}`,
              title: ad.name,
              partner: this.getPartner()?.name,
              createdAt: parseDate(ad.date_start),
              dateEnd: parseDate(ad.date_end).toDateString(),
              status: ad.status,
              price: toAmount(parseFloat(ad.discount_value)),
              originalPrice: toAmount(parseFloat(ad.original_price)),
              recommended: ad.recommended.toString(),
              submittedAt: ad.submitted_at,
            })
          );
        });
        return retVal;
      },

      getTabs() {
        return (
          self.components.get('Tabs') ||
          self.addComponent(
            TabsModel.create({
              id: 'Tabs',
              selectedTab: 'active',
              tabs: {
                active: TabModel.create({
                  id: 'active',
                  name: i18n.t('tabs:active'),
                }),
                expired: TabModel.create({
                  id: 'expired',
                  name: i18n.t('tabs:expired'),
                }),
                submitted: TabModel.create({
                  id: 'submitted',
                  name: i18n.t('tabs:submitted'),
                }),
                rejected: TabModel.create({
                  id: 'rejected',
                  name: i18n.t('tabs:rejected'),
                }),
                prep: TabModel.create({
                  id: 'prep',
                  name: i18n.t('tabs:prep'),
                }),
              },
              onChangeCallback: () => {
                this.loadAds();
              },
            })
          )
        );
      },

      setTotalPages(totalAds: number) {
        if (!totalAds) {
          this.getAdList().pagination.setTotalPages(1);
          return;
        }
        const totalPages = Math.ceil(totalAds / PAGE_SIZE);
        this.getAdList().pagination.setTotalPages(totalPages);
      },

      generatePagination(): { offset: number; limit: number } {
        let currentPage =
          self?.components?.get('AdList')?.pagination?.currentPage;
        currentPage = currentPage ? currentPage : 1;
        return {
          offset: (currentPage - 1) * PAGE_SIZE,
          limit: PAGE_SIZE,
        };
      },

      resetPagination() {
        this?.getAdList()?.pagination?.setCurrentPage(1);
      },

      generateNewFilter(): AdFilters {
        const retVal: AdFilters = {
          ...this.generatePagination(),
          status: this.getTabs().selectedTab,
        };
        return retVal;
      },
    };
  })
  .named('PartnerOffersPageModel');

export type PartnerOffersPageType = Instance<typeof PartnerOffersPageModel>;
