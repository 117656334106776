import {
  differenceInDays,
  differenceInSeconds,
  intervalToDuration,
} from 'date-fns';
import i18n from '../i18n';
import { PREP, SUBMITTING } from '../constants/ad';

export function toAmount(value: number | string, options = {}): any {
  return Number(value).toLocaleString('sr-RS', options);
}

export function formatCoordinates(value: number | string): any {
  return Number(value).toLocaleString('en-US', {
    maximumFractionDigits: 6,
  });
}

export function parseNumberSRLocale(numberText: string): number {
  return Number(numberText.replace('.', '').replace(',', '.'));
}

export function copyToClipboard(text: string): any {
  // navigator clipboard api needs a secure context (https)
  if (navigator.clipboard && window.isSecureContext) {
    // navigator clipboard api method'
    return navigator.clipboard.writeText(text);
  } else {
    // text area method
    const textArea = document.createElement('textarea');
    textArea.value = text;
    // make the textarea out of viewport
    textArea.style.position = 'fixed';
    textArea.style.left = '-999999px';
    textArea.style.top = '-999999px';
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    return new Promise<void>((res, rej) => {
      // here the magic happens
      document.execCommand('copy') ? res() : rej();
      textArea.remove();
    });
  }
}

export function getCategoryIdFromSubcategory(
  categorySubcategoryId: string
): string {
  return categorySubcategoryId.split('_')[0];
}

export function getSubcategoryIdFromSubcategory(
  categorySubcategoryId: string
): string {
  return categorySubcategoryId.split('_')[1];
}

export function getDisplayedPrice(
  original_price?: number | string | null,
  discount_value?: number | string | null
): any {
  const price =
    original_price && !isNaN(Number(original_price))
      ? Number(original_price)
      : null;
  const discount =
    discount_value && !isNaN(Number(discount_value))
      ? Number(discount_value)
      : null;
  if (discount) {
    return discount;
  }
  return price;
}

// EXPIRATION METHODS

export function isValidForLessThanOneMinute(
  validityEnd: Date | null,
  isExpired?: boolean | null
): any {
  if (validityEnd && !isExpired) {
    return differenceInSeconds(validityEnd, new Date()) < 59;
  }
  return false;
}

export function isValidForLessThanOneDay(validityEnd: Date | null): any {
  if (validityEnd) {
    return differenceInDays(validityEnd, new Date()) < 1;
  }
  return false;
}

export function isValidForFiveOrLessDays(validityEnd: Date | null): any {
  if (validityEnd) {
    return differenceInDays(validityEnd, new Date()) < 6;
  }
  return false;
}

export function isValidForSixToTenDays(validityEnd: Date | null): any {
  if (validityEnd) {
    return (
      differenceInDays(validityEnd, new Date()) < 11 &&
      differenceInDays(validityEnd, new Date()) > 5
    );
  }
  return false;
}

export function isValidForMoreThanTenDays(validityEnd: Date | null): any {
  if (validityEnd) {
    return differenceInDays(validityEnd, new Date()) > 10;
  }
  return false;
}

export function durationEndsWithButDoesntBeginWithOne(num: number): any {
  return num.toString().endsWith('1') && num !== 11;
}

export function durationEndsWithTwoThreeFourButDoesntBeginWithOne(
  num: number
): any {
  return (
    (num.toString().endsWith('2') ||
      num.toString().endsWith('3') ||
      num.toString().endsWith('4')) &&
    num !== 12 &&
    num !== 13 &&
    num !== 14
  );
}

export function getHoursString(num: number): any {
  if (durationEndsWithButDoesntBeginWithOne(num)) {
    return ' sat';
  } else if (durationEndsWithTwoThreeFourButDoesntBeginWithOne(num)) {
    return ' sata';
  } else if (num === 0) {
    return '';
  } else {
    return ' sati';
  }
}
export function getMinutesString(num: number): any {
  if (durationEndsWithButDoesntBeginWithOne(num)) {
    return ' minut';
  } else {
    return ' minuta';
  }
}
export function getSecondsString(num: number): any {
  if (durationEndsWithButDoesntBeginWithOne(num)) {
    return `${num} sekundu`;
  } else if (durationEndsWithTwoThreeFourButDoesntBeginWithOne(num)) {
    return `${num} sekunde`;
  } else if (num === 0) {
    return '';
  } else {
    return `${num} sekundi`;
  }
}

export function getExpiration(validityEnd: Date | null): any {
  if (validityEnd) {
    const durationInMonths = intervalToDuration({
      start: new Date(),
      end: validityEnd,
    }).months;
    const durationInDays = intervalToDuration({
      start: new Date(),
      end: validityEnd,
    }).days;

    const durationInMonthsString = durationInMonths
      ? `${durationInMonths} ${
          durationEndsWithButDoesntBeginWithOne(durationInMonths)
            ? 'mesec '
            : durationEndsWithTwoThreeFourButDoesntBeginWithOne(
                durationInMonths
              )
            ? 'meseca '
            : 'meseci '
        }`
      : '';

    const durationInDaysString = durationInDays
      ? `${durationInDays} ${
          durationEndsWithButDoesntBeginWithOne(durationInDays) ? 'dan' : 'dana'
        }`
      : '';

    if (durationInMonths || durationInDays) {
      return `${durationInMonthsString}${durationInDaysString} `;
    } else {
      return '';
    }
  } else {
    return '';
  }
}

// END EXPIRATION METHODS

/**
 * Returns an object which contains a promise, resolve and reject method
 * This promise can be resolved whenever we want, whereer we want
 */
export function createExternalPromise<T>(): {
  promise: Promise<T>;
  resolve: (value: T | PromiseLike<T>) => void;
  reject: (reason?: any) => void;
} {
  const obj: any = {};
  obj.promise = new Promise<T>((resolve, reject) => {
    obj.resolve = resolve;
    obj.reject = reject;
  });
  return obj;
}

/**
 *  These methods allow the user to manage a browser controlled dialog
 *  which allows him to cancel refreshing/leaving the tab
 *  @param e unload event
 */

const antiRefresh = (e: BeforeUnloadEvent) => {
  // Cancel the event
  e.preventDefault();
  // Chrome requires returnValue to be set
  e.returnValue = i18n.t('basic:create_offer_leave_confirmation');
};

export function registerAntiRefresh(): void {
  // To prevent accidental page refresh (at least give the user the option to decline)
  window.addEventListener('beforeunload', antiRefresh);
}

export function unregisterAntiRefresh(): void {
  window.removeEventListener('beforeunload', antiRefresh);
}

export function isOfferDraftForSave(status: string): boolean {
  return status === PREP || status === SUBMITTING || !status;
}
