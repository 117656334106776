import { observer } from 'mobx-react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { OfferStepsModelType, useMst } from '../../../internal';
import { Show } from '../../UI/show/Show';
import styles from './OfferSteps.module.scss';
import { PriceCheckType } from '../../../utils/api/ResponseTypes';

export const OfferSteps = observer(
  (props: {
    model: OfferStepsModelType;
    id?: string;
    standardPackageWithoutVoucher?: boolean;
  }): JSX.Element => {
    const { model, id, standardPackageWithoutVoucher } = props;
    const { currentPage } = useMst();
    const { t } = useTranslation();
    return (
      <div id={id} className={styles.OfferStepsContainer}>
        <div
          onClick={() => model.selectStep('1')}
          className={styles.StepContainer}
        >
          <div
            className={
              model.isFirstSelected ||
              model.isSecondSelected ||
              model.isThirdSelected
                ? styles.PassedStepNumberContainer
                : styles.StepNumberContainer
            }
          >
            <span className={styles.StepNumber}>{1}</span>
          </div>
          <div className={styles.StepText}>{t('steps:create_offer')}</div>
        </div>
        <div className={styles.StepLine}></div>
        <Show condition={!standardPackageWithoutVoucher}>
          <div
            onClick={() => model.selectStep('2')}
            className={styles.StepContainer}
          >
            <div
              className={
                model.isSecondSelected || model.isThirdSelected
                  ? styles.PassedStepNumberContainer
                  : styles.StepNumberContainer
              }
            >
              <span className={styles.StepNumber}>{2}</span>
            </div>
            <div className={styles.StepText}>
              {t('steps:target_group_and_codes')}
            </div>
          </div>
        </Show>
        <div className={styles.StepLine}></div>
        <div
          onClick={async () => {
            const response: PriceCheckType =
              await currentPage.submitOfferForPriceCheck();
            currentPage.setOfferPriceCheck(response);
            model.selectStep('3');
          }}
          className={styles.StepContainer}
        >
          <div
            className={
              model.isThirdSelected
                ? styles.PassedStepNumberContainer
                : styles.StepNumberContainer
            }
          >
            <span className={styles.StepNumber}>
              {standardPackageWithoutVoucher ? 2 : 3}
            </span>
          </div>
          <div className={styles.StepText}>
            {t('steps:sending_for_approval')}
          </div>
        </div>
      </div>
    );
  }
);
