import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import styles from './Administrators.module.scss';
import EDIT_ICON from '../../../../assets/icons/icons8_edit_property_96px.png';
import {
  AdministratorsModelType,
  PartnerAdminPublic,
  useMst,
} from '../../../../internal';
import { CreateUpdateAdministratorModal } from '../../administrators/CreateUpdateAdministratorModal';
import DELETE_ICON from '../../../../assets/icons/icons8_trash_32px.png';
import { ConfirmationModal } from '../../../UI/confirmation-modal/ConfirmationModal';
import { SUBMITTED } from '../../../../constants/admins';

export const Administrators = observer(
  (props: { model: AdministratorsModelType }): JSX.Element => {
    const { t } = useTranslation();
    const { model } = props;
    const { currentPage } = useMst();
    return (
      <div className={styles.Container}>
        <div className={styles.Section}>
          <div className={styles.TableContent}>
            <table>
              <thead>
                <tr className={styles.HeaderRow}>
                  <th className={`${styles.Left} ${styles.WideColumn}`}>
                    {t('basic:e-mail')}
                  </th>
                  <th className={styles.Left}>{t('basic:first_name')}</th>
                  <th className={styles.Left}>{t('basic:last_name')}</th>
                  <th className={styles.Left}>{t('basic:phone_number')}</th>
                  <th className={styles.Left}>Status</th>
                  <th className={`${styles.JustifyCenter}`}>
                    {t('basic:edit')}
                  </th>
                  <th className={`${styles.JustifyCenter}`}>
                    {t('basic:delete')}
                  </th>
                  <th className={styles.Resend}></th>
                </tr>
              </thead>
              <tbody>
                {model.adminsArray.map(
                  (elem: PartnerAdminPublic, index: number) => {
                    return (
                      <tr key={index}>
                        <td className={`${styles.Left} ${styles.Break}`}>
                          {elem.email}
                        </td>
                        <td className={styles.Left}>{elem.first_name}</td>
                        <td className={styles.Left}>{elem.last_name}</td>
                        <td className={styles.Left}>{elem.phone}</td>
                        <td className={styles.Left}>
                          {model.setStatus(elem.status)}
                        </td>
                        <td className={`${styles.JustifyCenter}`}>
                          <img
                            className={styles.Edit}
                            width="32px"
                            height="32px"
                            onClick={() => {
                              model.openEditAdminModal(elem);
                            }}
                            src={EDIT_ICON}
                          />
                        </td>
                        <td className={`${styles.JustifyCenter}`}>
                          <img
                            className={styles.Delete}
                            width="32px"
                            height="32px"
                            onClick={() => {
                              model.deleteModal.setAfterYesCallback(() => {
                                const id = elem.id;
                                currentPage.root.api.admin
                                  .delete(id)
                                  .then(() => {
                                    currentPage.loadAdmins();
                                  });
                              });
                              model.deleteModal.open();
                            }}
                            src={DELETE_ICON}
                          />
                        </td>
                        <td className={styles.Resend}>
                          {elem.status === SUBMITTED && (
                            <a
                              onClick={async () => {
                                await currentPage.root.api.admin.resendCreateAdminEmail(
                                  {
                                    email: elem.email,
                                  }
                                );
                                currentPage.root.showSuccess(
                                  t('alert:email_sent'),
                                  undefined
                                );
                              }}
                              className={styles.Link}
                            >
                              {t('basic:resend_verification_email')}
                            </a>
                          )}
                        </td>
                      </tr>
                    );
                  }
                )}
              </tbody>
            </table>
          </div>
          <div className={`${styles.AddAdminContainer}`}>
            <span className={styles.Plus}>+</span>
            <a
              onClick={() => {
                model.openCreateAdminModal();
              }}
              className={styles.Link}
            >
              {t('basic:add_admin')}
            </a>
            <CreateUpdateAdministratorModal model={model} />
            <ConfirmationModal model={model.deleteModal} />
          </div>
        </div>
      </div>
    );
  }
);
