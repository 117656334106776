import { observer } from 'mobx-react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonModelType } from '../../../../internal';
import styles from './LightButton.module.scss';

export const LightButton = observer(
  (props: {
    model?: ButtonModelType;
    onClick?: () => void;
    children?: any;
    disabled?: boolean;
    id?: string;
    isYellow?: boolean;
    style?: any;
  }): JSX.Element => {
    const { model, onClick, children, disabled = false, id, isYellow } = props;
    const { t } = useTranslation();
    const isEnabledComputed =
      model?.isEnabledComputed !== undefined ? model?.isEnabledComputed : true;

    const btnClassName = () => {
      if (disabled || !isEnabledComputed) {
        return styles.BtnDisabled;
      }
      if (isYellow) {
        return styles.YellowBtn;
      }
      return styles.Btn;
    };

    return (
      <div
        id={id}
        onClick={() => (onClick ? onClick() : model.onClick())}
        className={btnClassName()}
      >
        {children ? children : t(model?.label)}
      </div>
    );
  }
);
