import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import en_basic from './locales/en/basic.json';
import en_dropdown from './locales/en/dropdown.json';
import en_validators from './locales/en/validators.json';
import sr_basic from './locales/sr/basic.json';
import sr_dropdown from './locales/sr/dropdown.json';
import sr_validators from './locales/sr/validators.json';
import en_user_segments from './locales/en/user_segments.json';
import sr_user_segments from './locales/sr/user_segments.json';
import en_binary from './locales/en/binary.json';
import sr_binary from './locales/sr/binary.json';
import en_campaign_reach from './locales/en/campaign_reach.json';
import sr_camppaign_reach from './locales/sr/campaign_reach.json';
import en_campaign_duration from './locales/en/campaign_duration.json';
import sr_campaign_duration from './locales/sr/campaign_duration.json';
import en_voucher_duration from './locales/en/voucher_duration.json';
import sr_voucher_duration from './locales/sr/voucher_duration.json';
import en_steps from './locales/en/steps.json';
import sr_steps from './locales/sr/steps.json';
import en_warnings from './locales/en/warnings.json';
import sr_warnings from './locales/sr/warnings.json';
import en_offer from './locales/en/offer.json';
import sr_offer from './locales/sr/offer.json';
import en_forms from './locales/en/forms.json';
import sr_forms from './locales/sr/forms.json';
import en_tabs from './locales/en/tabs.json';
import sr_tabs from './locales/sr/tabs.json';
import en_alert from './locales/en/alert.json';
import sr_alert from './locales/sr/alert.json';
import en_ad_status from './locales/en/ad_status.json';
import sr_ad_status from './locales/sr/ad_status.json';
import sr_company_profile from './locales/sr/company_profile.json';
import en_company_profile from './locales/en/company_profile.json';
import sr_packages from './locales/sr/packages.json';
import en_packages from './locales/en/packages.json';
import en_account_status from './locales/en/account_status.json';
import sr_account_status from './locales/sr/account_status.json';
import en_contract_duration from './locales/en/contract_duration.json';
import sr_contract_duration from './locales/sr/contract_duration.json';
import en_partner_ad_status from './locales/en/partner_ad_status.json';
import sr_partner_ad_status from './locales/sr/partner_ad_status.json';
import en_ad_settings from './locales/en/ad_settings.json';
import sr_ad_settings from './locales/sr/ad_settings.json';
import en_ad_settings_titles from './locales/en/ad_settings_titles.json';
import sr_ad_settings_titles from './locales/sr/ad_settings_titles.json';
import en_navigation from './locales/en/navigation.json';
import sr_navigation from './locales/sr/navigation.json';
import en_administration from './locales/en/administration.json';
import sr_administration from './locales/sr/administration.json';
import sr_ads_table_columns from './locales/sr/ads_table_columns.json';
import sr_contract_request_status from './locales/sr/contract_request_status.json';
import sr_calculator from './locales/sr/calculator.json';
import en_banners from './locales/en/banners.json';
import sr_banners from './locales/sr/banners.json';

export const resources = {
  en: {
    dropdown: en_dropdown,
    basic: en_basic,
    validators: en_validators,
    user_segments: en_user_segments,
    binary: en_binary,
    campaign_reach: en_campaign_reach,
    campaign_duration: en_campaign_duration,
    voucher_duration: en_voucher_duration,
    steps: en_steps,
    warnings: en_warnings,
    offer: en_offer,
    forms: en_forms,
    tabs: en_tabs,
    alert: en_alert,
    ad_status: en_ad_status,
    company_profile: en_company_profile,
    packages: en_packages,
    account_status: en_account_status,
    contract_duration: en_contract_duration,
    partner_ad_status: en_partner_ad_status,
    ad_settings: en_ad_settings,
    ad_settings_titles: en_ad_settings_titles,
    navigation: en_navigation,
    administration: en_administration,
    banners: en_banners,
  },
  sr: {
    dropdown: sr_dropdown,
    basic: sr_basic,
    validators: sr_validators,
    user_segments: sr_user_segments,
    binary: sr_binary,
    campaign_reach: sr_camppaign_reach,
    campaign_duration: sr_campaign_duration,
    voucher_duration: sr_voucher_duration,
    steps: sr_steps,
    warnings: sr_warnings,
    offer: sr_offer,
    forms: sr_forms,
    tabs: sr_tabs,
    alert: sr_alert,
    ad_status: sr_ad_status,
    company_profile: sr_company_profile,
    packages: sr_packages,
    account_status: sr_account_status,
    contract_duration: sr_contract_duration,
    partner_ad_status: sr_partner_ad_status,
    ad_settings: sr_ad_settings,
    ad_settings_titles: sr_ad_settings_titles,
    navigation: sr_navigation,
    administration: sr_administration,
    ads_table_columns: sr_ads_table_columns,
    contract_request_status: sr_contract_request_status,
    calculator: sr_calculator,
    banners: sr_banners,
  },
};

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: 'sr',
    lng: 'sr',
    ns: ['basic', 'dropdown', 'validators'],
    detection: {
      order: ['queryString', 'cookie'],
      cache: ['cookie'],
    },
    interpolation: {
      escapeValue: false,
    },
    resources: resources,
    react: {
      useSuspense: false,
    },
  })
  .then();

export default i18n;
