import { IAnyModelType, Instance, types } from 'mobx-state-tree';
import {
  ComponentModel,
  TabModel,
  FunctionModel,
  TabModelType,
} from '../../../internal';

export const TabsModel: IAnyModelType = types
  .compose(
    ComponentModel,
    types.model({
      tabs: types.map(types.late(() => TabModel)),
      selectedTab: types.maybe(types.string),
      onChangeCallback: types.maybe(FunctionModel),
    })
  )
  .actions((self) => {
    return {
      afterCreate() {
        self.tabsArray.forEach((tag: any) => {
          tag.setParent(self.id);
        });
        if (!self.selectedTab) {
          self.selectedTab = self?.tabsArray[0]?.id;
        }
      },
      addTab(tab: TabModelType) {
        self.tabs.put(tab);
      },
      clearTabs() {
        self.tabs.clear();
      },
      handleCallback() {
        self?.onChangeCallback?.(self.selectedTab);
      },
      setOnChangeCallback(callback: (tabid: string) => any) {
        self.onChangeCallback = callback;
      },
      selectIndex(index: number) {
        const newSelectedTab = self.tabsArray[index]?.id;
        // actual on change check
        if (newSelectedTab && newSelectedTab !== self.selectedTab) {
          self.selectedTab = newSelectedTab;
          self.handleCallback();
        }
      },
      selectTab(tabId: string) {
        // actual change check
        if (self.selectedTab !== tabId) {
          self.selectedTab = tabId;
          self.handleCallback();
        }
      },
    };
  })
  .views((self) => {
    return {
      get tabsArray() {
        return Array.from(self.tabs.values());
      },
      get length() {
        return self.tabsArray.length;
      },
      get selectedIndex(): number {
        for (let i = 0; i < self.length; i++) {
          if (self?.tabsArray[i]?.id === self?.selectedTab) {
            return i;
          }
        }
        return -1;
      },
    };
  })
  .named('TabsModel');

export type TabsModelType = Instance<typeof TabsModel>;
