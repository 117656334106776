import { Instance, types } from 'mobx-state-tree';
import {
  ComponentModel,
  LocationPriceDataModel,
  LocationPriceDataModelType,
  ModalModel,
} from '../../../internal';

export const AdditionalLocationModel = types
  .compose(
    ComponentModel,
    types.model({
      numberOfAdditionalLocations: types.number,
      modal: types.late(() => ModalModel),
      prices: types.map(LocationPriceDataModel),
      selectedContractAddon: types.maybe(types.string),
    })
  )
  .actions((self) => {
    return {
      openModal() {
        self.modal.setOpened(true);
      },
      setNumberOfAdditionalLocations(newNumber: number) {
        self.numberOfAdditionalLocations = newNumber;
      },
      clearPrices() {
        self.prices.clear();
      },
      setPrices(prices: any) {
        this.clearPrices();
        prices.forEach((price: LocationPriceDataModelType) => {
          self.prices.set(price.id, price);
        });
      },
      getContractAddons(contractId: string, type: string) {
        self.root.api.contract.getContractAddons(contractId, type, 0, 1000);
      },
      setSelectedContractAddon(priceRange: any) {
        self.selectedContractAddon = priceRange.id;
      },
    };
  })
  .views((self) => {
    return {
      get pricesArray() {
        return Array.from(self.prices.values());
      },
      get amount(): number {
        if (!self.numberOfAdditionalLocations || !this?.pricesArray?.length) {
          return 0;
        }
        // find the range number of location is in, then calculate the price
        let range: any = null;
        for (const priceRange of this.pricesArray) {
          if (
            (priceRange as any).from <= self.numberOfAdditionalLocations &&
            (priceRange as any).to >= self.numberOfAdditionalLocations
          ) {
            self.setSelectedContractAddon(priceRange);
            range = priceRange;
          }
        }
        if (!range) {
          range = this.pricesArray[this.pricesArray.length - 1];
        }
        return range.price * self.numberOfAdditionalLocations;
      },
    };
  })
  .named('AdditionalLocationModel');

export type AdditionalLocationModelType = Instance<
  typeof AdditionalLocationModel
>;
