import { observer } from 'mobx-react';

import { AdministratorsModelType } from '../../../models/domain/administrators/AdministratorsModel';
import { Modal } from '../../UI/modal/Modal';
import styles from './CreateUpdateAdministratorModal.module.scss';
import { InputField } from '../../UI/input/InputField';
import { Button } from '../../UI/buttons/regular/Button';
import { DeleteAdministratorModal } from './DeleteAdministratorModal';
import { useTranslation } from 'react-i18next';

export const DialogFields = (props: {
  model: AdministratorsModelType;
}): JSX.Element => {
  const { model } = props;
  return (
    <>
      <div className={styles.InputFieldContainer}>
        <InputField model={model.getAdminEmail()} />
      </div>
      <div className={styles.InputFieldContainer}>
        <InputField model={model.getAdminFirstName()} />
      </div>
      <div className={styles.InputFieldContainer}>
        <InputField model={model.getAdminLastName()} />
      </div>
      <div className={styles.InputFieldContainer}>
        <InputField model={model.getAdminPhoneNumber()} />
      </div>
    </>
  );
};

export const CreateUpdateAdministratorModal = observer(
  (props: { model: AdministratorsModelType }): JSX.Element => {
    const { model } = props;
    const { t } = useTranslation();
    return (
      <Modal
        styles={{
          content: {
            minWidth: '30%',
            maxHeight: '100vh',
            borderRadius: '20px',
            padding: '40px',
          },
        }}
        model={model.modal}
      >
        {model.isUpdate ? (
          <>
            <div className={styles.HeaderContainer}>
              <span className={styles.Heading}>Izmeni</span>
            </div>
            <DialogFields model={model} />
            <div className={styles.ButtonContainer}>
              <Button model={model.getUpdateAdminButton()} />
            </div>
            <div className={styles.DeleteAdminContainer}>
              <a
                onClick={() => {
                  model.openDeleteModal();
                }}
                className={styles.Link}
              >
                {t('basic:delete_account')}
              </a>
              <DeleteAdministratorModal model={model} />
            </div>
          </>
        ) : (
          <>
            <div className={styles.HeaderContainer}>
              <span className={styles.Heading}>Dodaj administratora</span>
            </div>
            <DialogFields model={model} />
            <div className={styles.ButtonContainer}>
              <Button model={model.getSaveAdminButton()} />
            </div>
          </>
        )}
      </Modal>
    );
  }
);
