import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import {
  EXPIRED,
  REJECTED,
  SUBMITTED,
  PENDING,
  ACTIVE,
  CODE_SPENT,
  STOPPED,
  PUBLISHED,
  PREP,
} from '../../../../constants/ad';
import i18n from '../../../../i18n';
import { AdListElemModelType, PageRoutes, useMst } from '../../../../internal';
import { dateSRLocale } from '../../../../utils/date';
import styles from './PartnerAdListElem.module.scss';
import { Tooltip } from 'react-tippy';
import thumbsUp from '../../../../assets/icons/thumbs-up.png';
import EMPTY_CART from '../../../../assets/icons/empty-cart.png';
import SUCCESS from '../../../../assets/images/checked-green-background.png';
import { ConfirmationModal } from '../../../UI/confirmation-modal/ConfirmationModal';

// These objects serve  to help map BE statuses to FE colors
const statusColors = {
  prep: 'var(--pendingstatus)',
  publishing: 'var(--rejectedstatus)',
  submitted: 'var(--pendingstatus)',
  submitting: 'var(--pendingstatus)',
  published: 'var(--activestatus)',
  inactive: 'var(--inactivestatus)',
  rejected: 'var(--rejectedstatus)',
  stopped: 'var(--rejectedstatus)',
  update_submitted: 'var(--pendingstatus)',
  published_soon: 'var(--publishedsoonstatus)',
  published_soon_update: 'var(--publishedsoonupdate)',
  published_update: 'var(--publishedsoonupdate)',
};

function getBorderColor(status: string) {
  const borderColor = (statusColors as any)[status];
  if (!borderColor) {
    return {};
  }
  return { borderRightColor: borderColor };
}

function getColor(status: string) {
  const color = (statusColors as any)[status];
  if (!color) {
    return {};
  }
  return { color: color };
}

export const PartnerAdListElem = observer(
  (props: { model: AdListElemModelType }): JSX.Element => {
    const { model } = props;
    const { t } = useTranslation();
    const {
      router: { navigate },
      currentPage,
    } = useMst();
    const validPrice = () => model.price && model.price !== 'NaN';
    const isInactive = () =>
      model.status === EXPIRED ||
      model.status === CODE_SPENT ||
      model.status === STOPPED;
    const isRejected = () => model.status === REJECTED;
    const isPending = () =>
      model.status === PENDING || model.status === SUBMITTED;
    const isDraft = () => model.status === PREP;
    const deleteModal = currentPage.getDeleteDraftAdConfirmationModal(model.id);
    const successfulDeleteModal =
      currentPage.getSuccessfulDeleteDraftAdConfirmationModal(model.id);

    return (
      <div
        className={styles.Container}
        style={getBorderColor(model.status || '')}
      >
        <img className={styles.Image} src={model.imageSrc} />
        <div className={styles.MainContentContainer}>
          <div className={styles.LeftContainer}>
            <div className={styles.Title}>{model.title}</div>
            <div className={styles.Partner}>{model.partner}</div>
          </div>
          <div className={styles.MiddleContainer}>
            {validPrice() && !isDraft() && (
              <div className={styles.PricesContainer}>
                <div className={styles.OriginalPrice}>
                  {model.originalPrice} RSD
                </div>
                <div>{model.price} RSD</div>
              </div>
            )}
          </div>
          <div className={styles.RightContainer}>
            <div
              className={styles.Link}
              onClick={() => {
                const activeTabSelected =
                  currentPage?.getTabs()?.selectedTab === ACTIVE;
                navigate({
                  newView: PageRoutes.AdDetailsPartner.id,
                  queryParams: {
                    id: model.id,
                    ...(activeTabSelected ? { updateAllowed: false } : {}),
                  },
                });
              }}
            >
              {model.status == PUBLISHED && model.recommended == '1' && (
                <Tooltip
                  theme={'light'}
                  html={
                    <div style={{ padding: '0.2em' }}>
                      <span>{t('basic:recommended_offer')}</span>
                    </div>
                  }
                  position="top"
                >
                  <img
                    src={thumbsUp}
                    alt="thumbs-up"
                    className={styles.GoodQualityIcon}
                  />
                </Tooltip>
              )}
              <span className={styles.details}>
                {t('basic:in_more_details')}
              </span>
            </div>
            {!isDraft() ? (
              <div className={styles.RightText}>
                <span className={styles.StatusTxt}>{t('basic:status')}: </span>{' '}
                <span style={getColor(model.status || '')}>
                  {t(`partner_ad_status:${model.status}`)}
                </span>
              </div>
            ) : (
              <div className={styles.RightText}>
                <img
                  className={''}
                  src={EMPTY_CART}
                  onClick={(e: any) => {
                    deleteModal.open();
                    deleteModal.setAfterYesCallback(() => {
                      currentPage?.deleteSelectedAd(model.id);
                    });
                    deleteModal.setAfterNoCallback(() => {
                      deleteModal.close();
                    });
                  }}
                />
              </div>
            )}
            <div className={styles.RightText}>
              {isInactive() ? `${t('basic:active_to')} ` : ''}
              {isRejected() || isPending() ? `${t('basic:ad_sent')} ` : ''}
              {isInactive()
                ? `${dateSRLocale(new Date(model.dateEnd as string))}`
                : `${dateSRLocale(new Date(model.createdAt as any))}`}
            </div>
            {deleteModal.modal?.opened && (
              <ConfirmationModal
                model={currentPage.getDeleteDraftAdConfirmationModal(model.id)}
              >
                <>
                  <div>{t('basic:are_you_sure_delete_ad')}</div>
                </>
              </ConfirmationModal>
            )}

            {successfulDeleteModal.modal?.opened && (
              <ConfirmationModal
                model={successfulDeleteModal}
                onlyYesButton={true}
              >
                <div className={'text-align-center'}>
                  <div className={'mb-1'}>
                    <img className={'large-icon'} src={SUCCESS} />
                  </div>
                  <div>{t('basic:offer_deleted_successfully')}</div>
                </div>
              </ConfirmationModal>
            )}
          </div>
        </div>
      </div>
    );
  }
);
