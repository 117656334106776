import { getRoot, Instance, types } from 'mobx-state-tree';
import {
  ButtonModel,
  ComponentModel,
  RootType,
  TextInputModel,
  UsedCode,
} from '../../../internal';

export const UsedCodesModel = types
  .compose(
    ComponentModel,
    types.model({
      adId: types.maybe(types.string),
      codeInput: types.maybe(TextInputModel),
      btn: types.maybe(ButtonModel),
      elements: types.maybe(types.frozen<UsedCode[]>()),
    })
  )
  .views((self) => {
    return {
      get root(): RootType {
        return getRoot(self);
      },
      get total(): number {
        if (!self.elements) {
          return 0;
        }
        return self.elements
          .map((el: UsedCode) => Number(el.count))
          .reduce((partialSum: number, a: number) => partialSum + a, 0);
      },
    };
  })
  .actions((self) => {
    return {
      async loadCodes() {
        const elementResp = await self.root.api.usedCodes.getAllUsedCodes(
          self.adId
        );
        this.setElements(elementResp.data);
      },
      setAdId(id: string) {
        self.adId = id;
      },
      setElements(els: UsedCode[]) {
        self.elements = els;
      },
      clearInput() {
        self.codeInput.setValue('');
      },
      async submitCode() {
        const val = self?.codeInput?.value;
        if (!val) {
          return;
        }
        await self.root.api.usedCodes.save({
          ad_id: self.adId,
          submitted_by: null,
          count: Number(val),
        });
        await this.loadCodes();
      },
    };
  })
  .named('UsedCodesModel');

export type UsedCodesModelType = Instance<typeof UsedCodesModel>;
