import * as React from 'react';
import styles from './InfoField.module.scss';
import { InfoFieldModelType } from '../../../internal';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import iconInfo from '../../../assets/images/icon-info.svg';
import { Show } from '../show/Show';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';

export const InfoField = observer(
  (props: { model: InfoFieldModelType; id?: string }): JSX.Element => {
    const { model, id } = props;
    const { t } = useTranslation();
    return (
      <div id={id} className={styles.InfoFieldContainer}>
        {model.label ? (
          <label htmlFor={model.id} className={styles.InfoFieldLabel}>
            {t(model.label)}
          </label>
        ) : (
          <></>
        )}
        <div id={model.id} className={styles.InfoFieldContent}>
          <span>{model.content}</span>
          <Show condition={model.showIcon}>
            <Tooltip
              theme={'light'}
              html={
                <div style={{ padding: '1em' }}>
                  <span>Definisano ugovorom</span>
                </div>
              }
              position="bottom"
            >
              <img
                className={styles.IconInfo}
                src={iconInfo}
                alt={'wind pay supported'}
              />
            </Tooltip>
          </Show>
        </div>
      </div>
    );
  }
);
