import React from 'react';
import { FC } from 'react';

import styles from './Image.module.scss';

export interface ISvgIcon {
  className?: string | undefined;
  icon?: any;
  onClick?: (event: any) => void;
  id?: string;
}

const SvgIcon: FC<ISvgIcon> = ({ className, icon, id, onClick }: ISvgIcon) => {
  return (
    <span
      data-id={id || ''}
      className={`${styles.SvgIcon} ${className}`}
      onClick={onClick}
    >
      {icon}
    </span>
  );
};

export default SvgIcon;
