import React from 'react';

import styles from './Toast.module.scss';
import { ErrorStatus, ToastModelType, useMst } from '../../../internal';
import { observer } from 'mobx-react';
import Alert from '../alert';

export enum ToastPositionType {
  BottomLeft = 'BottomLeft',
  BottomRight = 'BottomRight',
  TopLeft = 'TopLeft',
  TopRight = 'TopRight',
}

const Toast = observer(
  ({
    position,
    list,
  }: {
    duration: number;
    position: ToastPositionType;
    list: ToastModelType[];
  }) => {
    const { axios, removeToast } = useMst();
    const error: ErrorStatus = axios.error;
    const deleteToast = (id: string) => {
      removeToast(id);
    };
    return (
      <div className={`${styles.Container} ${position}`}>
        {list.map((toast, index) => {
          return (
            <div key={index} className={`${styles.Item} ${position} `}>
              <Alert
                key={index}
                message={error ? error : toast.message}
                handleClick={() => deleteToast(toast.id)}
                highContrast={true}
                open={true}
                content={toast.content}
                severity={toast.severity}
                withIcon={true}
              />
            </div>
          );
        })}
      </div>
    );
  }
);

export default Toast;
