import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { RootType, useMst } from '../../internal';
import { useTranslation } from 'react-i18next';
import styles from './ForgotPassword.module.scss';
import { InputField } from '../../components/UI/input/InputField';
import { Button } from '../../components/UI/buttons/regular/Button';

const ForgotPassword = observer(() => {
  const { t } = useTranslation();
  const root: RootType = useMst();
  const {
    currentPage: { getUserNameModel, getSubmitModel },
  } = root;

  useEffect(() => {
    const handleSubmit = (e: { key: string }) => {
      if (e.key === 'Enter') {
        getSubmitModel().click();
      }
    };
    window.addEventListener('keypress', handleSubmit);
    return () => {
      window.removeEventListener('keypress', handleSubmit);
    };
  }, []);

  return (
    <div className={styles.ForgotPasswordContainer}>
      <div className={styles.ForgotPassword}>
        <h1 className={styles.Title}>
          {t('basic:enter_forgot_password_email')}
        </h1>
        <form
          onSubmit={(event) => {
            event.preventDefault();
          }}
          className={styles.Form}
          noValidate
        >
          <div className={styles.OuterInputFieldContainer}>
            <InputField model={getUserNameModel()} />
          </div>
          <div className={styles.ForgotPasswordBtnContainer}>
            <Button model={getSubmitModel()}></Button>
          </div>
        </form>
      </div>
    </div>
  );
});

export default ForgotPassword;
