import React, { FC, useEffect, useRef, useState } from 'react';
import {
  CheckmarkIcon,
  CloseLightIcon,
  DangerIcon,
  InfoIcon,
  WarningIcon,
} from '../../../images/Icons';

import styles from './Alert.module.scss';

export enum SeverityType {
  Success = 'Success',
  Info = 'Info',
  Warning = 'Warning',
  Danger = 'Danger',
}

interface InterfaceAlert {
  className?: string;
  highContrast?: boolean;
  disableClose?: boolean;
  duration?: number;
  handleClick?: () => void;
  message: string | JSX.Element;
  open?: boolean;
  severity: SeverityType;
  withIcon?: boolean;
  content?: any | JSX.Element;
}

const Alert: FC<InterfaceAlert> = ({
  className,
  highContrast,
  disableClose,
  duration,
  handleClick,
  message,
  open,
  severity,
  withIcon,
  content,
}: InterfaceAlert) => {
  const refAlert = useRef<HTMLDivElement>(null);
  const [heightAlert, setHeightAlert] = useState<string>('auto');

  useEffect(() => {
    if (open) {
      setHeightAlert(
        `${Number(refAlert.current && refAlert.current.clientHeight)}px`
      );

      setTimeout(() => {
        setHeightAlert('auto');
      }, 250);

      duration &&
        setTimeout((event) => {
          closeAlert(event);
        }, duration);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const closeAlert = (event: any) => {
    if (event) {
      const alertItem = event.target.closest('DIV').parentElement;
      alertItem.style.height = `${alertItem.offsetHeight}px`;
    } else {
      setHeightAlert(
        `${Number(refAlert.current && refAlert.current.clientHeight)}px`
      );
    }

    setTimeout(() => setHeightAlert('0'));
    setTimeout(() => {
      setHeightAlert('auto');
      if (handleClick) {
        handleClick();
      }
    }, 250);
  };

  const infoIcon = () => {
    switch (severity) {
      case SeverityType.Success:
        return <CheckmarkIcon className={styles.InfoIcon} />;
      case SeverityType.Info:
        return <InfoIcon className={styles.InfoIcon} />;
      case SeverityType.Warning:
        return <WarningIcon className={styles.InfoIcon} />;
      case SeverityType.Danger:
        return <DangerIcon className={styles.InfoIcon} />;
    }
  };

  return open && (message || content) ? (
    <div
      className={`${styles.Alert} ${severity} ${
        highContrast ? 'Dark' : 'Light'
      } ${className ? className : ''}`}
      ref={refAlert}
      style={{ height: heightAlert }}
    >
      {
        <div className={styles.Wrapper}>
          {!disableClose && (
            <CloseLightIcon
              className={styles.CloseIcon}
              onClick={(event: MouseEvent) => closeAlert(event)}
            />
          )}
          {withIcon && infoIcon()}
          <p>{message}</p>
          {content}
        </div>
      }
    </div>
  ) : null;
};

export default Alert;
