import { observer } from 'mobx-react';

import * as React from 'react';
import styles from './AddAccountModal.module.scss';
import { useTranslation } from 'react-i18next';
import { Modal } from '../../../UI/modal/Modal';
import { AdministrationAccountModelType, useMst } from '../../../../internal';
import { InputField } from '../../../UI/input/InputField';
import { Button } from '../../../UI/buttons/regular/Button';

export const AddAccountModal = observer(
  (props: { model: AdministrationAccountModelType }): JSX.Element => {
    const { model } = props;
    const { t } = useTranslation();
    const { currentPage } = useMst();
    return (
      <Modal
        styles={{
          content: {
            top: '20%',
            width: '40%',
            maxHeight: 'fit-content',
            borderRadius: '20px',
            padding: '40px',
          },
        }}
        model={model.modal}
      >
        <div className={styles.Container}>
          <div className={styles.ModalHeader}>
            {model.account_id
              ? model.editAccountModalTitleText
              : model.addAccountModalTitleText}
          </div>
          <div className={styles.ColumnsContainer}>
            <div className={styles.Column}>
              <div className={styles.FieldsContainer}>
                <div className={styles.InputFieldContainer}>
                  <InputField model={model.first_name} />
                </div>
                <div className={styles.InputFieldContainer}>
                  <InputField model={model.last_name} />
                </div>
                <div className={styles.InputFieldContainer}>
                  <InputField model={model.email} />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.SaveBtnContainer}>
            <Button
              model={currentPage.getSubmitButton()}
              onClick={() => model.save()}
            />
          </div>
        </div>
      </Modal>
    );
  }
);
