import { getRoot, types } from 'mobx-state-tree';
import { HttpVerbs } from '../../constants/verbs';
import { ApiListResponse, SubCategory, RootType } from '../../internal';

export const SubCategoryApi = types
  .model({})
  .views((self) => {
    return {
      get root(): RootType {
        return getRoot(self);
      },
    };
  })
  .actions((self) => {
    return {
      async getAllSubcategories(category: string) {
        const response = await self.root.axios.getAll('/subcategories', {
          category,
        });
        self.root.data.setSubcategories(response.data);
        return response;
      },
      getSubCategories(category: string, offset = 0, limit = 1000) {
        const categoryQuery = category ? `&category=${category}` : '';
        const offsetQuery = offset ? `&offset=${offset}` : '';
        const limitSizeQuery = limit ? `&limit=${limit}` : '';
        return new Promise((resolve) => {
          self.root.axios
            .requestData({
              type: HttpVerbs.GET,
              path: `/subcategories?${categoryQuery}${offsetQuery}${limitSizeQuery}`,
            })
            .then((response: ApiListResponse<SubCategory>) => {
              self.root.data.setSubcategories(response.data);
              resolve(response);
            });
        });
      },
    };
  });
