import * as React from 'react';
import { Queries, useMst, ViewType } from '../../../internal';

import styles from './Link.module.scss';

interface ILink {
  view: ViewType;
  className?: string;
  text?: string;
  children?: React.ReactNode;
  params?: Queries;
  queryParams?: Queries;
  shouldReplace?: boolean;
  isDisabled?: boolean;
  href?: string;
  onClick?: () => void;
}

export const Link = (props: ILink): JSX.Element => {
  const {
    router: { navigate },
    currentPage,
  } = useMst();
  const { isDisabled, shouldReplace, onClick } = props;
  const go = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if (!isDisabled) {
      navigate({
        newView: props.view,
        params: props.params,
        queryParams: props.queryParams,
        shouldReplace,
      });
      onClick?.();
    }
  };
  return (
    <a
      id={`${currentPage?.id}_go_to_${props.view.id}`}
      className={`${styles.Link} ${props.className}`}
      onClick={go}
      style={styles}
      href={props.href ?? '/'}
    >
      {/* CSS ways yielded no result, unfortunately, text-decoration on a:link or a doesn't seem to work*/}
      {props.text ? <u>{props.text}</u> : <></>} {props.children}
    </a>
  );
};
