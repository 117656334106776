import { observer } from 'mobx-react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { PaginationModelType } from '../../../internal';
import styles from './Pagination.module.scss';

export const Pagination = observer(
  (props: { model: PaginationModelType }): JSX.Element => {
    const { model } = props;
    const { t } = useTranslation();
    return (
      <div className={styles.Container}>
        <div className={styles.Prev10} onClick={() => model.previous10()}></div>
        <div className={styles.Prev} onClick={() => model.previous(1)}></div>
        <div className={styles.Main}>
          {model.currentPage} {t('basic:of')} {model.totalPages}
        </div>
        <div className={styles.Next} onClick={() => model.next(1)}></div>
        <div className={styles.Next10} onClick={() => model.next10()}></div>
      </div>
    );
  }
);
