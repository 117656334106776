import * as React from 'react';
import {
  DropdownModelType,
  DropdownOptionModelType,
} from '../../../../internal';
import '../BaseDropdown.scss';
import styles from './DropdownWithSearch.module.scss';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import SelectSearch, { fuzzySearch } from 'react-select-search';
import iconShop from '../../../../assets/icons/icon-shop.svg';
import { Show } from '../../show/Show';

export const DropdownWithSearch = observer(
  (props: { model: DropdownModelType; id?: string }): JSX.Element => {
    const { model, id } = props;
    const { t } = useTranslation();
    return (
      <div id={id} className={styles.SelectContainer}>
        {model.label ? (
          <label
            htmlFor={model.id}
            className={
              model.showIcon ? styles.SelectLabelCentered : styles.SelectLabel
            }
          >
            {t(model.label)}
            <span className={styles.Required}>
              {model.isRequired ? '*' : ''}
            </span>
          </label>
        ) : (
          <></>
        )}
        <div id={model.id} className={styles.SelectContent}>
          <Show condition={model.showIcon}>
            <div className={styles.DropdownIconContainer}>
              <img className={styles.DropdownIcon} src={iconShop} />
            </div>
          </Show>
          <SelectSearch
            options={model.optionsArray.map(
              (option: DropdownOptionModelType) => {
                return {
                  name: option.rawValue,
                  value: option.id,
                  disabled: option.disabled,
                };
              }
            )}
            value={model.value}
            onChange={(val) => model.onChange({ target: { value: val } })}
            placeholder={t(model.placeholder)}
            search={true}
            disabled={!model.isEnabledComputed}
            filterOptions={fuzzySearch}
          />
        </div>
        <div className={styles.SelectArrowsContainer}>
          <div className={styles.SelectArrowsInnerContainer}>
            <div className={styles.UpArrow} />
            <div className={styles.DownArrow} />
          </div>
        </div>
      </div>
    );
  }
);
