import { getRoot, Instance, types } from 'mobx-state-tree';
import { AdminPublic, RootType } from '../internal';
import { PARTNER_PROFILES, MODERATOR_PROFILE } from '../constants/header';

export const HeaderModel = types
  .model('HeaderModel', {
    id: types.maybe(types.string),
    activeTab: MODERATOR_PROFILE,
  })

  .views((self) => {
    return {
      get root(): RootType {
        return getRoot(self);
      },
      get activeMenuItem() {
        return this.root.router.currentView?.id;
      },
      get isModeratorProfileTabActive() {
        return self.activeTab === MODERATOR_PROFILE;
      },
      get isPartnerProfilesTabActive() {
        return self.activeTab === PARTNER_PROFILES;
      },
      get partnerName(): string {
        return this?.root?.user?.partner?.name;
      },
      get partnerId(): string {
        return this?.root?.user?.partner?.id;
      },
      get currentUser(): string {
        return (this?.root?.user?.admin as AdminPublic)?.email;
      },
      // prodavac
      get isSalesman(): boolean {
        return this?.root?.user?.isSalesman;
      },
      // registrovani administrator oglasivaca
      get isPartner(): boolean {
        return this?.root?.user?.isPartner;
      },
      // yettel moderator oglasa
      get isModerator(): boolean {
        return this?.root?.user?.isModerator;
      },
      // yettel administrator oglasa
      get isAdministrator(): boolean {
        return this?.root?.user?.isAdministrator;
      },
    };
  })
  .actions((self) => {
    return {
      afterCreate() {
        self.activeTab = localStorage.getItem('activeTab') ?? self.activeTab;
      },
      setActiveTab(value: string) {
        self.activeTab = value;
        localStorage.setItem('activeTab', value);
      },
      reset() {
        self.activeTab = MODERATOR_PROFILE;
        localStorage.removeItem('activeTab');
      },
    };
  })
  .named('HeaderModel');

export type HeaderModelType = Instance<typeof HeaderModel>;
