export enum WithCodeTypes {
  NO_CODE = 'nocode',
  PERSONALIZED = 'personalized',
  COMMON = 'common',
}

export enum StoreLocationTypes {
  ALL = 'all',
  ONLINE = 'online',
  OFFLINE = 'offline',
  SELECTED = 'selected',
  ONLINE_SELECTED = 'online-sel',
}

export enum GenderTypes {
  MALE = 'M',
  FEMALE = 'Ž',
}

export enum SpenderTypes {
  BIG_SPENDERS = 'ARPU_RANK1',
  MID_SPENDERS = 'ARPU_RANK2',
  SMALL_SPENDERS = 'ARPU_RANK3',
}

export enum TerritoryTypes {
  URBAN = 'U',
  RURAL = 'R',
}

export enum CodeTypes {
  NUMERIC = 'numeric',
  BARCODE = 'bar',
  QRCODE = 'qr',
}

export enum AdTypes {
  STANDARD = 'standard',
  SPECIAL = 'special',
}

export enum PurchasedExtensionTypes {
  BASIC = 'Basic',
  STANDARD = 'Standard',
  PREMIUM = 'Pro',
}

export const MAX_AGE = 99;
export const MIN_AGE = 18;
export const ALL_USERS = 'Svi';
