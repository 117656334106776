import { observer } from 'mobx-react-lite';
import { PromoPeriod } from './PromoPeriod';
import styles from './TariffPackages.module.scss';

import { MonthlyPaymentAddition } from './MonthlyPaymentAddition';
import { useMst } from '../../../../internal';
import { ContractExpired } from './ContractExpired';
import { ContractComponent } from './ContractComponent';
import { ConfirmRequestSentModal } from '../../tariff-package-modals/ConfirmRequestSentModal';
import { RequestSentModal } from '../../tariff-package-modals/RequestSentModal';
import { isAfter } from 'date-fns';

export const TariffPackages = observer(() => {
  const {
    currentPage,
    data: {
      isPromoPeriodOver,
      isPromoPackage,
      contractDetails,
      hasPackage,
      isSubscription,
      isContractExpired,
    },
  } = useMst();
  const isPayAsYouGo = contractDetails?.calculator === 'pay-as-you-go';

  return contractDetails ? (
    <>
      {!isPromoPeriodOver && <PromoPeriod isPromoPackage={isPromoPackage} />}
      {isContractExpired && <ContractExpired />}
      {!isPromoPackage && (
        <div
          className={
            !isPayAsYouGo
              ? styles.TariffPackagesWithAddition
              : styles.TariffPackages
          }
        >
          <ContractComponent calculator={contractDetails?.calculator} />
        </div>
      )}
      {!isPromoPackage && !isPayAsYouGo && isPromoPeriodOver && hasPackage && (
        <MonthlyPaymentAddition />
      )}
      <ConfirmRequestSentModal />
      <RequestSentModal currentPage={currentPage} />
    </>
  ) : (
    <h4
      style={{
        margin: 100,
      }}
    >
      Vaša kompanija nema kreiran ugovor. Kontaktirajte Yettel administratora.
    </h4>
  );
});
