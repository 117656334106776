import { getRoot, types } from 'mobx-state-tree';
import { ID_KEY } from '../../constants';
import i18n from '../../i18n';
import {
  TextInputModel,
  PageModel,
  ButtonModel,
  RootType,
  PageRoutes,
} from '../../internal';

export const UpdatePartnerAdminEmailModel = types
  .compose(PageModel, types.model({}))
  .views((self) => {
    return {
      get root(): RootType {
        return getRoot(self);
      },
      get email() {
        return self?.components?.get('email')?.value;
      },
      get emailNotEntered(): boolean {
        return !this.email;
      },
      get adminId(): string {
        return this?.root?.router?.queryParams['id'];
      },
      get verificationCode(): string {
        return this?.root?.router?.currentQueryParams?.code;
      },
      get emailParam(): string {
        return this?.root?.router?.currentQueryParams?.email;
      },
    };
  })
  .actions((self) => {
    return {
      async onPageExit() {
        // prevent caching
        self.clearData();
      },
      getConfirmBtnModel() {
        return (
          self.components.get('confirm') ||
          self.addComponent(
            ButtonModel.create({
              id: 'confirm',
              label: 'basic:confirm',
              onClickCallback: () => {
                this.updateAdminEmail();
              },
            })
          )
        );
      },
      updateAdminEmail() {
        if (self.emailNotEntered) {
          self.root.showFailure(i18n.t('alert:credentials_not_entered'));
          return;
        }
        self.root.api.partner
          .updatePartnerAdminEmail({
            new_email: this.getEmailModel().value,
            verification_code: self.verificationCode,
            id: self.adminId,
          })
          .then((response: any) => {
            self.root.router.navigate({
              newView: PageRoutes.Login.id,
            });
          });
      },
      getEmailModel() {
        return (
          self.components.get('email') ||
          self.addComponent(
            TextInputModel.create({
              id: 'email',
              label: 'basic:email',
              isRequired: true,
              value: self.emailParam,
              disabled: true,
            })
          )
        );
      },
    };
  })
  .named('UpdatePartnerAdminEmailModel');
