import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { useMst } from '../../internal';
import maintenance from '../../assets/images/maintenance.png';
import styles from './Maintenance.module.scss';
import Loader from '../../components/UI/loader/Loader';
const GRID_LG = 1025;

export const Maintenance = observer((): JSX.Element | null => {
  const { t } = useTranslation(['basic']);
  const {
    router: { isLoading },
  } = useMst();
  const isLaptopOrWider = window?.innerWidth >= GRID_LG;
  return isLoading ? (
    <Loader />
  ) : (
    <div className={styles.Maintenance}>
      <h1 className={styles.Title}>{t('basic:maintenance_main_message')}</h1>
      <h4 className={styles.Subtitle}>{t('basic:maintenance_message')}</h4>
      <img
        className={isLaptopOrWider ? styles.ImgDesktop : styles.Img}
        src={maintenance}
        alt={'maintenance'}
      />
    </div>
  );
});

export default Maintenance;
