import { observer } from 'mobx-react';
import styles from './AdType.module.scss';
import { AdTypeModelType } from '../../../internal';
import { TopAd } from './topAd/TopAd';
import React, { ReactNode } from 'react';
import { StandardAd } from './standardAd/StandardAd';

interface IOfferModel {
  model: AdTypeModelType;
  standardOffer?: boolean; // 'clanarina'
  ImageWrapper: ({ children }: { children: ReactNode }) => JSX.Element;
  id?: string;
  hideDistanceIcon?: boolean;
}

export const AdType = observer((props: IOfferModel) => {
  const { model, id, hideDistanceIcon } = props;

  return (
    <div id={id} className={styles.AdTypeContainer}>
      <TopAd model={model} uiOptions={{ hideDistanceIcon: hideDistanceIcon }} />
      <StandardAd
        model={model}
        uiOptions={{ hideDistanceIcon: hideDistanceIcon }}
      />
    </div>
  );
});
