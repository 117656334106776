import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { StoreLocationModalModelType } from '../../../../../../internal';
import { formatCoordinates } from '../../../../../../utils/methods';
import styles from './MapPinMarker.module.scss';

export const MapPinMarker = observer(
  (props: { model: StoreLocationModalModelType }): JSX.Element => {
    const { model } = props;
    const { t } = useTranslation();
    return (
      <div className={styles.Container}>
        <div className={styles.LabelContainer}>
          <div className={styles.Label}>{t('basic:pin_show_on_map')}</div>
        </div>
        {model.coordinatesSet && (
          <div className={styles.CoordinatesSetContainer}>
            <div className={styles.LatLng}>
              {formatCoordinates(model.latitude)},{' '}
              {formatCoordinates(model.longitude)}
            </div>
            <div
              className={styles.Link}
              onClick={() => model.setMarkerDraggable(true)}
            >
              {t('basic:edit')}
            </div>
          </div>
        )}
      </div>
    );
  }
);
