import * as React from 'react';
import styles from './Smartphone.module.scss';

export const Smartphone = (props: { children?: any }): JSX.Element => {
  const { children } = props;
  return (
    <div className={styles.Smartphone}>
      <div className={styles.Content}>{children}</div>
    </div>
  );
};
