import React from 'react';
import { CompositeDecorator, EditorState, Modifier } from 'draft-js';

const Link = (props: any) => {
  const { entityKey, contentState, children } = props;
  const { url, rel, target, className } = contentState
    ?.getEntity(entityKey)
    ?.getData();

  return (
    <a href={url} target={target} rel={rel} className={className}>
      {children}
    </a>
  );
};

const findLinkEntities = (
  contentBlock: any,
  callback: any,
  contentState: any
) => {
  contentBlock.findEntityRanges((character: any) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === 'LINK'
    );
  }, callback);
};

export const createLinkDecorator = (): CompositeDecorator =>
  new CompositeDecorator([
    {
      strategy: findLinkEntities,
      component: Link,
    },
  ]);

export const hasEditorSelection = (editorState: EditorState): boolean => {
  const selection = editorState.getSelection();
  const startOffset = selection.getStartOffset();
  const endOffset = selection.getEndOffset();
  return startOffset !== endOffset;
};

export const onAddLink = ({
  editorState,
  setEditorState,
  formData,
  resetForm,
}: {
  editorState: EditorState;
  setEditorState: (state: EditorState) => void;
  formData: {
    url: string;
    text: string;
  };
  resetForm: () => void;
}): void => {
  if (hasEditorSelection(editorState)) {
    return;
  }

  if (!formData?.url || !formData.text) {
    return;
  }

  const decorator = createLinkDecorator();
  const currentContent = editorState.getCurrentContent();
  currentContent.createEntity('LINK', 'MUTABLE', {
    url: formData.url,
    rel: 'noreferrer',
    target: '_blank',
    className: 'link',
  });
  const entityKey = currentContent.getLastCreatedEntityKey();
  const selection = editorState.getSelection();
  const textWithEntity = Modifier.insertText(
    currentContent,
    selection,
    formData.text,
    undefined,
    entityKey
  );
  const newState = EditorState.createWithContent(textWithEntity, decorator);
  setEditorState(newState);
  resetForm();
};
