import React from 'react';
import styles from './TariffPackages.module.scss';
import importantIcon from '../../../../assets/icons/important_icon.png';

export const ContractExpired = (): JSX.Element => {
  return (
    <div className={styles.ContractExpired}>
      <img src={importantIcon} alt="important" />
      <p>
        Vaša ugovorna obaveza je istekla. Ukoliko želite da obnovite ugovornu
        obavezu i ostvarite dodatni popust, kontaktirajte Vašeg partneship
        experta.
      </p>
    </div>
  );
};
