import { Instance, types } from 'mobx-state-tree';
import {
  CheckboxGroupModel,
  ComponentModel,
  DropdownModel,
  ModalModel,
  ContractExtended,
  Partner,
  TextAreaInputModel,
  TextInputModel,
  PartnerAdminNew,
} from '../../../../internal';

export const ChangePacketModalModel = types
  .compose(
    ComponentModel,
    types.model({
      modal: types.late(() => ModalModel),
      packagesDropdown: types.late(() => DropdownModel),
      changePacketModalDiscountsCheckboxGroupPayAsYouGo: types.late(
        () => CheckboxGroupModel
      ),
      changePacketModalDiscountsCheckboxGroupSubscription: types.late(
        () => CheckboxGroupModel
      ),
      changePacketModalBtlInput: TextInputModel,
      partnerAdmin: types.late(() => types.frozen<PartnerAdminNew>()),
      showRejectionReasonTextArea: false,
      rejectionReasonTextArea: TextAreaInputModel,
    })
  )
  .views((self) => {
    return {
      get catalogId() {
        if (self.packagesDropdown.selectedOptionId === 'pay-as-you-go') {
          return self.root.data.catalogs.find(
            (c: any) => c.calculator === 'pay-as-you-go'
          ).id;
        }

        if (
          self.changePacketModalDiscountsCheckboxGroupSubscription.selectedCheckboxesIds.includes(
            'uo'
          )
        ) {
          return self.root.data.catalogs.find(
            (c: any) => c.calculator === 'subscription-with-contract-obligation'
          ).id;
        } else {
          return self.root.data.catalogs.find(
            (c: any) => c.calculator === 'subscription'
          ).id;
        }
      },
    };
  })
  .actions((self) => {
    return {
      open() {
        if (
          self.partnerAdmin.contract_request?.calculator === 'pay-as-you-go'
        ) {
          self.packagesDropdown.selectOption(0);
          if (
            self.partnerAdmin.contract_request.discount_telco !== null &&
            self.partnerAdmin.contract_request.discount_telco !== 0
          ) {
            self.changePacketModalDiscountsCheckboxGroupPayAsYouGo.check({
              id: 'telco',
            });
          }
          if (
            self.partnerAdmin.contract_request.discount_soho !== null &&
            self.partnerAdmin.contract_request.discount_soho !== 0
          ) {
            self.changePacketModalDiscountsCheckboxGroupPayAsYouGo.check({
              id: 'soho',
            });
          }
        } else if (
          self.partnerAdmin.contract_request?.calculator === 'subscription'
        ) {
          self.packagesDropdown.selectOption(1);

          if (
            self.partnerAdmin.contract_request.discount_telco !== null &&
            self.partnerAdmin.contract_request.discount_telco !== 0
          ) {
            self.changePacketModalDiscountsCheckboxGroupSubscription.check({
              id: 'telco',
            });
          }
          if (
            self.partnerAdmin.contract_request.discount_btl !== null &&
            self.partnerAdmin.contract_request.discount_btl !== 0
          ) {
            self.changePacketModalDiscountsCheckboxGroupSubscription.check({
              id: 'btl',
            });
            self.changePacketModalBtlInput.setStringValue(
              self.partnerAdmin.contract_request.discount_btl
            );
          }
        } else if (
          self.partnerAdmin.contract_request?.calculator ===
          'subscription-with-contract-obligation'
        ) {
          self.packagesDropdown.selectOption(1);
          if (
            self.partnerAdmin.contract_request.discount_telco !== null &&
            self.partnerAdmin.contract_request.discount_telco !== 0
          ) {
            self.changePacketModalDiscountsCheckboxGroupSubscription.check({
              id: 'telco',
            });
          }
          if (
            self.partnerAdmin.contract_request.discount_so !== null &&
            self.partnerAdmin.contract_request.discount_so !== 0
          ) {
            self.changePacketModalDiscountsCheckboxGroupSubscription.check({
              id: 'uo',
            });
          }
          if (
            self.partnerAdmin.contract_request.discount_btl !== null &&
            self.partnerAdmin.contract_request.discount_btl !== 0
          ) {
            self.changePacketModalDiscountsCheckboxGroupSubscription.check({
              id: 'btl',
            });
            self.changePacketModalBtlInput.setStringValue(
              self.partnerAdmin.contract_request.discount_btl
            );
          }
        }
        self.modal.setOpened(true);
      },
      close() {
        self.modal.setOpened(false);
      },
      setPartnerAdmin(partnerAdmin: PartnerAdminNew) {
        self.partnerAdmin = partnerAdmin;
      },
      setShowRejectionReasonTextArea(value: boolean) {
        self.showRejectionReasonTextArea = value;
      },
      async submit() {
        let requestBody;
        if (self.packagesDropdown.selectedOptionId === 'pay-as-you-go') {
          requestBody = {
            discount_telco:
              self.changePacketModalDiscountsCheckboxGroupPayAsYouGo.selectedCheckboxesIds.includes(
                'telco'
              )
                ? 20
                : null,
            discount_soho:
              self.changePacketModalDiscountsCheckboxGroupPayAsYouGo.selectedCheckboxesIds.includes(
                'soho'
              )
                ? 30
                : null,
          };
        } else if (self.packagesDropdown.selectedOptionId === 'subscription') {
          requestBody = {
            discount_telco:
              self.changePacketModalDiscountsCheckboxGroupSubscription.selectedCheckboxesIds.includes(
                'telco'
              )
                ? 20
                : null,
            discount_so:
              self.changePacketModalDiscountsCheckboxGroupSubscription.selectedCheckboxesIds.includes(
                'uo'
              )
                ? 20
                : null,
            discount_btl:
              self.changePacketModalDiscountsCheckboxGroupSubscription.selectedCheckboxesIds.includes(
                'btl'
              )
                ? Number(self.changePacketModalBtlInput.value)
                : null,
          };
        }

        requestBody = {
          ...requestBody,
          catalog_id: self.catalogId,
          partner_id: self.partnerAdmin.id,
          id: self.partnerAdmin.contract_request.id,
        };

        await self.root.api.contractRequest.submit(requestBody);

        const partnerAdminsResponse =
          await self.root.api.partner.getAdminAccounts({
            id: self.partnerAdmin.id,
          });
        self.root.currentPage
          .getCreatedAccountList()
          .setAccounts(partnerAdminsResponse.data);
        this.close();
      },
      async reject() {
        this.setShowRejectionReasonTextArea(false);
        await self.root.api.contractRequest.reject(
          self.partnerAdmin.contract_request.id,
          self.rejectionReasonTextArea.value
        );

        const partnerAdminsResponse =
          await self.root.api.partner.getAdminAccounts({
            id: self.partnerAdmin.id,
          });
        self.root.currentPage
          .getCreatedAccountList()
          .setAccounts(partnerAdminsResponse.data);

        self.root.currentPage
          .getCreatedAccountList()
          .filters.company.resetValue();

        this.close();
      },
    };
  })
  .named('ChangePacketModalModel');

export type ChangePacketModalModelType = Instance<
  typeof ChangePacketModalModel
>;
