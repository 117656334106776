import { Instance, types } from 'mobx-state-tree';
import {
  CheckboxGroupModel,
  ComponentModel,
  FunctionModel,
} from '../../../internal';

export const CheckboxModel = types
  .compose(
    ComponentModel,
    types.model({
      parent: types.maybe(
        types.reference(types.late(() => CheckboxGroupModel))
      ),
      onChangeCallback: types.maybe(FunctionModel),
      isVisible: types.maybe(types.boolean),
    })
  )
  .actions((self) => {
    return {
      onChange(e: any) {
        const isDisabled = self?.parent?.isDisabled || self?.disabled;
        if (isDisabled) return;
        self?.parent?.toggle(self);
        if (self.onChangeCallback) {
          self.onChangeCallback(self);
        }
      },
      setParent(parent: string) {
        self.parent = parent;
      },
    };
  })
  .views((self) => {
    return {
      get isChecked() {
        return self?.parent?.selectedCheckboxes.has(self.id);
      },
      get isDisabled() {
        return self?.parent?.isDisabled;
      },
      get isSelfDisabled() {
        return self?.disabled;
      },
    };
  })
  .named('CheckboxModel');

export type CheckboxModelType = Instance<typeof CheckboxModel>;
