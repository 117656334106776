import { Instance, types } from 'mobx-state-tree';
import { ComponentModel, ModalModel, FunctionModel } from '../../../internal';
import { dateSRLocale } from '../../../utils/date';

export const DurationModel = types
  .compose(
    ComponentModel,
    types.model({
      fromDate: types.maybe(types.Date),
      toDate: types.maybe(types.Date),
      minDate: types.maybe(types.Date),
      maxDate: types.maybe(types.Date),
      maxDuration: types.maybe(types.number), // in days
      maxDurationText: types.maybe(types.string),
      modal: types.late(() => ModalModel),
      disabledDates: types.maybe(types.array(types.Date)),
      onChangeCallback: types.maybe(types.late(() => FunctionModel)),
    })
  )
  .actions((self) => {
    return {
      setMinDate(e: any) {
        self.minDate = e;
      },
      setMaxDate(e: any) {
        self.maxDate = e;
      },
      setMaxDurationText(text: string) {
        self.maxDurationText = text;
      },
      setPackageDuration(minDate: Date, maxDate: Date, text: string) {
        self.minDate = minDate;
        self.maxDate = maxDate;
        self.maxDurationText = text;
        self.fromDate = new Date();
        self.toDate = new Date();
        self?.onChangeCallback?.();
      },
      setFromDate(e: any) {
        self.fromDate = e;
        self?.onChangeCallback?.();
      },
      setToDate(e: any) {
        self.toDate = e;
        self?.onChangeCallback?.();
      },
      clearFromDate() {
        self.fromDate = undefined;
        self?.onChangeCallback?.();
      },
      clearToDate() {
        self.toDate = undefined;
        self?.onChangeCallback?.();
      },
      clearData() {
        self.fromDate = new Date();
        self.toDate = new Date();
      },
      setDisabledDates(disabledDates: Date[]) {
        self.disabledDates = disabledDates;
        self?.onChangeCallback?.();
      },
      openModal() {
        self.modal.setOpened(true);
      },
      saveAndClose(datesObj: { fromDate: Date; toDate: Date }) {
        if (
          !!datesObj?.fromDate &&
          !!datesObj.toDate &&
          datesObj.fromDate < datesObj.toDate
        ) {
          self.fromDate = datesObj.fromDate;
          self.toDate = datesObj.toDate;
          self.modal.setOpened(false);
          self?.onChangeCallback?.();
        }
      },
    };
  })
  .views((self) => {
    return {
      get localizedFromDate() {
        if (!self.fromDate) {
          return '';
        }
        return dateSRLocale(self.fromDate);
      },
      get localizedToDate() {
        if (!self.toDate) {
          return '';
        }
        return dateSRLocale(self.toDate);
      },
    };
  })
  .named('DurationModel');

export type DurationModelType = Instance<typeof DurationModel>;
