import { getRoot, Instance, types } from 'mobx-state-tree';
import { RootType } from '../../../internal';

export const SortModel = types
  .model({
    id: types.identifier,
    type: types.enumeration(['default', 'asc', 'desc']),
  })
  .views((self) => {
    return {
      get root(): RootType {
        return getRoot(self);
      },
    };
  })
  .actions((self) => {
    return {
      toggleType() {
        if (self.type === 'default') {
          self.type = 'asc';
        } else if (self.type === 'asc') {
          self.type = 'desc';
        } else {
          self.type = 'default';
        }
      },
    };
  });

export type SortModelType = Instance<typeof SortModel>;
