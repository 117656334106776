import { Instance, types } from 'mobx-state-tree';
import { ButtonModel } from '../buttons/regular-button/ButtonModel';

export const DatePickerV2Model = types
  .model({
    id: types.identifier,
    date: types.maybe(types.Date),
    dateRangeStart: types.maybe(types.Date),
    dateRangeEnd: types.maybe(types.Date),
    resetButton: types.maybe(ButtonModel),
  })
  .actions((self) => {
    return {
      setDate(date: Date) {
        self.date = date;
      },
      setDateRange(startDate: Date, endDate: Date) {
        self.dateRangeStart = startDate;
        self.dateRangeEnd = endDate;
      },
      resetDate() {
        self.date = undefined;
      },
      resetDateRange() {
        self.dateRangeStart = undefined;
        self.dateRangeEnd = undefined;
      },
    };
  });

export type DatePickerV2ModelType = Instance<typeof DatePickerV2Model>;
