import { getRoot, Instance, types } from 'mobx-state-tree';
import {
  AdministrationAccountModel,
  AdministrationAccountModelType,
  ButtonModel,
  ConfirmationModalModel,
  DeleteAccountModel,
  ModalModel,
  PageModel,
  RootType,
  TextInputModel,
} from '../../../internal';
import i18n from '../../../i18n';
import { YettelAdminTypeIds } from '../../../constants/yettelAdmins';
import { ADMINISTRATOR, MODERATOR, SELLER } from '../../../constants/roleTypes';

export const AdministrationPageModel = types
  .compose(
    PageModel,
    types.model({
      addNewRoleType: types.maybe(types.string),
    })
  )
  .views((self) => {
    return {
      get root(): RootType {
        return getRoot(self);
      },
      get addAccountModal() {
        return self.components.get('AddAccountModal');
      },
      get deleteAccountModal() {
        return self.components.get('DeleteAccountModal');
      },
    };
  })
  .actions((self) => {
    return {
      async beforePageEnter() {
        await this.getPageData();
      },
      async getPageData() {
        await Promise.all([
          self.root.api.admin.getAdmins(YettelAdminTypeIds.ADMINISTRATOR),
          self.root.api.admin.getAdmins(YettelAdminTypeIds.MODERATOR),
          self.root.api.admin.getAdmins(YettelAdminTypeIds.SELLER),
        ]);
      },
      getSubmitButton() {
        return (
          self.components.get('SubmitButton') ||
          self.addComponent(
            ButtonModel.create({
              id: 'SubmitButton',
              labelFunc: () =>
                this.getAddAccountModal().account_id
                  ? i18n.t('administration:update_text')
                  : i18n.t('administration:submit_text'),
            })
          )
        );
      },
      setAddNewRoleType(type: string) {
        self.addNewRoleType = type;
      },
      createAdministrationAccountModal() {
        return self.addComponent(
          AdministrationAccountModel.create({
            id: 'AddAccountModal',
            type: self.addNewRoleType,
            first_name: TextInputModel.create({
              id: 'firstName',
              type: 'text',
              isRequired: true,
              label: i18n.t('administration:first_name'),
            }),
            last_name: TextInputModel.create({
              id: 'lastName',
              type: 'text',
              isRequired: true,
              label: i18n.t('administration:last_name'),
            }),
            email: TextInputModel.create({
              id: 'email',
              type: 'text',
              isRequired: true,
              label: i18n.t('administration:email'),
            }),
            modal: ModalModel.create({
              id: 'modal',
              showCloseButton: true,
              opened: false,
            }),
          })
        );
      },
      createDeleteAccountModal(account_id?: string) {
        return self.addComponent(
          DeleteAccountModel.create({
            id: 'DeleteAccountModal',
            accountId: account_id,
            deleteAccountModal: ConfirmationModalModel.create({
              id: 'deleteModal',
              text: 'basic:are_you_sure',
              modal: ModalModel.create({
                id: 'modal',
                opened: false,
                showCloseButton: true,
              }),
            }),
          })
        );
      },
      openAddAccountModal(type: string, id?: string) {
        if (self.addAccountModal) {
          self.addAccountModal.setType(type);
          if (id) {
            const accountsArr =
              type === MODERATOR
                ? self.root.data.moderators
                : type === ADMINISTRATOR
                ? self.root.data.administrators
                : type === SELLER
                ? self.root.data.sellers
                : [];
            const account = accountsArr.find(
              (acc: AdministrationAccountModelType) => acc.id === id
            );
            self.addAccountModal.setAccountId(id);
            self.addAccountModal.setFirstName(account.first_name);
            self.addAccountModal.setLastName(account.last_name);
            self.addAccountModal.setEmail(account.email);
          } else {
            self.addAccountModal.resetInputs();
          }
          self.addAccountModal.modal.setOpened(true);
        } else {
          this.createAdministrationAccountModal();
        }
      },
      getAddAccountModal() {
        return (
          self.components.get('AddAccountModal') ||
          this.createAdministrationAccountModal()
        );
      },
      openDeleteAccountModal(account_id?: string) {
        if (self.deleteAccountModal) {
          self.deleteAccountModal.setAccountId(account_id);
          self.deleteAccountModal.deleteAccountModal.setAfterYesCallback(() => {
            self.root.api.admin.delete(account_id).then(() => {
              this.getPageData();
            });
          });
          self.deleteAccountModal.deleteAccountModal.modal.setOpened(true);
        } else {
          this.createDeleteAccountModal();
        }
      },
      getDeleteAccountModal() {
        return (
          self.components.get('DeleteAccountModal') ||
          this.createDeleteAccountModal()
        );
      },
    };
  })
  .named('AdministrationPageModel');

export type AdministrationPageModelType = Instance<
  typeof AdministrationPageModel
>;
