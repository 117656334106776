import { Instance, types } from 'mobx-state-tree';
import {
  ComponentModel,
  ModalModel,
  TextInputModel,
  UserPriceDataModel,
  UserPriceDataModelType,
} from '../../../internal';

export const AdditionalUserModel = types
  .compose(
    ComponentModel,
    types.model({
      modal: types.late(() => ModalModel),
      prices: types.map(UserPriceDataModel),
      numberOfAdditionalUsersField: TextInputModel,
      selectedContractAddon: types.maybe(types.string),
      // If this field is not true, the second section
      // of the second step won't turn into edit mode
      // allowing the user to confirm if they want to
      // accept the data which is on the screen
      shouldContinue: types.maybe(types.boolean),
    })
  )
  .actions((self) => {
    return {
      setShouldContinue(val: boolean) {
        self.shouldContinue = val;
      },
      openModal() {
        self.modal.setOpened(true);
      },
      clearPrices() {
        self.prices.clear();
      },
      setPrices(prices: any) {
        this.clearPrices();
        prices.forEach((price: UserPriceDataModelType) => {
          self.prices.set(price.id, price);
        });
      },
      getContractAddons(contractId: string, type: string) {
        self.root.api.contract.getContractAddons(contractId, type, 0, 1000);
      },
      setSelectedContractAddon(priceRange: any) {
        self.selectedContractAddon = priceRange.id;
      },
      setMaxNumber(val: number) {
        self.numberOfAdditionalUsersField.setMax(val);
      },
    };
  })
  .views((self) => {
    return {
      get pricesArray() {
        return Array.from(self.prices.values());
      },
      get numberOfAdditionalUsers() {
        const data = self?.numberOfAdditionalUsersField?.value;
        return data ? parseFloat(data) : 0;
      },
      get numberOfUsersExceedsMax(): boolean {
        return (
          this.numberOfAdditionalUsers > self?.numberOfAdditionalUsersField?.max
        );
      },
      get amount(): number {
        if (!self.numberOfAdditionalUsers || !this?.pricesArray?.length) {
          return 0;
        }
        // find the range number of users is in, then calculate the price
        let range: any = null;
        for (const priceRange of this.pricesArray) {
          if (
            (priceRange as any).from <= self.numberOfAdditionalUsers &&
            (priceRange as any).to >= self.numberOfAdditionalUsers
          ) {
            self.setSelectedContractAddon(priceRange);
            range = priceRange;
          }
        }
        if (!range) {
          range = this.pricesArray[this.pricesArray.length - 1];
        }
        return range.price * self.numberOfAdditionalUsers;
      },
    };
  })
  .actions((self) => {
    return {
      setDisabled(disabled: boolean) {
        self.numberOfAdditionalUsersField.setDisabled(disabled);
      },
    };
  })
  .named('AdditionalUserModel');

export type AdditionalUserModelType = Instance<typeof AdditionalUserModel>;
