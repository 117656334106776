// for to age dropdown for targeting
export const TO_MIN_AGE = 20;
export const TO_MAX_AGE = 111;
export const TO_EVERY = 5;

export const FROM_MIN_AGE = 20;
export const FROM_MAX_AGE = 106;
export const FROM_EVERY = 5;

export const FROM_AGE_OPTIONS = (() => {
  const retVal: any = {};
  retVal[0] = {
    id: '',
    value: '',
  };
  retVal[18] = {
    id: '18',
    value: '18',
  };
  retVal[19] = {
    id: '19',
    value: '19',
  };
  for (let i = FROM_MIN_AGE; i < FROM_MAX_AGE; i++) {
    if (i % FROM_EVERY === 0) {
      retVal[i] = {
        id: `${i}`,
        value: `${i}`,
      };
    }
  }
  return retVal;
})();

export const TO_AGE_OPTIONS = (() => {
  const retVal: any = {};
  retVal[0] = {
    id: '',
    value: '',
  };
  retVal[19] = {
    id: '19',
    value: '19',
  };
  for (let i = TO_MIN_AGE; i < TO_MAX_AGE; i++) {
    if (i % TO_EVERY === 0) {
      retVal[i] = {
        id: `${i}`,
        value: `${i}`,
      };
    }
  }
  return retVal;
})();
