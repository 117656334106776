import { observer } from 'mobx-react';
import styles from './MobileHeaderMenuModal.module.scss';
import { BottomHeaderContent } from './BottomHeaderContent';

type Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

export const MobileHeaderMenuModal = observer(
  ({ isOpen, setIsOpen }: Props) => {
    return (
      <div
        className={styles.MobileHeaderMenuModal}
        style={{ display: isOpen ? 'block' : 'none' }}
      >
        <div className={styles.MobileHeaderMenuContent}>
          <BottomHeaderContent />
        </div>
      </div>
    );
  }
);
