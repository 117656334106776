import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ClearCodesWarningModelType } from '../../../internal';
import { Button } from '../../UI/buttons/regular/Button';
import { Modal } from '../../UI/modal/Modal';
import styles from './ClearCodesWarning.module.scss';

// this dialog is shown when some conditions are met
// while trying to edit target group and locations
export const ClearCodesWarning = (props: {
  model: ClearCodesWarningModelType;
  id?: string;
}): JSX.Element => {
  const { model, id } = props;
  const { t } = useTranslation();
  return (
    <Modal id={id} model={model.modal}>
      <div className={styles.ContentContainer}>
        <div className={styles.TitleContainer}>
          <div className={styles.Title}>{t('warnings:clear_codes_title')}</div>
        </div>
        <div className={styles.MainContent}>
          {t('warnings:clear_codes_content')}
        </div>
        <div className={styles.BottomContent}>
          <Button model={model.continueButton} />
          <div className={styles.CancelLinkContainer}>
            <div onClick={() => model.cancel()} className={styles.CancelLink}>
              {t('basic:give_up')}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
