import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { BillSpecification } from '../../../../components/domain/bill-specification/BillSpecification';
import { OfferDetailsPreview } from '../../../../components/domain/offer-details-preview/OfferDetailsPreview';
import { PaymentSummary } from '../../../../components/domain/payment-summary/PaymentSummary';
import { Button } from '../../../../components/UI/buttons/regular/Button';
import { Section } from '../../../../components/UI/section/Section';
import { Show } from '../../../../components/UI/show/Show';
import { useMst } from '../../../../internal';
import styles from '../CreateOffer.module.scss';

export const ThirdStep = observer((): JSX.Element => {
  const {
    currentPage,
    data: { isPayAsYouGo, isPromoPeriodOver },
  } = useMst();
  const { t } = useTranslation();
  return (
    <>
      <div className={styles.FormContainer}>
        <Section model={currentPage.getUserPreviewSection()}>
          <div className={styles.FormContent}>
            <div className={styles.AdPreviewContainer}>
              <div className={styles.OfferPreviewContainer}>
                {currentPage.getOfferSteps().isThirdSelected && (
                  <OfferDetailsPreview preview={currentPage.offerPreview} />
                )}
              </div>
              <div className={styles.VerticalCenteredContent}>
                <Show condition={currentPage.standardPackageSelected}>
                  <div className={styles.CenteredBtnContainer}>
                    <Button model={currentPage.getSendForApproval()} />
                  </div>
                </Show>
                <div
                  onClick={() => currentPage.getOfferSteps().selectStep('1')}
                  className={styles.BackToCreateOffer}
                >
                  {t('offer:edit_offer')}
                </div>
              </div>
            </div>
          </div>
        </Section>
      </div>

      {isPayAsYouGo && isPromoPeriodOver ? (
        <div className={styles.FormContainer}>
          <Section model={currentPage.getPaymentSummarySection()}>
            <div className={styles.FormContent}>
              <PaymentSummary />
            </div>
          </Section>
        </div>
      ) : null}

      <Show condition={!currentPage.standardPackageSelected}>
        <div className={styles.FormContainer}>
          <Section model={currentPage.getBillSpecificationSection()}>
            <div className={styles.FormContent}>
              <BillSpecification
                preview={currentPage.billSpecificationPreview}
              />
            </div>
          </Section>
        </div>
      </Show>
    </>
  );
});
