import { observer } from 'mobx-react';
import * as React from 'react';
import styles from './ConfirmationModal.module.scss';
import { useTranslation } from 'react-i18next';
import { Modal } from '../modal/Modal';
import { Button } from '../buttons/regular/Button';
import { ConfirmationModalModelType } from '../../../internal';

const MODAL_STYLES = {
  content: {
    minWidth: '40%',
    maxHeight: 'fit-content',
    borderRadius: '20px',
    padding: '40px',
  },
};

export const ConfirmationModal = observer(
  (props: {
    model: ConfirmationModalModelType;
    onlyYesButton?: boolean;
    children?: JSX.Element;
  }): JSX.Element => {
    const { model, onlyYesButton = false, children } = props;
    const { t } = useTranslation();
    return (
      <Modal styles={MODAL_STYLES} model={model.modal}>
        <div className={styles.ModalHeader}>
          {model.text ? t(model.text) : <></>}
          {children ?? <></>}
        </div>
        <div className={styles.ButtonModalContainer}>
          {!onlyYesButton && (
            <button
              className={styles.BtnNo}
              onClick={() => {
                model.no();
              }}
            >
              {t(model.labelNo ?? 'basic:no')}
            </button>
          )}

          <Button
            onClick={() => {
              model.yes();
            }}
          >
            {t(model.labelYes ?? 'basic:yes')}
          </Button>
        </div>
      </Modal>
    );
  }
);

// No is where yes should be and vice versa. We want  to goad user not to do this
// used for situations where data could be lost accidentally...
export const ReverseConfirmationModal = observer(
  (props: {
    model: ConfirmationModalModelType;
    children?: JSX.Element;
  }): JSX.Element => {
    const { model, children } = props;
    const { t } = useTranslation();
    return (
      <Modal styles={MODAL_STYLES} model={model.modal}>
        <div className={styles.ModalHeader}>
          {model.text ? t(model.text) : <></>}
          {children ?? <></>}
        </div>
        <div className={styles.ButtonModalContainer}>
          <button
            className={styles.BtnNo}
            onClick={() => {
              model.yes();
            }}
          >
            {t(model.labelYes ?? 'basic:yes')}
          </button>
          <Button
            onClick={() => {
              model.no();
            }}
          >
            {t(model.labelNo ?? 'basic:no')}
          </Button>
        </div>
      </Modal>
    );
  }
);
