import { observer } from 'mobx-react';
import { AdTypeModelType } from '../../../../internal';
import styles from '../AdType.module.scss';
import distance from '../../../../assets/images/category/icon-walking.png';
import windPayIcon from '../../../../assets/images/icon-apple-pay.svg';
import importantIcon from '../../../../assets/icons/important-icon.png';
import checkMarkGreen from '../../../../assets/icons/checkmark-green-icon.png';
import checkMarkDark from '../../../../assets/icons/checkmark-dark-icon.png';
import { useMst } from '../../../../internal';
import React, { ReactNode } from 'react';
import { FileInput } from '../../file-input/FileInput';
import { Show } from '../../show/Show';
import ExpirationInfo from '../../expiration-info/ExpirationInfo';
import TopAdImage from '../../../../assets/images/offer-image-top.png';
import { Button } from '../../buttons/regular/Button';

interface IOfferModel {
  model: AdTypeModelType;
  uiOptions?: { hideDistanceIcon?: boolean };
}

export const TopAd = observer((props: IOfferModel) => {
  const { model, uiOptions } = props;
  const {
    currentPage: {
      adName,
      topOffersRemaining,
      adRegularPriceRSD,
      adLoweredPriceRSD,
      fileInputs,
      isImageFromResponse,
      offerPreview,
      shouldShowBothPrices,
      labelText,
      labelColorHex,
      getActivateButton,
      isPurchaseMade,
    },
    data: { isPayAsYouGo, currentCatalog, isPromoPeriodOver, isPromoPackage },
    router: { isCreateOfferView },
  } = useMst();
  const partnerName = offerPreview?.partner?.name;

  // AD SHOULD NEVER DISPLAY ONLY REGULAR PRICE
  // const shouldShowOnlyRegularPrice = () =>
  //   shouldShowOnlyRegularPriceFunc(
  //     adRegularPriceRSD,
  //     adLoweredPriceRSD,
  //     adDiscount
  //   );
  const renderImage = () => {
    if (isImageFromResponse) {
      return (
        <div
          className={styles.UploadedImage}
          style={{
            backgroundImage: `url(${offerPreview.imagesArray[0]})`,
            backgroundPosition: 'top center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          }}
        />
      );
    }
    if (fileInputs[0].filesArray.length > 0) {
      return (
        <FileInput
          model={fileInputs[0]}
          ImageWrapper={({ children }: { children: ReactNode }) => {
            const imgSrc = (children as any)?.props?.src;
            return (
              <div
                className={styles.UploadedImage}
                style={{
                  backgroundImage: `url(${imgSrc})`,
                  backgroundPosition: 'top center',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                }}
              />
            );
          }}
          LabelRenderer={() => <div />}
        />
      );
    }
    return (
      <div
        className={styles.UploadedImage}
        style={{
          backgroundImage: `url(${TopAdImage})`,
          backgroundPosition: 'top center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}
      />
    );
  };
  return (
    <>
      <div className={styles.TopAdContainer}>
        <div className={styles.TopAd}>
          <div className={styles.TopSection}>
            {renderImage()}
            <Show condition={labelText}>
              <div
                style={{
                  backgroundColor: labelColorHex,
                }}
                className={styles.LabelText}
              >
                {labelText}
              </div>
            </Show>
          </div>
          <div className={styles.Content}>
            <Show condition={shouldShowBothPrices}>
              <div className={styles.RibbonPrice}>
                <div className={styles.RegularPrice}>
                  {adRegularPriceRSD} RSD
                </div>
                <div>
                  <span>{adLoweredPriceRSD} RSD</span>
                </div>
              </div>
            </Show>
            {/*AD SHOULD NEVER DISPLAY ONLY REGULAR PRICE*/}
            {/*<Show condition={shouldShowOnlyRegularPrice()}>*/}
            {/*  <div className={styles.Price}>*/}
            {/*    <span>{adRegularPriceRSD}</span>*/}
            {/*    <span>RSD</span>*/}
            {/*  </div>*/}
            {/*</Show>*/}
            <p className={styles.Store}>{partnerName}</p>
            <h2 className={styles.Title}>{adName}</h2>
            <div className={styles.BottomSection}>
              <div className={styles.Info}>
                <ExpirationInfo model={offerPreview} />
              </div>
            </div>
            {!uiOptions?.hideDistanceIcon && (
              <>
                <span>|</span>
                <div className={styles.Distance}>
                  <img
                    className={styles.DistanceIcon}
                    src={distance}
                    alt={'distance'}
                  />
                  <span className={styles.DistanceText}>{model.distance}</span>
                </div>
              </>
            )}
            {model.isWindPaySupported && (
              <img
                className={styles.WindPayIcon}
                src={windPayIcon}
                alt={'wind pay supported'}
              />
            )}
          </div>
        </div>
        <Show condition={model.showDescriptionList}>
          <div className={styles.DescriptionSection}>
            <ul className={styles.Content}>
              <li>Puna širina oglasa</li>
              <li>Top pozicija</li>
              <li>Prikaz trajanja ponude</li>
              <li>Prikaz udaljenosti lokacije</li>
              <li>Opcija čuvanja za kasnije</li>
              <li>Istaknuta cena</li>
              <li>Idealan za ponude koje kratko traju</li>
            </ul>
          </div>
        </Show>
        <Show condition={isCreateOfferView && !isPayAsYouGo && !isPromoPackage}>
          {topOffersRemaining === 0 && isPromoPeriodOver ? (
            <div className={styles.UsedMaxFiveTopAds}>
              <img src={importantIcon} alt="important" />
              <div className={styles.TextContainer}>
                <p className={styles.Text}>
                  Iskoristili ste svih 5 top oglasa koji su uracunati u Vaš
                  paket.
                  <br></br>
                  Ukoliko želite možete da aktivirate dodatak sa dodatnih 5 top
                  oglasa po ceni od {currentCatalog?.top_addon_price} RSD.
                </p>
              </div>
              <Button model={getActivateButton()} />
            </div>
          ) : null}
          {isPromoPeriodOver && isPurchaseMade ? (
            <div className={styles.AdditionActivated}>
              <img
                className={styles.Icon}
                src={checkMarkGreen}
                alt="checkmark"
              />
              <p>Uspešno ste aktivirali tarifni dodatak.</p>
            </div>
          ) : null}

          {isPromoPeriodOver &&
          topOffersRemaining < 5 &&
          topOffersRemaining > 0 ? (
            <div className={styles.TopAdsCounterBox}>
              <img
                className={styles.Icon}
                src={checkMarkDark}
                alt="checkmark"
              />

              <p>
                Dostupno još {topOffersRemaining} Top{' '}
                {topOffersRemaining === 1 ? 'oglas' : 'oglasa'} do kraja
                obračunskog perioda.
              </p>
            </div>
          ) : null}
        </Show>
      </div>
    </>
  );
});
