import { Instance, types } from 'mobx-state-tree';
import { STANDARD } from '../../../constants';
import { PARTNER_PROFILES } from '../../../constants/header';
import { ComponentModel, PageRoutes } from '../../../internal';

export const ActivePackageModel = types
  .compose(
    ComponentModel,
    types.model({
      name: types.string,
      remainingUsers: types.string,
      locationNumber: types.string,
      validTo: types.string,
      paymentBy: types.string,
    })
  )
  .actions((self) => {
    return {
      async navigateToCreateOfferWithPackage() {
        const packageId = self.id;
        const createOfferPage = self.root.pages.get(PageRoutes.CreateOffer.id);

        // after this navigation code is complete we can set what we wish
        await self.root.router.navigate({
          newView: PageRoutes.CreateOffer.id,
          shouldReplace: true,
        });

        createOfferPage?.root?.header?.setActiveTab?.(PARTNER_PROFILES);
        // here we set the package
        let optionId = createOfferPage
          .getPackagePickModel()
          .optionsArray.filter(
            (option: { id: string; data: { id: string } }) => {
              return option?.data?.id === packageId;
            }
          )?.[0]?.id;
        optionId = optionId ? optionId : STANDARD;
        createOfferPage.getPackagePickModel().handleNewSelection(optionId);
      },
    };
  })
  .views((self) => {
    return {};
  })
  .named('ActivePackageModel');

export type ActivePackageModelType = Instance<typeof ActivePackageModel>;
