import { observer } from 'mobx-react';
import * as React from 'react';
import { TagModelType } from '../../../internal';
import styles from './Tag.module.scss';

export const Tag = observer(
  (props: { model: TagModelType; style?: any }): JSX.Element => {
    const { model, style } = props;
    return (
      <div
        style={style}
        className={
          !model.parent.isEnabledComputed
            ? styles.TagContainerDisabled
            : styles.TagContainer
        }
      >
        <span className={styles.TagText}>{model.name}</span>
        <span
          className={
            !model.parent.isEnabledComputed ? styles.XBtnDisabled : styles.XBtn
          }
          onClick={() => {
            if (model.parent.isEnabledComputed) {
              model.parent.remove(model);
            }
            // model.parent.isEnabledComputed ? model.remove : null
          }}
        >
          <img className={styles.XBtnIcon} />
        </span>
      </div>
    );
  }
);
