import { getRoot, Instance, types } from 'mobx-state-tree';
import { ComponentModel } from '../../ui/ComponentModel';
import { PaginationModel } from '../../ui/pagination/PaginationModel';
import { RootType } from '../../../rootInstance';
import {
  BannersListElementModel,
  BannersListElementModelType,
} from './BannersListElementModel';

export const BannersTableModel = types
  .compose(
    ComponentModel,
    types.model({
      pagination: types.maybe(types.late(() => PaginationModel)),
      rows: types.array(BannersListElementModel),
    })
  )
  .views((self) => {
    return {
      get root(): RootType {
        return getRoot(self);
      },
      get elements() {
        return Array.from(self.rows.values());
      },
    };
  })
  .actions((self) => {
    return {
      clearElements() {
        self.rows = [];
      },
      setElements(bannersArray: BannersListElementModelType[]) {
        self.clearElements();
        bannersArray.forEach((bannerElement: BannersListElementModelType) => {
          self.rows?.push(bannerElement);
        });
      },
    };
  })
  .named('BannersTableModel');

export type BannersListModelType = Instance<typeof BannersTableModel>;
