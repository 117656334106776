import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './CreateOfferSuccess.module.scss';
import confettiSrc from '../../../../assets/images/confetti.png';
import { PageRoutes, useMst } from '../../../../internal';
import { observer } from 'mobx-react';

export const CreateOfferSuccess = observer((): JSX.Element => {
  const { t } = useTranslation();
  const {
    router: { navigate },
  } = useMst();
  return (
    <div className={styles.PageContainer}>
      <div className={styles.FormContainer}>
        <div className={styles.FormContent}>
          <div className={styles.IconContainer}>
            <img className={styles.Icon} src={confettiSrc} />
          </div>
          <div className={styles.TextContainer}>
            <div className={styles.OfferSentTitle}>
              {t('basic:offer_sent_successfully')}
            </div>
            <div className={styles.OfferSentContent}>
              {t('basic:offer_sent_statistics')}{' '}
              <span
                className={styles.Link}
                onClick={() =>
                  navigate({ newView: PageRoutes.PartnerOffers.id })
                }
              >
                {t('basic:offer_sent_all_my_offers')}
              </span>
              .
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
