import { getRoot, types } from 'mobx-state-tree';
import { HttpVerbs } from '../../constants/verbs';
import i18n from '../../i18n';
import {
  RootType,
  ApiListResponse,
  PartnerLocation,
  ApiObjectResponse,
  ShoppingMall,
} from '../../internal';
import { StatusCodes } from '../../constants/statusCodes';

export const PartnerLocationApi = types
  .model()
  .views((self) => {
    return {
      get root(): RootType {
        return getRoot(self);
      },
    };
  })
  .actions((self) => {
    return {
      uploadCodes(file: File, partnerId: string, successCallback?: () => void) {
        return new Promise((resolve) => {
          self.root.axios
            .uploadFile({
              path: '/partner-locations/csv',
              fileKey: 'file',
              file,
              params: {
                ...(partnerId ? { partner_id: partnerId } : {}),
              },
              customErrorMessage: () => i18n.t('forms:invalid_csv'),
            })
            .then((response: any) => {
              resolve(response);
              if (response.statusCode === StatusCodes.OK) {
                successCallback?.();
              }
            });
        });
      },
      add(location: PartnerLocation) {
        return new Promise((resolve) => {
          self.root.axios
            .requestData({
              type: HttpVerbs.POST,
              path: '/partner-locations',
              data: location,
            })
            .then((response: ApiObjectResponse<PartnerLocation>) => {
              resolve(response);
            });
        });
      },
      delete(id: string) {
        return new Promise((resolve) => {
          self.root.axios
            .requestData({
              type: HttpVerbs.DELETE,
              path: `/partner-locations?id=${id}`,
            })
            .then((response: ApiObjectResponse<PartnerLocation>) => {
              resolve(response);
            });
        });
      },
      modify(location: PartnerLocation) {
        return new Promise((resolve) => {
          self.root.axios
            .requestData({
              type: HttpVerbs.PUT,
              path: '/partner-locations',
              data: location,
            })
            .then((response: ApiObjectResponse<PartnerLocation>) => {
              resolve(response);
            });
        });
      },
      // the endpoint which is used here is paginated, so we need to fetch
      // data 'page' by 'page'
      getPartnerLocations(
        partnerId: string,
        batchSize = 100,
        offset = 0,
        totalData: PartnerLocation[] = []
      ) {
        return new Promise((resolve) => {
          self.root.axios
            .requestData({
              type: HttpVerbs.GET,
              path: `/partner-locations?partner=${partnerId}&offset=${offset}&limit=${batchSize}`,
            })
            .then((response: ApiListResponse<PartnerLocation>) => {
              resolve(response);
              // add response page data to the total data, which will be set all at once
              totalData.push(...(response.data as PartnerLocation[]));
              const totalElements = response.meta.total;
              offset += batchSize;
              // there is still data to fetch, do it recursively
              if (offset < totalElements) {
                this.getPartnerLocations(
                  partnerId,
                  batchSize,
                  offset,
                  totalData
                );
              } else {
                // otherwise just set the model data
                this.setStores(totalData);
              }
            });
        });
      },
      setStores(totalData: PartnerLocation[] | null | undefined) {
        self.root.data.setPartnerLocations(totalData);
      },
      getShoppingMalls() {
        return new Promise((resolve) => {
          self.root.axios
            .requestData({
              type: HttpVerbs.GET,
              path: '/shopping-malls?&limit=1000',
            })
            .then((response: ApiListResponse<ShoppingMall>) => {
              resolve(response);
              self.root.data.setShoppingMalls(response.data);
            });
        });
      },
    };
  });
