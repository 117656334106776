import { IAnyModelType, Instance, types } from 'mobx-state-tree';
import {
  TagModel,
  TagModelType,
  ComponentModel,
  FunctionModel,
} from '../../../internal';

export const TagGroupModel: IAnyModelType = types
  .compose(
    ComponentModel,
    types.model({
      tags: types.map(types.late(() => TagModel)),
      maxNumber: types.maybe(types.number), // elements after this one will look differently
      addCallback: types.maybe(FunctionModel),
      removeCallback: types.maybe(FunctionModel),
    })
  )
  .actions((self) => {
    return {
      afterCreate() {
        self.tagsArray.forEach((tag: TagModelType) => {
          tag.setParent(self.id);
        });
      },
      add(tag: TagModelType) {
        self.tags.set(tag.id, TagModel.create(tag));
        self.tags.get(tag.id).setParent(self);
        if (self.addCallback) {
          self.addCallback();
        }
      },
      remove(element: TagModelType) {
        self.tags.delete(element.id);
        if (self.removeCallback) {
          self.removeCallback();
        }
      },
      setMaxNumber(value: number) {
        self.maxNumber = !value ? 0 : value;
      },
      clearTags() {
        self.tags.clear();
      },
    };
  })
  .views((self) => {
    return {
      get tagsArray() {
        return Array.from(self.tags.values());
      },
      get length() {
        return self.tagsArray.length;
      },
      get maxExceeded() {
        return self.tagsArray.length > self.maxNumber;
      },
      get numberOfExcessElements() {
        const num = self.tagsArray.length - self.maxNumber;
        return num > 0 ? num : 0;
      },
      get idsStr(): string {
        return Array.from(self.tags.keys()).join(',');
      },
      get valsStr(): string {
        return Array.from(self.tags.values())
          .map((tag: TagModelType) => tag.name)
          .join(',');
      },
    };
  })
  .named('TagGroupModel');

export type TagGroupModelType = Instance<typeof TagGroupModel>;
