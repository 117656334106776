export const SUBMITTED = 'submitted';
export const ACTIVE = 'active';
export const PENDING = 'pending';
export const EXPIRED = 'expired';
export const REJECTED = 'rejected';
export const PUBLISHED = 'published';
export const PUBLISHING = 'publishing';
export const SUBMITTING = 'submitting';
export const PREP = 'prep';
export const STOPPED = 'stopped';
export const PUBLISH_AD = 'publishAd';
export const REJECT_AD = 'rejectAd';
export const STOP_AD = 'stopAd';
export const CODE_SPENT = 'code_spent';
export const PUBLISHED_SOON = 'published_soon';
export const PUBLISHED_SOON_UPDATE = 'published_soon_update';
export const PUBLISHED_UPDATE = 'published_update';

export const CODE_TYPE_PERSONALIZED = 'personalized';
export const MAX_COUPON_NUN = 3_600_000;
