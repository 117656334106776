import * as React from 'react';
import { observer } from 'mobx-react';
import { Pagination } from '../../../../components/UI/pagination/Pagination';
import { Show } from '../../../../components/UI/show/Show';
import { BannersListElement } from './BannersListElement';
import {
  BannersListModelType,
  BannersListElementModelType,
} from '../../../../internal';
import styles from './BannersList.module.scss';

export const BannersList = observer(
  (props: {
    model: BannersListModelType;
    noElementsComponent: JSX.Element;
    openBannerDetailsPage: (id: number) => void;
    currentPage: any;
  }): JSX.Element => {
    const { model, noElementsComponent, currentPage } = props;
    return (
      <div className={styles.Container}>
        <Show condition={!model?.elements?.length}>
          <div className={styles.NoElementsContainer}>
            {noElementsComponent}
          </div>
        </Show>
        <Show condition={model?.elements?.length}>
          {model.elements.map(
            (elem: BannersListElementModelType, index: number) => {
              return (
                <BannersListElement
                  key={index}
                  model={elem}
                  openBannerDetailsPage={props.openBannerDetailsPage}
                  currentPage={currentPage}
                  index={index}
                />
              );
            }
          )}
          <div className={styles.PaginationOuterContainer}>
            <Pagination model={model.pagination} />
          </div>
        </Show>
      </div>
    );
  }
);
