import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './PaymentsAndDocuments.module.scss';
import { observer } from 'mobx-react';
import { useMst } from '../../internal';
import { Tabs } from '../../components/UI/tabs/Tabs';
import { TabPanel } from 'react-tabs';
import { PaymentsAndDocumentsTab1 } from './PaymentsAndDocumentsTab1';
import { PaymentsAndDocumentsTab2 } from './PaymentsAndDocumentsTab2';
import { PaymentsAndDocumentsTab3 } from './PaymentsAndDocumentsTab3';

export const PaymentsAndDocuments = observer((): ReactElement => {
  const { t } = useTranslation();
  const { currentPage } = useMst();
  return (
    <div className={styles.pageContainer}>
      <h1 className={styles.title}>{t('navigation:payments_and_documents')}</h1>

      <Tabs
        tabsClass={styles.tabs}
        model={currentPage.getPaymentsAndDocumentsTabs()}
      >
        <TabPanel className={styles.tabOne}>
          <PaymentsAndDocumentsTab1 />
        </TabPanel>
        <TabPanel>
          <PaymentsAndDocumentsTab2 />
        </TabPanel>
        <TabPanel>
          <PaymentsAndDocumentsTab3 documents={currentPage.documents} />
        </TabPanel>
      </Tabs>
    </div>
  );
});
