import { Instance, types } from 'mobx-state-tree';
import {
  ComponentModel,
  PartnerLocation,
  StoreLocationModalModel,
  DeleteLocationModalModel,
  ShoppingMall,
} from '../../../internal';

export const PhysicalStoreLocationsModel = types
  .compose(
    ComponentModel,
    types.model({
      elements: types.map(types.late(() => types.frozen<PartnerLocation>())),
      shoppingMalls: types.map(types.late(() => types.frozen<ShoppingMall>())),
      storeLocationModal: types.late(() => StoreLocationModalModel),
      deleteLocationModal: types.late(() => DeleteLocationModalModel),
    })
  )
  .actions((self) => {
    return {
      setLocations(newLocations: PartnerLocation[]) {
        self.elements.clear();
        newLocations.forEach((location: PartnerLocation) => {
          self.elements.set(location.id, location);
        });
      },
      setShoppingMalls(newShoppingMalls: ShoppingMall[]) {
        self.shoppingMalls.clear();
        newShoppingMalls.forEach((mall: ShoppingMall) => {
          self.shoppingMalls.set(mall.id, mall);
        });
      },
    };
  })
  .views((self) => {
    return {
      get locationsArray() {
        return Array.from(self.elements.values());
      },
    };
  })
  .named('PhysicalStoreLocationsModelType');

export type PhysicalStoreLocationsModelType = Instance<
  typeof PhysicalStoreLocationsModel
>;
