import { getParent, getRoot, Instance, types } from 'mobx-state-tree';
import { ComponentModelType, RootType } from '../../../internal';
import i18n from 'i18next';

// DropdownOptionModel - value represents display data received from the BE
// which can be further tweaked(translated etc) to form displayValue which is actually displayed
// Options are not identified by 'value' attribute, use 'id' instead
// This model can be extented by composition if you wish to change displayValue logic
export const DropdownOptionModel = types
  .model({
    id: types.string,
    value: types.string,
    data: types.maybe(types.frozen({})),
    disabled: types.maybe(types.boolean),
    colorIcon: types.maybe(types.string),
  })
  .views((self) => {
    return {
      get displayValue(): string {
        if ((getRoot(self) as RootType).language) {
          return i18n.t(self.value);
        } else {
          return self.value;
        }
      },
      get rawValue(): string {
        return self.value;
      },
      get isSelected() {
        return (getParent(self, 2) as ComponentModelType).value === self.value;
      },
    };
  })
  .actions((self) => {
    return {
      setData(data: any) {
        self.data = data;
      },
      setDisabled(val: boolean) {
        self.disabled = val;
      },
    };
  })
  .named('DropdownOptionModel');

export type DropdownOptionModelType = Instance<typeof DropdownOptionModel>;
