import * as React from 'react';
import styles from './PasswordField.module.scss';
import { PasswordInputModelType } from '../../../internal';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import EYE_HIDE from '../../../assets/icons/icons8-eye-hide-24.png';
import EYE_SHOW from '../../../assets/icons/icons8-eye-show-24.png';

export const PasswordField = observer(
  (props: { model: PasswordInputModelType; id?: string }): JSX.Element => {
    const { model, id } = props;
    const { t } = useTranslation();
    const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
      model.onChange(e);
      model.runValidators();
    };
    return (
      <div id={id} className={styles.InputContainer}>
        {model.label ? (
          <label htmlFor={model.id} className={styles.InputLabel}>
            {t(model.label)}
            <span className={styles.Required}>
              {model.isRequired ? '*' : ''}
            </span>
          </label>
        ) : (
          <></>
        )}
        <div id={model.id} className={styles.InputContent}>
          <input
            required={model.isRequired}
            className={`${
              model.isInvalid ? styles.InputFieldInvalid : styles.InputField
            } ${model.inputFieldArrowsHidden ? styles.ArrowsHidden : ''} ${
              model.isDisabled ? styles.Disabled : ''
            }`}
            type={model.type}
            disabled={model.isDisabled}
            placeholder={t(model.placeholder)}
            onChange={handleChange}
            value={model.value}
            autoComplete={'enter-pass'}
          />
          <div
            className={
              model.isInvalid ? styles.EyeContainerInvalid : styles.EyeContainer
            }
            onClick={() => model.toggleVisibility()}
          >
            <img src={model.isVisible ? EYE_SHOW : EYE_HIDE} alt={'img'} />
          </div>
        </div>
        {model.isInvalid ? (
          <div className={styles.ErrorContainer}>
            <div className={styles.Error}>{t(model.currentMessageValue)}</div>
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  }
);
