import {
  getParent,
  getRoot,
  IAnyModelType,
  Instance,
  types,
} from 'mobx-state-tree';
import { validateRequired } from '../../validators/validators';
import { FunctionModel, RootType } from '../../internal';

export const ComponentModel: IAnyModelType = types
  .model({
    autoFocus: false,
    id: types.identifier,
    value: types.maybeNull(
      types.optional(
        types.union(
          types.string,
          types.integer,
          types.Date,
          types.boolean,
          types.frozen({})
        ),
        ''
      )
    ),
    validators: types.optional(types.map(FunctionModel), {}),
    validationMessages: types.optional(types.map(types.string), {}),
    currentMessage: '',
    isRequired: false,
    name: '',
    label: '',
    labelFunc: types.maybe(FunctionModel),
    placeholder: '',
    type: '',
    disabled: false,
    step: 0,
    icon: '',
    afterCreateFunc: types.maybe(FunctionModel),
    isEnabledFunc: types.maybe(FunctionModel),
    isRequiredFunc: types.maybe(FunctionModel),
  })
  .views((self) => {
    return {
      get validationMessagesArray(): string[] {
        return Array.from(self.validationMessages.values());
      },
      get validatorsArray(): any[] {
        return Array.from(self.validators.values());
      },
      get computedValidatorsArray(): any[] {
        if (!self.validators.has('isRequired') && this.isRequiredComputed) {
          return [validateRequired, ...this.validatorsArray];
        } else {
          return this.validatorsArray;
        }
      },
      get parentPage() {
        return getParent(self, 2);
      },
      get root() {
        return getRoot(self);
      },
      get currentMessageValue(): string {
        return self.currentMessage;
      },
      get isInvalid() {
        return (
          self.currentMessage ||
          this.validationMessagesArray.filter((msg: string) => !!msg).length
        );
      },
      get isDisabled() {
        return self.disabled === true;
      },
      get isEnabledComputed() {
        if (self.isEnabledFunc) {
          return self.isEnabledFunc(self);
        }
        return !self.disabled;
      },
      get labelComputed() {
        if (self.labelFunc) {
          return self.labelFunc(self);
        }
        return self.label;
      },
      get isRequiredComputed() {
        if (self.isRequiredFunc) {
          return self.isRequiredFunc(self);
        }
        return self.isRequired;
      },
    };
  })
  .actions((self) => {
    return {
      onFocus() {
        const root: RootType = getRoot(self);
        root.setFocusedComponent && root.setFocusedComponent(self);
      },
      afterCreate() {
        if (self.isRequired) {
          this.addValidator('isRequired', validateRequired);
        }
        if (self.afterCreateFunc) {
          self.afterCreateFunc(self);
        }
      },
      setIsRequired(required: boolean) {
        self.isRequired = required;
        if (self.isRequiredComputed) {
          this.addValidator('isRequired', validateRequired);
        } else {
          this.removeValidator('isRequired');
        }
      },
      setValue(value: string | number | Date) {
        if (self.isDisabled) {
          return;
        }
        self.value = value;
        this.runValidators();
      },
      setDisabled(disabled: boolean) {
        self.disabled = disabled;
      },
      clearData() {
        self.value = null;
      },
      addValidator(name: string, validator: any) {
        self.validators.set(name, validator);
      },
      removeValidator(name: string) {
        self.validators.delete(name);
      },
      setCurrentMessage(currentMessage: string) {
        self.currentMessage = currentMessage;
      },
      runValidators() {
        // if no validators then message is empty
        // (component cannot be invalid, because there are no validators to fail)
        // useful when setCurrentMessage is used directly for
        // elements which don't have any validators
        // because this method runs when setValue is invoked
        if (!self?.computedValidatorsArray?.length) {
          self.currentMessage = '';
        }
        for (const validator of self.computedValidatorsArray) {
          const validationResult = validator(self);
          if (!validationResult.result) {
            self.currentMessage = validationResult.message;
            break;
          } else {
            self.currentMessage = '';
          }
        }
      },
      setLabel(label: string) {
        self.label = label;
      },
    };
  })
  .named('ComponentModel');
export type ComponentModelType = Instance<typeof ComponentModel>;
