import { NUMBER_PATTERN } from '../constants/patterns';
import { ComponentModelType } from '../internal';

export type ValidationResult = {
  result: boolean;
  message: string;
};

export const validateRequired = (
  self: ComponentModelType
): ValidationResult => {
  const returnValue = {
    result: !!self.value,
    message: '',
  };
  returnValue.message = returnValue.result
    ? ''
    : 'validators:required_validator';
  return returnValue;
};

// for those components which have string as value, guaranteed
export const validateRequiredStr = (
  self: ComponentModelType
): ValidationResult => {
  const returnValue = {
    result: !!self?.value?.trim?.(), // small, but important difference
    message: '',
  };
  returnValue.message = returnValue.result
    ? ''
    : 'validators:required_validator';
  return returnValue;
};

export const validateNumber = (self: ComponentModelType): ValidationResult => {
  const returnValue = {
    result: !self.value || NUMBER_PATTERN.test(self.value),
    message: '',
  };
  returnValue.message = returnValue.result ? '' : 'validators:number_validator';
  return returnValue;
};

export const validateTitle = (self: ComponentModelType): ValidationResult => {
  const returnValue = {
    result: self?.value?.length <= 90,
    message: '',
  };
  returnValue.message = returnValue.result ? '' : 'validators:title_validator';
  return returnValue;
};

export function validateEmail(self: ComponentModelType): ValidationResult {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const returnValue = {
    result: re.test(String(self.value).toLowerCase()),
    message: '',
  };
  returnValue.message = returnValue.result ? '' : 'validators:email_validator';
  return returnValue;
}
// we disable the linter here because it complains about escapes, which are necessary
export function validatePassword(self: ComponentModelType): ValidationResult {
  const re = new RegExp(
    //eslint-disable-next-line
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[.!:;"'^£\\\\$%&*()}{@#~?><>,|=_+¬\[\]`\/-])(?=.{10,})/
  );
  const returnValue = {
    result: re.test(self.value),
    message: '',
  };
  returnValue.message = returnValue.result
    ? ''
    : 'validators:password_validator';
  return returnValue;
}

export function validateURL(self: ComponentModelType): ValidationResult {
  const re =
    /(http(s)?:\/\/.)(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g;
  const returnValue = {
    result: re.test(String(self.value)) || !self.value,
    message: '',
  };
  returnValue.message = returnValue.result ? '' : 'validators:url_validator';
  return returnValue;
}
