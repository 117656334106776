import { getRoot, types } from 'mobx-state-tree';
import {
  RootType,
  PageRoutes,
  ApiListResponse,
  UserLocation,
} from '../../internal';
import { HttpVerbs } from '../../constants/verbs';
import { StatusCodes } from '../../constants/statusCodes';
import {
  getCategoryIdFromSubcategory,
  getSubcategoryIdFromSubcategory,
} from '../methods';

export interface UserTargets {
  targets: string;
}

export const UserApi = types
  .model()
  .views((self) => {
    return {
      get root(): RootType {
        return getRoot(self);
      },
    };
  })
  .actions((self) => {
    return {
      getUserLocations() {
        const currentPage = self.root.pages.get(PageRoutes.CreateOffer.id);
        if (currentPage.getUserLocationDropdown()?.options.size) {
          return Promise.resolve();
        }
        return new Promise((resolve) => {
          self.root.axios
            .requestData({ type: HttpVerbs.GET, path: '/user-locations' })
            .then((response: ApiListResponse<UserLocation>) => {
              resolve(response);
              const options = response.data
                ?.splice(
                  response.data.findIndex(
                    (v) => v.location_group === 'Cela Srbija'
                  ),
                  1
                )
                .concat(response.data)
                .map((ul: UserLocation) => {
                  return { id: `${ul.location}`, value: ul.location, data: ul };
                });

              currentPage.getUserLocationDropdown().setOptions(options, false);
            });
        });
      },
      getUserTargets(
        userLocations: string,
        gender: string,
        ageFrom: number,
        ageTo: number,
        similarOnly: boolean,
        categoriesAndSubcategories: [],
        userContractType: string,
        spenderType: string,
        territoryType: string
      ) {
        let categories = '';
        let subcategories = '';
        categoriesAndSubcategories.forEach((categoryAndSubcategory: string) => {
          categories =
            categories !== ''
              ? categories +
                ',' +
                getCategoryIdFromSubcategory(categoryAndSubcategory)
              : getCategoryIdFromSubcategory(categoryAndSubcategory);
          subcategories =
            subcategories !== ''
              ? subcategories +
                ',' +
                getSubcategoryIdFromSubcategory(categoryAndSubcategory)
              : getSubcategoryIdFromSubcategory(categoryAndSubcategory);
        });
        const userLocationsQuery = userLocations
          ? `user_locations=${userLocations}`
          : '';
        const genderQuery = gender ? `&gender=${gender}` : '';
        const ageFromQuery = ageFrom ? `&age_from=${ageFrom}` : '&age_from=0';
        const ageToQuery = ageTo ? `&age_to=${ageTo}` : '';
        const similarOnlyQuery = similarOnly
          ? `&similar_only=${similarOnly}`
          : 0;
        const categoryIdQuery =
          categoriesAndSubcategories.length > 0
            ? `&category_id=${categories}`
            : '';
        const subCategoryIdQuery =
          categoriesAndSubcategories.length > 0
            ? `&subcategory_id=${subcategories}`
            : '';
        const userContractTypeQuery = userContractType
          ? `&user_contract_type=${userContractType}`
          : '';
        const spenderTypeQuery = spenderType
          ? `&spender_type=${spenderType}`
          : '';
        const territoryTypeQuery = territoryType
          ? `&territory_type=${territoryType}`
          : '';
        return new Promise((resolve) => {
          self.root.axios
            .requestData({
              type: HttpVerbs.GET,
              path: `/users/targets?${userLocationsQuery}${genderQuery}${ageFromQuery}${ageToQuery}${similarOnlyQuery}${categoryIdQuery}${subCategoryIdQuery}${userContractTypeQuery}${spenderTypeQuery}${territoryTypeQuery}`,
            })
            .then((response: ApiListResponse<UserTargets>) => {
              if (response.statusCode === StatusCodes.OK) {
                self.root.data.setUserTargets(response.data);
              }
              resolve(response);
            });
        });
      },
    };
  });
