import { detach, IAnyModelType, Instance, types } from 'mobx-state-tree';
import {
  ComponentModel,
  CheckboxModel,
  CheckboxModelType,
  FunctionModel,
} from '../../../internal';

export const CheckboxGroupModel: IAnyModelType = types
  .compose(
    ComponentModel,
    types.model({
      checkboxes: types.map(types.late(() => CheckboxModel)),
      selectedCheckboxes: types.map(
        types.reference(types.late(() => CheckboxModel))
      ),
      onCheck: types.maybe(FunctionModel),
      onUncheck: types.maybe(FunctionModel),
      allSelectedOnInit: false,
      isVisible: true,
    })
  )
  .views((self) => {
    return {
      get checkboxesArray() {
        return Array.from(self.checkboxes.values());
      },
      get selectedCheckboxesIds() {
        return Array.from(self.selectedCheckboxes.keys());
      },
      get uncheckAllowed() {
        return self.selectedCheckboxesIds.length > 1 || !self.isRequired;
      },
      get selectedIdsStr() {
        return self.selectedCheckboxesIds.join(',');
      },
      get isAllChecked() {
        return Boolean(
          this.selectedCheckboxesIds.find((id) => id === 'allOptions')
        );
      },
    };
  })
  .actions((self) => {
    return {
      setIsVisible(value: boolean) {
        self.isVisible = value;
      },
      setAllSelectedOnInit(value: boolean) {
        self.allSelectedOnInit = value;
      },
      beforeDestroy() {
        self.checkboxes.clear();
      },
      afterAttach() {
        if (self.selectedCheckboxesIds.length > 0) {
          self?.setValue(self.selectedCheckboxesIds);
        }
        self.checkboxesArray.forEach((checkbox: CheckboxModelType) => {
          checkbox.setParent(self.id);
          if (
            (checkbox.id === 'allOptions' && checkbox.isChecked) ||
            self.allSelectedOnInit
          ) {
            return this.selectAll(false);
          }
        });
      },
      add(checkbox: CheckboxModelType) {
        self.checkboxes.set(checkbox.id, CheckboxModel.create(checkbox));
        self.checkboxes.get(checkbox.id).setParent(self);
        if (self.isAllChecked) {
          self.check(checkbox, false);
        }
      },
      check(checkbox: CheckboxModelType, shouldCallOnCheck = true) {
        if (self.isDisabled) {
          return;
        }
        self.selectedCheckboxes.delete(checkbox.id, checkbox.id);
        self.selectedCheckboxes.set(checkbox.id, checkbox.id);
        if (self.onCheck && shouldCallOnCheck) {
          self.onCheck(checkbox, self);
        }
        return self?.setValue(self.selectedCheckboxesIds);
      },
      uncheck(checkbox: CheckboxModelType, shouldCallOnUncheck = true) {
        if (self.isDisabled) {
          return;
        }
        self.selectedCheckboxes.delete(checkbox.id);
        if (self.onUncheck && shouldCallOnUncheck) {
          self.onUncheck(checkbox, self);
        }
        return self?.setValue(self.selectedCheckboxesIds);
      },
      toggle(checkbox: CheckboxModelType) {
        if (checkbox.id === 'allOptions') {
          checkbox.isChecked ? this.toggleAll(false) : this.toggleAll(true);
          return;
        }

        if (checkbox.isChecked && self.uncheckAllowed) {
          this.uncheck(checkbox);
        } else {
          this.check(checkbox);
        }

        if (
          self.selectedCheckboxesIds.length ===
          self.checkboxesArray.length - 1
        ) {
          const allOptionsCheckbox = self.checkboxes.get('allOptions');
          if (allOptionsCheckbox) {
            allOptionsCheckbox.isChecked
              ? this.uncheck(allOptionsCheckbox)
              : this.check(allOptionsCheckbox);
          }
        }
      },
      toggleAll(toggleValue: boolean) {
        toggleValue ? this.selectAll() : this.deselectAll();
      },
      selectAll(shouldCallOnCheck = true) {
        self.selectedCheckboxes.clear();
        self.checkboxesArray.forEach((checkbox: CheckboxModelType) => {
          self.check(checkbox, shouldCallOnCheck);
        });
      },
      deselectAll(shouldCallOnCheck = true) {
        if (!self.isRequired) {
          self.checkboxesArray.forEach((checkbox: CheckboxModelType) => {
            self.uncheck(checkbox, shouldCallOnCheck);
          });
        }
      },
      clearData(preserveSelection = false) {
        if (!preserveSelection) {
          detach(self.selectedCheckboxes);
        }
        detach(self.checkboxes);
      },
      setCheckboxes(
        checkboxes: CheckboxModelType[],
        preserveSelection = false
      ) {
        this.clearData(preserveSelection);
        checkboxes.forEach((checkbox: CheckboxModelType) => {
          self.checkboxes.set(checkbox.id, CheckboxModel.create(checkbox));
          self.checkboxes.get(checkbox.id).setParent(self);
        });
      },
    };
  })
  .named('CheckboxGroupModel');

export type CheckboxGroupModelType = Instance<typeof CheckboxGroupModel>;
