import * as React from 'react';
import { ReactElement } from 'react';
import { useMst } from '../../../../../internal';
import styles from '../AdDetails.module.scss';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import {
  ALL_USERS,
  CodeTypes,
  GenderTypes,
  MAX_AGE,
  MIN_AGE,
  SpenderTypes,
  StoreLocationTypes,
  TerritoryTypes,
  WithCodeTypes,
} from '../../../../../constants/adSettings';
import { format } from 'date-fns';
import { DownloadCSVTable } from '../download-csv-table/DownloadCSVTable';

export const AdSettings = observer((): ReactElement => {
  const {
    currentPage: {
      adDetails,
      categoryNames,
      subcategoryNames,
      purchasedExtensionData,
    },
  } = useMst();
  const { t } = useTranslation();

  const ad_with_or_without_voucher = () => {
    return adDetails.with_code === WithCodeTypes.COMMON ||
      WithCodeTypes.PERSONALIZED
      ? t('ad_settings:with_voucher')
      : adDetails.with_code === WithCodeTypes.NO_CODE
      ? t('ad_settings:without_voucher')
      : '-';
  };

  const store_location_type = () => {
    return adDetails.store_location_type === StoreLocationTypes.ALL
      ? t('ad_settings:all')
      : adDetails.store_location_type === StoreLocationTypes.ONLINE
      ? t('ad_settings:online')
      : adDetails.store_location_type === StoreLocationTypes.OFFLINE
      ? t('ad_settings:offline')
      : adDetails.store_location_type === StoreLocationTypes.SELECTED
      ? t('ad_settings:selected')
      : adDetails.store_location_type === StoreLocationTypes.ONLINE_SELECTED
      ? t('ad_settings:online-sel')
      : '-';
  };

  const code_count = () => {
    return adDetails.code_count === 0 ? '-' : adDetails.code_count;
  };

  const user_locations = () => {
    if (adDetails.user_locations) {
      return adDetails.user_locations.replace(/,/g, ', ');
    }
    return t('ad_settings:unsegmented_ad');
  };

  const gender = () => {
    return adDetails.gender === GenderTypes.MALE
      ? t('ad_settings:male')
      : adDetails.gender === GenderTypes.FEMALE
      ? t('ad_settings:female')
      : adDetails.gender === ''
      ? t('ad_settings:both_genders')
      : t('ad_settings:unsegmented_ad');
  };

  const age = () => {
    const isUnsegmented = Boolean(
      adDetails.age_from === MIN_AGE && adDetails.age_to === MAX_AGE
    );
    return isUnsegmented
      ? t('ad_settings:unsegmented_ad')
      : `${adDetails.age_from ?? ''} - ${adDetails.age_to ?? ''}`;
  };

  const similar_only = () => {
    return adDetails.similar_only === 0
      ? t('ad_settings:no')
      : adDetails.similar_only === 1
      ? t('ad_settings:yes')
      : t('ad_settings:unsegmented_ad');
  };

  const user_contract_type = () => {
    const prettifiedString = adDetails.user_contract_type?.replace(/,/g, ', ');
    return adDetails.user_contract_type === ALL_USERS
      ? t('ad_settings:business_all')
      : !adDetails.user_contract_type
      ? t('ad_settings:unsegmented_ad')
      : prettifiedString;
  };

  const spender_type = () => {
    let remappedString = '';
    if (adDetails.spender_type && adDetails.spender_type.length) {
      remappedString = adDetails.spender_type
        .replace(SpenderTypes.BIG_SPENDERS, t('ad_settings:big_spenders'))
        .replace(SpenderTypes.MID_SPENDERS, t('ad_settings:mid_spenders'))
        .replace(SpenderTypes.SMALL_SPENDERS, t('ad_settings:small_spenders'))
        .replace(/,/g, ', ');
    }
    return adDetails.spender_type === ''
      ? '-'
      : !adDetails.spender_type
      ? t('ad_settings:unsegmented_ad')
      : remappedString;
  };

  const territory_type = () => {
    return adDetails.territory_type === TerritoryTypes.URBAN
      ? t('ad_settings:urban')
      : adDetails.territory_type === TerritoryTypes.RURAL
      ? t('ad_settings:rural')
      : adDetails.territory_type === ''
      ? t('ad_settings:both_territories')
      : t('ad_settings:unsegmented_ad');
  };

  const code_type = () => {
    return adDetails.with_code === WithCodeTypes.COMMON
      ? t('ad_settings:code_common')
      : adDetails.with_code === WithCodeTypes.PERSONALIZED
      ? t('ad_settings:code_personalized')
      : '-';
  };

  const code_type_shown_in_ad = () => {
    let remappedString = '';
    if (adDetails.code_type && adDetails.code_type.length) {
      remappedString = adDetails.code_type
        .replace(CodeTypes.NUMERIC, t('ad_settings:numeric'))
        .replace(CodeTypes.BARCODE, t('ad_settings:bar'))
        .replace(CodeTypes.QRCODE, t('ad_settings:qr'))
        .replace(/,/g, ', ');
    }
    return !adDetails.code_type
      ? t('ad_settings:unsegmented_ad')
      : adDetails.code_type === ''
      ? '-'
      : remappedString;
  };

  const campaign_validity = () => {
    if (adDetails.date_start && adDetails.date_end) {
      const dateStart = new Date(adDetails.date_start);
      const dateEnd = new Date(adDetails.date_end);
      return `${format(dateStart, 'dd.MM.yyyy')} - ${format(
        dateEnd,
        'dd.MM.yyyy'
      )}`;
    }
    return '-';
  };

  const voucher_validity = () => {
    if (adDetails.date_code_start && adDetails.date_code_end) {
      const dateStart = new Date(adDetails.date_code_start);
      const dateEnd = new Date(adDetails.date_code_end);
      return `${format(dateStart, 'dd.MM.yyyy')} - ${format(
        dateEnd,
        'dd.MM.yyyy'
      )}`;
    }
    return '-';
  };

  const checkForUnsegmentedAd = (text: string) => {
    return text === t('ad_settings:unsegmented_ad');
  };

  return (
    <div className={styles.AdDetailTabContainer}>
      <div className={styles.AdSettingsContainer}>
        <div className={styles.ItemsColumn}>
          <div className={styles.Item}>
            <p className={styles.ItemTitle}>
              {t('ad_settings_titles:ad_with_or_without_voucher')}
            </p>
            <p
              className={`${styles.ItemText} ${
                checkForUnsegmentedAd(ad_with_or_without_voucher())
                  ? 'italic'
                  : ''
              }`}
            >
              {ad_with_or_without_voucher()}
            </p>
          </div>
          <div className={styles.Item}>
            <p className={styles.ItemTitle}>
              {t('ad_settings_titles:package')}
            </p>
            <p
              className={`${styles.ItemText} ${
                checkForUnsegmentedAd(purchasedExtensionData) ? 'italic' : ''
              }`}
            >
              {purchasedExtensionData}
            </p>
          </div>
          <div className={styles.Item}>
            <p className={styles.ItemTitle}>
              {t('ad_settings_titles:ad_category')}
            </p>
            <p
              className={`${styles.ItemText} ${
                checkForUnsegmentedAd(categoryNames) ? 'italic' : ''
              }`}
            >
              {categoryNames}
            </p>
          </div>
          <div className={styles.Item}>
            <p className={styles.ItemTitle}>
              {t('ad_settings_titles:ad_subcategory')}
            </p>
            <p
              className={`${styles.ItemText} ${
                checkForUnsegmentedAd(subcategoryNames) ? 'italic' : ''
              }`}
            >
              {subcategoryNames}
            </p>
          </div>
          <div className={styles.Item}>
            <p className={styles.ItemTitle}>
              {t('ad_settings_titles:ad_location_selected')}
            </p>
            <p
              className={`${styles.ItemText} ${
                checkForUnsegmentedAd(store_location_type()) ? 'italic' : ''
              }`}
            >
              {store_location_type()}
            </p>
          </div>
          <div className={styles.Item}>
            <p className={styles.ItemTitle}>
              {t('ad_settings_titles:campaign_validity')}
            </p>
            <p
              className={`${styles.ItemText} ${
                checkForUnsegmentedAd(campaign_validity()) ? 'italic' : ''
              }`}
            >
              {campaign_validity()}
            </p>
          </div>
          <div className={styles.Item}>
            <p className={styles.ItemTitle}>
              {t('ad_settings_titles:voucher_validity')}
            </p>
            <p
              className={`${styles.ItemText} ${
                checkForUnsegmentedAd(voucher_validity()) ? 'italic' : ''
              }`}
            >
              {voucher_validity()}
            </p>
          </div>
          <div className={styles.Item}>
            <p className={styles.ItemTitle}>
              {t('ad_settings_titles:max_number_of_coupons')}
            </p>
            <p
              className={`${styles.ItemText} ${
                checkForUnsegmentedAd(code_count()) ? 'italic' : ''
              }`}
            >
              {code_count()}
            </p>
          </div>
        </div>
        <div className={styles.ItemsColumn}>
          <div className={styles.Item}>
            <p className={styles.ItemTitle}>
              {t('ad_settings_titles:location_selected')}
            </p>
            <p
              className={`${styles.ItemText} ${
                checkForUnsegmentedAd(user_locations()) ? 'italic' : ''
              }`}
            >
              {user_locations()}
            </p>
          </div>
          <div className={`${styles.Item} ${styles.DoubleItem}`}>
            <div className={styles.ItemSplit}>
              <p className={styles.ItemTitle}>
                {t('ad_settings_titles:gender')}
              </p>
              <p
                className={`${styles.ItemText} ${
                  checkForUnsegmentedAd(gender()) ? 'italic' : ''
                }`}
              >
                {gender()}
              </p>
            </div>
            <div className={styles.ItemSplit}>
              <p className={styles.ItemTitle}>{t('ad_settings_titles:age')}</p>
              <p
                className={`${styles.ItemText} ${
                  checkForUnsegmentedAd(age()) ? 'italic' : ''
                }`}
              >
                {age()}
              </p>
            </div>
          </div>
          {/** see LOY-394, hidden because of that */}
          <div className={styles.Item} style={{ display: 'none' }}>
            <p className={styles.ItemTitle}>
              {t('ad_settings_titles:users_choosing_similar_offers')}
            </p>
            <p
              className={`${styles.ItemText} ${
                checkForUnsegmentedAd(similar_only()) ? 'italic' : ''
              }`}
            >
              {similar_only()}
            </p>
          </div>
          <div className={styles.Item}>
            <p className={styles.ItemTitle}>
              {t('ad_settings_titles:users_segmented')}
            </p>
            <p
              className={`${styles.ItemText} ${
                checkForUnsegmentedAd(user_contract_type()) ? 'italic' : ''
              }`}
            >
              {user_contract_type()}
            </p>
          </div>
          <div className={styles.Item}>
            <p className={styles.ItemTitle}>
              {t('ad_settings_titles:by_mobile_data_used')}
            </p>
            <p
              className={`${styles.ItemText} ${
                checkForUnsegmentedAd(spender_type()) ? 'italic' : ''
              }`}
            >
              {spender_type()}
            </p>
          </div>
          <div className={styles.Item}>
            <p className={styles.ItemTitle}>
              {t('ad_settings_titles:urban_or_rural')}
            </p>
            <p
              className={`${styles.ItemText} ${
                checkForUnsegmentedAd(territory_type()) ? 'italic' : ''
              }`}
            >
              {territory_type()}
            </p>
          </div>
        </div>
        <div className={styles.ItemsColumn}>
          <div className={styles.Item}>
            <p className={styles.ItemTitle}>
              {t('ad_settings_titles:type_of_code')}
            </p>
            <p
              className={`${styles.ItemText} ${
                checkForUnsegmentedAd(code_type()) ? 'italic' : ''
              }`}
            >
              {code_type()}
            </p>
          </div>
          <div className={styles.Item}>
            <p className={styles.ItemTitle}>
              {t('ad_settings_titles:code_type_shown_in_ad')}
            </p>
            <p
              className={`${styles.ItemText} ${
                checkForUnsegmentedAd(code_type()) ? 'italic' : ''
              }`}
            >
              {code_type_shown_in_ad()}
            </p>
          </div>

          {code_type() === 'Isti kod za sve korisnike' ? null : (
            <div className={styles.Item}>
              <DownloadCSVTable
                adId={adDetails.id}
                partnerId={adDetails.partner_id}
                reference={adDetails.ref}
                unUsed={true}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
});
