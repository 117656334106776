import React from 'react';
import styles from './TariffPackages.module.scss';
import infoIcon from '../../../../assets/icons/info_icon.png';
import clockIcon from '../../../../assets/icons/clock_icon.png';
import { Tooltip } from 'react-tippy';
import { Button } from '../../../UI/buttons/regular/Button';
import { observer } from 'mobx-react';
import { useMst } from '../../../../internal';
import checkmarkIcon from '../../../../assets/icons/checkmark_icon.png';
import { isAfter } from 'date-fns';
import { SUBSCRIPTION_WITHOUT_CONTRACT } from '../../../../utils/constants';

export const MonthlyPayment = observer(() => {
  const {
    currentPage: {
      setPackageName,
      getConfirmSendRequestModal,
      isSubscriptionWithNoContractRequested,
      getRequestTariffChangeButton,
      isRequestSubmitted,
    },
    data: {
      isUnderContractObligation,
      isMonthly,
      monthlyPaymentCatalog,
      monthlyPaymentCatalogPrice,
    },
  } = useMst();

  const requestPackageChange = () => {
    setPackageName('subscription');
    getConfirmSendRequestModal().setOpened(true);
  };

  return (
    <>
      <div className={styles.Text}>
        <h5 className={styles.Title}>{SUBSCRIPTION_WITHOUT_CONTRACT}</h5>
        <p>
          Mesečni tarifni paketi bez ugovorne obaveze su idealni za korisnike
          koji žele da koriste pun potecijal naših servisa bez ugovore obaveze
          sa mogućnošću da se lako prebace na plaćanje po kampanji ili na
          povoljnije plaćanje sa ugovornom obavezom.
        </p>
      </div>

      <div
        className={`${styles.MonthlyPaymentContent}  ${
          isMonthly && !isUnderContractObligation && styles.ActiveBorder
        }`}
      >
        <div className={styles.Box1}>
          <div className={styles.Content}>
            <p className={styles.Title}>Osnovno oglašavanje</p>
            <p className={styles.TitleHeading}>Neograničeno</p>
          </div>
        </div>
        <div className={styles.Box2}>
          <div className={styles.Content}>
            <div className={styles.TitleWrapper}>
              <p className={styles.Title}>Dodatne opcije</p>
            </div>
            <div className={styles.FlexContent}>
              <p className={styles.TitleHeading}>
                Neograničena segmentacija
                <span className={styles.TitleAddition}>
                  + 5 top oglasa /mes.
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className={styles.Box3}>
          <div className={styles.Content}>
            <div className={styles.TitleWrapper}>
              <p className={styles.Title}>Mogući popusti</p>
              <Tooltip
                theme={'dark'}
                html={
                  <div style={{ padding: '0.2em' }}>
                    <span>
                      Kontaktirajte Vašeg partneršip eksperta za ostvarivanje
                      ovih popusta
                    </span>
                  </div>
                }
                position="top"
              >
                <img src={infoIcon} alt="info" />
              </Tooltip>
            </div>
            <div className={styles.TextContent}>
              <p className={styles.Description}>
                Popust za Yettel korisnike{' '}
                <span style={{ fontWeight: 'bold' }}>
                  {monthlyPaymentCatalog?.discount_telco}%
                </span>
              </p>
            </div>
          </div>
        </div>
        <div
          className={`${
            isSubscriptionWithNoContractRequested
              ? styles.Box4RequestSent
              : styles.Box4
          } ${isMonthly && !isUnderContractObligation && styles.Active}`}
        >
          {isMonthly && !isUnderContractObligation ? (
            <div className={styles.ActivePackage}>
              <img src={checkmarkIcon} alt="check" />
              <p>Aktivan paket</p>
            </div>
          ) : (
            <>
              <div className={styles.Prices}>
                <div className={styles.Values}>
                  <p className={styles.CrossedPrice}>
                    {monthlyPaymentCatalog.subscription_price}
                  </p>
                  <p className={styles.NewPriceValue}>
                    {monthlyPaymentCatalogPrice}
                  </p>
                </div>
                <div className={styles.Currencies}>
                  <div className={styles.OldPriceCurrency}>
                    <span className={styles.Rsd}>RSD</span>
                    <span>mes.</span>
                  </div>
                  <div className={styles.NewPriceCurrency}>
                    <span className={styles.Rsd}>RSD</span>
                    <span>mes.</span>
                  </div>
                </div>
              </div>
              {isRequestSubmitted || isMonthly ? null : (
                <div className={styles.Button}>
                  <Button
                    model={getRequestTariffChangeButton()}
                    onClick={requestPackageChange}
                    disabledTooltipMessage={
                      'Način naplate je moguće promeniti jednom mesečno.'
                    }
                    customButtonStyles={{
                      height: '48px',
                      width: '168px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  />
                </div>
              )}
              {isSubscriptionWithNoContractRequested ? (
                <div className={styles.WaitingForApproval}>
                  <img src={clockIcon} alt="waiting for approval" />
                  <span>Čeka na odobrenje</span>
                </div>
              ) : null}
            </>
          )}
        </div>
      </div>
    </>
  );
});
