import { observer } from 'mobx-react';
import * as React from 'react';
import { Smartphone } from '../../UI/smartphone/Smartphone';
import { Header } from './header/Header';
import { Footer } from './footer/Footer';
import styles from './OfferDetailsPreview.module.scss';
import { formattedTime } from '../../../utils/date';
import { SliderSection } from './sections/slider-section/SliderSection';
import { InfoSection } from './sections/info-section/InfoSection';
import { DescriptionSection } from './sections/description-section/DescriptionSection';
import { TermsSection } from './sections/terms-section/TermsSection';
// import { NotesSection } from './sections/notes-section/NotesSection';
import { SellerInfoSection } from './sections/seller-info-section/SellerInfoSection';
import { Show } from '../../UI/show/Show';
import { TopOffer } from './offer-types/top-offer/TopOffer';
import { Placeholder } from './placeholder/Placeholder';
import { FeaturedOffer } from './offer-types/featured-offer/FeaturedOffer';
import { StandardOffer } from './offer-types/standard-offer/StandardOffer';
import { FEATURED, STANDARD, TOP } from '../../../constants';

export interface OfferPreview {
  discountDescription: string;
  imagesArray: string[];
  partner: {
    name: string;
    phone: string;
    web: string;
    email: string;
  };
  name: string;
  description: string;
  conditions: string;
  currency: string;
  isWithoutVoucher: boolean;
  offerType: string;
  partnerName: string;
  loweredPrice: number;
  regularPrice: number;
  shouldDisplayShowStoresButton: boolean;
  expiresIn: Date;
  labelText: string;
  labelColor: string;
}

export const OfferDetailsPreview = observer(
  (props: { preview: OfferPreview }): JSX.Element => {
    const [time, setTime] = React.useState(new Date());

    React.useEffect(() => {
      const interval = setInterval(() => setTime(new Date()), 1000);
      return () => {
        clearInterval(interval);
      };
    }, []);

    const { preview } = props;

    return (
      <Smartphone>
        <div className={styles.PhoneHeader}>
          <div className={styles.Time}>{formattedTime(time)}</div>
          <div className={styles.PhoneData}>
            <div className={styles.Signal}></div>
            <div className={styles.Wifi}></div>
            <div className={styles.Battery}></div>
          </div>
        </div>
        <Header model={preview} />
        <div className={styles.MainContent}>
          <Show condition={preview.isWithoutVoucher}>
            {preview.offerType === TOP && <TopOffer model={preview} />}
            {preview.offerType === FEATURED && (
              <FeaturedOffer model={preview} />
            )}
            {preview.offerType === STANDARD && (
              <StandardOffer model={preview} />
            )}
            <Placeholder />
            <Placeholder />
            <Placeholder />
          </Show>
          <Show condition={!preview.isWithoutVoucher}>
            <SliderSection model={preview} />
            <InfoSection model={preview} />
            <DescriptionSection model={preview} />
            <TermsSection model={preview} />
            {/*notes section is removed for this release*/}
            {/*<NotesSection />*/}
            <SellerInfoSection model={preview} />
          </Show>
        </div>
        <Footer />
      </Smartphone>
    );
  }
);
