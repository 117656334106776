import { getRoot, IAnyModelType, types } from 'mobx-state-tree';
import {
  CheckboxGroupModel,
  CheckboxModelType,
  CreateOfferPageModel,
  DropdownModel,
  DropdownOptionModel,
  DropdownOptionModelType,
  DropdowOptionGroupsModel,
  ModalModel,
  PickStoresModel,
  RootType,
  RoundButtonGroupModel,
  RoundButtonModel,
} from '../../../../internal';
import { getCategoryIdFromSubcategory } from '../../../../utils/methods';
const ALL = 'Sve';

export const OfferPickersModel: IAnyModelType = types
  .model({
    parent: types.maybe(
      types.reference(types.late(() => CreateOfferPageModel))
    ),
    categoriesConfig: types.frozen<any>({}),
    subcategoriesConfig: types.frozen<any>({}),
  })
  .views((self) => {
    return {
      get root(): RootType {
        return getRoot(self);
      },
    };
  })
  .actions((self) => {
    return {
      setOfferLinkRequired(val: boolean) {
        const linkUrlField = self?.parent?.getOfferLinkUrl?.();
        linkUrlField?.setIsRequired?.(val);
        if (!val) {
          linkUrlField?.setCurrentMessage?.('');
        }
      },
      getOfferTypeContainerModel() {
        return (
          self?.parent?.components.get('OfferTypeContainer') ||
          self?.parent?.addComponent(
            RoundButtonGroupModel.create({
              id: 'OfferTypeContainer',
              step: 1,
              buttons: {
                OfferWithVoucher: RoundButtonModel.create({
                  id: 'OfferWithVoucher',
                  label: 'basic:offer_with_voucher',
                }),
                OfferWithoutVoucher: RoundButtonModel.create({
                  id: 'OfferWithoutVoucher',
                  label: 'basic:offer_without_voucher',
                }),
              },
            })
          )
        );
      },
      getPackagePickModel() {
        return (
          self?.parent?.components.get('PackagePick') ||
          self?.parent?.addComponent(
            DropdownModel.create({
              id: 'PackagePick',
              label: 'basic:choose_package',
              isRequired: true,
              step: 1,
              options: {},
              disabled: true,
              onChangeCallback: (option: DropdownOptionModelType) => {
                // Temporary meassure, see LOY-393
                // self?.parent?.managePackagePick();
              },
            })
          )
        );
      },
      // Temporary logic see LOY-393
      getOfferTypeModel() {
        return (
          self?.parent?.components.get('OfferType') ||
          self?.parent?.addComponent(
            DropdownModel.create({
              id: 'OfferType',
              label: 'basic:offer_type',
              isRequired: false,
              initialValue: 'segmented',
              options: {
                segmented: DropdownOptionModel.create({
                  id: 'segmented',
                  value: 'dropdown:segmented_offer',
                }),
                unsegmented: DropdownOptionModel.create({
                  id: 'unsegmented',
                  value: 'dropdown:unsegmented_offer',
                }),
              },
              onChangeCallback: () => {
                self?.parent?.manageOfferTypePick();
              },
            })
          )
        );
      },
      getYettelSegments() {
        return (
          self?.parent?.components.get('YettelSegments') ||
          self?.parent?.addComponent(
            DropdownModel.create({
              id: 'YettelSegments',
              label: 'basic:yettel_segments',
              isRequired: false,
              options: {
                'non-yettel': DropdownOptionModel.create({
                  id: '',
                  value: 'dropdown:none',
                }),
                biznis: DropdownOptionModel.create({
                  id: 'biznis',
                  value: 'dropdown:business',
                }),
                prepaid: DropdownOptionModel.create({
                  id: 'prepaid',
                  value: 'dropdown:prepaid',
                }),
                postpaid: DropdownOptionModel.create({
                  id: 'postpaid',
                  value: 'dropdown:postpaid',
                }),
              },
            })
          )
        );
      },
      getLocationPickModel() {
        return (
          self?.parent?.components.get('LocationPick') ||
          self?.parent?.addComponent(
            DropdownModel.create({
              id: 'LocationPick',
              label: 'basic:valid_locations',
              isRequired: true,
              step: 1,
              onChangeCallback: () => {
                if (self?.parent?.directLinkToSiteShouldBeRequired) {
                  this.setOfferLinkRequired(true);
                } else {
                  this.setOfferLinkRequired(false);
                }
              },
              options: {
                1: DropdownOptionModel.create({
                  id: 'all',
                  value: 'dropdown:all_physical_and_online_stores',
                }),
                2: DropdownOptionModel.create({
                  id: 'online',
                  value: 'dropdown:just_online_stores',
                }),
                3: DropdownOptionModel.create({
                  id: 'offline',
                  value: 'dropdown:just_all_physical_stores',
                }),
                4: DropdownOptionModel.create({
                  id: 'selected',
                  value: 'dropdown:choose_individual_physical_stores',
                }),
                5: DropdownOptionModel.create({
                  id: 'online-sel',
                  value: 'dropdown:choose_both_online_and_physical_stores',
                }),
              },
            })
          )
        );
      },
      setCategoriesConfig(categoriesConfig: any) {
        self.categoriesConfig = categoriesConfig;
      },

      setSubCategoriesConfig(subcategoriesConfig: any) {
        self.subcategoriesConfig = subcategoriesConfig;
      },

      getCategoryAndSubcategoryPickModel() {
        const subcategories: any = {};
        let indexSubcategory = 0;
        Object.keys(self?.categoriesConfig).forEach(function (key) {
          subcategories[indexSubcategory] = {
            id: `${self?.categoriesConfig[key].id}_all`,
            categoryId: self?.categoriesConfig[key].id,
            label: ALL,
          };
          indexSubcategory++;
          self?.categoriesConfig[key].subcategories.map((subcategory: any) => {
            subcategories[indexSubcategory] = {
              id: `${self?.categoriesConfig[key].id}_${subcategory.id}`,
              categoryId: self?.categoriesConfig[key].id,
              label: subcategory.name,
            };
            indexSubcategory++;
          });
        });

        this.setSubCategoriesConfig(subcategories);

        return (
          self?.parent?.components.get('CategoryPick') ||
          self?.parent?.addComponent(
            DropdowOptionGroupsModel.create({
              id: 'CategoryPick',
              isOpen: false,
              optionGroups: CheckboxGroupModel.create({
                id: 'category_nameDropdownOptionGroups',
                checkboxes: {
                  ...self.categoriesConfig,
                },
                onCheck: () => {
                  this.setOptionsForCategoryPick();
                },
                onUncheck: () => {
                  this.setOptionsForCategoryPick();
                },
              }),
              options: CheckboxGroupModel.create({
                id: 'subcategory_nameDropdownOptions',
                checkboxes: {},
                onCheck: (nestedCheckbox: CheckboxModelType) => {
                  const disabledSubcategoriesIds: any = [];
                  const categoryId = getCategoryIdFromSubcategory(
                    nestedCheckbox.id
                  );
                  const categoryIdAll = `${categoryId}_all`;
                  const categoryPick =
                    self?.parent?.components.get('CategoryPick');
                  const allSubcategoryFound =
                    categoryPick.selectedOptionsArray.includes(categoryIdAll);
                  const allSubcategoryLength =
                    categoryPick.selectedOptionsArray.length;

                  if (allSubcategoryLength > 2) {
                    categoryPick.setDisabledOptions();
                    categoryPick.setDisabledOptionGroups();
                  }

                  if (nestedCheckbox.label === ALL || allSubcategoryFound) {
                    Object.keys(self?.subcategoriesConfig).forEach(function (
                      key
                    ) {
                      if (
                        self?.subcategoriesConfig[key].categoryId ===
                          categoryId &&
                        categoryIdAll !== self?.subcategoriesConfig[key].id
                      ) {
                        disabledSubcategoriesIds.push(
                          self?.subcategoriesConfig[key].id
                        );
                      }
                      if (
                        self?.subcategoriesConfig[key].categoryId ===
                          categoryId &&
                        categoryIdAll !== self?.subcategoriesConfig[key].id
                      ) {
                        categoryPick.options.uncheck(
                          self?.subcategoriesConfig[key]
                        );
                      }
                    });

                    categoryPick.setDisabledOptionsInsideOptionGroup(
                      disabledSubcategoriesIds
                    );
                  }
                },
                onUncheck: (nestedCheckbox: CheckboxModelType) => {
                  const categoryId = getCategoryIdFromSubcategory(
                    nestedCheckbox.id
                  );
                  const categoryIdAll = `${categoryId}_all`;
                  const categoryPick =
                    self?.parent?.components.get('CategoryPick');

                  Object.keys(self?.subcategoriesConfig).forEach(function (
                    key
                  ) {
                    if (
                      self?.subcategoriesConfig[key].categoryId ===
                        categoryId &&
                      !categoryPick.selectedOptionsArray.includes(categoryIdAll)
                    ) {
                      categoryPick.setEnabledOptions();
                      categoryPick.setEnabledOptionGroups();
                    }
                  });
                },
              }),
            })
          )
        );
      },

      setOptionsForCategoryPick() {
        const selectedCategorySubcategories: any = [];
        const categoryPick = self?.parent?.components.get('CategoryPick');
        Object.keys(self?.subcategoriesConfig).forEach(function (key) {
          if (
            categoryPick.selectedOptionGroupsArray.includes(
              self?.subcategoriesConfig[key].categoryId
            )
          ) {
            selectedCategorySubcategories.push(self?.subcategoriesConfig[key]);
          }
        });
        categoryPick.setOptions(selectedCategorySubcategories);
      },

      handleNewSelectionCategoriesAndSubcategoriesPick(
        selectedOptionGroupsIds: string[],
        selectedOptionsIds: string[]
      ) {
        const categoryPick = self?.parent?.components.get('CategoryPick');
        selectedOptionGroupsIds.forEach((selectedOptionGroupsId) => {
          Object.keys(self?.categoriesConfig).forEach(function (key) {
            if (self?.categoriesConfig[key].id === selectedOptionGroupsId) {
              categoryPick.optionGroups.check(self?.categoriesConfig[key]);

              selectedOptionsIds.forEach((selectedOptionsId: any) => {
                if (
                  selectedOptionsId.includes(self?.categoriesConfig[key].id)
                ) {
                  Object.keys(self?.subcategoriesConfig).forEach(function (
                    key
                  ) {
                    if (
                      self?.subcategoriesConfig[key].id === selectedOptionsId
                    ) {
                      categoryPick.options.check(
                        self?.subcategoriesConfig[key]
                      );
                    }
                  });
                }
              });
            }
          });
        });
      },

      getCategoryPickModel() {
        return (
          self?.parent?.components.get('CategoryPick') ||
          self?.parent?.addComponent(
            DropdownModel.create({
              id: 'CategoryPick',
              label: 'basic:product_category',
              isRequired: true,
              step: 1,
              options: {},
              onChangeCallback: (option: DropdownOptionModelType) => {
                self?.parent?.root.api.subcategory
                  .getAllSubcategories(option.id)
                  .then((response: any) => {
                    if (self.root.router.isCreateOfferPage) {
                      self.root.currentPage.handleSubCategories(response);
                    }
                    this?.getSubCategoryPickModel()?.runValidators();
                  });
              },
            })
          )
        );
      },
      getSubCategoryPickModel() {
        return (
          self?.parent?.components.get('SubCategoryPick') ||
          self?.parent?.addComponent(
            DropdownModel.create({
              id: 'SubCategoryPick',
              label: 'basic:product_subcategory',
              isRequired: false,
              step: 1,
              options: {},
            })
          )
        );
      },

      getPickStoresModal() {
        return (
          self?.parent?.components.get('PickStores') ||
          self?.parent?.addComponent(
            PickStoresModel.create({
              id: 'PickStores',
              searchValue: '',
              allStores: [],
              modal: ModalModel.create({
                id: 'PickStoresModal',
                label: 'Pick Stores',
                opened: false,
                showCloseButton: true,
              }),
              checkboxContainer: CheckboxGroupModel.create({
                id: 'StorePicker',
                checkboxes: {},
              }),
            })
          )
        );
      },
    };
  })
  .named('OfferPickersModel');
