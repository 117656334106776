import axios, { AxiosInstance } from 'axios';
// import { config } from "../config/env";

const AUTH_BASE_URL =
  // process.env.REACT_APP_AUTH_BASE_URL || 'http://www1.yettel.rs:21080';
  process.env.REACT_APP_AUTH_BASE_URL ||
  'https://shopping1.yettel.rs/feature1/partner/api';

export const axiosInstance = (
  token?: string,
  captcha?: string
): AxiosInstance => {
  const config: {
    baseURL: string;
    headers: {
      'Content-Type'?: string;
      Authorization?: string;
    };
  } = {
    baseURL: AUTH_BASE_URL,
    headers: {
      'Content-Type': 'application/json',
    },
  };
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  if (captcha) {
    (config.headers as any)['captcha-value'] = captcha;
  }
  return axios.create(config);
};

export const axiosEncodedInstance = (token?: string): AxiosInstance => {
  const config: {
    baseURL: string;
    headers: {
      'Content-Type'?: string;
      Authorization?: string;
    };
  } = {
    baseURL: AUTH_BASE_URL,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return axios.create(config);
};
