import { observer } from 'mobx-react';
import { TagGroupModelType, TagModelType } from '../../../internal';
import { Tag } from './Tag';
import styles from './TagGroup.module.scss';

export const TagGroup = observer(
  (props: { model: TagGroupModelType; id?: string }): JSX.Element => {
    const { model, id } = props;
    const styleCondition = (index: number) => {
      return (
        model.maxNumber !== undefined &&
        index >= model.maxNumber &&
        !model.isDisabled // all disabled tags look alike
      );
    };
    return (
      <div className={styles.Container} id={id}>
        {model.tagsArray.map((tag: TagModelType, index: number) => {
          return (
            <Tag
              style={styleCondition(index) ? { background: '#c9c9c9' } : {}}
              key={index}
              model={tag}
            />
          );
        })}
      </div>
    );
  }
);
