import { ReactElement } from 'react';
import styles from './AdministrationPage.module.scss';
import { useTranslation } from 'react-i18next';
import { AdministrationTable } from '../../../components/domain/administration/AdministrationTable';
import { useMst } from '../../../internal';
import { AddAccountModal } from '../../../components/domain/administration/add-account/AddAccountModal';
import { ADMINISTRATOR, MODERATOR, SELLER } from '../../../constants/roleTypes';
import { ConfirmationModal } from '../../../components/UI/confirmation-modal/ConfirmationModal';
import * as React from 'react';
import { observer } from 'mobx-react';

export const AdministrationPage = observer((): ReactElement => {
  const { t } = useTranslation();
  const {
    currentPage,
    user: { isSalesman, isAdministrator },
    data: { administrators, moderators, sellers },
  } = useMst();

  return (
    <div className={styles.PageContainer}>
      <div className={styles.PageTitleContainer}>
        <div className={`${styles.PageTitle} ${styles.NoTabs}`}>
          {t('navigation:administration')}
        </div>
      </div>
      <div className={styles.AdministrationOuterContainer}>
        <AdministrationTable
          type={ADMINISTRATOR}
          editable={isAdministrator}
          tableTitle={t('administration:administrators')}
          data={administrators}
          btnText={t('administration:add_administrator')}
        />
        <AdministrationTable
          type={MODERATOR}
          editable={!isSalesman}
          tableTitle={t('administration:moderators')}
          data={moderators}
          btnText={t('administration:add_moderator')}
        />
        <AdministrationTable
          type={SELLER}
          editable={!isSalesman}
          tableTitle={t('administration:sellers')}
          data={sellers}
          btnText={t('administration:add_seller')}
        />
      </div>
      <AddAccountModal model={currentPage.getAddAccountModal()} />
      <ConfirmationModal
        model={currentPage.getDeleteAccountModal()?.deleteAccountModal}
      />
    </div>
  );
});
