import { Instance, types } from 'mobx-state-tree';
import { ComponentModel, ModalModel } from '../../../internal';

export const ApproveRejectAdModel = types
  .compose(
    ComponentModel,
    types.model({
      modal: types.late(() => ModalModel),
      isRejected: false,
      isApproved: false,
      isStopped: false,
    })
  )
  .actions((self) => {
    return {
      openModal() {
        self.modal.setOpened(true);
      },
      setApproved() {
        self.isApproved = true;
        self.isRejected = false;
        self.isStopped = false;
      },
      setRejected() {
        self.isRejected = true;
        self.isApproved = false;
        self.isStopped = false;
      },
      setStopped() {
        self.isStopped = true;
        self.isRejected = false;
        self.isApproved = false;
      },
    };
  })
  .views((self) => {
    return {};
  })
  .named('ApproveRejectAdModel');

export type ApproveRejectAdModelType = Instance<typeof ApproveRejectAdModel>;
