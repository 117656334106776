import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { AdditionalUsers } from '../../../../components/domain/additional-users/AdditionalUsers';
import { BuyAdditionalPackageModal } from '../../../../components/domain/buy-additional-package-modal/BuyAdditionalPackageModal';
import { ClearCodesWarning } from '../../../../components/domain/clear-codes-warning/ClearCodesWarning';
import { UploadCodes } from '../../../../components/domain/upload-codes/UploadCodes';
import { UploadModal } from '../../../../components/domain/upload-codes/UploadModal';
import { AdditionalLocation } from '../../../../components/UI/additionallocations/AdditionalLocation';
import { LightButton } from '../../../../components/UI/buttons/light-button/LightButton';
import { Button } from '../../../../components/UI/buttons/regular/Button';
import { CheckboxGroup } from '../../../../components/UI/checkbox/CheckboxGroup';
import { Dropdown } from '../../../../components/UI/dropdown/Dropdown';
import { DropdownWithSearch } from '../../../../components/UI/dropdown/dropdown-with-search/DropdownWithSearch';
import { SectionMessage } from '../../../../components/UI/section/message/SectionMessage';
import { Section } from '../../../../components/UI/section/Section';
import { Show } from '../../../../components/UI/show/Show';
import { TagGroup } from '../../../../components/UI/tag/TagGroup';
import { SEGMENTED } from '../../../../constants';
import { useMst } from '../../../../internal';
import { templateReplace } from '../../../../utils/template';
import styles from '../CreateOffer.module.scss';

export const SecondStep = observer((): JSX.Element => {
  const { currentPage } = useMst();
  const { t } = useTranslation();
  const updateLocationText = () => {
    let locationText = '';
    if (currentPage.getLocationTagGroup().length === 1) {
      locationText = t('basic:added_location');
    } else if (
      currentPage.getLocationTagGroup().length === 2 ||
      currentPage.getLocationTagGroup().length === 3 ||
      currentPage.getLocationTagGroup().length === 4
    ) {
      locationText = t('basic:added_location_sr');
    } else {
      locationText = t('basic:added_locations');
    }
    return templateReplace(
      locationText,
      'x',
      `${currentPage.getLocationTagGroup().length}`
    );
  };

  return (
    <>
      <div className={styles.FormContainer}>
        <Section model={currentPage.getAdTypeSection()}>
          <div className={styles.FormContent}>
            <div className={styles.PackageContainer}>
              <div className={styles.SelectContainer}>
                <Dropdown model={currentPage.getOfferTypeModel()} />
              </div>
            </div>
          </div>
        </Section>
        <Section model={currentPage.getTargetSection()}>
          <div
            className={clsx(
              styles.FormContent,
              currentPage.getOfferTypeModel().value !== SEGMENTED &&
                styles.DisabledForm
            )}
          >
            <div className={styles.UserLocationContainer}>
              <div className={styles.UserLocationDropdownContainer}>
                <span className={styles.SectionTitle}>1. Izbor lokacija</span>
                <DropdownWithSearch
                  model={currentPage.getUserLocationDropdown()}
                />
              </div>
              <TagGroup model={currentPage.getLocationTagGroup()} />
            </div>
            <div className={styles.AdditionalLocationsContainer}>
              <Show condition={currentPage.getLocationTagGroup().maxExceeded}>
                <AdditionalLocation
                  maxNumber={currentPage.getLocationTagGroup().maxNumber}
                  model={currentPage.getAdditionalLocation()}
                />
              </Show>
            </div>
            <div className={styles.CenteredContent}>
              {/**Temporary addition, see LOY-393 */}
              <>
                <Show
                  condition={
                    !currentPage.getLocationPickSection().editMode &&
                    currentPage.getLocationTagGroup().length
                  }
                >
                  <div className={styles.CenteredContentChange}>
                    <div className={styles.Left}>
                      <SectionMessage content={updateLocationText()} />
                    </div>
                  </div>
                </Show>
              </>
            </div>
          </div>
          <div
            className={clsx(
              styles.FormContent,
              currentPage.getOfferTypeModel().value !== SEGMENTED &&
                styles.DisabledForm
            )}
          >
            <span className={styles.SectionTitle}>2. Ciljna grupa</span>
            <div className={styles.OfferSegmentsContainer}>
              <div className={styles.UserSegmentsContainer}>
                <div className={styles.RegularLabel}>
                  {t('user_segments:pick_gender')}
                </div>
                <Dropdown model={currentPage.getGenderDropdown()} />
              </div>
              <div className={styles.UserSegmentsContainer}>
                <div className={styles.RegularLabel}>
                  {t('user_segments:age')}
                </div>
                <div className={styles.AgeContainer}>
                  <Dropdown model={currentPage.getFromAge()} />
                  <Dropdown model={currentPage.getToAge()} />
                </div>
              </div>
              {/**Temporary measure, loy-394 */}
              <div className={styles.UserSegmentsContainer}>
                <div className={styles.RegularLabel}>
                  {t('user_segments:urban_or_rural_area')}
                </div>
                <Dropdown model={currentPage.getAreaDropdown()} />
              </div>
              <div
                className={styles.UserSegmentsContainer}
                style={{ display: 'none' }}
              >
                <div className={styles.RegularLabel}>
                  {t('user_segments:user_picked_similar_offers')}
                </div>
                <Dropdown
                  model={currentPage.getUsersPickedSimilarOffersDropdown()}
                />
              </div>
            </div>
            <div className={styles.OfferSegmentsContainer}>
              <div className={styles.UserSegmentsContainer}>
                <div className={styles.UserSegmentsLabel}>
                  {`${t('user_segments:user_segments')}`}
                  <span className={styles.Required}>*</span>
                </div>
                <CheckboxGroup model={currentPage.getUserSegments()} />
              </div>

              <div className={styles.UserSegmentsContainer}>
                <div className={styles.UserSegmentsLabel}>
                  {t('user_segments:mobile_network_spending')}
                </div>
                <CheckboxGroup model={currentPage.getMobileNetworkSpending()} />
              </div>
            </div>
            <div className={styles.AdditionalUsersContainer}>
              <Show condition={currentPage.shouldShowAdditionalUsers}>
                <AdditionalUsers model={currentPage.getAdditionalUsers()} />
              </Show>
            </div>
            <div className={styles.CenteredContent}>
              {/* temporary addition, see LOY-393 */}
              <>
                <Show condition={!currentPage.getTargetSection().editMode}>
                  <div className={styles.CenteredContentChange}>
                    <div className={styles.Left}>
                      <SectionMessage
                        content={currentPage.getTargetSection().message}
                      />
                    </div>
                    <LightButton
                      id={'editButton'}
                      model={currentPage.getTargetGroupEdit()}
                    />
                  </div>
                </Show>
                {currentPage.getOfferTypeModel().value !== SEGMENTED && (
                  <div>
                    <LightButton
                      model={currentPage.getBuyTargeting()}
                      isYellow
                    />
                  </div>
                )}
              </>
            </div>
          </div>
        </Section>
      </div>

      <Show condition={!currentPage.isWithoutVoucher}>
        <div className={styles.FormContainer}>
          <Section model={currentPage.getEnterCodeSection()}>
            <div className={styles.FormContent}>
              <div className={styles.UploadCodesContainer}>
                <UploadCodes model={currentPage.getEnterCodesModel()} />
                <UploadModal model={currentPage.getEnterCodesModel()} />
              </div>
            </div>
          </Section>
        </div>
      </Show>
      <ClearCodesWarning model={currentPage.getClearCodesWarning()} />
      <BuyAdditionalPackageModal
        model={currentPage.getBuyAdditionalPackage()}
      />
    </>
  );
});
