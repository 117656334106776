import { observer } from 'mobx-react';
import {
  AdditionalPackageModelType,
  BuyAdditionalPackageModalModelType,
} from '../../../internal';
import { Modal } from '../../UI/modal/Modal';
import { AdditionalPackage } from '../company-profile/package-selection/additional-package/AdditionalPackage';
import styles from './BuyAdditionalPackageModal.module.scss';

export const BuyAdditionalPackageModal = observer(
  (props: { model: BuyAdditionalPackageModalModelType }) => {
    const { model } = props;
    return (
      <Modal
        styles={{
          content: {
            top: '2.5%',
            width: '80%',
            maxHeight: 'fit-content',
            borderRadius: '20px',
            padding: '40px',
          },
        }}
        model={model.modal}
      >
        <div className={styles.Container}>
          <div className={styles.AdditionalPackagesContent}>
            {model.additionalPackagesArray.map(
              (elem: AdditionalPackageModelType, index: number) => {
                return <AdditionalPackage key={index} model={elem} />;
              }
            )}
          </div>
        </div>
      </Modal>
    );
  }
);
