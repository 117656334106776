import { observer } from 'mobx-react';
import * as React from 'react';
import { Show } from '../../show/Show';
import styles from './SectionMessage.module.scss';

export const SectionMessage = observer(
  (props: { content: string }): JSX.Element => {
    const content = props.content;
    return (
      <Show condition={content}>
        <div className={styles.MessageContainer}>
          <div className={styles.Checkmark} />
          <div className={styles.MessageText}>{content}</div>
        </div>
      </Show>
    );
  }
);
