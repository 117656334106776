import { observer } from 'mobx-react';
import { Modal } from '../../UI/modal/Modal';
import { Button } from '../../UI/buttons/regular/Button';
import styles from './ConfirmRequestSentModal.module.scss';
import { LightButton } from '../../UI/buttons/light-button/LightButton';
import { useMst } from '../../../internal';

export const ConfirmRequestSentModal = observer((props: any) => {
  const { currentPage } = useMst();

  const onConfirm = () => {
    currentPage.getConfirmSendRequestModal().setOpened(false);
    currentPage.getSendRequestModal().setOpened(true);
    currentPage.requestNewTariffPackage(currentPage.packageName);
  };

  const onDeny = () => {
    currentPage.getConfirmSendRequestModal().setOpened(false);
  };
  return (
    <Modal
      styles={{
        overlay: {
          backgroundColor: 'rgba(0, 35, 63, 0.5)',
          border: '1px solid #707070',
        },
        content: {
          opacity: '1',
        },
      }}
      model={currentPage.getConfirmSendRequestModal()}
    >
      <div className={styles.ModalContent}>
        <p>Da li se sigurni da želite da pošaljete zahtev za promenu paketa?</p>
        <div className={styles.Button}>
          <LightButton
            model={{
              id: 'da',
              label: 'DA',
              isEnabledComputed: true,
            }}
            onClick={onConfirm}
          />
          <Button
            model={{
              id: 'ne',
              label: 'NE',
              isEnabledComputed: true,
            }}
            onClick={onDeny}
          />
        </div>
      </div>
    </Modal>
  );
});
