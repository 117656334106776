import * as React from 'react';
import { observer } from 'mobx-react';

import { useTranslation } from 'react-i18next';
import expirationStyles from './ExpirationInfo.module.scss';
import expiration from '../../../assets/icons/icon-clock.svg';
import {
  isValidForFiveOrLessDays,
  isValidForSixToTenDays,
  isValidForLessThanOneDay,
  getExpiration,
} from '../../../utils/methods';
import { ExpirationCountdownComponent } from './expiration-countdown/ExpirationCountdown';
import { differenceInSeconds } from 'date-fns';

interface IOfferModel {
  model: { expiresIn: Date };
}

export const ExpirationInfo = observer((props: IOfferModel) => {
  const { model } = props;
  const { t } = useTranslation();
  const expiresInADay = isValidForLessThanOneDay(model.expiresIn);

  const isExpired = () => {
    const currentTime = new Date().getTime();
    if (currentTime && model.expiresIn) {
      return differenceInSeconds(model.expiresIn, currentTime) < 1;
    }
    return false;
  };
  return (
    <div
      className={`${expirationStyles.ExpirationInfo} ${
        isValidForFiveOrLessDays(model.expiresIn) && expirationStyles.Danger
      } ${isValidForSixToTenDays(model.expiresIn) && expirationStyles.Warning}`}
    >
      <div className={`${expirationStyles.ExpirationStringWrapper}`}>
        <img
          className={expirationStyles.ExpirationIcon}
          src={expiration}
          alt={'expiration'}
        />

        {isExpired() ? (
          <span className={`${expirationStyles.CountdownExpirationText}`}>
            {t('offer:offer_has_expired_text')}
          </span>
        ) : (
          <div className={expirationStyles.InfoLine}>
            <span className={`${expirationStyles.ExpirationText}`}>
              {`važi još ${getExpiration(model.expiresIn)}`}
              {!!model.expiresIn && expiresInADay && (
                <ExpirationCountdownComponent model={model} />
              )}
            </span>
          </div>
        )}
      </div>
    </div>
  );
});
export default ExpirationInfo;
