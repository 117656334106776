import * as React from 'react';
import { observer } from 'mobx-react';
import { dateSRLocale } from '../../../../utils/date';
import { useTranslation } from 'react-i18next';
import { Switch } from '../../../../components/UI/switch/Switch';
import { BannersListElementModelType } from '../../../../internal';
import CHEVRON_DOWN from '../../../../assets/icons/chevron-down.svg';
import styles from './BannersListElement.module.scss';
import EmptyImage from '../../../../assets/icons/empty.svg';

export const BannersListElement = observer(
  (props: {
    model: BannersListElementModelType;
    openBannerDetailsPage: (id: number) => void;
    currentPage: any;
    index: number;
  }): JSX.Element => {
    const { model, currentPage } = props;
    const { t } = useTranslation();

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      currentPage.toggleActive(model.id, e.target.checked);
    };

    return (
      <div
        className={styles.Container}
        style={{
          borderRightColor: model.isActive
            ? 'var(--activestatus)'
            : 'var(--inactivestatus)',
        }}
      >
        <img className={styles.Image} src={model.desktopImage || EmptyImage} />
        <div className={styles.MainContentContainer}>
          <div className={styles.LeftContainer}>
            <div
              className={styles.Title}
              onClick={() => props.openBannerDetailsPage(model.id)}
            >
              {model.name || 'Name'}
            </div>
            <div className={styles.Partner}>{model.author}</div>
          </div>
          <div className={styles.CenterContainer}>
            <img
              height="32px"
              width="32px"
              src={CHEVRON_DOWN}
              alt="up_arrow"
              style={{ transform: 'rotate(180deg)' }}
              onClick={() =>
                currentPage.changeOrder(model.id, model.order, props.index, -1)
              }
            />
            <img
              height="32px"
              width="32px"
              src={CHEVRON_DOWN}
              alt="down_arrow"
              onClick={() =>
                currentPage.changeOrder(model.id, model.order, props.index, 1)
              }
            />
          </div>
          <div className={styles.RightContainer}>
            <div className={styles.RightText}>
              <Switch checked={!!model.isActive} onChange={onChange} />
            </div>
            <div className={styles.RightText}>
              Status:
              <span
                style={{
                  marginLeft: '5px',
                  color: model.isActive
                    ? 'var(--activestatus)'
                    : 'var(--inactivestatus)',
                }}
              >
                {t(model.isActive ? 'banners:active' : 'banners:inactive')}
              </span>
            </div>
            <div className={styles.RightText}>
              <span>
                Period:{' '}
                {`${dateSRLocale(
                  new Date(model.startDate as string)
                )} - ${dateSRLocale(new Date(model.endDate as string))}`}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
);
