import { observer } from 'mobx-react';
import { AdditionalLocationModelType } from '../../../internal';
import { Modal } from '../../UI/modal/Modal';
import styles from './LocationPriceListModal.module.scss';
import { useTranslation } from 'react-i18next';
import { toAmount } from '../../../utils/methods';

export const LocationPriceListModal = observer(
  (props: { model: AdditionalLocationModelType }): JSX.Element => {
    const { model } = props;
    const { t } = useTranslation(['basic']);
    return (
      <Modal
        styles={{
          content: {
            minWidth: '60%',
            maxHeight: 'fit-content',
            borderRadius: '20px',
            padding: '40px',
          },
        }}
        model={model.modal}
      >
        <span className={styles.Header}>
          {t('price_list_addition_for_location')}
        </span>
        <div className={styles.Description}>
          <span>{t('price_list_addition_targeting')}</span>
          <span>{t('price_list_addition_buying_description')}</span>
        </div>
        <table className={styles.Table}>
          <thead>
            <tr>
              <th>{t('number_of_locations')}</th>
              <th>{t('price_per_location')}</th>
            </tr>
          </thead>
          <tbody>
            {model.pricesArray.map((item: any) => (
              <tr key={item}>
                <td>
                  {t('from')} {item.from} {t('to')} {item.to}
                </td>
                <td>
                  {toAmount(item?.price, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 17,
                  })}{' '}
                  RSD
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Modal>
    );
  }
);
