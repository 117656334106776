import * as React from 'react';
import { observer } from 'mobx-react';
import styles from './FileInput.module.scss';
import { FileInputType } from '../../../internal';
import { ReactNode, useRef, useState } from 'react';

interface IFileInput {
  model: FileInputType;
  LabelRenderer: ({ className }: { className?: string }) => JSX.Element;
  ImageWrapper: ({ children }: { children: ReactNode }) => JSX.Element;
  GalleryRenderer?: (model: FileInputType, className?: string) => JSX.Element;
  onFileSizeError?: (message?: string) => void;
  onFileTypeError?: (message?: string) => void;
  id?: string;
}

export const FileInput = observer((props: IFileInput) => {
  const { model, LabelRenderer, GalleryRenderer, ImageWrapper, id } = props;
  const [entered, setEntered] = useState<boolean>(false);
  const inputEl = useRef(null);
  const onDragEnter = (/*e: React.DragEvent<HTMLDivElement>*/) => {
    setEntered(true);
  };
  const onDragLeave = (/*e: React.DragEvent<HTMLDivElement>*/) => {
    setEntered(false);
  };
  const onDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    model.setInputRef(inputEl);
    model.processDroppedFiles(e);
    setEntered(false);
  };
  const onChange = (e: React.DragEvent<HTMLInputElement>) => {
    e.preventDefault();
    model.setInputRef(inputEl);
    model.processFiles(e);
    setEntered(false);
  };
  const filesArray = model?.filesArray;
  if (!model) {
    return <></>;
  }
  return (
    <>
      <div
        id={id}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
        className={styles.Upload}
      >
        <label
          className={styles.FileInputLabel}
          id={`${model.id}_label`}
          htmlFor={model.id}
        >
          {LabelRenderer ? (
            filesArray[0] ? (
              <ImageWrapper>
                <img
                  alt={''}
                  src={model.crop || URL.createObjectURL(filesArray[0])}
                />
              </ImageWrapper>
            ) : model?.src ? (
              <ImageWrapper>
                <img alt={''} src={model.src} />
              </ImageWrapper>
            ) : (
              <LabelRenderer className={entered ? 'Entered' : ''} />
            )
          ) : (
            model.value
          )}
        </label>
        <input
          ref={inputEl}
          data-id={model.id}
          type={'file'}
          accept="image/*"
          id={model.id}
          name={model.name}
          hidden={!!LabelRenderer}
          onInput={onChange}
          multiple
          disabled={!!model.file}
        />
      </div>
    </>
  );
});
