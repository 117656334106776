import * as React from 'react';
import { observer } from 'mobx-react';

import styles from './DescriptionSection.module.scss';

import { useTranslation } from 'react-i18next';
import { TextSection } from '../../text-section/TextSection';

interface IOfferModel {
  model: any;
}

export const DescriptionSection = observer((props: IOfferModel) => {
  const { model } = props;
  const { t } = useTranslation(['offer']);

  return (
    <>
      <div className={styles.AccordionSection}>
        <TextSection
          id={'opis_ponude'}
          title={t('offer:offer_description')}
          content={model.description ?? ''}
        />
      </div>
    </>
  );
});
export default DescriptionSection;
