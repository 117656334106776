import { observer } from 'mobx-react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { DurationModelType } from '../../../internal';
import { StandardDurationPick } from '../duration/StandardDurationPick';
import { VoucherDurationModal } from './modal/VoucherDurationModal';

export const VoucherDurationView = observer(
  (props: { model: DurationModelType; id?: string }): JSX.Element => {
    const { t } = useTranslation();
    const { model, id } = props;
    return (
      <>
        <StandardDurationPick
          id={id}
          model={model}
          label={t('basic:voucher_duration')}
        >
          <VoucherDurationModal model={model} />
        </StandardDurationPick>
      </>
    );
  }
);
