import { observer } from 'mobx-react';
import { Modal } from '../../../../components/UI/modal/Modal';
import { ApproveAdModelType } from '../../../../internal';
import { Button } from '../../../../components/UI/buttons/regular/Button';
import * as React from 'react';
import styles from './AdDetails.module.scss';
import { useTranslation } from 'react-i18next';
import { PUBLISH_AD, REJECT_AD, STOP_AD } from '../../../../constants/ad';

export const ConfirmationAdModal = observer(
  (props: { model: ApproveAdModelType }): JSX.Element => {
    const { model } = props;
    const { t } = useTranslation();
    return (
      <Modal
        styles={{
          content: {
            minWidth: '40%',
            maxHeight: 'fit-content',
            borderRadius: '20px',
            padding: '40px',
          },
        }}
        model={model.modal}
      >
        <div className={styles.ModalHeader}> {t('basic:are_you_sure')}</div>
        <div className={styles.ButtonModalContainer}>
          <button
            className={styles.BtnNo}
            onClick={() => {
              model.modal.setOpened(false);
            }}
          >
            {t('basic:no')}
          </button>
          <Button
            onClick={() => {
              if (model.type === PUBLISH_AD) {
                model.publishAd();
              } else if (model.type === REJECT_AD) {
                model.rejectAd();
              } else if (model.type === STOP_AD) {
                model.stopAd();
              }
              model.modal.setOpened(false);
            }}
          >
            {t('basic:yes')}
          </Button>
        </div>
      </Modal>
    );
  }
);
