import { observer } from 'mobx-react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonModelType } from '../../../../internal';
import styles from './Button.module.scss';
import { Tooltip } from 'react-tippy';

export const Button = observer(
  (props: {
    model?: ButtonModelType;
    onClick?: () => void;
    children?: any;
    disabled?: boolean;
    id?: string;
    customButtonStyles?: any;
    disabledTooltipMessage?: string;
  }): JSX.Element => {
    const {
      model,
      onClick,
      children,
      disabled,
      id,
      customButtonStyles,
      disabledTooltipMessage,
    } = props;
    const isDisabled =
      Boolean(disabled) || (model ? !model?.isEnabledComputed : false);
    const { t } = useTranslation();
    const ButtonComp = () => (
      <div
        id={id}
        onClick={isDisabled ? undefined : onClick ? onClick : model.onClick}
        className={isDisabled ? styles.BtnDisabled : styles.Btn}
        style={customButtonStyles ? customButtonStyles : {}}
      >
        {children ? children : t(model.labelComputed || model.label)}
      </div>
    );
    return isDisabled && disabledTooltipMessage ? (
      <Tooltip
        theme={'dark'}
        html={
          <div style={{ padding: '1em' }}>
            <span>{disabledTooltipMessage}</span>
          </div>
        }
      >
        <ButtonComp />
      </Tooltip>
    ) : (
      <ButtonComp />
    );
  }
);
