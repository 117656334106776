import { observer } from 'mobx-react';
import { DurationModelType } from '../../../../internal';
import { Modal } from '../../../UI/modal/Modal';
import styles from './OfferDurationModal.module.scss';

import { useTranslation } from 'react-i18next';
import { StandardDuration } from '../../duration/StandardDuration';

export const OfferDurationModal = observer(
  (props: { model: DurationModelType }): JSX.Element => {
    const { model } = props;
    const { t } = useTranslation();
    return (
      <Modal
        model={model.modal}
        styles={{
          content: {
            maxHeight: 'fit-content',
            top: '5%',
          },
        }}
      >
        <StandardDuration
          model={model}
          startRangeText={t('campaign_duration:campaign_start')}
          endRangeText={t('campaign_duration:campaign_end')}
          maxRange={30}
        >
          <div className={styles.TextHeader}>
            <div className={styles.PickDatesIntro}>
              {t('campaign_duration:pick_dates_intro')}
            </div>
            <div className={styles.CampaignDurationDisclaimer}>
              {model.maxDurationText}
            </div>
          </div>
        </StandardDuration>
      </Modal>
    );
  }
);
