import { getRoot, Instance, types } from 'mobx-state-tree';
import {
  CheckboxGroupModel,
  ComponentModel,
  ContractExtended,
  DropdownModel,
  ModalModel,
  Partner,
  PartnerAdminNew,
  RootType,
  TextInputModel,
} from '../../../../internal';
import { PROMO_PACKAGE } from '../../../../utils/constants';

export const BillingModalModel = types
  .compose(
    ModalModel,
    types.model({
      type: types.enumeration(['perAd', 'monthly', 'combined']),
      partnerAdmin: types.late(() => types.frozen<PartnerAdminNew>()),
      packagesDropdown: types.late(() => DropdownModel),
      billingModalDiscountsCheckboxGroupSubscriptionWithContract: types.late(
        () => CheckboxGroupModel
      ),
      billingModalDiscountsCheckboxGroupSubscription: types.late(
        () => CheckboxGroupModel
      ),
      billingModalDiscountsCheckboxGroupPayAsYouGo: types.late(
        () => CheckboxGroupModel
      ),
      billingModalBtlInput: TextInputModel,
    })
  )
  .views((self) => {
    return {
      get root(): RootType {
        return getRoot(self);
      },
    };
  })
  .actions((self) => {
    return {
      setPartnerAdmin(partnerAdmin: PartnerAdminNew) {
        self.partnerAdmin = partnerAdmin;
      },
      open() {
        self.setOpened(true);
        if (self.partnerAdmin.contract?.calculator === PROMO_PACKAGE) {
          self.packagesDropdown.selectOption(0);
        } else if (self.partnerAdmin.contract?.calculator === 'pay-as-you-go') {
          self.packagesDropdown.selectOption(1);
          if (
            self.partnerAdmin.contract.discount_telco !== null &&
            self.partnerAdmin.contract.discount_telco !== 0
          ) {
            self.billingModalDiscountsCheckboxGroupPayAsYouGo.check({
              id: 'telco',
            });
          }
          if (
            self.partnerAdmin.contract.discount_soho !== null &&
            self.partnerAdmin.contract.discount_soho !== 0
          ) {
            self.billingModalDiscountsCheckboxGroupPayAsYouGo.check({
              id: 'soho',
            });
          }
        } else if (self.partnerAdmin.contract?.calculator === 'subscription') {
          self.packagesDropdown.selectOption(2);

          if (
            self.partnerAdmin.contract.discount_telco !== null &&
            self.partnerAdmin.contract.discount_telco !== 0
          ) {
            self.billingModalDiscountsCheckboxGroupSubscription.check({
              id: 'telco',
            });
          }
          if (
            self.partnerAdmin.contract.discount_btl !== null &&
            self.partnerAdmin.contract.discount_btl !== 0
          ) {
            self.billingModalDiscountsCheckboxGroupSubscription.check({
              id: 'btl',
            });
            self.billingModalBtlInput.setStringValue(
              self.partnerAdmin.contract.discount_btl
            );
          }
        } else if (
          self.partnerAdmin.contract?.calculator ===
          'subscription-with-contract-obligation'
        ) {
          self.packagesDropdown.selectOption(3);
          if (
            self.partnerAdmin.contract.discount_telco !== null &&
            self.partnerAdmin.contract.discount_telco !== 0
          ) {
            self.billingModalDiscountsCheckboxGroupSubscriptionWithContract.check(
              { id: 'telco' }
            );
          }
          if (
            self.partnerAdmin.contract.discount_so !== null &&
            self.partnerAdmin.contract.discount_so !== 0
          ) {
            self.billingModalDiscountsCheckboxGroupSubscriptionWithContract.check(
              { id: 'uo' }
            );
          }
          if (
            self.partnerAdmin.contract.discount_btl !== null &&
            self.partnerAdmin.contract.discount_btl !== 0
          ) {
            self.billingModalDiscountsCheckboxGroupSubscriptionWithContract.check(
              { id: 'btl' }
            );
            self.billingModalBtlInput.setStringValue(
              self.partnerAdmin.contract.discount_btl
            );
          }
        }
        if (self.partnerAdmin.contract?.status === 'inactive') {
          self.packagesDropdown.setDisabled(true);
          self.billingModalDiscountsCheckboxGroupPayAsYouGo.setDisabled(true);
          self.billingModalDiscountsCheckboxGroupSubscription.setDisabled(true);
          self.billingModalDiscountsCheckboxGroupSubscriptionWithContract.setDisabled(
            true
          );
          self.billingModalBtlInput.setDisabled(true);
        }
      },
      close() {
        self.packagesDropdown.setDisabled(false);
        self.billingModalDiscountsCheckboxGroupPayAsYouGo.setDisabled(false);
        self.billingModalDiscountsCheckboxGroupSubscription.setDisabled(false);
        self.billingModalDiscountsCheckboxGroupSubscriptionWithContract.setDisabled(
          false
        );
        self.billingModalBtlInput.setDisabled(false);
        self.setOpened(false);
      },
      async submit() {
        let requestBody;
        if (self.packagesDropdown.selectedOptionId === 'pay-as-you-go') {
          requestBody = {
            discount_telco:
              self.billingModalDiscountsCheckboxGroupPayAsYouGo.selectedCheckboxesIds.includes(
                'telco'
              )
                ? self.root.data.payAsYouGoCatalog.discount_telco
                : null,
            discount_soho:
              self.billingModalDiscountsCheckboxGroupPayAsYouGo.selectedCheckboxesIds.includes(
                'soho'
              )
                ? self.root.data.payAsYouGoCatalog.discount_soho
                : null,
          };
        } else if (self.packagesDropdown.selectedOptionId === 'subscription') {
          requestBody = {
            discount_telco:
              self.billingModalDiscountsCheckboxGroupSubscription.selectedCheckboxesIds.includes(
                'telco'
              )
                ? self.root.data.monthlyPaymentCatalog.discount_telco
                : null,
            discount_btl:
              self.billingModalDiscountsCheckboxGroupSubscription.selectedCheckboxesIds.includes(
                'btl'
              )
                ? Number(self.billingModalBtlInput.value)
                : null,
          };
        } else {
          requestBody = {
            discount_telco:
              self.billingModalDiscountsCheckboxGroupSubscriptionWithContract.selectedCheckboxesIds.includes(
                'telco'
              )
                ? self.root.data.monthlyPaymentWithContractCatalog
                    .discount_telco
                : null,
            discount_so:
              self.billingModalDiscountsCheckboxGroupSubscriptionWithContract.selectedCheckboxesIds.includes(
                'uo'
              )
                ? self.root.data.monthlyPaymentWithContractCatalog.discount_so
                : null,
            discount_btl:
              self.billingModalDiscountsCheckboxGroupSubscriptionWithContract.selectedCheckboxesIds.includes(
                'btl'
              )
                ? Number(self.billingModalBtlInput.value)
                : null,
          };
        }

        requestBody = {
          ...requestBody,
          catalog_id: self.root.data.catalogs.find(
            (c: any) => c.calculator === self.packagesDropdown.selectedOptionId
          ).id,
          partner_id: self.partnerAdmin.id,
        };
        await self.root.api.contractRequest.submit(requestBody);

        const partnerAdminsResponse =
          await self.root.api.partner.getAdminAccounts();
        self.root.currentPage
          .getCreatedAccountList()
          .setAccounts(partnerAdminsResponse.data);

        this.close();
      },
    };
  })
  .named('BillingModalModel');

export type BillingModalModelType = Instance<typeof BillingModalModel>;
