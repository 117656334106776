import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import styles from './CompanyProfile.module.scss';
import { Tabs } from '../../components/UI/tabs/Tabs';
import { useMst } from '../../internal';
import { TabPanel } from 'react-tabs';
import { CompanyHeadquarters } from '../../components/domain/company-profile/company-headquarters/CompanyHeadquarters';
import { Administrators } from '../../components/domain/company-profile/administrators/Administrators';
import { StoreLocations } from '../../components/domain/company-profile/store-locations/StoreLocations';
import { TariffPackages } from '../../components/domain/company-profile/tariff-packages/TariffPackages';

export const CompanyProfile = observer((): JSX.Element => {
  const { t } = useTranslation(['basic']);
  const {
    currentPage,
    user: { isSalesman },
  } = useMst();
  return (
    <div className={styles.PageContainer}>
      <div className={styles.PageTitleContainer}>
        <div className={styles.PageTitle}>{t('basic:company_profile')}</div>
      </div>
      <div className={styles.CenteredTabsContainer}>
        <Tabs
          tabsClass={styles.CompanyProfileTabs}
          model={currentPage.getTabs()}
        >
          <>
            <TabPanel>
              <CompanyHeadquarters />
            </TabPanel>
            <TabPanel>
              <StoreLocations model={currentPage.getStoreLocations()} />
            </TabPanel>
            {/* Temporarily hidden, will be reimplemented*/}
            {/*<TabPanel>
              <PackageSelection model={currentPage.getPackageSelection()} />
            </TabPanel>
            */}
            <TabPanel style={{ paddingTop: '35px' }}>
              <TariffPackages />
            </TabPanel>
            <TabPanel>
              <Administrators model={currentPage.getAdministrators()} />
            </TabPanel>
          </>
        </Tabs>
      </div>
    </div>
  );
});
