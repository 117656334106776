import { observer } from 'mobx-react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonModelType } from '../../../../internal';
import styles from './YellowButton.module.scss';

export const YellowButton = observer(
  (props: {
    model?: ButtonModelType;
    onClick?: () => void;
    children?: any;
    disabled?: boolean;
    id?: string;
  }): JSX.Element => {
    const { model, onClick, children, disabled, id } = props;
    const { t } = useTranslation();
    return (
      <div
        id={id}
        onClick={() => (onClick ? onClick() : model.onClick())}
        className={
          disabled || model.isDisabled ? styles.BtnDisabled : styles.Btn
        }
      >
        {children ? children : t(model.label)}
      </div>
    );
  }
);
