import { observer } from 'mobx-react';
import styles from './Section.module.scss';
import React from 'react';
import { SectionModelType } from '../../../internal';
import { useTranslation } from 'react-i18next';
import { Checkbox } from '../checkbox/Checkbox';

export const Section = observer(
  (props: {
    model: SectionModelType;
    children?: any;
    id?: string;
  }): JSX.Element => {
    const { model, children, id } = props;
    const { t } = useTranslation();
    return (
      <section id={id}>
        {model.checkboxGroup ? (
          <Checkbox
            labelClassName={styles.FormHeaderText}
            checkboxClassName={styles.FormHeaderCheckbox}
            model={model.checkboxGroup.checkboxesArray[0]}
          ></Checkbox>
        ) : (
          <label htmlFor={model.id} className={styles.FormHeaderText}>
            <h3>{t(model.label)}</h3>
          </label>
        )}

        <div
          className={
            model.isOpened
              ? styles.ChildrenContainer
              : styles.ChildrenContainerHidden
          }
        >
          {children}
        </div>
      </section>
    );
  }
);
