import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { AdditionalPackageModelType } from '../../../../../internal';
import { YellowButton } from '../../../../UI/buttons/yellow-button/YellowButton';
import { ActivatePackageModal } from './activate-package/ActivatePackageModal';
import styles from './AdditionalPackage.module.scss';

export const AdditionalPackage = observer(
  (props: { model: AdditionalPackageModelType }): JSX.Element => {
    const { model } = props;
    const { t } = useTranslation();
    return (
      <>
        <div
          className={
            model.base ? styles.BaseContainer : styles.PackageContainer
          }
        >
          <div className={model.base ? styles.Title : styles.PackageTitle}>
            {t(`packages:${model.name}`)}
          </div>
          <div>Targetiranje</div>
          <div className={styles.UserNumber}>{model.userNumber}</div>
          <div className={styles.UserText}>korisnika</div>
          <div className={styles.LocationText}>Lokacija</div>
          <div className={styles.LocationNumber}>{model.locationNumber}</div>
          <div className={styles.Duration}>
            Trajanje paketa{' '}
            {model.durationDays ? `${model.durationDays} dana` : '-'}
          </div>
          <div className={styles.PriceText}>Cena</div>
          <div
            className={
              model.oldMonthlyPayment
                ? styles.OldMonthlyPayment
                : styles.OldMonthlyPaymentHidden
            }
          >
            {model.oldMonthlyPayment} RSD/mes
          </div>
          <div className={styles.CurrentMonthlyPayment}>
            {model.currentMonthlyPayment} RSD/mes
          </div>
          <div
            className={
              model.base
                ? styles.ActivateBtnContainerHidden
                : styles.ActivateBtnContainer
            }
          >
            <YellowButton
              onClick={() => {
                model.activateModal.openModal();
              }}
              model={{ disabled: false, isEnabledComputed: true }}
            >
              {t('basic:activate')}
            </YellowButton>
          </div>
        </div>
        <ActivatePackageModal model={model.activateModal} />
      </>
    );
  }
);
