import { reaction } from 'mobx';
import { getRoot, Instance, types } from 'mobx-state-tree';
import { RootType, ButtonModel, CheckboxGroupModel } from '../../../internal';

export const DropdownMultiselectModel = types
  .model({
    id: types.identifier,
    options: types.union(
      types.maybe(types.late(() => CheckboxGroupModel)),
      types.optional(types.map(types.late(() => CheckboxGroupModel)), {})
    ),
    isOpen: types.boolean,
    resetButton: types.maybe(types.late(() => ButtonModel)),
    onlyDropdown: types.maybe(types.boolean),
    shouldPostOnChange: true,
    type: types.enumeration(['single', 'nested']),
  })
  .views((self) => {
    return {
      get root(): RootType {
        return getRoot(self);
      },
      get optionsArray() {
        return self.type === 'nested'
          ? this.nestedOptionsArray
          : self.options?.checkboxesArray;
      },
      get selectedOptionsArray() {
        return self.options?.selectedCheckboxesIds;
      },
      get nestedOptionsArray() {
        return self.type === 'nested' ? Array.from(self.options.values()) : [];
      },
      get visibleNestedOptionsArray() {
        return this.nestedOptionsArray.filter(
          (option: any) => option.isVisible
        );
      },
      get allNestedOptionsLength() {
        let allOptionsLength = 0;
        for (const options of this.nestedOptionsArray) {
          allOptionsLength += (options as any).checkboxesArray.length;
        }
        return allOptionsLength;
      },
      get allSelectedNestedOptionsLength() {
        let allselectedOptionsLength = 0;
        for (const options of this.nestedOptionsArray) {
          allselectedOptionsLength += (options as any).selectedCheckboxesIds
            .length;
        }
        return allselectedOptionsLength;
      },
    };
  })
  .actions((self) => {
    return {
      setShouldPostOnChange(value: boolean) {
        self.shouldPostOnChange = value;
      },
      afterCreate() {
        reaction(
          () => {
            return self.selectedOptionsArray;
          },
          async (selectedOptionsArray, previousOptionsArray) => {
            if (self.id === 'ColumnSelector') {
              const selectedOptionsArrayWithoutAllOptions =
                selectedOptionsArray.filter(
                  (option: any) => option !== 'allOptions'
                );
              const selectedOptionsArrayString =
                selectedOptionsArrayWithoutAllOptions.join(',');
              if (self.shouldPostOnChange) {
                await self.root.api.offers.saveSettings(
                  selectedOptionsArrayString
                );
                if (selectedOptionsArray.length > previousOptionsArray.length) {
                  return self.root.currentPage.loadExtendedAds(false);
                }
              }
            }
          }
        );
      },
      setOptions: (options: any[]) => {
        options.forEach((option: any) => {
          if (self.type === 'single') {
            self.options.add(option);
          } else {
            self.options.put(option);
          }
        });
      },
      closeDropdown() {
        self.isOpen = false;
      },
      toggleIsOpen() {
        self.isOpen = !self.isOpen;
      },
      checkAll() {
        if (self.type === 'single') {
          self.options.toggleAll(true);
        } else {
          for (const options of self.optionsArray) {
            options.selectAll();
          }
        }
      },
    };
  });

export type DropdownMultiselectModelType = Instance<
  typeof DropdownMultiselectModel
>;
