import { getRoot, Instance, types } from 'mobx-state-tree';
import {
  ComponentModel,
  AdListElemModel,
  PaginationModel,
  RootType,
  ColumnModelType,
  AdListElemModelType,
  ColumnModel,
} from '../../../internal';

export const OldAdListModel = types
  .compose(
    ComponentModel,
    types.model({
      ads: types.map(AdListElemModel),
      pagination: types.late(() => PaginationModel),
      columns: types.map(ColumnModel),
    })
  )
  .views((self) => {
    return {
      get root(): RootType {
        return getRoot(self);
      },
      get adsArray() {
        return Array.from(self.ads.values());
      },
      get columnsArray(): ColumnModelType[] {
        return Array.from(self.columns.values());
      },
      get visibleColumnsArray() {
        return this.columnsArray.filter((column: ColumnModelType) => {
          return (
            self.root.currentPage
              .getColumnsSelector()
              .options.checkboxes.get(column.id).isChecked &&
            column.id !== 'allOptions'
          );
        });
      },
      get pageSize(): number {
        if (!this.root.currentPage) {
          return 20;
        }

        return Number(
          this.root.currentPage?.getPageSizeDropdown?.()?.value || 20
        );
      },
    };
  })
  .actions((self) => {
    return {
      clearElements() {
        self.ads.clear();
      },
      setElements(adsArr: AdListElemModelType[]) {
        self.clearElements();
        adsArr.forEach((adElem: AdListElemModelType) => {
          self.ads.put(adElem);
        });
      },
      setColumns(columnsArr: ColumnModelType[]) {
        self.columns.clear();
        columnsArr.forEach((column: ColumnModelType) => {
          self.columns.set(Object.keys(column)[0], {
            id: Object.keys(column)[0],
            label: Object.values(column)[0],
          });
        });
      },
    };
  })
  .named('AdListModel');

export type OldAdListModelType = Instance<typeof OldAdListModel>;
