import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './StoreLocations.module.scss';
import EDIT_ICON from '../../../../assets/icons/icons8_edit_property_96px.png';
import DELETE_ICON from '../../../../assets/icons/icons8_trash_32px.png';
import {
  PartnerLocation,
  PhysicalStoreLocationsModelType,
  ShoppingMall,
} from '../../../../internal';
import { StoreLocationModal } from './store-location-modal/StoreLocationModal';
import { DeleteLocationModal } from './delete-location-modal/DeleteLocationModal';

export const StoreLocations = observer(
  (props: { model: PhysicalStoreLocationsModelType }): JSX.Element => {
    const { t } = useTranslation();
    const { model } = props;
    return (
      <>
        <div className={styles.Container}>
          <div className={styles.Section}>
            <div className={styles.SectionContent}>
              <div className={styles.Title}>
                {t('basic:physical_store_locations')}
              </div>
              <div
                className={
                  model.locationsArray.length
                    ? styles.MainContent
                    : styles.MainContentEmpty
                }
              >
                {model.locationsArray.map(
                  (elem: PartnerLocation, index: number) => {
                    const shoppingMalls = model.shoppingMalls.toJSON();
                    const shoppingMall = elem.shopping_mall_id
                      ? shoppingMalls[elem.shopping_mall_id]
                      : null;
                    const name = shoppingMall
                      ? `${elem.name}, ${shoppingMall.name}, ${elem.address}, ${elem.city}`
                      : `${elem.name}, ${elem.address}, ${elem.city}`;
                    return (
                      <div className={styles.Row} key={index}>
                        <div className={styles.Left}>{name}</div>
                        <div className={styles.Right}>
                          <img
                            className={styles.Edit}
                            width="32px"
                            height="32px"
                            onClick={() => {
                              model.storeLocationModal.openModal();
                              model.storeLocationModal.setLocation(elem);
                            }}
                            src={EDIT_ICON}
                          />
                          <img
                            className={styles.Delete}
                            width="32px"
                            height="32px"
                            onClick={() => {
                              model.deleteLocationModal.setLocation(elem);
                              model.deleteLocationModal.openModal();
                            }}
                            src={DELETE_ICON}
                          />
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
              <div className={styles.AddStoreLocationContainer}>
                <span className={styles.Plus}>+</span>
                <a
                  onClick={() => {
                    model.storeLocationModal.openModal();
                  }}
                  className={styles.Link}
                >
                  {t('basic:add_store_location')}
                </a>
              </div>
            </div>
          </div>
        </div>
        <StoreLocationModal model={model.storeLocationModal} />
        <DeleteLocationModal model={model.deleteLocationModal} />
      </>
    );
  }
);
