import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './CreateOffer.module.scss';
import { observer } from 'mobx-react';
import { useMst } from '../../../internal';
import 'cropperjs/dist/cropper.css';
import { OfferSteps } from '../../../components/domain/offer-steps/OfferSteps';
import { Show } from '../../../components/UI/show/Show';
import { Button } from '../../../components/UI/buttons/regular/Button';
import { FirstStep } from './steps/FirstStep';
import { SecondStep } from './steps/SecondStep';
import { ThirdStep } from './steps/ThirdStep';
import giftIcon from '../../../assets/icons/gift-icon.png';
import {
  ConfirmationModal,
  ReverseConfirmationModal,
} from '../../../components/UI/confirmation-modal/ConfirmationModal';
import { differenceInDays } from 'date-fns';

export const CreateOffer = observer((): JSX.Element => {
  const { t } = useTranslation();
  const { currentPage } = useMst();

  return (
    <div className={styles.PageContainer}>
      <div className={styles.PageTitleContainer}>
        <div className={styles.PageTitle}>{t('basic:create_offer')}</div>
      </div>
      <div className={styles.StepsRow}>
        <div
          className={
            currentPage.standardPackageWithoutVoucher
              ? styles.OuterStepsContainerSmall
              : styles.OuterStepsContainer
          }
        >
          <OfferSteps
            model={currentPage.getOfferSteps()}
            standardPackageWithoutVoucher={
              currentPage.standardPackageWithoutVoucher
            }
          />
        </div>
      </div>
      <Show condition={currentPage.getOfferSteps().isFirstSelected}>
        <FirstStep />
      </Show>
      <Show condition={currentPage.getOfferSteps().isSecondSelected}>
        <SecondStep />
      </Show>
      <Show condition={currentPage.getOfferSteps().isThirdSelected}>
        <ThirdStep />
      </Show>
      <Show
        condition={
          !currentPage.getOfferSteps().isThirdSelected &&
          currentPage.getOfferSteps().isSecondSelected
        }
      >
        <div className={styles.SubmitContainer}>
          {currentPage.canSaveOfferDraft() && (
            <div
              onClick={() => currentPage.saveOffer()}
              className={styles.SaveBtn}
            >
              {t('basic:save_offer')}
            </div>
          )}
          <div
            onClick={() => currentPage.submitSecondStepForValidation()}
            className={styles.SubmitBtn}
          >
            {t('basic:continue')}
          </div>
        </div>
        <div className={styles.CenteredContent}>
          <div
            onClick={() => currentPage.getOfferSteps().selectStep('1')}
            className={styles.BackToCreateOffer}
          >
            {t('basic:back_to_create_offer')}
          </div>
        </div>
      </Show>
      <Show
        condition={
          currentPage.getOfferSteps().isThirdSelected &&
          !currentPage.standardPackageSelected
        }
      >
        <div className={styles.CenteredContentWithTopMargin}>
          <div className={styles.CenteredBtnContainer}>
            <Button model={currentPage.getBuyAndSendForApproval()} />
          </div>
        </div>
      </Show>
      <ReverseConfirmationModal model={currentPage.getLeaveConfirmationModal()}>
        <>
          <div>{t('basic:are_you_sure')}</div>
          <div>{t('basic:create_offer_leave_confirmation')}</div>
          <div>{t('basic:create_offer_save_confirmation_text')}</div>
        </>
      </ReverseConfirmationModal>
      <ConfirmationModal model={currentPage.getSaveOfferModal()}>
        <>
          <div>
            <strong>{t('basic:create_offer_save_confirmation')}</strong>
          </div>
          <div>{t('basic:create_offer_save_confirmation_text')}</div>
        </>
      </ConfirmationModal>
    </div>
  );
});
