import { observer } from 'mobx-react';
import { FC, useState } from 'react';
import { Calendar, DateRangePicker } from 'react-date-range';
import { DatePickerV2ModelType } from '../../../models/ui/DatePickerV2/DatePickerV2Model';
import { LightButton } from '../buttons/light-button/LightButton';
import sr from 'date-fns/locale/sr-Latn';
import styles from './DatePickerV2.module.scss';
import filterIcon from './../../../assets/icons/filter_icon.png';

export interface DatePickerV2Props {
  model: DatePickerV2ModelType;
  type: 'date' | 'dateRange';
}

export const DatePickerV2: FC<DatePickerV2Props> = observer(
  ({ model, type }) => {
    const [selectionRange, setSelectionRange] = useState({
      startDate: undefined,
      endDate: undefined,
      key: 'selection',
    });

    if (type === 'date') {
      return (
        <div className={styles.dateWrapper}>
          <div className={styles.filterHeader}>
            <img className={styles.filterIcon} src={filterIcon} />
            <span> Filteri </span>
          </div>
          <Calendar
            locale={sr}
            date={model.date}
            onChange={(date) => {
              model.setDate(date);
            }}
          />
          <div className={styles.resetButton}>
            <LightButton
              onClick={() => model.resetDate()}
              model={model.resetButton}
            >
              Resetuj
            </LightButton>
          </div>
        </div>
      );
    }

    if (type === 'dateRange') {
      return (
        <div className={styles.dateWrapper}>
          <div className={styles.filterHeader}>
            <img className={styles.filterIcon} src={filterIcon} />
            <span> Filteri </span>
          </div>
          <DateRangePicker
            months={2}
            direction="horizontal"
            locale={sr}
            ranges={[
              {
                startDate: model.dateRangeStart,
                endDate: model.dateRangeEnd,
              },
            ]}
            onChange={(ranges: any) => {
              model.setDateRange(
                ranges.range1.startDate,
                ranges.range1.endDate
              );
            }}
          />
          <div className={styles.resetButton}>
            <LightButton
              onClick={() => model.resetDateRange()}
              model={model.resetButton}
            >
              Resetuj
            </LightButton>
          </div>
        </div>
      );
    }

    return <></>;
  }
);
