const shouldShowOnlyRegularPrice1 = (
  adRegularPriceRSD: number,
  adLoweredPriceRSD: number,
  adDiscount?: string
): boolean =>
  !!(
    adRegularPriceRSD &&
    !adLoweredPriceRSD &&
    (!adDiscount || adDiscount === undefined)
  );
const shouldShowOnlyRegularPrice2 = (
  adRegularPriceRSD: number,
  adLoweredPriceRSD: number,
  adDiscount?: string
): boolean =>
  !!(
    adRegularPriceRSD &&
    (adDiscount || adDiscount === undefined) &&
    !adLoweredPriceRSD
  );

export const shouldShowOnlyRegularPriceFunc = (
  adRegularPriceRSD: number,
  adLoweredPriceRSD: number,
  adDiscount?: string
): boolean =>
  shouldShowOnlyRegularPrice1(
    adRegularPriceRSD,
    adLoweredPriceRSD,
    adDiscount
  ) ||
  shouldShowOnlyRegularPrice2(adRegularPriceRSD, adLoweredPriceRSD, adDiscount);

export const shouldShowBothPricesFunc = (
  adRegularPriceRSD: number,
  adLoweredPriceRSD: number,
  adDiscount?: string
): boolean => !!(adRegularPriceRSD && adLoweredPriceRSD);

export const shouldShowOnlyDiscountFunc = (
  adRegularPriceRSD: number,
  adLoweredPriceRSD: number,
  adDiscount: string
): boolean => !!(adRegularPriceRSD && adLoweredPriceRSD && adDiscount);
