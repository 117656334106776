import { HttpVerbs } from '../../constants/verbs';
import { RootType, ApiListResponse, PageRoutes } from '../../internal';
import { getRoot, types } from 'mobx-state-tree';
import { StringifyOptions } from 'querystring';

export interface Contract {
  id: string;
  partner_id: string;
  from: string;
  to: string;
  active: string;
  name: string;
  obligation_till: Date;
}

export interface ContractExtended {
  partner_id: string;
  status: string;
  catalog_id: string;
  from: Date;
  to: Date;
  obligation_till: Date;
  promo_till: Date;
  name: string;
  calculator: string;
  price_basic: number;
  price_targeted: number;
  price_top: number;
  top_addon_max: number;
  top_addon_price: number;
  top_addon_value: number;
  subscription_price: number;
  subscription_obligation: number;
  discount_quantity_level_1_amount: number;
  discount_quantity_level_1_number: number;
  discount_quantity_level_2_amount: number;
  discount_quantity_level_2_number: number;
  discount_quantity_level_3_amount: number;
  discount_quantity_level_3_number: number;
  id: string;
}

export interface ContractAddon {
  id: string;
  contract_id: string;
  from: string;
  to: string;
  price: string;
  active: string;
  type: string;
  addon_id: string;
}

export interface ContractExtension {
  id: string;
  contract_id: string;
  extension_id: string;
  name: string;
  targets: string;
  locations: string;
  days_valid: string;
  price: string;
  old_price: string;
  recommended: string;
  active: string;
}

export const ContractApi = types
  .model({})
  .views((self) => {
    return {
      get root(): RootType {
        return getRoot(self);
      },
    };
  })
  .actions((self) => {
    return {
      getContracts(partnerId: string, offset = 0, limit = 1000) {
        const offsetQuery = offset ? `&offset=${offset}` : '';
        const limitSizeQuery = limit ? `&limit=${limit}` : '';
        const partnerIdQuery = partnerId ? `&partner_id=${partnerId}` : '';
        return self.root.axios.requestData({
          type: HttpVerbs.GET,
          path: `/contracts?${partnerIdQuery}${offsetQuery}${limitSizeQuery}`,
        });
      },

      getContractExtensions(contractId: string, offset = 0, limit = 1000) {
        return new Promise((resolve) => {
          self.root.axios
            .requestData({
              type: HttpVerbs.GET,
              path: '/contract-extensions',
              params: {
                contract_id: contractId,
                offset,
                limit,
              },
            })
            .then((response: ApiListResponse<ContractExtension>) => {
              resolve(response);
              self.root.data.setContractExtensions(response.data);
            });
        });
      },
      requestNewTariffPackage(data: { contractId: string; catalogId: string }) {
        return new Promise((resolve, reject) => {
          self.root.axios
            .requestData({
              type: HttpVerbs.POST,
              path: '/contract-requests',
              data: data,
            })
            .then((response: any) => {
              resolve(response);
              console.log('Request sent', response);
            })
            .catch((err: any) => {
              reject(err);
              console.log(err);
            });
        });
      },
      getContractRequestsDetails(partnerId: string) {
        return new Promise((resolve) => {
          self.root.axios
            .requestData({
              type: HttpVerbs.GET,
              path: `/contract-requests/${partnerId}`,
            })
            .then((response: any) => {
              resolve(response);
            })
            .catch((err: any) => {
              console.log(err);
            });
        });
      },
      getContractAddons(
        contractId: string,
        type: string,
        offset = 0,
        limit = 1000
      ) {
        const offsetQuery = offset ? `&offset=${offset}` : '';
        const limitSizeQuery = limit ? `&limit=${limit}` : '';
        const contractIdQuery = contractId ? `&contract_id=${contractId}` : '';
        const typeQuery = type ? `&type=${type}` : '';

        return new Promise((resolve) => {
          self.root.axios
            .requestData({
              type: HttpVerbs.GET,
              path: `/contract-addons?${contractIdQuery}${typeQuery}${offsetQuery}${limitSizeQuery}`,
            })
            .then((response: ApiListResponse<ContractAddon>) => {
              resolve(response);
              if (type === 'location') {
                self.root.data.setLocationContractAddons(response.data);
                this.manageAdLocationResponse(response);
              } else {
                self.root.data.setTargetContractAddons(response.data);
                this.manageAdditionalUsersResponse(response);
              }
            });
        });
      },
      manageAdLocationResponse(response: ApiListResponse<ContractAddon>) {
        const offerPage = self.root.pages.get(PageRoutes.CreateOffer.id);
        offerPage.getAdditionalLocation().setPrices(
          response.data?.map((contractAddon: ContractAddon) => ({
            ...contractAddon,
            from: contractAddon.from,
            to: contractAddon.to,
            price: contractAddon.price,
          }))
        );
      },
      manageAdditionalUsersResponse(response: ApiListResponse<ContractAddon>) {
        const offerPage = self.root.pages.get(PageRoutes.CreateOffer.id);
        offerPage.getAdditionalUsers().setPrices(
          response.data?.map((contractAddon: ContractAddon) => ({
            ...contractAddon,
            from: contractAddon.from,
            to: contractAddon.to,
            price: contractAddon.price,
          }))
        );
      },
    };
  });
