import { getRoot, types } from 'mobx-state-tree';
import { axiosInstance } from './axios';
// import Cookies from 'universal-cookie';
import { RootType, ApiListResponse } from '../internal';
import i18n from '../i18n';
import { HttpVerbs } from '../constants/verbs';
import { StatusCodes } from '../constants/statusCodes';

export enum ErrorStatus {
  'none' = '',
  'e400' = 'e400',
  'e401' = 'e401',
  'e403' = 'e403',
  'serverNotResponding' = 'serverNotResponding',
}

export const AxiosModel = types
  .model('AxiosModel', {
    error: types.maybeNull(
      types.union(types.enumeration(Object.values(ErrorStatus)), types.string)
    ),
    calls: types.map(types.string),
  })
  .views((self) => {
    return {
      get isLoading(): boolean {
        return !!self.calls.size;
      },
    };
  })
  .actions((self) => {
    return {
      addCall(call: string) {
        self.calls.set(call, call);
      },
      removeCall(call: string) {
        self.calls.delete(call);
      },
      setError(error: ErrorStatus | string) {
        self.error = error;
      },
      async uploadFile({
        path,
        fileKey,
        file,
        params,
        customErrorMessage,
      }: {
        path: string;
        fileKey: string;
        file: File;
        params?: any;
        customErrorMessage?: (error?: any) => string;
      }) {
        const formData = new FormData();
        formData.append(fileKey, file);
        return this.requestData({
          type: HttpVerbs.POST,
          path,
          params: params,
          data: formData,
          customErrorMessage,
        });
      },
      // gets all entities from a given endpoint, simply by
      // loading page by page and returning the resulting array
      async getAll(
        path: string,
        params: any = {}
      ): Promise<ApiListResponse<any>> {
        const totalData: any[] = [];
        let offset = 0;
        const limit = 100;
        let response: ApiListResponse<any> = await this.requestData({
          type: HttpVerbs.GET,
          path,
          params: {
            ...params,
            offset,
            limit,
          },
        });

        const total = response?.meta?.total;

        while (response?.data && response?.data?.length) {
          totalData.push(...response.data);
          offset += limit;
          if (offset >= total) {
            break;
          }
          response = await this.requestData({
            type: HttpVerbs.GET,
            path,
            params: {
              ...params,
              offset,
              limit,
            },
          });
        }

        return { data: totalData, meta: {}, statusCode: 200 };
      },
      async requestData({
        type,
        path,
        data,
        customErrorMessage,
        params,
        responseType,
      }: {
        type: HttpVerbs;
        path: string;
        data?: any;
        customErrorMessage?: (error?: any) => string;
        params?: any;
        responseType?: any;
      }): Promise<any | undefined> {
        const root: RootType = getRoot(self);
        const config = {
          method: type,
          url: path,
          params: params,
          auth: path === 'auth/login' ? data : undefined,
          data: data ? (path !== 'auth/login' ? data : undefined) : undefined,
          responseType: responseType,
        };
        if (path !== 'auth/login') {
          delete config.auth;
        }
        if (!data) {
          delete config.data;
        }
        this.addCall(path);
        const instance = axiosInstance(
          root.user.token || localStorage.getItem('token')
        );
        return new Promise((resolve, reject) => {
          instance(config)
            .then((response) => {
              this.removeCall(path);
              if (response.data) {
                this.setError(ErrorStatus.none);
                resolve(response.data);
              } else {
                resolve(response);
              }
            })
            .catch((error) => {
              console.log('Error "catch"...', error.response);
              this.removeCall(path);
              root.router.setIsLoading(false);
              // handle maintenance statis code
              if (error.response.status === StatusCodes.MAINTENANCE) {
                reject(error);
                return root.router.redirectToMaintenance();
              }
              if (error.response) {
                root.showFailure(
                  customErrorMessage
                    ? customErrorMessage(error)
                    : i18n.t(
                        `alert:e${
                          error?.response?.data?.error?.description?.replaceAll(
                            ' ',
                            ''
                          ) ||
                          error?.response?.status ||
                          error.toString()
                        }`
                      )
                );
              } else {
                root.showFailure(ErrorStatus.serverNotResponding);
              }
              reject(error);
              // if forbidden redirect to login(by logging out)
              if (error?.response?.status === 403) {
                return root?.api?.admin?.logout();
              }

              if (!root.router.currentView) {
                root.router.redirectToModerator();
                // root.router.redirectToCreateOffer();
              }
            });
        });
      },
    };
  });
