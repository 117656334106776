import { Instance, types } from 'mobx-state-tree';
import { ComponentModel, FunctionModel } from '../../../internal';

export const PaginationModel = types
  .compose(
    ComponentModel,
    types.model({
      currentPage: types.number,
      totalPages: types.number,
      pageChangedCallback: types.maybe(FunctionModel),
    })
  )
  .actions((self) => {
    return {
      setCurrentPage(pageNum: number) {
        self.currentPage = pageNum;
        self.root.data.setCurrentPage(pageNum);
      },
      setTotalPages(totalPages: number) {
        self.totalPages = totalPages;
      },
      next() {
        const potentialNextPage = self.currentPage + 1;
        self.currentPage =
          potentialNextPage <= self.totalPages
            ? potentialNextPage
            : self.totalPages;
        self.root.data.setCurrentPage(
          potentialNextPage <= self.totalPages
            ? potentialNextPage
            : self.totalPages
        );
        self.root.data.setSelectedPage('');
        if (self.pageChangedCallback) {
          self.pageChangedCallback(self.currentPage);
        }
      },
      previous() {
        const potentialPrevPage = self.currentPage - 1;
        self.currentPage = potentialPrevPage >= 1 ? potentialPrevPage : 1;
        self.root.data.setCurrentPage(
          potentialPrevPage >= 1 ? potentialPrevPage : 1
        );
        self.root.data.setSelectedPage('');
        if (self.pageChangedCallback) {
          self.pageChangedCallback(self.currentPage);
        }
      },
      next10() {
        const potentialNextPage = self.currentPage + 10;
        self.currentPage =
          potentialNextPage <= self.totalPages
            ? potentialNextPage
            : self.totalPages;
        if (self.pageChangedCallback) {
          self.pageChangedCallback(self.currentPage);
        }
      },
      previous10() {
        const potentialPrevPage = self.currentPage - 10;
        self.currentPage = potentialPrevPage >= 1 ? potentialPrevPage : 1;
        if (self.pageChangedCallback) {
          self.pageChangedCallback(self.currentPage);
        }
      },
      getPage(pageNum: number) {
        self.currentPage = pageNum;
        self.root.data.setCurrentPage(pageNum);
        self.root.data.setSelectedPage('');
        if (self.pageChangedCallback) {
          self.pageChangedCallback(self.currentPage);
        }
      },
    };
  })
  .named('PaginationModel');

export type PaginationModelType = Instance<typeof PaginationModel>;
