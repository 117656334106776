import { differenceInDays } from 'date-fns';
import { Instance, types } from 'mobx-state-tree';
import { PAYMENT_BY_TRANSACTION, STANDARD } from '../../../constants';
import {
  ActivatePackageModel,
  ComponentModel,
  Contract,
  ContractExtension,
  ModalModel,
  Partner,
  PurchasedExtension,
  ActivePackageModel,
  AdditionalPackageModel,
} from '../../../internal';
import { dateSRLocale, parseDate } from '../../../utils/date';
import { toAmount } from '../../../utils/methods';

export const PackageSelectionModel = types
  .compose(
    ComponentModel,
    types.model({
      additionalPackages: types.map(types.late(() => AdditionalPackageModel)),
      activePackages: types.map(types.late(() => ActivePackageModel)),
    })
  )
  .actions((self) => {
    return {
      async manageActivePackages() {
        await self.root.api.purchasedExtension.getAllPurchasedExtensions(
          self?.contract?.id
        );
        self.setActivePackages([
          {
            id: STANDARD,
            name: 'Članarina',
            valid_till: self?.contract?.to,
            price: '0',
          },
          ...self.root.data.purchasedExtensions,
        ]);
      },
      setActivePackages(packages: PurchasedExtension[]) {
        self.activePackages.clear();
        packages.forEach((elem: PurchasedExtension) => {
          self.activePackages.set(
            elem.id,
            ActivePackageModel.create({
              id: elem.id,
              name: elem.name,
              remainingUsers: elem.targets_remaining
                ? toAmount(Number(elem.targets_remaining))
                : '-',
              locationNumber: elem.locations
                ? toAmount(Number(elem.locations))
                : '-',
              validTo: dateSRLocale(parseDate(elem.valid_till)),
              paymentBy: self.isProvisionPartner
                ? `${toAmount(1)}%`
                : `${toAmount(1)} RSD`,
            })
          );
        });
      },
      initActivatePackageModel(elem: ContractExtension) {
        return ActivatePackageModel.create({
          id: 'ActivatePackageModel',
          name: elem.name,
          contractId: elem.contract_id,
          contractExtensionId: elem.id,
          onAfterActivate: () => {
            self.manageActivePackages();
          },
          modal: ModalModel.create({
            id: 'modal',
            showCloseButton: false,
            opened: false,
          }),
        });
      },
      initAdditionalPackageModel(elem: ContractExtension) {
        return AdditionalPackageModel.create({
          id: elem.id,
          name: elem.name,
          base: false,
          userNumber: toAmount(Number(elem.targets)),
          locationNumber: toAmount(Number(elem.locations)),
          durationDays: Number(elem.days_valid),
          oldMonthlyPayment: `${
            elem.old_price ? toAmount(Number(elem.old_price)) : ''
          }`,
          currentMonthlyPayment: `${toAmount(Number(elem.price))}`,
          activateModal: this.initActivatePackageModel(elem),
        });
      },
      setAdditionalPackages(contractExtensions: ContractExtension[]) {
        self.additionalPackages.clear();
        self.additionalPackages.set(
          '1',
          AdditionalPackageModel.create({
            id: '1',
            name: 'Članarina',
            base: true,
            userNumber: '-',
            locationNumber: '-',
            durationDays: 0,
            currentMonthlyPayment: `${toAmount(0)}`,
            activateModal: ActivatePackageModel.create({
              id: 'ActivatePackageModel',
              name: 'Članarina',
              modal: ModalModel.create({
                id: 'modal',
                showCloseButton: false,
                opened: false,
              }),
            }),
          })
        );
        [...contractExtensions]
          .sort((a, b) => (Number(a.price) < Number(b.price) ? -1 : 1))
          .forEach((elem: ContractExtension) => {
            self.additionalPackages.set(
              elem.id,
              this.initAdditionalPackageModel(elem)
            );
          });
      },
    };
  })
  .views((self) => {
    return {
      get additionalPackagesArray() {
        return Array.from(self.additionalPackages.values());
      },
      get activePackagesArray() {
        return Array.from(self.activePackages.values());
      },
      get contract(): Contract {
        return self.root.data.contractDetails;
      },
      get partner(): Partner {
        return self?.root?.data?.partnerDetails;
      },
      get isProvisionPartner() {
        return this?.partner?.cost_by === PAYMENT_BY_TRANSACTION;
      },
    };
  })
  .named('PackageSelectionModel');

export type PackageSelectionModelType = Instance<typeof PackageSelectionModel>;
