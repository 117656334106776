import { observer } from 'mobx-react';
import { FC, useState } from 'react';
import {
  AdsTableFilterModelType,
  AdsTableFiltersModelType,
} from '../../../models/domain/ad-list/AdsTableFiltersModel';
import { DropdownMultiselectModelType } from '../../../models/ui/dropdown-multiselect/DropdownMultiselectModel';
import { LightButton } from '../../UI/buttons/light-button/LightButton';
import { DropdownMultiselect } from '../../UI/dropdown-multiselect/DropdownMultiselect';
import styles from './AdsTableFilter.module.scss';
import filterIcon from './../../../assets/icons/filter_icon.png';
import searchIcon from './../../../assets/icons/search.svg';
import sortIcon from './../../../assets/icons/ascending_sorting_64px.png';
import { InputField } from '../../UI/input/InputField';
import { Sort } from '../../UI/sort/Sort';
import { DatePickerV2 } from '../../UI/DatePickerV2/DatePickerV2';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { DatePickerV2ModelType } from '../../../models/ui/DatePickerV2/DatePickerV2Model';
import AgeSlider from '../../UI/ageSlider/AgeSlider';
import { useMst } from '../../../internal';

export interface AdsTableFilterProps {
  model: AdsTableFilterModelType;
}

export const AdsTableFilter: FC<AdsTableFilterProps> = observer(({ model }) => {
  const [show, setShow] = useState<boolean | string>(false);

  const { currentPage } = useMst();

  const ref = useDetectClickOutside({
    onTriggered: () => {
      setShow(false);
    },
  });

  const allOrNoneCategoriesSelected = (): boolean => {
    return (
      currentPage
        .getColumnFilters()
        .filters.get('category_name')
        .filter.options.selectedCheckboxesIds.includes('allOptions') ||
      currentPage.getColumnFilters().filters.get('category_name').filter.options
        .selectedCheckboxesIds.length === 0
    );
  };

  if (model.type === 'text') {
    return (
      <div className={styles.filterWrapper} ref={ref}>
        {(!model.isCurrentFilter || !show) && (
          <img
            alt={'filter'}
            className={styles.filterIcon}
            src={searchIcon}
            onClick={(e) => {
              setShow(true);
              currentPage.getColumnFilters().setCurrentFilter(model);
              e.stopPropagation();
            }}
          />
        )}
        {model.isCurrentFilter && show && (
          <InputField className={styles.wideInput} model={model.filter} />
        )}
        {model.filter.value !== '' && (
          <div className={styles.resetButton}>
            <LightButton
              model={model.filter.resetButton}
              onClick={() => {
                model.filter.clearInput();
              }}
            >
              Resetuj
            </LightButton>
          </div>
        )}
      </div>
    );
  }

  if (model.type === 'searchAndMultiselect') {
    return (
      <div className={styles.filterWrapper} ref={ref}>
        <img
          className={styles.filterIcon}
          src={filterIcon}
          onClick={(e) => {
            setShow('multiselect');
            currentPage.getColumnFilters().setCurrentFilter(model);
            e.stopPropagation();
          }}
        />
        {(!show || !model.isCurrentFilter) && (
          <img
            className={styles.filterIcon}
            src={searchIcon}
            onClick={(e) => {
              setShow('search');
              currentPage.getColumnFilters().setCurrentFilter(model);
              e.stopPropagation();
            }}
          />
        )}

        {show === 'search' && model.isCurrentFilter && (
          <InputField
            className={styles.wideInput}
            model={model.filter.filterSearch}
          />
        )}
        {show === 'multiselect' && model.isCurrentFilter && (
          <DropdownMultiselect model={model.filter.filterMultiselect} />
        )}
        {(model.filter.filterSearch.value !== '' ||
          model.filter.filterMultiselect.selectedOptionsArray.length <=
            model.filter.filterMultiselect.optionsArray.length - 1) && (
          <div className={styles.resetButton}>
            <LightButton
              model={model.filter.filterSearch.resetButton}
              onClick={() => {
                model.filter.filterSearch.clearInput();
                model.filter.filterMultiselect.checkAll();
              }}
            >
              Resetuj
            </LightButton>
          </div>
        )}
      </div>
    );
  }

  if (model.type === 'date') {
    return (
      <div className={styles.filterWrapper} ref={ref}>
        <img
          className={styles.filterIcon}
          src={filterIcon}
          onClick={(e) => {
            currentPage.getColumnFilters().setCurrentFilter(model);
            setShow(true);
            e.stopPropagation();
          }}
        />
        {model.filter.date && (
          <div className={styles.resetButton}>
            <LightButton
              model={model.filter.resetButton}
              onClick={() => {
                model.filter.setDate(undefined);
              }}
            >
              Resetuj
            </LightButton>
          </div>
        )}
        {show && model.isCurrentFilter && (
          <DatePickerV2 model={model.filter} type="date" />
        )}
      </div>
    );
  }

  if (model.type === 'dateRange') {
    return (
      <div className={styles.filterWrapper} ref={ref}>
        <img
          className={styles.filterIcon}
          src={filterIcon}
          onClick={(e) => {
            setShow(true);
            currentPage.getColumnFilters().setCurrentFilter(model);
            e.stopPropagation();
          }}
        />
        {model.filter.dateRangeStart && model.filter.dateRangeEnd && (
          <div className={styles.resetButton}>
            <LightButton
              model={model.filter.resetButton}
              onClick={() => {
                model.filter.resetDateRange();
              }}
            >
              Resetuj
            </LightButton>
          </div>
        )}
        {show && model.isCurrentFilter && (
          <DatePickerV2 model={model.filter} type="dateRange" />
        )}
      </div>
    );
  }

  if (model.type === 'sort') {
    return (
      <div className={styles.filterWrapper}>
        <img
          className={styles.filterIcon}
          src={sortIcon}
          onClick={(e) => {
            setShow(true);
            currentPage.getColumnFilters().setCurrentFilter(model);
            model.filter.toggleType();
            e.stopPropagation();
          }}
        />
      </div>
    );
  }
  if (model.type === 'slider') {
    return (
      <div className={styles.filterWrapper} ref={ref}>
        <img
          className={styles.filterIcon}
          src={filterIcon}
          onClick={(e) => {
            setShow(true);
            currentPage.getColumnFilters().setCurrentFilter(model);
            e.stopPropagation();
          }}
        />
        {model.filter.isAgeFrom && model.filter.value !== 18 && (
          <div className={styles.resetButton}>
            <LightButton
              model={model.filter.resetButton}
              onClick={() => {
                model.filter.setValue(18);
              }}
            >
              Resetuj
            </LightButton>
          </div>
        )}
        {!model.filter.isAgeFrom && model.filter.value !== 18 && (
          <div className={styles.resetButton}>
            <LightButton
              model={model.filter.resetButton}
              onClick={() => {
                model.filter.setValue(18);
              }}
            >
              Resetuj
            </LightButton>
          </div>
        )}
        {show && model.isCurrentFilter && <AgeSlider model={model.filter} />}
      </div>
    );
  }

  if (model.type === 'multiselect') {
    return (
      <div ref={ref} className={styles.filterWrapper}>
        <img
          className={styles.filterIcon}
          src={filterIcon}
          onClick={(e) => {
            setShow(true);
            currentPage.getColumnFilters().setCurrentFilter(model);
            e.stopPropagation();
          }}
        />
        {model.filter.selectedOptionsArray.length <=
          model.filter.optionsArray.length - 1 && (
          <div className={styles.resetButton}>
            <LightButton
              model={model.filter.resetButton}
              onClick={() => model.filter.checkAll()}
            >
              Resetuj
            </LightButton>
          </div>
        )}
        {show && model.isCurrentFilter && (
          <DropdownMultiselect model={model.filter} />
        )}
      </div>
    );
  }

  if (model.type === 'multiselectNested') {
    {
      return (
        <div ref={ref} className={styles.filterWrapper}>
          <img
            style={{
              opacity: allOrNoneCategoriesSelected() ? '50%' : '',
              pointerEvents: allOrNoneCategoriesSelected() ? 'none' : 'auto',
            }}
            className={styles.filterIcon}
            src={filterIcon}
            onClick={(e) => {
              setShow(true);
              currentPage.getColumnFilters().setCurrentFilter(model);
              e.stopPropagation();
            }}
          />
          {model.filter.allSelectedNestedOptionsLength <=
            model.filter.allNestedOptionsLength - 1 && (
            <div className={styles.resetButton}>
              <LightButton
                model={model.filter.resetButton}
                onClick={() => model.filter.checkAll()}
              >
                Resetuj
              </LightButton>
            </div>
          )}
          {show && model.isCurrentFilter && (
            <DropdownMultiselect model={model.filter} />
          )}
        </div>
      );
    }
  }

  return <></>;
});
