import { Instance, types } from 'mobx-state-tree';
import { ComponentModel, ModalModel, FunctionModel } from '../../../internal';

export const ConfirmationModalModel = types
  .compose(
    ComponentModel,
    types.model({
      modal: types.late(() => ModalModel),
      text: types.maybe(types.string),
      labelNo: types.maybe(types.string),
      labelYes: types.maybe(types.string),
      afterNo: types.maybe(types.late(() => FunctionModel)),
      afterYes: types.maybe(types.late(() => FunctionModel)),
    })
  )
  .actions((self) => {
    return {
      setAfterYesCallback(val: () => void) {
        self.afterYes = val;
      },
      setAfterNoCallback(val: () => void) {
        self.afterNo = val;
      },
      open() {
        self.modal.setOpened(true);
      },
      close() {
        self.modal.setOpened(false);
      },
      yes() {
        self?.afterYes?.();
        self.close();
      },
      no() {
        self?.afterNo?.();
        self.close();
      },
    };
  })
  .views((self) => {
    return {};
  })
  .named('ConfirmationModalModel');

export type ConfirmationModalModelType = Instance<
  typeof ConfirmationModalModel
>;
