import { observer } from 'mobx-react';

import * as React from 'react';
import styles from './ActivatePackageModal.module.scss';
import { useTranslation } from 'react-i18next';
import { ActivatePackageModelType } from '../../../../../../internal';
import { Modal } from '../../../../../UI/modal/Modal';
import { Button } from '../../../../../UI/buttons/regular/Button';
import { templateReplace } from '../../../../../../utils/template';

export const ActivatePackageModal = observer(
  (props: { model: ActivatePackageModelType }): JSX.Element => {
    const { model } = props;
    const { t } = useTranslation();
    return (
      <Modal
        styles={{
          content: {
            minWidth: '40%',
            maxHeight: 'fit-content',
            borderRadius: '20px',
            padding: '40px',
          },
        }}
        model={model.modal}
      >
        <div className={styles.ModalHeader}>
          {' '}
          {templateReplace(
            t('basic:are_you_sure_activate'),
            'x',
            t(`packages:${model.name}`)
          )}
        </div>
        <div className={styles.ButtonModalContainer}>
          <button
            className={styles.BtnNo}
            onClick={() => {
              model.cancel();
            }}
          >
            {t('basic:no')}
          </button>
          <Button
            onClick={() => {
              model.activate();
            }}
          >
            {t('basic:yes')}
          </Button>
        </div>
      </Modal>
    );
  }
);
