import { observer } from 'mobx-react';
import * as React from 'react';
import styles from './PartnerAdList.module.scss';
import { AdListElemModelType, AdListModelType } from '../../../internal';
import { Pagination } from '../../UI/pagination/Pagination';
import { Show } from '../../UI/show/Show';
import { PartnerAdListElem } from './partner-ad-list-elem/PartnerAdListElem';

export const PartnerAdList = observer(
  (props: {
    model: AdListModelType;
    noElementsComponent: JSX.Element;
  }): JSX.Element => {
    const { model, noElementsComponent } = props;
    return (
      <div className={styles.Container}>
        <Show condition={!model?.adsArray?.length}>
          <div className={styles.NoElementsContainer}>
            {noElementsComponent}
          </div>
        </Show>
        <Show condition={model?.adsArray?.length}>
          {model.adsArray.map((elem: AdListElemModelType, index: number) => {
            return <PartnerAdListElem key={index} model={elem} />;
          })}
          <div className={styles.PaginationOuterContainer}>
            <Pagination model={model.pagination} />
          </div>
        </Show>
      </div>
    );
  }
);
