import { Instance, types } from 'mobx-state-tree';
import { ComponentModel, RadioButtonGroupModel } from '../../../../internal';

export const RadioButtonModel = types
  .compose(
    ComponentModel,
    types.model({
      parent: types.maybe(
        types.reference(types.late(() => RadioButtonGroupModel))
      ),
    })
  )
  .actions((self) => {
    return {
      onClick(e: any) {
        self?.parent?.setValue(self.id);
      },
      setParent(parent: string) {
        self.parent = parent;
      },
      onChange(e: any) {
        self?.parent?.setValue(self.id);
      },
    };
  })
  .views((self) => {
    return {
      get isSelected() {
        return self?.parent?.value === self.id;
      },
    };
  })
  .named('RadioButtonModel');

export type RadioButtonModelType = Instance<typeof RadioButtonModel>;
