import * as React from 'react';
import { observer } from 'mobx-react';

import styles from './StandardOffer.module.scss';
import { OfferPreview } from '../../OfferDetailsPreview';
import { toAmount } from '../../../../../utils/methods';

interface IOfferModel {
  model: OfferPreview;
}

export const StandardOffer = observer((props: IOfferModel) => {
  const { model } = props;

  const thumbnailStyles = {
    backgroundImage: `url(${model.imagesArray[0]})`,
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  };

  return (
    <>
      <div className={styles.StandardOffer}>
        <div className={styles.PhotoWrapper}>
          <div className={styles.Photo} style={thumbnailStyles} />
        </div>
        <div className={styles.Content}>
          <span className={styles.Store}>{model?.partner?.name}</span>
          <div className={styles.TitleWrapper}>
            <h2 className={styles.Title}>{model.name}</h2>
          </div>
          <div className={styles.Info}>
            {!!model.loweredPrice && (
              <div className={styles.Price}>
                {model.regularPrice && (
                  <span className={styles.OriginalPrice}>
                    {toAmount(Number(model.regularPrice))} RSD
                  </span>
                )}
                <span className={styles.Amount}>
                  {toAmount(model.loweredPrice)} RSD
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
});
