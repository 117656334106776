import { getRoot, Instance, types } from 'mobx-state-tree';
import { RootType, CheckboxGroupModel } from '../../../internal';

export const DropdowOptionGroupsModel = types
  .model({
    id: types.identifier,
    optionGroups: types.union(
      types.maybe(types.late(() => CheckboxGroupModel)),
      types.optional(types.map(types.late(() => CheckboxGroupModel)), {})
    ),
    options: types.union(
      types.maybe(types.late(() => CheckboxGroupModel)),
      types.optional(types.map(types.late(() => CheckboxGroupModel)), {})
    ),
    isOpen: types.boolean,
  })
  .views((self) => {
    return {
      get root(): RootType {
        return getRoot(self);
      },
      get optionGroupsArray() {
        return self.optionGroups?.checkboxesArray;
      },
      get selectedOptionGroupsArray() {
        return self.optionGroups?.selectedCheckboxesIds;
      },
      get optionsArray() {
        return self.options?.checkboxesArray;
      },
      get selectedOptionsArray() {
        return self.options?.selectedCheckboxesIds;
      },
    };
  })
  .actions((self) => {
    return {
      setOptions: (options: any[]) => {
        options.forEach((option: any) => {
          self.options.add(option);
        });
      },
      setOptionGroups: (optionGroups: any[]) => {
        optionGroups.forEach((optionGroup: any) => {
          self.optionGroups.add(optionGroup);
        });
      },
      closeDropdown() {
        self.isOpen = false;
      },
      toggleIsOpen() {
        self.isOpen = !self.isOpen;
      },
      checkAllOptionGroups() {
        self.optionGroups.toggleAll(true);
      },
      checkAllOptions() {
        self.options.toggleAll(true);
      },

      setDisabledOptionGroups() {
        self.optionGroupsArray.map((optionGroup: any) => {
          if (!self.selectedOptionGroupsArray.includes(optionGroup.id)) {
            optionGroup.setDisabled(true);
          }
        });
      },

      setEnabledOptionGroups() {
        self.optionGroupsArray.map((optionGroup: any) => {
          optionGroup.setDisabled(false);
        });
      },

      setDisabledOptions() {
        self.optionsArray.map((option: any) => {
          if (!self.selectedOptionsArray.includes(option.id)) {
            option.setDisabled(true);
          }
        });
      },
      setEnabledOptions() {
        self.optionsArray.map((option: any) => {
          option.setDisabled(false);
        });
      },
      setDisabledOptionsInsideOptionGroup(optionIds: any) {
        self.optionsArray.map((option: any) => {
          if (optionIds.includes(option.id)) {
            option.setDisabled(true);
          }
        });
      },
      clearAllOptions() {
        self.optionGroups.deselectAll();
        self.options.deselectAll();
      },
    };
  });

export type DropdowOptionGroupsModelType = Instance<
  typeof DropdowOptionGroupsModel
>;
