import { observer } from 'mobx-react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { RoundButtonModelType } from '../../../../internal';
import styles from './RoundButton.module.scss';

export const RoundButton = observer(
  (props: { model: RoundButtonModelType }): JSX.Element => {
    const { model } = props;
    const { t } = useTranslation(['basic']);
    return (
      <div
        className={`${
          model.isSelected ? styles.BtnContainerActive : styles.BtnContainer
        } ${model.isDisabled ? styles.Disabled : ''}`}
        onClick={() => {
          model.onClick(null);
        }}
      >
        <div className={styles.BtnContent}>{t(model.label)}</div>
      </div>
    );
  }
);
