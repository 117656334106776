import React, { useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import { PageRoutes, RootType, useMst } from '../../internal';
import { useTranslation } from 'react-i18next';
import styles from './Login.module.scss';
import { InputField } from '../../components/UI/input/InputField';
import ReCAPTCHA from 'react-google-recaptcha';
import { Button } from '../../components/UI/buttons/regular/Button';
import Link from '../../components/UI/link';
import { PasswordField } from '../../components/UI/password/PasswordField';

const Login = observer(() => {
  const { t } = useTranslation();
  const root: RootType = useMst();
  const {
    currentPage,
    router,
    currentPage: {
      getUserNameModel,
      getPasswordModel,
      getSubmitModel,
      setCaptcha,
    },
  } = root;

  const captchaRef = useRef<ReCAPTCHA>(null);

  useEffect(() => {
    const handleSubmit = (e: { key: string }) => {
      if (e.key === 'Enter') {
        getSubmitModel().click();
      }
    };
    window.addEventListener('keypress', handleSubmit);
    return () => {
      window.removeEventListener('keypress', handleSubmit);
    };
  }, []);

  const handleCaptchaChange = (value: string | null) => {
    setCaptcha(value);
  };

  return currentPage && currentPage.id === PageRoutes.Login.id ? (
    <div className={styles.LoginContainer}>
      <div className={styles.Login}>
        <h1 className={styles.Title}>{t('basic:login')}</h1>
        <form
          onSubmit={(event) => {
            captchaRef?.current?.reset();
            event.preventDefault();
          }}
          className={styles.Form}
          noValidate
        >
          <div className={styles.OuterInputFieldContainer}>
            <InputField model={getUserNameModel()} />
          </div>
          <div className={styles.OuterInputFieldContainer}>
            <PasswordField model={getPasswordModel()} />
          </div>
          {currentPage.showCaptcha && (
            <div className={styles.CaptchaContainer}>
              <ReCAPTCHA
                ref={captchaRef}
                sitekey={process.env.REACT_APP_RECAPTCHA_KEY ?? ''}
                onChange={handleCaptchaChange}
              />
            </div>
          )}
          <div className={styles.LoginBtnContainer}>
            <Button model={getSubmitModel()}></Button>
          </div>
          <div className={styles.OuterInputFieldContainer}>
            <Link
              text={t('basic:forgot_password_question')}
              href={`${
                process.env.REACT_APP_PATH_SUFFIX || ''
              }/partner/forgot-password`}
              view={router.views.get(PageRoutes.ForgotPassword.id)}
            />
          </div>
        </form>
      </div>
    </div>
  ) : null;
});

export default Login;
