import { observer } from 'mobx-react';

import { AdministratorsModelType } from '../../../models/domain/administrators/AdministratorsModel';
import { Modal } from '../../UI/modal/Modal';
import styles from './CreateUpdateAdministratorModal.module.scss';
import React from 'react';
import { Button } from '../../UI/buttons/regular/Button';
import { templateReplace } from '../../../utils/template';
import { useTranslation } from 'react-i18next';
import { useMst } from '../../../internal';

export const DeleteAdministratorModal = observer(
  (props: { model: AdministratorsModelType }): JSX.Element => {
    const { model } = props;
    const { currentPage } = useMst();
    const { t } = useTranslation();
    return (
      <Modal
        styles={{
          content: {
            minWidth: '30%',
            maxHeight: 'fit-content',
            borderRadius: '20px',
            padding: '40px',
          },
        }}
        model={model.deleteAdminModal}
      >
        <div className={styles.ModalHeader}>
          {' '}
          {templateReplace(t('basic:delete_admin_question'), 'x', model.name)}
        </div>
        <div className={styles.ButtonModalContainer}>
          <Button
            onClick={() => {
              model.deleteAdmin().then(() => {
                model.deleteAdminModal.setOpened(false);
                model.modal.setOpened(false);
                currentPage.loadAdmins();
              });
            }}
          >
            {t('basic:yes')}
          </Button>
          <button
            className={styles.BtnNo}
            onClick={() => {
              model.deleteAdminModal.setOpened(false);
            }}
          >
            {t('basic:no')}
          </button>
        </div>
      </Modal>
    );
  }
);
