import React from 'react';
import styles from './NumberedPagination.module.scss';
import { usePagination, DOTS } from './usePagination';
import { PaginationModelType, useMst } from '../../../internal';
import { observer } from 'mobx-react';
import prev from '../../../assets/icons/icon-back.png';
import prevGray from '../../../assets/icons/icon-back-gray.png';
import next from '../../../assets/icons/icon-forward.png';
import nextGray from '../../../assets/icons/icon-forward-gray.png';

const NumberedPagination = observer(
  (props: {
    model: PaginationModelType;
    setAlert: (alert: boolean) => void;
  }): JSX.Element => {
    const { model, setAlert } = props;
    const {
      data: { pageSize },
    } = useMst();
    const currentPage = model.currentPage;
    const totalCount = model.totalPages;
    const siblingCount = 1;
    const paginationRange = usePagination({
      currentPage,
      totalCount,
      siblingCount,
      pageSize,
    });
    const lastPage = paginationRange ? [paginationRange?.length - 1] : '';

    function uuidv4() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
        /[xy]/g,
        function (c) {
          const r = (Math.random() * 16) | 0,
            v = c == 'x' ? r : (r & 0x3) | 0x8;
          return v.toString(16);
        }
      );
    }

    return (
      <ul className={styles.PaginationContainer}>
        <li
          className={`${styles.PaginationItem} ${
            currentPage === 1 ? styles.Disabled : ''
          }`}
          onClick={() => {
            model.previous();
            setAlert(false);
          }}
        >
          <div className={currentPage == 1 ? styles.Gray : styles.Prev}>
            <img src={currentPage == 1 ? prevGray : prev} alt="prev" />
          </div>
        </li>
        {paginationRange?.map((pageNumber) => {
          if (pageNumber === DOTS) {
            return (
              <li key={uuidv4()} className={styles.Dots}>
                &#8230;
              </li>
            );
          }

          return (
            <li
              className={`${styles.PaginationItem} ${
                pageNumber === currentPage ? styles.Selected : ''
              }`}
              key={pageNumber}
              onClick={() => {
                model.getPage(pageNumber);
                setAlert(false);
              }}
            >
              {pageNumber}
            </li>
          );
        })}
        <li
          className={`${styles.PaginationItem} ${
            currentPage === lastPage ? styles.Disabled : ''
          }`}
          onClick={() => {
            model.next();
            setAlert(false);
          }}
        >
          <div
            className={currentPage == totalCount ? styles.Gray : styles.Next}
          >
            <img src={currentPage == totalCount ? nextGray : next} alt="next" />
          </div>
        </li>
      </ul>
    );
  }
);

export default NumberedPagination;
