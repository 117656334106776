import { Instance, types } from 'mobx-state-tree';
import i18n from '../../../../i18n';
import {
  ButtonModel,
  ComponentModel,
  ModalModel,
  PartnerLocation,
  TabsModel,
  TextInputModel,
  FunctionModel,
  DropdownModel,
  CheckboxGroupModel,
  CheckboxModel,
  ShoppingMall,
} from '../../../../internal';
import {
  DEFAULT_LATITUDE,
  DEFAULT_LONGITUDE,
} from '../../../../utils/google-maps';
import { validateEmail } from '../../../../validators/validators';
import { IS_SHOPPING_MALL } from '../../../../constants/companyprofile';

export const StoreLocationModalModel = types
  .compose(
    ComponentModel,
    types.model({
      name: types.late(() => TextInputModel),
      getShoppingMallGroup: types.late(() => CheckboxGroupModel),
      shoppingMallLocation: types.late(() => DropdownModel),
      shoppingMall: types.late(() => DropdownModel),
      address: types.late(() => TextInputModel),
      latitude: types.maybe(types.number),
      longitude: types.maybe(types.number),
      city: types.maybe(types.string),
      workingHoursWeekday: types.late(() => TextInputModel),
      workingHoursSaturday: types.late(() => TextInputModel),
      workingHoursSunday: types.late(() => TextInputModel),
      phone: types.late(() => TextInputModel),
      email: types.late(() => TextInputModel),
      saveBtn: types.late(() => ButtonModel),
      modal: types.late(() => ModalModel),
      tabs: types.late(() => TabsModel),
      onSaveCallback: types.maybe(types.late(() => FunctionModel)),
      locationId: types.maybeNull(types.string),
      markerDraggable: types.maybe(types.boolean),
    })
  )
  .actions((self) => {
    return {
      afterCreate() {
        self.email.addValidator('validateEmail', validateEmail);
        self.modal.setOnAfterClose(() => {
          self.clear();
        });
      },
      setMarkerDraggable(val: boolean) {
        self.markerDraggable = val;
      },
      checkIfIsValid() {
        const children = [
          self.name,
          self.address,
          self.workingHoursWeekday,
          self.workingHoursSaturday,
          self.workingHoursSunday,
          self.phone,
          self.email,
        ];
        let valid = true;
        for (const child of children) {
          child.runValidators();
          if (child.isInvalid) {
            valid = false;
          }
        }
        if (!self.coordinatesSet) {
          self.root.showFailure(i18n.t('alert:coordinates_not_set'));
          valid = false;
        }
        return valid;
      },
      clear() {
        self.name.clearData();
        self.address.clearData();
        self.resetLatLng();
        self.workingHoursWeekday.clearData();
        self.workingHoursSaturday.clearData();
        self.workingHoursSunday.clearData();
        self.phone.clearData();
        self.email.clearData();
        self.locationId = null;
        self.setMarkerDraggable(false);
        self.tabs.selectIndex(0);
        self.getShoppingMallGroup.deselectAll();
        self.shoppingMallLocation.selectOption(0);
        self.shoppingMall.selectOption(0);
      },
      openModal() {
        self.modal.setOpened(true);
      },
      closeModal() {
        self.modal.setOpened(false);
      },
      setAddress(address: string) {
        self.address.setValue(address);
      },
      setLatLng(latitude: number | string, longitude: number | string) {
        if (latitude === undefined || longitude === undefined) {
          return;
        }
        self.latitude = Number(latitude);
        self.longitude = Number(longitude);
        self.manageCity();
      },
      async manageCity() {
        const city = await self.root.api.geolocation.geolocationToLocality({
          lat: self.latitude,
          lng: self.longitude,
        });
        self.setCity(city);
      },
      setCity(city: string) {
        self.city = city;
      },
      // sync coordinates to address
      async syncCoordinates() {
        try {
          const coordinates: { lat: number; lng: number } =
            await self.root.api.geolocation.addressToGeolocation(
              self.address.value
            );
          self.setLatLng(coordinates.lat, coordinates.lng);
        } catch (err) {
          console.log('Geolocation API error: ' + err);
          if (!self.coordinatesSet) {
            self.setLatLng(DEFAULT_LATITUDE, DEFAULT_LONGITUDE);
          }
        }
      },
      // sync address to coordinates
      async syncAddress() {
        try {
          const address: string =
            await self.root.api.geolocation.geolocationToAddress({
              lat: self.latitude,
              lng: self.longitude,
            });
          if (address) {
            self.address.setValue(address);
          }
        } catch (err) {
          console.log('Geolocation API error: ' + err);
        }
      },
      resetLatLng() {
        self.latitude = undefined;
        self.longitude = undefined;
        self.city = undefined;
      },
      async save() {
        if (!this.checkIfIsValid()) {
          self.root.showFailure(i18n.t('alert:form_invalid'));
          return;
        }
        if (!self.getShoppingMallGroup.selectedIdsStr) {
          self.location.shopping_mall_id = null;
        }
        if (self.locationId) {
          await self.root.api.partnerLocation.modify(self.location);
        } else {
          await self.root.api.partnerLocation.add(self.location);
        }
        self.closeModal();
        self?.onSaveCallback?.();
      },
      setLocation(location: PartnerLocation) {
        self.name.setValue(location.name);
        self.address.setValue(location.address);
        self.setLatLng(location.latitude, location.longitude);
        self.workingHoursWeekday.setValue(location.work_days);
        self.workingHoursSaturday.setValue(location.saturday);
        self.workingHoursSunday.setValue(location.sunday);
        self.phone.setValue(location.phone);
        self.email.setValue(location.email);
        self.locationId = location.id ? location.id : null;
        self.setCity(location.city);
        if (location.shopping_mall_id) {
          // select shopping mall and location group by their indices in dropdown
          self.getShoppingMallGroup.check(
            CheckboxModel.create({ id: IS_SHOPPING_MALL })
          );
          // find shopping malllocation group
          const shoppingMallIndex = self.root.data.shoppingMalls.findIndex(
            (mall: ShoppingMall) => mall.id === location.shopping_mall_id
          );
          const shoppingMallLocations: any[] = [
            ...new Set(
              self.root.data.shoppingMalls.map(
                (mall: ShoppingMall) => mall.location_group
              )
            ),
          ];
          let selectedLocationGroupMalls: any = [];
          // find shopping mall indice in selected location group dropdown
          const shoppingMallLocationsIndex = shoppingMallLocations.findIndex(
            (locationGroup: string) => {
              const selectedLocationGroup =
                self.root.data.shoppingMalls[shoppingMallIndex].location_group;
              selectedLocationGroupMalls = self.root.data.shoppingMalls.filter(
                (el: any) => el.location_group === selectedLocationGroup
              );
              return locationGroup === selectedLocationGroup;
            }
          );
          const shoppingMallIndexInSelectedLocationGroup =
            selectedLocationGroupMalls.findIndex(
              (mall: ShoppingMall) => mall.id === location.shopping_mall_id
            );

          self.shoppingMallLocation.selectOption(shoppingMallLocationsIndex);
          self.shoppingMall.selectOption(
            shoppingMallIndexInSelectedLocationGroup
          );
        }
      },
      cancel() {
        self.closeModal();
      },
    };
  })
  .views((self) => {
    return {
      get coordinatesSet(): boolean {
        return self.latitude !== undefined && self.longitude !== undefined;
      },
      get location(): PartnerLocation {
        return {
          id: self.locationId,
          partner_id: self?.root?.data?.partnerDetails?.id,
          name: self?.name?.value,
          address: self?.address?.value,
          work_days: self?.workingHoursWeekday?.value,
          saturday: self?.workingHoursSaturday?.value,
          sunday: self?.workingHoursSunday?.value,
          phone: self?.phone?.value,
          email: self?.email?.value,
          latitude: self?.latitude,
          longitude: self?.longitude,
          city: self.city,
          shopping_mall_id: self.shoppingMall.value,
        };
      },
    };
  })
  .named('StoreLocationModalModel');

export type StoreLocationModalModelType = Instance<
  typeof StoreLocationModalModel
>;
