import { Instance, types } from 'mobx-state-tree';
import { RoundButtonGroupModel, ComponentModel } from '../../../../internal';

export const RoundButtonModel = types
  .compose(
    ComponentModel,
    types.model({
      parent: types.maybe(
        types.reference(types.late(() => RoundButtonGroupModel))
      ),
    })
  )
  .actions((self) => {
    return {
      onClick(e: any) {
        self?.parent?.setValue(self.id);
      },
      setParent(parent: string) {
        self.parent = parent;
      },
    };
  })
  .views((self) => {
    return {
      get isSelected() {
        return self?.parent?.value === self.id;
      },
    };
  })
  .named('RoundButtonModel');

export type RoundButtonModelType = Instance<typeof RoundButtonModel>;
