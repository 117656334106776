import { Instance, types } from 'mobx-state-tree';
import {
  ButtonModel,
  ComponentModel,
  DatePickerModel,
  DropdownModel,
} from '../../../internal';

export const OfferFiltersModel = types
  .compose(
    ComponentModel,
    types.model({
      // company: types.late(() => DropdownModel),
      // category: types.late(() => DropdownModel),
      // subcategory: types.late(() => DropdownModel),
      // dateFrom: types.late(() => DatePickerModel),
      // dateTo: types.late(() => DatePickerModel),
      columns: types.late(() => DropdownModel),
    })
  )
  .actions((self) => {
    return {};
  })
  .views((self) => {
    return {};
  })
  .named('OfferFiltersModel');

export type OfferFiltersModelType = Instance<typeof OfferFiltersModel>;
