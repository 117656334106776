import { parse } from 'date-fns';

export function dateSRLocale(date: Date | any): string {
  if (!date) {
    return '';
  }
  const monthNum = date.getMonth() + 1;
  const day = date.getDate();
  return `${day >= 10 ? day : `0${date.getDate(day)}`}.${
    monthNum < 10 ? `0${monthNum}` : monthNum
  }.${date.getFullYear()}`;
}

export function formattedTime(date: Date): string {
  return `${date.getHours()}:${
    date.getMinutes() >= 10 ? `${date.getMinutes()}` : `0${date.getMinutes()}`
  }`;
}

// date formatter for sending the data to the BE
export function dateFormatter(date: Date): string {
  if (!date) {
    return '';
  }
  const month =
    date.getMonth() + 1 >= 10 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`;
  const day = date.getDate() >= 10 ? date.getDate() : `0${date.getDate()}`;
  return `${date.getFullYear()}-${month}-${day}`;
}

export function parseDate(dateStr: string): Date {
  return parse(dateStr, 'yyyy-MM-dd', new Date());
}
