import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { DurationModelType } from '../../../../models/domain/duration/DurationModel';
import { Modal } from '../../../../components/UI/modal/Modal';
import { StandardDuration } from '../../../../components/domain/duration/StandardDuration';
import styles from '../CreateBanner.module.scss';

export const BannerDurationModal = observer(
  (props: { model: DurationModelType }): JSX.Element => {
    const { model } = props;
    const { t } = useTranslation();
    return (
      <Modal
        model={model.modal}
        styles={{
          content: {
            maxHeight: 'fit-content',
            top: '5%',
          },
        }}
      >
        <StandardDuration
          model={model}
          startRangeText={t('contract_duration:start')}
          endRangeText={t('contract_duration:end')}
        >
          <div className={styles.TextHeader}>
            <div className={styles.PickDatesIntro}>
              {t('banners:pick_dates_intro')}
            </div>
            <div className={styles.CampaignDurationDisclaimer}>
              {model.maxDurationText}
            </div>
          </div>
        </StandardDuration>
      </Modal>
    );
  }
);
