import { getRoot, types } from 'mobx-state-tree';
import {
  PageModel,
  TabsModel,
  TabModel,
  PaymentsAndDocumentsTableModel,
  PartnerApi,
  RootType,
  TabModelType,
  TabsModelType,
} from '../../internal';

export interface IDocument {
  id: string;
  name: string;
  date: string;
}

export interface IInvoice {
  invoice_ads: any[];
  partner_id: string;
  contract_id: string;
  year: number;
  month: number;
  number: number;
  is_paid: number;
  total: number;
  subtotal: number;
  discount_telco_value: number;
  discount_telco_amount: number;
  discount_soho_value: number;
  discount_soho_amount: number;
  discount_quantity_value: number;
  discount_quantity_amount: number;
  discount_so_value: number;
  discount_so_amount: number;
  discount_btl_value: number;
  discount_btl_amount: number;
  product_basic: number;
  product_segmentation: number;
  product_paygo_addon: number;
  product_subscription: number;
  product_addon: number;
  id: string;
  period_start: string;
  period_end: string;
  calculator:
    | 'subscription-with-contract-obligation'
    | 'subscription'
    | 'pay-as-you-go';
}

export const PaymentsAndDocumentsModel = types
  .compose(
    PageModel,
    types.model({
      documents: types.optional(types.array(types.frozen<IDocument>()), []),
      years: types.array(types.string),
      invoicesByMonthsForSelectedYear: types.array(types.frozen()),
      currentInvoices: types.array(types.frozen<IInvoice>()),
      currentlyShownHistoricalInvoices: types.maybe(
        types.array(types.frozen<IInvoice>())
      ),
    })
  )
  .views((self) => {
    return {
      get root(): RootType {
        return getRoot(self);
      },
      get yearsTabsObject() {
        const years: any = {};
        return self.years
          .slice()
          .reverse()
          .map((year: string) => {
            return {
              id: year,
              name: year,
            };
          });
      },
      get currentInvoicesSum() {
        return self.currentInvoices.reduce(
          (sum, invoice) => sum + invoice.total,
          0
        );
      },
      get currentlyShownHistoricalInvoicesSum() {
        return self.currentlyShownHistoricalInvoices?.reduce(
          (sum, invoice) => sum + invoice.total,
          0
        );
      },
    };
  })
  .actions((self) => {
    return {
      async beforePageEnter() {
        const id = JSON.parse(localStorage.getItem('partner') || '{}')?.id;
        if (!self.root.data.partnerDetails) {
          await self.root.api.partner.get(id);
        }
        await Promise.all([
          self.root.api.partner.getAllPartnerDocuments(id),
          self.root.api.invoices.getInvoicesYears(id),
          self.root.api.invoices.getInvoicesByYear(
            id,
            new Date().getFullYear()
          ),
          self.root.api.contract.getContracts(id),
          self.root.api.invoices.getCurrentInvoice(id),
        ]).then((responses: any[]) => {
          this.setDocuments(responses[0].data);
          this.setYears(responses[1].data);

          const invoicesByMonthsForSelectedYearReducedByMonth =
            responses[2].data.reduce((acc: any, { month, total }: any) => {
              acc[month] = acc[month] || { month: month, total: 0 };
              acc[month].total += total;
              return acc;
            }, []);

          invoicesByMonthsForSelectedYearReducedByMonth.reverse();

          this.setInvoicesByMonthsForSelectedYear(
            invoicesByMonthsForSelectedYearReducedByMonth
          );

          this.setCurrentInvoices(responses[4].data);
          for (const invoice of responses[4].data) {
            if (invoice.invoice_ads) {
              this.getInvoiceAds().setElements(invoice.invoice_ads);
            }
          }
        });

        return Promise.resolve(true);
      },
      setDocuments(documents: any) {
        self.documents = documents;
      },
      setYears(years: any) {
        self.years = years;
        const tabsModel = this.getHistoryMonthlyInvoicesYearsTabs();
        tabsModel.clearTabs();
        self.yearsTabsObject.forEach((tab: TabModelType) => {
          tabsModel.addTab(tab);
        });
      },
      setInvoicesByMonthsForSelectedYear(invoicesByMonthsForSelectedYear: any) {
        self.invoicesByMonthsForSelectedYear = invoicesByMonthsForSelectedYear;
      },
      setCurrentInvoices(invoices: any) {
        self.currentInvoices = invoices;
      },

      getInvoiceAds() {
        return (
          self.components.get('PaymentsAndDocumentsTable') ||
          self.addComponent(
            PaymentsAndDocumentsTableModel.create({
              id: 'PaymentsAndDocumentsTable',
              rows: {},
              columns: {
                ad_name: {
                  id: 'ad_name',
                  label: 'Oglas',
                },
                status: {
                  id: 'status',
                  label: 'Status',
                },
                price_basic_per_day: {
                  id: 'price_basic_per_day',
                  label: 'Osnovno oglašavanje',
                },
                price_targeted_per_day: {
                  id: 'price_targeted_per_day',
                  label: 'Izbor ciljne grupe',
                },
                price_top_per_day: {
                  id: 'price_top_per_day',
                  label: 'Top oglas',
                },
                subtotal_per_day: {
                  id: 'subtotal_per_day',
                  label: 'Ukupno po danu',
                },
                start: {
                  id: 'start',
                  label: 'Datum početka',
                },
                end: {
                  id: 'end',
                  label: 'Datum kraja',
                },
                days: {
                  id: 'days',
                  label: 'Broj dana',
                },
                subtotal: {
                  id: 'subtotal',
                  label: 'Ukupno po oglasu',
                },
              },
            })
          )
        );
      },
      getPaymentsAndDocumentsTabs() {
        return (
          self.components.get('PaymentsAndDocumentsTabs') ||
          self.addComponent(
            TabsModel.create({
              id: 'PaymentsAndDocumentsTabs',
              tabs: {
                trenutnoZaduzenje: TabModel.create({
                  id: 'trenutnoZaduzenje',
                  name: 'Trenutno zaduženje',
                }),
                istorijaRacuna: TabModel.create({
                  id: 'istorijaRacuna',
                  name: 'Istorija računa',
                }),
                dokumenti: TabModel.create({
                  id: 'dokumenti',
                  name: 'Dokumenti',
                }),
              },
              selectedTab: 'trenutnoZaduzenje',
              onChangeCallback: async (selectedTabId: string) => {
                if (selectedTabId === 'trenutnoZaduzenje') {
                  const id = JSON.parse(
                    localStorage.getItem('partner') || '{}'
                  )?.id;
                  if (!self.root.data.partnerDetails) {
                    await self.root.api.partner.get(id);
                  }
                  self.root.api.invoices
                    .getCurrentInvoice(id)
                    .then((response: any) => {
                      for (const invoice of response.data) {
                        if (invoice.invoice_ads) {
                          this.getInvoiceAds().setElements(invoice.invoice_ads);
                        }
                      }
                    });
                }
                if (selectedTabId === 'istorijaRacuna') {
                  this.setCurrentlyShownHistoricalInvoicesAction(undefined);
                  this.getHistoryMonthlyInvoicesYearsTabs().selectIndex(0);
                }
              },
            })
          )
        );
      },
      getHistoryMonthlyInvoicesYearsTabs() {
        return (
          self.components.get('HistoryMonthlyInvoicesYearsTabs') ||
          self.addComponent(
            TabsModel.create({
              id: 'HistoryMonthlyInvoicesYearsTabs',
              selectedTab: new Date().getFullYear().toString(),
              onChangeCallback: async (selectedTab: string) => {
                const invoicesByMonthsForSelectedYearResponse =
                  await self.root.api.invoices.getInvoicesByYear(
                    self.root.data.partnerDetails.id,
                    Number(selectedTab)
                  );

                const invoicesByMonthsForSelectedYearReducedByMonth =
                  invoicesByMonthsForSelectedYearResponse.data.reduce(
                    (acc: any, { month, total }: any) => {
                      acc[month] = acc[month] || { month: month, total: 0 };
                      acc[month].total += total;
                      return acc;
                    },
                    []
                  );

                invoicesByMonthsForSelectedYearReducedByMonth.reverse();

                this.setInvoicesByMonthsForSelectedYear(
                  invoicesByMonthsForSelectedYearReducedByMonth
                );
              },
              afterCreateFunc: (selfie: TabsModelType) => {
                self.yearsTabsObject.forEach((tab: TabModelType) => {
                  selfie.addTab(tab);
                });
              },
            })
          )
        );
      },
      setCurrentlyShownHistoricalInvoices(month: number) {
        self.root.api.invoices
          .getInvoicesByYear(
            self.root.data.partnerDetails.id,
            Number(this.getHistoryMonthlyInvoicesYearsTabs().selectedTab)
          )
          .then((response: any) => {
            const filteredInvoices = response.data.filter(
              (invoice: IInvoice) => invoice.month === month
            );
            this.setCurrentlyShownHistoricalInvoicesAction(filteredInvoices);

            for (const invoice of filteredInvoices) {
              if (invoice.invoice_ads) {
                this.getInvoiceAds().setElements(invoice.invoice_ads);
              }
            }
          });
      },
      setCurrentlyShownHistoricalInvoicesAction(
        currentlyShownHistoricalInvoices: any
      ) {
        self.currentlyShownHistoricalInvoices =
          currentlyShownHistoricalInvoices;
      },
      resetCurrentlyShownHistoricalInvoices() {
        self.currentlyShownHistoricalInvoices = undefined;
      },
    };
  })
  .named('PaymentsAndDocumentsModel');
