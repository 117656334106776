import { observer } from 'mobx-react';
import { ReactElement } from 'react';
import { useMst } from '../../../internal';
import styles from './AllAds.module.scss';
import { useTranslation } from 'react-i18next';
import { OffersFilter } from '../../../components/domain/offer-filters/OfferFilters';
import { Table } from '../../../components/domain/ad-list/Table';

export const AllAds = observer((): ReactElement => {
  const { t } = useTranslation();
  const { currentPage } = useMst();

  return (
    <div className={styles.PageContainer}>
      <div className={styles.PageTitleContainer}>
        <div className={styles.PageTitle}>{t('basic:all_offers_view')}</div>
      </div>
      <OffersFilter />
      <div className={styles.AdListOuterContainer}>
        <Table model={currentPage.getAdList()} isAdTable={true} />
      </div>
    </div>
  );
});
