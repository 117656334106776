import { RootType, ApiListResponse, ApiObjectResponse } from '../../internal';
import { getRoot, types } from 'mobx-state-tree';
import { HttpVerbs } from '../../constants/verbs';
import { dateFormatter } from '../date';

export interface PurchasedExtension {
  id: string;
  contract_id: string;
  contract_extension_id: string;
  targets_initial: string;
  targets_add_on: string;
  targets_remaining: string;
  locations_initial: string;
  locations_add_on: string;
  locations: string;
  date_purchased: string;
  price: string;
  valid_till: string;
  active: string;
  name: string;
}

export const PurchasedExtensionApi = types
  .model('PurchasedExtensionApi', {})
  .views((self) => {
    return {
      get root(): RootType {
        return getRoot(self);
      },
    };
  })
  .actions((self) => {
    return {
      activatePackage(newPackage: {
        contract_extension_id: string;
        contract_id: string;
      }) {
        return new Promise((resolve) => {
          self.root.axios
            .requestData({
              type: HttpVerbs.POST,
              path: '/purchased-extensions',
              data: {
                ...newPackage,
                targets_initial: 0,
                targets_add_on: 0,
                targets_remaining: 0,
                locations_initial: 0,
                locations_add_on: 0,
                locations: 0,
                date_purchased: new Date(),
                price: 0,
                valid_till: dateFormatter(new Date()),
                active: 1,
              },
            })
            .then((response: ApiObjectResponse<PurchasedExtension>) => {
              resolve(response);
            });
        });
      },
      async getAllPurchasedExtensions(contractId: string) {
        const response = await self.root.axios.getAll('/purchased-extensions', {
          contract_id: contractId,
        });
        self.root.data.setPurchasedExtensions(response.data);
        return response;
      },
      getPurchasedExtensions(contractId: string, offset = 0, limit = 1000) {
        const offsetQuery = offset ? `&offset=${offset}` : '';
        const limitSizeQuery = limit ? `&limit=${limit}` : '';
        const contractIdQuery = contractId ? `&contract_id=${contractId}` : '';
        return new Promise((resolve) => {
          self.root.axios
            .requestData({
              type: HttpVerbs.GET,
              path: `/purchased-extensions?${contractIdQuery}${offsetQuery}${limitSizeQuery}`,
            })
            .then((response: ApiListResponse<PurchasedExtension>) => {
              self.root.data.setPurchasedExtensions(response.data);
              resolve(response);
            });
        });
      },
      getPurchasedExtension(purchaseExtensionId: string) {
        return new Promise((resolve) => {
          self.root.axios
            .requestData({
              type: HttpVerbs.GET,
              path: `/purchased-extensions/${purchaseExtensionId}`,
            })
            .then((response: ApiListResponse<PurchasedExtension>) => {
              self.root.data.setPurchasedExtension(response.data);
              resolve(response);
            });
        });
      },
    };
  });
