import {
  Ad,
  ApiObjectResponse,
  RootType,
  AdFilters,
  AdAnalytics,
  PublishAd,
  AdAttributes,
} from '../../internal';
import { getRoot, types } from 'mobx-state-tree';
import { HttpVerbs } from '../../constants/verbs';
import i18n from '../../i18n';
import { CODE_COUNT_MISMATCH } from '../../constants';
import fileDownload from 'js-file-download';
import {
  ACTIVE,
  PREP,
  PUBLISHED_SOON_UPDATE,
  PUBLISHED_UPDATE,
  SUBMITTED,
} from '../../constants/ad';
import { IMAGES_BASE_URL } from './images';
import { StatusCodes } from '../../constants/statusCodes';

export interface RequestNewAddon {
  type: string;
  count: number;
  contract_addon_id: string;
}

export const OffersApi = types
  .model('OffersApi', {})
  .views((self) => {
    return {
      get root(): RootType {
        return getRoot(self);
      },
    };
  })
  .actions((self) => {
    return {
      async saveSettings(selectedOptionsArrayString: string): Promise<any> {
        return await self.root.axios.requestData({
          type: HttpVerbs.POST,
          path: '/settings',
          data: { ads_extended_columns: selectedOptionsArrayString },
        });
      },
      async getAllColumns(): Promise<ApiObjectResponse<Ad[]>> {
        return self.root.axios.requestData({
          type: HttpVerbs.GET,
          path: '/offers/extended/filters?f=columns',
        });
      },

      async getVisibleColumns() {
        return self.root.axios.requestData({
          type: HttpVerbs.GET,
          path: '/settings',
        });
      },
      postForPriceCheck(ad: Ad) {
        return self.root.axios.requestData({
          type: HttpVerbs.POST,
          path: '/offers/price',
          data: ad,
        });
      },
      async saveAd(ad: Ad, isSubmitted = false, isPost = false) {
        try {
          const data: any = { ...ad };
          if (isSubmitted) {
            data.status = SUBMITTED;
          } else {
            delete data.status;
          }

          const response: ApiObjectResponse<Ad> =
            await self.root.axios.requestData({
              type: ad.ad_id && !isPost ? HttpVerbs.PATCH : HttpVerbs.POST,
              path: '/offers',
              data,
            });
          await this.uploadImages(response?.data?.id as string);
        } catch (err) {
          self.root.showFailure('e400');
          throw err;
        }
      },
      async uploadImages(adId: string) {
        const promises: (() => Promise<any>)[] = [];
        const { currentPage } = self.root;
        // collecting promises to resolve
        currentPage.imagesArray.forEach((image: File, index: number) => {
          promises.push(() =>
            self.root.axios.uploadFile({
              path: `/offers/${adId}/image/${index}`,
              fileKey: 'image',
              file: image,
            })
          );
        });

        // Endpoint seems to require that we resolve the promises sequentially.
        // Otherwise, some images do not get recorded as uploaded
        const results: Promise<any>[] = [];
        for (const promiseFunc of promises) {
          results.push(await promiseFunc());
        }
        return results;
      },
      deleteCodes(partnerId: string, uploadCodesRef: string) {
        return new Promise((resolve) => {
          self.root.axios
            .requestData({
              type: HttpVerbs.DELETE,
              path: '/offers/code-csv',
              params: {
                partner_id: partnerId,
                ref: uploadCodesRef,
              },
            })
            .then((response: any) => {
              resolve(response);
            });
        });
      },
      downloadUsedCSVCodes(partnerId: string, adId: string) {
        return new Promise((resolve) => {
          self.root.axios
            .requestData({
              type: HttpVerbs.GET,
              path: `/offers/code-csv-used/${partnerId}/${adId}`,
            })
            .then((response: string) => {
              fileDownload(response, `${partnerId}-${adId}.csv`);
              resolve(response);
            });
        });
      },
      downloadCSVTable(filtersQuery: string) {
        return new Promise((resolve) => {
          self.root.axios
            .requestData({
              type: HttpVerbs.GET,
              path: `/offers/extended/download?${filtersQuery}`,
            })
            .then((response: string) => {
              fileDownload(response, 'allAds.csv');
              resolve(response);
              console.log(response);
            });
        });
      },
      downloadCSVCodes(ref: string, partnerId: string) {
        return new Promise((resolve) => {
          self.root.axios
            .requestData({
              type: HttpVerbs.GET,
              path: `/offers/code-csv?ref=${ref}&partner_id=${partnerId}`,
            })
            .then((response: string) => {
              fileDownload(response, `${ref}-${partnerId}.csv`);
              resolve(response);
            });
        });
      },
      getCSVCodes(ref: string, partnerId: string) {
        return new Promise((resolve) => {
          self.root.axios
            .requestData({
              type: HttpVerbs.GET,
              path: `/offers/code-csv?ref=${ref}&partner_id=${partnerId}`,
            })
            .then((response: string) => {
              resolve(response);
            });
        });
      },
      getAdAnalytics(adId: string) {
        return new Promise((resolve) => {
          self.root.axios
            .requestData({
              type: HttpVerbs.GET,
              path: '/offers/offer-analytics',
              params: {
                ad_id: adId,
              },
            })
            .then((response: ApiObjectResponse<AdAnalytics>) => {
              self.root.data.setAdAnalytics(response.data);
              resolve(response);
            });
        });
      },

      uploadCodes(file: File, partnerId?: string, codeCount?: number) {
        const { currentPage } = getRoot(self);
        self.root.router.setIsLoading(true);
        return new Promise((resolve) => {
          self.root.axios
            .uploadFile({
              path: '/offers/code-csv',
              fileKey: 'file',
              file,
              params: {
                ...(partnerId ? { partner_id: partnerId } : {}),
                ...(codeCount ? { code_count: codeCount } : {}),
              },
              customErrorMessage: (error: {
                response: {
                  data: {
                    error: {
                      description: string;
                      type: string;
                    };
                  };
                };
              }) => {
                if (
                  error?.response?.data?.error?.description ===
                  CODE_COUNT_MISMATCH
                ) {
                  return i18n.t('forms:code_count_mismatch');
                }
                return i18n.t('forms:invalid_csv');
              },
            })
            .then((response: any) => {
              resolve(response);
              if (response.statusCode === StatusCodes.OK) {
                self.root.router.setIsLoading(false);
                currentPage
                  .getEnterCodesModel()
                  .setUploadCodesRef(response.data.ref);
                currentPage
                  .getEnterCodesModel()
                  .setUploadCodesCount(response.data.count);
                currentPage.getEnterCodesModel().setFile(file);
              }
            });
        });
      },
      offerSaveAttributes(adId: string, data: AdAttributes) {
        return new Promise((resolve, reject) => {
          self.root.axios
            .requestData({
              type: HttpVerbs.PUT,
              path: `/offers/${adId}/attributes`,
              data: data,
            })
            .then((response: ApiObjectResponse<Ad>) => {
              resolve(response);
            })
            .catch((err: any) => {
              reject(err);
            });
        });
      },
      downloadImages() {
        const { currentPage } = self.root;
        const imagesArray = [
          currentPage.adDetails.image0,
          currentPage.adDetails.image1,
          currentPage.adDetails.image2,
          currentPage.adDetails.image3,
        ];
        imagesArray
          .filter((img) => !!img)
          .map((img: string) => {
            return new Promise((resolve) => {
              const link = `${IMAGES_BASE_URL}${img}`;
              self.root.axios
                .requestData({
                  type: HttpVerbs.GET,
                  path: link,
                  responseType: 'blob',
                })
                .then((response: any) => {
                  resolve(response);
                  const url = window.URL.createObjectURL(new Blob([response]));
                  const a = document.createElement('a');
                  a.style.display = 'none';
                  a.href = url;
                  a.download = img;
                  document.body.appendChild(a);
                  //simulate click
                  a.click();
                  //remove the link when done
                  document.body.removeChild(a);
                });
            });
          });
      },

      get(id: string) {
        return new Promise((resolve) => {
          self.root.axios
            .requestData({
              type: HttpVerbs.GET,
              path: `/offers/${id}`,
            })
            .then((response: ApiObjectResponse<Ad>) => {
              self.root.data.setAdDetails(response.data);
              resolve(response);
            });
        });
      },
      async fetchUpdate(adId: string) {
        try {
          return await self.root.api.adUpdate.get(adId);
        } catch (err) {
          return {};
        }
      },
      // not all data is fetched with the initial GET, some can potentially be
      // in the update as well
      // ads in tab (active) are exempt from this (we get it from filter)
      async mapElement(elem: Ad, isActiveAd: boolean) {
        if (
          (elem.status === PUBLISHED_UPDATE ||
            elem.status === PUBLISHED_SOON_UPDATE) &&
          !isActiveAd
        ) {
          const update = await this.fetchUpdate(elem.id);
          // we re-override the dates, because the backend sends us
          // nulls in the update object so it must be prevented from overriding
          // the dates
          return {
            ...elem,
            ...update,
            id: elem.id,
            date_start: elem.date_start,
            date_end: elem.date_end,
            date_code_start: elem.date_code_start,
            date_code_end: elem.date_code_end,
          } as Ad;
        }
        return elem;
      },
      async getExtendedAds(filter: AdFilters = { offset: 0, limit: 10 }) {
        const response = await self.root.axios.requestData({
          type: HttpVerbs.GET,
          path: '/offers/extended',
          params: filter,
        });
        self.root.data.setAds(response.data);
        self.root.data.setTotalAdsCount(response?.meta?.total);
        return response;
      },
      async getAdDiscountPercentages() {
        const response = await self.root.axios.requestData({
          type: HttpVerbs.GET,
          path: '/offers/extended/filters?f=ad_discount_percentage',
        });
        return response;
      },
      async getAdStatuses() {
        const response = await self.root.axios.requestData({
          type: HttpVerbs.GET,
          path: '/offers/extended/filters?f=ad_status',
        });
        return response;
      },
      async getAllCategories() {
        const response = await self.root.axios.requestData({
          type: HttpVerbs.GET,
          path: '/categories?limit=100',
        });
        return response;
      },
      async getAllSubCategories() {
        const response = await self.root.axios.requestData({
          type: HttpVerbs.GET,
          path: '/subcategories?limit=1000',
        });
        return response;
      },
      async getAllUserLocations() {
        const response = await self.root.axios.requestData({
          type: HttpVerbs.GET,
          path: '/user-locations',
        });
        return response;
      },
      async getAllAdmins() {
        const response = await self.root.axios.requestData({
          type: HttpVerbs.GET,
          path: '/admins?limit=1000',
        });
        return response;
      },
      async getAds(filter: AdFilters = { offset: 0, limit: 10 }) {
        const response = await self.root.axios.requestData({
          type: HttpVerbs.GET,
          path: '/offers',
          params: filter,
        });
        // handle ad updates if necessary
        const newResponseData = [];
        for (const elem of response.data) {
          newResponseData.push(
            await this.mapElement(elem, filter.status === ACTIVE)
          );
        }
        response.data = newResponseData;
        self.root.data.setAds(response.data);
        self.root.data.setTotalAdsCount(response?.meta?.total);
        return response;
      },
      publishAd(adId: string, data: PublishAd) {
        return new Promise((resolve, reject) => {
          self.root.axios
            .requestData({
              type: HttpVerbs.PUT,
              path: `/offers/${adId}/publish`,
              data: data,
            })
            .then((response: ApiObjectResponse<Ad>) => {
              resolve(response);
            })
            .catch((err: any) => {
              reject(err);
            });
        });
      },
      rejectAd(adId: string, reason: string) {
        return new Promise((resolve, reject) => {
          self.root.axios
            .requestData({
              type: HttpVerbs.PUT,
              path: `/offers/${adId}/reject`,
              data: {
                reason: reason,
              },
            })
            .then((response: ApiObjectResponse<Ad>) => {
              resolve(response);
            })
            .catch((err: any) => {
              reject(err);
            });
        });
      },
      stopAd(adId: string) {
        const { currentPage } = self.root;
        return new Promise((resolve) => {
          self.root.axios
            .requestData({
              type: HttpVerbs.PUT,
              path: `/offers/${adId}/stop`,
            })
            .then((response: ApiObjectResponse<Ad>) => {
              resolve(response);
              currentPage.getApproveRejectAd().setStopped();
              currentPage.getApproveRejectAd().openModal();
            })
            .catch((err: any) => {
              resolve(err);
            });
        });
      },
      async deleteAd(adId: string) {
        const { currentPage } = self.root;

        return new Promise((resolve, reject) => {
          self.root.axios
            .requestData({
              type: HttpVerbs.DELETE,
              path: `/offers/${adId}`,
            })
            .then((response: ApiObjectResponse<Ad>) => {
              resolve(response);
              currentPage
                .getSuccessfulDeleteDraftAdConfirmationModal(adId)
                .open();
              currentPage
                .getSuccessfulDeleteDraftAdConfirmationModal(adId)
                .setAfterYesCallback(() => {
                  currentPage?.loadAds();
                });
            })
            .catch((err: any) => {
              reject(err);
            });
        });
      },
      async getPartnerAds(filter: AdFilters = { offset: 0, limit: 10 }) {
        const response = await self.root.axios.requestData({
          type: HttpVerbs.GET,
          path: '/offers',
          params: filter,
        });
        // handle ad updates if necessary
        const newResponseData = [];
        for (const elem of response.data) {
          newResponseData.push(
            await this.mapElement(elem, filter.status === ACTIVE)
          );
        }
        response.data = newResponseData;
        return response;
      },
      async saveComment(offerId: string, comment: string) {
        const response = await self.root.axios.requestData({
          type: HttpVerbs.POST,
          path: `offer-updates/${offerId}/comment`,
          data: { comment },
        });

        return response;
      },
    };
  });
