import { FC } from 'react';
import { useMst } from '../../internal';
import { IDocument } from './PaymentsAndDocumentsModel';
import fileDownload from 'js-file-download';
import styles from './PaymentsAndDocumentsTab3.module.scss';

interface PaymentsAndDocumentsTab3Props {
  documents: IDocument[];
}

export const PaymentsAndDocumentsTab3: FC<PaymentsAndDocumentsTab3Props> = ({
  documents,
}) => {
  const root = useMst();

  if (documents.length === 0) {
    return (
      <p className={styles.noDocumentsText}> Trenutno nemate dokumenata. </p>
    );
  }

  if (documents.length > 0) {
    return (
      <div className={styles.documentsWrapper}>
        {documents.map((document: IDocument, i: number) => (
          <div key={i} className={styles.documentWrapper}>
            <div className={styles.documentNameAndDate}>
              <span> {document.name} </span>
              <span className={styles.documentDate}>
                {new Date(document.date).toLocaleDateString('de-DE', {
                  day: '2-digit',
                  month: '2-digit',
                  year: 'numeric',
                })}
              </span>
            </div>
            <div>
              <span
                className={styles.documentPreview}
                onClick={() =>
                  root.api.partner
                    .getPartnerDocument(root.user.partner.id, document.id)
                    .then((response: any) => {
                      const file = new Blob([response], {
                        type: 'application/pdf',
                      });
                      const fileURL = URL.createObjectURL(file);
                      window.open(fileURL);
                    })
                }
              >
                Pogledaj
              </span>
              <span
                onClick={() =>
                  root.api.partner
                    .getPartnerDocument(root.user.partner.id, document.id)
                    .then((response: any) => {
                      fileDownload(response, 'file.pdf');
                    })
                }
                className={styles.documentSave}
              >
                Sačuvaj
              </span>
            </div>
          </div>
        ))}
      </div>
    );
  }

  return <></>;
};
