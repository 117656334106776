import { getRoot, Instance, types } from 'mobx-state-tree';
import {
  ComponentModel,
  AccountListFiltersModel,
  PaginationModel,
  ConfirmationModalModel,
  EditAccountModalModel,
  Partner,
  ChangePacketModalModel,
  BillingModalModel,
  RootType,
  ApproveRejectPackageModalModel,
  PartnerAdminNew,
} from '../../../../internal';

export const CreatedAccountListModel = types
  .compose(
    ComponentModel,
    types.model({
      orderBy: '',
      order: '',
      filters: types.late(() => AccountListFiltersModel),
      elements: types.map(types.late(() => types.frozen<PartnerAdminNew>())),
      pagination: types.late(() => PaginationModel),
      editModal: types.late(() => EditAccountModalModel),
      deleteModal: types.late(() => ConfirmationModalModel),
      changePacketModal: types.late(() => ChangePacketModalModel),
      billingModal: types.late(() => BillingModalModel),
      approveRejectPackageModal: types.late(
        () => ApproveRejectPackageModalModel
      ),
    })
  )
  .views((self) => {
    return {
      get accountsArray(): Partner[] {
        return Array.from(self.elements.values());
      },
      get root(): RootType {
        return getRoot(self);
      },
    };
  })
  .actions((self) => {
    return {
      toggleOrder() {
        this.setOrder(!self.order || self.order === 'asc' ? 'desc' : 'asc');
      },
      setOrderBy(val: string) {
        self.orderBy = val;
      },
      setOrder(val: string) {
        self.order = val;
      },
      setAccounts(accs: Partner[]) {
        self.elements.clear();
        accs.forEach((acc) => {
          self.elements.set(acc.id, acc);
        });
      },
    };
  })
  .named('CreatedAccountListModel');

export type CreatedAccountListModelType = Instance<
  typeof CreatedAccountListModel
>;
