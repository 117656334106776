import { Instance, types } from 'mobx-state-tree';
import {
  ComponentModel,
  ModalModel,
  FunctionModel,
  PartnerLocation,
} from '../../../../internal';

export const DeleteLocationModalModel = types
  .compose(
    ComponentModel,
    types.model({
      location: types.maybe(types.frozen<PartnerLocation>()),
      modal: types.late(() => ModalModel),
      onAfterDelete: types.late(() => FunctionModel),
    })
  )
  .actions((self) => {
    return {
      setLocation(loc: PartnerLocation) {
        self.location = loc;
      },
      openModal() {
        self.modal.setOpened(true);
      },
      closeModal() {
        self.modal.setOpened(false);
      },
      async delete() {
        await self.root.api.partnerLocation.delete(self.location.id);
        self.closeModal();
        self?.onAfterDelete?.();
      },
      cancel() {
        self.closeModal();
      },
    };
  })
  .views((self) => {
    return {};
  })
  .named('DeleteLocationModalModel');

export type DeleteLocationModalModelType = Instance<
  typeof DeleteLocationModalModel
>;
