import { Instance, types } from 'mobx-state-tree';
import { ComponentModel, ConfirmationModalModel } from '../../../../internal';

export const DeleteAccountModel = types
  .compose(
    ComponentModel,
    types.model({
      accountId: types.maybe(types.string),
      deleteAccountModal: types.late(() => ConfirmationModalModel),
    })
  )
  .views((self) => {
    return {};
  })
  .actions((self) => {
    return {
      setAccountId(id?: string) {
        self.accountId = id;
      },
    };
  })
  .named('DeleteAccountModel');

export type DeleteAccountModelType = Instance<typeof DeleteAccountModel>;
