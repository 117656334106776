import { Instance, types } from 'mobx-state-tree';
import { ButtonModel, ComponentModel } from '../internal';

export const AdTypeModel = types
  .compose(
    ComponentModel,
    types.model({
      id: types.identifier,
      name: types.maybe(types.string),
      image: types.maybe(types.string),
      loweredPrice: types.maybe(types.number),
      regularPrice: types.maybe(types.number),
      discount: types.maybe(types.string),
      store: types.maybe(types.string),
      expiration: types.maybe(types.string),
      distance: types.maybe(types.string),
      isWindPaySupported: types.maybe(types.boolean),
      isTopOffer: false,
      isFeaturedOffer: false,
      showDescriptionList: true,
      activateButton: types.maybe(types.late(() => ButtonModel)),
    })
  )
  .named('AdTypeModel');

export type AdTypeModelType = Instance<typeof AdTypeModel>;
