import styles from './TariffPackages.module.scss';
import { Button } from '../../../UI/buttons/regular/Button';
import { observer } from 'mobx-react';
import { useMst } from '../../../../internal';
import checkmarkIcon from '../../../../assets/icons/checkmark_icon.png';
import { AdditionAcivatedModal } from '../../tariff-package-modals/AdditionAcivatedModal';

export const MonthlyPaymentAddition = observer(() => {
  const {
    currentPage,
    api: {
      purchases: { newPurchase },
    },
    data: {
      partnerDetails,
      contractDetails,
      setPurchases,
      hasPurchases,
      currentCatalog,
    },
  } = useMst();
  const makePurchase = async () => {
    const response = await newPurchase(partnerDetails.id, contractDetails.id);
    setPurchases(response.data);
    currentPage.getAdditionActivatedModal().setOpened(true);
  };
  return (
    <>
      <div className={styles.Text} style={{ paddingTop: '60px' }}>
        <h5 className={styles.Title}>Dodatak za mesečni tarifni paket</h5>
      </div>
      <div className={styles.MonthlyPaymentAdditionContent}>
        <div className={styles.FullWidthBox}>
          <p className={styles.Title}>+5 dodatnih Top oglasa</p>
        </div>

        <div
          className={
            !hasPurchases ? styles.Box4Addition : styles.Box4AdditionActive
          }
        >
          <div className={styles.Price}>
            <p className={styles.PriceValue}>
              {currentCatalog?.top_addon_price}{' '}
              <span className={styles.Currency}>RSD</span>
            </p>
          </div>
          {!hasPurchases ? (
            <div className={styles.Button}>
              <Button
                model={currentPage.getTariffAdditionButton()}
                onClick={async () => {
                  await makePurchase();
                  currentPage.getAdditionActivatedModal().setOpened(true);
                }}
                customButtonStyles={{
                  height: '48px',
                  width: '168px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              />
            </div>
          ) : (
            <div className={styles.ActivePackage}>
              <img src={checkmarkIcon} alt="check" />
              <p>Aktivan dodatak</p>
            </div>
          )}
        </div>
        <AdditionAcivatedModal currentPage={currentPage} />
      </div>
    </>
  );
});
