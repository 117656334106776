import { Instance, types } from 'mobx-state-tree';
import {
  CheckboxGroupModel,
  CheckboxModelType,
  ComponentModel,
  ModalModel,
  StoreModel,
  StoreModelType,
} from '../../../internal';
import Fuse from 'fuse.js';

export const PickStoresModel = types
  .compose(
    ComponentModel,
    types.model({
      modal: types.late(() => ModalModel),
      allStores: types.array(types.late(() => StoreModel)),
      searchValue: types.string,
      checkboxContainer: types.late(() => CheckboxGroupModel),
      selection: types.array(types.late(() => StoreModel)),
    })
  )
  .actions((self) => {
    return {
      afterCreate() {
        self.updateCheckboxes(self.allStores);
      },
      setStores(stores: StoreModelType[]) {
        self.allStores = stores;
        self.updateCheckboxes(self.allStores);
      },
      updateCheckboxes(stores: StoreModelType[]) {
        const checkboxes = stores.map(
          (store: StoreModelType): CheckboxModelType => {
            return {
              id: store.id,
              label: store.name,
            };
          }
        );
        self.checkboxContainer.setCheckboxes(checkboxes, true);
      },
      openModal() {
        self.modal.setOpened(true);
        self.search(''); // resets the search
      },
      saveAndClose() {
        if (self.anythingSelected) {
          self.modal.setOpened(false);
          self.updateStoreSelection();
        }
      },
      search(value: string) {
        self.searchValue = value;
        const relevantStores = self.getRelevantStores(value);
        self.updateCheckboxes(relevantStores);
      },
      getRelevantStores(value: string) {
        if (!value) {
          return self.allStores;
        }
        const options = { keys: ['name'] };
        const fuse = new Fuse(self.allStores, options);
        return fuse.search(value).map((elem) => elem.item);
      },
      clearSelection() {
        self.selection = [];
      },
      updateStoreSelection() {
        self.selection = self.checkboxContainer.selectedCheckboxesIds.map(
          (id: string) => {
            return StoreModel.create({
              id: id,
              name: self?.checkboxContainer?.selectedCheckboxes?.get(id)?.label,
            });
          }
        );
      },
    };
  })
  .views((self) => {
    return {
      get anythingSelected(): boolean {
        return self.checkboxContainer.selectedCheckboxesIds.length > 0;
      },
      get everythingSelected(): boolean {
        return (
          self.checkboxContainer.checkboxesArray.length &&
          self.checkboxContainer.selectedCheckboxesIds.length ===
            self.checkboxContainer.checkboxesArray.length
        );
      },
      get idsStr(): string {
        return self.checkboxContainer.selectedCheckboxesIds.join(',');
      },
    };
  })
  .named('PickStoresModel');

export type PickStoresModelType = Instance<typeof PickStoresModel>;
