import { Instance, types } from 'mobx-state-tree';
import { ChangeEvent } from 'react';
import { TextInputModel } from '../../../internal';

export const PasswordInputModel = types
  .compose(
    TextInputModel,
    types.model({
      type: 'password',
    })
  )
  .views((self) => {
    return {
      get isInvisible(): boolean {
        return self.type === 'password';
      },
      get isVisible(): boolean {
        return self.type === 'text';
      },
    };
  })
  .actions((self) => {
    return {
      onChange(e: ChangeEvent) {
        const value = (e.target as HTMLInputElement).value;
        self.value = value;
        if (self.setValueCallback) {
          self.setValueCallback(value);
        }
      },
      toggleVisibility() {
        if (self.isInvisible) {
          self.type = 'text';
        } else {
          self.type = 'password';
        }
      },
    };
  })
  .named('PasswordInputModel');

export type PasswordInputModelType = Instance<typeof PasswordInputModel>;
