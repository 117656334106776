import { IAnyModelType, Instance, types } from 'mobx-state-tree';

export const GeolocationModel: IAnyModelType = types
  .model({
    latitude: types.maybeNull(types.number),
    longitude: types.maybeNull(types.number),
    accessDenied: types.maybe(types.boolean),
  })
  .actions((self: GeolocationModelType) => {
    return {
      requestLocation(successCallback?: any, errorCallback?: any) {
        navigator.geolocation.getCurrentPosition(
          (pos: { coords: GeolocationCoordinates; timestamp: number }) => {
            this.setLatitude(pos.coords.latitude);
            this.setLongitude(pos.coords.longitude);
            this.setAccessDenied(false);
            if (successCallback) {
              successCallback();
            }
          },
          () => {
            this.setLatitude(null);
            this.setLongitude(null);
            this.setAccessDenied(true);
            if (errorCallback) {
              errorCallback();
            }
          }
        );
      },
      setLatitude(newLat: any) {
        self.latitude = newLat;
      },
      setLongitude(newLon: any) {
        self.longitude = newLon;
      },
      setAccessDenied(accessDenied: boolean) {
        self.accessDenied = accessDenied;
      },
    };
  })
  .views((self: GeolocationModelType) => {
    return {
      get currentLatitude(): number {
        return self.latitude;
      },
      get currentLongitude(): number {
        return self.longitude;
      },
      get coordinates(): { latitude: number; longitude: number } {
        return { latitude: self.latitude, longitude: self.longitude };
      },
      get isLocationDenied(): boolean {
        return self.accessDenied === true;
      },
    };
  })
  .named('GeolocationModel');

export type GeolocationModelType = Instance<typeof GeolocationModel>;
