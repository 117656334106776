import { observer } from 'mobx-react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { DurationModelType } from '../../../internal';
import { StandardDurationPick } from '../duration/StandardDurationPick';
import { OfferDurationModal } from './modal/OfferDurationModal';
import styles from './OfferDurationView.module.scss';

export const OfferDurationView = observer(
  (props: { model: DurationModelType; id?: string }): JSX.Element => {
    const { t } = useTranslation();
    const { model, id } = props;
    return (
      <div
        style={{ display: 'flex', flexDirection: 'column' }}
        className={styles.OfferDurationContainer}
      >
        <StandardDurationPick
          id={id}
          model={model}
          label={t('basic:offer_duration')}
        >
          <OfferDurationModal model={model} />
        </StandardDurationPick>
        <p className={styles.HelperText}>{t('basic:offer_duration_help')}</p>
      </div>
    );
  }
);
