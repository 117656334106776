import { getRoot, types } from 'mobx-state-tree';
import { ADMIN_EXISTS, LINK_EXPIRED } from '../../constants';
import { HttpVerbs } from '../../constants/verbs';
import i18n from '../../i18n';
import {
  ApiObjectResponse,
  RootType,
  Admin,
  Role,
  ApiListResponse,
  AdminPublic,
} from '../../internal';
import { axiosInstance } from '../axios';
import { YettelAdminTypeIds } from '../../constants/yettelAdmins';
import { StatusCodes } from '../../constants/statusCodes';

export const AdminApi = types
  .model({})
  .views((self) => {
    return {
      get root(): RootType {
        return getRoot(self);
      },
      get errorHandlerMap(): any {
        const retVal: any = {};
        retVal[ADMIN_EXISTS] = i18n.t('forms:admin_exists');
        retVal[LINK_EXPIRED] = i18n.t('forms:link_expired');
        return retVal;
      },
    };
  })
  .actions((self) => {
    return {
      // Login api call follows a different pattern compared to rest of the app
      // The client requested that we add error messages to the form itself
      // if the login fails, instead of displaying a properly formatted message
      // from the BE. See LoginModel for more details
      async login({
        email,
        password,
        captcha,
      }: {
        email: string;
        password: string;
        captcha?: string;
      }) {
        const LOGIN_PATH = '/auth/login';
        try {
          self.root.axios.addCall(LOGIN_PATH);
          const instance = axiosInstance(undefined, captcha);
          const config = {
            method: HttpVerbs.POST,
            url: LOGIN_PATH,
            data: { email, password },
          };
          const response = await instance(config);
          self.root.axios.removeCall(LOGIN_PATH);
          return response.data;
        } catch (err) {
          self.root.axios.removeCall(LOGIN_PATH);
          throw err;
        }
      },
      async ssoLogin({ token }: { token: string }) {
        const LOGIN_PATH = '/sso/login';
        try {
          self.root.axios.addCall(LOGIN_PATH);
          const config = {
            method: HttpVerbs.GET,
            url: LOGIN_PATH,
          };
          const instance = axiosInstance(token);
          const response = await instance(config);
          self.root.axios.removeCall(LOGIN_PATH);
          return response.data;
        } catch (err) {
          self.root.axios.removeCall(LOGIN_PATH);
          throw err;
        }
      },
      async sendResetPasswordEmail({ email }: { email: string }) {
        const PATH = '/admins/send-email-reset-password';
        try {
          self.root.axios.addCall(PATH);
          const config = {
            method: HttpVerbs.PUT,
            url: PATH,
            data: { email },
          };
          const instance = axiosInstance(undefined);
          const response = await instance(config);
          self.root.axios.removeCall(PATH);
          return response;
        } catch (err) {
          self.root.axios.removeCall(PATH);
          throw err;
        }
      },
      logout() {
        self.root.reset();
      },
      async getRoles(): Promise<ApiListResponse<Role>> {
        return self.root.axios.getAll('/role');
      },
      async getRole(id: string): Promise<Role | undefined> {
        const response = await this.getRoles();
        return response?.data?.find((role) => role.id === id);
      },
      // more or less the same as refresh token
      // with the main difference being
      // no loader which blocks user experience
      async refreshTokenSilently() {
        const PATH = '/auth/refresh-token';
        try {
          const config = {
            method: HttpVerbs.POST,
            url: PATH,
          };
          const instance = axiosInstance(
            self.root.user.token || localStorage.getItem('token')
          );
          const response = await instance(config);

          // set new token if everything is alright
          window.localStorage.setItem('token', response.data.data.newToken);
          self.root.user.setToken(response.data.data.newToken);

          return response;
        } catch (err) {
          // if the request fails, remove the token and logout
          self?.root?.showFailure?.(i18n.t('alert:session_expired'));
        }
      },
      refreshToken() {
        return new Promise((resolve, reject) => {
          self.root.axios
            .requestData({
              type: HttpVerbs.POST,
              path: '/refresh-token',
              customErrorMessage: () => i18n.t('alert:session_expired'),
            })
            .then((response: any) => {
              window.localStorage.setItem('token', response.data.newToken);
              self.root.user.setToken(response.data.newToken);
              resolve(response);
            });
        });
      },
      delete(id: string) {
        return new Promise((resolve) => {
          self.root.axios
            .requestData({
              type: HttpVerbs.DELETE,
              path: '/admins',
              params: { id: id },
            })
            .then((response: any) => {
              resolve(response);
            });
        });
      },
      adminErrorHandler(error: {
        response: {
          data: {
            error: {
              description: string;
              type: string;
            };
          };
        };
      }) {
        const description = error?.response?.data?.error?.description;
        return self.errorHandlerMap[description]
          ? self.errorHandlerMap[description]
          : 'Error.';
      },
      // called on multiple occasions at once, so shared model pattern is not an option
      // here
      get(id: string): ApiObjectResponse<Admin> {
        return self.root.axios.requestData({
          type: HttpVerbs.GET,
          path: `/admins/${id}`,
        });
      },
      getAdminsByPartner(partnerId: string) {
        return new Promise((resolve, reject) => {
          self.root.axios
            .requestData({
              type: HttpVerbs.GET,
              path: `/admins?partner_id=${partnerId}&offset=0&limit=100`,
            })
            .then((response: any) => {
              resolve(response);
              self.root.data.setOtherPartnerAdmins(response.data);
            })
            .catch((err: any) => {
              reject(err);
            });
        });
      },
      createPartnerAdmin(data: any) {
        const { currentPage } = self.root;
        const partnerId = currentPage.root.header.partnerId;
        return new Promise((resolve, reject) => {
          self.root.axios
            .requestData({
              type: HttpVerbs.POST,
              path: '/admins/send-email-create-admin',
              data: {
                ...data,
                partner_id: partnerId,
                partner_main_account: 0,
              },
              customErrorMessage: (err: any) => this.adminErrorHandler(err),
            })
            .then((response: any) => {
              resolve(response);
            })
            .catch((err: any) => {
              reject(err);
            });
        });
      },
      updatePartnerAdminEmail() {
        const { currentPage } = self.root;
        return new Promise((resolve) => {
          self.root.axios
            .requestData({
              type: HttpVerbs.POST,
              path: '/admins/send-email-update-email',
              data: {
                email: currentPage.getAdministrators().newEmail,
                id: currentPage.getAdministrators().adminId,
              },
              customErrorMessage: (err: any) => this.adminErrorHandler(err),
            })
            .then((response: any) => {
              resolve(response);
              console.log('extract verification code', response);
            });
        });
      },
      activate(id: string) {
        return new Promise((resolve) => {
          self.root.axios
            .requestData({
              type: HttpVerbs.PUT,
              data: {},
              path: `/admins/${id}/activate`,
            })
            .then((response: ApiObjectResponse<any>) => {
              resolve(response);
            });
        });
      },
      deactivate(id: string) {
        return new Promise((resolve) => {
          self.root.axios
            .requestData({
              type: HttpVerbs.PUT,
              data: {},
              path: `/admins/${id}/deactivate`,
            })
            .then((response: ApiObjectResponse<any>) => {
              resolve(response);
            });
        });
      },
      async verifyAdmin(data: {
        email: string;
        password: string;
        verification_code: string;
      }) {
        const PATH = '/admins/verify';
        try {
          self.root.axios.addCall(PATH);
          const config = {
            method: HttpVerbs.PUT,
            url: PATH,
            data,
          };
          const instance = axiosInstance(undefined);
          const response = await instance(config);
          self.root.axios.removeCall(PATH);
          return response;
        } catch (err: any) {
          self.root.axios.removeCall(PATH);
          throw err;
        }
      },
      async resetPassword(data: {
        email: string;
        password: string;
        verification_code: string;
      }) {
        const PATH = '/admins/reset-password';
        try {
          self.root.axios.addCall(PATH);
          const config = {
            method: HttpVerbs.PUT,
            url: PATH,
            data,
          };
          const instance = axiosInstance(undefined);
          const response = await instance(config);
          self.root.axios.removeCall(PATH);
          return response;
        } catch (err) {
          self.root.axios.removeCall(PATH);
          throw err;
        }
      },
      createAdmin(obj: {
        first_name: string;
        last_name: string;
        email: string;
        role_id: string;
      }) {
        return new Promise((resolve) => {
          self.root.axios
            .requestData({
              type: HttpVerbs.POST,
              path: '/admins/send-email-create-admin',
              data: obj,
            })
            .then((response: any) => {
              resolve(response);
            });
        });
      },
      resendCreateAdminEmail(obj: { email: string }) {
        return new Promise((resolve) => {
          self.root.axios
            .requestData({
              type: HttpVerbs.POST,
              path: '/admins/resend-email-create-admin',
              data: obj,
            })
            .then((response: any) => {
              resolve(response);
            });
        });
      },
      updateAdmin(
        adminId: string,
        obj: {
          first_name: string;
          last_name: string;
        }
      ) {
        return new Promise((resolve) => {
          self.root.axios
            .requestData({
              type: HttpVerbs.PUT,
              path: `/admins/${adminId}/update-name`,
              data: obj,
            })
            .then((response: any) => {
              resolve(response);
            });
        });
      },
      deleteAdmin() {
        const { currentPage } = self.root;

        const adminId = currentPage.getAdministrators().id;
        const adminIdQuery = adminId ? `&id=${adminId}` : '';
        return new Promise((resolve) => {
          self.root.axios
            .requestData({
              type: HttpVerbs.DELETE,
              path: `/admins?${adminIdQuery}`,
            })
            .then((response: any) => {
              resolve(response);
            });
        });
      },
      async getAllSalesmen(): Promise<ApiListResponse<AdminPublic>> {
        return await self.root.axios.getAll('/admins', {
          role_id: YettelAdminTypeIds.SELLER,
        });
      },
      getAdmins(roleId: string) {
        const roleIdQuery = roleId ? `&role_id=${roleId}` : '';
        return new Promise((resolve) => {
          self.root.axios
            .requestData({
              type: HttpVerbs.GET,
              path: `/admins?${roleIdQuery}&limit=1000`,
            })
            .then((response: any) => {
              roleId === YettelAdminTypeIds.ADMINISTRATOR
                ? self.root.data.setAdministrators(response.data)
                : roleId === YettelAdminTypeIds.MODERATOR
                ? self.root.data.setModerators(response.data)
                : roleId === YettelAdminTypeIds.SELLER
                ? self.root.data.setSellers(response.data)
                : false;
              resolve(response);
            });
        });
      },
    };
  })
  .named('AdminApi');
