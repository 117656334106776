import React, { Fragment } from 'react';
import { observer } from 'mobx-react';
import { useMst } from '../internal';
import Loader from '../components/UI/loader/Loader';

export const StateRouter = observer(() => {
  const { axios, router } = useMst();

  return (
    <Fragment>
      {(router.isLoading || axios.isLoading) && <Loader />}
      {router.currentView && router.currentView.component ? (
        React.cloneElement(router.currentView.component, router.props)
      ) : router.isLoading ? (
        <Loader />
      ) : null}
    </Fragment>
  );
});

export default StateRouter;
