import { observer } from 'mobx-react';
import {
  EditAccountModalModelType,
  IDocument,
  useMst,
} from '../../../../../internal';
import { Button } from '../../../../UI/buttons/regular/Button';
import { Dropdown } from '../../../../UI/dropdown/Dropdown';
import { InputField } from '../../../../UI/input/InputField';
import { Modal } from '../../../../UI/modal/Modal';
import styles from './EditAccountModal.module.scss';
import {
  ADMIN_STATUS_ACTIVE,
  ADMIN_STATUS_INACTIVE,
  ADMIN_STATUS_NONACTIVE,
  ADMIN_STATUS_REQUESTED,
} from '../../../../../constants';
import { useTranslation } from 'react-i18next';
import DownloadLink from 'react-download-link';
import { LightButton } from '../../../../UI/buttons/light-button/LightButton';
import deleteIcon from '../../../../../assets/icons/icons8_trash_32px.png';
import { UploadContractModal } from '../../../upload-contract/UploadContractModal';
import React from 'react';
import durationViewStyles from '../../../duration/StandardDurationPick.module.scss';
import { dateSRLocale } from '../../../../../utils/date';
import { SUBMITTED } from '../../../../../constants/admins';
import { PARTNER } from '../../../../../constants/roleTypes';

export const EditAccountModal = observer(
  (props: { model: EditAccountModalModelType }) => {
    const { model } = props;
    const {
      currentPage,
      api: {
        partnerDocument: { deleteDocument },
      },
      user: { role },
    } = useMst();
    const { t } = useTranslation();

    const promptAlert: React.CSSProperties = {
      textDecoration: 'underline',
      color: ' #002340',
      fontSize: '14px',
    };
    return (
      <Modal
        styles={{
          content: {
            minWidth: '70%',
            maxHeight: 'fit-content',
            borderRadius: '20px',
            padding: '20px',
            top: '15px',
          },
        }}
        model={model.modal}
      >
        <div className={styles.Container}>
          <div className={styles.ColumnsContainer}>
            <div className={styles.Column}>
              <div className={styles.FieldsContainer}>
                <div className={styles.InputFieldContainer}>
                  <InputField model={model.company} />
                </div>
                <div className={styles.InputFieldContainer}>
                  <InputField model={model.pib} />
                </div>
                <div className={styles.InputFieldContainer}>
                  <InputField model={model.uniqueNumber} />
                </div>

                <div className={styles.InputFieldContainer}>
                  <InputField model={model.street} />
                </div>
                <div className={styles.InputFieldContainer}>
                  <InputField model={model.streetNumber} />
                </div>
                <div className={styles.InputFieldContainer}>
                  <InputField model={model.apartmentNumber} />
                </div>
                <div className={styles.InputFieldContainer}>
                  <InputField model={model.city} />
                </div>
                <div className={styles.InputFieldContainer}>
                  <InputField model={model.zip} />
                </div>
                <div className={styles.InputFieldContainer}>
                  <InputField model={model.pak} />
                </div>
                {/* <div className={styles.InputFieldContainer}>
                  <Dropdown model={model.taxPayer} />
                </div> */}
                {/*
                <div className={styles.InputFieldContainer}>
                  <InputField model={model.contractIdExternal} />
                </div>
                <div className={styles.InputFieldContainer}>
                  <InputField model={model.sapId} />
                </div> */}
              </div>
            </div>
            <div className={styles.Column}>
              <div className={styles.FieldsContainer}>
                <div className={styles.InputFieldContainer}>
                  <InputField model={model.billingAddressStreet} />
                </div>
                <div className={styles.InputFieldContainer}>
                  <InputField model={model.billingAddressStreetNumber} />
                </div>
                <div className={styles.InputFieldContainer}>
                  <InputField model={model.billingAddressApartmentNumber} />
                </div>
                <div className={styles.InputFieldContainer}>
                  <InputField model={model.billingAddressCity} />
                </div>
                <div className={styles.InputFieldContainer}>
                  <InputField model={model.billingAddressZip} />
                </div>
                <div className={styles.InputFieldContainer}>
                  <InputField model={model.billingAddressPak} />
                </div>
                <div className={styles.InputFieldContainer}>
                  <Dropdown model={model.contractType} />
                </div>
                <div className={styles.InputFieldContainer}>
                  <div className={durationViewStyles.LowMarginLabel}>
                    {t('basic:contract_start_date')}:{' '}
                    {dateSRLocale(model.contractDuration.fromDate)}
                  </div>
                </div>
              </div>
              <div className={styles.InputFieldContainer}>
                <InputField model={model.email} />
              </div>
              {model.status === SUBMITTED && (
                <div style={{ margin: '10px 0' }}>
                  <Button
                    model={model.getResendEmailButton()}
                    onClick={() => {
                      model.resendEmail(model.email.value);
                    }}
                  />
                </div>
              )}

              <div className={styles.InputFieldContainer}>
                <InputField model={model.adminFirstName} />
              </div>
              <div className={styles.InputFieldContainer}>
                <InputField model={model.adminLastName} />
              </div>
              <div className={styles.InputFieldContainer}>
                <InputField model={model.adminPhoneNumber} />
              </div>
              {role.name !== PARTNER && (
                <div className={styles.InputFieldContainer}>
                  <Dropdown model={model.segmentation} />
                </div>
              )}
            </div>
            <div className={styles.Column}>
              <div className={styles.FieldsContainer}>
                <div className={styles.InputFieldContainer}>
                  <Dropdown model={model.category} />
                </div>
                <div className={styles.InputFieldContainer}>
                  <Dropdown model={model.hasYettelPay} />
                </div>
                <div className={styles.InputFieldContainer}>
                  <Dropdown model={model.companyLegalForm} />
                </div>

                <div className={styles.InputFieldContainer}>
                  <Dropdown model={model.numberOfEmployees} />
                </div>
                <div className={styles.InputFieldContainer}>
                  <Dropdown model={model.partnershipExpert} />
                </div>
              </div>
              <div className={styles.UploadBtnContainer}>
                <div className={styles.CodeTypeLabel}>
                  {`${t('dropdown:contract_contract_type')}`}
                </div>
                <div style={{ marginBottom: '30px', width: '100%' }}>
                  {model.filesArray.map(
                    (file: File | IDocument, index: number) => {
                      return (
                        <React.Fragment key={index}>
                          {file !== null && (
                            <div className={styles.ContractContainer}>
                              <p>{file.name}</p>
                              <div className={styles.DownloadDeleteContainer}>
                                <DownloadLink
                                  style={promptAlert}
                                  label="Preuzmi"
                                  filename={file.name}
                                  exportFile={async () => {
                                    if (file instanceof File) {
                                      return file;
                                    } else {
                                      const fileResponse =
                                        await model.root.api.partner.getPartnerDocument(
                                          model.partnerId,
                                          (file as any).id
                                        );
                                      return fileResponse;
                                    }
                                  }}
                                />
                                <img
                                  className={styles.DeleteIcon}
                                  src={deleteIcon}
                                  alt="delete"
                                  onClick={async () => {
                                    if ((file as File & { id: string }).id) {
                                      await deleteDocument(
                                        model.partnerId,
                                        (file as File & { id: string }).id
                                      );
                                    }
                                    model.removeFile(file);
                                  }}
                                />
                              </div>
                            </div>
                          )}
                        </React.Fragment>
                      );
                    }
                  )}
                </div>

                <LightButton
                  model={model.uploadDocumentBtn}
                  onClick={() => {
                    model.getUploadContractModel().modal.setOpened(true);
                  }}
                />
              </div>
            </div>
          </div>

          <div className={styles.SaveBtnContainer}>
            <Button
              model={model.getSaveButton()}
              onClick={() => {
                model.adminStatus === ADMIN_STATUS_REQUESTED
                  ? model.approve(currentPage.loadData)
                  : model.save(currentPage.loadData);
              }}
            />
            {model.adminStatus === ADMIN_STATUS_REQUESTED ? (
              <span
                style={{
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  marginTop: '10px',
                }}
                onClick={() =>
                  model.reject(() => {
                    currentPage.loadData();
                  })
                }
              >
                Odbij
              </span>
            ) : null}
          </div>
          {model.adminStatus === ADMIN_STATUS_ACTIVE && (
            <span
              className={styles.Link}
              onClick={async () => {
                await currentPage.root.api.admin.deactivate(model.adminId);
                currentPage.loadData(false);
                model.close();
              }}
            >
              {t('basic:deactivate')}
            </span>
          )}
          {(model.adminStatus === ADMIN_STATUS_INACTIVE ||
            model.adminStatus === ADMIN_STATUS_NONACTIVE) && (
            <span
              className={styles.Link}
              onClick={async () => {
                await currentPage.root.api.admin.activate(model.adminId);
                currentPage.init;
                model.close();
                currentPage.loadData(false);
              }}
            >
              {t('basic:activate')}
            </span>
          )}
        </div>
        <UploadContractModal model={model.getUploadContractModel()} />
      </Modal>
    );
  }
);
