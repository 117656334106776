import { observer } from 'mobx-react';
import { TextAreaInputModelType } from '../../../internal';
import styles from './TextAreaInputField.module.scss';
import * as React from 'react';
import 'draft-js/dist/Draft.css';
import { CustomTextEditor } from './CustomTextEditor';
import { useTranslation } from 'react-i18next';

export const TextAreaInputField = observer(
  (props: { model: TextAreaInputModelType; id?: string }): JSX.Element => {
    const { model, id } = props;
    const { t } = useTranslation();
    return (
      <div id={id} className={styles.TextAreaContainer}>
        {model.label ? (
          <label htmlFor={model.id} className={styles.InputLabel}>
            {t(model.label)}
            <span className={styles.Required}>
              {model.isRequired ? '*' : ''}
            </span>
          </label>
        ) : (
          <></>
        )}
        <CustomTextEditor model={model} />
        {model.isInvalid ? (
          <div className={styles.ErrorContainer}>
            <div className={styles.Error}>{t(model.currentMessageValue)}</div>
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  }
);
