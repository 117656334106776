import { observer } from 'mobx-react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { AdditionalUserModelType } from '../../../internal';
import { toAmount } from '../../../utils/methods';
import { templateReplace } from '../../../utils/template';
import { InputField } from '../../UI/input/InputField';
import styles from './AdditionalUsers.module.scss';
import { UserPriceListModal } from './user-price-list/UserPriceListModal';

export const AdditionalUsers = observer(
  (props: { model: AdditionalUserModelType }): JSX.Element => {
    const { model } = props;
    const { t } = useTranslation();
    return (
      <div className={styles.AddAdditional}>
        <div className={styles.BuyAdditionalUsersContainer}>
          <div className={styles.BuyAdditionalUsersMain}>
            <div className={styles.InputContainer}>
              <span className={styles.BuyAdditionalUsersText}>
                {t('basic:buy_additional_users_text')}
              </span>
              <InputField model={model.numberOfAdditionalUsersField} />
              <div className={styles.LinkContainer}>
                <a
                  onClick={() => {
                    model.openModal();
                  }}
                  className={styles.Link}
                >
                  {t('basic:location_addition_price_list')}
                </a>
              </div>
            </div>
            {model.numberOfUsersExceedsMax && (
              <div className={styles.MaxExceededText}>
                {templateReplace(
                  t('basic:maximum_number_of_users_you_can_add'),
                  'x',
                  `${toAmount(model?.numberOfAdditionalUsersField?.max)}`
                )}
              </div>
            )}
          </div>
          <UserPriceListModal model={model} />
        </div>
        <div className={styles.PriceContainer}>
          <span className={styles.PriceSpan}>{t('basic:price')}</span>
          <div className={styles.PriceContent}>
            <span className={styles.Price}>{toAmount(model.amount)} RSD</span>
          </div>
        </div>
      </div>
    );
  }
);
