import * as React from 'react';
import { observer } from 'mobx-react';

import styles from './InfoSection.module.scss';

import { ButtonModel } from '../../../../../internal';
import { PreviewButton } from '../../preview-button/PreviewButton';
import { Show } from '../../../../UI/show/Show';
import { OfferPreview } from '../../OfferDetailsPreview';
import {
  shouldShowBothPricesFunc,
  shouldShowOnlyRegularPriceFunc,
} from '../../../../UI/adtype/conditions';
import { toAmount } from '../../../../../utils/methods';
import ExpirationInfo from '../../../../UI/expiration-info/ExpirationInfo';

interface IOfferModel {
  model: OfferPreview;
}

const dummyCouponBtn = ButtonModel.create({
  id: 'couponBtn',
  label: 'offer:get_coupon',
});

export const InfoSection = observer((props: IOfferModel) => {
  const { model } = props;

  const shouldShowOnlyRegularPrice = () =>
    shouldShowOnlyRegularPriceFunc(model.regularPrice, model.loweredPrice);
  const shouldShowBothPrices = () =>
    shouldShowBothPricesFunc(model.regularPrice, model.loweredPrice);
  return (
    <>
      <div className={styles.InfoSection}>
        <div className={styles.Store}>
          <p className={styles.StoreName}>{model.partner?.name}</p>
        </div>
        <h2 className={styles.Title}>{model.name}</h2>
        <div className={styles.ExpirationContainer}>
          <ExpirationInfo model={model} />
        </div>
        <Show condition={shouldShowOnlyRegularPrice()}>
          <div className={styles.PriceContainer}>
            <div className={styles.RegularPrice}>
              <div className={styles.RegularPriceRow}>
                <div className={styles.PriceText}>Cena</div>
                <div className={styles.PriceAmount}>
                  {model.regularPrice} RSD
                </div>
              </div>
            </div>
          </div>
        </Show>
        <Show condition={shouldShowBothPrices()}>
          <div className={styles.PriceContainer}>
            <div className={styles.OldPrice}>
              <span>{toAmount(model.regularPrice)} RSD</span>
            </div>
            <div className={styles.RegularPrice}>
              <div className={styles.RegularPriceRow}>
                <div className={styles.PriceText}>Cena</div>
                <div className={styles.PriceAmount}>
                  {toAmount(model.loweredPrice)} RSD
                </div>
              </div>
            </div>
          </div>
        </Show>
        <div className={styles.CouponBtnContainer}>
          <PreviewButton model={dummyCouponBtn} />
        </div>
      </div>
    </>
  );
});
