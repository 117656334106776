import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { DropdowOptionGroupsModelType } from '../../../internal';
import { getCategoryIdFromSubcategory } from '../../../utils/methods';
import REMOVE from '../../../assets/icons/close-circle.svg';
import styles from './SelectedOptionGroupsOptions.module.scss';

export interface IDropdownOptionGroupsOptionsProps {
  model: DropdowOptionGroupsModelType;
}

export const SelectedOptionGroupsOptions: FC<IDropdownOptionGroupsOptionsProps> =
  observer(({ model }) => {
    const { t } = useTranslation();

    function getSubcategoryNameFromId(categorySubcategoryId: string): string {
      return model.optionsArray.find(
        (option: any) => option.id === categorySubcategoryId
      )?.label;
    }

    function getCategoryNameFromId(categorySubcategoryId: string): string {
      const categoryId = getCategoryIdFromSubcategory(categorySubcategoryId);
      return model.optionGroupsArray.find(
        (optionGroup: any) => optionGroup.id === categoryId
      )?.label;
    }

    function removeSelectedOption(categorySubcategoryId: string): void {
      const optionToUncheck = model.optionsArray.find(
        (option: any) => option.id === categorySubcategoryId
      );
      model.options.uncheck(optionToUncheck);
    }

    return (
      <div className={styles.SelectedOptionContainer}>
        {model.selectedOptionsArray.length > 0 && (
          <div className={styles.SelectedOptionText}>
            <span>{`${t('offer:you_have_chosen')} ${
              model.selectedOptionsArray.length
            } ${
              model.selectedOptionsArray.length > 1
                ? t('offer:chosen_categories')
                : t('offer:chosen_category')
            }`}</span>
          </div>
        )}
        {model.selectedOptionsArray.map((selectedOption: string) => {
          return (
            <div
              className={styles.SelectedOption}
              key={selectedOption}
              onClick={(e: any) => {
                e.stopPropagation();
              }}
            >
              {getCategoryNameFromId(selectedOption)}/
              {getSubcategoryNameFromId(selectedOption)}
              <span>
                <img
                  className={styles.SortIcon}
                  src={REMOVE}
                  onClick={(e: any) => {
                    removeSelectedOption(selectedOption);
                  }}
                />
              </span>
            </div>
          );
        })}
      </div>
    );
  });
