import { observer } from 'mobx-react';
import React from 'react';
import { MonthlyPayment } from './MonthlyPayment';
import { MonthlyPaymentContract } from './MonthlyPaymentContract';
import { PayAsYouGo } from './PayAsYouGo';
import { useMst } from '../../../../internal';
import styles from './TariffPackages.module.scss';
import { dateSRLocale } from '../../../../utils/date';

export const ContractComponent = observer(
  (props: { calculator: string }): JSX.Element => {
    const { data } = useMst();
    switch (props.calculator) {
      case 'subscription':
        return (
          <>
            <MonthlyPayment />
            <MonthlyPaymentContract />
            <PayAsYouGo />
          </>
        );
      case 'subscription-with-contract-obligation':
        return (
          <>
            <MonthlyPaymentContract />
            {data?.contractDetails.obligation_from &&
            data?.contractDetails.obligation_till ? (
              <div className={styles.Obligation}>
                <strong>Ugovorna obaveza </strong>{' '}
                {dateSRLocale(new Date(data?.contractDetails.obligation_from))}{' '}
                -{' '}
                {dateSRLocale(new Date(data?.contractDetails.obligation_till))}
              </div>
            ) : null}
            <MonthlyPayment />
            <PayAsYouGo />
          </>
        );
      case 'pay-as-you-go':
        return (
          <>
            <PayAsYouGo />
            <MonthlyPaymentContract />
            <MonthlyPayment />
          </>
        );
      default: {
        return (
          <>
            <MonthlyPaymentContract />
            <MonthlyPayment />
            <PayAsYouGo />
          </>
        );
      }
    }
  }
);
