import { Instance, types } from 'mobx-state-tree';
import { ComponentModel, FunctionModel } from '../../../../internal';

export const ButtonModel = types
  .compose(
    ComponentModel,
    types.model({
      onClickCallback: types.maybe(types.late(() => FunctionModel)),
      disabled: types.maybe(types.boolean),
    })
  )
  .actions((self) => {
    return {
      onClick() {
        if (self.onClickCallback && !self.disabled) {
          self.onClickCallback();
        }
      },
      setCallback(callback: () => void) {
        self.onClickCallback = callback;
      },
      click() {
        self.onClick();
      },
      enable() {
        self.setDisabled(false);
      },
      disable() {
        self.setDisabled(true);
      },
    };
  })
  .views((self) => {
    return {
      get isDisabled() {
        return self.disabled === true;
      },
    };
  })
  .named('ButtonModel');

export type ButtonModelType = Instance<typeof ButtonModel>;
