import * as React from 'react';
import { observer } from 'mobx-react';

import styles from './SellerInfoSection.module.scss';

import { useTranslation } from 'react-i18next';
import { ButtonModel } from '../../../../../internal';
import { PreviewButton } from '../../preview-button/PreviewButton';
import { Show } from '../../../../UI/show/Show';
import { TextSection } from '../../text-section/TextSection';
import { OfferPreview } from '../../OfferDetailsPreview';

interface IOfferModel {
  model: OfferPreview;
}

const dummyShowNearestStoresButton = ButtonModel.create({
  id: 'showNearestStores',
  label: 'offer:show_nearest_stores',
});

const dummyLinkHandler = (e: any) => {
  e.preventDefault();
};

export const SellerInfoSection = observer((props: IOfferModel) => {
  const { t } = useTranslation();
  const { model } = props;
  return (
    <>
      <div className={styles.SellerInfoSection}>
        <TextSection
          id={'kontakt'}
          title={t('offer:offer_location_and_contact')}
        />
        <div className={styles.Content}>
          <p className={styles.Title}>{model.partner?.name}</p>
          <div className={styles.InfoItem}>
            <span>{t('basic:contact_phone_number')}</span>
            <a onClick={dummyLinkHandler} className={styles.Link}>
              {model.partner?.phone}
            </a>
          </div>
          <div className={styles.InfoItem}>
            <span>{t('basic:website')}</span>
            {!!model.partner?.web && (
              <span>
                <a onClick={dummyLinkHandler} className={styles.Link}>
                  {model.partner.web}
                </a>
              </span>
            )}
          </div>
          <div className={styles.InfoItem}>
            <span>{t('basic:email')}</span>
            <span>
              <a onClick={dummyLinkHandler} className={styles.Link}>
                {model.partner?.email}
              </a>
            </span>
          </div>
          <Show condition={model.shouldDisplayShowStoresButton}>
            <div className={styles.ShowNearestStoresContainer}>
              <PreviewButton model={dummyShowNearestStoresButton} />
            </div>
          </Show>
        </div>
      </div>
    </>
  );
});
