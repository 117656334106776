import { observer } from 'mobx-react';
import { AdTypeModelType } from '../../../../internal';
import styles from '../AdType.module.scss';
import React, { ReactNode } from 'react';
import windPayIcon from '../../../../assets/images/icon-apple-pay.svg';
import { useMst } from '../../../../internal';
import { FileInput } from '../../file-input/FileInput';
import { Show } from '../../show/Show';
import { toAmount } from '../../../../utils/methods';
import StandardAdImage from '../../../../assets/images/offer-image-standard.png';

interface IOfferModel {
  model: AdTypeModelType;
  uiOptions?: { hideDistanceIcon?: boolean };
}

export const StandardAd = observer((props: IOfferModel) => {
  const { model } = props;
  const {
    currentPage: {
      adName,
      adRegularPriceRSD,
      adLoweredPriceRSD,
      fileInputs,
      isImageFromResponse,
      offerPreview,
      shouldShowBothPrices,
    },
  } = useMst();
  const partnerName = offerPreview?.partner?.name;

  // AD SHOULD NEVER DISPLAY ONLY REGULAR PRICE
  // const shouldShowOnlyRegularPrice = () =>
  //   shouldShowOnlyRegularPriceFunc(adRegularPriceRSD, adLoweredPriceRSD);

  // const shouldShowBothPrices = () =>
  //   shouldShowBothPricesFunc(model.regularPrice, model.loweredPrice);

  const renderImage = () => {
    if (isImageFromResponse) {
      return (
        <div
          className={styles.UploadedImage}
          style={{
            backgroundImage: `url(${offerPreview.imagesArray[0]})`,
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          }}
        />
      );
    }
    if (fileInputs[0].filesArray.length > 0) {
      return (
        <FileInput
          model={fileInputs[0]}
          ImageWrapper={({ children }: { children: ReactNode }) => {
            const imgSrc = (children as any)?.props?.src;
            return (
              <div
                className={styles.UploadedImage}
                style={{
                  backgroundImage: `url(${imgSrc})`,
                  backgroundPosition: 'top center',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                }}
              />
            );
          }}
          LabelRenderer={() => <div />}
        />
      );
    }
    return (
      <div
        className={styles.UploadedImage}
        style={{
          backgroundImage: `url(${StandardAdImage})`,
          backgroundPosition: 'top center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}
      />
    );
  };

  return (
    <>
      <div className={styles.StandardAdContainer}>
        <div className={styles.StandardAd}>
          <div className={styles.TopSection}>
            <div className={styles.PartnerInfo}>{partnerName}</div>
          </div>
          <div className={styles.Content}>
            <div className={styles.PhotoWrapper}>{renderImage()}</div>
            <div className={styles.TextWrapper}>
              <h2 className={styles.Title}>{adName}</h2>
              <div className={styles.Info}>
                {/*AD SHOULD NEVER DISPLAY ONLY REGULAR PRICE*/}
                {/*<Show condition={shouldShowOnlyRegularPrice()}>*/}
                {/*  <span className={styles.Price}>*/}
                {/*    <span>{adRegularPriceRSD}</span>*/}
                {/*    <span>RSD</span>*/}
                {/*  </span>*/}
                {/*</Show>*/}
                <Show condition={shouldShowBothPrices}>
                  <div className={styles.Price}>
                    <p className={styles.OriginalPrice}>
                      {toAmount(Number(adRegularPriceRSD))} RSD
                    </p>
                    <p className={styles.Amount}>
                      {toAmount(adLoweredPriceRSD)} RSD
                    </p>
                  </div>
                </Show>
              </div>
              {model.isWindPaySupported && (
                <img
                  className={styles.WindPayIcon}
                  src={windPayIcon}
                  alt={'wind pay supported'}
                />
              )}
            </div>
          </div>
        </div>
        <Show condition={model.showDescriptionList}>
          <div className={styles.DescriptionSection}>
            <ul className={styles.Content}>
              <li>Standardna veličina oglasa</li>
            </ul>
          </div>
        </Show>
      </div>
    </>
  );
});
