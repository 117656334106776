import { getRoot, Instance, types } from 'mobx-state-tree';
import {
  ComponentModel,
  ModalModel,
  RootType,
  TextInputModel,
} from '../../../internal';
import i18n from 'i18next';
import { ADMINISTRATOR, MODERATOR, SELLER } from '../../../constants/roleTypes';
import { YettelAdminTypeIds } from '../../../constants/yettelAdmins';

export const AdministrationAccountModel = types
  .compose(
    ComponentModel,
    types.model({
      type: types.maybe(types.string),
      account_id: types.maybe(types.maybeNull(types.string)),
      first_name: types.late(() => TextInputModel),
      last_name: types.late(() => TextInputModel),
      email: types.late(() => TextInputModel),
      modal: types.late(() => ModalModel),
    })
  )
  .views((self) => {
    return {
      get root(): RootType {
        return getRoot(self);
      },
      get addAccountModalTitleText(): string | undefined {
        return self.type === MODERATOR
          ? i18n.t('administration:modal_title_add_moderator')
          : self.type === ADMINISTRATOR
          ? i18n.t('administration:modal_title_add_administrator')
          : self.type === SELLER
          ? i18n.t('administration:modal_title_add_seller')
          : undefined;
      },
      get editAccountModalTitleText(): string | undefined {
        return self.type === MODERATOR
          ? i18n.t('administration:modal_title_edit_moderator')
          : self.type === ADMINISTRATOR
          ? i18n.t('administration:modal_title_edit_administrator')
          : self.type === SELLER
          ? i18n.t('administration:modal_title_edit_seller')
          : undefined;
      },
      get deleteAccountModalTitleText(): string | undefined {
        return `${i18n.t('basic:delete_admin_question')} ${self.firstName} ${
          self.lastName
        }?)`;
      },
      get roleId(): string | undefined {
        return self.type === MODERATOR
          ? YettelAdminTypeIds.MODERATOR
          : self.type === ADMINISTRATOR
          ? YettelAdminTypeIds.ADMINISTRATOR
          : self.type === SELLER
          ? YettelAdminTypeIds.SELLER
          : undefined;
      },
      get isUpdate(): boolean {
        return !!self.account_id;
      },
      get isDisabled(): boolean {
        return this.isUpdate;
      },
    };
  })
  .actions((self) => {
    return {
      setType(value: string) {
        self.type = value;
      },
      setAccountId(id: string | null) {
        self.account_id = id;
      },
      setFirstName(text: string) {
        self.first_name.value = text;
      },
      setLastName(text: string) {
        self.last_name.value = text;
      },
      setEmail(text: string) {
        self.email.value = text;
        self.email.disabled = true;
      },
      resetInputs() {
        this.setAccountId(null);
        self.email.disabled = false;
        this.clear();
      },
      checkIfIsValid() {
        const children = self.isUpdate
          ? [self.first_name, self.last_name]
          : [self.first_name, self.last_name, self.email];
        let valid = true;
        for (const child of children) {
          child.runValidators();
          if (child.isInvalid) {
            valid = false;
          }
        }
        return valid;
      },
      clear() {
        self.first_name.clearData();
        self.last_name.clearData();
        self.email.clearData();
      },
      async save() {
        if (!this.checkIfIsValid()) {
          self.root.showFailure(i18n.t('alert:form_invalid'));
          return;
        }
        if (self.isUpdate) {
          await self.root.api.admin.updateAdmin(self.account_id, {
            first_name: self.first_name.value,
            last_name: self.last_name.value,
          });
        } else {
          await self.root.api.admin.createAdmin({
            first_name: self.first_name.value,
            last_name: self.last_name.value,
            email: self.email.value,
            role_id: self.roleId,
          });
        }
        self.modal.setOpened(false);
        this.clear();
        await self.root.currentPage.getPageData();
      },
    };
  })
  .named('AdministrationAccountModel');

export type AdministrationAccountModelType = Instance<
  typeof AdministrationAccountModel
>;
