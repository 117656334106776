import { observer } from 'mobx-react';
import { IInvoice, useMst } from '../../internal';
import { PaymentReport } from './PaymentReport';
import styles from './PaymentsAndDocumentsTab1.module.scss';
import styles2 from './PaymentsAndDocumentsTab2.module.scss';

export const PaymentsAndDocumentsTab1 = observer(() => {
  const {
    currentPage,
    data: { isPromoPeriodOver },
  } = useMst();

  if (!isPromoPeriodOver) {
    return (
      <p className={styles.isPromoPeriodMessage}>
        Tokom trajanja promo perioda oglašavanje je besplatno. Nemate trenutnog
        zaduženja.
      </p>
    );
  }

  return (
    <div className={styles.wrapper}>
      {currentPage.currentInvoices.map((invoice: IInvoice, i: number) => {
        if (i === 0) {
          return invoice.calculator === 'pay-as-you-go' ? (
            invoice.invoice_ads?.length > 0 ? (
              <PaymentReport
                key={i}
                paymentReport={invoice}
                perOfferPaymentsTable={currentPage.getInvoiceAds()}
                showMonthAndInvoiceDate={true}
              />
            ) : (
              <p key={i} className={styles2.noInvoicesMessage}>
                Trenutno nemate zaduženja na Yettel Shopping-u.
              </p>
            )
          ) : (
            <PaymentReport
              key={i}
              paymentReport={invoice}
              showMonthAndInvoiceDate={true}
            />
          );
        } else {
          return invoice.calculator === 'pay-as-you-go' &&
            invoice.invoice_ads?.length > 0 ? (
            <PaymentReport
              key={i}
              paymentReport={invoice}
              perOfferPaymentsTable={currentPage.getInvoiceAds()}
              showMonthAndInvoiceDate={false}
            />
          ) : (
            <PaymentReport
              key={i}
              paymentReport={invoice}
              showMonthAndInvoiceDate={false}
            />
          );
        }
      })}
      {currentPage.currentInvoices.length > 1 && (
        <div className={styles.finalSum}>
          <span> Ukupno za naplatu </span>
          <span>
            {currentPage.currentInvoicesSum.toLocaleString('sr-RS')} RSD
          </span>
        </div>
      )}
    </div>
  );
});
