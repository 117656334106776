import debounce from 'lodash/debounce';
import { getParent, getRoot, Instance, types } from 'mobx-state-tree';
import { CATEGORIES } from '../../../constants/createoffer';
import i18n from '../../../i18n';
import search from '../../../assets/icons/search.svg';
import {
  AdTableModel,
  ColumnModel,
  DropdownModel,
  PageModel,
  PaginationModel,
  RootType,
  TabModel,
  TabsModel,
  TextInputModel,
  ButtonModel,
  CheckboxGroupModel,
  AdsTableFiltersModel,
  DropdownMultiselectModel,
  AdsTableFilterModel,
  CheckboxModelType,
  CheckboxGroupModelType,
  DatePickerV2Model,
  SliderModel,
  SortModel,
  SearchAndMultiselectFilterModel,
  Partner,
} from '../../../internal';
import { MODERATOR_PROFILE } from '../../../constants/header';
import { reaction } from 'mobx';
import { HttpVerbs } from '../../../constants/verbs';
import { format } from 'date-fns';
import {
  MODERATOR_ID,
  PRODAVAC_ID,
  SUPER_ADMIN_ID,
} from '../../../constants/admins';

export const AllAdsModel = types
  .compose(
    PageModel,
    types.model({
      activeTab: CATEGORIES,
      columnsConfig: types.frozen<any>({}),
      visibleColumnsConfig: types.frozen<any>({}),
      adDiscountPercentagesConfig: types.frozen<any>({}),
      adStatusesConfig: types.frozen<any>({}),
      categoriesConfig: types.frozen<any>({}),
      subcategoriesConfig: types.frozen<any>({}),
      allUserLocationsConfig: types.frozen<any>({}),
      allPartnersConfig: types.frozen<any>({}),
      allPartnersLegalNameConfig: types.frozen<any>({}),
      creatorsConfig: types.frozen<any>({}),
      publishersConfig: types.frozen<any>({}),
      partnershipExpertsConfig: types.frozen<any>({}),
    })
  )
  .views((self) => {
    return {
      get root(): RootType {
        return getRoot(self);
      },
      get tableSearchValue() {
        if (this.root.currentPage?.id === self.id) {
          return this.root.currentPage.getSearchInput().value;
        }
      },
      get tableFilters() {
        const filters: any = {};
        if (this.root.currentPage?.id === self.id) {
          for (const filter of this.root.currentPage.getColumnFilters()
            .filtersArray) {
            filters[filter.id] = filter.selectedOptionsArray;
          }
        }
        return filters;
      },
      get tableFiltersQuery() {
        let filtersQuery = '';
        if (this.root.currentPage?.id === self.id) {
          const filtersArray =
            this.root.currentPage.getColumnFilters?.().filtersArray;
          if (filtersArray) {
            for (const filter of filtersArray) {
              if (filter.type === 'date' && filter.filter.date) {
                filtersQuery += filter.filter.id + '=';
                filtersQuery += format(filter.filter.date, 'yyyy-MM-dd');
                filtersQuery += '&';
                continue;
              }
              if (
                filter.type === 'dateRange' &&
                filter.filter.dateRangeStart &&
                filter.filter.dateRangeEnd
              ) {
                filtersQuery += filter.filter.id + '=';
                filtersQuery +=
                  format(filter.filter.dateRangeStart, 'yyyy-MM-dd') +
                  ',' +
                  format(filter.filter.dateRangeEnd, 'yyyy-MM-dd');
                filtersQuery += '&';
                continue;
              }
              if (filter.type === 'text' && filter.filter.value !== '') {
                filtersQuery += filter.filter.id + '=';
                filtersQuery += filter.filter.value;
                filtersQuery += '&';
                continue;
              }
              if (filter.type === 'sort' && filter.filter.type !== 'default') {
                filtersQuery += 'order_by=' + filter.filter.id;
                filtersQuery += '&';
                filtersQuery += 'order=' + filter.filter.type;
                filtersQuery += '&';
                continue;
              }
              if (filter.type === 'slider' && filter.filter.value !== 18) {
                filtersQuery += filter.filter.id + '=';
                filtersQuery += filter.filter.value;

                filtersQuery += '&';
                continue;
              }
              if (filter.type === 'multiselect') {
                if (
                  filter.filter.selectedOptionsArray.includes('allOptions') ||
                  filter.filter.selectedOptionsArray.length === 0
                ) {
                  continue;
                }
                if (filter.filter.id === 'category_name') {
                  filtersQuery += 'category_id=';
                } else if (filter.filter.id === 'creator') {
                  filtersQuery += 'creator_id=';
                } else if (filter.filter.id === 'partnership_expert') {
                  filtersQuery += 'partnership_expert_id=';
                } else if (filter.filter.id === 'publisher') {
                  filtersQuery += 'publisher_id=';
                } else {
                  filtersQuery += filter.filter.id + '=';
                }
                for (const filterOption of filter.filter.selectedOptionsArray) {
                  filtersQuery += filterOption;
                  const lastFilter =
                    filter.filter.selectedOptionsArray[
                      filter.filter.selectedOptionsArray.length - 1
                    ];
                  if (filterOption !== lastFilter) {
                    filtersQuery += ',';
                  }
                }
                filtersQuery += '&';
                continue;
              }
              if (filter.type === 'multiselectNested') {
                let alreadyAddedFilterIdToQuery = false;
                for (const nestedFilters of filter.filter.optionsArray) {
                  if (
                    nestedFilters.selectedCheckboxesIds.length ===
                    nestedFilters.checkboxes.size
                  ) {
                    continue;
                  } else {
                    if (!alreadyAddedFilterIdToQuery) {
                      if (filter.filter.id === 'subcategory_name') {
                        filtersQuery += 'subcategory_id=';
                      } else {
                        filtersQuery += filter.filter.id + '=';
                      }
                      alreadyAddedFilterIdToQuery = true;
                    }
                  }
                  filtersQuery += nestedFilters.selectedCheckboxesIds.join(',');
                  filtersQuery += ',';
                }
                filtersQuery = filtersQuery.slice(0, -1);
                filtersQuery += '&';
              }
              if (filter.type === 'searchAndMultiselect') {
                if (
                  !filter.filter.filterMultiselect.selectedOptionsArray.includes(
                    'allOptions'
                  ) &&
                  filter.filter.filterMultiselect.selectedOptionsArray.length >
                    0
                ) {
                  if (
                    filter.filter.filterMultiselect.selectedOptionsArray.includes(
                      'allOptions'
                    ) ||
                    filter.filter.filterMultiselect.selectedOptionsArray
                      .length === 0
                  ) {
                    continue;
                  }
                  if (
                    filter.filter.filterMultiselect.id === 'partner_name' ||
                    filter.filter.filterMultiselect.id === 'partner_legal_name'
                  ) {
                    filtersQuery += 'partner_id=';
                  } else {
                    filtersQuery += filter.filter.filterMultiselect.id + '=';
                  }
                  for (const filterOption of filter.filter.filterMultiselect
                    .selectedOptionsArray) {
                    filtersQuery += filterOption;
                    const lastFilter =
                      filter.filter.filterMultiselect.selectedOptionsArray[
                        filter.filter.filterMultiselect.selectedOptionsArray
                          .length - 1
                      ];
                    if (filterOption !== lastFilter) {
                      filtersQuery += ',';
                    }
                  }
                  filtersQuery += '&';
                  continue;
                } else {
                  if (filter.filter.filterSearch.value !== '') {
                    filtersQuery += filter.filter.filterSearch.id + '=';
                    filtersQuery += filter.filter.filterSearch.value;
                    filtersQuery += '&';
                    continue;
                  }
                }
              }
            }
          } else {
            return '';
          }

          filtersQuery = filtersQuery.slice(0, -1);
        }

        return filtersQuery;
      },
    };
  })
  .actions((self) => {
    return {
      afterCreate() {
        reaction(
          () => {
            return self.tableFiltersQuery;
          },
          debounce((tableFiltersQuery) => {
            return !self.root.isLoggedOut && this.loadExtendedAds(false);
          }, 500)
        );
        reaction(
          () => {
            return self.tableSearchValue;
          },
          debounce((tableSearchValue) => {
            return !self.root.isLoggedOut && this.loadExtendedAds(false);
          }, 500)
        );
      },
      async onPageExit() {
        // prevent caching
        self.clearData();
      },
      setVisibleColumnsConfig(visibleColumnsConfig: any) {
        self.visibleColumnsConfig = visibleColumnsConfig;
      },
      setAdDiscountPercentagesConfig(adDiscountPercentagesConfig: any) {
        self.adDiscountPercentagesConfig = adDiscountPercentagesConfig;
      },
      setAdStatusesConfig(adStatusesConfig: any) {
        self.adStatusesConfig = adStatusesConfig;
      },
      setCategoriesConfig(categoriesConfig: any) {
        self.categoriesConfig = categoriesConfig;
      },
      setSubcategoriesConfig(subcategoriesConfig: any) {
        self.subcategoriesConfig = subcategoriesConfig;
      },
      setAllUserLocationsConfig(allUserLocationsConfig: any) {
        self.allUserLocationsConfig = allUserLocationsConfig;
      },
      setAllPartnersConfig(allPartnersConfig: any) {
        self.allPartnersConfig = allPartnersConfig;
      },
      setallPartnersLegalNameConfig(allPartnersLegalNameConfig: any) {
        self.allPartnersLegalNameConfig = allPartnersLegalNameConfig;
      },
      setCreatorsConfig(creatorsConfig: any) {
        self.creatorsConfig = creatorsConfig;
      },
      setPublishersConfig(publishersConfig: any) {
        self.publishersConfig = publishersConfig;
      },
      setPartnershipExpertsConfig(partnershipExpertsConfig: any) {
        self.partnershipExpertsConfig = partnershipExpertsConfig;
      },

      async beforePageEnter() {
        const visibleColumns = await self.root.api.offers.getVisibleColumns();

        await Promise.all([
          self.root.api.category.getAllCategories('abc'),
          self.root.api.partner.getAllPartners(),
          self.root.api.offers.getAllColumns(),
          self.root.api.offers.getAdDiscountPercentages(),
          self.root.api.offers.getAdStatuses(),
          self.root.api.offers.getAllUserLocations(),
          self.root.api.offers.getAllSubCategories(),
          self.root.api.offers.getAllAdmins(),
        ]).then((response: any) => {
          const allPartnersConfig: any = {};
          const allPartnersLegalNameConfig: any = {};
          response[1].data.forEach((partner: Partner) => {
            allPartnersConfig[partner.id] = {
              id: partner.id,
              label: partner.name,
            };
            allPartnersLegalNameConfig[partner.id] = {
              id: partner.id,
              label: partner.legal_name,
            };
          });
          this.setAllPartnersConfig(allPartnersConfig);
          this.setallPartnersLegalNameConfig(allPartnersLegalNameConfig);

          const ColumnsArray = response[2].data;

          this.getAdList().setColumns(ColumnsArray);
          !this.getColumnsSelector().options.checkboxes.size &&
            this.getColumnsSelector().setOptions([
              { id: 'allOptions', label: 'Sve kolone' },
              ...response[2].data.map((c: any) => {
                return { id: Object.keys(c)[0], label: Object.values(c)[0] };
              }),
            ]);
          const visibleColumnsConfig: any = {};
          const visibleColumnsIds = visibleColumns.data.ads_extended_columns
            ? visibleColumns.data.ads_extended_columns.split(',')
            : response[2].data.map((c: any) => Object.keys(c)[0]);
          this.getColumnsSelector().setShouldPostOnChange(false);
          !Object.keys(self.visibleColumnsConfig).length &&
            visibleColumnsIds
              .filter((column: string) => {
                return !!column && column !== 'ad_description';
              })
              .forEach((visibleColumnId: string) => {
                visibleColumnsConfig[visibleColumnId] = visibleColumnId;
                this.getColumnsSelector().options.toggle(
                  this.getColumnsSelector().options.checkboxes.get(
                    visibleColumnId
                  )
                );
              });
          this.getColumnsSelector().setShouldPostOnChange(true);
          !Object.keys(self.visibleColumnsConfig).length &&
            this.setVisibleColumnsConfig(visibleColumnsConfig);

          const adDiscountPercentagesConfig: any = {};
          response[3].data.forEach((value: any) => {
            adDiscountPercentagesConfig[value] = {
              id: value,
              label: Number.isInteger(Number(value))
                ? Number(value).toFixed(0) + '%'
                : Number(value).toFixed(1) + '%',
            };
          });
          this.setAdDiscountPercentagesConfig(adDiscountPercentagesConfig);

          const adStatusesConfig: any = {};
          response[4].data.forEach((value: any) => {
            adStatusesConfig[value] = {
              id: value,
              label: 'ad_status:' + value,
            };
          });
          this.setAdStatusesConfig(adStatusesConfig);

          const allUserLocationsConfig: any = {};
          response[5].data.forEach((location: any) => {
            allUserLocationsConfig[location.location] = {
              id: location.location,
              label: location.location,
            };
          });
          this.setAllUserLocationsConfig(allUserLocationsConfig);

          const categoriesConfig: any = {};
          response[0].data.forEach((category: any) => {
            categoriesConfig[category.id] = {
              id: category.id,
              label: category.name,
              subcategories: category.subcategories,
            };
          });
          this.setCategoriesConfig(categoriesConfig);

          const subcategoriesConfig: any = [];
          Object.entries(categoriesConfig).forEach(
            ([key, value]: [key: string, value: any]) => {
              const sub = {
                label: value.label,
                id: value.label,
                checkboxes: {},
                allSelectedOnInit: true,
              };
              value.subcategories.forEach((subcategory: any) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                sub.checkboxes[subcategory.name] = {
                  id: subcategory.id,
                  label: subcategory.name,
                };
              });
              subcategoriesConfig.push(sub);
            }
          );
          this.setSubcategoriesConfig(subcategoriesConfig);

          const creatorsConfig: any = {};
          const publishersConfig: any = {};
          const partnershipExpertsConfig: any = {};
          response[7].data.forEach((admin: any) => {
            creatorsConfig[admin.id] = {
              id: admin.id,
              label: admin.email,
            };
            if (
              admin.role_id === MODERATOR_ID ||
              admin.role_id === SUPER_ADMIN_ID
            ) {
              publishersConfig[admin.id] = {
                id: admin.id,
                label: admin.email,
              };
            }
            if (admin.role_id === PRODAVAC_ID) {
              partnershipExpertsConfig[admin.id] = {
                id: admin.id,
                label: admin.email,
              };
            }
          });
          this.setCreatorsConfig(creatorsConfig);
          this.setPublishersConfig(publishersConfig);
          this.setPartnershipExpertsConfig(partnershipExpertsConfig);

          !(self as any).getCategoryListFilter().options.checkboxes?.size &&
            (self as any).getCategoryListFilter().setOptions(categoriesConfig);
          // (self as any).getCategoryListFilter().setOptions(categoriesConfig);
          !(self as any).getSubcategoryListFilter().options?.size &&
            (self as any)
              .getSubcategoryListFilter()
              .setOptions(subcategoriesConfig);
        });
      },
      getSearchInput() {
        return (
          self.components.get('Pretraga') ||
          self.addComponent(
            TextInputModel.create({
              id: 'Pretraga',
              placeholder: 'Pretraga',
              isRequired: false,
              icon: search,
            })
          )
        );
      },
      async loadExtendedAds(resetPagination = true) {
        if (resetPagination) {
          this.resetPagination();
        }

        const queryStringRequestBody: any = {};
        const queryStringSplitArray = (self as any).finalQuery.split('&');
        queryStringSplitArray.forEach((query: string) => {
          const queryPartsArray = query.split('=');
          queryStringRequestBody[queryPartsArray[0]] = queryPartsArray[1];
        });

        const response = await self.root.axios.requestData({
          type: HttpVerbs.POST,
          path: '/offers/extended',
          data: queryStringRequestBody,
        });
        this.getAdList().setElements(response.data);
        self.root.data.setTotalAdsCount(response?.meta?.total);
        this.setTotalPages();
        return response;
      },

      setTotalPages() {
        if (!self.root.data.totalAdsCount) {
          this.getAdList().pagination.setTotalPages(1);
          return;
        }
        const totalPages = Math.ceil(
          self.root.data.totalAdsCount / this.getAdList().pageSize
        );
        this.getAdList().pagination.setTotalPages(totalPages);
      },
      getTabs() {
        return (
          self.components.get('Tabs') ||
          self.addComponent(
            TabsModel.create({
              id: 'Tabs',
              selectedTab: 'submitted',
              tabs: {
                submitted: TabModel.create({
                  id: 'submitted',
                  name: i18n.t('tabs:submitted'),
                }),
                active: TabModel.create({
                  id: 'active',
                  name: i18n.t('tabs:active'),
                }),
                expired: TabModel.create({
                  id: 'expired',
                  name: i18n.t('tabs:expired'),
                }),
                rejected: TabModel.create({
                  id: 'rejected',
                  name: i18n.t('tabs:rejected'),
                }),
                prep: TabModel.create({
                  id: 'prep',
                  name: i18n.t('tabs:prep'),
                }),
              },
              onChangeCallback: () => {
                this.loadExtendedAds(true);
              },
            })
          )
        );
      },

      getColumnsSelector() {
        return (
          self.components.get('ColumnSelector') ||
          self.addComponent(
            DropdownMultiselectModel.create({
              id: 'ColumnSelector',
              type: 'single',
              isOpen: false,
              resetButton: ButtonModel.create({
                id: 'ColumnsDropdownResetButton',
                disabled: false,
              }),
              options: CheckboxGroupModel.create({
                id: 'CheckboxGroup',
                checkboxes: {},
              }),
            })
          )
        );
      },

      getColumnFilters() {
        return (
          self.components.get('ColumnFilters') ||
          self.addComponent(
            AdsTableFiltersModel.create({
              id: 'ColumnFilters',
              filters: {
                ad_id: AdsTableFilterModel.create({
                  id: 'ad_id',
                  type: 'text',
                  filter: TextInputModel.create({
                    id: 'ad_id',
                    placeholder: 'ID oglasa',
                    isRequired: false,
                    icon: search,
                    resetButton: ButtonModel.create({
                      id: 'ad_idResetButton',
                      disabled: false,
                    }),
                  }),
                }),
                ad_status: AdsTableFilterModel.create({
                  id: 'ad_status',
                  type: 'multiselect',
                  filter: DropdownMultiselectModel.create({
                    id: 'ad_status',
                    type: 'single',
                    onlyDropdown: true,
                    isOpen: false,
                    resetButton: ButtonModel.create({
                      id: 'ad_statusDropdownResetButton',
                      disabled: false,
                    }),
                    options: CheckboxGroupModel.create({
                      id: 'ad_statusDropdownCheckboxGroup',
                      checkboxes: {
                        allOptions: {
                          label: 'Sve',
                          id: 'allOptions',
                        },
                        ...self.adStatusesConfig,
                      },
                      selectedCheckboxes: {
                        allOptions: 'allOptions',
                      },
                    }),
                  }),
                }),
                ad_recommended: AdsTableFilterModel.create({
                  id: 'ad_recommended',
                  type: 'multiselect',
                  filter: DropdownMultiselectModel.create({
                    id: 'ad_recommended',
                    type: 'single',
                    onlyDropdown: true,
                    isOpen: false,
                    resetButton: ButtonModel.create({
                      id: 'ad_recommendedDropdownResetButton',
                      disabled: false,
                    }),
                    options: CheckboxGroupModel.create({
                      id: 'ad_recommendedDropdownCheckboxGroup',
                      checkboxes: {
                        allOptions: {
                          label: 'Sve',
                          id: 'allOptions',
                        },
                        yes: {
                          label: 'Da',
                          id: '1',
                        },
                        no: {
                          label: 'Ne',
                          id: '0',
                        },
                      },
                      selectedCheckboxes: {
                        allOptions: 'allOptions',
                      },
                    }),
                  }),
                }),
                category_name: AdsTableFilterModel.create({
                  id: 'category_name',
                  type: 'multiselect',
                  filter: DropdownMultiselectModel.create({
                    id: 'category_name',
                    type: 'single',
                    onlyDropdown: true,
                    isOpen: false,
                    resetButton: ButtonModel.create({
                      id: 'category_nameDropdownResetButton',
                      disabled: false,
                    }),
                    options: CheckboxGroupModel.create({
                      id: 'category_nameDropdownCheckboxGroup',
                      checkboxes: {
                        allOptions: {
                          label: 'Sve',
                          id: 'allOptions',
                        },
                        ...self.categoriesConfig,
                      },
                      selectedCheckboxes: {
                        allOptions: 'allOptions',
                      },
                      onCheck: (
                        nestedCheckbox: CheckboxModelType,
                        nestedCheckboxGroup: CheckboxGroupModelType
                      ) => {
                        (self as any)
                          .getSubcategoryListFilter()
                          .options.get(nestedCheckbox.label)
                          ?.setIsVisible(true);
                      },
                      onUncheck: (
                        nestedCheckbox: CheckboxModelType,
                        nestedCheckboxGroup: CheckboxGroupModelType
                      ) => {
                        (self as any)
                          .getSubcategoryListFilter()
                          .options.get(nestedCheckbox.label)
                          ?.setIsVisible(false);
                      },
                    }),
                  }),
                }),
                subcategory_name: AdsTableFilterModel.create({
                  id: 'subcategory_name',
                  type: 'multiselectNested',
                  filter: DropdownMultiselectModel.create({
                    id: 'subcategory_name',
                    type: 'nested',
                    onlyDropdown: true,
                    isOpen: false,
                    resetButton: ButtonModel.create({
                      id: 'subcategory_nameDropdownResetButton',
                      disabled: false,
                    }),
                    options: {},
                  }),
                }),
                ad_visual_type: AdsTableFilterModel.create({
                  id: 'ad_visual_type',
                  type: 'multiselect',
                  filter: DropdownMultiselectModel.create({
                    id: 'ad_visual_type',
                    type: 'single',
                    onlyDropdown: true,
                    isOpen: false,
                    resetButton: ButtonModel.create({
                      id: 'ad_visual_typeDropdownResetButton',
                      disabled: false,
                    }),
                    options: CheckboxGroupModel.create({
                      id: 'ad_visual_typeDropdownCheckboxGroup',
                      checkboxes: {
                        allOptions: {
                          label: 'Sve',
                          id: 'allOptions',
                        },
                        top: {
                          id: 'top',
                          label: 'Top',
                        },
                        besplatan: {
                          id: 'standard',
                          label: 'Besplatan',
                        },
                      },
                      selectedCheckboxes: {
                        allOptions: 'allOptions',
                      },
                    }),
                  }),
                }),
                ad_with_code: AdsTableFilterModel.create({
                  id: 'ad_with_code',
                  type: 'multiselect',
                  filter: DropdownMultiselectModel.create({
                    id: 'ad_with_code',
                    type: 'single',
                    onlyDropdown: true,
                    isOpen: false,
                    resetButton: ButtonModel.create({
                      id: 'ad_with_codeDropdownResetButton',
                      disabled: false,
                    }),
                    options: CheckboxGroupModel.create({
                      id: 'ad_with_codeDropdownCheckboxGroup',
                      checkboxes: {
                        allOptions: {
                          label: 'Sve',
                          id: 'allOptions',
                        },
                        personalized: {
                          id: 'personalized',
                          label: 'Personalizovani',
                        },
                        common: {
                          id: 'common',
                          label: 'Isti kod za sve korisnike',
                        },
                      },
                      selectedCheckboxes: {
                        allOptions: 'allOptions',
                      },
                    }),
                  }),
                }),
                ad_code_type: AdsTableFilterModel.create({
                  id: 'ad_code_type',
                  type: 'multiselect',
                  filter: DropdownMultiselectModel.create({
                    id: 'ad_code_type',
                    type: 'single',
                    onlyDropdown: true,
                    isOpen: false,
                    resetButton: ButtonModel.create({
                      id: 'ad_code_typeDropdownResetButton',
                      disabled: false,
                    }),
                    options: CheckboxGroupModel.create({
                      id: 'ad_code_typeDropdownCheckboxGroup',
                      checkboxes: {
                        allOptions: {
                          label: 'Sve',
                          id: 'allOptions',
                        },
                        qr: {
                          id: 'qr',
                          label: 'QR kod',
                        },
                        numeric: {
                          id: 'numeric',
                          label: 'Numerički kod',
                        },
                        bar: {
                          id: 'bar',
                          label: 'Bar kod',
                        },
                      },
                      selectedCheckboxes: {
                        allOptions: 'allOptions',
                      },
                    }),
                  }),
                }),
                ad_type: AdsTableFilterModel.create({
                  id: 'ad_type',
                  type: 'multiselect',
                  filter: DropdownMultiselectModel.create({
                    id: 'ad_type',
                    type: 'single',
                    onlyDropdown: true,
                    isOpen: false,
                    resetButton: ButtonModel.create({
                      id: 'ad_typeDropdownResetButton',
                      disabled: false,
                    }),
                    options: CheckboxGroupModel.create({
                      id: 'ad_typeDropdownCheckboxGroup',
                      checkboxes: {
                        allOptions: {
                          label: 'Sve',
                          id: 'allOptions',
                        },
                        standard: {
                          id: 'standard',
                          label: 'Nesegmentirana',
                        },
                        special: {
                          id: 'special',
                          label: 'Segmentirana',
                        },
                      },
                      selectedCheckboxes: {
                        allOptions: 'allOptions',
                      },
                    }),
                  }),
                }),
                ad_gender: AdsTableFilterModel.create({
                  id: 'ad_gender',
                  type: 'multiselect',
                  filter: DropdownMultiselectModel.create({
                    id: 'ad_gender',
                    type: 'single',
                    onlyDropdown: true,
                    isOpen: false,
                    resetButton: ButtonModel.create({
                      id: 'ad_genderDropdownResetButton',
                      disabled: false,
                    }),
                    options: CheckboxGroupModel.create({
                      id: 'ad_genderDropdownCheckboxGroup',
                      checkboxes: {
                        allOptions: {
                          label: 'Sve',
                          id: 'allOptions',
                        },
                        M: {
                          id: 'M',
                          label: 'Muški',
                        },
                        Ž: {
                          id: 'Ž',
                          label: 'Ženski',
                        },
                      },
                      selectedCheckboxes: {
                        allOptions: 'allOptions',
                      },
                    }),
                  }),
                }),
                ad_territory_type: AdsTableFilterModel.create({
                  id: 'ad_territory_type',
                  type: 'multiselect',
                  filter: DropdownMultiselectModel.create({
                    id: 'ad_territory_type',
                    type: 'single',
                    onlyDropdown: true,
                    isOpen: false,
                    resetButton: ButtonModel.create({
                      id: 'ad_territory_typeDropdownResetButton',
                      disabled: false,
                    }),
                    options: CheckboxGroupModel.create({
                      id: 'ad_territory_typeDropdownCheckboxGroup',
                      checkboxes: {
                        allOptions: {
                          label: 'Sve',
                          id: 'allOptions',
                        },
                        U: {
                          id: 'U',
                          label: 'Urbano',
                        },
                        R: {
                          id: 'R',
                          label: 'Ruralno',
                        },
                      },
                      selectedCheckboxes: {
                        allOptions: 'allOptions',
                      },
                    }),
                  }),
                }),
                ad_user_contract_type: AdsTableFilterModel.create({
                  id: 'ad_user_contract_type',
                  type: 'multiselect',
                  filter: DropdownMultiselectModel.create({
                    id: 'ad_user_contract_type',
                    type: 'single',
                    onlyDropdown: true,
                    isOpen: false,
                    resetButton: ButtonModel.create({
                      id: 'ad_user_contract_typeDropdownResetButton',
                      disabled: false,
                    }),
                    options: CheckboxGroupModel.create({
                      id: 'ad_user_contract_typeDropdownCheckboxGroup',
                      checkboxes: {
                        allOptions: {
                          label: 'Sve',
                          id: 'allOptions',
                        },
                        prepaid: {
                          id: 'prepaid',
                          label: 'Pripejd',
                        },
                        postpaid: {
                          id: 'postpaid',
                          label: 'Postpaid',
                        },
                        biznis: {
                          id: 'biznis',
                          label: 'Biznis',
                        },
                        nonYettel: {
                          id: 'non-yettel',
                          label: 'Non yettel',
                        },
                      },
                      selectedCheckboxes: {
                        allOptions: 'allOptions',
                      },
                    }),
                  }),
                }),
                ad_spender_type: AdsTableFilterModel.create({
                  id: 'ad_spender_type',
                  type: 'multiselect',
                  filter: DropdownMultiselectModel.create({
                    id: 'ad_spender_type',
                    type: 'single',
                    onlyDropdown: true,
                    isOpen: false,
                    resetButton: ButtonModel.create({
                      id: 'ad_spender_typeDropdownResetButton',
                      disabled: false,
                    }),
                    options: CheckboxGroupModel.create({
                      id: 'ad_spender_typeDropdownCheckboxGroup',
                      checkboxes: {
                        allOptions: {
                          label: 'Sve',
                          id: 'allOptions',
                        },
                        ARPU_RANK1: {
                          id: 'ARPU_RANK1',
                          label: 'Veliki potrošači',
                        },
                        ARPU_RANK2: {
                          id: 'ARPU_RANK2',
                          label: 'Srednji potrošači',
                        },
                        ARPU_RANK3: {
                          id: 'ARPU_RANK3',
                          label: 'Mali potrošači',
                        },
                      },
                      selectedCheckboxes: {
                        allOptions: 'allOptions',
                      },
                    }),
                  }),
                }),
                ad_name: AdsTableFilterModel.create({
                  id: 'ad_name',
                  type: 'text',
                  filter: TextInputModel.create({
                    id: 'ad_name',
                    placeholder: 'Naslov ponude',
                    isRequired: false,
                    icon: search,
                    resetButton: ButtonModel.create({
                      id: 'ad_nameResetButton',
                      disabled: false,
                    }),
                  }),
                }),
                ad_keywords: AdsTableFilterModel.create({
                  id: 'ad_keywords',
                  type: 'text',
                  filter: TextInputModel.create({
                    id: 'ad_keywords',
                    placeholder: 'Ključne reči',
                    isRequired: false,
                    icon: search,
                    resetButton: ButtonModel.create({
                      id: 'ad_keywordsResetButton',
                      disabled: false,
                    }),
                  }),
                }),
                ad_date_start: AdsTableFilterModel.create({
                  id: 'ad_date_start',
                  type: 'date',
                  filter: DatePickerV2Model.create({
                    id: 'ad_date_start',
                    resetButton: ButtonModel.create({
                      id: 'ad_date_startResetButton',
                      disabled: false,
                    }),
                  }),
                }),
                ad_date_end: AdsTableFilterModel.create({
                  id: 'ad_date_end',
                  type: 'date',
                  filter: DatePickerV2Model.create({
                    id: 'ad_date_end',
                    resetButton: ButtonModel.create({
                      id: 'ad_date_endResetButton',
                      disabled: false,
                    }),
                  }),
                }),
                ad_discount_percentage: AdsTableFilterModel.create({
                  id: 'ad_discount_percentage',
                  type: 'multiselect',
                  filter: DropdownMultiselectModel.create({
                    id: 'ad_discount_percentage',
                    type: 'single',
                    onlyDropdown: true,
                    isOpen: false,
                    resetButton: ButtonModel.create({
                      id: 'ad_discount_percentageDropdownResetButton',
                      disabled: false,
                    }),
                    options: CheckboxGroupModel.create({
                      id: 'ad_discount_percentageDropdownCheckboxGroup',
                      checkboxes: {
                        allOptions: {
                          label: 'Sve',
                          id: 'allOptions',
                        },
                        ...self.adDiscountPercentagesConfig,
                      },
                      selectedCheckboxes: {
                        allOptions: 'allOptions',
                      },
                    }),
                  }),
                }),
                ad_claimed_codes: AdsTableFilterModel.create({
                  id: 'ad_claimed_codes',
                  type: 'sort',
                  filter: SortModel.create({
                    id: 'ad_claimed_codes',
                    type: 'default',
                  }),
                }),
                ad_views: AdsTableFilterModel.create({
                  id: 'ad_views',
                  type: 'sort',
                  filter: SortModel.create({
                    id: 'ad_views',
                    type: 'default',
                  }),
                }),
                partner_name: AdsTableFilterModel.create({
                  id: 'partner_name',
                  type: 'searchAndMultiselect',
                  filter: SearchAndMultiselectFilterModel.create({
                    id: 'partner_name',
                    filterMultiselect: DropdownMultiselectModel.create({
                      id: 'partner_name',
                      type: 'single',
                      onlyDropdown: true,
                      isOpen: false,
                      resetButton: ButtonModel.create({
                        id: 'partner_nameDropdownResetButton',
                        disabled: false,
                      }),
                      options: CheckboxGroupModel.create({
                        id: 'partner_nameDropdownCheckboxGroup',
                        checkboxes: {
                          allOptions: {
                            label: 'Sve',
                            id: 'allOptions',
                          },
                          ...self.allPartnersConfig,
                        },
                        selectedCheckboxes: {
                          allOptions: 'allOptions',
                        },
                      }),
                    }),
                    filterSearch: TextInputModel.create({
                      id: 'partner_name',
                      placeholder: 'Naziv partnera',
                      isRequired: false,
                      icon: search,
                      resetButton: ButtonModel.create({
                        id: 'partner_nameResetButton',
                        disabled: false,
                      }),
                    }),
                  }),
                }),
                partner_legal_name: AdsTableFilterModel.create({
                  id: 'partner_legal_name',
                  type: 'searchAndMultiselect',
                  filter: SearchAndMultiselectFilterModel.create({
                    id: 'partner_legal_name',
                    filterMultiselect: DropdownMultiselectModel.create({
                      id: 'partner_legal_name',
                      type: 'single',
                      onlyDropdown: true,
                      isOpen: false,
                      resetButton: ButtonModel.create({
                        id: 'partner_legal_nameDropdownResetButton',
                        disabled: false,
                      }),
                      options: CheckboxGroupModel.create({
                        id: 'partner_legal_nameDropdownCheckboxGroup',
                        checkboxes: {
                          allOptions: {
                            label: 'Sve',
                            id: 'allOptions',
                          },
                          ...self.allPartnersLegalNameConfig,
                        },
                        selectedCheckboxes: {
                          allOptions: 'allOptions',
                        },
                      }),
                    }),
                    filterSearch: TextInputModel.create({
                      id: 'partner_legal_name',
                      placeholder: 'Pravni naziv partnera',
                      isRequired: false,
                      icon: search,
                      resetButton: ButtonModel.create({
                        id: 'partner_legal_nameResetButton',
                        disabled: false,
                      }),
                    }),
                  }),
                }),
                ad_date_code_start: AdsTableFilterModel.create({
                  id: 'ad_date_code_start',
                  type: 'date',
                  filter: DatePickerV2Model.create({
                    id: 'ad_date_code_start',
                    resetButton: ButtonModel.create({
                      id: 'ad_date_code_startResetButton',
                      disabled: false,
                    }),
                  }),
                }),
                ad_date_code_end: AdsTableFilterModel.create({
                  id: 'ad_date_code_end',
                  type: 'date',
                  filter: DatePickerV2Model.create({
                    id: 'ad_date_code_end',
                    resetButton: ButtonModel.create({
                      id: 'ad_date_code_endResetButton',
                      disabled: false,
                    }),
                  }),
                }),
                ad_date_range: AdsTableFilterModel.create({
                  id: 'ad_date_range',
                  type: 'dateRange',
                  filter: DatePickerV2Model.create({
                    id: 'ad_date_range',
                    resetButton: ButtonModel.create({
                      id: 'ad_date_rangeResetButton',
                      disabled: false,
                    }),
                  }),
                }),
                ad_store_location_type: AdsTableFilterModel.create({
                  id: 'ad_store_location_type',
                  type: 'multiselect',
                  filter: DropdownMultiselectModel.create({
                    id: 'ad_store_location_type',
                    type: 'single',
                    onlyDropdown: true,
                    isOpen: false,
                    resetButton: ButtonModel.create({
                      id: 'ad_store_location_typeDropdownResetButton',
                      disabled: false,
                    }),
                    options: CheckboxGroupModel.create({
                      id: 'ad_store_location_typeDropdownCheckboxGroup',
                      checkboxes: {
                        allOptions: {
                          label: 'Sve',
                          id: 'allOptions',
                        },
                        online: {
                          id: 'online',
                          label: 'Samo online prodavnice',
                        },
                        offline: {
                          id: 'offline',
                          label: 'Sve fizičke prodavnice bez online',
                        },
                        selected: {
                          id: 'selected',
                          label: 'Izbor pojedinačnih fizičkih prodavnica',
                        },
                        'online-sel': {
                          id: 'online-sel',
                          label:
                            'Online prodavnice i izbor pojedinačnih fizičkih',
                        },
                        all: {
                          id: 'all',
                          label: 'Sve fizičke i online prodavnice',
                        },
                      },
                      selectedCheckboxes: {
                        allOptions: 'allOptions',
                      },
                    }),
                  }),
                }),
                ad_label_text: AdsTableFilterModel.create({
                  id: 'ad_label_text',
                  type: 'text',
                  filter: TextInputModel.create({
                    id: 'ad_label_text',
                    placeholder: 'Labela',
                    isRequired: false,
                    icon: search,
                    resetButton: ButtonModel.create({
                      id: 'ad_label_textResetButton',
                      disabled: false,
                    }),
                  }),
                }),
                partner_admin_name: AdsTableFilterModel.create({
                  id: 'partner_admin_name',
                  type: 'text',
                  filter: TextInputModel.create({
                    id: 'partner_admin_name',
                    placeholder: 'Partner ime i prezime',
                    isRequired: false,
                    icon: search,
                    resetButton: ButtonModel.create({
                      id: 'partner_admin_nameResetButton',
                      disabled: false,
                    }),
                  }),
                }),
                ad_user_locations: AdsTableFilterModel.create({
                  id: 'ad_user_locations',
                  type: 'multiselect',
                  filter: DropdownMultiselectModel.create({
                    id: 'ad_user_locations',
                    type: 'single',
                    onlyDropdown: true,
                    isOpen: false,
                    resetButton: ButtonModel.create({
                      id: 'ad_user_locationsDropdownResetButton',
                      disabled: false,
                    }),
                    options: CheckboxGroupModel.create({
                      id: 'ad_user_locationsDropdownCheckboxGroup',
                      checkboxes: {
                        allOptions: {
                          label: 'Sve',
                          id: 'allOptions',
                        },
                        ...self.allUserLocationsConfig,
                      },
                      selectedCheckboxes: {
                        allOptions: 'allOptions',
                      },
                    }),
                  }),
                }),
                creator: AdsTableFilterModel.create({
                  id: 'creator',
                  type: 'multiselect',
                  filter: DropdownMultiselectModel.create({
                    id: 'creator',
                    type: 'single',
                    onlyDropdown: true,
                    isOpen: false,
                    resetButton: ButtonModel.create({
                      id: 'creatorDropdownResetButton',
                      disabled: false,
                    }),
                    options: CheckboxGroupModel.create({
                      id: 'creatorDropdownCheckboxGroup',
                      checkboxes: {
                        allOptions: {
                          label: 'Sve',
                          id: 'allOptions',
                        },
                        ...self.creatorsConfig,
                      },
                      selectedCheckboxes: {
                        allOptions: 'allOptions',
                      },
                    }),
                  }),
                }),
                publisher: AdsTableFilterModel.create({
                  id: 'publisher',
                  type: 'multiselect',
                  filter: DropdownMultiselectModel.create({
                    id: 'publisher',
                    type: 'single',
                    onlyDropdown: true,
                    isOpen: false,
                    resetButton: ButtonModel.create({
                      id: 'publisherDropdownResetButton',
                      disabled: false,
                    }),
                    options: CheckboxGroupModel.create({
                      id: 'publisherDropdownCheckboxGroup',
                      checkboxes: {
                        allOptions: {
                          label: 'Sve',
                          id: 'allOptions',
                        },
                        ...self.publishersConfig,
                      },
                      selectedCheckboxes: {
                        allOptions: 'allOptions',
                      },
                    }),
                  }),
                }),
                partnership_expert: AdsTableFilterModel.create({
                  id: 'partnership_expert',
                  type: 'multiselect',
                  filter: DropdownMultiselectModel.create({
                    id: 'partnership_expert',
                    type: 'single',
                    onlyDropdown: true,
                    isOpen: false,
                    resetButton: ButtonModel.create({
                      id: 'partnership_expertDropdownResetButton',
                      disabled: false,
                    }),
                    options: CheckboxGroupModel.create({
                      id: 'partnership_expertDropdownCheckboxGroup',
                      checkboxes: {
                        allOptions: {
                          label: 'Sve',
                          id: 'allOptions',
                        },
                        ...self.partnershipExpertsConfig,
                      },
                      selectedCheckboxes: {
                        allOptions: 'allOptions',
                      },
                    }),
                  }),
                }),

                ad_age_from: AdsTableFilterModel.create({
                  id: 'ad_age_from',
                  type: 'slider',
                  filter: SliderModel.create({
                    id: 'ad_age_from',
                    isAgeFrom: true,
                    resetButton: ButtonModel.create({
                      id: 'ad_age_fromResetButton',
                      disabled: false,
                    }),
                  }),
                }),
                ad_age_to: AdsTableFilterModel.create({
                  id: 'ad_age_to',
                  type: 'slider',
                  filter: SliderModel.create({
                    id: 'ad_age_to',
                    isAgeFrom: false,
                    resetButton: ButtonModel.create({
                      id: 'ad_age_toResetButton',
                      disabled: false,
                    }),
                  }),
                }),
              },
            })
          )
        );
      },
      getPageSizeDropdown() {
        return (
          self.components.get('PageSizeDropdown') ||
          self.addComponent(
            DropdownModel.create({
              id: 'PageSizeDropdown',
              //label: 'Broj oglasa po strani',
              placeholder: '10',
              initialValue: '20',
              onChangeCallback: (value: any) => {
                return this.loadExtendedAds(true);
              },
              options: {
                '10': {
                  id: '10',
                  value: '10',
                },
                '20': {
                  id: '20',
                  value: '20',
                },
                '50': {
                  id: '50',
                  value: '50',
                },
                '100': {
                  id: '100',
                  value: '100',
                },
              },
            })
          )
        );
      },
      getAdList() {
        return (
          self.components.get('AdList') ||
          self.addComponent(
            AdTableModel.create({
              id: 'AdList',
              ads: {},
              columns: {},
              selectableColumns: true,
              pagination: PaginationModel.create({
                id: 'AdListPagination',
                currentPage: 1,
                totalPages: 1,
                pageChangedCallback: (pageNum: number) => {
                  this.loadExtendedAds(false);
                },
              }),
            })
          )
        );
      },
      resetPagination() {
        this?.getAdList()?.pagination?.setCurrentPage(1);
      },
      onPageEnter() {
        self.root.setActiveTab(MODERATOR_PROFILE);
      },
    };
  })
  .views((self) => {
    return {
      get pageNumber(): number {
        return self.getAdList().pagination?.currentPage;
      },
      get paginationQuery(): string {
        return `limit=${parseInt(self.getPageSizeDropdown().value)}&offset=${
          (this.pageNumber - 1) * parseInt(self.getPageSizeDropdown().value)
        }`;
      },
      get finalQuery(): string {
        return `${this.paginationQuery ? this.paginationQuery : ''}${
          self.tableFiltersQuery ? '&' + self.tableFiltersQuery : ''
        }${self.tableSearchValue ? '&search=' + self.tableSearchValue : ''}`;
      },
      get downloadCSVQuery(): string {
        return `${self.tableFiltersQuery ? '&' + self.tableFiltersQuery : ''}${
          self.tableSearchValue ? '&search=' + self.tableSearchValue : ''
        }`;
      },
    };
  })
  .actions((self) => {
    return {
      getCategoryListFilter() {
        return self.getColumnFilters()?.filters.get('category_name').filter;
      },
      getSubcategoryListFilter() {
        return self.getColumnFilters()?.filters.get('subcategory_name').filter;
      },
    };
  })
  .named('AllAdsModel');

export type AllAdsModelType = Instance<typeof AllAdsModel>;
export type ColumnModelType = Instance<typeof ColumnModel>;
