import { getRoot, types } from 'mobx-state-tree';
import { HttpVerbs } from '../../constants/verbs';
import { RootType } from '../../internal';

export const InvoiceApi = types
  .model({})
  .views((self) => {
    return {
      get root(): RootType {
        return getRoot(self);
      },
    };
  })
  .actions((self) => {
    return {
      async getCurrentInvoice(partnerId: string) {
        const response = await self.root.axios.requestData({
          type: HttpVerbs.GET,
          path: `/invoices/current?partner_id=${partnerId}`,
        });
        return response;
      },
      async getInvoicesYears(partnerId: string) {
        const response = await self.root.axios.requestData({
          type: HttpVerbs.GET,
          path: `/invoices/years?partner_id=${partnerId}`,
        });
        return response;
      },
      async getInvoicesByYear(partnerId: string, year: number) {
        const response = await self.root.axios.requestData({
          type: HttpVerbs.GET,
          path: `/invoices/${year}?partner_id=${partnerId}`,
        });
        return response;
      },
    };
  });
