import React from 'react';
import giftIcon from '../../../../assets/icons/gift_icon.png';
import checkmarkIcon from '../../../../assets/icons/checkmark_icon.png';
import styles from './TariffPackages.module.scss';
export const PromoPeriod = (props: {
  isPromoPackage: string;
}): React.ReactElement => {
  return (
    <div className={styles.PromoPeriod}>
      <div className={styles.PromoPeriodHeader}>
        <img src={giftIcon} alt="gift" />
        <div className={styles.PromoPeriodTitle}>
          <h1>Poklon za Vas!</h1>
          {!props.isPromoPackage ? (
            <p>Besplatno oglašavanje na Yettel Shopping tokom 2 meseca.</p>
          ) : (
            <p>
              Besplatno oglašavanje na Yettel Shopping-u tokom Promo perioda.
            </p>
          )}
        </div>
      </div>
      {!props.isPromoPackage ? (
        <p className={styles.Content}>
          Kao novi partner nakon potpisivanja ugovora ostvarujete pravo na promo
          period u trajanju od 2 meseca. Tokom promo perioda omogućeno Vam je
          besplatno oglašavanje: neograničen broj standardnih oglasa i 5 top
          oglasa mesečno.
        </p>
      ) : (
        <p className={styles.Content}>
          Kao naš partner u toku Promo perioda, ostvarujete pravo na besplatno
          oglašavanje. Imate pravo na neograničeno postavljanje oglasa uz
          korišćenje segmentacije korisnika i istaknutog formata oglasa.
        </p>
      )}

      <div className={styles.CheckMarks}>
        <>
          <img src={checkmarkIcon} alt="check" />
          <p>
            {!props.isPromoPackage
              ? 'Neograničen broj standardnih oglasa'
              : 'Neograničen broj oglasa'}
          </p>
        </>

        <img src={checkmarkIcon} alt="check" />
        <p>Izbor ciljne grupe</p>
      </div>
    </div>
  );
};
