import { observer } from 'mobx-react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckboxGroup } from '../../../components/UI/checkbox/CheckboxGroup';
import { Dropdown } from '../../../components/UI/dropdown/Dropdown';
import { InputField } from '../../../components/UI/input/InputField';
import { Modal } from '../../../components/UI/modal/Modal';
import { TextArea } from '../../../components/UI/textarea/TextArea';
import { ChangePacketModalModelType } from '../../../internal';
import styles from './ChangePacketModal.module.scss';

interface IChangePacketModalProps {
  model: ChangePacketModalModelType;
}

export const ChangePacketModal: FC<IChangePacketModalProps> = observer(
  ({ model }) => {
    const { t } = useTranslation();

    return (
      <Modal
        model={model.modal}
        styles={{
          content: {
            //maxHeight: 'fit-content',
            width: '800px',
            borderRadius: '20px',
            maxHeight: '90%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
          },
        }}
      >
        <div className={styles.modalWrapper}>
          <p className={styles.title}> Promena tarifnog paketa </p>
          <div className={styles.table}>
            <div className={styles.tableRow}>
              <span> Partner/Kompanija: </span>
              <span> {model.partnerAdmin?.name} </span>
            </div>
            <div className={styles.tableRow}>
              <span> Aktivan paket: </span>
              <span className={styles.activePacketName}>
                {t(`calculator:${model.partnerAdmin?.contract?.calculator}`)}
              </span>
            </div>
            <div className={styles.tableRow}>
              <span> Uračunati popusti: </span>
              <div className={styles.tableRowDiscounts}>
                {Boolean(model.partnerAdmin?.contract?.discount_btl) && (
                  <span>
                    BTL popust {model.partnerAdmin?.contract?.discount_btl}%
                  </span>
                )}
                {Boolean(model.partnerAdmin?.contract?.discount_so) && (
                  <span>
                    UO popust {model.partnerAdmin?.contract?.discount_so}%
                  </span>
                )}
                {Boolean(model.partnerAdmin?.contract?.discount_soho) && (
                  <span>
                    SOHO popust {model.partnerAdmin?.contract?.discount_soho}%
                  </span>
                )}
                {Boolean(model.partnerAdmin?.contract?.discount_telco) && (
                  <span>
                    TELCO popust {model.partnerAdmin?.contract?.discount_telco}%
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className={styles.packagesDropdownWrapper}>
            <Dropdown model={model.packagesDropdown} />
          </div>

          <p className={styles.discountsTitle}> Aktivni popusti </p>
          <div className={styles.discountsWrapper}>
            {model.packagesDropdown.selectedOptionId === 'pay-as-you-go' && (
              <CheckboxGroup
                model={model.changePacketModalDiscountsCheckboxGroupPayAsYouGo}
              />
            )}
            {model.packagesDropdown.selectedOptionId === 'subscription' && (
              <>
                <CheckboxGroup
                  model={
                    model.changePacketModalDiscountsCheckboxGroupSubscription
                  }
                />
                {model.changePacketModalDiscountsCheckboxGroupSubscription.selectedCheckboxesIds.includes(
                  'btl'
                ) && (
                  <div
                    style={{
                      width: '25%',
                      display: 'flex',
                      alignItems: 'flex-end',
                    }}
                  >
                    <InputField model={model.changePacketModalBtlInput} />
                  </div>
                )}
              </>
            )}
          </div>

          {model.showRejectionReasonTextArea ? (
            <>
              <div className={styles.rejectionReasonTextAreaWrapper}>
                <TextArea model={model.rejectionReasonTextArea} />
              </div>
              <div className={styles.buttonsWrapperRejection}>
                <button
                  onClick={() => model.reject()}
                  className={styles.saveButton}
                >
                  Sačuvaj
                </button>
                <button
                  onClick={() => model.setShowRejectionReasonTextArea(false)}
                  className={styles.giveUpButton}
                >
                  Odustani
                </button>
              </div>
            </>
          ) : (
            <div className={styles.buttonsWrapper}>
              <button
                onClick={() => model.setShowRejectionReasonTextArea(true)}
                className={styles.rejectButton}
              >
                Odbij zahtev
              </button>
              <button
                onClick={() => model.submit()}
                className={styles.approveButton}
              >
                Pošalji na odobrenje
              </button>
            </div>
          )}
        </div>
      </Modal>
    );
  }
);
