import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import styles from './MobileHeaderProfileModal.module.scss';
import { useMst } from '../../../rootInstance';
import Link from '../link';
import { PageRoutes } from '../../../utils/PageRoutes';
import { PARTNER_PROFILES } from '../../../constants/header';
import React from 'react';

type Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

export const MobileHeaderProfileModal = observer(
  ({ isOpen, setIsOpen }: Props) => {
    const {
      router,
      header: {
        setActiveTab,
        currentUser,
        partnerName,
        isModerator,
        isAdministrator,
        isSalesman,
      },
      api: {
        admin: { logout },
      },
    } = useMst();
    const { t } = useTranslation(['basic']);

    const handleLogout = () => {
      logout();
      setIsOpen(false);
    };
    return (
      <div
        className={styles.MobileHeaderProfileModal}
        style={{ display: isOpen ? 'block' : 'none' }}
      >
        <div className={styles.MobileHeaderProfileBack}>
          <div className={styles.goBack} onClick={() => setIsOpen(false)}>
            &#60; <span className={styles.goBackText}>{t('back')}</span>
          </div>
        </div>

        <div className={styles.MobileHeaderProfileContent}>
          <div className={styles.MobileHeaderUserInfo}>
            <span className={styles.MobileHeaderUserInfoNameEmail}>
              {currentUser}
            </span>
          </div>
          <div className={styles.MobileHeaderMenuCompany}>
            <span className={styles.MobileHeaderMenuCompanyName}>
              {partnerName}
            </span>
            {(isModerator || isAdministrator || isSalesman) && (
              <Link view={router.views.get(PageRoutes.PartnerProfiles.id)}>
                <span
                  onClick={() => {
                    setActiveTab(PARTNER_PROFILES);
                    router.navigate(PageRoutes.PartnerProfiles.id);
                    setIsOpen(false);
                  }}
                  className={styles.MobileHeaderChangeCompany}
                >
                  {partnerName ? t('change') : t('choose_company')}
                </span>
              </Link>
            )}
          </div>

          <div className={styles.MobileHeaderMenuLogout} onClick={handleLogout}>
            <span className={styles.MobileHeaderMenuLogoutText}>
              {t('logout')}
            </span>
          </div>
        </div>
      </div>
    );
  }
);
