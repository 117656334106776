import React from 'react';
import SvgIcon, { ISvgIcon } from './SvgIcon';

// Bold
import { ReactComponent as Chat } from '../assets/icons/chat.svg';
import { ReactComponent as Check } from '../assets/icons/check.svg';
import { ReactComponent as CheckWhite } from '../assets/icons/check-white.svg';
import { ReactComponent as Checkmark } from '../assets/icons/checkmark.svg';
import { ReactComponent as CloseCircle } from '../assets/icons/close-circle.svg';
import { ReactComponent as CloseSquare } from '../assets/icons/close_square.svg';
import { ReactComponent as Colapse } from '../assets/icons/colapse.svg';
import { ReactComponent as Danger } from '../assets/icons/danger.svg';
import { ReactComponent as Document } from '../assets/icons/document.svg';
import { ReactComponent as Download } from '../assets/icons/download.svg';
import { ReactComponent as Dropdown } from '../assets/icons/dropdown.svg';
import { ReactComponent as Empty } from '../assets/icons/empty.svg';
import { ReactComponent as Exit } from '../assets/icons/exit.svg';
import { ReactComponent as Expand } from '../assets/icons/expand.svg';
import { ReactComponent as Hide } from '../assets/icons/hide.svg';
import { ReactComponent as Info } from '../assets/icons/info.svg';
import { ReactComponent as Image } from '../assets/icons/image.svg';
import { ReactComponent as Notification } from '../assets/icons/notification.svg';
import { ReactComponent as Show } from '../assets/icons/show.svg';
import { ReactComponent as Spam } from '../assets/icons/spam.svg';
import { ReactComponent as TimeCircle } from '../assets/icons/time_circle.svg';
import { ReactComponent as Upload } from '../assets/icons/upload.svg';
import { ReactComponent as User } from '../assets/icons/user.svg';
import { ReactComponent as Warning } from '../assets/icons/warning.svg';
import { ReactComponent as Hamburger } from '../assets/icons/hamburger.svg';
// Light
import { ReactComponent as ArrowDownLight } from '../assets/icons/arrow_down_light.svg';
import { ReactComponent as ArrowLeftLight } from '../assets/icons/arrow_left_light.svg';
import { ReactComponent as ArrowLeft2Light } from '../assets/icons/arrow_left_2_light.svg';
import { ReactComponent as ArrowRightLight } from '../assets/icons/arrow_right_light.svg';
import { ReactComponent as CheckmarkLight } from '../assets/icons/checkmark_light.svg';
import { ReactComponent as CloseLight } from '../assets/icons/close_light.svg';
import { ReactComponent as DeleteLight } from '../assets/icons/delete_light.svg';
import { ReactComponent as EditLight } from '../assets/icons/edit_light.svg';
import { ReactComponent as NotificationLight } from '../assets/icons/notification_light.svg';
import { ReactComponent as ZoomLight } from '../assets/icons/zoom_light.svg';

export const ArrowDownLightIcon = (props: ISvgIcon): JSX.Element => (
  <SvgIcon {...props} icon={<ArrowDownLight />} />
);

export const ArrowLeftLightIcon = (props: ISvgIcon): JSX.Element => (
  <SvgIcon {...props} icon={<ArrowLeftLight />} />
);

export const ArrowLeft2LightIcon = (props: ISvgIcon): JSX.Element => (
  <SvgIcon {...props} icon={<ArrowLeft2Light />} />
);

export const ArrowRightLightIcon = (props: ISvgIcon): JSX.Element => (
  <SvgIcon {...props} icon={<ArrowRightLight />} />
);

export const CheckmarkLightIcon = (props: ISvgIcon): JSX.Element => (
  <SvgIcon {...props} icon={<CheckmarkLight />} />
);

export const NotificationLightIcon = (props: ISvgIcon): JSX.Element => (
  <SvgIcon {...props} icon={<NotificationLight />} />
);

export const ZoomLightIcon = (props: ISvgIcon): JSX.Element => (
  <SvgIcon {...props} icon={<ZoomLight />} />
);

export const ChatIcon = (props: ISvgIcon): JSX.Element => (
  <SvgIcon {...props} icon={<Chat />} />
);

export const CheckIcon = (props: ISvgIcon): JSX.Element => (
  <SvgIcon {...props} icon={<Check />} />
);

export const CheckWhiteIcon = (props: ISvgIcon): JSX.Element => (
  <SvgIcon {...props} icon={<CheckWhite />} />
);

export const CheckmarkIcon = (props: ISvgIcon): JSX.Element => (
  <SvgIcon {...props} icon={<Checkmark />} />
);

export const CloseLightIcon = (props: ISvgIcon): JSX.Element => (
  <SvgIcon {...props} icon={<CloseLight />} />
);

export const CloseCircleIcon = (props: ISvgIcon): JSX.Element => (
  <SvgIcon {...props} icon={<CloseCircle />} />
);

export const CloseSquareIcon = (props: ISvgIcon): JSX.Element => (
  <SvgIcon {...props} icon={<CloseSquare />} />
);

export const ColapseIcon = (props: ISvgIcon): JSX.Element => (
  <SvgIcon {...props} icon={<Colapse />} />
);

export const DangerIcon = (props: ISvgIcon): JSX.Element => (
  <SvgIcon {...props} icon={<Danger />} />
);

export const DeleteLightIcon = (props: ISvgIcon): JSX.Element => (
  <SvgIcon {...props} icon={<DeleteLight />} />
);

export const DocumentIcon = (props: ISvgIcon): JSX.Element => (
  <SvgIcon {...props} icon={<Document />} />
);

export const DownloadIcon = (props: ISvgIcon): JSX.Element => (
  <SvgIcon {...props} icon={<Download />} />
);

export const DropdownIcon = (props: ISvgIcon): JSX.Element => (
  <SvgIcon {...props} icon={<Dropdown />} />
);

export const EditLightIcon = (props: ISvgIcon): JSX.Element => (
  <SvgIcon {...props} icon={<EditLight />} />
);

export const EmptyIcon = (props: ISvgIcon): JSX.Element => (
  <SvgIcon {...props} icon={<Empty />} />
);

export const ExitIcon = (props: ISvgIcon): JSX.Element => (
  <SvgIcon {...props} icon={<Exit />} />
);

export const ExpandIcon = (props: ISvgIcon): JSX.Element => (
  <SvgIcon {...props} icon={<Expand />} />
);

export const HideIcon = (props: ISvgIcon): JSX.Element => (
  <SvgIcon {...props} icon={<Hide />} />
);

export const ImageIcon = (props: ISvgIcon): JSX.Element => (
  <SvgIcon {...props} icon={<Image />} />
);

export const InfoIcon = (props: ISvgIcon): JSX.Element => (
  <SvgIcon {...props} icon={<Info />} />
);

export const NotificationIcon = (props: ISvgIcon): JSX.Element => (
  <SvgIcon {...props} icon={<Notification />} />
);

export const ShowIcon = (props: ISvgIcon): JSX.Element => (
  <SvgIcon {...props} icon={<Show />} />
);

export const SpamIcon = (props: ISvgIcon): JSX.Element => (
  <SvgIcon {...props} icon={<Spam />} />
);

export const TimeCircleIcon = (props: ISvgIcon): JSX.Element => (
  <SvgIcon {...props} icon={<TimeCircle />} />
);

export const UploadIcon = (props: ISvgIcon): JSX.Element => (
  <SvgIcon {...props} icon={<Upload />} />
);

export const UserIcon = (props: ISvgIcon): JSX.Element => (
  <SvgIcon {...props} icon={<User />} />
);

export const WarningIcon = (props: ISvgIcon): JSX.Element => (
  <SvgIcon {...props} icon={<Warning />} />
);

export const HamburgerIcon = (props: ISvgIcon): JSX.Element => (
  <SvgIcon {...props} icon={<Hamburger />} />
);
