import { observer } from 'mobx-react';
import { DurationModelType } from '../../../../internal';
import { Modal } from '../../../UI/modal/Modal';
import styles from './VoucherDurationModal.module.scss';

import { useTranslation } from 'react-i18next';
import { templateReplace } from '../../../../utils/template';
import { StandardDuration } from '../../duration/StandardDuration';

export const VoucherDurationModal = observer(
  (props: { model: DurationModelType }): JSX.Element => {
    const { model } = props;
    const { t } = useTranslation();
    return (
      <Modal
        model={model.modal}
        styles={{
          content: {
            maxHeight: 'fit-content',
            top: '5%',
          },
        }}
      >
        <StandardDuration
          model={model}
          startRangeText={t('voucher_duration:validity_start')}
          endRangeText={t('voucher_duration:validity_end')}
        >
          <div className={styles.TextHeader}>
            <div className={styles.PickDatesIntro}>
              {t('voucher_duration:pick_dates_intro')}
            </div>
          </div>
        </StandardDuration>
      </Modal>
    );
  }
);
