import { getRoot, Instance, types } from 'mobx-state-tree';
import {
  Ad,
  AdditionalLocationModel,
  AdTypeModel,
  ModalModel,
  PageModel,
  Partner,
  RootType,
  SectionModel,
  ApproveAdModel,
  ButtonModel,
  TextAreaInputModel,
  ApproveRejectAdModel,
  PageRoutes,
  CreateOfferPageType,
  FileInputType,
  Contract,
  ContractExtension,
  ContractAddon,
  Admin,
  AdAnalytics,
  UsedCodesModel,
  TextInputModel,
  TabsModel,
  TabModel,
  Category,
  SubCategory,
  AdUpdate,
  DropdownModel,
  DropdownOptionModel,
  DropdownModelType,
  AdAttributes,
} from '../../../../internal';
import { IMAGES_BASE_URL } from '../../../../utils/api/images';
import {
  CODE_SPENT,
  EXPIRED,
  PUBLISHED,
  PUBLISHING,
  REJECT_AD,
  STOPPED,
  SUBMITTED,
  SUBMITTING,
  PUBLISHED_SOON,
  PUBLISHED_UPDATE,
  PUBLISHED_SOON_UPDATE,
  PREP,
  CODE_TYPE_PERSONALIZED,
} from '../../../../constants/ad';
import { filesToFileList, linkToFile } from '../../../../utils/files';
import { PARTNER_PROFILES } from '../../../../constants/header';
import { parseDate } from '../../../../utils/date';
import { endOfDay, format } from 'date-fns';
import { parseNumberSRLocale, toAmount } from '../../../../utils/methods';
import i18n from '../../../../i18n';
import {
  shouldShowBothPricesFunc,
  shouldShowOnlyDiscountFunc,
} from '../../../../components/UI/adtype/conditions';
import {
  AdTypes,
  PurchasedExtensionTypes,
  StoreLocationTypes,
} from '../../../../constants/adSettings';
import { SEGMENTED, SPECIAL, UNSEGMENTED } from '../../../../constants';
import blueIcon from '../../../../assets/images/dropdown/blue.svg';
import pinkIcon from '../../../../assets/images/dropdown/pink.svg';
import yellowIcon from '../../../../assets/images/dropdown/yellow.svg';
import { EditorState } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';

export const AdDetailsModel = types
  .compose(
    PageModel,
    types.model({
      isImageFromResponse: true,
      showCancelReasonInput: false,
      adExtension: types.maybe(types.frozen<ContractExtension>()),
      adCreator: types.maybe(types.frozen<Admin>()),
      approvedBy: types.maybe(types.frozen<Admin>()),
      update: types.maybe(types.frozen<AdUpdate>()),
      rejectedByDetails: types.maybe(types.frozen<Admin>()),
      adAddons: types.optional(types.array(types.frozen<ContractAddon>()), []),
      recommended: types.maybe(types.number),
      labelText: types.maybeNull(types.string),
      labelColor: types.maybeNull(types.string),
      labelColorHex: types.maybeNull(types.string),
    })
  )
  .views((self) => {
    return {
      get root(): RootType {
        return getRoot(self);
      },
      get adDetails(): Ad {
        return {
          ...this.root.data.adDetails,
          ...(self.update ? self.update : {}),
          id: this.root.data.adDetails?.id,
          // The backend returns null for dates, so we have to do
          // this to keep the right values
          date_start: this.root.data.adDetails?.date_start,
          date_end: this.root.data.adDetails?.date_end,
          date_code_start: this.root.data.adDetails?.date_code_start,
          date_code_end: this.root.data.adDetails?.date_code_end,
        } as Ad;
      },
      get adAttributes() {
        return {
          label_text: this.getLabelText().value,
          label_color:
            this.getLabelText().value !== ''
              ? this.getLabelColorDropdown().value
              : '',
          recommended: Number(this.getRecommendedDropdown().value),
          should_reindex: Boolean(
            this.root.data.adDetails.status === PUBLISHED_UPDATE ||
              this.root.data.adDetails.status === PUBLISHED_SOON_UPDATE ||
              this.root.data.adDetails.status === PUBLISHED_SOON ||
              this.root.data.adDetails.status === PUBLISHED
          ),
        } as any as AdAttributes;
      },
      get categoryNames(): string {
        let adCategories = '';
        this.adDetails.categories.map((adDetailsCategory: any) => {
          const adCategory = this.root.data.categories.find(
            (category: Category) => {
              return category.id === adDetailsCategory.category_id;
            }
          );

          if (adCategory) {
            adCategories = `${
              adCategories.length > 0 ? adCategories + '/' : adCategories
            }  ${adCategory.name} `;
          }
        });
        return adCategories || '-';
      },
      get subcategoryNames(): string {
        let adSubCategories = '';
        this.adDetails.categories.map((adDetailsCategory: any) => {
          this.root.data.categories.map((category: Category) => {
            const adSubCategory = category.subcategories.find(
              (subcategory: SubCategory) => {
                return subcategory.id === adDetailsCategory.sub_category_id;
              }
            );
            if (adSubCategory) {
              adSubCategories = `${
                adSubCategories.length > 0
                  ? adSubCategories + '/'
                  : adSubCategories
              }  ${adSubCategory.name} `;
            }
          });

          if (adDetailsCategory.sub_category_id === null) {
            adSubCategories = `${
              adSubCategories.length > 0
                ? adSubCategories + '/'
                : adSubCategories
            }  Sve`;
          }
        });
        return adSubCategories || '-';
      },
      get purchasedExtensionData(): string | null {
        if (
          !this.adDetails.purchased_extension_id ||
          this.adDetails.type === AdTypes.STANDARD
        ) {
          return i18n.t('ad_settings:standard');
        } else if (
          this.adDetails.purchased_extension_id &&
          this.adDetails.type === AdTypes.SPECIAL &&
          this.root.data.purchasedExtension
        ) {
          const purchasedExtensionName =
            this.root.data.purchasedExtension.name ===
            PurchasedExtensionTypes.BASIC
              ? i18n.t('ad_settings:basic_extension')
              : this.root.data.purchasedExtension.name ===
                PurchasedExtensionTypes.STANDARD
              ? i18n.t('ad_settings:standard_extension')
              : this.root.data.purchasedExtension.name ===
                PurchasedExtensionTypes.PREMIUM
              ? i18n.t('ad_settings:premium_extension')
              : null;

          const targetsRemaining = this.root.data.purchasedExtension
            .targets_remaining
            ? `preostalo ${this.root.data.purchasedExtension.targets_remaining} korisnika`
            : null;

          const validTill = this.root.data.purchasedExtension.valid_till
            ? `važi do ${format(
                new Date(this.root.data.purchasedExtension.valid_till),
                'dd.MM.yyyy'
              )}`
            : null;

          const locationNumber = this.root.data.purchasedExtension
            .locations_initial
            ? `${this.root.data.purchasedExtension.locations_initial} lokacija`
            : null;

          return `Paket ${purchasedExtensionName} - ${targetsRemaining} - ${locationNumber} - ${validTill}`;
        }
        return '-';
      },
      get partnerDetails(): Partner {
        return this.root.data.partnerDetails;
      },
      get adAnalytics(): AdAnalytics {
        return this.root.data.adAnalytics;
      },
      get isPartnerView() {
        return location.href.includes('/ad-details-partner');
      },
      getTabs() {
        return (
          // self.components.get('Tabs') ||
          self.addComponent(
            TabsModel.create({
              id: 'Tabs',
              selectedTab: 'ad_overview',
              tabs: {
                active: TabModel.create({
                  id: 'ad_overview',
                  name: i18n.t('tabs:ad_overview'),
                }),
                expired: TabModel.create({
                  id: 'ad_settings',
                  name: i18n.t('tabs:ad_settings'),
                }),
              },
            })
          )
        );
      },
      get isWithoutVoucher() {
        if (this.adDetails.status === PREP) {
          return false;
        }
        return (
          !this.adDetails.with_code || this.adDetails.with_code === 'no_code'
        );
      },
      get isOnlineOfferOnly() {
        return this.adDetails.store_location_type === StoreLocationTypes.ONLINE;
      },
      get shouldDisplayShowStoresButton() {
        return (
          !this.isOnlineOfferOnly &&
          this?.adDetails?.user_locations?.split?.(',')?.length > 1
        );
      },
      get adSubmitter(): string | undefined {
        return self?.adCreator?.email;
      },
      get adApprovedBy(): string | undefined {
        return self?.approvedBy?.email;
      },
      // used for smartphone preview
      get offerPreview() {
        return {
          discountDescription: this.adDetails.discount_description,
          imagesArray: [
            this.adDetails.image0,
            this.adDetails.image1,
            this.adDetails.image2,
            this.adDetails.image3,
          ]
            .filter((img) => !!img)
            .map((img) => `${IMAGES_BASE_URL}${img}`),
          partner: {
            name: this.partnerDetails.name,
            phone: this.partnerDetails.customer_contact_phone,
            web: this.partnerDetails.web,
            email: this.partnerDetails.customer_contact_email,
          },
          name: this.adDetails.name,
          expiration: 'još',
          description: this.adDetails.description,
          conditions: this.adDetails.conditions,
          isWithoutVoucher: this.isWithoutVoucher,
          partnerName: this.partnerDetails.name,
          regularPrice: this.adDetails.original_price,
          loweredPrice: this.adDetails.discount_value,
          shouldDisplayShowStoresButton: this.shouldDisplayShowStoresButton,
          expiresIn: this.expiresIn,
          offerType: this.adDetails.visual_type,
          labelText: this.adDetails.label_text,
          labelColor: this.adDetails.label_color,
        };
      },
      get expiresIn() {
        return endOfDay(parseDate(this?.adDetails?.date_end));
      },
      get contract(): Contract {
        return this.root.data.contractDetails;
      },
      get billSpecificationPreview(): { key: string; value: string }[] {
        const rows: { key: string; value: string }[] = [];

        if (self.adExtension) {
          rows.push({
            key: self.adExtension.name,
            value: `${self.adExtension.price}`,
          });
        }

        if (self.adAddons && self.adAddons.length) {
          self.adAddons.forEach((addon) => {
            let heading: any = '';
            let amount: any = '';
            this.adDetails.contract_addons.map((details: any) => {
              if (addon.id === details.contract_addon_id) {
                if (addon.type === 'location') {
                  heading = 'Kupljeno ' + `${details.count}` + ' lokacija';
                } else if (addon.type === 'target') {
                  heading =
                    'Dodato ' +
                    `${toAmount(parseFloat(details.count))}` +
                    ' korisnika';
                }
                amount = (
                  parseFloat(addon.price) * parseFloat(details.count)
                ).toString();
              }
            });

            rows.push({
              key: heading,
              value: amount,
            });
          });
        }

        rows.push({
          key: 'Ukupno',
          value: `${rows
            .map((elem: any) => parseFloat(elem.value))
            .reduce((a, b) => a + b, 0)}`,
        });
        return rows;
      },
      get emptyBillSpec(): boolean {
        // there is always 1.
        return !(this?.billSpecificationPreview?.length > 1);
      },
      get packageUsersAvailable(): number {
        const data =
          self?.components?.get('PackagePick')?.selectedOption
            ?.targetsRemaining;
        return data ? parseFloat(data) : 0;
      },
      getVisualType() {
        return this.adDetails.visual_type;
      },
      get adName(): string {
        return this.adDetails.name;
      },
      get adRegularPriceRSD(): number {
        if (!this.adDetails.original_price) {
          return 0;
        }
        return parseFloat(this.adDetails.original_price);
      },
      get adLoweredPriceRSD(): number {
        if (!this.adDetails.discount_value) {
          return 0;
        }
        return parseFloat(this.adDetails.discount_value);
      },
      get adStore(): string {
        return this.partnerDetails.name;
      },
      get adDiscount(): string {
        return this.adDetails.discount_description;
      },
      getAdTypeModel() {
        return (
          self.components.get('AdTypeOverviewModel') ||
          self.addComponent(
            AdTypeModel.create({
              id: 'AdTypeOverviewModel',
              name: this.adDetails.name,
              loweredPrice: parseFloat(this.adDetails.discount_value),
              regularPrice: parseFloat(this.adDetails.original_price),
              discount: this.adDetails.discount_description,
              currency: ' RSD', //TODO add currency
              store: this.partnerDetails.name,
              expiration: 'Jos 3 dana', // TODO add self.adExpiration
              distance: '1 km', // TODO add  self.adDistance
              // isWindPaySupported: self.adIsWindPaySupported,
              isTopOffer: false, //TODO add handling
              isFeaturedOffer: false, //TODO add handling
              showDescriptionList: false,
            })
          )
        );
      },
      getAdditionalLocation() {
        return (
          self?.components.get('AdditionalLocationModel') ||
          self?.addComponent(
            AdditionalLocationModel.create({
              id: 'AdditionalLocationModel',
              numberOfAdditionalLocations: 5,
              modal: ModalModel.create({
                id: 'AdditionalLocationPriceListModal',
                label: 'Price List',
                opened: false,
                showCloseButton: true,
              }),
              prices: {},
            })
          )
        );
      },
      getTextInputField() {
        return (
          self?.components.get('CancelAdReason') ||
          self?.addComponent(
            TextAreaInputModel.create({
              id: 'CancelAdReason',
              label: 'Razlog odbijanja ponude',
              isRequired: true,
              showButtons: false,
              editorState: EditorState.createWithContent(
                stateFromHTML(this.adDetails?.comment || '')
              ),
            })
          )
        );
      },
      getLabelText() {
        return (
          self?.components.get('AdditionalLabel') ||
          self?.addComponent(
            TextInputModel.create({
              id: 'AdditionalLabel',
              label: 'basic:additional_label',
              isRequired: false,
            })
          )
        );
      },
      getLabelColorDropdown() {
        return (
          self?.components.get('LabelColorDropdown') ||
          self?.addComponent(
            DropdownModel.create({
              id: 'LabelColorDropdown',
              label: 'Izaberite boju pozadine',
              initialValue: 'blue',
              options: {
                0: DropdownOptionModel.create({
                  id: 'blue',
                  value: 'basic:blue',
                  colorIcon: blueIcon,
                }),
                1: DropdownOptionModel.create({
                  id: 'pink',
                  value: 'basic:pink',
                  colorIcon: pinkIcon,
                }),
                2: DropdownOptionModel.create({
                  id: 'yellow',
                  value: 'basic:yellow',
                  colorIcon: yellowIcon,
                }),
              },
            })
          )
        );
      },
      getRecommendedDropdown() {
        return (
          self?.components.get('RecommendedDropdown') ||
          self?.addComponent(
            DropdownModel.create({
              id: 'RecommendedDropdown',
              label: '',
              initialValue: '0',
              options: {
                0: DropdownOptionModel.create({
                  id: '0',
                  value: 'basic:no',
                }),
                1: DropdownOptionModel.create({
                  id: '1',
                  value: 'basic:yes',
                }),
              },
            })
          )
        );
      },
      getCommentTextArea() {
        return (
          self?.components.get('Comment') ||
          self?.addComponent(
            TextAreaInputModel.create({
              id: 'Comment',
              label: 'Komentar',
              isRequired: false,
              showButtons: false,
              resize: false,
              editorState: EditorState.createWithContent(
                stateFromHTML(this.adDetails?.comment || '')
              ),
            })
          )
        );
      },
      get shouldShowBothPrices() {
        return shouldShowBothPricesFunc(
          Number(this.adDetails.original_price),
          Number(this.adDetails.discount_value),
          this.adDetails.discount_description
        );
      },
      get shouldShowOnlyDiscount() {
        return shouldShowOnlyDiscountFunc(
          Number(this.adDetails.original_price),
          Number(this.adDetails.discount_value),
          this.adDetails.discount_description
        );
      },
      get adIdQueryParam(): string {
        return this.root?.router?.queryParams?.id;
      },
      get updateNotAllowed(): boolean {
        return `${this.root?.router?.queryParams?.updateAllowed}` === 'false';
      },
      get updateAllowed(): boolean {
        return !this.updateNotAllowed;
      },
    };
  })
  .actions((self) => {
    return {
      navigateToProperBackPage() {
        if (self.isPartnerView) {
          self.root.router.navigate({ newView: PageRoutes.PartnerOffers.id });
        } else {
          self.root.router.navigate({ newView: PageRoutes.AllAds.id });
        }
      },
      async beforePageEnter() {
        this?.getUsedCodes()?.codeInput?.setValue?.('');
        self.getCommentTextArea().resetState();
        self.getTextInputField().resetState();
        self.showCancelReasonInput = false;

        await self.root.api.offers.get(self.adIdQueryParam);
        await self.root.api.partner.get(self.adDetails.partner_id);
        await self.root.api.contract.getContracts(self.adDetails.partner_id);
        // fecth all categories to find ad's category name
        await self.root.api.category.getAllCategories('abc');
        // fetch ad's purchased extension
        if (self.adDetails.purchased_extension_id) {
          await self.root.api.purchasedExtension.getPurchasedExtension(
            self.adDetails.purchased_extension_id
          );
        } else {
          self.root.data.setPurchasedExtension(null);
        }
        await this.manageRejectedBy();
        await this.manageAdExtension();
        await this.manageAdAddons();
        await this.manageAdAnalytics();
        await this.manageCreatedBy();
        await this.manageApprovedBy();
        await this.manageAdUpdate();
        self.getLabelText().setValue('');
        this.setLabelHex(null);
        this.resetDropdown(self.getRecommendedDropdown());
        this.resetDropdown(self.getLabelColorDropdown());
        this.setLabelColor(self?.adDetails?.label_color);
        this.setLabelText(self?.adDetails?.label_text);
        self.getRecommendedDropdown().setValue(self?.adDetails?.recommended);
        if (self?.adDetails?.label_color) {
          this.getLabelColor();
        }
        self
          .getCommentTextArea()
          .setEditorState(
            EditorState.createWithContent(
              stateFromHTML(self.adDetails?.comment || '')
            )
          );
      },
      resetDropdown(dropdownComp: DropdownModelType) {
        dropdownComp.setValue(
          dropdownComp.initialValue ?? dropdownComp?.optionsArray?.[0]?.id
        );
      },
      async manageAdUpdate() {
        if (
          (self.adDetails.status === PUBLISHED_UPDATE ||
            self.adDetails.status === PUBLISHED_SOON_UPDATE) &&
          self.updateAllowed
        ) {
          this.setUpdate(await self.root.api.adUpdate.get(self.adDetails.id));
        }
      },
      setLabelColor(color: string | null) {
        if (color) {
          self.getLabelColorDropdown().setValue(color);
        }
        self.labelColor = color;
      },
      setLabelText(labelText: string | null) {
        if (labelText) {
          self.getLabelText().setValue(labelText);
        }
        self.labelText = labelText;
      },
      setLabelHex(hexValue: string | null) {
        self.labelColorHex = hexValue;
      },
      setUpdate(update: AdUpdate) {
        self.update = update;
      },
      // manages max used codes which can be entered
      manageMaxCodes() {
        this.getUsedCodes().codeInput.setMax(
          (self?.adAnalytics?.codes_total
            ? self?.adAnalytics?.codes_total
            : Infinity) - this.getUsedCodes().total
        );
      },
      setAdCreator(admin: Admin) {
        self.adCreator = admin;
      },
      setRejectedBy(admin: Admin) {
        self.rejectedByDetails = admin;
      },
      setApprovedBy(admin: Admin) {
        self.approvedBy = admin;
      },
      setRecommended(recommended: number) {
        self.recommended = recommended;
      },
      setAdditionalLabelText(labelText: string) {
        self.labelText = labelText;
      },
      async manageCreatedBy() {
        const createdBy = self?.adDetails?.created_by;
        if (!createdBy) {
          return;
        }
        const response = await self.root.api.admin.get(createdBy);
        this.setAdCreator(response.data);
      },
      async manageRejectedBy() {
        const createdBy = self?.adDetails?.rejections?.[0]?.created_by;
        if (createdBy) {
          const response = await self.root.api.admin.get(createdBy);
          this.setRejectedBy(response.data);
        }
      },
      async manageApprovedBy() {
        const approvedBy = self?.adDetails?.published_by;
        if (!approvedBy) {
          return;
        }
        const response = await self.root.api.admin.get(approvedBy);
        this.setApprovedBy(response.data);
      },
      saveAttributes() {
        self.root.api.offers
          .offerSaveAttributes(self.adDetails?.id, self.adAttributes)
          .then(() => {
            this.setAttributes();
          });
      },
      saveComment() {
        self.root.api.offers.saveComment(
          self.adDetails?.id,
          self.getCommentTextArea()?.value
        );
        self.adDetails.comment = self.getCommentTextArea().value;
        self
          .getTextInputField()
          .setEditorState(
            EditorState.createWithContent(
              stateFromHTML(self.adDetails?.comment)
            )
          );
      },
      setAttributes() {
        this.setLabelText(self?.getLabelText().value);
        this.setLabelColor(self?.getLabelColorDropdown().value);
        this.getLabelColor();
      },
      downloadPictures() {
        self.root.api.offers.downloadImages();
      },
      clearData() {
        // clear ad's purchased extension on exit ad detail page
        self.root.data.setPurchasedExtension(null);
        this.resetModelFields();
      },
      resetModelFields() {
        self.isImageFromResponse = true;
        self.showCancelReasonInput = false;
        self.adExtension = undefined;
        self.adCreator = undefined;
        self.update = undefined;
        self.rejectedByDetails = undefined;
        self.adAddons.clear();
        self.approvedBy = undefined;
        self.labelText = null;
        self.recommended = 0;
      },
      async manageAdAnalytics() {
        await self.root.api.offers.getAdAnalytics(self.adDetails.id);
        this.getUsedCodes().setAdId(self.adDetails.id);
        await this.getUsedCodes().loadCodes();
        this.manageMaxCodes();
      },
      setAdExtension(extension: ContractExtension | undefined) {
        self.adExtension = extension ? extension : undefined;
      },
      async manageAdExtension() {
        if (!self.adDetails.contract_extension) {
          this.setAdExtension(undefined);
          return;
        }
        await self.root.api.contract.getContractExtensions(self.contract.id);
        const extensions: ContractExtension[] =
          self.root.data.contractExtensions;
        const adExtension: ContractExtension = extensions.filter(
          (elem: ContractExtension) =>
            elem.id === self.adDetails.contract_extension
        )[0];
        this.setAdExtension(adExtension);
      },
      setAdAddons(addons: ContractAddon[]) {
        self.adAddons.replace(addons);
      },
      async manageAdAddons() {
        if (
          !self.adDetails.contract_addons ||
          !self.adDetails.contract_addons.length
        ) {
          this.setAdAddons([]);
          return;
        }
        await self.root.api.contract.getContractAddons(
          self.contract.id,
          'location'
        );
        await self.root.api.contract.getContractAddons(
          self.contract.id,
          'target'
        );
        const allContractAddons: ContractAddon[] = [
          ...self.root.data.locationContractAddons,
          ...self.root.data.targetContractAddons,
        ];
        const addonIds = self.adDetails.contract_addons.map((addon: any) => {
          return addon.contract_addon_id;
        });
        const relevantAddons: ContractAddon[] = allContractAddons.filter(
          (addon: ContractAddon) => addonIds.includes(addon.id)
        );
        this.setAdAddons(relevantAddons);
      },
      setShowCancelReasonInput() {
        self.showCancelReasonInput = !self.showCancelReasonInput;
        self
          .getTextInputField()
          .setEditorState(
            EditorState.createWithContent(
              stateFromHTML(self.adDetails?.comment || '')
            )
          );
      },
      getConfirmationModal() {
        return (
          self?.components.get('ConfirmationModal') ||
          self?.addComponent(
            ApproveAdModel.create({
              id: 'ConfirmationModal',
              modal: ModalModel.create({
                id: 'ApproveAdModelModal',
                label: 'Approve Ad',
                opened: false,
                showCloseButton: true,
              }),
            })
          )
        );
      },
      getApproveRejectAd() {
        return (
          self?.components.get('ApproveRejectAdModel') ||
          self?.addComponent(
            ApproveRejectAdModel.create({
              id: 'ApproveRejectAdModel',
              modal: ModalModel.create({
                id: 'ApproveRejectModal',
                label: 'Approve Ad',
                opened: false,
                showCloseButton: true,
              }),
            })
          )
        );
      },
      getSectionHeader() {
        return (
          self?.components.get('WaitingForApprovalSection') ||
          self?.addComponent(
            SectionModel.create({
              id: 'WaitingForApprovalSection',
              opened: true,
            })
          )
        );
      },
      getSectionHeaderLabel() {
        let label = '';
        const status = self?.root?.data?.adDetails?.status;
        if (status === SUBMITTED) {
          label = 'basic:sent_for_approval';
        } else if (status === PUBLISHED) {
          label = 'basic:active_ad';
        } else if (status === EXPIRED) {
          label = 'basic:expired_ad';
        } else if (status === PUBLISHED_SOON) {
          label = 'basic:published_soon_ad';
        } else if (status === STOPPED) {
          label = 'basic:stopped_ad';
        } else if (status === SUBMITTING) {
          label = 'basic:submitting_ad';
        } else if (status === PUBLISHING) {
          label = 'basic:publishing_ad';
        } else if (status === CODE_SPENT) {
          label = 'basic:code_spent_ad';
        } else if (status === PUBLISHED_UPDATE) {
          label = 'basic:published_update_ad';
        } else if (status === PUBLISHED_SOON_UPDATE) {
          label = 'basic:published_soon_update_ad';
        } else if (status === PREP) {
          label = 'basic:draft_ad';
        } else {
          label = 'basic:rejected_ad';
        }
        this.getSectionHeader().setLabel(label);
        return this.getSectionHeader();
      },
      getPackagesAndAddonsPriceListSection() {
        return (
          self?.components.get('PackagesAndAddonsPriceListSection') ||
          self?.addComponent(
            SectionModel.create({
              id: 'PackagesAndAddonsPriceListSection',
              label: 'basic:overview_price_list_packages_and_addons',
              opened: true,
            })
          )
        );
      },
      getChangeButton() {
        return (
          self.components.get('ChangeButton') ||
          self.addComponent(
            ButtonModel.create({
              id: 'ChangeButtonModel',
              label: 'basic:send',
              onClickCallback: () => {
                this.getConfirmationModal().openModal(REJECT_AD);
              },
            })
          )
        );
      },
      getPlaceAdAgainButton() {
        return (
          self.components.get('PlaceAdAgainButton') ||
          self.addComponent(
            ButtonModel.create({
              id: 'PlaceAdAgainButtonModel',
              label: 'basic:place_ad_again',
              onClickCallback: () => {
                this.editInactiveAd();
              },
            })
          )
        );
      },
      selectPartner() {
        self.root.user.setPartner(self.partnerDetails);
      },

      getUsedCodes() {
        return (
          self?.components.get('UsedCodes') ||
          self?.addComponent(
            UsedCodesModel.create({
              id: 'UsedCodes',
              codeInput: TextInputModel.create({
                id: 'input',
                type: 'number',
                min: 0,
                placeholder: i18n.t('basic:input_ad_codes'),
              }),
              btn: ButtonModel.create({
                id: 'btn',
                label: 'basic:input',
                onClickCallback: async () => {
                  await this.getUsedCodes().submitCode();
                  this.getUsedCodes().clearInput();
                  this.manageMaxCodes();
                },
              }),
            })
          )
        );
      },

      /** Fill create offer page when recreating the ad start  */
      recreateAd() {
        this.selectPartner();
        const createOfferPage = self.root.pages.get(PageRoutes.CreateOffer.id);
        createOfferPage.clearData();

        self.root.header.setActiveTab(PARTNER_PROFILES);
        this.fillFirstStep(createOfferPage);
        this.fillDates();
        this.manageCodeSection();
      },
      setValidDates() {
        const page = self.root.pages.get(PageRoutes.CreateOffer.id);
        const voucherDuration = page.getVoucherDuration();
        const offerDuration = page.getOfferDuration();

        voucherDuration.setFromDate(new Date());
        voucherDuration.setToDate(new Date());

        offerDuration.setFromDate(new Date());
        offerDuration.setToDate(new Date());
      },
      fillCategoriesAndSubcategories() {
        const page = self.root.pages.get(PageRoutes.CreateOffer.id);
        const selectedCategoriesIds: any = [];
        const selectedSubcategoriesIds: any = [];
        self.adDetails.categories.map((adDetailsCategory: any) => {
          const category = self.root.data.categories.find(
            (category: Category) =>
              category.id === adDetailsCategory.category_id
          );
          if (!category) return;

          selectedCategoriesIds.push(category.id);

          const subCategory = category.subcategories.find(
            (subcategory: SubCategory) =>
              subcategory.id === adDetailsCategory.sub_category_id
          );

          if (!subCategory) {
            selectedSubcategoriesIds.push(
              `${adDetailsCategory.category_id}_all`
            );
          } else {
            selectedSubcategoriesIds.push(
              `${adDetailsCategory.category_id}_${adDetailsCategory.sub_category_id}`
            );
          }
        });

        page.handleNewSelectionCategoriesAndSubcategoriesPick(
          selectedCategoriesIds,
          selectedSubcategoriesIds
        );
      },
      disableNumberOfCoupons() {
        const page = self.root.pages.get(PageRoutes.CreateOffer.id);
        page.disableNumberOfCoupons();
      },
      manageCreateOfferUpdateMode() {
        const page = self.root.pages.get(PageRoutes.CreateOffer.id);
        page.getNumberOfCoupons().setValue(self?.adAnalytics?.codes_total);
        this.manageCodeSection();
        page.handleUpdateMode();
      },
      manageCodeSection() {
        if (self.adDetails.with_code) {
          const page = self.root.pages.get(PageRoutes.CreateOffer.id);
          page
            .getEnterCodesModel()
            .codeTypeDropdown.setValue(
              self.adDetails.with_code === 'common' ? 'common' : 'personalized'
            );
          page
            .getEnterCodesModel()
            .textInputField.setValue(self.adDetails.common_code);
          const checkboxContainer = page.getEnterCodesModel().checkboxContainer;
          self.adDetails.code_type.split(',').forEach((codeType) => {
            checkboxContainer.check({ id: codeType });
          });
        }
      },
      resetCreateAdPage(page: CreateOfferPageType) {
        page.getOfferNameModel().setValue('');
        page.getLoweredPriceRSDModel().setValue('');
        page.getDiscountPercent().setValue('');
        page.getNumberOfCoupons().setValue('');
        page.getRegularPriceRSDModel().setValue('');
        page.getDiscountModel().setValue('');
        page.getOfferDescriptionModel().setStateFromString('');
        page.getOfferTerms().setStateFromString('');
        page.getOfferLinkUrl().setValue('');
        page.getKeywords().setValue('');
        page
          .getFileInputs()
          .forEach((fileInput: FileInputType) => fileInput.clearFiles());
        page.getAdRadioButtons().setValue('');
        page.getEnterCodesModel().textInputField.setValue('');
        page.getEnterCodesModel().setUploadCodesRef('');
        page.getLocationTagGroup().clearTags();
        page.getCategoryAndSubcategoryPickModel().clearAllOptions();
        page.componentsArray.forEach((component: any) => {
          if (component.setCurrentMessage) {
            component.setCurrentMessage('');
          }
        });
      },
      async fillAdImages(page: CreateOfferPageType) {
        const images = [
          self.adDetails.image0,
          self.adDetails.image1,
          self.adDetails.image2,
          self.adDetails.image3,
        ];
        const fileInputs = page.getFileInputs();
        fileInputs.forEach((fileInput: FileInputType) =>
          fileInput.clearFiles()
        );
        images.forEach(async (image: string, index: number) => {
          if (image) {
            let file = null;
            try {
              // this fails locally mainly because of cors error
              file = await linkToFile(`${IMAGES_BASE_URL}${image}`);
            } catch (err) {
              console.log(err);
            }
            if (file) {
              fileInputs[index].setFiles(filesToFileList([file]), false);
            }
          }
        });
      },
      fillPartnerLocations() {
        const page = self.root.pages.get(PageRoutes.CreateOffer.id);
        const storeLocationsStr = self?.adDetails?.store_locations;
        if (!storeLocationsStr) {
          return;
        }
        const locations = storeLocationsStr.split?.(',');
        if (locations) {
          const pickStoreModal = page.getPickStoresModal();
          locations.forEach((locationId: string) => {
            pickStoreModal.checkboxContainer.check({ id: locationId });
          });
          pickStoreModal.updateStoreSelection();
        }
      },
      handleOfferType(page: any) {
        // LOY-393 changes
        // (if type is special the offer is segmented, otherwise not)
        page
          .getOfferTypeModel()
          .handleNewSelection(
            self.adDetails.type === SPECIAL ? SEGMENTED : UNSEGMENTED,
            true
          );
      },
      fillFirstStep(page: CreateOfferPageType) {
        page
          .getLocationPickModel()
          .handleNewSelection(self.adDetails.store_location_type);
        this.handleOfferType(page);
        page.getOfferNameModel().setValue(self.adDetails.name);

        if (self.adDetails.discount_value) {
          page
            .getLoweredPriceRSDModel()
            .setValue(`${parseNumberSRLocale(self.adDetails.discount_value)}`);
        }

        if (self.adDetails.discount_percentage) {
          page
            .getDiscountPercent()
            .setValue(self.adDetails.discount_percentage);
        }

        if (self.adDetails.code_count) {
          page.getNumberOfCoupons().setValue(self.adDetails.code_count);
        }

        if (self.adDetails.original_price) {
          page
            .getRegularPriceRSDModel()
            .setValue(`${parseNumberSRLocale(self.adDetails.original_price)}`);
        }

        page.getDiscountModel().setValue(self.adDetails.discount_description);
        page
          .getOfferDescriptionModel()
          .setStateFromString(self.adDetails.description);
        page.getOfferTerms().setStateFromString(self.adDetails.conditions);
        page.getOfferLinkUrl().setValue(self.adDetails.url);
        page.getKeywords().setValue(self.adDetails.keywords);
        this.fillAdImages(page);
        this.fillAdType(page);
        this.fillSecondStep(page);
      },
      // top or free for now, visual type
      fillAdType(page: CreateOfferPageType) {
        page.getAdRadioButtons().setValue(self.adDetails.visual_type);
      },
      fillTags(page: CreateOfferPageType) {
        if (!self.adDetails.user_locations) {
          return;
        }
        const places = self.adDetails.user_locations.split(',');
        places.forEach((place: string) => {
          page.getLocationTagGroup().add({ id: `${place}`, name: place });
        });
        page.updateDisabledLocations();
      },
      fillCheckboxes(page: CreateOfferPageType) {
        const segments = self?.adDetails?.user_contract_type?.split?.(',');
        if (segments) {
          page.getUserSegments().setIsRequired(false);
          page.getUserSegments().deselectAll();
          segments.forEach((segmentId: string) => {
            page.getUserSegments().check({ id: segmentId });
          });
          page.getUserSegments().setIsRequired(true);
        }

        const types = self?.adDetails?.spender_type?.split?.(',');
        if (types) {
          page.getMobileNetworkSpending().deselectAll();
          types.forEach((typeId: string) => {
            page.getMobileNetworkSpending().check({ id: typeId });
          });
        }
      },
      fillTargeting(page: CreateOfferPageType) {
        // so we can edit the components and their values
        page
          .getGenderDropdown()
          .handleNewSelection(
            self.adDetails.gender ? self.adDetails.gender : ' '
          );
        page
          .getFromAge()
          .handleNewSelection(
            self.adDetails.age_from ? `${self.adDetails.age_from}` : ''
          );
        page
          .getToAge()
          .handleNewSelection(
            self.adDetails.age_to ? `${self.adDetails.age_to}` : ''
          );

        page
          .getUsersPickedSimilarOffersDropdown()
          .handleNewSelection(self.adDetails.similar_only);
        page
          .getAreaDropdown()
          .handleNewSelection(
            self.adDetails.territory_type ? self.adDetails.territory_type : ' '
          );
        this.fillCheckboxes(page);
      },
      async fillSecondStep(page: CreateOfferPageType) {
        this.fillTags(page);
        this.fillTargeting(page);
        if (self.adDetails.common_code) {
          page
            .getEnterCodesModel()
            .textInputField.setValue(self.adDetails.common_code);
        }
        if (self.adDetails.with_code === CODE_TYPE_PERSONALIZED) {
          const csvData = await self.root.api.offers.getCSVCodes(
            self.adDetails.ref,
            self.adDetails.partner_id
          );
          const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
          const file = new File([blob], self.adDetails.ref, {
            type: 'text/csv',
          });
          page.getEnterCodesModel().setFile(file);
        }

        page.getEnterCodesModel().setUploadCodesRef(self.adDetails.ref || '');
      },
      /** Fill create offer page when recreating the ad end  */

      async editInactiveAd() {
        this.recreateAd();
        await self.root.router.redirectToCreateOffer({
          updateAd: self.adDetails.id,
          // isInactive: true,
        });
        this.fillPartnerLocations();
        // This has to be filled after page navigation completes
        this.fillCategoriesAndSubcategories();
        this.fillPartnerLocations();
        this.setValidDates();
        if (self.adDetails.status === SUBMITTED) {
          this.disableDates();
          const page = self.root.pages.get(PageRoutes.CreateOffer.id);
          page.getEnterCodesModel().setDisabled(true);
        }
      },
      fillDates() {
        const page = self.root.pages.get(PageRoutes.CreateOffer.id);
        const voucherDuration = page.getVoucherDuration();
        const offerDuration = page.getOfferDuration();

        setTimeout(() => {
          voucherDuration.setFromDate(
            parseDate(self.adDetails.date_code_start)
          );
          voucherDuration.setToDate(parseDate(self.adDetails.date_code_end));

          offerDuration.setFromDate(parseDate(self.adDetails.date_start));
          offerDuration.setToDate(parseDate(self.adDetails.date_end));
        }, 1500);
      },
      // as per LOY-588, sorely needed
      disableDates() {
        const page = self.root.pages.get(PageRoutes.CreateOffer.id);
        page.getOfferDuration().setDisabled(true);
        page.getVoucherDuration().setDisabled(true);
      },
      async editActiveAd() {
        this.recreateAd();
        await self.root.router.redirectToCreateOffer({
          updateAd: self.adDetails.id,
        });
        this.fillCategoriesAndSubcategories();
        // this has to be filled after page navigation completes
        this.fillDates();
        this.disableDates();
        this.disableNumberOfCoupons();
        this.fillPartnerLocations();
        this.manageCreateOfferUpdateMode();
      },
      getActivateButton() {
        return (
          self.components.get('ActivateButton') ||
          self.addComponent(
            ButtonModel.create({
              id: 'ActivateButton',
              label: 'basic:activate',
              onClickCallback: () => {
                console.log('click');
              },
            })
          )
        );
      },
      getLabelColor(): string | undefined {
        switch (self.labelColor) {
          case 'blue':
            return (self.labelColorHex = '#C4DFE9');
          case 'pink':
            return (self.labelColorHex = '#FFE9D3');
          case 'yellow':
            return (self.labelColorHex = '#FFF6CF');
          case undefined:
            return '';
        }
      },
    };
  })
  .named('AdDetailsModel');

export type AdDetailsModelType = Instance<typeof AdDetailsModel>;
