import { observer } from 'mobx-react';
import styles from './Header.module.scss';
import { useMst } from '../../../internal';
import { useTranslation } from 'react-i18next';
import giftIcon from '../../../assets/icons/gift-icon.png';
import { differenceInDays } from 'date-fns';
import { BottomHeaderContent } from './BottomHeaderContent';

export const BottomHeader = observer(() => {
  const {
    router,
    data: { partnerDetails, isPromoPeriodOver, isPromoPackage },
  } = useMst();
  const { t } = useTranslation(['basic', 'navigation']);
  const {
    header: { activeMenuItem },
  } = useMst();
  if (!activeMenuItem) {
    return <></>;
  }
  const numberOfDays = partnerDetails?.promo_till
    ? differenceInDays(new Date(partnerDetails?.promo_till), Date.now())
    : -1;
  return (
    <div className={styles.NavBottomContainer}>
      <div className={styles.NavBottomContent}>
        <BottomHeaderContent />
      </div>
      {!partnerDetails ||
      isPromoPeriodOver ||
      isPromoPackage ? null : router.isCreateOfferPage ||
        router.isPaymentsAndDocumentsPage ? (
        <div
          className={
            numberOfDays >= 10 || numberOfDays < 0
              ? styles.PromoPeriodCounterGreen
              : styles.PromoPeriodCounterOrange
          }
        >
          <img className={styles.Icon} src={giftIcon} alt="promo" />
          <p>{t('basic:promo_period_length')} </p>
          {numberOfDays > 0 ? (
            <>
              <span>{numberOfDays}</span>
              <p>{t('basic:days')}</p>
            </>
          ) : null}
        </div>
      ) : null}
    </div>
  );
});
