import { observer } from 'mobx-react';
import { FC } from 'react';
import { CheckboxGroup } from '../../../components/UI/checkbox/CheckboxGroup';
import { Dropdown } from '../../../components/UI/dropdown/Dropdown';
import { InputField } from '../../../components/UI/input/InputField';
import { Modal } from '../../../components/UI/modal/Modal';
import { BillingModalModelType } from '../../domain/accounts/billing-modal-model/BillingModalModel';
import styles from './BillingModal.module.scss';
import { PROMO_PACKAGE } from '../../../utils/constants';

interface IBillingModalProps {
  model: BillingModalModelType;
}

export const BillingModal: FC<IBillingModalProps> = observer(({ model }) => {
  return (
    <Modal
      styles={{
        content: {
          //maxHeight: 'fit-content',
          width: '800px',
          borderRadius: '20px',
          maxHeight: '90%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
        },
      }}
      model={model}
    >
      <div className={styles.modalWrapper}>
        <p className={styles.title}> Model naplate </p>

        <div className={styles.table}>
          <div className={styles.tableRow}>
            <span> Partner/Kompanija: </span>
            <span> {model.partnerAdmin?.name} </span>
          </div>
        </div>

        <div className={styles.packagesDropdownWrapper}>
          <Dropdown model={model.packagesDropdown} />
        </div>
        {model.packagesDropdown?.selectedOptionId !== PROMO_PACKAGE && (
          <>
            <p className={styles.discountsTitle}> Aktivni popusti </p>
            <div className={styles.discountsCheckboxesWrapper}>
              {model.packagesDropdown.selectedOptionId === 'pay-as-you-go' && (
                <CheckboxGroup
                  model={model.billingModalDiscountsCheckboxGroupPayAsYouGo}
                />
              )}
              {model.packagesDropdown.selectedOptionId === 'subscription' && (
                <div style={{ display: 'flex' }}>
                  <CheckboxGroup
                    model={model.billingModalDiscountsCheckboxGroupSubscription}
                  />
                  {model.billingModalDiscountsCheckboxGroupSubscription.selectedCheckboxesIds.includes(
                    'btl'
                  ) && (
                    <div
                      style={{
                        width: '25%',
                        display: 'flex',
                        alignItems: 'flex-end',
                      }}
                    >
                      <InputField model={model.billingModalBtlInput} />
                    </div>
                  )}
                </div>
              )}
              {model.packagesDropdown.selectedOptionId ===
                'subscription-with-contract-obligation' && (
                <div style={{ display: 'flex' }}>
                  <CheckboxGroup
                    model={
                      model.billingModalDiscountsCheckboxGroupSubscriptionWithContract
                    }
                  />
                  {model.billingModalDiscountsCheckboxGroupSubscriptionWithContract.selectedCheckboxesIds.includes(
                    'btl'
                  ) && (
                    <div
                      style={{
                        width: '25%',
                        display: 'flex',
                        alignItems: 'flex-end',
                      }}
                    >
                      <InputField model={model.billingModalBtlInput} />
                    </div>
                  )}
                </div>
              )}
            </div>
          </>
        )}

        {model.partnerAdmin?.contract.status !== 'inactive' ? (
          <div className={styles.buttonsWrapper}>
            <button
              onClick={() => model.submit()}
              className={styles.saveChangesButton}
            >
              Sačuvaj izmene
            </button>
            <button
              onClick={() => model.close()}
              className={styles.rejectButton}
            >
              Poništi
            </button>
          </div>
        ) : (
          <div className={styles.buttonsWrapper}>
            <button
              onClick={() => model.close()}
              className={styles.rejectButton}
            >
              Zatvori
            </button>
          </div>
        )}
      </div>
    </Modal>
  );
});
