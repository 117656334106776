import { observer } from 'mobx-react';
import * as React from 'react';
import ReactModal from 'react-modal';
import { ModalModelType } from '../../../internal';
import style from './Modal.module.scss';

export const Modal = observer(
  (props: {
    styles?: any; // see the docs: https://reactcommunity.org/react-modal/styles/
    children: any;
    model: ModalModelType;
    id?: string;
  }): JSX.Element => {
    const { model, children, styles, id } = props;
    return (
      <ReactModal
        id={id}
        style={styles ? styles : {}}
        isOpen={model.isOpened}
        overlayClassName={style.Overlay}
        className={style.Content}
        contentLabel={model.label}
        onAfterClose={() => model.onAfterClose?.(model)}
        onAfterOpen={() => model.onAfterOpen?.(model)}
        appElement={document.getElementById('root') || undefined}
        onRequestClose={() => model.setOpened(false)}
      >
        {model.showCloseButton ? (
          <div
            onClick={() => model.setOpened(false)}
            className={style.XButton}
          />
        ) : (
          <></>
        )}
        {children}
      </ReactModal>
    );
  }
);
