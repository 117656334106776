import * as React from 'react';
import { Editor, RichUtils } from 'draft-js';
import styles from './TextAreaInputField.module.scss';
import { stateToHTML } from 'draft-js-export-html';

import {
  BOLD,
  ITALIC,
  UNORDERED_LIST_ITEM,
} from '../../../constants/createoffer';
import { observer } from 'mobx-react';
import { TextInputModelType } from '../../../internal';
import { Show } from '../show/Show';
import { hasEditorSelection, onAddLink } from '../../../utils/draftJS_Link';
import linkIcon from '../../../assets/icons/link_icon.png';

export const CustomTextEditor = observer(
  (props: { model: TextInputModelType }): JSX.Element => {
    const {
      model,
      model: { editorState, setEditorState },
    } = props;

    const [open, setOpen] = React.useState<boolean>(false);
    const [formData, setFormData] = React.useState({
      url: '',
      text: '',
    });

    const editor = React.useRef<Editor>(null);
    const focusEditor = React.useCallback(() => {
      if (editor.current) {
        editor.current.focus();
      }
    }, [editor]);

    const handleToggleClick = (e: React.MouseEvent, inlineStyle: string) => {
      e.preventDefault();
      if (inlineStyle === BOLD) {
        model.toggleBold(model.isSelectedBold);
      } else {
        model.toggleItalic(model.isSelectedItalic);
      }
      setEditorState(RichUtils.toggleInlineStyle(editorState, inlineStyle));
    };

    const handleBlockClick = (e: React.MouseEvent, blockType: string) => {
      e.preventDefault();
      if (blockType === UNORDERED_LIST_ITEM) {
        model.toggleBullet(model.isSelectedBullet);
      }
      setEditorState(RichUtils.toggleBlockType(editorState, blockType));
    };
    const options = {
      inlineStyles: {
        BOLD: { element: 'b' },
      },
    };

    const setInputValue = (event: any) => {
      const { name, value } = event.target;
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    };

    const resetForm = () => {
      setFormData({
        url: '',
        text: '',
      });
    };

    const html = stateToHTML(editorState.getCurrentContent(), options);
    const hasText = editorState.getCurrentContent().hasText();
    model.setHtml(hasText, html);
    return (
      <>
        <div className={styles.TextAreaContent} onClick={focusEditor}>
          <div className={styles.TextAreaButtons}>
            <Show condition={model.showButtons}>
              <button
                className={
                  model.isSelectedBold
                    ? styles.RichTextButtonActive
                    : styles.RichTextButton
                }
                onMouseDown={(e) => handleToggleClick(e, BOLD)}
              >
                <img className={styles.RichTextIconBold} />
              </button>
              <button
                className={
                  model.isSelectedItalic
                    ? styles.RichTextButtonActive
                    : styles.RichTextButton
                }
                onMouseDown={(e) => handleToggleClick(e, ITALIC)}
              >
                <img className={styles.RichTextIconItalic} />
              </button>
              <button
                className={
                  model.isSelectedBullet
                    ? styles.RichTextButtonActive
                    : styles.RichTextButton
                }
                onMouseDown={(e) => handleBlockClick(e, UNORDERED_LIST_ITEM)}
              >
                <img className={styles.RichTextIconBullet} />
              </button>
              <button
                className={styles.RichTextButton}
                onMouseDown={() => setOpen(true)}
                disabled={hasEditorSelection(editorState)}
              >
                <img src={linkIcon} alt="link_icon" />
              </button>
            </Show>
          </div>
          <Editor
            editorState={editorState}
            onChange={setEditorState}
            ref={editor}
          />
        </div>
        <dialog open={open} tabIndex={1}>
          <form method="dialog">
            <input
              type="text"
              name="url"
              placeholder="URL"
              onChange={setInputValue}
              value={formData.url}
            />
            <input
              type="text"
              name="text"
              placeholder="Tekst"
              onChange={setInputValue}
              value={formData.text}
            />
            <button
              onClick={() => {
                setOpen(false);
                onAddLink({
                  editorState,
                  setEditorState,
                  formData,
                  resetForm,
                });
              }}
            >
              Dodaj
            </button>
          </form>
        </dialog>
      </>
    );
  }
);
