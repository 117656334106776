import { observer } from 'mobx-react';
import * as React from 'react';
import styles from './Checkbox.module.scss';
import { useTranslation } from 'react-i18next';
const BASIC_STYLE = 'Basic';

export const Checkbox = observer(
  (props: {
    model: any;
    typeClass?: any;
    labelClassName?: string;
    checkboxClassName?: string;
  }): JSX.Element | null => {
    const {
      model,
      typeClass = BASIC_STYLE,
      labelClassName,
      checkboxClassName,
    } = props;
    const { t } = useTranslation();

    if (model.isVisible === false) return null;

    return (
      <div className={`${styles.CheckboxContainer} ${checkboxClassName || ''}`}>
        <div className={styles[typeClass]}>
          <input
            type="checkbox"
            id={model.id}
            name={model.id}
            value={model.value}
            onChange={model.onChange}
            checked={model.isChecked}
            disabled={
              typeClass !== BASIC_STYLE ? model.disabled : model.isDisabled
            }
          />
          <label
            className={`${labelClassName || ''} ${
              !model.isEnabledComputed
                ? styles.CheckboxLabelDisabled
                : styles.CheckboxLabel
            }`}
            htmlFor={model.id}
          >
            {t(model.label)}
          </label>
        </div>
      </div>
    );
  }
);
