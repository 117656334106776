import { observer } from 'mobx-react-lite';
import { PageRoutes, useMst } from '../../../internal';
import Link from '../../UI/link';

export const ConfirmAdminSuccess = observer((): JSX.Element => {
  const { router, clearToasts } = useMst();
  return (
    <div style={{ fontSize: '18px' }}>
      Uspešno ste kreirali nalog. Prijavite se na Admin panel klikom{' '}
      <Link
        text="ovde"
        view={router.views.get(PageRoutes.Login.id)}
        onClick={() => {
          clearToasts();
        }}
      />
    </div>
  );
});

export const ConfirmResetSuccess = observer((): JSX.Element => {
  const { router, clearToasts } = useMst();
  return (
    <div style={{ fontSize: '18px' }}>
      Uspešno ste resetovali lozinku. Prijavite se na Admin panel klikom{' '}
      <Link
        text="ovde"
        view={router.views.get(PageRoutes.Login.id)}
        onClick={() => {
          clearToasts();
        }}
      />
    </div>
  );
});

export const createConfirmAdminMessage = (): JSX.Element => (
  <ConfirmAdminSuccess />
);

export const createConfirmResetSuccessMessage = (): JSX.Element => (
  <ConfirmResetSuccess />
);
